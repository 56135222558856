import { SelectValuePairCategory } from './types';
import { getObjectTypeIdValuePairCategory } from './getObjectTypeIdValuePairCategory';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';

export const getVisibleValuePairCategories = (
  selectedParam:
    | workflow_parameters.v1.DestinationActionParameter
    | null
    | undefined,
  selectedSubRow: number | null | undefined
): (SelectValuePairCategory | string)[] => {
  const ObjectTypeIds = getObjectTypeIdValuePairCategory(selectedParam);

  if (selectedParam?.static) {
    if (selectedParam?.static?.objectTypeIdContext) {
      return [
        ObjectTypeIds,
        SelectValuePairCategory.PayloadField,
        SelectValuePairCategory.CalculatedValue,
        SelectValuePairCategory.ConstantValue,
        SelectValuePairCategory.ElidedValue,
      ];
    }

    return [
      SelectValuePairCategory.Identifiers,
      SelectValuePairCategory.MorfProperties,
      SelectValuePairCategory.CustomProperties,
      SelectValuePairCategory.PayloadField,
      SelectValuePairCategory.CalculatedValue,
      SelectValuePairCategory.ConstantValue,
      SelectValuePairCategory.ElidedValue,
      SelectValuePairCategory.MorfEventType,
      SelectValuePairCategory.MorfEventTime,
      SelectValuePairCategory.TemplateEditor,
    ];
  }

  if (selectedParam?.dynamic) {
    if (selectedParam?.dynamic?.elementFieldType?.listType) {
      if (selectedSubRow !== null) {
        return [
          ObjectTypeIds,
          SelectValuePairCategory.Identifiers,
          SelectValuePairCategory.MorfProperties,
          SelectValuePairCategory.CustomProperties,
          SelectValuePairCategory.PayloadField,
          SelectValuePairCategory.CalculatedValue,
          SelectValuePairCategory.ConstantValue,
          SelectValuePairCategory.ElidedValue,
          SelectValuePairCategory.MorfEventType,
          SelectValuePairCategory.MorfEventTime,
        ];
      }

      return [
        SelectValuePairCategory.MorfProperties,
        SelectValuePairCategory.CustomProperties,
        SelectValuePairCategory.CalculatedValue,
        SelectValuePairCategory.PayloadField,
        SelectValuePairCategory.BuildArray,
      ];
    }

    if (selectedParam?.dynamic?.elementFieldType?.mapType) {
      if (selectedSubRow !== null) {
        return [
          ObjectTypeIds,
          SelectValuePairCategory.Identifiers,
          SelectValuePairCategory.MorfProperties,
          SelectValuePairCategory.CustomProperties,
          SelectValuePairCategory.PayloadField,
          SelectValuePairCategory.CalculatedValue,
          SelectValuePairCategory.ConstantValue,
          SelectValuePairCategory.ElidedValue,
          SelectValuePairCategory.MorfEventType,
          SelectValuePairCategory.MorfEventTime,
        ];
      }

      return [
        SelectValuePairCategory.CalculatedValue,
        SelectValuePairCategory.PayloadField,
        SelectValuePairCategory.BuildObject,
      ];
    }
  }

  return [
    ObjectTypeIds,
    SelectValuePairCategory.Identifiers,
    SelectValuePairCategory.MorfProperties,
    SelectValuePairCategory.CustomProperties,
    SelectValuePairCategory.PayloadField,
    SelectValuePairCategory.CalculatedValue,
    SelectValuePairCategory.ConstantValue,
    SelectValuePairCategory.ElidedValue,
    SelectValuePairCategory.MorfEventType,
    SelectValuePairCategory.MorfEventTime,
    SelectValuePairCategory.TemplateEditor,
  ];
};
