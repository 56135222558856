import Cookies from 'js-cookie';
import { Container } from '@morf/ui/Container';
import { DocumentationIcon } from './DocumentationIcon';
import { FC, memo, useRef, useState } from 'react';
import { Flexbox } from '@morf/ui/Flexbox';
import { Icon } from '@morf/ui/Icon';
import { NavigationItems } from './NavigationItems';
import { NavigationRailProps } from './types';
import { OrganizationMenu } from './OrganizationMenu';
import { STYTCH_SESSION_JWT } from '@morf/constants';
import { SpotlightSearchIcon } from './SpotlightSearchIcon';
import { Tooltip } from '@morf/ui/Tooltip';
import { UserMenu } from './UserMenu';
import { accounts } from '@morf/proto/accounts/v1/accounts_ts_proto';
import { clearAllCookies } from './clearAllCookies';
import { clearLocalStorage } from './clearLocalStorage';
import { revokeStytchSession } from '../../../../lib/revokeStytchSession';
import { useClickOutside } from '@morf/ui/Hooks/useClickOutside';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usePermissions } from '../../../context/permissions/usePermissions';
import { useRouter } from 'next/router';
import { useTheme } from '@morf/theming';

export const navigationRailWidth = '3rem';

const NavigationRail: FC<NavigationRailProps> = ({
  organizationId,
  organizationPermissions,
  setShowSpotlightSearch,
  userImage,
}) => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const theme = useTheme();
  const { organizationPermission } = usePermissions();
  const organizationMenuRef = useRef<HTMLDivElement>(null);

  const [organizationMenu, setOrganizationMenu] = useState(false);

  useClickOutside(organizationMenuRef, () => {
    setOrganizationMenu(false);
  });

  const { mutateAsync: onRevokeStytchSession } = useMutation(
    async (session_jwt: string) => {
      return await revokeStytchSession({
        session_jwt,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        router.push('/login');
      },
    }
  );

  const handleToggleOrganizationMenu = () =>
    setOrganizationMenu(!organizationMenu);
  const handleCloseOrganizationMenu = () => setOrganizationMenu(false);

  const handleLogout = () => {
    const session_jwt = Cookies.get(STYTCH_SESSION_JWT);
    if (session_jwt) {
      onRevokeStytchSession(session_jwt);
      clearAllCookies();
      clearLocalStorage();
    }
  };

  const handleSelectOrganization = (organizationId: string) => {
    router.push({
      pathname: `/organizations/${organizationId}`,
    });
  };

  const isProduction =
    organizationPermission?.organization?.type ===
    accounts.v1.OrganizationType.CUSTOMER_PRODUCTION;

  const organizationIconName = isProduction ? 'production' : 'staging';
  const backgroundColor = isProduction
    ? theme.colors.main.light.light
    : theme.colors.ui.card;

  return (
    <Flexbox
      data-testid='navigation-rail'
      direction='column'
      justifyContent='flex-start'
      alignItems='center'
      backgroundColor={backgroundColor}
      borderType='borderRight'
      width={navigationRailWidth}
      py={1.25}
    >
      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='center'
        borderType='borderBottom'
        height='auto'
        gap={1.75}
        pb={1.25}
      >
        <Icon
          name='morf-logo'
          size={2}
          stroke={theme.colors.main.primary.dark}
        />
        <Tooltip
          tooltipText='Manage or switch Organization'
          tooltipDirection='right'
          isActive={!organizationMenu}
        >
          <Container ref={organizationMenuRef} cursor='pointer'>
            <Icon
              name={organizationIconName}
              onClick={handleToggleOrganizationMenu}
              size={1.75}
              stroke='none'
            />
            {organizationMenu && (
              <OrganizationMenu
                onClose={handleCloseOrganizationMenu}
                onSelectOrganization={handleSelectOrganization}
                organizationId={organizationId}
                organizationPermissions={organizationPermissions}
              />
            )}
          </Container>
        </Tooltip>
      </Flexbox>

      <NavigationItems organizationId={organizationId} />

      <Flexbox
        direction='column'
        justifyContent='flex-end'
        alignItems='center'
        gap={0.25}
      >
        <SpotlightSearchIcon setShowSpotlightSearch={setShowSpotlightSearch} />
        <DocumentationIcon />
        <UserMenu onLogout={handleLogout} userImage={userImage} />
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedNavigationRail = memo(NavigationRail);
