"use strict";

import * as $protobuf from "protobufjs/minimal";
import { healthie as healthie$1 } from "../../../healthie/v1/healthie_ts_proto";
import { workflow_parameters as workflow_parameters$1 } from "../../../workflow_parameters/v1/workflow_parameters_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.fetchactions = (() => {
  const fetchactions = {};
  fetchactions.healthie = (() => {
    const healthie = {};
    healthie.v2 = (() => {
      const v2 = {};
      v2.FetchAction = (() => {
        class FetchAction {
          get type() {
            for (const key of ["createOrUpdatePatient"]) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set type(name) {
            for (const key of ["createOrUpdatePatient"]) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new FetchAction(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.createOrUpdatePatient != null && Object.hasOwnProperty.call(message, "createOrUpdatePatient")) {
              $root.fetchactions.healthie.v2.CreateOrUpdatePatient.encode(message.createOrUpdatePatient, writer.uint32(10).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new FetchAction();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.createOrUpdatePatient = $root.fetchactions.healthie.v2.CreateOrUpdatePatient.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v2.FetchAction) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v2.FetchAction();
            if (object.createOrUpdatePatient != null) {
              if (typeof object.createOrUpdatePatient !== "object") {
                throw new TypeError(".fetchactions.healthie.v2.FetchAction.createOrUpdatePatient: object expected, but got " + (typeof object.createOrUpdatePatient));
              }
              message.createOrUpdatePatient = $root.fetchactions.healthie.v2.CreateOrUpdatePatient.fromObject(object.createOrUpdatePatient);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.createOrUpdatePatient != null && message.hasOwnProperty("createOrUpdatePatient")) {
              object.createOrUpdatePatient = $root.fetchactions.healthie.v2.CreateOrUpdatePatient.toObject(message.createOrUpdatePatient, options);
              if (options.oneofs) {
                object.type = "createOrUpdatePatient";
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v2.FetchAction";
          }
        }

        FetchAction.prototype.createOrUpdatePatient = null;

        return FetchAction;
      })();

      v2.CreateOrUpdatePatient = (() => {
        class CreateOrUpdatePatient {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateOrUpdatePatient(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.doNotAssociateThirdPartyId != null && Object.hasOwnProperty.call(message, "doNotAssociateThirdPartyId")) {
              writer.uint32(24).bool(message.doNotAssociateThirdPartyId);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              healthie$1.v1.User.encode(message.result, writer.uint32(34).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateOrUpdatePatient();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.doNotAssociateThirdPartyId = reader.bool();
                  break;
                }
                case 4: {
                  message.result = healthie$1.v1.User.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v2.CreateOrUpdatePatient) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v2.CreateOrUpdatePatient();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v2.CreateOrUpdatePatient.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v2.CreateOrUpdatePatient.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v2.CreateOrUpdatePatient.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v2.CreateOrUpdatePatient.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.doNotAssociateThirdPartyId != null) {
              message.doNotAssociateThirdPartyId = Boolean(object.doNotAssociateThirdPartyId);
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v2.CreateOrUpdatePatient.result: object expected, but got " + (typeof object.result));
              }
              message.result = healthie$1.v1.User.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.doNotAssociateThirdPartyId = false;
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.doNotAssociateThirdPartyId != null && message.hasOwnProperty("doNotAssociateThirdPartyId")) {
              object.doNotAssociateThirdPartyId = message.doNotAssociateThirdPartyId;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = healthie$1.v1.User.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v2.CreateOrUpdatePatient";
          }
        }

        CreateOrUpdatePatient.prototype.params = $util.emptyArray;
        CreateOrUpdatePatient.prototype.configs = $util.emptyArray;
        CreateOrUpdatePatient.prototype.doNotAssociateThirdPartyId = false;
        CreateOrUpdatePatient.prototype.result = null;

        return CreateOrUpdatePatient;
      })();

      return v2;
    })();

    return healthie;
  })();

  return fetchactions;
})();

export const fetchactions = $root.fetchactions;
