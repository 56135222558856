import { CustomNodeType, NodeType } from './types';

export const iconNameNodeTypeMap: Partial<{
  [key in CustomNodeType]: string;
}> = {
  [NodeType.FilterNode]: 'filter-lines',
  [NodeType.PauseNode]: 'wait',
  [NodeType.ProfileLookupNode]: 'patient-lookup',
  [NodeType.RestartNode]: 'refresh-circle',
  [NodeType.FetchActionNode]: 'arrow-path-squared',
  [NodeType.DestinationActionNode]: 'destination',
};
