"use strict";

import * as $protobuf from "protobufjs/minimal";
import { google as google$1 } from "../../empty_ts_proto";
import { timing as timing$1 } from "../../timing/v1/timing_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.subscription_topics = (() => {
  const subscription_topics = {};
  subscription_topics.v1 = (() => {
    const v1 = {};
    v1.SubscriptionTopic = (() => {
      class SubscriptionTopic {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SubscriptionTopic(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(18).string(message.name);
          }
          if (message.description != null && Object.hasOwnProperty.call(message, "description")) {
            writer.uint32(26).string(message.description);
          }
          if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt")) {
            timing$1.v1.Timestamp.encode(message.createdAt, writer.uint32(34).fork()).ldelim();
          }
          if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt")) {
            timing$1.v1.Timestamp.encode(message.updatedAt, writer.uint32(42).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SubscriptionTopic();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              case 4: {
                message.createdAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.updatedAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.subscription_topics.v1.SubscriptionTopic) {
            return object;
          }
          const message = new $root.subscription_topics.v1.SubscriptionTopic();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== "object") {
              throw new TypeError(".subscription_topics.v1.SubscriptionTopic.createdAt: object expected, but got " + (typeof object.createdAt));
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(object.createdAt);
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== "object") {
              throw new TypeError(".subscription_topics.v1.SubscriptionTopic.updatedAt: object expected, but got " + (typeof object.updatedAt));
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(object.updatedAt);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
            object.name = "";
            object.createdAt = null;
            object.updatedAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.description != null && message.hasOwnProperty("description")) {
            object.description = message.description;
          }
          if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
            object.createdAt = timing$1.v1.Timestamp.toObject(message.createdAt, options);
          }
          if (message.updatedAt != null && message.hasOwnProperty("updatedAt")) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(message.updatedAt, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "subscription_topics.v1.SubscriptionTopic";
        }
      }

      SubscriptionTopic.prototype.id = "";
      SubscriptionTopic.prototype.name = "";
      SubscriptionTopic.prototype.description = null;
      SubscriptionTopic.prototype.createdAt = null;
      SubscriptionTopic.prototype.updatedAt = null;

      return SubscriptionTopic;
    })();

    v1.SubscriptionTopicPreferences = (() => {
      class SubscriptionTopicPreferences {
        constructor(properties) {
          this.subscriptionTopicPreferences = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SubscriptionTopicPreferences(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscriptionTopicPreferences != null && Object.hasOwnProperty.call(message, "subscriptionTopicPreferences")) {
            for (const element of message.subscriptionTopicPreferences) {
              $root.subscription_topics.v1.SubscriptionTopicPreference.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          if (message.globalSubscriptionPreferences != null && Object.hasOwnProperty.call(message, "globalSubscriptionPreferences")) {
            $root.subscription_topics.v1.MediaTypeSubscriptionPreferences.encode(message.globalSubscriptionPreferences, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SubscriptionTopicPreferences();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.subscriptionTopicPreferences || !message.subscriptionTopicPreferences.length) {
                  message.subscriptionTopicPreferences = [];
                }
                message.subscriptionTopicPreferences.push($root.subscription_topics.v1.SubscriptionTopicPreference.decode(reader, reader.uint32()));
                break;
              }
              case 2: {
                message.globalSubscriptionPreferences = $root.subscription_topics.v1.MediaTypeSubscriptionPreferences.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.subscription_topics.v1.SubscriptionTopicPreferences) {
            return object;
          }
          const message = new $root.subscription_topics.v1.SubscriptionTopicPreferences();
          if (object.subscriptionTopicPreferences) {
            if (!Array.isArray(object.subscriptionTopicPreferences)) {
              throw new TypeError(".subscription_topics.v1.SubscriptionTopicPreferences.subscriptionTopicPreferences: array type expected, but got " + (typeof object.subscriptionTopicPreferences))
            }
            message.subscriptionTopicPreferences = new Array(object.subscriptionTopicPreferences.length);
            for (let i = 0; i < object.subscriptionTopicPreferences.length; ++i) {
              if (typeof object.subscriptionTopicPreferences[i] !== "object") {
                throw new TypeError(".subscription_topics.v1.SubscriptionTopicPreferences.subscriptionTopicPreferences: object expected, but got " + (typeof object.subscriptionTopicPreferences[i]));
              }
              message.subscriptionTopicPreferences[i] = $root.subscription_topics.v1.SubscriptionTopicPreference.fromObject(object.subscriptionTopicPreferences[i]);
            }
          }
          if (object.globalSubscriptionPreferences != null) {
            if (typeof object.globalSubscriptionPreferences !== "object") {
              throw new TypeError(".subscription_topics.v1.SubscriptionTopicPreferences.globalSubscriptionPreferences: object expected, but got " + (typeof object.globalSubscriptionPreferences));
            }
            message.globalSubscriptionPreferences = $root.subscription_topics.v1.MediaTypeSubscriptionPreferences.fromObject(object.globalSubscriptionPreferences);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.subscriptionTopicPreferences = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.globalSubscriptionPreferences = null;
          }
          let keys;
          if (message.subscriptionTopicPreferences && message.subscriptionTopicPreferences.length) {
            object.subscriptionTopicPreferences = new Array(message.subscriptionTopicPreferences.length);
            for (let i = 0; i < message.subscriptionTopicPreferences.length; ++i) {
              object.subscriptionTopicPreferences[i] = $root.subscription_topics.v1.SubscriptionTopicPreference.toObject(message.subscriptionTopicPreferences[i], options);
            }
          }
          if (message.globalSubscriptionPreferences != null && message.hasOwnProperty("globalSubscriptionPreferences")) {
            object.globalSubscriptionPreferences = $root.subscription_topics.v1.MediaTypeSubscriptionPreferences.toObject(message.globalSubscriptionPreferences, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "subscription_topics.v1.SubscriptionTopicPreferences";
        }
      }

      SubscriptionTopicPreferences.prototype.subscriptionTopicPreferences = $util.emptyArray;
      SubscriptionTopicPreferences.prototype.globalSubscriptionPreferences = null;

      return SubscriptionTopicPreferences;
    })();

    v1.SubscriptionTopicPreference = (() => {
      class SubscriptionTopicPreference {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SubscriptionTopicPreference(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.topic != null && Object.hasOwnProperty.call(message, "topic")) {
            $root.subscription_topics.v1.SubscriptionTopic.encode(message.topic, writer.uint32(10).fork()).ldelim();
          }
          if (message.mediaTypeSubscriptionPreferences != null && Object.hasOwnProperty.call(message, "mediaTypeSubscriptionPreferences")) {
            $root.subscription_topics.v1.MediaTypeSubscriptionPreferences.encode(message.mediaTypeSubscriptionPreferences, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SubscriptionTopicPreference();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.topic = $root.subscription_topics.v1.SubscriptionTopic.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.mediaTypeSubscriptionPreferences = $root.subscription_topics.v1.MediaTypeSubscriptionPreferences.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.subscription_topics.v1.SubscriptionTopicPreference) {
            return object;
          }
          const message = new $root.subscription_topics.v1.SubscriptionTopicPreference();
          if (object.topic != null) {
            if (typeof object.topic !== "object") {
              throw new TypeError(".subscription_topics.v1.SubscriptionTopicPreference.topic: object expected, but got " + (typeof object.topic));
            }
            message.topic = $root.subscription_topics.v1.SubscriptionTopic.fromObject(object.topic);
          }
          if (object.mediaTypeSubscriptionPreferences != null) {
            if (typeof object.mediaTypeSubscriptionPreferences !== "object") {
              throw new TypeError(".subscription_topics.v1.SubscriptionTopicPreference.mediaTypeSubscriptionPreferences: object expected, but got " + (typeof object.mediaTypeSubscriptionPreferences));
            }
            message.mediaTypeSubscriptionPreferences = $root.subscription_topics.v1.MediaTypeSubscriptionPreferences.fromObject(object.mediaTypeSubscriptionPreferences);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.topic = null;
            object.mediaTypeSubscriptionPreferences = null;
          }
          let keys;
          if (message.topic != null && message.hasOwnProperty("topic")) {
            object.topic = $root.subscription_topics.v1.SubscriptionTopic.toObject(message.topic, options);
          }
          if (message.mediaTypeSubscriptionPreferences != null && message.hasOwnProperty("mediaTypeSubscriptionPreferences")) {
            object.mediaTypeSubscriptionPreferences = $root.subscription_topics.v1.MediaTypeSubscriptionPreferences.toObject(message.mediaTypeSubscriptionPreferences, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "subscription_topics.v1.SubscriptionTopicPreference";
        }
      }

      SubscriptionTopicPreference.prototype.topic = null;
      SubscriptionTopicPreference.prototype.mediaTypeSubscriptionPreferences = null;

      return SubscriptionTopicPreference;
    })();

    v1.MediaTypeSubscriptionPreferences = (() => {
      class MediaTypeSubscriptionPreferences {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MediaTypeSubscriptionPreferences(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.emailSubscribed != null && Object.hasOwnProperty.call(message, "emailSubscribed")) {
            writer.uint32(8).bool(message.emailSubscribed);
          }
          if (message.smsSubscribed != null && Object.hasOwnProperty.call(message, "smsSubscribed")) {
            writer.uint32(16).bool(message.smsSubscribed);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MediaTypeSubscriptionPreferences();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.emailSubscribed = reader.bool();
                break;
              }
              case 2: {
                message.smsSubscribed = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.subscription_topics.v1.MediaTypeSubscriptionPreferences) {
            return object;
          }
          const message = new $root.subscription_topics.v1.MediaTypeSubscriptionPreferences();
          if (object.emailSubscribed != null) {
            message.emailSubscribed = Boolean(object.emailSubscribed);
          }
          if (object.smsSubscribed != null) {
            message.smsSubscribed = Boolean(object.smsSubscribed);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.emailSubscribed = false;
            object.smsSubscribed = false;
          }
          let keys;
          if (message.emailSubscribed != null && message.hasOwnProperty("emailSubscribed")) {
            object.emailSubscribed = message.emailSubscribed;
          }
          if (message.smsSubscribed != null && message.hasOwnProperty("smsSubscribed")) {
            object.smsSubscribed = message.smsSubscribed;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "subscription_topics.v1.MediaTypeSubscriptionPreferences";
        }
      }

      MediaTypeSubscriptionPreferences.prototype.emailSubscribed = false;
      MediaTypeSubscriptionPreferences.prototype.smsSubscribed = false;

      return MediaTypeSubscriptionPreferences;
    })();

    v1.FieldMediaType = (() => {
      class FieldMediaType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FieldMediaType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.mapping != null && Object.hasOwnProperty.call(message, "mapping")) {
            writer.uint32(8).int32(message.mapping);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FieldMediaType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.mapping = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.subscription_topics.v1.FieldMediaType) {
            return object;
          }
          const message = new $root.subscription_topics.v1.FieldMediaType();
          if (object.mapping != null) {
            switch (object.mapping) {
              case "UNSPECIFIED_MEDIA_TYPE":
              case 0: {
                message.mapping = 0;
                break;
              }
              case "EMAIL":
              case 1: {
                message.mapping = 1;
                break;
              }
              case "SMS":
              case 2: {
                message.mapping = 2;
                break;
              }
              default: {
                if (typeof object.mapping == "number") {
                  message.mapping = object.mapping;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.mapping = options.enums === String ? "UNSPECIFIED_MEDIA_TYPE" : 0
          }
          let keys;
          if (message.mapping != null && message.hasOwnProperty("mapping")) {
            object.mapping = options.enums === String ? $root.subscription_topics.v1.MediaType[message.mapping] === undefined ? message.mapping : $root.subscription_topics.v1.MediaType[message.mapping] : message.mapping;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "subscription_topics.v1.FieldMediaType";
        }
      }

      FieldMediaType.prototype.mapping = 0;

      return FieldMediaType;
    })();

    v1.CreateSubscriptionTopicRequest = (() => {
      class CreateSubscriptionTopicRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateSubscriptionTopicRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(10).string(message.name);
          }
          if (message.description != null && Object.hasOwnProperty.call(message, "description")) {
            writer.uint32(18).string(message.description);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateSubscriptionTopicRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.description = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.subscription_topics.v1.CreateSubscriptionTopicRequest) {
            return object;
          }
          const message = new $root.subscription_topics.v1.CreateSubscriptionTopicRequest();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = "";
          }
          let keys;
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.description != null && message.hasOwnProperty("description")) {
            object.description = message.description;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "subscription_topics.v1.CreateSubscriptionTopicRequest";
        }
      }

      CreateSubscriptionTopicRequest.prototype.name = "";
      CreateSubscriptionTopicRequest.prototype.description = null;

      return CreateSubscriptionTopicRequest;
    })();

    v1.CreateSubscriptionTopicResponse = (() => {
      class CreateSubscriptionTopicResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateSubscriptionTopicResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.topic != null && Object.hasOwnProperty.call(message, "topic")) {
            $root.subscription_topics.v1.SubscriptionTopic.encode(message.topic, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateSubscriptionTopicResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.topic = $root.subscription_topics.v1.SubscriptionTopic.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.subscription_topics.v1.CreateSubscriptionTopicResponse) {
            return object;
          }
          const message = new $root.subscription_topics.v1.CreateSubscriptionTopicResponse();
          if (object.topic != null) {
            if (typeof object.topic !== "object") {
              throw new TypeError(".subscription_topics.v1.CreateSubscriptionTopicResponse.topic: object expected, but got " + (typeof object.topic));
            }
            message.topic = $root.subscription_topics.v1.SubscriptionTopic.fromObject(object.topic);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.topic = null;
          }
          let keys;
          if (message.topic != null && message.hasOwnProperty("topic")) {
            object.topic = $root.subscription_topics.v1.SubscriptionTopic.toObject(message.topic, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "subscription_topics.v1.CreateSubscriptionTopicResponse";
        }
      }

      CreateSubscriptionTopicResponse.prototype.topic = null;

      return CreateSubscriptionTopicResponse;
    })();

    v1.UpdateSubscriptionTopicRequest = (() => {
      class UpdateSubscriptionTopicRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateSubscriptionTopicRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(18).string(message.name);
          }
          if (message.description != null && Object.hasOwnProperty.call(message, "description")) {
            writer.uint32(26).string(message.description);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateSubscriptionTopicRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.subscription_topics.v1.UpdateSubscriptionTopicRequest) {
            return object;
          }
          const message = new $root.subscription_topics.v1.UpdateSubscriptionTopicRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.description != null && message.hasOwnProperty("description")) {
            object.description = message.description;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "subscription_topics.v1.UpdateSubscriptionTopicRequest";
        }
      }

      UpdateSubscriptionTopicRequest.prototype.id = "";
      UpdateSubscriptionTopicRequest.prototype.name = null;
      UpdateSubscriptionTopicRequest.prototype.description = null;

      return UpdateSubscriptionTopicRequest;
    })();

    v1.UpdateSubscriptionTopicResponse = (() => {
      class UpdateSubscriptionTopicResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateSubscriptionTopicResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.topic != null && Object.hasOwnProperty.call(message, "topic")) {
            $root.subscription_topics.v1.SubscriptionTopic.encode(message.topic, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateSubscriptionTopicResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.topic = $root.subscription_topics.v1.SubscriptionTopic.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.subscription_topics.v1.UpdateSubscriptionTopicResponse) {
            return object;
          }
          const message = new $root.subscription_topics.v1.UpdateSubscriptionTopicResponse();
          if (object.topic != null) {
            if (typeof object.topic !== "object") {
              throw new TypeError(".subscription_topics.v1.UpdateSubscriptionTopicResponse.topic: object expected, but got " + (typeof object.topic));
            }
            message.topic = $root.subscription_topics.v1.SubscriptionTopic.fromObject(object.topic);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.topic = null;
          }
          let keys;
          if (message.topic != null && message.hasOwnProperty("topic")) {
            object.topic = $root.subscription_topics.v1.SubscriptionTopic.toObject(message.topic, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "subscription_topics.v1.UpdateSubscriptionTopicResponse";
        }
      }

      UpdateSubscriptionTopicResponse.prototype.topic = null;

      return UpdateSubscriptionTopicResponse;
    })();

    v1.DeleteSubscriptionTopicRequest = (() => {
      class DeleteSubscriptionTopicRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DeleteSubscriptionTopicRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DeleteSubscriptionTopicRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.subscription_topics.v1.DeleteSubscriptionTopicRequest) {
            return object;
          }
          const message = new $root.subscription_topics.v1.DeleteSubscriptionTopicRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "subscription_topics.v1.DeleteSubscriptionTopicRequest";
        }
      }

      DeleteSubscriptionTopicRequest.prototype.id = "";

      return DeleteSubscriptionTopicRequest;
    })();

    v1.DeleteSubscriptionTopicResponse = (() => {
      class DeleteSubscriptionTopicResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DeleteSubscriptionTopicResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.topic != null && Object.hasOwnProperty.call(message, "topic")) {
            $root.subscription_topics.v1.SubscriptionTopic.encode(message.topic, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DeleteSubscriptionTopicResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.topic = $root.subscription_topics.v1.SubscriptionTopic.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.subscription_topics.v1.DeleteSubscriptionTopicResponse) {
            return object;
          }
          const message = new $root.subscription_topics.v1.DeleteSubscriptionTopicResponse();
          if (object.topic != null) {
            if (typeof object.topic !== "object") {
              throw new TypeError(".subscription_topics.v1.DeleteSubscriptionTopicResponse.topic: object expected, but got " + (typeof object.topic));
            }
            message.topic = $root.subscription_topics.v1.SubscriptionTopic.fromObject(object.topic);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.topic = null;
          }
          let keys;
          if (message.topic != null && message.hasOwnProperty("topic")) {
            object.topic = $root.subscription_topics.v1.SubscriptionTopic.toObject(message.topic, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "subscription_topics.v1.DeleteSubscriptionTopicResponse";
        }
      }

      DeleteSubscriptionTopicResponse.prototype.topic = null;

      return DeleteSubscriptionTopicResponse;
    })();

    v1.ListSubscriptionTopicsRequest = (() => {
      class ListSubscriptionTopicsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListSubscriptionTopicsRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.stub != null && Object.hasOwnProperty.call(message, "stub")) {
            google$1.protobuf.Empty.encode(message.stub, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListSubscriptionTopicsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stub = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.subscription_topics.v1.ListSubscriptionTopicsRequest) {
            return object;
          }
          const message = new $root.subscription_topics.v1.ListSubscriptionTopicsRequest();
          if (object.stub != null) {
            if (typeof object.stub !== "object") {
              throw new TypeError(".subscription_topics.v1.ListSubscriptionTopicsRequest.stub: object expected, but got " + (typeof object.stub));
            }
            message.stub = google$1.protobuf.Empty.fromObject(object.stub);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.stub = null;
          }
          let keys;
          if (message.stub != null && message.hasOwnProperty("stub")) {
            object.stub = google$1.protobuf.Empty.toObject(message.stub, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "subscription_topics.v1.ListSubscriptionTopicsRequest";
        }
      }

      ListSubscriptionTopicsRequest.prototype.stub = null;

      return ListSubscriptionTopicsRequest;
    })();

    v1.ListSubscriptionTopicsResponse = (() => {
      class ListSubscriptionTopicsResponse {
        constructor(properties) {
          this.topics = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListSubscriptionTopicsResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.topics != null && Object.hasOwnProperty.call(message, "topics")) {
            for (const element of message.topics) {
              $root.subscription_topics.v1.SubscriptionTopic.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListSubscriptionTopicsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.topics || !message.topics.length) {
                  message.topics = [];
                }
                message.topics.push($root.subscription_topics.v1.SubscriptionTopic.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.subscription_topics.v1.ListSubscriptionTopicsResponse) {
            return object;
          }
          const message = new $root.subscription_topics.v1.ListSubscriptionTopicsResponse();
          if (object.topics) {
            if (!Array.isArray(object.topics)) {
              throw new TypeError(".subscription_topics.v1.ListSubscriptionTopicsResponse.topics: array type expected, but got " + (typeof object.topics))
            }
            message.topics = new Array(object.topics.length);
            for (let i = 0; i < object.topics.length; ++i) {
              if (typeof object.topics[i] !== "object") {
                throw new TypeError(".subscription_topics.v1.ListSubscriptionTopicsResponse.topics: object expected, but got " + (typeof object.topics[i]));
              }
              message.topics[i] = $root.subscription_topics.v1.SubscriptionTopic.fromObject(object.topics[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.topics = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.topics && message.topics.length) {
            object.topics = new Array(message.topics.length);
            for (let i = 0; i < message.topics.length; ++i) {
              object.topics[i] = $root.subscription_topics.v1.SubscriptionTopic.toObject(message.topics[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "subscription_topics.v1.ListSubscriptionTopicsResponse";
        }
      }

      ListSubscriptionTopicsResponse.prototype.topics = $util.emptyArray;

      return ListSubscriptionTopicsResponse;
    })();

    v1.GetSubscriptionTopicRequest = (() => {
      class GetSubscriptionTopicRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetSubscriptionTopicRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetSubscriptionTopicRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.subscription_topics.v1.GetSubscriptionTopicRequest) {
            return object;
          }
          const message = new $root.subscription_topics.v1.GetSubscriptionTopicRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "subscription_topics.v1.GetSubscriptionTopicRequest";
        }
      }

      GetSubscriptionTopicRequest.prototype.id = "";

      return GetSubscriptionTopicRequest;
    })();

    v1.GetSubscriptionTopicResponse = (() => {
      class GetSubscriptionTopicResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetSubscriptionTopicResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.topic != null && Object.hasOwnProperty.call(message, "topic")) {
            $root.subscription_topics.v1.SubscriptionTopic.encode(message.topic, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetSubscriptionTopicResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.topic = $root.subscription_topics.v1.SubscriptionTopic.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.subscription_topics.v1.GetSubscriptionTopicResponse) {
            return object;
          }
          const message = new $root.subscription_topics.v1.GetSubscriptionTopicResponse();
          if (object.topic != null) {
            if (typeof object.topic !== "object") {
              throw new TypeError(".subscription_topics.v1.GetSubscriptionTopicResponse.topic: object expected, but got " + (typeof object.topic));
            }
            message.topic = $root.subscription_topics.v1.SubscriptionTopic.fromObject(object.topic);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.topic = null;
          }
          let keys;
          if (message.topic != null && message.hasOwnProperty("topic")) {
            object.topic = $root.subscription_topics.v1.SubscriptionTopic.toObject(message.topic, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "subscription_topics.v1.GetSubscriptionTopicResponse";
        }
      }

      GetSubscriptionTopicResponse.prototype.topic = null;

      return GetSubscriptionTopicResponse;
    })();

    v1.GetProfileSubscriptionTopicPreferencesRequest = (() => {
      class GetProfileSubscriptionTopicPreferencesRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetProfileSubscriptionTopicPreferencesRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.token != null && Object.hasOwnProperty.call(message, "token")) {
            writer.uint32(10).string(message.token);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetProfileSubscriptionTopicPreferencesRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.token = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.subscription_topics.v1.GetProfileSubscriptionTopicPreferencesRequest) {
            return object;
          }
          const message = new $root.subscription_topics.v1.GetProfileSubscriptionTopicPreferencesRequest();
          if (object.token != null) {
            message.token = String(object.token);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.token = "";
          }
          let keys;
          if (message.token != null && message.hasOwnProperty("token")) {
            object.token = message.token;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "subscription_topics.v1.GetProfileSubscriptionTopicPreferencesRequest";
        }
      }

      GetProfileSubscriptionTopicPreferencesRequest.prototype.token = "";

      return GetProfileSubscriptionTopicPreferencesRequest;
    })();

    v1.GetProfileSubscriptionTopicPreferencesResponse = (() => {
      class GetProfileSubscriptionTopicPreferencesResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetProfileSubscriptionTopicPreferencesResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscriptionTopicPreferences != null && Object.hasOwnProperty.call(message, "subscriptionTopicPreferences")) {
            $root.subscription_topics.v1.SubscriptionTopicPreferences.encode(message.subscriptionTopicPreferences, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetProfileSubscriptionTopicPreferencesResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscriptionTopicPreferences = $root.subscription_topics.v1.SubscriptionTopicPreferences.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.subscription_topics.v1.GetProfileSubscriptionTopicPreferencesResponse) {
            return object;
          }
          const message = new $root.subscription_topics.v1.GetProfileSubscriptionTopicPreferencesResponse();
          if (object.subscriptionTopicPreferences != null) {
            if (typeof object.subscriptionTopicPreferences !== "object") {
              throw new TypeError(".subscription_topics.v1.GetProfileSubscriptionTopicPreferencesResponse.subscriptionTopicPreferences: object expected, but got " + (typeof object.subscriptionTopicPreferences));
            }
            message.subscriptionTopicPreferences = $root.subscription_topics.v1.SubscriptionTopicPreferences.fromObject(object.subscriptionTopicPreferences);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscriptionTopicPreferences = null;
          }
          let keys;
          if (message.subscriptionTopicPreferences != null && message.hasOwnProperty("subscriptionTopicPreferences")) {
            object.subscriptionTopicPreferences = $root.subscription_topics.v1.SubscriptionTopicPreferences.toObject(message.subscriptionTopicPreferences, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "subscription_topics.v1.GetProfileSubscriptionTopicPreferencesResponse";
        }
      }

      GetProfileSubscriptionTopicPreferencesResponse.prototype.subscriptionTopicPreferences = null;

      return GetProfileSubscriptionTopicPreferencesResponse;
    })();

    v1.UpdateProfileSubscriptionTopicPreferencesRequest = (() => {
      class UpdateProfileSubscriptionTopicPreferencesRequest {
        constructor(properties) {
          this.subscriptionTopicPreferences = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateProfileSubscriptionTopicPreferencesRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.token != null && Object.hasOwnProperty.call(message, "token")) {
            writer.uint32(10).string(message.token);
          }
          if (message.subscriptionTopicPreferences != null && Object.hasOwnProperty.call(message, "subscriptionTopicPreferences")) {
            for (const element of message.subscriptionTopicPreferences) {
              $root.subscription_topics.v1.SubscriptionTopicPreferenceRequest.encode(element, writer.uint32(18).fork()).ldelim();
            }
          }
          if (message.globalSubscriptionPreferences != null && Object.hasOwnProperty.call(message, "globalSubscriptionPreferences")) {
            $root.subscription_topics.v1.MediaTypeSubscriptionPreferences.encode(message.globalSubscriptionPreferences, writer.uint32(26).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateProfileSubscriptionTopicPreferencesRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.token = reader.string();
                break;
              }
              case 2: {
                if (!message.subscriptionTopicPreferences || !message.subscriptionTopicPreferences.length) {
                  message.subscriptionTopicPreferences = [];
                }
                message.subscriptionTopicPreferences.push($root.subscription_topics.v1.SubscriptionTopicPreferenceRequest.decode(reader, reader.uint32()));
                break;
              }
              case 3: {
                message.globalSubscriptionPreferences = $root.subscription_topics.v1.MediaTypeSubscriptionPreferences.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.subscription_topics.v1.UpdateProfileSubscriptionTopicPreferencesRequest) {
            return object;
          }
          const message = new $root.subscription_topics.v1.UpdateProfileSubscriptionTopicPreferencesRequest();
          if (object.token != null) {
            message.token = String(object.token);
          }
          if (object.subscriptionTopicPreferences) {
            if (!Array.isArray(object.subscriptionTopicPreferences)) {
              throw new TypeError(".subscription_topics.v1.UpdateProfileSubscriptionTopicPreferencesRequest.subscriptionTopicPreferences: array type expected, but got " + (typeof object.subscriptionTopicPreferences))
            }
            message.subscriptionTopicPreferences = new Array(object.subscriptionTopicPreferences.length);
            for (let i = 0; i < object.subscriptionTopicPreferences.length; ++i) {
              if (typeof object.subscriptionTopicPreferences[i] !== "object") {
                throw new TypeError(".subscription_topics.v1.UpdateProfileSubscriptionTopicPreferencesRequest.subscriptionTopicPreferences: object expected, but got " + (typeof object.subscriptionTopicPreferences[i]));
              }
              message.subscriptionTopicPreferences[i] = $root.subscription_topics.v1.SubscriptionTopicPreferenceRequest.fromObject(object.subscriptionTopicPreferences[i]);
            }
          }
          if (object.globalSubscriptionPreferences != null) {
            if (typeof object.globalSubscriptionPreferences !== "object") {
              throw new TypeError(".subscription_topics.v1.UpdateProfileSubscriptionTopicPreferencesRequest.globalSubscriptionPreferences: object expected, but got " + (typeof object.globalSubscriptionPreferences));
            }
            message.globalSubscriptionPreferences = $root.subscription_topics.v1.MediaTypeSubscriptionPreferences.fromObject(object.globalSubscriptionPreferences);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.subscriptionTopicPreferences = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.token = "";
            object.globalSubscriptionPreferences = null;
          }
          let keys;
          if (message.token != null && message.hasOwnProperty("token")) {
            object.token = message.token;
          }
          if (message.subscriptionTopicPreferences && message.subscriptionTopicPreferences.length) {
            object.subscriptionTopicPreferences = new Array(message.subscriptionTopicPreferences.length);
            for (let i = 0; i < message.subscriptionTopicPreferences.length; ++i) {
              object.subscriptionTopicPreferences[i] = $root.subscription_topics.v1.SubscriptionTopicPreferenceRequest.toObject(message.subscriptionTopicPreferences[i], options);
            }
          }
          if (message.globalSubscriptionPreferences != null && message.hasOwnProperty("globalSubscriptionPreferences")) {
            object.globalSubscriptionPreferences = $root.subscription_topics.v1.MediaTypeSubscriptionPreferences.toObject(message.globalSubscriptionPreferences, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "subscription_topics.v1.UpdateProfileSubscriptionTopicPreferencesRequest";
        }
      }

      UpdateProfileSubscriptionTopicPreferencesRequest.prototype.token = "";
      UpdateProfileSubscriptionTopicPreferencesRequest.prototype.subscriptionTopicPreferences = $util.emptyArray;
      UpdateProfileSubscriptionTopicPreferencesRequest.prototype.globalSubscriptionPreferences = null;

      return UpdateProfileSubscriptionTopicPreferencesRequest;
    })();

    v1.SubscriptionTopicPreferenceRequest = (() => {
      class SubscriptionTopicPreferenceRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SubscriptionTopicPreferenceRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.topicId != null && Object.hasOwnProperty.call(message, "topicId")) {
            writer.uint32(10).string(message.topicId);
          }
          if (message.mediaTypeSubscriptionPreferences != null && Object.hasOwnProperty.call(message, "mediaTypeSubscriptionPreferences")) {
            $root.subscription_topics.v1.MediaTypeSubscriptionPreferences.encode(message.mediaTypeSubscriptionPreferences, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SubscriptionTopicPreferenceRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.topicId = reader.string();
                break;
              }
              case 2: {
                message.mediaTypeSubscriptionPreferences = $root.subscription_topics.v1.MediaTypeSubscriptionPreferences.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.subscription_topics.v1.SubscriptionTopicPreferenceRequest) {
            return object;
          }
          const message = new $root.subscription_topics.v1.SubscriptionTopicPreferenceRequest();
          if (object.topicId != null) {
            message.topicId = String(object.topicId);
          }
          if (object.mediaTypeSubscriptionPreferences != null) {
            if (typeof object.mediaTypeSubscriptionPreferences !== "object") {
              throw new TypeError(".subscription_topics.v1.SubscriptionTopicPreferenceRequest.mediaTypeSubscriptionPreferences: object expected, but got " + (typeof object.mediaTypeSubscriptionPreferences));
            }
            message.mediaTypeSubscriptionPreferences = $root.subscription_topics.v1.MediaTypeSubscriptionPreferences.fromObject(object.mediaTypeSubscriptionPreferences);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.topicId = "";
            object.mediaTypeSubscriptionPreferences = null;
          }
          let keys;
          if (message.topicId != null && message.hasOwnProperty("topicId")) {
            object.topicId = message.topicId;
          }
          if (message.mediaTypeSubscriptionPreferences != null && message.hasOwnProperty("mediaTypeSubscriptionPreferences")) {
            object.mediaTypeSubscriptionPreferences = $root.subscription_topics.v1.MediaTypeSubscriptionPreferences.toObject(message.mediaTypeSubscriptionPreferences, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "subscription_topics.v1.SubscriptionTopicPreferenceRequest";
        }
      }

      SubscriptionTopicPreferenceRequest.prototype.topicId = "";
      SubscriptionTopicPreferenceRequest.prototype.mediaTypeSubscriptionPreferences = null;

      return SubscriptionTopicPreferenceRequest;
    })();

    v1.UpdateProfileSubscriptionTopicPreferencesResponse = (() => {
      class UpdateProfileSubscriptionTopicPreferencesResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateProfileSubscriptionTopicPreferencesResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscriptionTopicPreferences != null && Object.hasOwnProperty.call(message, "subscriptionTopicPreferences")) {
            $root.subscription_topics.v1.SubscriptionTopicPreferences.encode(message.subscriptionTopicPreferences, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateProfileSubscriptionTopicPreferencesResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscriptionTopicPreferences = $root.subscription_topics.v1.SubscriptionTopicPreferences.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.subscription_topics.v1.UpdateProfileSubscriptionTopicPreferencesResponse) {
            return object;
          }
          const message = new $root.subscription_topics.v1.UpdateProfileSubscriptionTopicPreferencesResponse();
          if (object.subscriptionTopicPreferences != null) {
            if (typeof object.subscriptionTopicPreferences !== "object") {
              throw new TypeError(".subscription_topics.v1.UpdateProfileSubscriptionTopicPreferencesResponse.subscriptionTopicPreferences: object expected, but got " + (typeof object.subscriptionTopicPreferences));
            }
            message.subscriptionTopicPreferences = $root.subscription_topics.v1.SubscriptionTopicPreferences.fromObject(object.subscriptionTopicPreferences);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscriptionTopicPreferences = null;
          }
          let keys;
          if (message.subscriptionTopicPreferences != null && message.hasOwnProperty("subscriptionTopicPreferences")) {
            object.subscriptionTopicPreferences = $root.subscription_topics.v1.SubscriptionTopicPreferences.toObject(message.subscriptionTopicPreferences, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "subscription_topics.v1.UpdateProfileSubscriptionTopicPreferencesResponse";
        }
      }

      UpdateProfileSubscriptionTopicPreferencesResponse.prototype.subscriptionTopicPreferences = null;

      return UpdateProfileSubscriptionTopicPreferencesResponse;
    })();

    v1.MediaType = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED_MEDIA_TYPE"] = 0;
      values[valuesById[1] = "EMAIL"] = 1;
      values[valuesById[2] = "SMS"] = 2;
      return values;
    })();

    return v1;
  })();

  return subscription_topics;
})();

export const subscription_topics = $root.subscription_topics;
