import { NodeType } from '../../../types';
import { newNodeData } from './types';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';
import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';

export const addPauseNode = (isBranch: boolean): newNodeData => {
  return {
    configuration: new workflows.v1.WorkflowNode({
      pause: new workflows.v1.WorkflowPause({
        timestamps:
          new workflow_parameters.v1.DestinationActionParameterSources({
            sources: [],
          }),
        refreshEventPayload: false,
      }),
    }),
    isBranchNode: isBranch,
    name: 'Wait',
    type: NodeType.PauseNode,
  };
};
