export const Stripe = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1997_2652)'>
        <g clipPath='url(#clip1_1997_2652)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0 20C0 8.95431 8.95431 0 20 0V0C31.0457 0 40 8.95431 40 20V20C40 31.0457 31.0457 40 20 40V40C8.95431 40 0 31.0457 0 20V20Z'
            fill='#635BFF'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M18.4401 15.55C18.4401 14.61 19.2101 14.24 20.4901 14.24C22.3301 14.24 24.6501 14.8 26.4901 15.79V10.11C24.4801 9.31 22.5001 9 20.5001 9C15.5901 9 12.3301 11.56 12.3301 15.84C12.3301 22.51 21.5201 21.45 21.5201 24.33C21.5201 25.44 20.5501 25.8 19.2001 25.8C17.1901 25.8 14.6301 24.98 12.6001 23.87V29.62C14.8501 30.59 17.1201 31 19.2001 31C24.2301 31 27.6901 28.51 27.6901 24.18C27.6501 16.98 18.4401 18.26 18.4401 15.55Z'
            fill='white'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_1997_2652'>
          <rect width='40' height='40' fill='white' />
        </clipPath>
        <clipPath id='clip1_1997_2652'>
          <rect width='40' height='40' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
