import moment from 'moment-timezone';
import { TimestampType } from '../Timestamp';

export const formatTimestamp = (
  timestamp: TimestampType
): {
  fullTimestamp: string;
  fullTimestampWithoutSeconds: string;
  formattedTimestamp: string;
} => {
  const givenDate = moment.tz(timestamp.isoDateString, timestamp.timezone);
  const fullTimestamp = givenDate.format('MMM DD, YYYY [at] hh:mm:ss a z');
  const fullTimestampWithoutSeconds = givenDate.format(
    'MMM DD, YYYY [at] hh:mm a z'
  );

  const formattedTimestamp = givenDate.calendar(null, {
    sameDay: '[Today at] hh:mm:ss a z',
    lastDay: '[Yesterday at] hh:mm:ss a z',
    lastWeek: 'MMM DD, YYYY [at] hh:mm:ss a z',
    sameElse: 'MMM DD, YYYY [at] hh:mm:ss a z',
  });

  return { fullTimestamp, fullTimestampWithoutSeconds, formattedTimestamp };
};
