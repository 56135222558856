import { destinationactions as activecampaignDestinationActions } from '@morf/proto/destinationactions/activecampaign/v1/activecampaign_ts_proto';
import { destinationactions as customerIoDestinationActions } from '@morf/proto/destinationactions/customerio/v1/customerio_ts_proto';
import { destinationactions as healthieDestinationActions } from '@morf/proto/destinationactions/healthie/v1/healthie_ts_proto';
import { destinationactions as intakeqDestinationActions } from '@morf/proto/destinationactions/intakeq/v1/intakeq_ts_proto';
import { destinationactions as morfDestinationActions } from '@morf/proto/destinationactions/morf/v1/morf_ts_proto';
import { destinationactions as segmentDestinationActions } from '@morf/proto/destinationactions/segment/v1/segment_ts_proto';
import { destinationactions as slackDestinationActions } from '@morf/proto/destinationactions/slack/v1/slack_ts_proto';
import { destinationactions as spruceDestinationActions } from '@morf/proto/destinationactions/spruce/v1/spruce_ts_proto';

export const createActionMap = <T extends Record<string, unknown>>(
  module: T,
  applicationNameAndVersion: string
): Record<string, T[keyof T]> => {
  const actionMap: Record<string, T[keyof T]> = {};
  for (const key in module) {
    actionMap[`${applicationNameAndVersion}.${key}`] = module[key];
  }
  return actionMap;
};

export const allActions = {
  ...createActionMap(
    activecampaignDestinationActions.activecampaign.v1,
    'activecampaign.v1'
  ),
  ...createActionMap(
    customerIoDestinationActions.customerio.v1,
    'customerio.v1'
  ),
  ...createActionMap(healthieDestinationActions.healthie.v1, 'healthie.v1'),
  ...createActionMap(intakeqDestinationActions.intakeq.v1, 'intakeq.v1'),
  ...createActionMap(morfDestinationActions.morf.v1, 'morf.v1'),
  ...createActionMap(segmentDestinationActions.segment.v1, 'segment.v1'),
  ...createActionMap(slackDestinationActions.slack.v1, 'slack.v1'),
  ...createActionMap(spruceDestinationActions.spruce.v1, 'spruce.v1'),
};
