import { Extension } from '@tiptap/react';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import {
  SlashCommandPluginState,
  reducerFn,
  initState,
  filterItems,
} from './SlashCommandState';
import { handleKeyDown } from './SlashCommandActions';
import type { SlashCommandItem } from './SlashCommandTypes';

export const pluginKey = new PluginKey('slashCommand');

export type SlashCommandViewEvent = {
  type: 'update' | 'show' | 'hide';
  state: SlashCommandPluginState;
  view: EditorView;
  items: SlashCommandItem[];
  position: { x: number; y: number } | null;
};

export type SlashCommandViewCallback = (event: SlashCommandViewEvent) => void;

const viewCallbacks: Set<SlashCommandViewCallback> = new Set();

export function registerSlashCommandViewCallback(
  callback: SlashCommandViewCallback
) {
  viewCallbacks.add(callback);
  return () => {
    viewCallbacks.delete(callback);
  };
}

function emitSlashCommandViewEvent(event: SlashCommandViewEvent) {
  viewCallbacks.forEach((callback) => callback(event));
}

/**
 * SlashCommandExtension for Tiptap editor
 * Allows users to trigger custom commands with a slash (/) prefix
 */
export const SlashCommandExtension = Extension.create({
  name: 'slashCommand',

  addOptions() {
    return {
      items: [],
    };
  },

  addProseMirrorPlugins() {
    const items = this.options.items;

    return [
      new Plugin({
        key: pluginKey,
        props: {
          handleKeyDown: (view, event) => {
            const state = view.state;
            const pluginState = pluginKey.getState(
              state
            ) as SlashCommandPluginState;

            return handleKeyDown(view, event, pluginState, pluginKey);
          },

          handleClick: (view) => {
            const pluginState = pluginKey.getState(
              view.state
            ) as SlashCommandPluginState;

            if (pluginState.isActive) {
              view.dispatch(
                view.state.tr.setMeta(pluginKey, {
                  type: 'CLOSE',
                })
              );
            }

            return false;
          },
        },
        state: {
          init() {
            return initState(items);
          },
          apply(tr, state) {
            return reducerFn(tr, state, pluginKey);
          },
        },
        view(editorView) {
          let lastState: SlashCommandPluginState | null = null;

          const updateMenu = () => {
            const pluginState = pluginKey.getState(
              editorView.state
            ) as SlashCommandPluginState;

            if (
              !lastState ||
              JSON.stringify(lastState) !== JSON.stringify(pluginState)
            ) {
              lastState = { ...pluginState };

              if (pluginState.isActive && pluginState.slashPos !== null) {
                const filteredItems = filterItems(
                  pluginState.items,
                  pluginState.query
                );

                const coords = editorView.coordsAtPos(pluginState.slashPos);

                const position = {
                  x: coords.left,
                  y: coords.bottom,
                };

                emitSlashCommandViewEvent({
                  type: 'update',
                  state: pluginState,
                  view: editorView,
                  items: filteredItems,
                  position,
                });
              } else if (lastState.isActive && !pluginState.isActive) {
                emitSlashCommandViewEvent({
                  type: 'hide',
                  state: pluginState,
                  view: editorView,
                  items: [],
                  position: null,
                });
              }
            }
          };

          const handleDocumentClick = (e: MouseEvent) => {
            const pluginState = pluginKey.getState(
              editorView.state
            ) as SlashCommandPluginState;

            if (
              pluginState?.isActive &&
              !editorView.dom.contains(e.target as Node)
            ) {
              editorView.dispatch(
                editorView.state.tr.setMeta(pluginKey, {
                  type: 'CLOSE',
                })
              );
            }
          };

          updateMenu();

          document.addEventListener('mousedown', handleDocumentClick);

          return {
            update: () => {
              updateMenu();
            },
            destroy: () => {
              document.removeEventListener('mousedown', handleDocumentClick);
              emitSlashCommandViewEvent({
                type: 'hide',
                state: lastState || initState(items),
                view: editorView,
                items: [],
                position: null,
              });
            },
          };
        },
      }),
    ];
  },
});

export default SlashCommandExtension;
