import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';
import { validation } from '@morf/proto/validation/v1/validation_ts_proto';

export type ValidationIssueIndicatorProps = {
  validationIssue: validation.v1.WorkflowValidationIssue;
};

const ValidationIssueIndicator: FC<ValidationIssueIndicatorProps> = ({
  validationIssue,
}) => {
  const theme = useTheme();

  return (
    <Flexbox
      data-testid='validation-issue-indicator'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
      backgroundColor={theme.colors.ui.body}
      borderRadius={0.5}
      p={0.5}
      gap={0.25}
    >
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        height='auto'
        gap={0.5}
      >
        <Icon size={1} name='alert' stroke={theme.colors.support.red.dark} />
        <Text tag='p2' color={theme.colors.support.red.dark} weight='bold'>
          Validation failed
        </Text>
      </Flexbox>

      {validationIssue.celCompilationErrors?.compilationErrorMessages?.errors.map(
        (error, index) => (
          <Text key={index} tag='p2'>
            {error.message}
          </Text>
        )
      )}
      {validationIssue.celCompilationErrors?.typeError && (
        <Text tag='p2'>{validationIssue.celCompilationErrors.typeError}</Text>
      )}

      {validationIssue.constantValueTypeAssertionError && (
        <Text tag='p2'>{validationIssue.constantValueTypeAssertionError}</Text>
      )}

      {validationIssue.contactMorfSupport && (
        <Text tag='p2'>Contact Morf Support for assistance.</Text>
      )}

      {validationIssue.issueDescription && (
        <Text tag='p2'>{validationIssue.issueDescription}</Text>
      )}

      {validationIssue.duplicatedResultObjectKey && (
        <Text tag='p2'>
          A result object with this key already exists. Please use a unique key.
        </Text>
      )}
    </Flexbox>
  );
};

export const MemoizedValidationIssueIndicator = memo(ValidationIssueIndicator);
