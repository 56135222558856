import rpcService from '@morf/server/browser-server';
import { Container } from '@morf/ui';
import { FC, memo, useState } from 'react';
import { Flexbox } from '@morf/ui/Flexbox';
import { LayoutProps } from './types';
import { NavigationRail } from './NavigationRail';
import { SpotlightSearch } from '@morf/ui/SpotlightSearch';
import { formatSpotlightSearchRows } from './formatSpotlightSearchRows';
import { navigationRailWidth } from './NavigationRail/NavigationRail';
import { profiles } from '@morf/proto/profiles/v1/profiles_ts_proto';
import { useQuery } from '@tanstack/react-query';
import { useTheme } from '@morf/theming';

const Layout: FC<LayoutProps> = ({
  children,
  organizationId,
  organizationPermissions,
  userImage,
}) => {
  const theme = useTheme();
  const childrenMaxWidth = `calc(100% - ${navigationRailWidth})`;

  const [searchValue, setSearchValue] = useState<string>('');
  const [showSpotlightSearch, setShowSpotlightSearch] = useState(false);

  const handleClose = () => {
    setSearchValue('');
    setShowSpotlightSearch(false);
  };

  const { data, isLoading } = useQuery<profiles.v1.IndexSearchResponse>(
    ['/profiles.v1.ProfilesService/Search', organizationId, searchValue],
    async () => {
      return await rpcService.profilesV1Service.search({
        searchString: searchValue,
        limit: 10,
      });
    },
    {
      enabled: !!searchValue,
    }
  );

  const formattedSpotlightSearchRows = formatSpotlightSearchRows(
    data?.profileHits,
    organizationId,
    isLoading
  );

  return (
    <Flexbox
      data-testid='layout'
      backgroundColor={theme.colors.ui.card}
      height='100vh'
      gap={0}
    >
      <SpotlightSearch
        isOpen={showSpotlightSearch}
        onClose={handleClose}
        rows={formattedSpotlightSearchRows}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setShowSpotlightSearch={setShowSpotlightSearch}
      />
      <NavigationRail
        organizationId={organizationId}
        organizationPermissions={organizationPermissions}
        setShowSpotlightSearch={setShowSpotlightSearch}
        userImage={userImage}
      />
      <Container maxWidth={childrenMaxWidth} overflow='auto'>
        {children}
      </Container>
    </Flexbox>
  );
};

export const MemoizedLayout = memo(Layout);
