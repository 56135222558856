import { FC } from 'react';
import { ParameterVariantProps } from './types';
import { ParameterTag } from './ParameterTag/ParameterTag';
import { useTheme } from '@morf/theming';

export const ParameterVariant: FC<ParameterVariantProps> = ({
  integrationIconName,
  isReadOnly,
  label,
  type,
  wordWrap,
}) => {
  const { support, ui, text } = useTheme().colors;

  const typeMap: Record<
    ParameterVariantProps['type'],
    {
      backgroundColor: string;
      iconColor: string;
      iconName?: string;
      leafFontColor: string;
      objectFontColor?: string;
    }
  > = {
    calculated: {
      backgroundColor: ui.body,
      iconColor: text.muted,
      iconName: 'variable',
      leafFontColor: text.body,
    },
    conditional: {
      backgroundColor: ui.body,
      iconColor: text.muted,
      iconName: 'conditional',
      leafFontColor: text.body,
    },
    constant: {
      backgroundColor: ui.body,
      iconColor: text.muted,
      iconName: 'text',
      leafFontColor: text.body,
    },
    custom: {
      backgroundColor: ui.body,
      iconColor: text.muted,
      iconName: 'tag',
      leafFontColor: text.body,
      objectFontColor: text.muted,
    },
    disabled: {
      backgroundColor: ui.body,
      iconColor: text.muted,
      iconName: 'alert',
      leafFontColor: text.muted,
      objectFontColor: text.muted,
    },
    identifier: {
      ...(integrationIconName ? {} : { iconName: 'patient-circle' }),
      backgroundColor: ui.body,
      iconColor: text.muted,
      leafFontColor: text.body,
    },
    morf: {
      backgroundColor: ui.body,
      iconColor: text.muted,
      iconName: 'properties',
      leafFontColor: text.body,
      objectFontColor: text.muted,
    },
    objectTypeId: {
      backgroundColor: ui.body,
      iconColor: text.muted,
      iconName: 'curly-brackets',
      leafFontColor: text.body,
    },
    payload: {
      backgroundColor: ui.body,
      iconColor: text.muted,
      iconName: 'code-bracket',
      leafFontColor: text.body,
      objectFontColor: text.muted,
    },
    validationIssue: {
      backgroundColor: support.red.lightest,
      iconColor: text.muted,
      iconName: 'alert',
      leafFontColor: text.body,
    },
    eventType: {
      backgroundColor: ui.body,
      iconColor: text.muted,
      iconName: 'details',
      leafFontColor: text.body,
    },
    eventTime: {
      backgroundColor: ui.body,
      iconColor: text.muted,
      iconName: 'clock',
      leafFontColor: text.body,
    },
  };

  const {
    backgroundColor,
    objectFontColor,
    iconName,
    iconColor,
    leafFontColor,
  } = typeMap[type] || {};

  return (
    <ParameterTag
      backgroundColor={backgroundColor}
      iconColor={iconColor}
      iconName={iconName}
      integrationIconName={integrationIconName}
      isReadOnly={isReadOnly}
      label={label}
      leafFontColor={leafFontColor}
      objectFontColor={objectFontColor}
      wordWrap={wordWrap}
    />
  );
};
