"use strict";

import * as $protobuf from "protobufjs/minimal";
import { google as google$1 } from "../../empty_ts_proto";
import { timing as timing$1 } from "../../timing/v1/timing_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.domains = (() => {
  const domains = {};
  domains.v1 = (() => {
    const v1 = {};
    v1.CreateDomainRequest = (() => {
      class CreateDomainRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateDomainRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(10).string(message.name);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateDomainRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.domains.v1.CreateDomainRequest) {
            return object;
          }
          const message = new $root.domains.v1.CreateDomainRequest();
          if (object.name != null) {
            message.name = String(object.name);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = "";
          }
          let keys;
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "domains.v1.CreateDomainRequest";
        }
      }

      CreateDomainRequest.prototype.name = "";

      return CreateDomainRequest;
    })();

    v1.CreateDomainResponse = (() => {
      class CreateDomainResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateDomainResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.domain != null && Object.hasOwnProperty.call(message, "domain")) {
            $root.domains.v1.Domain.encode(message.domain, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateDomainResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.domain = $root.domains.v1.Domain.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.domains.v1.CreateDomainResponse) {
            return object;
          }
          const message = new $root.domains.v1.CreateDomainResponse();
          if (object.domain != null) {
            if (typeof object.domain !== "object") {
              throw new TypeError(".domains.v1.CreateDomainResponse.domain: object expected, but got " + (typeof object.domain));
            }
            message.domain = $root.domains.v1.Domain.fromObject(object.domain);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.domain = null;
          }
          let keys;
          if (message.domain != null && message.hasOwnProperty("domain")) {
            object.domain = $root.domains.v1.Domain.toObject(message.domain, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "domains.v1.CreateDomainResponse";
        }
      }

      CreateDomainResponse.prototype.domain = null;

      return CreateDomainResponse;
    })();

    v1.GetDomainRequest = (() => {
      class GetDomainRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetDomainRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(10).string(message.name);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetDomainRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.domains.v1.GetDomainRequest) {
            return object;
          }
          const message = new $root.domains.v1.GetDomainRequest();
          if (object.name != null) {
            message.name = String(object.name);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = "";
          }
          let keys;
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "domains.v1.GetDomainRequest";
        }
      }

      GetDomainRequest.prototype.name = "";

      return GetDomainRequest;
    })();

    v1.GetDomainResponse = (() => {
      class GetDomainResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetDomainResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.domain != null && Object.hasOwnProperty.call(message, "domain")) {
            $root.domains.v1.Domain.encode(message.domain, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetDomainResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.domain = $root.domains.v1.Domain.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.domains.v1.GetDomainResponse) {
            return object;
          }
          const message = new $root.domains.v1.GetDomainResponse();
          if (object.domain != null) {
            if (typeof object.domain !== "object") {
              throw new TypeError(".domains.v1.GetDomainResponse.domain: object expected, but got " + (typeof object.domain));
            }
            message.domain = $root.domains.v1.Domain.fromObject(object.domain);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.domain = null;
          }
          let keys;
          if (message.domain != null && message.hasOwnProperty("domain")) {
            object.domain = $root.domains.v1.Domain.toObject(message.domain, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "domains.v1.GetDomainResponse";
        }
      }

      GetDomainResponse.prototype.domain = null;

      return GetDomainResponse;
    })();

    v1.ListDomainsRequest = (() => {
      class ListDomainsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListDomainsRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.stub != null && Object.hasOwnProperty.call(message, "stub")) {
            google$1.protobuf.Empty.encode(message.stub, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListDomainsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stub = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.domains.v1.ListDomainsRequest) {
            return object;
          }
          const message = new $root.domains.v1.ListDomainsRequest();
          if (object.stub != null) {
            if (typeof object.stub !== "object") {
              throw new TypeError(".domains.v1.ListDomainsRequest.stub: object expected, but got " + (typeof object.stub));
            }
            message.stub = google$1.protobuf.Empty.fromObject(object.stub);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.stub = null;
          }
          let keys;
          if (message.stub != null && message.hasOwnProperty("stub")) {
            object.stub = google$1.protobuf.Empty.toObject(message.stub, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "domains.v1.ListDomainsRequest";
        }
      }

      ListDomainsRequest.prototype.stub = null;

      return ListDomainsRequest;
    })();

    v1.ListDomainsResponse = (() => {
      class ListDomainsResponse {
        constructor(properties) {
          this.domains = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListDomainsResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.domains != null && Object.hasOwnProperty.call(message, "domains")) {
            for (const element of message.domains) {
              $root.domains.v1.DomainSummary.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListDomainsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.domains || !message.domains.length) {
                  message.domains = [];
                }
                message.domains.push($root.domains.v1.DomainSummary.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.domains.v1.ListDomainsResponse) {
            return object;
          }
          const message = new $root.domains.v1.ListDomainsResponse();
          if (object.domains) {
            if (!Array.isArray(object.domains)) {
              throw new TypeError(".domains.v1.ListDomainsResponse.domains: array type expected, but got " + (typeof object.domains))
            }
            message.domains = new Array(object.domains.length);
            for (let i = 0; i < object.domains.length; ++i) {
              if (typeof object.domains[i] !== "object") {
                throw new TypeError(".domains.v1.ListDomainsResponse.domains: object expected, but got " + (typeof object.domains[i]));
              }
              message.domains[i] = $root.domains.v1.DomainSummary.fromObject(object.domains[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.domains = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.domains && message.domains.length) {
            object.domains = new Array(message.domains.length);
            for (let i = 0; i < message.domains.length; ++i) {
              object.domains[i] = $root.domains.v1.DomainSummary.toObject(message.domains[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "domains.v1.ListDomainsResponse";
        }
      }

      ListDomainsResponse.prototype.domains = $util.emptyArray;

      return ListDomainsResponse;
    })();

    v1.VerifyDomainRequest = (() => {
      class VerifyDomainRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new VerifyDomainRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(10).string(message.name);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new VerifyDomainRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.domains.v1.VerifyDomainRequest) {
            return object;
          }
          const message = new $root.domains.v1.VerifyDomainRequest();
          if (object.name != null) {
            message.name = String(object.name);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = "";
          }
          let keys;
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "domains.v1.VerifyDomainRequest";
        }
      }

      VerifyDomainRequest.prototype.name = "";

      return VerifyDomainRequest;
    })();

    v1.VerifyDomainResponse = (() => {
      class VerifyDomainResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new VerifyDomainResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.domain != null && Object.hasOwnProperty.call(message, "domain")) {
            $root.domains.v1.Domain.encode(message.domain, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new VerifyDomainResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.domain = $root.domains.v1.Domain.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.domains.v1.VerifyDomainResponse) {
            return object;
          }
          const message = new $root.domains.v1.VerifyDomainResponse();
          if (object.domain != null) {
            if (typeof object.domain !== "object") {
              throw new TypeError(".domains.v1.VerifyDomainResponse.domain: object expected, but got " + (typeof object.domain));
            }
            message.domain = $root.domains.v1.Domain.fromObject(object.domain);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.domain = null;
          }
          let keys;
          if (message.domain != null && message.hasOwnProperty("domain")) {
            object.domain = $root.domains.v1.Domain.toObject(message.domain, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "domains.v1.VerifyDomainResponse";
        }
      }

      VerifyDomainResponse.prototype.domain = null;

      return VerifyDomainResponse;
    })();

    v1.DNSRecord = (() => {
      class DNSRecord {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DNSRecord(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.isActive != null && Object.hasOwnProperty.call(message, "isActive")) {
            writer.uint32(8).bool(message.isActive);
          }
          if (message.recordType != null && Object.hasOwnProperty.call(message, "recordType")) {
            writer.uint32(18).string(message.recordType);
          }
          if (message.isValid != null && Object.hasOwnProperty.call(message, "isValid")) {
            writer.uint32(24).bool(message.isValid);
          }
          if (message.value != null && Object.hasOwnProperty.call(message, "value")) {
            writer.uint32(34).string(message.value);
          }
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(42).string(message.name);
          }
          if (message.priority != null && Object.hasOwnProperty.call(message, "priority")) {
            writer.uint32(50).string(message.priority);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DNSRecord();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.isActive = reader.bool();
                break;
              }
              case 2: {
                message.recordType = reader.string();
                break;
              }
              case 3: {
                message.isValid = reader.bool();
                break;
              }
              case 4: {
                message.value = reader.string();
                break;
              }
              case 5: {
                message.name = reader.string();
                break;
              }
              case 6: {
                message.priority = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.domains.v1.DNSRecord) {
            return object;
          }
          const message = new $root.domains.v1.DNSRecord();
          if (object.isActive != null) {
            message.isActive = Boolean(object.isActive);
          }
          if (object.recordType != null) {
            message.recordType = String(object.recordType);
          }
          if (object.isValid != null) {
            message.isValid = Boolean(object.isValid);
          }
          if (object.value != null) {
            message.value = String(object.value);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.priority != null) {
            message.priority = String(object.priority);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.isActive = false;
            object.recordType = "";
            object.isValid = false;
            object.value = "";
            object.name = "";
            object.priority = "";
          }
          let keys;
          if (message.isActive != null && message.hasOwnProperty("isActive")) {
            object.isActive = message.isActive;
          }
          if (message.recordType != null && message.hasOwnProperty("recordType")) {
            object.recordType = message.recordType;
          }
          if (message.isValid != null && message.hasOwnProperty("isValid")) {
            object.isValid = message.isValid;
          }
          if (message.value != null && message.hasOwnProperty("value")) {
            object.value = message.value;
          }
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.priority != null && message.hasOwnProperty("priority")) {
            object.priority = message.priority;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "domains.v1.DNSRecord";
        }
      }

      DNSRecord.prototype.isActive = false;
      DNSRecord.prototype.recordType = "";
      DNSRecord.prototype.isValid = false;
      DNSRecord.prototype.value = "";
      DNSRecord.prototype.name = "";
      DNSRecord.prototype.priority = "";

      return DNSRecord;
    })();

    v1.Domain = (() => {
      class Domain {
        constructor(properties) {
          this.dnsRecordsRequiredForSendingEmails = [];
          this.dnsRecordsRequiredForReceivingEmails = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Domain(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(10).string(message.name);
          }
          if (message.state != null && Object.hasOwnProperty.call(message, "state")) {
            writer.uint32(16).int32(message.state);
          }
          if (message.dnsRecordsRequiredForSendingEmails != null && Object.hasOwnProperty.call(message, "dnsRecordsRequiredForSendingEmails")) {
            for (const element of message.dnsRecordsRequiredForSendingEmails) {
              $root.domains.v1.DNSRecord.encode(element, writer.uint32(26).fork()).ldelim();
            }
          }
          if (message.dnsRecordsRequiredForReceivingEmails != null && Object.hasOwnProperty.call(message, "dnsRecordsRequiredForReceivingEmails")) {
            for (const element of message.dnsRecordsRequiredForReceivingEmails) {
              $root.domains.v1.DNSRecord.encode(element, writer.uint32(34).fork()).ldelim();
            }
          }
          if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt")) {
            timing$1.v1.Timestamp.encode(message.createdAt, writer.uint32(42).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Domain();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.state = reader.int32();
                break;
              }
              case 3: {
                if (!message.dnsRecordsRequiredForSendingEmails || !message.dnsRecordsRequiredForSendingEmails.length) {
                  message.dnsRecordsRequiredForSendingEmails = [];
                }
                message.dnsRecordsRequiredForSendingEmails.push($root.domains.v1.DNSRecord.decode(reader, reader.uint32()));
                break;
              }
              case 4: {
                if (!message.dnsRecordsRequiredForReceivingEmails || !message.dnsRecordsRequiredForReceivingEmails.length) {
                  message.dnsRecordsRequiredForReceivingEmails = [];
                }
                message.dnsRecordsRequiredForReceivingEmails.push($root.domains.v1.DNSRecord.decode(reader, reader.uint32()));
                break;
              }
              case 5: {
                message.createdAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.domains.v1.Domain) {
            return object;
          }
          const message = new $root.domains.v1.Domain();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.state != null) {
            switch (object.state) {
              case "DOMAIN_STATE_UNSPECIFIED":
              case 0: {
                message.state = 0;
                break;
              }
              case "DOMAIN_STATE_ACTIVE":
              case 1: {
                message.state = 1;
                break;
              }
              case "DOMAIN_STATE_UNVERIFIED":
              case 2: {
                message.state = 2;
                break;
              }
              case "DOMAIN_STATE_DISABLED":
              case 3: {
                message.state = 3;
                break;
              }
              default: {
                if (typeof object.state == "number") {
                  message.state = object.state;
                  break;
                }
                break;
              }
            }
          }
          if (object.dnsRecordsRequiredForSendingEmails) {
            if (!Array.isArray(object.dnsRecordsRequiredForSendingEmails)) {
              throw new TypeError(".domains.v1.Domain.dnsRecordsRequiredForSendingEmails: array type expected, but got " + (typeof object.dnsRecordsRequiredForSendingEmails))
            }
            message.dnsRecordsRequiredForSendingEmails = new Array(object.dnsRecordsRequiredForSendingEmails.length);
            for (let i = 0; i < object.dnsRecordsRequiredForSendingEmails.length; ++i) {
              if (typeof object.dnsRecordsRequiredForSendingEmails[i] !== "object") {
                throw new TypeError(".domains.v1.Domain.dnsRecordsRequiredForSendingEmails: object expected, but got " + (typeof object.dnsRecordsRequiredForSendingEmails[i]));
              }
              message.dnsRecordsRequiredForSendingEmails[i] = $root.domains.v1.DNSRecord.fromObject(object.dnsRecordsRequiredForSendingEmails[i]);
            }
          }
          if (object.dnsRecordsRequiredForReceivingEmails) {
            if (!Array.isArray(object.dnsRecordsRequiredForReceivingEmails)) {
              throw new TypeError(".domains.v1.Domain.dnsRecordsRequiredForReceivingEmails: array type expected, but got " + (typeof object.dnsRecordsRequiredForReceivingEmails))
            }
            message.dnsRecordsRequiredForReceivingEmails = new Array(object.dnsRecordsRequiredForReceivingEmails.length);
            for (let i = 0; i < object.dnsRecordsRequiredForReceivingEmails.length; ++i) {
              if (typeof object.dnsRecordsRequiredForReceivingEmails[i] !== "object") {
                throw new TypeError(".domains.v1.Domain.dnsRecordsRequiredForReceivingEmails: object expected, but got " + (typeof object.dnsRecordsRequiredForReceivingEmails[i]));
              }
              message.dnsRecordsRequiredForReceivingEmails[i] = $root.domains.v1.DNSRecord.fromObject(object.dnsRecordsRequiredForReceivingEmails[i]);
            }
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== "object") {
              throw new TypeError(".domains.v1.Domain.createdAt: object expected, but got " + (typeof object.createdAt));
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(object.createdAt);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.dnsRecordsRequiredForSendingEmails = [];
            object.dnsRecordsRequiredForReceivingEmails = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = "";
            object.state = options.enums === String ? "DOMAIN_STATE_UNSPECIFIED" : 0
            object.createdAt = null;
          }
          let keys;
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.state != null && message.hasOwnProperty("state")) {
            object.state = options.enums === String ? $root.domains.v1.DomainState[message.state] === undefined ? message.state : $root.domains.v1.DomainState[message.state] : message.state;
          }
          if (message.dnsRecordsRequiredForSendingEmails && message.dnsRecordsRequiredForSendingEmails.length) {
            object.dnsRecordsRequiredForSendingEmails = new Array(message.dnsRecordsRequiredForSendingEmails.length);
            for (let i = 0; i < message.dnsRecordsRequiredForSendingEmails.length; ++i) {
              object.dnsRecordsRequiredForSendingEmails[i] = $root.domains.v1.DNSRecord.toObject(message.dnsRecordsRequiredForSendingEmails[i], options);
            }
          }
          if (message.dnsRecordsRequiredForReceivingEmails && message.dnsRecordsRequiredForReceivingEmails.length) {
            object.dnsRecordsRequiredForReceivingEmails = new Array(message.dnsRecordsRequiredForReceivingEmails.length);
            for (let i = 0; i < message.dnsRecordsRequiredForReceivingEmails.length; ++i) {
              object.dnsRecordsRequiredForReceivingEmails[i] = $root.domains.v1.DNSRecord.toObject(message.dnsRecordsRequiredForReceivingEmails[i], options);
            }
          }
          if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
            object.createdAt = timing$1.v1.Timestamp.toObject(message.createdAt, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "domains.v1.Domain";
        }
      }

      Domain.prototype.name = "";
      Domain.prototype.state = 0;
      Domain.prototype.dnsRecordsRequiredForSendingEmails = $util.emptyArray;
      Domain.prototype.dnsRecordsRequiredForReceivingEmails = $util.emptyArray;
      Domain.prototype.createdAt = null;

      return Domain;
    })();

    v1.DomainSummary = (() => {
      class DomainSummary {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DomainSummary(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(10).string(message.name);
          }
          if (message.state != null && Object.hasOwnProperty.call(message, "state")) {
            writer.uint32(16).int32(message.state);
          }
          if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt")) {
            timing$1.v1.Timestamp.encode(message.createdAt, writer.uint32(26).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DomainSummary();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.state = reader.int32();
                break;
              }
              case 3: {
                message.createdAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.domains.v1.DomainSummary) {
            return object;
          }
          const message = new $root.domains.v1.DomainSummary();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.state != null) {
            switch (object.state) {
              case "DOMAIN_STATE_UNSPECIFIED":
              case 0: {
                message.state = 0;
                break;
              }
              case "DOMAIN_STATE_ACTIVE":
              case 1: {
                message.state = 1;
                break;
              }
              case "DOMAIN_STATE_UNVERIFIED":
              case 2: {
                message.state = 2;
                break;
              }
              case "DOMAIN_STATE_DISABLED":
              case 3: {
                message.state = 3;
                break;
              }
              default: {
                if (typeof object.state == "number") {
                  message.state = object.state;
                  break;
                }
                break;
              }
            }
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== "object") {
              throw new TypeError(".domains.v1.DomainSummary.createdAt: object expected, but got " + (typeof object.createdAt));
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(object.createdAt);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = "";
            object.state = options.enums === String ? "DOMAIN_STATE_UNSPECIFIED" : 0
            object.createdAt = null;
          }
          let keys;
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.state != null && message.hasOwnProperty("state")) {
            object.state = options.enums === String ? $root.domains.v1.DomainState[message.state] === undefined ? message.state : $root.domains.v1.DomainState[message.state] : message.state;
          }
          if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
            object.createdAt = timing$1.v1.Timestamp.toObject(message.createdAt, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "domains.v1.DomainSummary";
        }
      }

      DomainSummary.prototype.name = "";
      DomainSummary.prototype.state = 0;
      DomainSummary.prototype.createdAt = null;

      return DomainSummary;
    })();

    v1.DomainState = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "DOMAIN_STATE_UNSPECIFIED"] = 0;
      values[valuesById[1] = "DOMAIN_STATE_ACTIVE"] = 1;
      values[valuesById[2] = "DOMAIN_STATE_UNVERIFIED"] = 2;
      values[valuesById[3] = "DOMAIN_STATE_DISABLED"] = 3;
      return values;
    })();

    return v1;
  })();

  return domains;
})();

export const domains = $root.domains;
