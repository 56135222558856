import React from 'react';
import { Editor } from '@tiptap/react';
import { SlashCommandFloatingMenu } from './SlashCommandFloatingMenu';

interface SlashCommandProviderProps {
  children: React.ReactNode;
  editor: Editor | null;
}

/**
 * Provider component for the SlashCommand extension
 * Renders the SlashCommandFloatingMenu and passes the editor to it
 */
export const SlashCommandProvider: React.FC<SlashCommandProviderProps> = ({
  children,
  editor,
}) => {
  const isEditorReady = editor && editor.isEditable && editor.view;

  return (
    <>
      {children}
      {isEditorReady && <SlashCommandFloatingMenu editor={editor.view} />}
    </>
  );
};

export default SlashCommandProvider;
