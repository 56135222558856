import Long from 'long';
import { TimeOption } from './TimeDropdown/TimeOption/types';
import { timing } from '@morf/proto/timing/v1/timing_ts_proto';

const getCurrentTimestamp = (): timing.v1.Timestamp => ({
  seconds: Long.fromNumber(Math.floor(Date.now() / 1000)),
  nanoseconds: new Date().getMilliseconds() * 1e6,
});

export const getEarliestAndLatestTimestamps = (
  timeOption: TimeOption | undefined
): {
  earliestTimestampInclusive: timing.v1.Timestamp | undefined;
  latestTimestampExclusive: timing.v1.Timestamp;
} => {
  const earliestTimestampInclusive = timeOption?.timeRange?.startTimestamp;
  const currentTimestamp = getCurrentTimestamp();
  const latestTimestampExclusive =
    timeOption?.timeRange?.endTimestamp || currentTimestamp;

  return { earliestTimestampInclusive, latestTimestampExclusive };
};
