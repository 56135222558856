import { FC } from 'react';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { useTheme } from '@morf/theming';
import { Container } from '../Container';
import { useRouter } from 'next/router';

interface NotFoundProps {
  /**
   * The header text to display
   * @default "Oops! We couldn't find that page."
   */
  header?: string;
  /**
   * The body text to display
   * @default "The page you are looking for may not exist or is temporarily unavailable."
   */
  body?: string;
  /**
   * The text to display on the CTA button
   * @default "Go Home"
   */
  ctaText?: string;
  /**
   * The action to perform when the CTA button is clicked
   * @default Navigate to home page
   */
  onCtaClick?: () => void;
}

export const NotFound: FC<NotFoundProps> = ({
  header = "Oops! We couldn't find that page.",
  body = 'The page you are looking for may not exist or is temporarily unavailable.',
  ctaText = 'Go Home',
  onCtaClick,
}) => {
  const theme = useTheme();
  const router = useRouter();

  const handleCtaClick = () => {
    if (onCtaClick) {
      onCtaClick();
    } else {
      router.push('/');
    }
  };

  return (
    <Flexbox
      data-testid='not-found'
      direction='column'
      justifyContent='center'
      alignItems='center'
      p={2}
    >
      <Icon name='warning' size={4} stroke={theme.colors.text.muted} />
      <Text tag='h1'>{header}</Text>
      <Text tag='p1'>{body}</Text>
      <Container pt={2} height='auto' width='auto'>
        <Button onClick={handleCtaClick} variant='primary' size='base'>
          <Text tag='h3' color='inherit'>
            {ctaText}
          </Text>
        </Button>
      </Container>
    </Flexbox>
  );
};
