export const removeObjectKeys = <T extends object>(
  obj: T,
  keysToRemove: string[]
): T => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => removeObjectKeys(item, keysToRemove)) as T;
  }
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([key]) => !keysToRemove.includes(key))
      .map(([key, value]) => [key, removeObjectKeys(value, keysToRemove)])
  ) as T;
};
