import {
  ReactNodeViewRenderer,
  Node,
  mergeAttributes,
  Editor,
} from '@tiptap/react';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { NodeViewWrapper } from '@tiptap/react';
import { NodeViewWrapperStyled } from './CelExpression.css';
import { celExpressionInputRule } from './CelExpressionInputRule';
import { Container, ParameterVariantProps } from '../../..';
import { ParameterVariant } from '../../../ParameterVariant/ParameterVariant';
import {
  deserializeParameterSource,
  getLabelFromParameterSource,
  getParameterTypeFromSource,
} from './CelExpressionUtils';

export interface CelExpressionAttributes {
  id: string;
  parameterSource?: string;
  label?: string;
  pos?: number;
}

export const CelExpression = Node.create({
  name: 'celExpression',
  group: 'inline',
  inline: true,
  atom: true,
  selectable: true,

  addAttributes() {
    return {
      parameterSource: {
        default: '',
      },
      id: {
        default: `cel-${Date.now()}-${Math.floor(Math.random() * 1000)}`,
      },
      label: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-cel-expression]',
        getAttrs: (element) => {
          if (!(element instanceof HTMLElement)) {
            return false;
          }

          const parameterSource =
            element.getAttribute('data-parameter-source') || '';

          return {
            parameterSource,
            id:
              element.getAttribute('data-id') ||
              `cel-${Date.now()}-${Math.floor(Math.random() * 1000)}`,
            label: element.getAttribute('data-label') || '',
          };
        },
      },
    ];
  },

  // Render CEL expressions to HTML
  renderHTML({ node }) {
    const attrs = node.attrs as CelExpressionAttributes;

    return [
      'span',
      mergeAttributes({
        'data-cel-expression': 'true',
        'data-parameter-source': attrs.parameterSource || '',
        'data-id': attrs.id,
        'data-label': attrs.label || '',
        class: 'cel-expression',
      }),
      attrs.label || '',
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(CelExpressionContainer);
  },

  addInputRules() {
    return [celExpressionInputRule(this.type)];
  },
});

// React component for rendering CEL expressions
const CelExpressionContainer = ({ node }: { node: ProseMirrorNode }) => {
  const attrs = node.attrs as CelExpressionAttributes;

  return (
    <NodeViewWrapperStyled
      as={NodeViewWrapper}
      className='cel-expression-wrapper'
      contentEditable={false}
    >
      <CelExpressionView attrs={attrs} />
    </NodeViewWrapperStyled>
  );
};

export const CelExpressionView = ({
  attrs,
}: {
  attrs: CelExpressionAttributes;
}) => {
  const parameterSource = attrs.parameterSource
    ? deserializeParameterSource(attrs.parameterSource)
    : null;

  const type: ParameterVariantProps['type'] = parameterSource
    ? getParameterTypeFromSource(parameterSource)
    : ('payload' as const);

  const label =
    attrs.label ||
    (parameterSource
      ? getLabelFromParameterSource(parameterSource)
      : 'Parameter');

  return (
    <Container width='auto' display='inline-block'>
      <ParameterVariant
        id={attrs.id}
        type={type}
        label={label}
        wordWrap='break-word'
      />
    </Container>
  );
};

// Helper function to get all CEL expressions from an editor instance
export const getAllCelExpressions = (
  editor: Editor
): CelExpressionAttributes[] => {
  const expressions: CelExpressionAttributes[] = [];

  const html = editor.getHTML();

  const celExpressionPattern =
    /<span[^>]*data-cel-expression(?:="true"|=""|)[^>]*class="cel-expression"[^>]*>.*?<\/span>/g;

  const matches = Array.from(html.matchAll(celExpressionPattern));

  for (let i = 0; i < matches.length; i++) {
    const match = matches[i];
    const fullMatch = match[0];
    const startPosition = match.index || 0;

    const idMatch = fullMatch.match(/data-id="([^"]*)"/);
    const id = idMatch ? idMatch[1] : undefined;

    const paramSourceMatch = fullMatch.match(/data-parameter-source="([^"]*)"/);
    const parameterSource = paramSourceMatch ? paramSourceMatch[1] : undefined;

    const labelMatch = fullMatch.match(/data-label="([^"]*)"/);
    const label = labelMatch ? labelMatch[1] : undefined;

    let extractedLabel = label;
    if (!extractedLabel) {
      const contentMatch = fullMatch.match(/>([^<]*)</);
      if (contentMatch && contentMatch[1].trim()) {
        extractedLabel = contentMatch[1].trim();
      }
    }

    expressions.push({
      id: id || `cel-${Date.now()}-${Math.floor(Math.random() * 1000)}`,
      parameterSource,
      label: extractedLabel,
      pos: startPosition,
    });
  }

  return expressions;
};

export default CelExpression;
