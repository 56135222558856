export const MorfDark = () => {
  return (
    <svg strokeWidth={0} viewBox='0 0 125 40'>
      <path
        fill='currentColor'
        d='M4.00003 22.066L4.00003 17.9326C4.00003 16.5905 5.09147 15.4977 6.43358 15.4977L13.092 15.4977L9.7614 9.72502C9.10654 8.55331 9.5065 7.06755 10.6543 6.3972L14.2412 4.3284C14.8017 4.00167 15.4608 3.91436 16.0931 4.08617C16.717 4.25658 17.2409 4.65935 17.5676 5.22126L20.8997 10.9869L24.2289 5.22549C24.5584 4.66076 25.0823 4.25798 25.7062 4.08758C26.3371 3.91577 26.9976 4.00308 27.5624 4.33262L31.1394 6.39579C32.29 7.06896 32.6928 8.55753 32.0422 9.71657L28.7059 15.4991L35.3658 15.4991C36.7079 15.4991 37.7993 16.5919 37.7993 17.934L37.7993 22.0674C37.7993 23.4095 36.7079 24.5023 35.3658 24.5023L28.7073 24.5023L32.0379 30.275C32.6942 31.4425 32.2928 32.9325 31.1451 33.6028L27.5581 35.6716C26.9976 35.9983 26.3385 36.0856 25.7062 35.9138C25.0823 35.7434 24.5584 35.3406 24.2317 34.7787L20.8997 29.0131L17.5704 34.7745C17.2409 35.3392 16.717 35.742 16.0931 35.9124C15.4608 36.0842 14.8017 35.9983 14.237 35.6674L10.6585 33.6042C9.5065 32.931 9.10372 31.4439 9.75577 30.2834L13.092 24.5009L6.43358 24.5009C5.09146 24.5009 4.00003 23.4081 4.00003 22.066V22.066Z'
      />
      <path
        fill='currentColor'
        d='M48.4839 30.9085V15.0435H53.0253L53.1747 17.9117L52.5473 17.9416C52.7863 17.4237 53.0851 16.9656 53.4436 16.5672C53.822 16.1689 54.2403 15.8402 54.6985 15.5813C55.1765 15.3024 55.6645 15.0933 56.1625 14.9538C56.6803 14.8144 57.1982 14.7447 57.7161 14.7447C58.4929 14.7447 59.2 14.8642 59.8374 15.1032C60.4748 15.3422 61.0325 15.7306 61.5106 16.2684C61.9886 16.7863 62.3671 17.4934 62.6459 18.3898L61.899 18.33L62.138 17.852C62.3969 17.3739 62.7256 16.9457 63.124 16.5672C63.5423 16.1689 64.0004 15.8402 64.4983 15.5813C64.9963 15.3024 65.5142 15.0933 66.052 14.9538C66.5898 14.8144 67.1077 14.7447 67.6056 14.7447C68.8605 14.7447 69.9062 14.9937 70.7428 15.4916C71.5794 15.9697 72.2068 16.6967 72.6251 17.6727C73.0633 18.6487 73.2824 19.8339 73.2824 21.2281V30.9085H68.4721V21.6166C68.4721 20.9792 68.3825 20.4513 68.2032 20.033C68.0239 19.6148 67.755 19.306 67.3965 19.1068C67.0579 18.8877 66.6296 18.7782 66.1117 18.7782C65.6935 18.7782 65.305 18.8479 64.9465 18.9873C64.6079 19.1267 64.3091 19.316 64.0502 19.555C63.8112 19.794 63.6219 20.0729 63.4825 20.3916C63.3431 20.7103 63.2734 21.0688 63.2734 21.4672V30.9085H58.463V21.5867C58.463 20.9891 58.3734 20.4812 58.1941 20.0629C58.0149 19.6446 57.746 19.3259 57.3875 19.1068C57.0289 18.8877 56.6106 18.7782 56.1326 18.7782C55.7143 18.7782 55.3259 18.8479 54.9674 18.9873C54.6287 19.1267 54.33 19.316 54.071 19.555C53.832 19.794 53.6428 20.0729 53.5033 20.3916C53.3639 20.7103 53.2942 21.0588 53.2942 21.4373V30.9085H48.4839Z'
      />
      <path
        fill='currentColor'
        d='M84.723 31.2073C83.0698 31.2073 81.5958 30.8488 80.3011 30.1317C79.0263 29.4146 78.0105 28.4386 77.2536 27.2037C76.5166 25.9687 76.1481 24.5545 76.1481 22.9611C76.1481 21.3676 76.5166 19.9534 77.2536 18.7184C78.0105 17.4835 79.0263 16.5075 80.3011 15.7904C81.5958 15.0733 83.0698 14.7148 84.723 14.7148C86.3762 14.7148 87.8402 15.0733 89.115 15.7904C90.4097 16.5075 91.4256 17.4835 92.1626 18.7184C92.8995 19.9534 93.268 21.3676 93.268 22.9611C93.268 24.5545 92.8995 25.9687 92.1626 27.2037C91.4256 28.4386 90.4097 29.4146 89.115 30.1317C87.8402 30.8488 86.3762 31.2073 84.723 31.2073ZM84.723 27.0842C85.4401 27.0842 86.0775 26.9049 86.6352 26.5464C87.1929 26.1878 87.6311 25.6998 87.9498 25.0824C88.2685 24.4649 88.4179 23.7578 88.398 22.9611C88.4179 22.1643 88.2685 21.4572 87.9498 20.8397C87.6311 20.2023 87.1929 19.7044 86.6352 19.3459C86.0775 18.9873 85.4401 18.8081 84.723 18.8081C84.0059 18.8081 83.3586 18.9873 82.781 19.3459C82.2232 19.7044 81.785 20.2023 81.4663 20.8397C81.1476 21.4572 80.9982 22.1643 81.0182 22.9611C80.9982 23.7578 81.1476 24.4649 81.4663 25.0824C81.785 25.6998 82.2232 26.1878 82.781 26.5464C83.3586 26.9049 84.0059 27.0842 84.723 27.0842Z'
      />
      <path
        fill='currentColor'
        d='M96.394 30.9085V15.0435H100.935L101.145 20.2123L100.248 19.2263C100.487 18.3698 100.876 17.603 101.413 16.9258C101.971 16.2485 102.619 15.7107 103.356 15.3124C104.092 14.914 104.879 14.7148 105.716 14.7148C106.074 14.7148 106.403 14.7447 106.702 14.8044C107.021 14.8642 107.309 14.9339 107.568 15.0136L106.254 20.3019C106.035 20.1625 105.736 20.053 105.357 19.9733C104.999 19.8737 104.62 19.8239 104.222 19.8239C103.784 19.8239 103.375 19.9036 102.997 20.0629C102.619 20.2023 102.3 20.4115 102.041 20.6903C101.782 20.9692 101.573 21.2979 101.413 21.6763C101.274 22.0548 101.204 22.483 101.204 22.9611V30.9085H96.394Z'
      />
      <path
        fill='currentColor'
        d='M110.977 30.9085V14.2666C110.977 13.2309 111.196 12.2947 111.634 11.4581C112.092 10.6216 112.72 9.96424 113.516 9.4862C114.333 8.98824 115.279 8.73926 116.355 8.73926C117.112 8.73926 117.809 8.84881 118.446 9.06791C119.083 9.28702 119.651 9.59575 120.149 9.99412L118.775 13.4898C118.516 13.3504 118.267 13.2408 118.028 13.1612C117.789 13.0815 117.57 13.0417 117.37 13.0417C117.012 13.0417 116.713 13.1014 116.474 13.2209C116.235 13.3404 116.056 13.5297 115.936 13.7886C115.837 14.0276 115.787 14.3264 115.787 14.6849V30.9085H113.397C112.859 30.9085 112.381 30.9085 111.963 30.9085C111.564 30.9085 111.236 30.9085 110.977 30.9085ZM108.676 19.4355V15.4916H119.044V19.4355H108.676Z'
      />
    </svg>
  );
};
