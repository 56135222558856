import { DefaultTheme } from '../Theme';
import { ThemeContainerProps } from '../types';
import { defaultColors } from '../ColorPalette';
import {
  defaultFontTypes,
  defaultH1,
  defaultH2,
  defaultH3,
  defaultH4,
  defaultH5,
  defaultH6,
  defaultH7,
  defaultLink,
  defaultLongform,
  defaultMaxNrOfSlots,
  defaultP1,
  defaultP2,
  defaultP3,
  defaultP4,
  defaultSubtitle,
} from '../ColorPalette/constants';

export const berryStreet: ThemeContainerProps = {
  ...DefaultTheme,
  cancellationPolicyHeader: 'Late Change Policy',
  cancellationPolicyDescription: `<p>In order to protect our provider's time, appointment changes within 24hrs of the scheduled time will incur a $100 fee per our <a style="color: #003E32; text-decoration: underline;" href="https://www.berrystreet.co/policy#cancellations" target='_blank'>policies</a>.`,
  colors: {
    ...defaultColors,
    text: {
      body: '#003E32',
      ...defaultColors.text,
    },
    main: {
      primary: {
        darkest: '#003E32',
        darker: '#2B9B76',
        dark: '',
        normal: '',
        light: '',
        lighter: '',
        lightest: '',
      },
      dark: {
        darkest: '#003E32',
        darker: '',
        dark: '',
        normal: '',
        light: '',
        lighter: '',
        lightest: '',
      },
      light: {
        darkest: '',
        darker: '',
        dark: '',
        normal: '#2B9B76',
        light: '#EDF8F4',
        lighter: '',
        lightest: '',
      },
    },
  },
  container: {
    borderType: 'none',
    borderRadius: 0,
  },
  contentOrder: {
    bio: 1,
    specialties: 2,
  },
  datePicker: {
    borderRadius: 1.25,
    style: 'fill',
  },
  fontTypes: {
    ...defaultFontTypes,
    h1: {
      ...defaultH1,
      family: 'canela-light',
    },
    h2: {
      ...defaultH2,
      family: 'canela-light',
    },
    h3: {
      ...defaultH3,
      family: 'hoves-medium',
    },
    h4: {
      ...defaultH4,
      family: 'hoves-medium',
    },
    h5: {
      ...defaultH5,
      family: 'hoves-medium',
    },
    h6: {
      ...defaultH6,
      family: 'hoves-medium',
    },
    h7: {
      ...defaultH7,
      family: 'hoves-medium',
    },
    p1: {
      ...defaultP1,
      family: 'hoves-regular',
    },
    p2: {
      ...defaultP2,
      family: 'hoves-regular',
    },
    p3: {
      ...defaultP3,
      family: 'hoves-regular',
    },
    p4: {
      ...defaultP4,
      family: 'hoves-regular',
    },
    subtitle: {
      ...defaultSubtitle,
      family: 'hoves-regular',
    },
    longform: {
      ...defaultLongform,
      family: 'hoves-regular',
    },
    link: {
      ...defaultLink,
      family: 'hoves-medium',
    },
  },
  input: { borderRadius: 0.5 },
  loader: 'berry-street.gif',
  maxNrOfSlots: defaultMaxNrOfSlots,
  noAvailableSlotsForMatchingProvidersMessage:
    '<p>Providers in your area currently have busy schedules.<br/> Reach out to <a style="color: #003E32; text-decoration: underline;" href="mailto: support@berrystreet.co" target="_blank">support@berrystreet.co</a> directly so that we can help get you matched with a provider who is a great fit.</p>',
  privacyPolicy: 'https://www.berrystreet.co/privacy',
  termsOfService: 'https://www.berrystreet.co/terms-and-conditions',
  timePicker: {
    borderRadius: 0.5,
    style: 'darkFill',
  },
  tooltip: {
    borderRadius: 0.5,
    type: 'dark',
  },
  withinTimeWindowHours: 24,
  withinTimeWindowTitle: 'An appointment change within 24hrs will incur a fee',
  withinTimeWindowMessage: `<p>In order to protect our provider's time, our system automatically applies a late change fee ($100) which you agreed to when making your booking. <br/><br/> If you believe this is in error, you can reach out to us directly at support@berrystreet.co.</p>`,
};
