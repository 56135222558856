import { ExtendedColumnDef } from '../../types';
import { Flexbox } from '../../../Flexbox';
import { RowData, flexRender } from '@tanstack/react-table';
import { StyledVirtualHeadCell } from './VirtualTableHeadCell.css';
import { VirtualTableHeadCellProps } from './types';
import { memo } from 'react';

const VirtualTableHeadCell: <RowData>(
  props: VirtualTableHeadCellProps<RowData>
) => JSX.Element = ({ header }) => {
  const columnDef = header.column.columnDef as ExtendedColumnDef<RowData>;
  const minWidth = `${header.column.getSize() / 16}rem`;

  return (
    <StyledVirtualHeadCell
      key={header.id}
      data-testid='virtual-head-cell'
      borderType={columnDef.headCellBorderType}
      headCellPl={columnDef.headCellPl}
      headCellPr={columnDef.headCellPr}
    >
      <Flexbox
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        minWidth={minWidth}
      >
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}
      </Flexbox>
    </StyledVirtualHeadCell>
  );
};

export const MemoizedVirtualTableHeadCell = memo(
  VirtualTableHeadCell
) as typeof VirtualTableHeadCell;
