import _ from 'lodash';
import rpcService from '@morf/server/browser-server';
import { FC, memo } from 'react';
import { Filter } from '@morf/ui/Filter';
import { FilterOption } from '@morf/ui/Filter/FilterDropdown/FilterOption/types';
import { WorkflowFilterProps } from './types';
import { formatSourceApplicationIcon } from '../../../helpers';
import { useQuery } from '@tanstack/react-query';
import { useUrlParams } from '../../../helpers/useUrlParams';
import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';

const WorkflowFilter: FC<WorkflowFilterProps> = ({
  setIsWorkflowFilterSelected,
}) => {
  const { organizationId, workflowIds } = useUrlParams();

  const { data: listResponse, isLoading: listResponseIsLoading } =
    useQuery<workflows.v1.ListResponse>(
      ['/workflows.v1.WorkflowsService/List', organizationId],
      async () => {
        return await rpcService.workflowsV1Service.list({
          filter: new workflows.v1.ListRequest.Filter(),
        });
      }
    );

  const allWorkflowOptions: FilterOption[] =
    listResponse?.workflows?.map(({ id, name, sourceApplication }) => ({
      id,
      name,
      integrationIconName: formatSourceApplicationIcon(sourceApplication),
    })) || [];

  const selectedWorkflowOptions = allWorkflowOptions.filter(({ id }) =>
    workflowIds?.includes(id)
  );

  return (
    <Filter
      allOptions={allWorkflowOptions}
      isLoading={listResponseIsLoading}
      name='Workflow'
      options={selectedWorkflowOptions}
      queryName='workflowIds'
      setIsFilterSelected={setIsWorkflowFilterSelected}
    />
  );
};

export const MemoizedWorkflowFilter = memo(WorkflowFilter);
