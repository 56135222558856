import _ from 'lodash';
import { allActions } from '../constants';

export const hasRequiredThirdPartyIdMissingBehavior = (
  applicationType: string,
  actionType: string
): boolean =>
  _.some(allActions, (action, key) => {
    const selectedAction =
      applicationType.replace(/V([^V]*)$/, '.V$1') + '.' + actionType;

    return (
      _.toLower(key) === _.toLower(selectedAction) &&
      action.prototype &&
      action.prototype.hasOwnProperty('requiredThirdPartyIdMissingBehavior')
    );
  });
