import { ActionListProps } from './types';
import { Container } from '../../../../../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../../../../../Flexbox';
import { HighlightText } from '../../../../../Typography';
import { IntegrationIcon } from '../../../../../IntegrationIcon';
import { convertToTitleCase } from '../../../../../Helpers/convertToTitleCase';
import { useTheme } from '@morf/theming';

const ActionList: FC<ActionListProps> = ({ highlightWords, list, onClick }) => {
  const theme = useTheme();

  const formatApplication = (application: string): string => {
    const match = application.match(/^(.*)(V[^V]*)$/);
    return match
      ? `${convertToTitleCase(match[1].trim())} ${match[2]}`
      : convertToTitleCase(application);
  };

  return (
    <Flexbox
      data-testid='action-list'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      overflow='auto'
      gap={0}
    >
      {list.map((item) => {
        const handleClick = () => onClick(item);
        const { name, integrationIconName, application } = item;
        const formattedApplication: string = formatApplication(application);

        return (
          <Flexbox
            key={name}
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            height='auto'
            cursor='pointer'
            hoverBackgroundColor={theme.colors.ui.body}
            onClick={handleClick}
            px={0.75}
            py={0.5}
            gap={0.5}
          >
            <Flexbox
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
              height='auto'
              width='auto'
              gap={0.25}
            >
              <Container width='auto' height='auto'>
                {integrationIconName && (
                  <IntegrationIcon name={integrationIconName} size={1.25} />
                )}
              </Container>

              <Container width='auto' height='auto'>
                {formattedApplication && (
                  <HighlightText
                    tag='p3'
                    color={theme.colors.text.muted}
                    highlightWords={highlightWords}
                    whiteSpace='nowrap'
                  >
                    {formattedApplication}
                  </HighlightText>
                )}
              </Container>
            </Flexbox>

            <HighlightText tag='p2' highlightWords={highlightWords}>
              {name}
            </HighlightText>
          </Flexbox>
        );
      })}
    </Flexbox>
  );
};

export const MemoizedActionList = memo(ActionList);
