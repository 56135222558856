import Long from 'long';
import moment from 'moment';
import { timing } from '@morf/proto/timing/v1/timing_ts_proto';

export const convertRFC3339ToTimestamp = (
  rfc3339String: string
): timing.v1.Timestamp => {
  const momentObject = moment(rfc3339String);
  const seconds = momentObject.unix();
  const milliseconds = momentObject.millisecond();
  const nanoseconds = milliseconds * 1e6;

  return new timing.v1.Timestamp({
    seconds: Long.fromNumber(seconds),
    nanoseconds: nanoseconds,
  });
};
