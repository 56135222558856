"use strict";

import Long from "long";
import * as $protobuf from "protobufjs/minimal";
import { timing as timing$1 } from "../../timing/v1/timing_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.stripe = (() => {
  const stripe = {};
  stripe.v1 = (() => {
    const v1 = {};
    v1.Customer = (() => {
      class Customer {
        constructor(properties) {
          this.metadata = {};
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Customer(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.address != null && Object.hasOwnProperty.call(message, "address")) {
            $root.stripe.v1.Address.encode(message.address, writer.uint32(10).fork()).ldelim();
          }
          if (message.balance != null && Object.hasOwnProperty.call(message, "balance")) {
            writer.uint32(16).int64(message.balance);
          }
          if (message.created != null && Object.hasOwnProperty.call(message, "created")) {
            timing$1.v1.Timestamp.encode(message.created, writer.uint32(26).fork()).ldelim();
          }
          if (message.currency != null && Object.hasOwnProperty.call(message, "currency")) {
            writer.uint32(34).string(message.currency);
          }
          if (message.description != null && Object.hasOwnProperty.call(message, "description")) {
            writer.uint32(42).string(message.description);
          }
          if (message.email != null && Object.hasOwnProperty.call(message, "email")) {
            writer.uint32(50).string(message.email);
          }
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(58).string(message.id);
          }
          if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata")) {
            for (const key of Object.keys(message.metadata)) {
              writer.uint32(66).fork().uint32(10).string(key).uint32(18).string(message.metadata[key]).ldelim();
            }
          }
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(74).string(message.name);
          }
          if (message.phone != null && Object.hasOwnProperty.call(message, "phone")) {
            writer.uint32(82).string(message.phone);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Customer();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.address = $root.stripe.v1.Address.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.balance = reader.int64();
                break;
              }
              case 3: {
                message.created = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.currency = reader.string();
                break;
              }
              case 5: {
                message.description = reader.string();
                break;
              }
              case 6: {
                message.email = reader.string();
                break;
              }
              case 7: {
                message.id = reader.string();
                break;
              }
              case 8: {
                if (message.metadata === $util.emptyObject) message.metadata = {};
                let end2 = reader.uint32() + reader.pos;
                key = "";
                value = "";
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value = reader.string();
                      break;
                    }
                  }
                  message.metadata[key] = value;
                }
                break;
              }
              case 9: {
                message.name = reader.string();
                break;
              }
              case 10: {
                message.phone = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stripe.v1.Customer) {
            return object;
          }
          const message = new $root.stripe.v1.Customer();
          if (object.address != null) {
            if (typeof object.address !== "object") {
              throw new TypeError(".stripe.v1.Customer.address: object expected, but got " + (typeof object.address));
            }
            message.address = $root.stripe.v1.Address.fromObject(object.address);
          }
          if (object.balance != null) {
            if ($util.Long) {
              (message.balance = $util.Long.fromValue(object.balance)).unsigned = false;
            } else if (typeof object.balance === "string") {
              message.balance = parseInt(object.balance, 10);
            } else if (typeof object.balance === "number") {
              message.balance = object.balance;
            } else if (typeof object.balance === "object") {
              message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber();
            }
          }
          if (object.created != null) {
            if (typeof object.created !== "object") {
              throw new TypeError(".stripe.v1.Customer.created: object expected, but got " + (typeof object.created));
            }
            message.created = timing$1.v1.Timestamp.fromObject(object.created);
          }
          if (object.currency != null) {
            message.currency = String(object.currency);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.email != null) {
            message.email = String(object.email);
          }
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.metadata) {
            if (typeof object.metadata !== "object") {
              throw new TypeError(".stripe.v1.Customer.metadata: object expected, but got " + (typeof object.metadata));
            }
            message.metadata = {};
            for (let keys = Object.keys(object.metadata), i = 0; i < keys.length; ++i) {
              message.metadata[keys[i]] = String(object.metadata[keys[i]]);
            }
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.phone != null) {
            message.phone = String(object.phone);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
            object.metadata = {};
          }
          if (options.defaults) {
            object.created = null;
          }
          let keys;
          if (message.address != null && message.hasOwnProperty("address")) {
            object.address = $root.stripe.v1.Address.toObject(message.address, options);
          }
          if (message.balance != null && message.hasOwnProperty("balance")) {
            object.balance = typeof message.balance === "number" ? (options.longs === String ? String(message.balance) : message.balance) : (options.longs === String ? $util.Long.prototype.toString.call(message.balance) : options.longs === Number ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber() : message.balance);
          }
          if (message.created != null && message.hasOwnProperty("created")) {
            object.created = timing$1.v1.Timestamp.toObject(message.created, options);
          }
          if (message.currency != null && message.hasOwnProperty("currency")) {
            object.currency = message.currency;
          }
          if (message.description != null && message.hasOwnProperty("description")) {
            object.description = message.description;
          }
          if (message.email != null && message.hasOwnProperty("email")) {
            object.email = message.email;
          }
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.metadata && (keys = Object.keys(message.metadata)).length) {
            object.metadata = {};
            for (let i = 0; i < keys.length; ++i) {
              object.metadata[keys[i]] = message.metadata[keys[i]];
            }
          }
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.phone != null && message.hasOwnProperty("phone")) {
            object.phone = message.phone;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stripe.v1.Customer";
        }
      }

      Customer.prototype.address = null;
      Customer.prototype.balance = null;
      Customer.prototype.created = null;
      Customer.prototype.currency = null;
      Customer.prototype.description = null;
      Customer.prototype.email = null;
      Customer.prototype.id = null;
      Customer.prototype.metadata = $util.emptyObject;
      Customer.prototype.name = null;
      Customer.prototype.phone = null;

      return Customer;
    })();

    v1.Address = (() => {
      class Address {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Address(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.city != null && Object.hasOwnProperty.call(message, "city")) {
            writer.uint32(10).string(message.city);
          }
          if (message.country != null && Object.hasOwnProperty.call(message, "country")) {
            writer.uint32(18).string(message.country);
          }
          if (message.line1 != null && Object.hasOwnProperty.call(message, "line1")) {
            writer.uint32(26).string(message.line1);
          }
          if (message.line2 != null && Object.hasOwnProperty.call(message, "line2")) {
            writer.uint32(34).string(message.line2);
          }
          if (message.postalCode != null && Object.hasOwnProperty.call(message, "postalCode")) {
            writer.uint32(42).string(message.postalCode);
          }
          if (message.state != null && Object.hasOwnProperty.call(message, "state")) {
            writer.uint32(50).string(message.state);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Address();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.city = reader.string();
                break;
              }
              case 2: {
                message.country = reader.string();
                break;
              }
              case 3: {
                message.line1 = reader.string();
                break;
              }
              case 4: {
                message.line2 = reader.string();
                break;
              }
              case 5: {
                message.postalCode = reader.string();
                break;
              }
              case 6: {
                message.state = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stripe.v1.Address) {
            return object;
          }
          const message = new $root.stripe.v1.Address();
          if (object.city != null) {
            message.city = String(object.city);
          }
          if (object.country != null) {
            message.country = String(object.country);
          }
          if (object.line1 != null) {
            message.line1 = String(object.line1);
          }
          if (object.line2 != null) {
            message.line2 = String(object.line2);
          }
          if (object.postalCode != null) {
            message.postalCode = String(object.postalCode);
          }
          if (object.state != null) {
            message.state = String(object.state);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.city != null && message.hasOwnProperty("city")) {
            object.city = message.city;
          }
          if (message.country != null && message.hasOwnProperty("country")) {
            object.country = message.country;
          }
          if (message.line1 != null && message.hasOwnProperty("line1")) {
            object.line1 = message.line1;
          }
          if (message.line2 != null && message.hasOwnProperty("line2")) {
            object.line2 = message.line2;
          }
          if (message.postalCode != null && message.hasOwnProperty("postalCode")) {
            object.postalCode = message.postalCode;
          }
          if (message.state != null && message.hasOwnProperty("state")) {
            object.state = message.state;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stripe.v1.Address";
        }
      }

      Address.prototype.city = null;
      Address.prototype.country = null;
      Address.prototype.line1 = null;
      Address.prototype.line2 = null;
      Address.prototype.postalCode = null;
      Address.prototype.state = null;

      return Address;
    })();

    v1.PaymentSource = (() => {
      class PaymentSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PaymentSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          if (message.customerId != null && Object.hasOwnProperty.call(message, "customerId")) {
            writer.uint32(18).string(message.customerId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PaymentSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.customerId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stripe.v1.PaymentSource) {
            return object;
          }
          const message = new $root.stripe.v1.PaymentSource();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.customerId != null) {
            message.customerId = String(object.customerId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.customerId != null && message.hasOwnProperty("customerId")) {
            object.customerId = message.customerId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stripe.v1.PaymentSource";
        }
      }

      PaymentSource.prototype.id = "";
      PaymentSource.prototype.customerId = null;

      return PaymentSource;
    })();

    return v1;
  })();

  return stripe;
})();

export const stripe = $root.stripe;
