/**
 * Formats the event payload stub object type by extracting and converting the first non-null nested field name to title case.
 *
 * @param {event_payload.v2.EventPayload} eventPayload - The event payload object to process.
 * @returns {string} The formatted object type as a title-cased string, or an empty string if no valid field is found.
 *
 * This function iterates through the eventPayload object and its nested objects to find the first non-null and non-undefined field.
 * It then converts this field name to title case and returns it. If no valid field is found, it returns an empty string.
 * This is future-proof for additional new fields that may be added in the future.
 *
 * Note: The function assumes that finding no valid field is impossible in practice, hence the comment "Impossible" for the empty string return.
 */

import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';
import { convertToTitleCase } from '../../../Helpers/convertToTitleCase';

export const formatEventPayloadStubObjectType = (
  eventPayloadStub: workflows.v1.Trigger['eventPayloadStub']
): string => {
  if (!eventPayloadStub) {
    return '';
  }

  const fields = Object.keys(eventPayloadStub) as Array<
    keyof typeof eventPayloadStub
  >;
  for (const field of fields) {
    const value = eventPayloadStub[field];
    if (value && typeof value === 'object') {
      const nestedFields = Object.keys(value);
      for (const nestedField of nestedFields) {
        if (nestedField == 'eventType') {
          continue;
        }
        if (value[nestedField as keyof typeof value] != null) {
          return convertToTitleCase(nestedField);
        }
      }
    }
  }
  return '';
};
