"use strict";

import * as $protobuf from "protobufjs/minimal";
import { stripe as stripe$1 } from "../../../stripe/v1/stripe_ts_proto";
import { workflow_parameters as workflow_parameters$1 } from "../../../workflow_parameters/v1/workflow_parameters_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.fetchactions = (() => {
  const fetchactions = {};
  fetchactions.stripe = (() => {
    const stripe = {};
    stripe.v1 = (() => {
      const v1 = {};
      v1.FetchAction = (() => {
        class FetchAction {
          get type() {
            for (const key of ["createOrUpdateCustomer", "createCard"]) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set type(name) {
            for (const key of ["createOrUpdateCustomer", "createCard"]) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new FetchAction(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.createOrUpdateCustomer != null && Object.hasOwnProperty.call(message, "createOrUpdateCustomer")) {
              $root.fetchactions.stripe.v1.CreateOrUpdateCustomer.encode(message.createOrUpdateCustomer, writer.uint32(10).fork()).ldelim();
            }
            if (message.createCard != null && Object.hasOwnProperty.call(message, "createCard")) {
              $root.fetchactions.stripe.v1.CreateCard.encode(message.createCard, writer.uint32(18).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new FetchAction();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.createOrUpdateCustomer = $root.fetchactions.stripe.v1.CreateOrUpdateCustomer.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.createCard = $root.fetchactions.stripe.v1.CreateCard.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.stripe.v1.FetchAction) {
              return object;
            }
            const message = new $root.fetchactions.stripe.v1.FetchAction();
            if (object.createOrUpdateCustomer != null) {
              if (typeof object.createOrUpdateCustomer !== "object") {
                throw new TypeError(".fetchactions.stripe.v1.FetchAction.createOrUpdateCustomer: object expected, but got " + (typeof object.createOrUpdateCustomer));
              }
              message.createOrUpdateCustomer = $root.fetchactions.stripe.v1.CreateOrUpdateCustomer.fromObject(object.createOrUpdateCustomer);
            }
            if (object.createCard != null) {
              if (typeof object.createCard !== "object") {
                throw new TypeError(".fetchactions.stripe.v1.FetchAction.createCard: object expected, but got " + (typeof object.createCard));
              }
              message.createCard = $root.fetchactions.stripe.v1.CreateCard.fromObject(object.createCard);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.createOrUpdateCustomer != null && message.hasOwnProperty("createOrUpdateCustomer")) {
              object.createOrUpdateCustomer = $root.fetchactions.stripe.v1.CreateOrUpdateCustomer.toObject(message.createOrUpdateCustomer, options);
              if (options.oneofs) {
                object.type = "createOrUpdateCustomer";
              }
            }
            if (message.createCard != null && message.hasOwnProperty("createCard")) {
              object.createCard = $root.fetchactions.stripe.v1.CreateCard.toObject(message.createCard, options);
              if (options.oneofs) {
                object.type = "createCard";
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.stripe.v1.FetchAction";
          }
        }

        FetchAction.prototype.createOrUpdateCustomer = null;
        FetchAction.prototype.createCard = null;

        return FetchAction;
      })();

      v1.CreateOrUpdateCustomer = (() => {
        class CreateOrUpdateCustomer {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateOrUpdateCustomer(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.doNotAssociateThirdPartyId != null && Object.hasOwnProperty.call(message, "doNotAssociateThirdPartyId")) {
              writer.uint32(32).bool(message.doNotAssociateThirdPartyId);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              stripe$1.v1.Customer.encode(message.result, writer.uint32(26).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateOrUpdateCustomer();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 4: {
                  message.doNotAssociateThirdPartyId = reader.bool();
                  break;
                }
                case 3: {
                  message.result = stripe$1.v1.Customer.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.stripe.v1.CreateOrUpdateCustomer) {
              return object;
            }
            const message = new $root.fetchactions.stripe.v1.CreateOrUpdateCustomer();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.stripe.v1.CreateOrUpdateCustomer.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.stripe.v1.CreateOrUpdateCustomer.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.stripe.v1.CreateOrUpdateCustomer.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.stripe.v1.CreateOrUpdateCustomer.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.doNotAssociateThirdPartyId != null) {
              message.doNotAssociateThirdPartyId = Boolean(object.doNotAssociateThirdPartyId);
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.stripe.v1.CreateOrUpdateCustomer.result: object expected, but got " + (typeof object.result));
              }
              message.result = stripe$1.v1.Customer.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.doNotAssociateThirdPartyId = false;
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.doNotAssociateThirdPartyId != null && message.hasOwnProperty("doNotAssociateThirdPartyId")) {
              object.doNotAssociateThirdPartyId = message.doNotAssociateThirdPartyId;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = stripe$1.v1.Customer.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.stripe.v1.CreateOrUpdateCustomer";
          }
        }

        CreateOrUpdateCustomer.prototype.params = $util.emptyArray;
        CreateOrUpdateCustomer.prototype.configs = $util.emptyArray;
        CreateOrUpdateCustomer.prototype.doNotAssociateThirdPartyId = false;
        CreateOrUpdateCustomer.prototype.result = null;

        return CreateOrUpdateCustomer;
      })();

      v1.CreateCard = (() => {
        class CreateCard {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateCard(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              stripe$1.v1.PaymentSource.encode(message.result, writer.uint32(34).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateCard();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.result = stripe$1.v1.PaymentSource.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.stripe.v1.CreateCard) {
              return object;
            }
            const message = new $root.fetchactions.stripe.v1.CreateCard();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.stripe.v1.CreateCard.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.stripe.v1.CreateCard.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.stripe.v1.CreateCard.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.stripe.v1.CreateCard.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.stripe.v1.CreateCard.result: object expected, but got " + (typeof object.result));
              }
              message.result = stripe$1.v1.PaymentSource.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = stripe$1.v1.PaymentSource.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.stripe.v1.CreateCard";
          }
        }

        CreateCard.prototype.params = $util.emptyArray;
        CreateCard.prototype.configs = $util.emptyArray;
        CreateCard.prototype.requiredThirdPartyIdMissingBehavior = 0;
        CreateCard.prototype.result = null;

        return CreateCard;
      })();

      return v1;
    })();

    return stripe;
  })();

  return fetchactions;
})();

export const fetchactions = $root.fetchactions;
