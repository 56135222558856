import { CustomNode } from '../../types';
import { Edge } from 'reactflow';
import { ProfileLookupNodeType } from '../types';
import { profiles } from '@morf/proto/profiles/v1/profiles_ts_proto';
import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';

export const profileLookupSubflowFieldNotFound = (
  index: number,
  lookup: profiles.v1.Lookup,
  lookupsLength: number
): {
  nodes: CustomNode[];
  edges: Edge[];
} => {
  let nodes: CustomNode[] = [];
  let edges: Edge[] = [];

  nodes.push({
    id: `lookup-subflow-field-not-found-${index}`,
    type: ProfileLookupNodeType.BranchNode,
    data: {
      value: {
        name: 'field not found',
        title: 'Field not found',
        configuration: new workflows.v1.WorkflowNode({}),
      },
    },
    parentNode: `lookup-subflow-field-lookup-${index}`,
    position: { x: 250, y: 100 },
  });

  edges.push({
    id: `lookup-subflow-field-lookup-${index}-field-not-found-${index}`,
    source: `lookup-subflow-field-lookup-${index}`,
    target: `lookup-subflow-field-not-found-${index}`,
  });

  switch (lookup.fieldNotPresentAction) {
    case profiles.v1.FieldNotPresentAction.FIELD_NOT_PRESENT_ACTION_ERROR:
      nodes.push({
        id: `lookup-subflow-error-${index}`,
        type: ProfileLookupNodeType.ErrorNode,
        data: {
          value: {
            name: 'error',
            title: 'Error',
            configuration: new workflows.v1.WorkflowNode({}),
          },
        },
        parentNode: `lookup-subflow-field-not-found-${index}`,
        position: { x: 0, y: 100 },
      });

      edges.push({
        id: `lookup-subflow-field-not-found-${index}-error-${index}`,
        source: `lookup-subflow-field-not-found-${index}`,
        target: `lookup-subflow-error-${index}`,
      });
      break;

    case profiles.v1.FieldNotPresentAction
      .FIELD_NOT_PRESENT_ACTION_SKIP_TO_NEXT_LOOKUP_IN_SEQUENCE:
      if (index < lookupsLength - 1) {
        edges.push({
          id: `lookup-subflow-field-not-found-${index}-field-lookup-${
            index + 1
          }`,
          source: `lookup-subflow-field-not-found-${index}`,
          target: `lookup-subflow-field-lookup-${index + 1}`,
        });
      }
      break;

    case profiles.v1.FieldNotPresentAction
      .FIELD_NOT_PRESENT_ACTION_TERMINATE_WORKFLOW_GRACEFULLY:
      nodes.push({
        id: `lookup-subflow-terminate-${index}`,
        type: ProfileLookupNodeType.TerminateNode,
        data: {
          value: {
            name: 'terminate',
            title: 'Terminate',
            configuration: new workflows.v1.WorkflowNode({}),
          },
        },
        parentNode: `lookup-subflow-field-not-found-${index}`,
        position: { x: 0, y: 100 },
      });

      edges.push({
        id: `lookup-subflow-field-not-found-${index}-terminate-${index}`,
        source: `lookup-subflow-field-not-found-${index}`,
        target: `lookup-subflow-terminate-${index}`,
      });

    default:
      break;
  }

  return { nodes, edges };
};
