import _ from 'lodash';
import { formatType } from '../../../../Helpers/formatType';
import { getObjectTypeIdFieldType } from './SelectValuePairModal/getObjectTypeIdFieldType';
import { google } from '@morf/proto/checked_ts_proto';
import { hasField } from '../../../../Helpers/hasField';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';

const formatListElementType = (
  fieldType:
    | workflow_parameters.v1.DynamicDestinationActionParameterType.ListType.ElemType
    | null
    | undefined,
  objectTypeIdContext:
    | workflow_parameters.v1.ObjectTypeIDContext
    | null
    | undefined
): string | undefined => {
  if (fieldType) {
    if (hasField(fieldType, 'primitiveType')) {
      return _.toLower(
        google.api.expr.v1alpha1.Type.PrimitiveType[fieldType.primitiveType]
      );
    }

    if (hasField(fieldType, 'wellKnownType')) {
      return _.toLower(
        google.api.expr.v1alpha1.Type.WellKnownType[fieldType.wellKnownType]
      );
    }

    if (hasField(fieldType, 'extendedType')) {
      return workflow_parameters.v1.ExtendedType.Type[fieldType.extendedType];
    }

    if (hasField(fieldType, 'messageType')) {
      if (
        _.isEqual(fieldType.messageType, 'workflow_parameters.v1.ObjectTypeID')
      ) {
        return getObjectTypeIdFieldType(objectTypeIdContext);
      }
      return 'messageType';
    }
  }
};

export const formatDynamicDestinationActionParameterType = (
  fieldType:
    | workflow_parameters.v1.DynamicDestinationActionParameterType
    | null
    | undefined,
  objectTypeIdContext:
    | workflow_parameters.v1.ObjectTypeIDContext
    | null
    | undefined
): string => {
  if (fieldType) {
    if (hasField(fieldType, 'listType')) {
      return `${formatListElementType(
        fieldType.listType?.elemType,
        objectTypeIdContext
      )}[]`;
    }

    if (hasField(fieldType, 'mapType')) {
      return `map <${formatType(
        fieldType.mapType?.keyType,
        objectTypeIdContext
      )},${formatType(fieldType.mapType?.valueType, objectTypeIdContext)}>`;
    }
  }

  return '';
};
