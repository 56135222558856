import { createActionMap } from '../DestinationActionNode/constants';
import { fetchactions as activecampaignFetchActions } from '@morf/proto/fetchactions/activecampaign/v1/activecampaign_ts_proto';
import { fetchactions as facebookFetchActions } from '@morf/proto/fetchactions/facebook/v1/facebook_ts_proto';
import { fetchactions as freshdeskFetchActions } from '@morf/proto/fetchactions/freshdesk/v1/freshdesk_ts_proto';
import { fetchactions as googleFetchActions } from '@morf/proto/fetchactions/google/v1/google_ts_proto';
import { fetchactions as healthieFetchActionsV1 } from '@morf/proto/fetchactions/healthie/v1/healthie_ts_proto';
import { fetchactions as healthieFetchActionsV2 } from '@morf/proto/fetchactions/healthie/v2/healthie_ts_proto';
import { fetchactions as hubspotFetchActions } from '@morf/proto/fetchactions/hubspot/v1/hubspot_ts_proto';
import { fetchactions as intakeqFetchActions } from '@morf/proto/fetchactions/intakeq/v1/intakeq_ts_proto';
import { fetchactions as intercomFetchActions } from '@morf/proto/fetchactions/intercom/v1/intercom_ts_proto';
import { fetchactions as mixpanelFetchActions } from '@morf/proto/fetchactions/mixpanel/v1/mixpanel_ts_proto';
import { fetchactions as morfFetchActions } from '@morf/proto/fetchactions/morf/v1/morf_ts_proto';
import { fetchactions as nextdoorFetchActions } from '@morf/proto/fetchactions/nextdoor/v1/nextdoor_ts_proto';
import { fetchactions as stediFetchActions } from '@morf/proto/fetchactions/stedi/v1/stedi_ts_proto';
import { fetchactions as stripeFetchActions } from '@morf/proto/fetchactions/stripe/v1/stripe_ts_proto';
import { fetchactions as tiktokFetchActions } from '@morf/proto/fetchactions/tiktok/v1/tiktok_ts_proto';
import { fetchactions as twilioFetchActions } from '@morf/proto/fetchactions/twilio/v1/twilio_ts_proto';

export const allActions = {
  ...createActionMap(
    activecampaignFetchActions.activecampaign.v1,
    'activecampaign.v1'
  ),
  ...createActionMap(facebookFetchActions.facebook.v1, 'facebook.v1'),
  ...createActionMap(freshdeskFetchActions.freshdesk.v1, 'freshdesk.v1'),
  ...createActionMap(googleFetchActions.google.v1, 'google.v1'),
  ...createActionMap(healthieFetchActionsV1.healthie.v1, 'healthie.v1'),
  ...createActionMap(healthieFetchActionsV2.healthie.v2, 'healthie.v2'),
  ...createActionMap(hubspotFetchActions.hubspot.v1, 'hubspot.v1'),
  ...createActionMap(intakeqFetchActions.intakeq.v1, 'intakeq.v1'),
  ...createActionMap(intercomFetchActions.intercom.v1, 'intercom.v1'),
  ...createActionMap(mixpanelFetchActions.mixpanel.v1, 'mixpanel.v1'),
  ...createActionMap(morfFetchActions.morf.v1, 'morf.v1'),
  ...createActionMap(nextdoorFetchActions.nextdoor.v1, 'nextdoor.v1'),
  ...createActionMap(stediFetchActions.stedi.v1, 'stedi.v1'),
  ...createActionMap(stripeFetchActions.stripe.v1, 'stripe.v1'),
  ...createActionMap(tiktokFetchActions.tiktok.v1, 'tiktok.v1'),
  ...createActionMap(twilioFetchActions.twilio.v1, 'twilio.v1'),
};
