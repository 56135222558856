import { FilterNodeProps } from './types';
import { NodeType, ParameterizedWorkflowNode } from '../types';
import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';

export const filterNode: FilterNodeProps = {
  id: '27c1a4a8-5450-4845-bdf2-cb51db315d49',
  type: NodeType.FilterNode,
  data: {
    value: {
      configuration: new workflows.v1.WorkflowNode({
        id: '27c1a4a8-5450-4845-bdf2-cb51db315d49',
        name: 'Current step id is email address',
        filter: new workflows.v1.WorkflowFilter({
          celExpressionStr:
            'event.current_step_id == "email_address" && event.calculated_eligibility.exists(x, x == "HEALTHIE")',
        }),
        validationIssues: [],
      }) as workflows.v1.WorkflowNode & ParameterizedWorkflowNode<'filter'>,
      name: 'Current step id is email address',
      title: 'Filter',
    },
  },
  position: { x: 0, y: 0 },
};
