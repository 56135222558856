"use strict";

import Long from "long";
import * as $protobuf from "protobufjs/minimal";
import { google as google$1 } from "../../../empty_ts_proto";
import { values as values$1 } from "../../../values/v1/values_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.google = (() => {
  const google = {};
  google.bigquery = (() => {
    const bigquery = {};
    bigquery.v1 = (() => {
      const v1 = {};
      v1.FieldSchemaChange = (() => {
        class FieldSchemaChange {
          get type() {
            for (const key of ["fieldAddition", "fieldUpdate"]) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set type(name) {
            for (const key of ["fieldAddition", "fieldUpdate"]) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            this.nestedFieldChanges = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new FieldSchemaChange(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.field != null && Object.hasOwnProperty.call(message, "field")) {
              writer.uint32(10).string(message.field);
            }
            if (message.description != null && Object.hasOwnProperty.call(message, "description")) {
              writer.uint32(18).string(message.description);
            }
            if (message.fieldType != null && Object.hasOwnProperty.call(message, "fieldType")) {
              writer.uint32(24).int32(message.fieldType);
            }
            if (message.repeated != null && Object.hasOwnProperty.call(message, "repeated")) {
              writer.uint32(32).bool(message.repeated);
            }
            if (message.required != null && Object.hasOwnProperty.call(message, "required")) {
              writer.uint32(40).bool(message.required);
            }
            if (message.nestedFieldChanges != null && Object.hasOwnProperty.call(message, "nestedFieldChanges")) {
              for (const element of message.nestedFieldChanges) {
                $root.google.bigquery.v1.FieldSchemaChange.encode(element, writer.uint32(50).fork()).ldelim();
              }
            }
            if (message.fieldAddition != null && Object.hasOwnProperty.call(message, "fieldAddition")) {
              google$1.protobuf.Empty.encode(message.fieldAddition, writer.uint32(58).fork()).ldelim();
            }
            if (message.fieldUpdate != null && Object.hasOwnProperty.call(message, "fieldUpdate")) {
              $root.google.bigquery.v1.FieldUpdate.encode(message.fieldUpdate, writer.uint32(66).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new FieldSchemaChange();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.field = reader.string();
                  break;
                }
                case 2: {
                  message.description = reader.string();
                  break;
                }
                case 3: {
                  message.fieldType = reader.int32();
                  break;
                }
                case 4: {
                  message.repeated = reader.bool();
                  break;
                }
                case 5: {
                  message.required = reader.bool();
                  break;
                }
                case 6: {
                  if (!message.nestedFieldChanges || !message.nestedFieldChanges.length) {
                    message.nestedFieldChanges = [];
                  }
                  message.nestedFieldChanges.push($root.google.bigquery.v1.FieldSchemaChange.decode(reader, reader.uint32()));
                  break;
                }
                case 7: {
                  message.fieldAddition = google$1.protobuf.Empty.decode(reader, reader.uint32());
                  break;
                }
                case 8: {
                  message.fieldUpdate = $root.google.bigquery.v1.FieldUpdate.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.google.bigquery.v1.FieldSchemaChange) {
              return object;
            }
            const message = new $root.google.bigquery.v1.FieldSchemaChange();
            if (object.field != null) {
              message.field = String(object.field);
            }
            if (object.description != null) {
              message.description = String(object.description);
            }
            if (object.fieldType != null) {
              switch (object.fieldType) {
                case "UNSPECIFIED_FIELD_TYPE":
                case 0: {
                  message.fieldType = 0;
                  break;
                }
                case "STRING":
                case 1: {
                  message.fieldType = 1;
                  break;
                }
                case "BYTES":
                case 2: {
                  message.fieldType = 2;
                  break;
                }
                case "INTEGER":
                case 3: {
                  message.fieldType = 3;
                  break;
                }
                case "FLOAT":
                case 4: {
                  message.fieldType = 4;
                  break;
                }
                case "BOOLEAN":
                case 5: {
                  message.fieldType = 5;
                  break;
                }
                case "TIMESTAMP":
                case 6: {
                  message.fieldType = 6;
                  break;
                }
                case "RECORD":
                case 7: {
                  message.fieldType = 7;
                  break;
                }
                case "DATE":
                case 8: {
                  message.fieldType = 8;
                  break;
                }
                case "TIME":
                case 9: {
                  message.fieldType = 9;
                  break;
                }
                case "DATETIME":
                case 10: {
                  message.fieldType = 10;
                  break;
                }
                case "NUMERIC":
                case 11: {
                  message.fieldType = 11;
                  break;
                }
                case "GEOGRAPHY":
                case 12: {
                  message.fieldType = 12;
                  break;
                }
                case "BIGNUMERIC":
                case 13: {
                  message.fieldType = 13;
                  break;
                }
                case "INTERVAL":
                case 14: {
                  message.fieldType = 14;
                  break;
                }
                case "JSON":
                case 15: {
                  message.fieldType = 15;
                  break;
                }
                case "RANGE":
                case 16: {
                  message.fieldType = 16;
                  break;
                }
                default: {
                  if (typeof object.fieldType == "number") {
                    message.fieldType = object.fieldType;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.repeated != null) {
              message.repeated = Boolean(object.repeated);
            }
            if (object.required != null) {
              message.required = Boolean(object.required);
            }
            if (object.nestedFieldChanges) {
              if (!Array.isArray(object.nestedFieldChanges)) {
                throw new TypeError(".google.bigquery.v1.FieldSchemaChange.nestedFieldChanges: array type expected, but got " + (typeof object.nestedFieldChanges))
              }
              message.nestedFieldChanges = new Array(object.nestedFieldChanges.length);
              for (let i = 0; i < object.nestedFieldChanges.length; ++i) {
                if (typeof object.nestedFieldChanges[i] !== "object") {
                  throw new TypeError(".google.bigquery.v1.FieldSchemaChange.nestedFieldChanges: object expected, but got " + (typeof object.nestedFieldChanges[i]));
                }
                message.nestedFieldChanges[i] = $root.google.bigquery.v1.FieldSchemaChange.fromObject(object.nestedFieldChanges[i]);
              }
            }
            if (object.fieldAddition != null) {
              if (typeof object.fieldAddition !== "object") {
                throw new TypeError(".google.bigquery.v1.FieldSchemaChange.fieldAddition: object expected, but got " + (typeof object.fieldAddition));
              }
              message.fieldAddition = google$1.protobuf.Empty.fromObject(object.fieldAddition);
            }
            if (object.fieldUpdate != null) {
              if (typeof object.fieldUpdate !== "object") {
                throw new TypeError(".google.bigquery.v1.FieldSchemaChange.fieldUpdate: object expected, but got " + (typeof object.fieldUpdate));
              }
              message.fieldUpdate = $root.google.bigquery.v1.FieldUpdate.fromObject(object.fieldUpdate);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.nestedFieldChanges = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.field = "";
              object.fieldType = options.enums === String ? "UNSPECIFIED_FIELD_TYPE" : 0
              object.repeated = false;
              object.required = false;
            }
            let keys;
            if (message.field != null && message.hasOwnProperty("field")) {
              object.field = message.field;
            }
            if (message.description != null && message.hasOwnProperty("description")) {
              object.description = message.description;
            }
            if (message.fieldType != null && message.hasOwnProperty("fieldType")) {
              object.fieldType = options.enums === String ? $root.google.bigquery.v1.FieldType[message.fieldType] === undefined ? message.fieldType : $root.google.bigquery.v1.FieldType[message.fieldType] : message.fieldType;
            }
            if (message.repeated != null && message.hasOwnProperty("repeated")) {
              object.repeated = message.repeated;
            }
            if (message.required != null && message.hasOwnProperty("required")) {
              object.required = message.required;
            }
            if (message.nestedFieldChanges && message.nestedFieldChanges.length) {
              object.nestedFieldChanges = new Array(message.nestedFieldChanges.length);
              for (let i = 0; i < message.nestedFieldChanges.length; ++i) {
                object.nestedFieldChanges[i] = $root.google.bigquery.v1.FieldSchemaChange.toObject(message.nestedFieldChanges[i], options);
              }
            }
            if (message.fieldAddition != null && message.hasOwnProperty("fieldAddition")) {
              object.fieldAddition = google$1.protobuf.Empty.toObject(message.fieldAddition, options);
              if (options.oneofs) {
                object.type = "fieldAddition";
              }
            }
            if (message.fieldUpdate != null && message.hasOwnProperty("fieldUpdate")) {
              object.fieldUpdate = $root.google.bigquery.v1.FieldUpdate.toObject(message.fieldUpdate, options);
              if (options.oneofs) {
                object.type = "fieldUpdate";
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "google.bigquery.v1.FieldSchemaChange";
          }
        }

        FieldSchemaChange.prototype.field = "";
        FieldSchemaChange.prototype.description = null;
        FieldSchemaChange.prototype.fieldType = 0;
        FieldSchemaChange.prototype.repeated = false;
        FieldSchemaChange.prototype.required = false;
        FieldSchemaChange.prototype.nestedFieldChanges = $util.emptyArray;
        FieldSchemaChange.prototype.fieldAddition = null;
        FieldSchemaChange.prototype.fieldUpdate = null;

        return FieldSchemaChange;
      })();

      v1.FieldUpdate = (() => {
        class FieldUpdate {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new FieldUpdate(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.newDescription != null && Object.hasOwnProperty.call(message, "newDescription")) {
              writer.uint32(10).string(message.newDescription);
            }
            if (message.newFieldType != null && Object.hasOwnProperty.call(message, "newFieldType")) {
              writer.uint32(16).int32(message.newFieldType);
            }
            if (message.newRepeated != null && Object.hasOwnProperty.call(message, "newRepeated")) {
              writer.uint32(24).bool(message.newRepeated);
            }
            if (message.newRequired != null && Object.hasOwnProperty.call(message, "newRequired")) {
              writer.uint32(32).bool(message.newRequired);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new FieldUpdate();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.newDescription = reader.string();
                  break;
                }
                case 2: {
                  message.newFieldType = reader.int32();
                  break;
                }
                case 3: {
                  message.newRepeated = reader.bool();
                  break;
                }
                case 4: {
                  message.newRequired = reader.bool();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.google.bigquery.v1.FieldUpdate) {
              return object;
            }
            const message = new $root.google.bigquery.v1.FieldUpdate();
            if (object.newDescription != null) {
              message.newDescription = String(object.newDescription);
            }
            if (object.newFieldType != null) {
              switch (object.newFieldType) {
                case "UNSPECIFIED_FIELD_TYPE":
                case 0: {
                  message.newFieldType = 0;
                  break;
                }
                case "STRING":
                case 1: {
                  message.newFieldType = 1;
                  break;
                }
                case "BYTES":
                case 2: {
                  message.newFieldType = 2;
                  break;
                }
                case "INTEGER":
                case 3: {
                  message.newFieldType = 3;
                  break;
                }
                case "FLOAT":
                case 4: {
                  message.newFieldType = 4;
                  break;
                }
                case "BOOLEAN":
                case 5: {
                  message.newFieldType = 5;
                  break;
                }
                case "TIMESTAMP":
                case 6: {
                  message.newFieldType = 6;
                  break;
                }
                case "RECORD":
                case 7: {
                  message.newFieldType = 7;
                  break;
                }
                case "DATE":
                case 8: {
                  message.newFieldType = 8;
                  break;
                }
                case "TIME":
                case 9: {
                  message.newFieldType = 9;
                  break;
                }
                case "DATETIME":
                case 10: {
                  message.newFieldType = 10;
                  break;
                }
                case "NUMERIC":
                case 11: {
                  message.newFieldType = 11;
                  break;
                }
                case "GEOGRAPHY":
                case 12: {
                  message.newFieldType = 12;
                  break;
                }
                case "BIGNUMERIC":
                case 13: {
                  message.newFieldType = 13;
                  break;
                }
                case "INTERVAL":
                case 14: {
                  message.newFieldType = 14;
                  break;
                }
                case "JSON":
                case 15: {
                  message.newFieldType = 15;
                  break;
                }
                case "RANGE":
                case 16: {
                  message.newFieldType = 16;
                  break;
                }
                default: {
                  if (typeof object.newFieldType == "number") {
                    message.newFieldType = object.newFieldType;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.newRepeated != null) {
              message.newRepeated = Boolean(object.newRepeated);
            }
            if (object.newRequired != null) {
              message.newRequired = Boolean(object.newRequired);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.newDescription != null && message.hasOwnProperty("newDescription")) {
              object.newDescription = message.newDescription;
            }
            if (message.newFieldType != null && message.hasOwnProperty("newFieldType")) {
              object.newFieldType = options.enums === String ? $root.google.bigquery.v1.FieldType[message.newFieldType] === undefined ? message.newFieldType : $root.google.bigquery.v1.FieldType[message.newFieldType] : message.newFieldType;
            }
            if (message.newRepeated != null && message.hasOwnProperty("newRepeated")) {
              object.newRepeated = message.newRepeated;
            }
            if (message.newRequired != null && message.hasOwnProperty("newRequired")) {
              object.newRequired = message.newRequired;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "google.bigquery.v1.FieldUpdate";
          }
        }

        FieldUpdate.prototype.newDescription = null;
        FieldUpdate.prototype.newFieldType = null;
        FieldUpdate.prototype.newRepeated = null;
        FieldUpdate.prototype.newRequired = null;

        return FieldUpdate;
      })();

      v1.Field = (() => {
        class Field {
          constructor(properties) {
            this.fields = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Field(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
              writer.uint32(10).string(message.name);
            }
            if (message.type != null && Object.hasOwnProperty.call(message, "type")) {
              writer.uint32(16).int32(message.type);
            }
            if (message.repeated != null && Object.hasOwnProperty.call(message, "repeated")) {
              writer.uint32(24).bool(message.repeated);
            }
            if (message.required != null && Object.hasOwnProperty.call(message, "required")) {
              writer.uint32(32).bool(message.required);
            }
            if (message.description != null && Object.hasOwnProperty.call(message, "description")) {
              writer.uint32(42).string(message.description);
            }
            if (message.fields != null && Object.hasOwnProperty.call(message, "fields")) {
              for (const element of message.fields) {
                $root.google.bigquery.v1.Field.encode(element, writer.uint32(50).fork()).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Field();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.name = reader.string();
                  break;
                }
                case 2: {
                  message.type = reader.int32();
                  break;
                }
                case 3: {
                  message.repeated = reader.bool();
                  break;
                }
                case 4: {
                  message.required = reader.bool();
                  break;
                }
                case 5: {
                  message.description = reader.string();
                  break;
                }
                case 6: {
                  if (!message.fields || !message.fields.length) {
                    message.fields = [];
                  }
                  message.fields.push($root.google.bigquery.v1.Field.decode(reader, reader.uint32()));
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.google.bigquery.v1.Field) {
              return object;
            }
            const message = new $root.google.bigquery.v1.Field();
            if (object.name != null) {
              message.name = String(object.name);
            }
            if (object.type != null) {
              switch (object.type) {
                case "UNSPECIFIED_FIELD_TYPE":
                case 0: {
                  message.type = 0;
                  break;
                }
                case "STRING":
                case 1: {
                  message.type = 1;
                  break;
                }
                case "BYTES":
                case 2: {
                  message.type = 2;
                  break;
                }
                case "INTEGER":
                case 3: {
                  message.type = 3;
                  break;
                }
                case "FLOAT":
                case 4: {
                  message.type = 4;
                  break;
                }
                case "BOOLEAN":
                case 5: {
                  message.type = 5;
                  break;
                }
                case "TIMESTAMP":
                case 6: {
                  message.type = 6;
                  break;
                }
                case "RECORD":
                case 7: {
                  message.type = 7;
                  break;
                }
                case "DATE":
                case 8: {
                  message.type = 8;
                  break;
                }
                case "TIME":
                case 9: {
                  message.type = 9;
                  break;
                }
                case "DATETIME":
                case 10: {
                  message.type = 10;
                  break;
                }
                case "NUMERIC":
                case 11: {
                  message.type = 11;
                  break;
                }
                case "GEOGRAPHY":
                case 12: {
                  message.type = 12;
                  break;
                }
                case "BIGNUMERIC":
                case 13: {
                  message.type = 13;
                  break;
                }
                case "INTERVAL":
                case 14: {
                  message.type = 14;
                  break;
                }
                case "JSON":
                case 15: {
                  message.type = 15;
                  break;
                }
                case "RANGE":
                case 16: {
                  message.type = 16;
                  break;
                }
                default: {
                  if (typeof object.type == "number") {
                    message.type = object.type;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.repeated != null) {
              message.repeated = Boolean(object.repeated);
            }
            if (object.required != null) {
              message.required = Boolean(object.required);
            }
            if (object.description != null) {
              message.description = String(object.description);
            }
            if (object.fields) {
              if (!Array.isArray(object.fields)) {
                throw new TypeError(".google.bigquery.v1.Field.fields: array type expected, but got " + (typeof object.fields))
              }
              message.fields = new Array(object.fields.length);
              for (let i = 0; i < object.fields.length; ++i) {
                if (typeof object.fields[i] !== "object") {
                  throw new TypeError(".google.bigquery.v1.Field.fields: object expected, but got " + (typeof object.fields[i]));
                }
                message.fields[i] = $root.google.bigquery.v1.Field.fromObject(object.fields[i]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.fields = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.name = "";
              object.type = options.enums === String ? "UNSPECIFIED_FIELD_TYPE" : 0
              object.repeated = false;
              object.required = false;
              object.description = "";
            }
            let keys;
            if (message.name != null && message.hasOwnProperty("name")) {
              object.name = message.name;
            }
            if (message.type != null && message.hasOwnProperty("type")) {
              object.type = options.enums === String ? $root.google.bigquery.v1.FieldType[message.type] === undefined ? message.type : $root.google.bigquery.v1.FieldType[message.type] : message.type;
            }
            if (message.repeated != null && message.hasOwnProperty("repeated")) {
              object.repeated = message.repeated;
            }
            if (message.required != null && message.hasOwnProperty("required")) {
              object.required = message.required;
            }
            if (message.description != null && message.hasOwnProperty("description")) {
              object.description = message.description;
            }
            if (message.fields && message.fields.length) {
              object.fields = new Array(message.fields.length);
              for (let i = 0; i < message.fields.length; ++i) {
                object.fields[i] = $root.google.bigquery.v1.Field.toObject(message.fields[i], options);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "google.bigquery.v1.Field";
          }
        }

        Field.prototype.name = "";
        Field.prototype.type = 0;
        Field.prototype.repeated = false;
        Field.prototype.required = false;
        Field.prototype.description = "";
        Field.prototype.fields = $util.emptyArray;

        return Field;
      })();

      v1.SaveToBigQueryResponse = (() => {
        class SaveToBigQueryResponse {
          constructor(properties) {
            this.schemaDifferences = [];
            this.tableSchema = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new SaveToBigQueryResponse(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.partition != null && Object.hasOwnProperty.call(message, "partition")) {
              values$1.v1.Date.encode(message.partition, writer.uint32(10).fork()).ldelim();
            }
            if (message.estimatedRowCount != null && Object.hasOwnProperty.call(message, "estimatedRowCount")) {
              writer.uint32(16).uint64(message.estimatedRowCount);
            }
            if (message.schemaDifferences != null && Object.hasOwnProperty.call(message, "schemaDifferences")) {
              for (const element of message.schemaDifferences) {
                $root.google.bigquery.v1.FieldSchemaChange.encode(element, writer.uint32(26).fork()).ldelim();
              }
            }
            if (message.tableSchema != null && Object.hasOwnProperty.call(message, "tableSchema")) {
              for (const element of message.tableSchema) {
                $root.google.bigquery.v1.Field.encode(element, writer.uint32(34).fork()).ldelim();
              }
            }
            if (message.tableName != null && Object.hasOwnProperty.call(message, "tableName")) {
              writer.uint32(42).string(message.tableName);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new SaveToBigQueryResponse();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.partition = values$1.v1.Date.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.estimatedRowCount = reader.uint64();
                  break;
                }
                case 3: {
                  if (!message.schemaDifferences || !message.schemaDifferences.length) {
                    message.schemaDifferences = [];
                  }
                  message.schemaDifferences.push($root.google.bigquery.v1.FieldSchemaChange.decode(reader, reader.uint32()));
                  break;
                }
                case 4: {
                  if (!message.tableSchema || !message.tableSchema.length) {
                    message.tableSchema = [];
                  }
                  message.tableSchema.push($root.google.bigquery.v1.Field.decode(reader, reader.uint32()));
                  break;
                }
                case 5: {
                  message.tableName = reader.string();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.google.bigquery.v1.SaveToBigQueryResponse) {
              return object;
            }
            const message = new $root.google.bigquery.v1.SaveToBigQueryResponse();
            if (object.partition != null) {
              if (typeof object.partition !== "object") {
                throw new TypeError(".google.bigquery.v1.SaveToBigQueryResponse.partition: object expected, but got " + (typeof object.partition));
              }
              message.partition = values$1.v1.Date.fromObject(object.partition);
            }
            if (object.estimatedRowCount != null) {
              if ($util.Long) {
                (message.estimatedRowCount = $util.Long.fromValue(object.estimatedRowCount)).unsigned = true;
              } else if (typeof object.estimatedRowCount === "string") {
                message.estimatedRowCount = parseInt(object.estimatedRowCount, 10);
              } else if (typeof object.estimatedRowCount === "number") {
                message.estimatedRowCount = object.estimatedRowCount;
              } else if (typeof object.estimatedRowCount === "object") {
                message.estimatedRowCount = new $util.LongBits(object.estimatedRowCount.low >>> 0, object.estimatedRowCount.high >>> 0).toNumber(true);
              }
            }
            if (object.schemaDifferences) {
              if (!Array.isArray(object.schemaDifferences)) {
                throw new TypeError(".google.bigquery.v1.SaveToBigQueryResponse.schemaDifferences: array type expected, but got " + (typeof object.schemaDifferences))
              }
              message.schemaDifferences = new Array(object.schemaDifferences.length);
              for (let i = 0; i < object.schemaDifferences.length; ++i) {
                if (typeof object.schemaDifferences[i] !== "object") {
                  throw new TypeError(".google.bigquery.v1.SaveToBigQueryResponse.schemaDifferences: object expected, but got " + (typeof object.schemaDifferences[i]));
                }
                message.schemaDifferences[i] = $root.google.bigquery.v1.FieldSchemaChange.fromObject(object.schemaDifferences[i]);
              }
            }
            if (object.tableSchema) {
              if (!Array.isArray(object.tableSchema)) {
                throw new TypeError(".google.bigquery.v1.SaveToBigQueryResponse.tableSchema: array type expected, but got " + (typeof object.tableSchema))
              }
              message.tableSchema = new Array(object.tableSchema.length);
              for (let i = 0; i < object.tableSchema.length; ++i) {
                if (typeof object.tableSchema[i] !== "object") {
                  throw new TypeError(".google.bigquery.v1.SaveToBigQueryResponse.tableSchema: object expected, but got " + (typeof object.tableSchema[i]));
                }
                message.tableSchema[i] = $root.google.bigquery.v1.Field.fromObject(object.tableSchema[i]);
              }
            }
            if (object.tableName != null) {
              message.tableName = String(object.tableName);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.schemaDifferences = [];
              object.tableSchema = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.partition = null;
              if ($util.Long) {
                let long = new $util.Long(0, 0, true)
                object.estimatedRowCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
              } else {
                object.estimatedRowCount = options.longs === String ? "0" : 0;
              }
              object.tableName = "";
            }
            let keys;
            if (message.partition != null && message.hasOwnProperty("partition")) {
              object.partition = values$1.v1.Date.toObject(message.partition, options);
            }
            if (message.estimatedRowCount != null && message.hasOwnProperty("estimatedRowCount")) {
              object.estimatedRowCount = typeof message.estimatedRowCount === "number" ? (options.longs === String ? String(message.estimatedRowCount) : message.estimatedRowCount) : (options.longs === String ? $util.Long.prototype.toString.call(message.estimatedRowCount) : options.longs === Number ? new $util.LongBits(message.estimatedRowCount.low >>> 0, message.estimatedRowCount.high >>> 0).toNumber(true) : message.estimatedRowCount);
            }
            if (message.schemaDifferences && message.schemaDifferences.length) {
              object.schemaDifferences = new Array(message.schemaDifferences.length);
              for (let i = 0; i < message.schemaDifferences.length; ++i) {
                object.schemaDifferences[i] = $root.google.bigquery.v1.FieldSchemaChange.toObject(message.schemaDifferences[i], options);
              }
            }
            if (message.tableSchema && message.tableSchema.length) {
              object.tableSchema = new Array(message.tableSchema.length);
              for (let i = 0; i < message.tableSchema.length; ++i) {
                object.tableSchema[i] = $root.google.bigquery.v1.Field.toObject(message.tableSchema[i], options);
              }
            }
            if (message.tableName != null && message.hasOwnProperty("tableName")) {
              object.tableName = message.tableName;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "google.bigquery.v1.SaveToBigQueryResponse";
          }
        }

        SaveToBigQueryResponse.prototype.partition = null;
        SaveToBigQueryResponse.prototype.estimatedRowCount = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
        SaveToBigQueryResponse.prototype.schemaDifferences = $util.emptyArray;
        SaveToBigQueryResponse.prototype.tableSchema = $util.emptyArray;
        SaveToBigQueryResponse.prototype.tableName = "";

        return SaveToBigQueryResponse;
      })();

      v1.FieldType = (function() {
        const valuesById = {};
        const values = Object.create(valuesById);
        values[valuesById[0] = "UNSPECIFIED_FIELD_TYPE"] = 0;
        values[valuesById[1] = "STRING"] = 1;
        values[valuesById[2] = "BYTES"] = 2;
        values[valuesById[3] = "INTEGER"] = 3;
        values[valuesById[4] = "FLOAT"] = 4;
        values[valuesById[5] = "BOOLEAN"] = 5;
        values[valuesById[6] = "TIMESTAMP"] = 6;
        values[valuesById[7] = "RECORD"] = 7;
        values[valuesById[8] = "DATE"] = 8;
        values[valuesById[9] = "TIME"] = 9;
        values[valuesById[10] = "DATETIME"] = 10;
        values[valuesById[11] = "NUMERIC"] = 11;
        values[valuesById[12] = "GEOGRAPHY"] = 12;
        values[valuesById[13] = "BIGNUMERIC"] = 13;
        values[valuesById[14] = "INTERVAL"] = 14;
        values[valuesById[15] = "JSON"] = 15;
        values[valuesById[16] = "RANGE"] = 16;
        return values;
      })();

      return v1;
    })();

    return bigquery;
  })();

  return google;
})();

export const google = $root.google;
