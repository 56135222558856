"use strict";

import * as $protobuf from "protobufjs/minimal";
import { scheduling as scheduling$1 } from "../../scheduling/v1/scheduling_ts_proto";
import { google as google$1 } from "../../struct_ts_proto";
import { timing as timing$1 } from "../../timing/v1/timing_ts_proto";
import { values as values$1 } from "../../values/v1/values_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.morf_analytics = (() => {
  const morf_analytics = {};
  morf_analytics.v1 = (() => {
    const v1 = {};
    v1.TrackEventType = (() => {
      class TrackEventType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new TrackEventType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.eventName != null && Object.hasOwnProperty.call(message, "eventName")) {
            writer.uint32(10).string(message.eventName);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new TrackEventType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventName = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.morf_analytics.v1.TrackEventType) {
            return object;
          }
          const message = new $root.morf_analytics.v1.TrackEventType();
          if (object.eventName != null) {
            message.eventName = String(object.eventName);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventName = "";
          }
          let keys;
          if (message.eventName != null && message.hasOwnProperty("eventName")) {
            object.eventName = message.eventName;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "morf_analytics.v1.TrackEventType";
        }
      }

      TrackEventType.prototype.eventName = "";

      return TrackEventType;
    })();

    v1.SubscribedEventTypes = (() => {
      class SubscribedEventTypes {
        constructor(properties) {
          this.eventTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SubscribedEventTypes(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.eventTypes != null && Object.hasOwnProperty.call(message, "eventTypes")) {
            for (const element of message.eventTypes) {
              $root.morf_analytics.v1.TrackEventType.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SubscribedEventTypes();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.eventTypes || !message.eventTypes.length) {
                  message.eventTypes = [];
                }
                message.eventTypes.push($root.morf_analytics.v1.TrackEventType.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.morf_analytics.v1.SubscribedEventTypes) {
            return object;
          }
          const message = new $root.morf_analytics.v1.SubscribedEventTypes();
          if (object.eventTypes) {
            if (!Array.isArray(object.eventTypes)) {
              throw new TypeError(".morf_analytics.v1.SubscribedEventTypes.eventTypes: array type expected, but got " + (typeof object.eventTypes))
            }
            message.eventTypes = new Array(object.eventTypes.length);
            for (let i = 0; i < object.eventTypes.length; ++i) {
              if (typeof object.eventTypes[i] !== "object") {
                throw new TypeError(".morf_analytics.v1.SubscribedEventTypes.eventTypes: object expected, but got " + (typeof object.eventTypes[i]));
              }
              message.eventTypes[i] = $root.morf_analytics.v1.TrackEventType.fromObject(object.eventTypes[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.eventTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.eventTypes && message.eventTypes.length) {
            object.eventTypes = new Array(message.eventTypes.length);
            for (let i = 0; i < message.eventTypes.length; ++i) {
              object.eventTypes[i] = $root.morf_analytics.v1.TrackEventType.toObject(message.eventTypes[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "morf_analytics.v1.SubscribedEventTypes";
        }
      }

      SubscribedEventTypes.prototype.eventTypes = $util.emptyArray;

      return SubscribedEventTypes;
    })();

    v1.TrackProfileIds = (() => {
      class TrackProfileIds {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new TrackProfileIds(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.customer != null && Object.hasOwnProperty.call(message, "customer")) {
            writer.uint32(10).string(message.customer);
          }
          if (message.formsort != null && Object.hasOwnProperty.call(message, "formsort")) {
            writer.uint32(18).string(message.formsort);
          }
          if (message.healthie != null && Object.hasOwnProperty.call(message, "healthie")) {
            writer.uint32(26).string(message.healthie);
          }
          if (message.axleHealth != null && Object.hasOwnProperty.call(message, "axleHealth")) {
            writer.uint32(34).string(message.axleHealth);
          }
          if (message.butterflyLabs != null && Object.hasOwnProperty.call(message, "butterflyLabs")) {
            writer.uint32(42).string(message.butterflyLabs);
          }
          if (message.recurly != null && Object.hasOwnProperty.call(message, "recurly")) {
            writer.uint32(50).string(message.recurly);
          }
          if (message.intercom != null && Object.hasOwnProperty.call(message, "intercom")) {
            writer.uint32(58).string(message.intercom);
          }
          if (message.sanaBenefits != null && Object.hasOwnProperty.call(message, "sanaBenefits")) {
            writer.uint32(66).string(message.sanaBenefits);
          }
          if (message.activeCampaign != null && Object.hasOwnProperty.call(message, "activeCampaign")) {
            writer.uint32(74).string(message.activeCampaign);
          }
          if (message.junction != null && Object.hasOwnProperty.call(message, "junction")) {
            writer.uint32(82).string(message.junction);
          }
          if (message.segment != null && Object.hasOwnProperty.call(message, "segment")) {
            writer.uint32(90).string(message.segment);
          }
          if (message.intakeq != null && Object.hasOwnProperty.call(message, "intakeq")) {
            writer.uint32(98).string(message.intakeq);
          }
          if (message.customerIo != null && Object.hasOwnProperty.call(message, "customerIo")) {
            writer.uint32(106).string(message.customerIo);
          }
          if (message.freshdesk != null && Object.hasOwnProperty.call(message, "freshdesk")) {
            writer.uint32(114).string(message.freshdesk);
          }
          if (message.hubspot != null && Object.hasOwnProperty.call(message, "hubspot")) {
            writer.uint32(122).string(message.hubspot);
          }
          if (message.stripe != null && Object.hasOwnProperty.call(message, "stripe")) {
            writer.uint32(130).string(message.stripe);
          }
          if (message.feathery != null && Object.hasOwnProperty.call(message, "feathery")) {
            writer.uint32(138).string(message.feathery);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new TrackProfileIds();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.customer = reader.string();
                break;
              }
              case 2: {
                message.formsort = reader.string();
                break;
              }
              case 3: {
                message.healthie = reader.string();
                break;
              }
              case 4: {
                message.axleHealth = reader.string();
                break;
              }
              case 5: {
                message.butterflyLabs = reader.string();
                break;
              }
              case 6: {
                message.recurly = reader.string();
                break;
              }
              case 7: {
                message.intercom = reader.string();
                break;
              }
              case 8: {
                message.sanaBenefits = reader.string();
                break;
              }
              case 9: {
                message.activeCampaign = reader.string();
                break;
              }
              case 10: {
                message.junction = reader.string();
                break;
              }
              case 11: {
                message.segment = reader.string();
                break;
              }
              case 12: {
                message.intakeq = reader.string();
                break;
              }
              case 13: {
                message.customerIo = reader.string();
                break;
              }
              case 14: {
                message.freshdesk = reader.string();
                break;
              }
              case 15: {
                message.hubspot = reader.string();
                break;
              }
              case 16: {
                message.stripe = reader.string();
                break;
              }
              case 17: {
                message.feathery = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.morf_analytics.v1.TrackProfileIds) {
            return object;
          }
          const message = new $root.morf_analytics.v1.TrackProfileIds();
          if (object.customer != null) {
            message.customer = String(object.customer);
          }
          if (object.formsort != null) {
            message.formsort = String(object.formsort);
          }
          if (object.healthie != null) {
            message.healthie = String(object.healthie);
          }
          if (object.axleHealth != null) {
            message.axleHealth = String(object.axleHealth);
          }
          if (object.butterflyLabs != null) {
            message.butterflyLabs = String(object.butterflyLabs);
          }
          if (object.recurly != null) {
            message.recurly = String(object.recurly);
          }
          if (object.intercom != null) {
            message.intercom = String(object.intercom);
          }
          if (object.sanaBenefits != null) {
            message.sanaBenefits = String(object.sanaBenefits);
          }
          if (object.activeCampaign != null) {
            message.activeCampaign = String(object.activeCampaign);
          }
          if (object.junction != null) {
            message.junction = String(object.junction);
          }
          if (object.segment != null) {
            message.segment = String(object.segment);
          }
          if (object.intakeq != null) {
            message.intakeq = String(object.intakeq);
          }
          if (object.customerIo != null) {
            message.customerIo = String(object.customerIo);
          }
          if (object.freshdesk != null) {
            message.freshdesk = String(object.freshdesk);
          }
          if (object.hubspot != null) {
            message.hubspot = String(object.hubspot);
          }
          if (object.stripe != null) {
            message.stripe = String(object.stripe);
          }
          if (object.feathery != null) {
            message.feathery = String(object.feathery);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.customer != null && message.hasOwnProperty("customer")) {
            object.customer = message.customer;
          }
          if (message.formsort != null && message.hasOwnProperty("formsort")) {
            object.formsort = message.formsort;
          }
          if (message.healthie != null && message.hasOwnProperty("healthie")) {
            object.healthie = message.healthie;
          }
          if (message.axleHealth != null && message.hasOwnProperty("axleHealth")) {
            object.axleHealth = message.axleHealth;
          }
          if (message.butterflyLabs != null && message.hasOwnProperty("butterflyLabs")) {
            object.butterflyLabs = message.butterflyLabs;
          }
          if (message.recurly != null && message.hasOwnProperty("recurly")) {
            object.recurly = message.recurly;
          }
          if (message.intercom != null && message.hasOwnProperty("intercom")) {
            object.intercom = message.intercom;
          }
          if (message.sanaBenefits != null && message.hasOwnProperty("sanaBenefits")) {
            object.sanaBenefits = message.sanaBenefits;
          }
          if (message.activeCampaign != null && message.hasOwnProperty("activeCampaign")) {
            object.activeCampaign = message.activeCampaign;
          }
          if (message.junction != null && message.hasOwnProperty("junction")) {
            object.junction = message.junction;
          }
          if (message.segment != null && message.hasOwnProperty("segment")) {
            object.segment = message.segment;
          }
          if (message.intakeq != null && message.hasOwnProperty("intakeq")) {
            object.intakeq = message.intakeq;
          }
          if (message.customerIo != null && message.hasOwnProperty("customerIo")) {
            object.customerIo = message.customerIo;
          }
          if (message.freshdesk != null && message.hasOwnProperty("freshdesk")) {
            object.freshdesk = message.freshdesk;
          }
          if (message.hubspot != null && message.hasOwnProperty("hubspot")) {
            object.hubspot = message.hubspot;
          }
          if (message.stripe != null && message.hasOwnProperty("stripe")) {
            object.stripe = message.stripe;
          }
          if (message.feathery != null && message.hasOwnProperty("feathery")) {
            object.feathery = message.feathery;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "morf_analytics.v1.TrackProfileIds";
        }
      }

      TrackProfileIds.prototype.customer = null;
      TrackProfileIds.prototype.formsort = null;
      TrackProfileIds.prototype.healthie = null;
      TrackProfileIds.prototype.axleHealth = null;
      TrackProfileIds.prototype.butterflyLabs = null;
      TrackProfileIds.prototype.recurly = null;
      TrackProfileIds.prototype.intercom = null;
      TrackProfileIds.prototype.sanaBenefits = null;
      TrackProfileIds.prototype.activeCampaign = null;
      TrackProfileIds.prototype.junction = null;
      TrackProfileIds.prototype.segment = null;
      TrackProfileIds.prototype.intakeq = null;
      TrackProfileIds.prototype.customerIo = null;
      TrackProfileIds.prototype.freshdesk = null;
      TrackProfileIds.prototype.hubspot = null;
      TrackProfileIds.prototype.stripe = null;
      TrackProfileIds.prototype.feathery = null;

      return TrackProfileIds;
    })();

    v1.Track = (() => {
      class Track {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Track(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.eventId != null && Object.hasOwnProperty.call(message, "eventId")) {
            writer.uint32(10).string(message.eventId);
          }
          if (message.eventName != null && Object.hasOwnProperty.call(message, "eventName")) {
            writer.uint32(18).string(message.eventName);
          }
          if (message.userId != null && Object.hasOwnProperty.call(message, "userId")) {
            writer.uint32(26).string(message.userId);
          }
          if (message.profileIds != null && Object.hasOwnProperty.call(message, "profileIds")) {
            $root.morf_analytics.v1.TrackProfileIds.encode(message.profileIds, writer.uint32(34).fork()).ldelim();
          }
          if (message.profileProperties != null && Object.hasOwnProperty.call(message, "profileProperties")) {
            google$1.protobuf.Struct.encode(message.profileProperties, writer.uint32(42).fork()).ldelim();
          }
          if (message.eventData != null && Object.hasOwnProperty.call(message, "eventData")) {
            google$1.protobuf.Struct.encode(message.eventData, writer.uint32(50).fork()).ldelim();
          }
          if (message.occurredAt != null && Object.hasOwnProperty.call(message, "occurredAt")) {
            writer.uint32(58).string(message.occurredAt);
          }
          if (message.sourceId != null && Object.hasOwnProperty.call(message, "sourceId")) {
            writer.uint32(74).string(message.sourceId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Track();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventId = reader.string();
                break;
              }
              case 2: {
                message.eventName = reader.string();
                break;
              }
              case 3: {
                message.userId = reader.string();
                break;
              }
              case 4: {
                message.profileIds = $root.morf_analytics.v1.TrackProfileIds.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.profileProperties = google$1.protobuf.Struct.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.eventData = google$1.protobuf.Struct.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.occurredAt = reader.string();
                break;
              }
              case 9: {
                message.sourceId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.morf_analytics.v1.Track) {
            return object;
          }
          const message = new $root.morf_analytics.v1.Track();
          if (object.eventId != null) {
            message.eventId = String(object.eventId);
          }
          if (object.eventName != null) {
            message.eventName = String(object.eventName);
          }
          if (object.userId != null) {
            message.userId = String(object.userId);
          }
          if (object.profileIds != null) {
            if (typeof object.profileIds !== "object") {
              throw new TypeError(".morf_analytics.v1.Track.profileIds: object expected, but got " + (typeof object.profileIds));
            }
            message.profileIds = $root.morf_analytics.v1.TrackProfileIds.fromObject(object.profileIds);
          }
          if (object.profileProperties != null) {
            if (typeof object.profileProperties !== "object") {
              throw new TypeError(".morf_analytics.v1.Track.profileProperties: object expected, but got " + (typeof object.profileProperties));
            }
            message.profileProperties = google$1.protobuf.Struct.fromObject(object.profileProperties);
          }
          if (object.eventData != null) {
            if (typeof object.eventData !== "object") {
              throw new TypeError(".morf_analytics.v1.Track.eventData: object expected, but got " + (typeof object.eventData));
            }
            message.eventData = google$1.protobuf.Struct.fromObject(object.eventData);
          }
          if (object.occurredAt != null) {
            message.occurredAt = String(object.occurredAt);
          }
          if (object.sourceId != null) {
            message.sourceId = String(object.sourceId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventName = "";
            object.userId = "";
            object.sourceId = "";
          }
          let keys;
          if (message.eventId != null && message.hasOwnProperty("eventId")) {
            object.eventId = message.eventId;
          }
          if (message.eventName != null && message.hasOwnProperty("eventName")) {
            object.eventName = message.eventName;
          }
          if (message.userId != null && message.hasOwnProperty("userId")) {
            object.userId = message.userId;
          }
          if (message.profileIds != null && message.hasOwnProperty("profileIds")) {
            object.profileIds = $root.morf_analytics.v1.TrackProfileIds.toObject(message.profileIds, options);
          }
          if (message.profileProperties != null && message.hasOwnProperty("profileProperties")) {
            object.profileProperties = google$1.protobuf.Struct.toObject(message.profileProperties, options);
          }
          if (message.eventData != null && message.hasOwnProperty("eventData")) {
            object.eventData = google$1.protobuf.Struct.toObject(message.eventData, options);
          }
          if (message.occurredAt != null && message.hasOwnProperty("occurredAt")) {
            object.occurredAt = message.occurredAt;
          }
          if (message.sourceId != null && message.hasOwnProperty("sourceId")) {
            object.sourceId = message.sourceId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "morf_analytics.v1.Track";
        }
      }

      Track.prototype.eventId = null;
      Track.prototype.eventName = "";
      Track.prototype.userId = "";
      Track.prototype.profileIds = null;
      Track.prototype.profileProperties = null;
      Track.prototype.eventData = null;
      Track.prototype.occurredAt = null;
      Track.prototype.sourceId = "";

      return Track;
    })();

    v1.SchedulingProvider = (() => {
      class SchedulingProvider {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SchedulingProvider(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId")) {
            scheduling$1.v1.ProviderId.encode(message.providerId, writer.uint32(82).fork()).ldelim();
          }
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(90).string(message.name);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SchedulingProvider();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 10: {
                message.providerId = scheduling$1.v1.ProviderId.decode(reader, reader.uint32());
                break;
              }
              case 11: {
                message.name = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.morf_analytics.v1.SchedulingProvider) {
            return object;
          }
          const message = new $root.morf_analytics.v1.SchedulingProvider();
          if (object.providerId != null) {
            if (typeof object.providerId !== "object") {
              throw new TypeError(".morf_analytics.v1.SchedulingProvider.providerId: object expected, but got " + (typeof object.providerId));
            }
            message.providerId = scheduling$1.v1.ProviderId.fromObject(object.providerId);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.providerId = null;
            object.name = "";
          }
          let keys;
          if (message.providerId != null && message.hasOwnProperty("providerId")) {
            object.providerId = scheduling$1.v1.ProviderId.toObject(message.providerId, options);
          }
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "morf_analytics.v1.SchedulingProvider";
        }
      }

      SchedulingProvider.prototype.providerId = null;
      SchedulingProvider.prototype.name = "";

      return SchedulingProvider;
    })();

    v1.TimeSlotSelectionViewed = (() => {
      class TimeSlotSelectionViewed {
        constructor(properties) {
          this.licensedGeographicLocales = [];
          this.specialties = [];
          this.internalTags = [];
          this.providersViewed = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new TimeSlotSelectionViewed(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.application != null && Object.hasOwnProperty.call(message, "application")) {
            writer.uint32(8).int32(message.application);
          }
          if (message.viewType != null && Object.hasOwnProperty.call(message, "viewType")) {
            writer.uint32(16).int32(message.viewType);
          }
          if (message.appointmentType != null && Object.hasOwnProperty.call(message, "appointmentType")) {
            scheduling$1.v1.AppointmentTypeInfo.encode(message.appointmentType, writer.uint32(26).fork()).ldelim();
          }
          if (message.licensedGeographicLocales != null && Object.hasOwnProperty.call(message, "licensedGeographicLocales")) {
            for (const element of message.licensedGeographicLocales) {
              writer.uint32(34).string(element);
            }
          }
          if (message.specialties != null && Object.hasOwnProperty.call(message, "specialties")) {
            for (const element of message.specialties) {
              writer.uint32(42).string(element);
            }
          }
          if (message.internalTags != null && Object.hasOwnProperty.call(message, "internalTags")) {
            for (const element of message.internalTags) {
              writer.uint32(50).string(element);
            }
          }
          if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate")) {
            values$1.v1.Date.encode(message.startDate, writer.uint32(58).fork()).ldelim();
          }
          if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate")) {
            values$1.v1.Date.encode(message.endDate, writer.uint32(66).fork()).ldelim();
          }
          if (message.viewedDate != null && Object.hasOwnProperty.call(message, "viewedDate")) {
            values$1.v1.Date.encode(message.viewedDate, writer.uint32(74).fork()).ldelim();
          }
          if (message.numTimeslotsOnViewedDate != null && Object.hasOwnProperty.call(message, "numTimeslotsOnViewedDate")) {
            writer.uint32(80).uint32(message.numTimeslotsOnViewedDate);
          }
          if (message.numTimeslotsInRange != null && Object.hasOwnProperty.call(message, "numTimeslotsInRange")) {
            writer.uint32(88).uint32(message.numTimeslotsInRange);
          }
          if (message.hoursUntilNextAvailableTimeslot != null && Object.hasOwnProperty.call(message, "hoursUntilNextAvailableTimeslot")) {
            writer.uint32(96).uint32(message.hoursUntilNextAvailableTimeslot);
          }
          if (message.providersViewed != null && Object.hasOwnProperty.call(message, "providersViewed")) {
            for (const element of message.providersViewed) {
              $root.morf_analytics.v1.SchedulingProvider.encode(element, writer.uint32(106).fork()).ldelim();
            }
          }
          if (message.profileCustomerId != null && Object.hasOwnProperty.call(message, "profileCustomerId")) {
            writer.uint32(114).string(message.profileCustomerId);
          }
          if (message.insurancePayer != null && Object.hasOwnProperty.call(message, "insurancePayer")) {
            scheduling$1.v1.FilterInsurancePayer.encode(message.insurancePayer, writer.uint32(122).fork()).ldelim();
          }
          if (message.profileEmailAddress != null && Object.hasOwnProperty.call(message, "profileEmailAddress")) {
            writer.uint32(130).string(message.profileEmailAddress);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new TimeSlotSelectionViewed();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.application = reader.int32();
                break;
              }
              case 2: {
                message.viewType = reader.int32();
                break;
              }
              case 3: {
                message.appointmentType = scheduling$1.v1.AppointmentTypeInfo.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                if (!message.licensedGeographicLocales || !message.licensedGeographicLocales.length) {
                  message.licensedGeographicLocales = [];
                }
                message.licensedGeographicLocales.push(reader.string());
                break;
              }
              case 5: {
                if (!message.specialties || !message.specialties.length) {
                  message.specialties = [];
                }
                message.specialties.push(reader.string());
                break;
              }
              case 6: {
                if (!message.internalTags || !message.internalTags.length) {
                  message.internalTags = [];
                }
                message.internalTags.push(reader.string());
                break;
              }
              case 7: {
                message.startDate = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 8: {
                message.endDate = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 9: {
                message.viewedDate = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 10: {
                message.numTimeslotsOnViewedDate = reader.uint32();
                break;
              }
              case 11: {
                message.numTimeslotsInRange = reader.uint32();
                break;
              }
              case 12: {
                message.hoursUntilNextAvailableTimeslot = reader.uint32();
                break;
              }
              case 13: {
                if (!message.providersViewed || !message.providersViewed.length) {
                  message.providersViewed = [];
                }
                message.providersViewed.push($root.morf_analytics.v1.SchedulingProvider.decode(reader, reader.uint32()));
                break;
              }
              case 14: {
                message.profileCustomerId = reader.string();
                break;
              }
              case 15: {
                message.insurancePayer = scheduling$1.v1.FilterInsurancePayer.decode(reader, reader.uint32());
                break;
              }
              case 16: {
                message.profileEmailAddress = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.morf_analytics.v1.TimeSlotSelectionViewed) {
            return object;
          }
          const message = new $root.morf_analytics.v1.TimeSlotSelectionViewed();
          if (object.application != null) {
            switch (object.application) {
              case "UNSPECIFIED_SCHEDULING_APPLICATION":
              case 0: {
                message.application = 0;
                break;
              }
              case "HEALTHIE":
              case 1: {
                message.application = 1;
                break;
              }
              case "ACUITY":
              case 2: {
                message.application = 2;
                break;
              }
              default: {
                if (typeof object.application == "number") {
                  message.application = object.application;
                  break;
                }
                break;
              }
            }
          }
          if (object.viewType != null) {
            switch (object.viewType) {
              case "ILLEGAL_UNSPECIFIED_VIEW_TYPE":
              case 0: {
                message.viewType = 0;
                break;
              }
              case "BASIC_VIEW":
              case 1: {
                message.viewType = 1;
                break;
              }
              case "PROVIDERS_VIEW":
              case 2: {
                message.viewType = 2;
                break;
              }
              case "PROVIDER_PROFILE":
              case 3: {
                message.viewType = 3;
                break;
              }
              default: {
                if (typeof object.viewType == "number") {
                  message.viewType = object.viewType;
                  break;
                }
                break;
              }
            }
          }
          if (object.appointmentType != null) {
            if (typeof object.appointmentType !== "object") {
              throw new TypeError(".morf_analytics.v1.TimeSlotSelectionViewed.appointmentType: object expected, but got " + (typeof object.appointmentType));
            }
            message.appointmentType = scheduling$1.v1.AppointmentTypeInfo.fromObject(object.appointmentType);
          }
          if (object.licensedGeographicLocales) {
            if (!Array.isArray(object.licensedGeographicLocales)) {
              throw new TypeError(".morf_analytics.v1.TimeSlotSelectionViewed.licensedGeographicLocales: array type expected, but got " + (typeof object.licensedGeographicLocales))
            }
            message.licensedGeographicLocales = new Array(object.licensedGeographicLocales.length);
            for (let i = 0; i < object.licensedGeographicLocales.length; ++i) {
              message.licensedGeographicLocales[i] = String(object.licensedGeographicLocales[i]);
            }
          }
          if (object.specialties) {
            if (!Array.isArray(object.specialties)) {
              throw new TypeError(".morf_analytics.v1.TimeSlotSelectionViewed.specialties: array type expected, but got " + (typeof object.specialties))
            }
            message.specialties = new Array(object.specialties.length);
            for (let i = 0; i < object.specialties.length; ++i) {
              message.specialties[i] = String(object.specialties[i]);
            }
          }
          if (object.internalTags) {
            if (!Array.isArray(object.internalTags)) {
              throw new TypeError(".morf_analytics.v1.TimeSlotSelectionViewed.internalTags: array type expected, but got " + (typeof object.internalTags))
            }
            message.internalTags = new Array(object.internalTags.length);
            for (let i = 0; i < object.internalTags.length; ++i) {
              message.internalTags[i] = String(object.internalTags[i]);
            }
          }
          if (object.startDate != null) {
            if (typeof object.startDate !== "object") {
              throw new TypeError(".morf_analytics.v1.TimeSlotSelectionViewed.startDate: object expected, but got " + (typeof object.startDate));
            }
            message.startDate = values$1.v1.Date.fromObject(object.startDate);
          }
          if (object.endDate != null) {
            if (typeof object.endDate !== "object") {
              throw new TypeError(".morf_analytics.v1.TimeSlotSelectionViewed.endDate: object expected, but got " + (typeof object.endDate));
            }
            message.endDate = values$1.v1.Date.fromObject(object.endDate);
          }
          if (object.viewedDate != null) {
            if (typeof object.viewedDate !== "object") {
              throw new TypeError(".morf_analytics.v1.TimeSlotSelectionViewed.viewedDate: object expected, but got " + (typeof object.viewedDate));
            }
            message.viewedDate = values$1.v1.Date.fromObject(object.viewedDate);
          }
          if (object.numTimeslotsOnViewedDate != null) {
            message.numTimeslotsOnViewedDate = object.numTimeslotsOnViewedDate >>> 0;
          }
          if (object.numTimeslotsInRange != null) {
            message.numTimeslotsInRange = object.numTimeslotsInRange >>> 0;
          }
          if (object.hoursUntilNextAvailableTimeslot != null) {
            message.hoursUntilNextAvailableTimeslot = object.hoursUntilNextAvailableTimeslot >>> 0;
          }
          if (object.providersViewed) {
            if (!Array.isArray(object.providersViewed)) {
              throw new TypeError(".morf_analytics.v1.TimeSlotSelectionViewed.providersViewed: array type expected, but got " + (typeof object.providersViewed))
            }
            message.providersViewed = new Array(object.providersViewed.length);
            for (let i = 0; i < object.providersViewed.length; ++i) {
              if (typeof object.providersViewed[i] !== "object") {
                throw new TypeError(".morf_analytics.v1.TimeSlotSelectionViewed.providersViewed: object expected, but got " + (typeof object.providersViewed[i]));
              }
              message.providersViewed[i] = $root.morf_analytics.v1.SchedulingProvider.fromObject(object.providersViewed[i]);
            }
          }
          if (object.profileCustomerId != null) {
            message.profileCustomerId = String(object.profileCustomerId);
          }
          if (object.insurancePayer != null) {
            if (typeof object.insurancePayer !== "object") {
              throw new TypeError(".morf_analytics.v1.TimeSlotSelectionViewed.insurancePayer: object expected, but got " + (typeof object.insurancePayer));
            }
            message.insurancePayer = scheduling$1.v1.FilterInsurancePayer.fromObject(object.insurancePayer);
          }
          if (object.profileEmailAddress != null) {
            message.profileEmailAddress = String(object.profileEmailAddress);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.licensedGeographicLocales = [];
            object.specialties = [];
            object.internalTags = [];
            object.providersViewed = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.application = options.enums === String ? "UNSPECIFIED_SCHEDULING_APPLICATION" : 0
            object.viewType = options.enums === String ? "ILLEGAL_UNSPECIFIED_VIEW_TYPE" : 0
            object.appointmentType = null;
            object.startDate = null;
            object.endDate = null;
            object.viewedDate = null;
            object.numTimeslotsOnViewedDate = 0;
            object.numTimeslotsInRange = 0;
            object.hoursUntilNextAvailableTimeslot = 0;
          }
          let keys;
          if (message.application != null && message.hasOwnProperty("application")) {
            object.application = options.enums === String ? scheduling$1.v1.SchedulingApplication[message.application] === undefined ? message.application : scheduling$1.v1.SchedulingApplication[message.application] : message.application;
          }
          if (message.viewType != null && message.hasOwnProperty("viewType")) {
            object.viewType = options.enums === String ? scheduling$1.v1.SchedulingApplicationViewType[message.viewType] === undefined ? message.viewType : scheduling$1.v1.SchedulingApplicationViewType[message.viewType] : message.viewType;
          }
          if (message.appointmentType != null && message.hasOwnProperty("appointmentType")) {
            object.appointmentType = scheduling$1.v1.AppointmentTypeInfo.toObject(message.appointmentType, options);
          }
          if (message.licensedGeographicLocales && message.licensedGeographicLocales.length) {
            object.licensedGeographicLocales = new Array(message.licensedGeographicLocales.length);
            for (let i = 0; i < message.licensedGeographicLocales.length; ++i) {
              object.licensedGeographicLocales[i] = message.licensedGeographicLocales[i];
            }
          }
          if (message.specialties && message.specialties.length) {
            object.specialties = new Array(message.specialties.length);
            for (let i = 0; i < message.specialties.length; ++i) {
              object.specialties[i] = message.specialties[i];
            }
          }
          if (message.internalTags && message.internalTags.length) {
            object.internalTags = new Array(message.internalTags.length);
            for (let i = 0; i < message.internalTags.length; ++i) {
              object.internalTags[i] = message.internalTags[i];
            }
          }
          if (message.startDate != null && message.hasOwnProperty("startDate")) {
            object.startDate = values$1.v1.Date.toObject(message.startDate, options);
          }
          if (message.endDate != null && message.hasOwnProperty("endDate")) {
            object.endDate = values$1.v1.Date.toObject(message.endDate, options);
          }
          if (message.viewedDate != null && message.hasOwnProperty("viewedDate")) {
            object.viewedDate = values$1.v1.Date.toObject(message.viewedDate, options);
          }
          if (message.numTimeslotsOnViewedDate != null && message.hasOwnProperty("numTimeslotsOnViewedDate")) {
            object.numTimeslotsOnViewedDate = message.numTimeslotsOnViewedDate;
          }
          if (message.numTimeslotsInRange != null && message.hasOwnProperty("numTimeslotsInRange")) {
            object.numTimeslotsInRange = message.numTimeslotsInRange;
          }
          if (message.hoursUntilNextAvailableTimeslot != null && message.hasOwnProperty("hoursUntilNextAvailableTimeslot")) {
            object.hoursUntilNextAvailableTimeslot = message.hoursUntilNextAvailableTimeslot;
          }
          if (message.providersViewed && message.providersViewed.length) {
            object.providersViewed = new Array(message.providersViewed.length);
            for (let i = 0; i < message.providersViewed.length; ++i) {
              object.providersViewed[i] = $root.morf_analytics.v1.SchedulingProvider.toObject(message.providersViewed[i], options);
            }
          }
          if (message.profileCustomerId != null && message.hasOwnProperty("profileCustomerId")) {
            object.profileCustomerId = message.profileCustomerId;
          }
          if (message.insurancePayer != null && message.hasOwnProperty("insurancePayer")) {
            object.insurancePayer = scheduling$1.v1.FilterInsurancePayer.toObject(message.insurancePayer, options);
          }
          if (message.profileEmailAddress != null && message.hasOwnProperty("profileEmailAddress")) {
            object.profileEmailAddress = message.profileEmailAddress;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "morf_analytics.v1.TimeSlotSelectionViewed";
        }
      }

      TimeSlotSelectionViewed.prototype.application = 0;
      TimeSlotSelectionViewed.prototype.viewType = 0;
      TimeSlotSelectionViewed.prototype.appointmentType = null;
      TimeSlotSelectionViewed.prototype.licensedGeographicLocales = $util.emptyArray;
      TimeSlotSelectionViewed.prototype.specialties = $util.emptyArray;
      TimeSlotSelectionViewed.prototype.internalTags = $util.emptyArray;
      TimeSlotSelectionViewed.prototype.startDate = null;
      TimeSlotSelectionViewed.prototype.endDate = null;
      TimeSlotSelectionViewed.prototype.viewedDate = null;
      TimeSlotSelectionViewed.prototype.numTimeslotsOnViewedDate = 0;
      TimeSlotSelectionViewed.prototype.numTimeslotsInRange = 0;
      TimeSlotSelectionViewed.prototype.hoursUntilNextAvailableTimeslot = 0;
      TimeSlotSelectionViewed.prototype.providersViewed = $util.emptyArray;
      TimeSlotSelectionViewed.prototype.profileCustomerId = null;
      TimeSlotSelectionViewed.prototype.insurancePayer = null;
      TimeSlotSelectionViewed.prototype.profileEmailAddress = null;

      return TimeSlotSelectionViewed;
    })();

    v1.TimeSlotSelected = (() => {
      class TimeSlotSelected {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new TimeSlotSelected(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.application != null && Object.hasOwnProperty.call(message, "application")) {
            writer.uint32(8).int32(message.application);
          }
          if (message.viewType != null && Object.hasOwnProperty.call(message, "viewType")) {
            writer.uint32(16).int32(message.viewType);
          }
          if (message.appointmentType != null && Object.hasOwnProperty.call(message, "appointmentType")) {
            scheduling$1.v1.AppointmentTypeInfo.encode(message.appointmentType, writer.uint32(26).fork()).ldelim();
          }
          if (message.datetime != null && Object.hasOwnProperty.call(message, "datetime")) {
            timing$1.v1.Timestamp.encode(message.datetime, writer.uint32(34).fork()).ldelim();
          }
          if (message.providerScheduling != null && Object.hasOwnProperty.call(message, "providerScheduling")) {
            $root.morf_analytics.v1.SchedulingProvider.encode(message.providerScheduling, writer.uint32(42).fork()).ldelim();
          }
          if (message.profileCustomerId != null && Object.hasOwnProperty.call(message, "profileCustomerId")) {
            writer.uint32(50).string(message.profileCustomerId);
          }
          if (message.profileEmailAddress != null && Object.hasOwnProperty.call(message, "profileEmailAddress")) {
            writer.uint32(58).string(message.profileEmailAddress);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new TimeSlotSelected();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.application = reader.int32();
                break;
              }
              case 2: {
                message.viewType = reader.int32();
                break;
              }
              case 3: {
                message.appointmentType = scheduling$1.v1.AppointmentTypeInfo.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.datetime = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.providerScheduling = $root.morf_analytics.v1.SchedulingProvider.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.profileCustomerId = reader.string();
                break;
              }
              case 7: {
                message.profileEmailAddress = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.morf_analytics.v1.TimeSlotSelected) {
            return object;
          }
          const message = new $root.morf_analytics.v1.TimeSlotSelected();
          if (object.application != null) {
            switch (object.application) {
              case "UNSPECIFIED_SCHEDULING_APPLICATION":
              case 0: {
                message.application = 0;
                break;
              }
              case "HEALTHIE":
              case 1: {
                message.application = 1;
                break;
              }
              case "ACUITY":
              case 2: {
                message.application = 2;
                break;
              }
              default: {
                if (typeof object.application == "number") {
                  message.application = object.application;
                  break;
                }
                break;
              }
            }
          }
          if (object.viewType != null) {
            switch (object.viewType) {
              case "ILLEGAL_UNSPECIFIED_VIEW_TYPE":
              case 0: {
                message.viewType = 0;
                break;
              }
              case "BASIC_VIEW":
              case 1: {
                message.viewType = 1;
                break;
              }
              case "PROVIDERS_VIEW":
              case 2: {
                message.viewType = 2;
                break;
              }
              case "PROVIDER_PROFILE":
              case 3: {
                message.viewType = 3;
                break;
              }
              default: {
                if (typeof object.viewType == "number") {
                  message.viewType = object.viewType;
                  break;
                }
                break;
              }
            }
          }
          if (object.appointmentType != null) {
            if (typeof object.appointmentType !== "object") {
              throw new TypeError(".morf_analytics.v1.TimeSlotSelected.appointmentType: object expected, but got " + (typeof object.appointmentType));
            }
            message.appointmentType = scheduling$1.v1.AppointmentTypeInfo.fromObject(object.appointmentType);
          }
          if (object.datetime != null) {
            if (typeof object.datetime !== "object") {
              throw new TypeError(".morf_analytics.v1.TimeSlotSelected.datetime: object expected, but got " + (typeof object.datetime));
            }
            message.datetime = timing$1.v1.Timestamp.fromObject(object.datetime);
          }
          if (object.providerScheduling != null) {
            if (typeof object.providerScheduling !== "object") {
              throw new TypeError(".morf_analytics.v1.TimeSlotSelected.providerScheduling: object expected, but got " + (typeof object.providerScheduling));
            }
            message.providerScheduling = $root.morf_analytics.v1.SchedulingProvider.fromObject(object.providerScheduling);
          }
          if (object.profileCustomerId != null) {
            message.profileCustomerId = String(object.profileCustomerId);
          }
          if (object.profileEmailAddress != null) {
            message.profileEmailAddress = String(object.profileEmailAddress);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.application = options.enums === String ? "UNSPECIFIED_SCHEDULING_APPLICATION" : 0
            object.viewType = options.enums === String ? "ILLEGAL_UNSPECIFIED_VIEW_TYPE" : 0
            object.appointmentType = null;
            object.datetime = null;
            object.providerScheduling = null;
          }
          let keys;
          if (message.application != null && message.hasOwnProperty("application")) {
            object.application = options.enums === String ? scheduling$1.v1.SchedulingApplication[message.application] === undefined ? message.application : scheduling$1.v1.SchedulingApplication[message.application] : message.application;
          }
          if (message.viewType != null && message.hasOwnProperty("viewType")) {
            object.viewType = options.enums === String ? scheduling$1.v1.SchedulingApplicationViewType[message.viewType] === undefined ? message.viewType : scheduling$1.v1.SchedulingApplicationViewType[message.viewType] : message.viewType;
          }
          if (message.appointmentType != null && message.hasOwnProperty("appointmentType")) {
            object.appointmentType = scheduling$1.v1.AppointmentTypeInfo.toObject(message.appointmentType, options);
          }
          if (message.datetime != null && message.hasOwnProperty("datetime")) {
            object.datetime = timing$1.v1.Timestamp.toObject(message.datetime, options);
          }
          if (message.providerScheduling != null && message.hasOwnProperty("providerScheduling")) {
            object.providerScheduling = $root.morf_analytics.v1.SchedulingProvider.toObject(message.providerScheduling, options);
          }
          if (message.profileCustomerId != null && message.hasOwnProperty("profileCustomerId")) {
            object.profileCustomerId = message.profileCustomerId;
          }
          if (message.profileEmailAddress != null && message.hasOwnProperty("profileEmailAddress")) {
            object.profileEmailAddress = message.profileEmailAddress;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "morf_analytics.v1.TimeSlotSelected";
        }
      }

      TimeSlotSelected.prototype.application = 0;
      TimeSlotSelected.prototype.viewType = 0;
      TimeSlotSelected.prototype.appointmentType = null;
      TimeSlotSelected.prototype.datetime = null;
      TimeSlotSelected.prototype.providerScheduling = null;
      TimeSlotSelected.prototype.profileCustomerId = null;
      TimeSlotSelected.prototype.profileEmailAddress = null;

      return TimeSlotSelected;
    })();

    v1.TrackResponse = (() => {
      class TrackResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new TrackResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.statusCode != null && Object.hasOwnProperty.call(message, "statusCode")) {
            writer.uint32(8).int32(message.statusCode);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new TrackResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.statusCode = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.morf_analytics.v1.TrackResponse) {
            return object;
          }
          const message = new $root.morf_analytics.v1.TrackResponse();
          if (object.statusCode != null) {
            message.statusCode = object.statusCode | 0;
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.statusCode = 0;
          }
          let keys;
          if (message.statusCode != null && message.hasOwnProperty("statusCode")) {
            object.statusCode = message.statusCode;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "morf_analytics.v1.TrackResponse";
        }
      }

      TrackResponse.prototype.statusCode = 0;

      return TrackResponse;
    })();

    v1.MorfAnalyticsService = (() => {
      class MorfAnalyticsService extends $protobuf.rpc.Service {
        constructor(rpcImpl, requestDelimited = false, responseDelimited = false) {
          super(rpcImpl, requestDelimited, responseDelimited);
        }

        static create(rpcImpl, requestDelimited = false, responseDelimited = false) {
          return new MorfAnalyticsService(rpcImpl, requestDelimited, responseDelimited);
        }
      }

      Object.defineProperty(MorfAnalyticsService.prototype.morfAnalyticsTrack = function morfAnalyticsTrack(request, callback) {
        return this.rpcCall(morfAnalyticsTrack, $root.morf_analytics.v1.Track, $root.morf_analytics.v1.TrackResponse, request, callback);
      }, "name", { value: "MorfAnalyticsTrack" });

      Object.defineProperty(MorfAnalyticsService.prototype.trackTimeSlotSelectionViewed = function trackTimeSlotSelectionViewed(request, callback) {
        return this.rpcCall(trackTimeSlotSelectionViewed, $root.morf_analytics.v1.TimeSlotSelectionViewed, $root.morf_analytics.v1.TrackResponse, request, callback);
      }, "name", { value: "TrackTimeSlotSelectionViewed" });

      Object.defineProperty(MorfAnalyticsService.prototype.trackTimeSlotSelected = function trackTimeSlotSelected(request, callback) {
        return this.rpcCall(trackTimeSlotSelected, $root.morf_analytics.v1.TimeSlotSelected, $root.morf_analytics.v1.TrackResponse, request, callback);
      }, "name", { value: "TrackTimeSlotSelected" });

      return MorfAnalyticsService;
    })();

    return v1;
  })();

  return morf_analytics;
})();

export const morf_analytics = $root.morf_analytics;
