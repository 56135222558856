"use strict";

import * as $protobuf from "protobufjs/minimal";
import { workflow_parameters as workflow_parameters$1 } from "../../../workflow_parameters/v1/workflow_parameters_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.destinationactions = (() => {
  const destinationactions = {};
  destinationactions.activecampaign = (() => {
    const activecampaign = {};
    activecampaign.v1 = (() => {
      const v1 = {};
      v1.DestinationAction = (() => {
        class DestinationAction {
          get type() {
            for (const key of ["createOrUpdateContact", "sendEventForContact"]) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set type(name) {
            for (const key of ["createOrUpdateContact", "sendEventForContact"]) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new DestinationAction(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.createOrUpdateContact != null && Object.hasOwnProperty.call(message, "createOrUpdateContact")) {
              $root.destinationactions.activecampaign.v1.CreateOrUpdateContact.encode(message.createOrUpdateContact, writer.uint32(10).fork()).ldelim();
            }
            if (message.sendEventForContact != null && Object.hasOwnProperty.call(message, "sendEventForContact")) {
              $root.destinationactions.activecampaign.v1.SendEventForContact.encode(message.sendEventForContact, writer.uint32(18).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new DestinationAction();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.createOrUpdateContact = $root.destinationactions.activecampaign.v1.CreateOrUpdateContact.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.sendEventForContact = $root.destinationactions.activecampaign.v1.SendEventForContact.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.destinationactions.activecampaign.v1.DestinationAction) {
              return object;
            }
            const message = new $root.destinationactions.activecampaign.v1.DestinationAction();
            if (object.createOrUpdateContact != null) {
              if (typeof object.createOrUpdateContact !== "object") {
                throw new TypeError(".destinationactions.activecampaign.v1.DestinationAction.createOrUpdateContact: object expected, but got " + (typeof object.createOrUpdateContact));
              }
              message.createOrUpdateContact = $root.destinationactions.activecampaign.v1.CreateOrUpdateContact.fromObject(object.createOrUpdateContact);
            }
            if (object.sendEventForContact != null) {
              if (typeof object.sendEventForContact !== "object") {
                throw new TypeError(".destinationactions.activecampaign.v1.DestinationAction.sendEventForContact: object expected, but got " + (typeof object.sendEventForContact));
              }
              message.sendEventForContact = $root.destinationactions.activecampaign.v1.SendEventForContact.fromObject(object.sendEventForContact);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.createOrUpdateContact != null && message.hasOwnProperty("createOrUpdateContact")) {
              object.createOrUpdateContact = $root.destinationactions.activecampaign.v1.CreateOrUpdateContact.toObject(message.createOrUpdateContact, options);
              if (options.oneofs) {
                object.type = "createOrUpdateContact";
              }
            }
            if (message.sendEventForContact != null && message.hasOwnProperty("sendEventForContact")) {
              object.sendEventForContact = $root.destinationactions.activecampaign.v1.SendEventForContact.toObject(message.sendEventForContact, options);
              if (options.oneofs) {
                object.type = "sendEventForContact";
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "destinationactions.activecampaign.v1.DestinationAction";
          }
        }

        DestinationAction.prototype.createOrUpdateContact = null;
        DestinationAction.prototype.sendEventForContact = null;

        return DestinationAction;
      })();

      v1.CreateOrUpdateContact = (() => {
        class CreateOrUpdateContact {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateOrUpdateContact(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateOrUpdateContact();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.destinationactions.activecampaign.v1.CreateOrUpdateContact) {
              return object;
            }
            const message = new $root.destinationactions.activecampaign.v1.CreateOrUpdateContact();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".destinationactions.activecampaign.v1.CreateOrUpdateContact.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".destinationactions.activecampaign.v1.CreateOrUpdateContact.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".destinationactions.activecampaign.v1.CreateOrUpdateContact.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".destinationactions.activecampaign.v1.CreateOrUpdateContact.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "destinationactions.activecampaign.v1.CreateOrUpdateContact";
          }
        }

        CreateOrUpdateContact.prototype.params = $util.emptyArray;
        CreateOrUpdateContact.prototype.configs = $util.emptyArray;

        return CreateOrUpdateContact;
      })();

      v1.SendEventForContact = (() => {
        class SendEventForContact {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new SendEventForContact(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new SendEventForContact();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.destinationactions.activecampaign.v1.SendEventForContact) {
              return object;
            }
            const message = new $root.destinationactions.activecampaign.v1.SendEventForContact();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".destinationactions.activecampaign.v1.SendEventForContact.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".destinationactions.activecampaign.v1.SendEventForContact.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".destinationactions.activecampaign.v1.SendEventForContact.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".destinationactions.activecampaign.v1.SendEventForContact.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "destinationactions.activecampaign.v1.SendEventForContact";
          }
        }

        SendEventForContact.prototype.params = $util.emptyArray;
        SendEventForContact.prototype.configs = $util.emptyArray;

        return SendEventForContact;
      })();

      return v1;
    })();

    return activecampaign;
  })();

  return destinationactions;
})();

export const destinationactions = $root.destinationactions;
