'use client';

import { Text, Tooltip } from '..';
import { subscription_topics } from '@morf/proto/subscription_topics/v1/subscription_topics_ts_proto';
import {
  Grid,
  GridHeader,
  TopicRow,
  GridCell,
  Description,
  UnsubscribeGrid,
  UnsubscribeSection,
  StyledCheckbox,
  StyledSwitch,
} from './TopicPreference.css';
import { TopicPreferenceProps } from './types';

export function TopicPreference({
  preferences,
  onTopicPreferenceChange,
  onGlobalEmailChange,
  readonly = false,
}: TopicPreferenceProps) {
  const prefs = preferences.subscriptionTopicPreferences;
  if (!prefs) return null;

  const globalEmailSubscribed =
    prefs.globalSubscriptionPreferences?.emailSubscribed || false;

  return (
    <>
      <Grid>
        <GridHeader>
          <Text tag='h5'>Topics</Text>
        </GridHeader>
        <GridHeader>
          <Text tag='h5'>Email</Text>
        </GridHeader>
        <GridHeader>
          <Text tag='h5'>Text (SMS)</Text>
        </GridHeader>

        {prefs.subscriptionTopicPreferences?.map((topic) => (
          <TopicRow key={topic.topic?.id}>
            <GridCell isFirst>
              <Text tag='p1'>{topic.topic?.name}</Text>
              <Description tag='p2'>{topic.topic?.description}</Description>
            </GridCell>
            <GridCell
              isDisabled={!globalEmailSubscribed || readonly}
              onClick={
                readonly
                  ? undefined
                  : () => {
                      if (!topic.topic?.id || !onTopicPreferenceChange) return;
                      onTopicPreferenceChange(
                        topic.topic.id,
                        'emailSubscribed',
                        !(
                          topic.mediaTypeSubscriptionPreferences
                            ?.emailSubscribed ?? false
                        )
                      );
                    }
              }
            >
              <StyledCheckbox
                checked={
                  topic.mediaTypeSubscriptionPreferences?.emailSubscribed ??
                  false
                }
                height='1.25rem'
                width='1.25rem'
                checkSize={1.25}
                isDisabled={!globalEmailSubscribed || readonly}
              />
            </GridCell>
            <GridCell
              isDisabled={readonly}
              onClick={
                readonly
                  ? undefined
                  : () => {
                      if (!topic.topic?.id || !onTopicPreferenceChange) return;
                      onTopicPreferenceChange(
                        topic.topic.id,
                        'smsSubscribed',
                        !(
                          topic.mediaTypeSubscriptionPreferences
                            ?.smsSubscribed ?? false
                        )
                      );
                    }
              }
            >
              <Tooltip tooltipText='Globally opt out of SMS messages by replying STOP'>
                <StyledCheckbox
                  checked={
                    topic.mediaTypeSubscriptionPreferences?.smsSubscribed ??
                    false
                  }
                  height='1.25rem'
                  width='1.25rem'
                  checkSize={1.25}
                  isDisabled={readonly}
                />
              </Tooltip>
            </GridCell>
          </TopicRow>
        ))}
      </Grid>

      <UnsubscribeSection>
        <UnsubscribeGrid>
          <GridCell isFirst>
            <Text tag='h4'>Global email unsubscribe</Text>
            <Description tag='p2'>
              Unsubscribe from all current and future email topics
            </Description>
          </GridCell>
          <GridCell>
            <StyledSwitch
              checked={!globalEmailSubscribed}
              onChange={
                readonly
                  ? () => {}
                  : () => onGlobalEmailChange?.(globalEmailSubscribed)
              }
              size='medium'
              aria-label='Global email unsubscribe'
              isDisabled={readonly}
            />
          </GridCell>
        </UnsubscribeGrid>
      </UnsubscribeSection>
    </>
  );
}
