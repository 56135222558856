import { organization } from '@morf/proto/organization/v1/organization_ts_proto';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';
import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';

export enum SelectValuePairCategory {
  Identifiers = 'Identifiers',
  MorfProperties = 'Morf properties',
  CustomProperties = 'Custom properties',
  PayloadField = 'Payload field',
  CalculatedValue = 'Calculated value',
  ConstantValue = 'Static value',
  ElidedValue = 'Elided value',
  UnsubscribeUrl = 'Unsubscribe URL',
  MorfEventType = 'Morf event type',
  MorfEventTime = 'Morf event time',
  BuildObject = 'Build object',
  BuildArray = 'Build array',
  ObjectTypeIds = 'Object type ids',
  TemplateEditor = 'Template editor',
}

export type SelectValuePairModalProps = {
  configuration?: workflows.v1.WorkflowFetchAction | null;
  eventPayloadFieldSchemas?: workflow_parameters.v1.WorkflowEventPayloadFieldSchema[];
  includeEmail?: boolean;
  includePhone?: boolean;
  onClick: (
    sources: workflow_parameters.v1.DestinationActionParameterSource[]
  ) => void;
  onClose: () => void;
  onCreateCustomProperty: (
    data: organization.v1.CreateProfilePropertyRequest
  ) => void;
  selectedParam?: workflow_parameters.v1.DestinationActionParameter | null;
  selectedSource: workflow_parameters.v1.DestinationActionParameterSource | null;
  selectedSubRow?: number | null;
  visibleValuePairCategories?: (SelectValuePairCategory | string)[];
  /**
   * Controls whether the modal should use the default modal positioning (centered in screen)
   * or be positioned inline where it's rendered.
   * @default true - Use modal positioning by default for backward compatibility
   */
  useModalPositioning?: boolean;
  autofocus?: boolean;
  width?: string;
};
