import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';
import { ParameterVariantProps } from '../../../ParameterVariant/types';

type DestinationActionParameterSource =
  workflow_parameters.v1.DestinationActionParameterSource;

/**
 * Serializes a DestinationActionParameterSource object to a base64 string
 * This allows us to store complex parameter source data in HTML attributes
 */
export function serializeParameterSource(
  parameterSource: DestinationActionParameterSource
): string {
  try {
    const jsonString = JSON.stringify(parameterSource);

    return Buffer.from(jsonString).toString('base64');
  } catch (error) {
    console.error('Error serializing parameter source:', error);
    return '';
  }
}

/**
 * Deserializes a base64 string back to a DestinationActionParameterSource object
 */
export function deserializeParameterSource(
  base64String: string
): DestinationActionParameterSource | null {
  try {
    const jsonString = Buffer.from(base64String, 'base64').toString('utf-8');

    return JSON.parse(jsonString) as DestinationActionParameterSource;
  } catch (error) {
    console.error('Error deserializing parameter source:', error);
    return null;
  }
}

/**
 * Extracts a human-readable label from a DestinationActionParameterSource
 * This is used for display purposes in the editor
 */
export function getLabelFromParameterSource(
  parameterSource: DestinationActionParameterSource
): string {
  if (parameterSource.eventPayloadFieldLookup) {
    return (
      parameterSource.eventPayloadFieldLookup.celExpressionStr ||
      'Event Payload Field'
    );
  } else if (parameterSource.calculatedValue) {
    return (
      parameterSource.calculatedValue.celExpressionStr || 'Calculated Value'
    );
  } else if (parameterSource.profilePropertyLookup) {
    return (
      parameterSource.profilePropertyLookup.propertyName || 'Profile Property'
    );
  } else if (parameterSource.constantValue) {
    const value = parameterSource.constantValue;
    // Handle different value types from the CEL Value type
    if (value.stringValue !== undefined) {
      return String(value.stringValue);
    } else if (value.int64Value !== undefined) {
      return String(value.int64Value);
    } else if (value.doubleValue !== undefined) {
      return String(value.doubleValue);
    } else if (value.boolValue !== undefined) {
      return String(value.boolValue);
    } else {
      return 'Constant Value';
    }
  } else if (parameterSource.objectTypeId) {
    return parameterSource.objectTypeId.label || 'Object Type ID';
  } else if (parameterSource.profileIdentifier) {
    return 'Profile Identifier';
  } else if (parameterSource.morfEventType) {
    return 'Morf Event Type';
  } else if (parameterSource.morfEventTime) {
    return 'Morf Event Time';
  } else if (parameterSource.elidedValue) {
    return 'Elided Value';
  } else if (parameterSource.templateSource) {
    return 'Template Source';
  }

  return 'Parameter';
}

/**
 * Determines the parameter type from a DestinationActionParameterSource
 * This is used to display the correct icon and styling in the editor
 */
export function getParameterTypeFromSource(
  parameterSource: DestinationActionParameterSource
): ParameterVariantProps['type'] {
  if (parameterSource.eventPayloadFieldLookup) {
    return 'payload';
  } else if (parameterSource.calculatedValue) {
    return 'calculated';
  } else if (parameterSource.profilePropertyLookup) {
    return 'morf';
  } else if (parameterSource.constantValue) {
    return 'constant';
  } else if (parameterSource.objectTypeId) {
    return 'objectTypeId';
  } else if (parameterSource.profileIdentifier) {
    return 'identifier';
  } else if (parameterSource.morfEventType) {
    return 'eventType';
  } else if (parameterSource.morfEventTime) {
    return 'eventTime';
  } else if (parameterSource.templateSource) {
    return 'payload';
  } else {
    return 'custom';
  }
}
