import { ChangeEvent, FC, memo } from 'react';
import { Container } from '../Container';
import { Flexbox } from '../Flexbox';
import { HexColorPickerProps } from './types';
import { Input } from '../Input';

const HexColorPicker: FC<HexColorPickerProps> = ({
  color = '#000000',
  onColorChange,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onColorChange(e.target.value);
  };

  return (
    <Flexbox
      data-testid='hex-color-picker'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      height='2.25rem'
      gap={0.25}
    >
      <Container
        backgroundColor={color}
        minWidth='2.25rem'
        width='2.25rem'
        borderType='border'
        borderRadius={0.5}
      />
      <Input id='hex-color' value={color} onChange={handleChange} />
    </Flexbox>
  );
};

export const MemoizedHexColorPicker = memo(HexColorPicker);
