import { AddEdgeButton } from './AddEdgeButton';
import { AddEdgeProps } from './types';
import {
  CustomNode,
  NodeType,
  ParameterizedWorkflowNode,
} from '../../CustomNode/types';
import { EdgeLabelRenderer, BaseEdge, getSmoothStepPath } from 'reactflow';
import { EdgeType } from '../types';
import { FC, memo } from 'react';
import { getLayoutedElements } from '../../getLayoutedElements';
import { newNodeData } from './AddEdgeModal/types';
import { nodesWithMultipleEdges } from '../../nodesWithMultipleEdges';
import { useUpdateUrlParam } from '../../../Hooks/useUpdateUrlParam';
import { useWorkflow } from '../../../../../apps/admin/components/context/workflow/useWorkflow';
import { v4 as uuidv4 } from 'uuid';
import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';

const AddEdge: FC<AddEdgeProps> = ({
  data,
  id,
  markerEnd,
  source,
  sourcePosition,
  sourceX,
  sourceY,
  target,
  targetPosition,
  targetX,
  targetY,
}) => {
  const onAddNode = data?.value?.onAddNode;
  const updateUrlParam = useUpdateUrlParam();
  const { nodes, edges, setEdges, setNodes, destinationActions, fetchActions } =
    useWorkflow();

  const branchNodes = nodesWithMultipleEdges(nodes, edges);
  const isBranchEdge = branchNodes.includes(source);

  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    offset: 1,
    borderRadius: 25,
  });

  const handleAddEdgeButtonClick = (
    newNodeData: newNodeData,
    aboveBranchButton: boolean
  ) => {
    const newNodeId = uuidv4();

    const newNode: CustomNode = {
      id: newNodeId,
      type: newNodeData.type,
      data: {
        value: {
          name: newNodeData.name,
          integrationIconName: newNodeData.integrationIconName,
          configuration: newNodeData.configuration,
          ...data?.value,
        },
      },
      position: { x: 0, y: 0 },
      selectable: true,
    };

    updateUrlParam({ panelNodeId: newNodeId });

    const sourceToNewNodeEdge = {
      id: `${source}-${newNodeId}`,
      source,
      target: newNodeId,
      type: EdgeType.AddEdge,
    };

    const newNodeToTargetEdges = aboveBranchButton
      ? edges
          .filter((edge) => edge.source === source)
          .map((edge) => ({
            id: `${newNodeId}-${edge.target}`,
            source: newNodeId,
            target: edge.target,
            type: EdgeType.AddEdge,
          }))
      : [
          {
            id: `${newNodeId}-${target}`,
            source: newNodeId,
            target,
            type: EdgeType.AddEdge,
          },
        ];

    const remainingEdges = aboveBranchButton
      ? edges.filter((edge) => edge.source !== source)
      : edges.filter((edge) => edge.id !== id);

    const updatedNodes = [...nodes, newNode];
    const updatedEdges = newNodeData.isBranchNode
      ? [...edges, sourceToNewNodeEdge]
      : [...remainingEdges, sourceToNewNodeEdge, ...newNodeToTargetEdges];

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      updatedNodes,
      updatedEdges
    );
    setEdges(layoutedEdges);
    setNodes(layoutedNodes);

    if (onAddNode) {
      switch (newNodeData.type) {
        case NodeType.DestinationActionNode:
          onAddNode({
            node: new workflows.v1.CreateWorkflowNodeRequest({
              id: newNodeId,
              name: newNodeData.name,
              destinationAction: (
                newNodeData.configuration as workflows.v1.WorkflowNode &
                  ParameterizedWorkflowNode<'destinationAction'>
              ).destinationAction,
            }),
          });
          break;
        case NodeType.FetchActionNode:
          onAddNode({
            node: new workflows.v1.CreateWorkflowNodeRequest({
              id: newNodeId,
              name: newNodeData.name,
              fetchAction: (
                newNodeData.configuration as workflows.v1.WorkflowNode &
                  ParameterizedWorkflowNode<'fetchAction'>
              ).fetchAction,
            }),
          });
          break;
        case NodeType.FilterNode:
          onAddNode({
            node: new workflows.v1.CreateWorkflowNodeRequest({
              id: newNodeId,
              name: newNodeData.name,
              filter: new workflows.v1.CreateWorkflowFilterRequest({
                celExpressionStr: '',
              }),
            }),
          });
          break;
        case NodeType.PauseNode:
          onAddNode({
            node: new workflows.v1.CreateWorkflowNodeRequest({
              id: newNodeId,
              name: newNodeData.name,
              pause: (
                newNodeData.configuration as workflows.v1.WorkflowNode &
                  ParameterizedWorkflowNode<'pause'>
              ).pause,
            }),
          });
          break;
        case NodeType.ProfileConvertNode:
          onAddNode({
            node: new workflows.v1.CreateWorkflowNodeRequest({
              id: newNodeId,
              name: newNodeData.name,
              destinationAction: (
                newNodeData.configuration as workflows.v1.WorkflowNode &
                  ParameterizedWorkflowNode<'destinationAction'>
              ).destinationAction,
            }),
          });
          break;
        case NodeType.ProfileUpdateNode:
          onAddNode({
            node: new workflows.v1.CreateWorkflowNodeRequest({
              id: newNodeId,
              name: newNodeData.name,
              destinationAction: (
                newNodeData.configuration as workflows.v1.WorkflowNode &
                  ParameterizedWorkflowNode<'destinationAction'>
              ).destinationAction,
            }),
          });
          break;
        case NodeType.RestartNode:
          onAddNode({
            node: new workflows.v1.CreateWorkflowNodeRequest({
              id: newNodeId,
              name: newNodeData.name,
              restart: newNodeData.configuration,
            }),
          });
          break;
        case NodeType.TrackProfilePropertiesNode:
          onAddNode({
            node: new workflows.v1.CreateWorkflowNodeRequest({
              id: newNodeId,
              name: newNodeData.name,
              destinationAction: (
                newNodeData.configuration as workflows.v1.WorkflowNode &
                  ParameterizedWorkflowNode<'destinationAction'>
              ).destinationAction,
            }),
          });
          break;
      }
    }
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} />
      <EdgeLabelRenderer>
        <AddEdgeButton
          labelX={isBranchEdge ? targetX : (sourceX + targetX) / 2}
          labelY={isBranchEdge ? targetY - 30 : (sourceY + targetY) / 2}
          destinationActions={destinationActions}
          fetchActions={fetchActions}
          name={isBranchEdge ? 'add-above-branch-node' : 'add-node'}
          onClick={(newNodeData) =>
            handleAddEdgeButtonClick(newNodeData, false)
          }
        />
      </EdgeLabelRenderer>
    </>
  );
};

export const MemoizedAddEdge = memo(AddEdge);
