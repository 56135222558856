"use strict";

import * as $protobuf from "protobufjs/minimal";
import { active_campaign as active_campaign$1 } from "../../active_campaign/v1/active_campaign_ts_proto";
import { acuity as acuity$1 } from "../../acuity/v1/acuity_ts_proto";
import { axle_health as axle_health$1 } from "../../axle_health/v1/axle_health_ts_proto";
import { butterfly_labs as butterfly_labs$1 } from "../../butterfly_labs/v1/butterfly_labs_ts_proto";
import { calendly as calendly$1 } from "../../calendly/v1/calendly_ts_proto";
import { customerio as customerio$1 } from "../../customerio/v1/customerio_ts_proto";
import { feathery as feathery$1 } from "../../feathery/v1/feathery_ts_proto";
import { formsort as formsort$1 } from "../../formsort/v1/formsort_ts_proto";
import { healthie as healthie$1 } from "../../healthie/v1/healthie_ts_proto";
import { hubspot as hubspot$1 } from "../../hubspot/v1/hubspot_ts_proto";
import { intakeq as intakeq$1 } from "../../intakeq/v1/intakeq_ts_proto";
import { junction as junction$1 } from "../../junction/v1/junction_ts_proto";
import { morf_analytics as morf_analytics$1 } from "../../morf_analytics/v1/morf_analytics_ts_proto";
import { morf_appointment as morf_appointment$1 } from "../../morf_appointment/v1/morf_appointment_ts_proto";
import { push_json as push_json$1 } from "../../push_json/v1/push_json_ts_proto";
import { segment as segment$1 } from "../../segment/v1/segment_ts_proto";
import { source_application as source_application$1 } from "../../source_application/v2/source_application_ts_proto";
import { google as google$1 } from "../../struct_ts_proto";
import { timing as timing$1 } from "../../timing/v1/timing_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.sources = (() => {
  const sources = {};
  sources.v2 = (() => {
    const v2 = {};
    v2.WebhookRequest = (() => {
      class WebhookRequest {
        get body() {
          for (const key of ["bytes", "json"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set body(name) {
          for (const key of ["bytes", "json"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WebhookRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.orgId != null && Object.hasOwnProperty.call(message, "orgId")) {
            writer.uint32(10).string(message.orgId);
          }
          if (message.sourceId != null && Object.hasOwnProperty.call(message, "sourceId")) {
            writer.uint32(18).string(message.sourceId);
          }
          if (message.webhookId != null && Object.hasOwnProperty.call(message, "webhookId")) {
            writer.uint32(26).string(message.webhookId);
          }
          if (message.sourceApplication != null && Object.hasOwnProperty.call(message, "sourceApplication")) {
            writer.uint32(32).int32(message.sourceApplication);
          }
          if (message.requestTime != null && Object.hasOwnProperty.call(message, "requestTime")) {
            timing$1.v1.Timestamp.encode(message.requestTime, writer.uint32(42).fork()).ldelim();
          }
          if (message.commitTimestamp != null && Object.hasOwnProperty.call(message, "commitTimestamp")) {
            timing$1.v1.Timestamp.encode(message.commitTimestamp, writer.uint32(50).fork()).ldelim();
          }
          if (message.method != null && Object.hasOwnProperty.call(message, "method")) {
            writer.uint32(58).string(message.method);
          }
          if (message.host != null && Object.hasOwnProperty.call(message, "host")) {
            writer.uint32(66).string(message.host);
          }
          if (message.rawQueryParams != null && Object.hasOwnProperty.call(message, "rawQueryParams")) {
            writer.uint32(74).string(message.rawQueryParams);
          }
          if (message.headers != null && Object.hasOwnProperty.call(message, "headers")) {
            google$1.protobuf.Struct.encode(message.headers, writer.uint32(82).fork()).ldelim();
          }
          if (message.sourceType != null && Object.hasOwnProperty.call(message, "sourceType")) {
            $root.sources.v2.SourceType.encode(message.sourceType, writer.uint32(90).fork()).ldelim();
          }
          if (message.bytes != null && Object.hasOwnProperty.call(message, "bytes")) {
            writer.uint32(98).bytes(message.bytes);
          }
          if (message.json != null && Object.hasOwnProperty.call(message, "json")) {
            google$1.protobuf.Struct.encode(message.json, writer.uint32(106).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WebhookRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.orgId = reader.string();
                break;
              }
              case 2: {
                message.sourceId = reader.string();
                break;
              }
              case 3: {
                message.webhookId = reader.string();
                break;
              }
              case 4: {
                message.sourceApplication = reader.int32();
                break;
              }
              case 5: {
                message.requestTime = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.commitTimestamp = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.method = reader.string();
                break;
              }
              case 8: {
                message.host = reader.string();
                break;
              }
              case 9: {
                message.rawQueryParams = reader.string();
                break;
              }
              case 10: {
                message.headers = google$1.protobuf.Struct.decode(reader, reader.uint32());
                break;
              }
              case 11: {
                message.sourceType = $root.sources.v2.SourceType.decode(reader, reader.uint32());
                break;
              }
              case 12: {
                message.bytes = reader.bytes();
                break;
              }
              case 13: {
                message.json = google$1.protobuf.Struct.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.WebhookRequest) {
            return object;
          }
          const message = new $root.sources.v2.WebhookRequest();
          if (object.orgId != null) {
            message.orgId = String(object.orgId);
          }
          if (object.sourceId != null) {
            message.sourceId = String(object.sourceId);
          }
          if (object.webhookId != null) {
            message.webhookId = String(object.webhookId);
          }
          if (object.sourceApplication != null) {
            switch (object.sourceApplication) {
              case "UNSPECIFIED_SOURCE_APPLICATION":
              case 0: {
                message.sourceApplication = 0;
                break;
              }
              case "FORMSORT":
              case 1: {
                message.sourceApplication = 1;
                break;
              }
              case "HEALTHIE":
              case 2: {
                message.sourceApplication = 2;
                break;
              }
              case "AXLE_HEALTH":
              case 3: {
                message.sourceApplication = 3;
                break;
              }
              case "BUTTERFLY_LABS":
              case 4: {
                message.sourceApplication = 4;
                break;
              }
              case "MORF":
              case 5: {
                message.sourceApplication = 5;
                break;
              }
              case "PUSH_JSON":
              case 6: {
                message.sourceApplication = 6;
                break;
              }
              case "ACUITY":
              case 7: {
                message.sourceApplication = 7;
                break;
              }
              case "JUNCTION":
              case 8: {
                message.sourceApplication = 8;
                break;
              }
              case "SEGMENT":
              case 9: {
                message.sourceApplication = 9;
                break;
              }
              case "ACTIVE_CAMPAIGN":
              case 10: {
                message.sourceApplication = 10;
                break;
              }
              case "INTAKEQ":
              case 11: {
                message.sourceApplication = 11;
                break;
              }
              case "CUSTOMER_IO":
              case 12: {
                message.sourceApplication = 12;
                break;
              }
              case "HUBSPOT":
              case 13: {
                message.sourceApplication = 13;
                break;
              }
              case "CUSTOMER":
              case 14: {
                message.sourceApplication = 14;
                break;
              }
              case "CALENDLY":
              case 15: {
                message.sourceApplication = 15;
                break;
              }
              case "FEATHERY":
              case 16: {
                message.sourceApplication = 16;
                break;
              }
              default: {
                if (typeof object.sourceApplication == "number") {
                  message.sourceApplication = object.sourceApplication;
                  break;
                }
                break;
              }
            }
          }
          if (object.requestTime != null) {
            if (typeof object.requestTime !== "object") {
              throw new TypeError(".sources.v2.WebhookRequest.requestTime: object expected, but got " + (typeof object.requestTime));
            }
            message.requestTime = timing$1.v1.Timestamp.fromObject(object.requestTime);
          }
          if (object.commitTimestamp != null) {
            if (typeof object.commitTimestamp !== "object") {
              throw new TypeError(".sources.v2.WebhookRequest.commitTimestamp: object expected, but got " + (typeof object.commitTimestamp));
            }
            message.commitTimestamp = timing$1.v1.Timestamp.fromObject(object.commitTimestamp);
          }
          if (object.method != null) {
            message.method = String(object.method);
          }
          if (object.host != null) {
            message.host = String(object.host);
          }
          if (object.rawQueryParams != null) {
            message.rawQueryParams = String(object.rawQueryParams);
          }
          if (object.headers != null) {
            if (typeof object.headers !== "object") {
              throw new TypeError(".sources.v2.WebhookRequest.headers: object expected, but got " + (typeof object.headers));
            }
            message.headers = google$1.protobuf.Struct.fromObject(object.headers);
          }
          if (object.sourceType != null) {
            if (typeof object.sourceType !== "object") {
              throw new TypeError(".sources.v2.WebhookRequest.sourceType: object expected, but got " + (typeof object.sourceType));
            }
            message.sourceType = $root.sources.v2.SourceType.fromObject(object.sourceType);
          }
          if (object.bytes != null) {
            if (typeof object.bytes === "string") {
              $util.base64.decode(object.bytes, message.bytes = $util.newBuffer($util.base64.length(object.bytes)), 0);
            } else if (object.bytes.length >= 0) {
              message.bytes = object.bytes;
            }
          }
          if (object.json != null) {
            if (typeof object.json !== "object") {
              throw new TypeError(".sources.v2.WebhookRequest.json: object expected, but got " + (typeof object.json));
            }
            message.json = google$1.protobuf.Struct.fromObject(object.json);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.orgId = "";
            object.sourceId = "";
            object.webhookId = "";
            object.sourceApplication = options.enums === String ? "UNSPECIFIED_SOURCE_APPLICATION" : 0
            object.requestTime = null;
            object.commitTimestamp = null;
            object.method = "";
            object.host = "";
            object.rawQueryParams = "";
            object.headers = null;
            object.sourceType = null;
          }
          let keys;
          if (message.orgId != null && message.hasOwnProperty("orgId")) {
            object.orgId = message.orgId;
          }
          if (message.sourceId != null && message.hasOwnProperty("sourceId")) {
            object.sourceId = message.sourceId;
          }
          if (message.webhookId != null && message.hasOwnProperty("webhookId")) {
            object.webhookId = message.webhookId;
          }
          if (message.sourceApplication != null && message.hasOwnProperty("sourceApplication")) {
            object.sourceApplication = options.enums === String ? source_application$1.v2.SourceApplication.Application[message.sourceApplication] === undefined ? message.sourceApplication : source_application$1.v2.SourceApplication.Application[message.sourceApplication] : message.sourceApplication;
          }
          if (message.requestTime != null && message.hasOwnProperty("requestTime")) {
            object.requestTime = timing$1.v1.Timestamp.toObject(message.requestTime, options);
          }
          if (message.commitTimestamp != null && message.hasOwnProperty("commitTimestamp")) {
            object.commitTimestamp = timing$1.v1.Timestamp.toObject(message.commitTimestamp, options);
          }
          if (message.method != null && message.hasOwnProperty("method")) {
            object.method = message.method;
          }
          if (message.host != null && message.hasOwnProperty("host")) {
            object.host = message.host;
          }
          if (message.rawQueryParams != null && message.hasOwnProperty("rawQueryParams")) {
            object.rawQueryParams = message.rawQueryParams;
          }
          if (message.headers != null && message.hasOwnProperty("headers")) {
            object.headers = google$1.protobuf.Struct.toObject(message.headers, options);
          }
          if (message.sourceType != null && message.hasOwnProperty("sourceType")) {
            object.sourceType = $root.sources.v2.SourceType.toObject(message.sourceType, options);
          }
          if (message.bytes != null && message.hasOwnProperty("bytes")) {
            object.bytes = options.bytes === String ? $util.base64.encode(message.bytes, 0, message.bytes.length) : options.bytes === Array ? Array.prototype.slice.call(message.bytes) : message.bytes;
            if (options.oneofs) {
              object.body = "bytes";
            }
          }
          if (message.json != null && message.hasOwnProperty("json")) {
            object.json = google$1.protobuf.Struct.toObject(message.json, options);
            if (options.oneofs) {
              object.body = "json";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.WebhookRequest";
        }
      }

      WebhookRequest.prototype.orgId = "";
      WebhookRequest.prototype.sourceId = "";
      WebhookRequest.prototype.webhookId = "";
      WebhookRequest.prototype.sourceApplication = 0;
      WebhookRequest.prototype.requestTime = null;
      WebhookRequest.prototype.commitTimestamp = null;
      WebhookRequest.prototype.method = "";
      WebhookRequest.prototype.host = "";
      WebhookRequest.prototype.rawQueryParams = "";
      WebhookRequest.prototype.headers = null;
      WebhookRequest.prototype.sourceType = null;
      WebhookRequest.prototype.bytes = new Uint8Array();
      WebhookRequest.prototype.json = null;

      return WebhookRequest;
    })();

    v2.CreateRequest = (() => {
      class CreateRequest {
        get source() {
          for (const key of ["formsortWebhook", "healthieWebhook", "axleHealthWebhook", "butterflyLabsWebhook", "acuityWebhook", "junctionWebhook", "segmentWebhook", "activeCampaignWebhook", "morfAppointmentWebhook", "pushJsonWebhook", "intakeqWebhook", "customerIoWebhook", "hubspotWebhook", "morfAnalyticsWebhook", "calendlyWebhook", "featheryWebhook"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set source(name) {
          for (const key of ["formsortWebhook", "healthieWebhook", "axleHealthWebhook", "butterflyLabsWebhook", "acuityWebhook", "junctionWebhook", "segmentWebhook", "activeCampaignWebhook", "morfAppointmentWebhook", "pushJsonWebhook", "intakeqWebhook", "customerIoWebhook", "hubspotWebhook", "morfAnalyticsWebhook", "calendlyWebhook", "featheryWebhook"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.formsortWebhook != null && Object.hasOwnProperty.call(message, "formsortWebhook")) {
            $root.sources.v2.CreateFormsortWebhookSource.encode(message.formsortWebhook, writer.uint32(10).fork()).ldelim();
          }
          if (message.healthieWebhook != null && Object.hasOwnProperty.call(message, "healthieWebhook")) {
            $root.sources.v2.CreateHealthieWebhookSource.encode(message.healthieWebhook, writer.uint32(18).fork()).ldelim();
          }
          if (message.axleHealthWebhook != null && Object.hasOwnProperty.call(message, "axleHealthWebhook")) {
            $root.sources.v2.CreateAxleHealthWebhookSource.encode(message.axleHealthWebhook, writer.uint32(26).fork()).ldelim();
          }
          if (message.butterflyLabsWebhook != null && Object.hasOwnProperty.call(message, "butterflyLabsWebhook")) {
            $root.sources.v2.CreateButterflyLabsWebhookSource.encode(message.butterflyLabsWebhook, writer.uint32(34).fork()).ldelim();
          }
          if (message.acuityWebhook != null && Object.hasOwnProperty.call(message, "acuityWebhook")) {
            $root.sources.v2.CreateAcuityWebhookSource.encode(message.acuityWebhook, writer.uint32(42).fork()).ldelim();
          }
          if (message.junctionWebhook != null && Object.hasOwnProperty.call(message, "junctionWebhook")) {
            $root.sources.v2.CreateJunctionWebhookSource.encode(message.junctionWebhook, writer.uint32(50).fork()).ldelim();
          }
          if (message.segmentWebhook != null && Object.hasOwnProperty.call(message, "segmentWebhook")) {
            $root.sources.v2.CreateSegmentWebhookSource.encode(message.segmentWebhook, writer.uint32(58).fork()).ldelim();
          }
          if (message.activeCampaignWebhook != null && Object.hasOwnProperty.call(message, "activeCampaignWebhook")) {
            $root.sources.v2.CreateActiveCampaignWebhookSource.encode(message.activeCampaignWebhook, writer.uint32(66).fork()).ldelim();
          }
          if (message.morfAppointmentWebhook != null && Object.hasOwnProperty.call(message, "morfAppointmentWebhook")) {
            $root.sources.v2.CreateMorfAppointmentWebhookSource.encode(message.morfAppointmentWebhook, writer.uint32(74).fork()).ldelim();
          }
          if (message.pushJsonWebhook != null && Object.hasOwnProperty.call(message, "pushJsonWebhook")) {
            $root.sources.v2.CreatePushJsonWebhookSource.encode(message.pushJsonWebhook, writer.uint32(82).fork()).ldelim();
          }
          if (message.intakeqWebhook != null && Object.hasOwnProperty.call(message, "intakeqWebhook")) {
            $root.sources.v2.CreateIntakeQWebhookSource.encode(message.intakeqWebhook, writer.uint32(90).fork()).ldelim();
          }
          if (message.customerIoWebhook != null && Object.hasOwnProperty.call(message, "customerIoWebhook")) {
            $root.sources.v2.CreateCustomerIoWebhookSource.encode(message.customerIoWebhook, writer.uint32(98).fork()).ldelim();
          }
          if (message.hubspotWebhook != null && Object.hasOwnProperty.call(message, "hubspotWebhook")) {
            $root.sources.v2.CreateHubspotWebhookSource.encode(message.hubspotWebhook, writer.uint32(106).fork()).ldelim();
          }
          if (message.morfAnalyticsWebhook != null && Object.hasOwnProperty.call(message, "morfAnalyticsWebhook")) {
            $root.sources.v2.CreateMorfAnalyticsWebhookSource.encode(message.morfAnalyticsWebhook, writer.uint32(114).fork()).ldelim();
          }
          if (message.calendlyWebhook != null && Object.hasOwnProperty.call(message, "calendlyWebhook")) {
            $root.sources.v2.CreateCalendlyWebhookSource.encode(message.calendlyWebhook, writer.uint32(122).fork()).ldelim();
          }
          if (message.featheryWebhook != null && Object.hasOwnProperty.call(message, "featheryWebhook")) {
            $root.sources.v2.CreateFeatheryWebhookSource.encode(message.featheryWebhook, writer.uint32(130).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.formsortWebhook = $root.sources.v2.CreateFormsortWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.healthieWebhook = $root.sources.v2.CreateHealthieWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.axleHealthWebhook = $root.sources.v2.CreateAxleHealthWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.butterflyLabsWebhook = $root.sources.v2.CreateButterflyLabsWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.acuityWebhook = $root.sources.v2.CreateAcuityWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.junctionWebhook = $root.sources.v2.CreateJunctionWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.segmentWebhook = $root.sources.v2.CreateSegmentWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 8: {
                message.activeCampaignWebhook = $root.sources.v2.CreateActiveCampaignWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 9: {
                message.morfAppointmentWebhook = $root.sources.v2.CreateMorfAppointmentWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 10: {
                message.pushJsonWebhook = $root.sources.v2.CreatePushJsonWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 11: {
                message.intakeqWebhook = $root.sources.v2.CreateIntakeQWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 12: {
                message.customerIoWebhook = $root.sources.v2.CreateCustomerIoWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 13: {
                message.hubspotWebhook = $root.sources.v2.CreateHubspotWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 14: {
                message.morfAnalyticsWebhook = $root.sources.v2.CreateMorfAnalyticsWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 15: {
                message.calendlyWebhook = $root.sources.v2.CreateCalendlyWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 16: {
                message.featheryWebhook = $root.sources.v2.CreateFeatheryWebhookSource.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.CreateRequest) {
            return object;
          }
          const message = new $root.sources.v2.CreateRequest();
          if (object.formsortWebhook != null) {
            if (typeof object.formsortWebhook !== "object") {
              throw new TypeError(".sources.v2.CreateRequest.formsortWebhook: object expected, but got " + (typeof object.formsortWebhook));
            }
            message.formsortWebhook = $root.sources.v2.CreateFormsortWebhookSource.fromObject(object.formsortWebhook);
          }
          if (object.healthieWebhook != null) {
            if (typeof object.healthieWebhook !== "object") {
              throw new TypeError(".sources.v2.CreateRequest.healthieWebhook: object expected, but got " + (typeof object.healthieWebhook));
            }
            message.healthieWebhook = $root.sources.v2.CreateHealthieWebhookSource.fromObject(object.healthieWebhook);
          }
          if (object.axleHealthWebhook != null) {
            if (typeof object.axleHealthWebhook !== "object") {
              throw new TypeError(".sources.v2.CreateRequest.axleHealthWebhook: object expected, but got " + (typeof object.axleHealthWebhook));
            }
            message.axleHealthWebhook = $root.sources.v2.CreateAxleHealthWebhookSource.fromObject(object.axleHealthWebhook);
          }
          if (object.butterflyLabsWebhook != null) {
            if (typeof object.butterflyLabsWebhook !== "object") {
              throw new TypeError(".sources.v2.CreateRequest.butterflyLabsWebhook: object expected, but got " + (typeof object.butterflyLabsWebhook));
            }
            message.butterflyLabsWebhook = $root.sources.v2.CreateButterflyLabsWebhookSource.fromObject(object.butterflyLabsWebhook);
          }
          if (object.acuityWebhook != null) {
            if (typeof object.acuityWebhook !== "object") {
              throw new TypeError(".sources.v2.CreateRequest.acuityWebhook: object expected, but got " + (typeof object.acuityWebhook));
            }
            message.acuityWebhook = $root.sources.v2.CreateAcuityWebhookSource.fromObject(object.acuityWebhook);
          }
          if (object.junctionWebhook != null) {
            if (typeof object.junctionWebhook !== "object") {
              throw new TypeError(".sources.v2.CreateRequest.junctionWebhook: object expected, but got " + (typeof object.junctionWebhook));
            }
            message.junctionWebhook = $root.sources.v2.CreateJunctionWebhookSource.fromObject(object.junctionWebhook);
          }
          if (object.segmentWebhook != null) {
            if (typeof object.segmentWebhook !== "object") {
              throw new TypeError(".sources.v2.CreateRequest.segmentWebhook: object expected, but got " + (typeof object.segmentWebhook));
            }
            message.segmentWebhook = $root.sources.v2.CreateSegmentWebhookSource.fromObject(object.segmentWebhook);
          }
          if (object.activeCampaignWebhook != null) {
            if (typeof object.activeCampaignWebhook !== "object") {
              throw new TypeError(".sources.v2.CreateRequest.activeCampaignWebhook: object expected, but got " + (typeof object.activeCampaignWebhook));
            }
            message.activeCampaignWebhook = $root.sources.v2.CreateActiveCampaignWebhookSource.fromObject(object.activeCampaignWebhook);
          }
          if (object.morfAppointmentWebhook != null) {
            if (typeof object.morfAppointmentWebhook !== "object") {
              throw new TypeError(".sources.v2.CreateRequest.morfAppointmentWebhook: object expected, but got " + (typeof object.morfAppointmentWebhook));
            }
            message.morfAppointmentWebhook = $root.sources.v2.CreateMorfAppointmentWebhookSource.fromObject(object.morfAppointmentWebhook);
          }
          if (object.pushJsonWebhook != null) {
            if (typeof object.pushJsonWebhook !== "object") {
              throw new TypeError(".sources.v2.CreateRequest.pushJsonWebhook: object expected, but got " + (typeof object.pushJsonWebhook));
            }
            message.pushJsonWebhook = $root.sources.v2.CreatePushJsonWebhookSource.fromObject(object.pushJsonWebhook);
          }
          if (object.intakeqWebhook != null) {
            if (typeof object.intakeqWebhook !== "object") {
              throw new TypeError(".sources.v2.CreateRequest.intakeqWebhook: object expected, but got " + (typeof object.intakeqWebhook));
            }
            message.intakeqWebhook = $root.sources.v2.CreateIntakeQWebhookSource.fromObject(object.intakeqWebhook);
          }
          if (object.customerIoWebhook != null) {
            if (typeof object.customerIoWebhook !== "object") {
              throw new TypeError(".sources.v2.CreateRequest.customerIoWebhook: object expected, but got " + (typeof object.customerIoWebhook));
            }
            message.customerIoWebhook = $root.sources.v2.CreateCustomerIoWebhookSource.fromObject(object.customerIoWebhook);
          }
          if (object.hubspotWebhook != null) {
            if (typeof object.hubspotWebhook !== "object") {
              throw new TypeError(".sources.v2.CreateRequest.hubspotWebhook: object expected, but got " + (typeof object.hubspotWebhook));
            }
            message.hubspotWebhook = $root.sources.v2.CreateHubspotWebhookSource.fromObject(object.hubspotWebhook);
          }
          if (object.morfAnalyticsWebhook != null) {
            if (typeof object.morfAnalyticsWebhook !== "object") {
              throw new TypeError(".sources.v2.CreateRequest.morfAnalyticsWebhook: object expected, but got " + (typeof object.morfAnalyticsWebhook));
            }
            message.morfAnalyticsWebhook = $root.sources.v2.CreateMorfAnalyticsWebhookSource.fromObject(object.morfAnalyticsWebhook);
          }
          if (object.calendlyWebhook != null) {
            if (typeof object.calendlyWebhook !== "object") {
              throw new TypeError(".sources.v2.CreateRequest.calendlyWebhook: object expected, but got " + (typeof object.calendlyWebhook));
            }
            message.calendlyWebhook = $root.sources.v2.CreateCalendlyWebhookSource.fromObject(object.calendlyWebhook);
          }
          if (object.featheryWebhook != null) {
            if (typeof object.featheryWebhook !== "object") {
              throw new TypeError(".sources.v2.CreateRequest.featheryWebhook: object expected, but got " + (typeof object.featheryWebhook));
            }
            message.featheryWebhook = $root.sources.v2.CreateFeatheryWebhookSource.fromObject(object.featheryWebhook);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.formsortWebhook != null && message.hasOwnProperty("formsortWebhook")) {
            object.formsortWebhook = $root.sources.v2.CreateFormsortWebhookSource.toObject(message.formsortWebhook, options);
            if (options.oneofs) {
              object.source = "formsortWebhook";
            }
          }
          if (message.healthieWebhook != null && message.hasOwnProperty("healthieWebhook")) {
            object.healthieWebhook = $root.sources.v2.CreateHealthieWebhookSource.toObject(message.healthieWebhook, options);
            if (options.oneofs) {
              object.source = "healthieWebhook";
            }
          }
          if (message.axleHealthWebhook != null && message.hasOwnProperty("axleHealthWebhook")) {
            object.axleHealthWebhook = $root.sources.v2.CreateAxleHealthWebhookSource.toObject(message.axleHealthWebhook, options);
            if (options.oneofs) {
              object.source = "axleHealthWebhook";
            }
          }
          if (message.butterflyLabsWebhook != null && message.hasOwnProperty("butterflyLabsWebhook")) {
            object.butterflyLabsWebhook = $root.sources.v2.CreateButterflyLabsWebhookSource.toObject(message.butterflyLabsWebhook, options);
            if (options.oneofs) {
              object.source = "butterflyLabsWebhook";
            }
          }
          if (message.acuityWebhook != null && message.hasOwnProperty("acuityWebhook")) {
            object.acuityWebhook = $root.sources.v2.CreateAcuityWebhookSource.toObject(message.acuityWebhook, options);
            if (options.oneofs) {
              object.source = "acuityWebhook";
            }
          }
          if (message.junctionWebhook != null && message.hasOwnProperty("junctionWebhook")) {
            object.junctionWebhook = $root.sources.v2.CreateJunctionWebhookSource.toObject(message.junctionWebhook, options);
            if (options.oneofs) {
              object.source = "junctionWebhook";
            }
          }
          if (message.segmentWebhook != null && message.hasOwnProperty("segmentWebhook")) {
            object.segmentWebhook = $root.sources.v2.CreateSegmentWebhookSource.toObject(message.segmentWebhook, options);
            if (options.oneofs) {
              object.source = "segmentWebhook";
            }
          }
          if (message.activeCampaignWebhook != null && message.hasOwnProperty("activeCampaignWebhook")) {
            object.activeCampaignWebhook = $root.sources.v2.CreateActiveCampaignWebhookSource.toObject(message.activeCampaignWebhook, options);
            if (options.oneofs) {
              object.source = "activeCampaignWebhook";
            }
          }
          if (message.morfAppointmentWebhook != null && message.hasOwnProperty("morfAppointmentWebhook")) {
            object.morfAppointmentWebhook = $root.sources.v2.CreateMorfAppointmentWebhookSource.toObject(message.morfAppointmentWebhook, options);
            if (options.oneofs) {
              object.source = "morfAppointmentWebhook";
            }
          }
          if (message.pushJsonWebhook != null && message.hasOwnProperty("pushJsonWebhook")) {
            object.pushJsonWebhook = $root.sources.v2.CreatePushJsonWebhookSource.toObject(message.pushJsonWebhook, options);
            if (options.oneofs) {
              object.source = "pushJsonWebhook";
            }
          }
          if (message.intakeqWebhook != null && message.hasOwnProperty("intakeqWebhook")) {
            object.intakeqWebhook = $root.sources.v2.CreateIntakeQWebhookSource.toObject(message.intakeqWebhook, options);
            if (options.oneofs) {
              object.source = "intakeqWebhook";
            }
          }
          if (message.customerIoWebhook != null && message.hasOwnProperty("customerIoWebhook")) {
            object.customerIoWebhook = $root.sources.v2.CreateCustomerIoWebhookSource.toObject(message.customerIoWebhook, options);
            if (options.oneofs) {
              object.source = "customerIoWebhook";
            }
          }
          if (message.hubspotWebhook != null && message.hasOwnProperty("hubspotWebhook")) {
            object.hubspotWebhook = $root.sources.v2.CreateHubspotWebhookSource.toObject(message.hubspotWebhook, options);
            if (options.oneofs) {
              object.source = "hubspotWebhook";
            }
          }
          if (message.morfAnalyticsWebhook != null && message.hasOwnProperty("morfAnalyticsWebhook")) {
            object.morfAnalyticsWebhook = $root.sources.v2.CreateMorfAnalyticsWebhookSource.toObject(message.morfAnalyticsWebhook, options);
            if (options.oneofs) {
              object.source = "morfAnalyticsWebhook";
            }
          }
          if (message.calendlyWebhook != null && message.hasOwnProperty("calendlyWebhook")) {
            object.calendlyWebhook = $root.sources.v2.CreateCalendlyWebhookSource.toObject(message.calendlyWebhook, options);
            if (options.oneofs) {
              object.source = "calendlyWebhook";
            }
          }
          if (message.featheryWebhook != null && message.hasOwnProperty("featheryWebhook")) {
            object.featheryWebhook = $root.sources.v2.CreateFeatheryWebhookSource.toObject(message.featheryWebhook, options);
            if (options.oneofs) {
              object.source = "featheryWebhook";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.CreateRequest";
        }
      }

      CreateRequest.prototype.formsortWebhook = null;
      CreateRequest.prototype.healthieWebhook = null;
      CreateRequest.prototype.axleHealthWebhook = null;
      CreateRequest.prototype.butterflyLabsWebhook = null;
      CreateRequest.prototype.acuityWebhook = null;
      CreateRequest.prototype.junctionWebhook = null;
      CreateRequest.prototype.segmentWebhook = null;
      CreateRequest.prototype.activeCampaignWebhook = null;
      CreateRequest.prototype.morfAppointmentWebhook = null;
      CreateRequest.prototype.pushJsonWebhook = null;
      CreateRequest.prototype.intakeqWebhook = null;
      CreateRequest.prototype.customerIoWebhook = null;
      CreateRequest.prototype.hubspotWebhook = null;
      CreateRequest.prototype.morfAnalyticsWebhook = null;
      CreateRequest.prototype.calendlyWebhook = null;
      CreateRequest.prototype.featheryWebhook = null;

      return CreateRequest;
    })();

    v2.CreateFeatheryWebhookSource = (() => {
      class CreateFeatheryWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateFeatheryWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            feathery$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateFeatheryWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = feathery$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.CreateFeatheryWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.CreateFeatheryWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.CreateFeatheryWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = feathery$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = feathery$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.CreateFeatheryWebhookSource";
        }
      }

      CreateFeatheryWebhookSource.prototype.subscribedEventTypes = null;

      return CreateFeatheryWebhookSource;
    })();

    v2.CreateFormsortWebhookSource = (() => {
      class CreateFormsortWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateFormsortWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            formsort$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateFormsortWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = formsort$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.CreateFormsortWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.CreateFormsortWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.CreateFormsortWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = formsort$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = formsort$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.CreateFormsortWebhookSource";
        }
      }

      CreateFormsortWebhookSource.prototype.subscribedEventTypes = null;

      return CreateFormsortWebhookSource;
    })();

    v2.CreateHealthieWebhookSource = (() => {
      class CreateHealthieWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateHealthieWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            healthie$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          if (message.environment != null && Object.hasOwnProperty.call(message, "environment")) {
            writer.uint32(16).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateHealthieWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = healthie$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.CreateHealthieWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.CreateHealthieWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.CreateHealthieWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = healthie$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case "UNDEFINED_ILLEGAL":
              case 0: {
                message.environment = 0;
                break;
              }
              case "SANDBOX":
              case 1: {
                message.environment = 1;
                break;
              }
              case "PRODUCTION":
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == "number") {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
            object.environment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = healthie$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          if (message.environment != null && message.hasOwnProperty("environment")) {
            object.environment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.environment] === undefined ? message.environment : healthie$1.v1.HealthieEnvironment[message.environment] : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.CreateHealthieWebhookSource";
        }
      }

      CreateHealthieWebhookSource.prototype.subscribedEventTypes = null;
      CreateHealthieWebhookSource.prototype.environment = 0;

      return CreateHealthieWebhookSource;
    })();

    v2.CreateAxleHealthWebhookSource = (() => {
      class CreateAxleHealthWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateAxleHealthWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            axle_health$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          if (message.environment != null && Object.hasOwnProperty.call(message, "environment")) {
            writer.uint32(16).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateAxleHealthWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = axle_health$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.CreateAxleHealthWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.CreateAxleHealthWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.CreateAxleHealthWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = axle_health$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case "UNDEFINED_ILLEGAL":
              case 0: {
                message.environment = 0;
                break;
              }
              case "DEVELOPMENT":
              case 1: {
                message.environment = 1;
                break;
              }
              case "PRODUCTION":
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == "number") {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
            object.environment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = axle_health$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          if (message.environment != null && message.hasOwnProperty("environment")) {
            object.environment = options.enums === String ? axle_health$1.v1.AxleHealthEnvironment[message.environment] === undefined ? message.environment : axle_health$1.v1.AxleHealthEnvironment[message.environment] : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.CreateAxleHealthWebhookSource";
        }
      }

      CreateAxleHealthWebhookSource.prototype.subscribedEventTypes = null;
      CreateAxleHealthWebhookSource.prototype.environment = 0;

      return CreateAxleHealthWebhookSource;
    })();

    v2.CreateButterflyLabsWebhookSource = (() => {
      class CreateButterflyLabsWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateButterflyLabsWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            butterfly_labs$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateButterflyLabsWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = butterfly_labs$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.CreateButterflyLabsWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.CreateButterflyLabsWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.CreateButterflyLabsWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = butterfly_labs$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = butterfly_labs$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.CreateButterflyLabsWebhookSource";
        }
      }

      CreateButterflyLabsWebhookSource.prototype.subscribedEventTypes = null;

      return CreateButterflyLabsWebhookSource;
    })();

    v2.CreateAcuityWebhookSource = (() => {
      class CreateAcuityWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateAcuityWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            acuity$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateAcuityWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = acuity$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.CreateAcuityWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.CreateAcuityWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.CreateAcuityWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = acuity$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = acuity$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.CreateAcuityWebhookSource";
        }
      }

      CreateAcuityWebhookSource.prototype.subscribedEventTypes = null;

      return CreateAcuityWebhookSource;
    })();

    v2.CreateJunctionWebhookSource = (() => {
      class CreateJunctionWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateJunctionWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            junction$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          if (message.environment != null && Object.hasOwnProperty.call(message, "environment")) {
            writer.uint32(16).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateJunctionWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = junction$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.CreateJunctionWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.CreateJunctionWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.CreateJunctionWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = junction$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case "UNDEFINED_ILLEGAL":
              case 0: {
                message.environment = 0;
                break;
              }
              case "STAGING":
              case 1: {
                message.environment = 1;
                break;
              }
              case "PRODUCTION":
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == "number") {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
            object.environment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = junction$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          if (message.environment != null && message.hasOwnProperty("environment")) {
            object.environment = options.enums === String ? junction$1.v1.JunctionEnvironment[message.environment] === undefined ? message.environment : junction$1.v1.JunctionEnvironment[message.environment] : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.CreateJunctionWebhookSource";
        }
      }

      CreateJunctionWebhookSource.prototype.subscribedEventTypes = null;
      CreateJunctionWebhookSource.prototype.environment = 0;

      return CreateJunctionWebhookSource;
    })();

    v2.CreateSegmentWebhookSource = (() => {
      class CreateSegmentWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateSegmentWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            segment$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateSegmentWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = segment$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.CreateSegmentWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.CreateSegmentWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.CreateSegmentWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = segment$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = segment$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.CreateSegmentWebhookSource";
        }
      }

      CreateSegmentWebhookSource.prototype.subscribedEventTypes = null;

      return CreateSegmentWebhookSource;
    })();

    v2.CreateActiveCampaignWebhookSource = (() => {
      class CreateActiveCampaignWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateActiveCampaignWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            active_campaign$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateActiveCampaignWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = active_campaign$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.CreateActiveCampaignWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.CreateActiveCampaignWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.CreateActiveCampaignWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = active_campaign$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = active_campaign$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.CreateActiveCampaignWebhookSource";
        }
      }

      CreateActiveCampaignWebhookSource.prototype.subscribedEventTypes = null;

      return CreateActiveCampaignWebhookSource;
    })();

    v2.CreateMorfAnalyticsWebhookSource = (() => {
      class CreateMorfAnalyticsWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateMorfAnalyticsWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            morf_analytics$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateMorfAnalyticsWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = morf_analytics$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.CreateMorfAnalyticsWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.CreateMorfAnalyticsWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.CreateMorfAnalyticsWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = morf_analytics$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = morf_analytics$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.CreateMorfAnalyticsWebhookSource";
        }
      }

      CreateMorfAnalyticsWebhookSource.prototype.subscribedEventTypes = null;

      return CreateMorfAnalyticsWebhookSource;
    })();

    v2.CreateMorfAppointmentWebhookSource = (() => {
      class CreateMorfAppointmentWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateMorfAppointmentWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            morf_appointment$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateMorfAppointmentWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = morf_appointment$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.CreateMorfAppointmentWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.CreateMorfAppointmentWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.CreateMorfAppointmentWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = morf_appointment$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = morf_appointment$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.CreateMorfAppointmentWebhookSource";
        }
      }

      CreateMorfAppointmentWebhookSource.prototype.subscribedEventTypes = null;

      return CreateMorfAppointmentWebhookSource;
    })();

    v2.CreatePushJsonWebhookSource = (() => {
      class CreatePushJsonWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreatePushJsonWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            push_json$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreatePushJsonWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = push_json$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.CreatePushJsonWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.CreatePushJsonWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.CreatePushJsonWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = push_json$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = push_json$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.CreatePushJsonWebhookSource";
        }
      }

      CreatePushJsonWebhookSource.prototype.subscribedEventTypes = null;

      return CreatePushJsonWebhookSource;
    })();

    v2.CreateIntakeQWebhookSource = (() => {
      class CreateIntakeQWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateIntakeQWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            intakeq$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateIntakeQWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = intakeq$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.CreateIntakeQWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.CreateIntakeQWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.CreateIntakeQWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = intakeq$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = intakeq$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.CreateIntakeQWebhookSource";
        }
      }

      CreateIntakeQWebhookSource.prototype.subscribedEventTypes = null;

      return CreateIntakeQWebhookSource;
    })();

    v2.CreateCustomerIoWebhookSource = (() => {
      class CreateCustomerIoWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateCustomerIoWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            customerio$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateCustomerIoWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = customerio$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.CreateCustomerIoWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.CreateCustomerIoWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.CreateCustomerIoWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = customerio$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = customerio$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.CreateCustomerIoWebhookSource";
        }
      }

      CreateCustomerIoWebhookSource.prototype.subscribedEventTypes = null;

      return CreateCustomerIoWebhookSource;
    })();

    v2.CreateHubspotWebhookSource = (() => {
      class CreateHubspotWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateHubspotWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            hubspot$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateHubspotWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = hubspot$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.CreateHubspotWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.CreateHubspotWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.CreateHubspotWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = hubspot$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = hubspot$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.CreateHubspotWebhookSource";
        }
      }

      CreateHubspotWebhookSource.prototype.subscribedEventTypes = null;

      return CreateHubspotWebhookSource;
    })();

    v2.CreateCalendlyWebhookSource = (() => {
      class CreateCalendlyWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateCalendlyWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            calendly$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateCalendlyWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = calendly$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.CreateCalendlyWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.CreateCalendlyWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.CreateCalendlyWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = calendly$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = calendly$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.CreateCalendlyWebhookSource";
        }
      }

      CreateCalendlyWebhookSource.prototype.subscribedEventTypes = null;

      return CreateCalendlyWebhookSource;
    })();

    v2.Source = (() => {
      class Source {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Source(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          if (message.timing != null && Object.hasOwnProperty.call(message, "timing")) {
            timing$1.v1.Timing.encode(message.timing, writer.uint32(18).fork()).ldelim();
          }
          if (message.sourceType != null && Object.hasOwnProperty.call(message, "sourceType")) {
            $root.sources.v2.SourceType.encode(message.sourceType, writer.uint32(26).fork()).ldelim();
          }
          if (message.organizationId != null && Object.hasOwnProperty.call(message, "organizationId")) {
            writer.uint32(34).string(message.organizationId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Source();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.timing = timing$1.v1.Timing.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.sourceType = $root.sources.v2.SourceType.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.organizationId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.Source) {
            return object;
          }
          const message = new $root.sources.v2.Source();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.timing != null) {
            if (typeof object.timing !== "object") {
              throw new TypeError(".sources.v2.Source.timing: object expected, but got " + (typeof object.timing));
            }
            message.timing = timing$1.v1.Timing.fromObject(object.timing);
          }
          if (object.sourceType != null) {
            if (typeof object.sourceType !== "object") {
              throw new TypeError(".sources.v2.Source.sourceType: object expected, but got " + (typeof object.sourceType));
            }
            message.sourceType = $root.sources.v2.SourceType.fromObject(object.sourceType);
          }
          if (object.organizationId != null) {
            message.organizationId = String(object.organizationId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
            object.timing = null;
            object.sourceType = null;
            object.organizationId = "";
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.timing != null && message.hasOwnProperty("timing")) {
            object.timing = timing$1.v1.Timing.toObject(message.timing, options);
          }
          if (message.sourceType != null && message.hasOwnProperty("sourceType")) {
            object.sourceType = $root.sources.v2.SourceType.toObject(message.sourceType, options);
          }
          if (message.organizationId != null && message.hasOwnProperty("organizationId")) {
            object.organizationId = message.organizationId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.Source";
        }
      }

      Source.prototype.id = "";
      Source.prototype.timing = null;
      Source.prototype.sourceType = null;
      Source.prototype.organizationId = "";

      return Source;
    })();

    v2.SourceType = (() => {
      class SourceType {
        get type() {
          for (const key of ["formsortWebhook", "healthieWebhook", "axleHealthWebhook", "butterflyLabsWebhook", "acuityWebhook", "junctionWebhook", "segmentWebhook", "activeCampaignWebhook", "morfAppointmentWebhook", "pushJsonWebhook", "intakeqWebhook", "customerIoWebhook", "hubspotWebhook", "morfAnalyticsWebhook", "calendlyWebhook", "featheryWebhook"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["formsortWebhook", "healthieWebhook", "axleHealthWebhook", "butterflyLabsWebhook", "acuityWebhook", "junctionWebhook", "segmentWebhook", "activeCampaignWebhook", "morfAppointmentWebhook", "pushJsonWebhook", "intakeqWebhook", "customerIoWebhook", "hubspotWebhook", "morfAnalyticsWebhook", "calendlyWebhook", "featheryWebhook"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SourceType(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.formsortWebhook != null && Object.hasOwnProperty.call(message, "formsortWebhook")) {
            $root.sources.v2.FormsortWebhookSource.encode(message.formsortWebhook, writer.uint32(10).fork()).ldelim();
          }
          if (message.healthieWebhook != null && Object.hasOwnProperty.call(message, "healthieWebhook")) {
            $root.sources.v2.HealthieWebhookSource.encode(message.healthieWebhook, writer.uint32(18).fork()).ldelim();
          }
          if (message.axleHealthWebhook != null && Object.hasOwnProperty.call(message, "axleHealthWebhook")) {
            $root.sources.v2.AxleHealthWebhookSource.encode(message.axleHealthWebhook, writer.uint32(26).fork()).ldelim();
          }
          if (message.butterflyLabsWebhook != null && Object.hasOwnProperty.call(message, "butterflyLabsWebhook")) {
            $root.sources.v2.ButterflyLabsWebhookSource.encode(message.butterflyLabsWebhook, writer.uint32(34).fork()).ldelim();
          }
          if (message.acuityWebhook != null && Object.hasOwnProperty.call(message, "acuityWebhook")) {
            $root.sources.v2.AcuityWebhookSource.encode(message.acuityWebhook, writer.uint32(42).fork()).ldelim();
          }
          if (message.junctionWebhook != null && Object.hasOwnProperty.call(message, "junctionWebhook")) {
            $root.sources.v2.JunctionWebhookSource.encode(message.junctionWebhook, writer.uint32(50).fork()).ldelim();
          }
          if (message.segmentWebhook != null && Object.hasOwnProperty.call(message, "segmentWebhook")) {
            $root.sources.v2.SegmentWebhookSource.encode(message.segmentWebhook, writer.uint32(58).fork()).ldelim();
          }
          if (message.activeCampaignWebhook != null && Object.hasOwnProperty.call(message, "activeCampaignWebhook")) {
            $root.sources.v2.ActiveCampaignWebhookSource.encode(message.activeCampaignWebhook, writer.uint32(66).fork()).ldelim();
          }
          if (message.morfAppointmentWebhook != null && Object.hasOwnProperty.call(message, "morfAppointmentWebhook")) {
            $root.sources.v2.MorfAppointmentWebhookSource.encode(message.morfAppointmentWebhook, writer.uint32(74).fork()).ldelim();
          }
          if (message.pushJsonWebhook != null && Object.hasOwnProperty.call(message, "pushJsonWebhook")) {
            $root.sources.v2.PushJsonWebhookSource.encode(message.pushJsonWebhook, writer.uint32(82).fork()).ldelim();
          }
          if (message.intakeqWebhook != null && Object.hasOwnProperty.call(message, "intakeqWebhook")) {
            $root.sources.v2.IntakeQWebhookSource.encode(message.intakeqWebhook, writer.uint32(90).fork()).ldelim();
          }
          if (message.customerIoWebhook != null && Object.hasOwnProperty.call(message, "customerIoWebhook")) {
            $root.sources.v2.CustomerIoWebhookSource.encode(message.customerIoWebhook, writer.uint32(98).fork()).ldelim();
          }
          if (message.hubspotWebhook != null && Object.hasOwnProperty.call(message, "hubspotWebhook")) {
            $root.sources.v2.HubspotWebhookSource.encode(message.hubspotWebhook, writer.uint32(106).fork()).ldelim();
          }
          if (message.morfAnalyticsWebhook != null && Object.hasOwnProperty.call(message, "morfAnalyticsWebhook")) {
            $root.sources.v2.MorfAnalyticsWebhookSource.encode(message.morfAnalyticsWebhook, writer.uint32(114).fork()).ldelim();
          }
          if (message.calendlyWebhook != null && Object.hasOwnProperty.call(message, "calendlyWebhook")) {
            $root.sources.v2.CalendlyWebhookSource.encode(message.calendlyWebhook, writer.uint32(122).fork()).ldelim();
          }
          if (message.featheryWebhook != null && Object.hasOwnProperty.call(message, "featheryWebhook")) {
            $root.sources.v2.FeatheryWebhookSource.encode(message.featheryWebhook, writer.uint32(130).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SourceType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.formsortWebhook = $root.sources.v2.FormsortWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.healthieWebhook = $root.sources.v2.HealthieWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.axleHealthWebhook = $root.sources.v2.AxleHealthWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.butterflyLabsWebhook = $root.sources.v2.ButterflyLabsWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.acuityWebhook = $root.sources.v2.AcuityWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.junctionWebhook = $root.sources.v2.JunctionWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.segmentWebhook = $root.sources.v2.SegmentWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 8: {
                message.activeCampaignWebhook = $root.sources.v2.ActiveCampaignWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 9: {
                message.morfAppointmentWebhook = $root.sources.v2.MorfAppointmentWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 10: {
                message.pushJsonWebhook = $root.sources.v2.PushJsonWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 11: {
                message.intakeqWebhook = $root.sources.v2.IntakeQWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 12: {
                message.customerIoWebhook = $root.sources.v2.CustomerIoWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 13: {
                message.hubspotWebhook = $root.sources.v2.HubspotWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 14: {
                message.morfAnalyticsWebhook = $root.sources.v2.MorfAnalyticsWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 15: {
                message.calendlyWebhook = $root.sources.v2.CalendlyWebhookSource.decode(reader, reader.uint32());
                break;
              }
              case 16: {
                message.featheryWebhook = $root.sources.v2.FeatheryWebhookSource.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.SourceType) {
            return object;
          }
          const message = new $root.sources.v2.SourceType();
          if (object.formsortWebhook != null) {
            if (typeof object.formsortWebhook !== "object") {
              throw new TypeError(".sources.v2.SourceType.formsortWebhook: object expected, but got " + (typeof object.formsortWebhook));
            }
            message.formsortWebhook = $root.sources.v2.FormsortWebhookSource.fromObject(object.formsortWebhook);
          }
          if (object.healthieWebhook != null) {
            if (typeof object.healthieWebhook !== "object") {
              throw new TypeError(".sources.v2.SourceType.healthieWebhook: object expected, but got " + (typeof object.healthieWebhook));
            }
            message.healthieWebhook = $root.sources.v2.HealthieWebhookSource.fromObject(object.healthieWebhook);
          }
          if (object.axleHealthWebhook != null) {
            if (typeof object.axleHealthWebhook !== "object") {
              throw new TypeError(".sources.v2.SourceType.axleHealthWebhook: object expected, but got " + (typeof object.axleHealthWebhook));
            }
            message.axleHealthWebhook = $root.sources.v2.AxleHealthWebhookSource.fromObject(object.axleHealthWebhook);
          }
          if (object.butterflyLabsWebhook != null) {
            if (typeof object.butterflyLabsWebhook !== "object") {
              throw new TypeError(".sources.v2.SourceType.butterflyLabsWebhook: object expected, but got " + (typeof object.butterflyLabsWebhook));
            }
            message.butterflyLabsWebhook = $root.sources.v2.ButterflyLabsWebhookSource.fromObject(object.butterflyLabsWebhook);
          }
          if (object.acuityWebhook != null) {
            if (typeof object.acuityWebhook !== "object") {
              throw new TypeError(".sources.v2.SourceType.acuityWebhook: object expected, but got " + (typeof object.acuityWebhook));
            }
            message.acuityWebhook = $root.sources.v2.AcuityWebhookSource.fromObject(object.acuityWebhook);
          }
          if (object.junctionWebhook != null) {
            if (typeof object.junctionWebhook !== "object") {
              throw new TypeError(".sources.v2.SourceType.junctionWebhook: object expected, but got " + (typeof object.junctionWebhook));
            }
            message.junctionWebhook = $root.sources.v2.JunctionWebhookSource.fromObject(object.junctionWebhook);
          }
          if (object.segmentWebhook != null) {
            if (typeof object.segmentWebhook !== "object") {
              throw new TypeError(".sources.v2.SourceType.segmentWebhook: object expected, but got " + (typeof object.segmentWebhook));
            }
            message.segmentWebhook = $root.sources.v2.SegmentWebhookSource.fromObject(object.segmentWebhook);
          }
          if (object.activeCampaignWebhook != null) {
            if (typeof object.activeCampaignWebhook !== "object") {
              throw new TypeError(".sources.v2.SourceType.activeCampaignWebhook: object expected, but got " + (typeof object.activeCampaignWebhook));
            }
            message.activeCampaignWebhook = $root.sources.v2.ActiveCampaignWebhookSource.fromObject(object.activeCampaignWebhook);
          }
          if (object.morfAppointmentWebhook != null) {
            if (typeof object.morfAppointmentWebhook !== "object") {
              throw new TypeError(".sources.v2.SourceType.morfAppointmentWebhook: object expected, but got " + (typeof object.morfAppointmentWebhook));
            }
            message.morfAppointmentWebhook = $root.sources.v2.MorfAppointmentWebhookSource.fromObject(object.morfAppointmentWebhook);
          }
          if (object.pushJsonWebhook != null) {
            if (typeof object.pushJsonWebhook !== "object") {
              throw new TypeError(".sources.v2.SourceType.pushJsonWebhook: object expected, but got " + (typeof object.pushJsonWebhook));
            }
            message.pushJsonWebhook = $root.sources.v2.PushJsonWebhookSource.fromObject(object.pushJsonWebhook);
          }
          if (object.intakeqWebhook != null) {
            if (typeof object.intakeqWebhook !== "object") {
              throw new TypeError(".sources.v2.SourceType.intakeqWebhook: object expected, but got " + (typeof object.intakeqWebhook));
            }
            message.intakeqWebhook = $root.sources.v2.IntakeQWebhookSource.fromObject(object.intakeqWebhook);
          }
          if (object.customerIoWebhook != null) {
            if (typeof object.customerIoWebhook !== "object") {
              throw new TypeError(".sources.v2.SourceType.customerIoWebhook: object expected, but got " + (typeof object.customerIoWebhook));
            }
            message.customerIoWebhook = $root.sources.v2.CustomerIoWebhookSource.fromObject(object.customerIoWebhook);
          }
          if (object.hubspotWebhook != null) {
            if (typeof object.hubspotWebhook !== "object") {
              throw new TypeError(".sources.v2.SourceType.hubspotWebhook: object expected, but got " + (typeof object.hubspotWebhook));
            }
            message.hubspotWebhook = $root.sources.v2.HubspotWebhookSource.fromObject(object.hubspotWebhook);
          }
          if (object.morfAnalyticsWebhook != null) {
            if (typeof object.morfAnalyticsWebhook !== "object") {
              throw new TypeError(".sources.v2.SourceType.morfAnalyticsWebhook: object expected, but got " + (typeof object.morfAnalyticsWebhook));
            }
            message.morfAnalyticsWebhook = $root.sources.v2.MorfAnalyticsWebhookSource.fromObject(object.morfAnalyticsWebhook);
          }
          if (object.calendlyWebhook != null) {
            if (typeof object.calendlyWebhook !== "object") {
              throw new TypeError(".sources.v2.SourceType.calendlyWebhook: object expected, but got " + (typeof object.calendlyWebhook));
            }
            message.calendlyWebhook = $root.sources.v2.CalendlyWebhookSource.fromObject(object.calendlyWebhook);
          }
          if (object.featheryWebhook != null) {
            if (typeof object.featheryWebhook !== "object") {
              throw new TypeError(".sources.v2.SourceType.featheryWebhook: object expected, but got " + (typeof object.featheryWebhook));
            }
            message.featheryWebhook = $root.sources.v2.FeatheryWebhookSource.fromObject(object.featheryWebhook);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.formsortWebhook != null && message.hasOwnProperty("formsortWebhook")) {
            object.formsortWebhook = $root.sources.v2.FormsortWebhookSource.toObject(message.formsortWebhook, options);
            if (options.oneofs) {
              object.type = "formsortWebhook";
            }
          }
          if (message.healthieWebhook != null && message.hasOwnProperty("healthieWebhook")) {
            object.healthieWebhook = $root.sources.v2.HealthieWebhookSource.toObject(message.healthieWebhook, options);
            if (options.oneofs) {
              object.type = "healthieWebhook";
            }
          }
          if (message.axleHealthWebhook != null && message.hasOwnProperty("axleHealthWebhook")) {
            object.axleHealthWebhook = $root.sources.v2.AxleHealthWebhookSource.toObject(message.axleHealthWebhook, options);
            if (options.oneofs) {
              object.type = "axleHealthWebhook";
            }
          }
          if (message.butterflyLabsWebhook != null && message.hasOwnProperty("butterflyLabsWebhook")) {
            object.butterflyLabsWebhook = $root.sources.v2.ButterflyLabsWebhookSource.toObject(message.butterflyLabsWebhook, options);
            if (options.oneofs) {
              object.type = "butterflyLabsWebhook";
            }
          }
          if (message.acuityWebhook != null && message.hasOwnProperty("acuityWebhook")) {
            object.acuityWebhook = $root.sources.v2.AcuityWebhookSource.toObject(message.acuityWebhook, options);
            if (options.oneofs) {
              object.type = "acuityWebhook";
            }
          }
          if (message.junctionWebhook != null && message.hasOwnProperty("junctionWebhook")) {
            object.junctionWebhook = $root.sources.v2.JunctionWebhookSource.toObject(message.junctionWebhook, options);
            if (options.oneofs) {
              object.type = "junctionWebhook";
            }
          }
          if (message.segmentWebhook != null && message.hasOwnProperty("segmentWebhook")) {
            object.segmentWebhook = $root.sources.v2.SegmentWebhookSource.toObject(message.segmentWebhook, options);
            if (options.oneofs) {
              object.type = "segmentWebhook";
            }
          }
          if (message.activeCampaignWebhook != null && message.hasOwnProperty("activeCampaignWebhook")) {
            object.activeCampaignWebhook = $root.sources.v2.ActiveCampaignWebhookSource.toObject(message.activeCampaignWebhook, options);
            if (options.oneofs) {
              object.type = "activeCampaignWebhook";
            }
          }
          if (message.morfAppointmentWebhook != null && message.hasOwnProperty("morfAppointmentWebhook")) {
            object.morfAppointmentWebhook = $root.sources.v2.MorfAppointmentWebhookSource.toObject(message.morfAppointmentWebhook, options);
            if (options.oneofs) {
              object.type = "morfAppointmentWebhook";
            }
          }
          if (message.pushJsonWebhook != null && message.hasOwnProperty("pushJsonWebhook")) {
            object.pushJsonWebhook = $root.sources.v2.PushJsonWebhookSource.toObject(message.pushJsonWebhook, options);
            if (options.oneofs) {
              object.type = "pushJsonWebhook";
            }
          }
          if (message.intakeqWebhook != null && message.hasOwnProperty("intakeqWebhook")) {
            object.intakeqWebhook = $root.sources.v2.IntakeQWebhookSource.toObject(message.intakeqWebhook, options);
            if (options.oneofs) {
              object.type = "intakeqWebhook";
            }
          }
          if (message.customerIoWebhook != null && message.hasOwnProperty("customerIoWebhook")) {
            object.customerIoWebhook = $root.sources.v2.CustomerIoWebhookSource.toObject(message.customerIoWebhook, options);
            if (options.oneofs) {
              object.type = "customerIoWebhook";
            }
          }
          if (message.hubspotWebhook != null && message.hasOwnProperty("hubspotWebhook")) {
            object.hubspotWebhook = $root.sources.v2.HubspotWebhookSource.toObject(message.hubspotWebhook, options);
            if (options.oneofs) {
              object.type = "hubspotWebhook";
            }
          }
          if (message.morfAnalyticsWebhook != null && message.hasOwnProperty("morfAnalyticsWebhook")) {
            object.morfAnalyticsWebhook = $root.sources.v2.MorfAnalyticsWebhookSource.toObject(message.morfAnalyticsWebhook, options);
            if (options.oneofs) {
              object.type = "morfAnalyticsWebhook";
            }
          }
          if (message.calendlyWebhook != null && message.hasOwnProperty("calendlyWebhook")) {
            object.calendlyWebhook = $root.sources.v2.CalendlyWebhookSource.toObject(message.calendlyWebhook, options);
            if (options.oneofs) {
              object.type = "calendlyWebhook";
            }
          }
          if (message.featheryWebhook != null && message.hasOwnProperty("featheryWebhook")) {
            object.featheryWebhook = $root.sources.v2.FeatheryWebhookSource.toObject(message.featheryWebhook, options);
            if (options.oneofs) {
              object.type = "featheryWebhook";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.SourceType";
        }
      }

      SourceType.prototype.formsortWebhook = null;
      SourceType.prototype.healthieWebhook = null;
      SourceType.prototype.axleHealthWebhook = null;
      SourceType.prototype.butterflyLabsWebhook = null;
      SourceType.prototype.acuityWebhook = null;
      SourceType.prototype.junctionWebhook = null;
      SourceType.prototype.segmentWebhook = null;
      SourceType.prototype.activeCampaignWebhook = null;
      SourceType.prototype.morfAppointmentWebhook = null;
      SourceType.prototype.pushJsonWebhook = null;
      SourceType.prototype.intakeqWebhook = null;
      SourceType.prototype.customerIoWebhook = null;
      SourceType.prototype.hubspotWebhook = null;
      SourceType.prototype.morfAnalyticsWebhook = null;
      SourceType.prototype.calendlyWebhook = null;
      SourceType.prototype.featheryWebhook = null;

      return SourceType;
    })();

    v2.FeatheryWebhookSource = (() => {
      class FeatheryWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FeatheryWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            feathery$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(18).string(message.url);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FeatheryWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = feathery$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.url = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.FeatheryWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.FeatheryWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.FeatheryWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = feathery$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
            object.url = "";
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = feathery$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.FeatheryWebhookSource";
        }
      }

      FeatheryWebhookSource.prototype.subscribedEventTypes = null;
      FeatheryWebhookSource.prototype.url = "";

      return FeatheryWebhookSource;
    })();

    v2.FormsortWebhookSource = (() => {
      class FormsortWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormsortWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            formsort$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          if (message.formResponsesUrl != null && Object.hasOwnProperty.call(message, "formResponsesUrl")) {
            writer.uint32(18).string(message.formResponsesUrl);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormsortWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = formsort$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.formResponsesUrl = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.FormsortWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.FormsortWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.FormsortWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = formsort$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          if (object.formResponsesUrl != null) {
            message.formResponsesUrl = String(object.formResponsesUrl);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
            object.formResponsesUrl = "";
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = formsort$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          if (message.formResponsesUrl != null && message.hasOwnProperty("formResponsesUrl")) {
            object.formResponsesUrl = message.formResponsesUrl;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.FormsortWebhookSource";
        }
      }

      FormsortWebhookSource.prototype.subscribedEventTypes = null;
      FormsortWebhookSource.prototype.formResponsesUrl = "";

      return FormsortWebhookSource;
    })();

    v2.HealthieWebhookSource = (() => {
      class HealthieWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new HealthieWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            healthie$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(18).string(message.url);
          }
          if (message.environment != null && Object.hasOwnProperty.call(message, "environment")) {
            writer.uint32(24).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new HealthieWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = healthie$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.url = reader.string();
                break;
              }
              case 3: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.HealthieWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.HealthieWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.HealthieWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = healthie$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case "UNDEFINED_ILLEGAL":
              case 0: {
                message.environment = 0;
                break;
              }
              case "SANDBOX":
              case 1: {
                message.environment = 1;
                break;
              }
              case "PRODUCTION":
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == "number") {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
            object.url = "";
            object.environment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = healthie$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          if (message.environment != null && message.hasOwnProperty("environment")) {
            object.environment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.environment] === undefined ? message.environment : healthie$1.v1.HealthieEnvironment[message.environment] : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.HealthieWebhookSource";
        }
      }

      HealthieWebhookSource.prototype.subscribedEventTypes = null;
      HealthieWebhookSource.prototype.url = "";
      HealthieWebhookSource.prototype.environment = 0;

      return HealthieWebhookSource;
    })();

    v2.AxleHealthWebhookSource = (() => {
      class AxleHealthWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new AxleHealthWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            axle_health$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(18).string(message.url);
          }
          if (message.environment != null && Object.hasOwnProperty.call(message, "environment")) {
            writer.uint32(24).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new AxleHealthWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = axle_health$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.url = reader.string();
                break;
              }
              case 3: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.AxleHealthWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.AxleHealthWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.AxleHealthWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = axle_health$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case "UNDEFINED_ILLEGAL":
              case 0: {
                message.environment = 0;
                break;
              }
              case "DEVELOPMENT":
              case 1: {
                message.environment = 1;
                break;
              }
              case "PRODUCTION":
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == "number") {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
            object.url = "";
            object.environment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = axle_health$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          if (message.environment != null && message.hasOwnProperty("environment")) {
            object.environment = options.enums === String ? axle_health$1.v1.AxleHealthEnvironment[message.environment] === undefined ? message.environment : axle_health$1.v1.AxleHealthEnvironment[message.environment] : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.AxleHealthWebhookSource";
        }
      }

      AxleHealthWebhookSource.prototype.subscribedEventTypes = null;
      AxleHealthWebhookSource.prototype.url = "";
      AxleHealthWebhookSource.prototype.environment = 0;

      return AxleHealthWebhookSource;
    })();

    v2.ButterflyLabsWebhookSource = (() => {
      class ButterflyLabsWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ButterflyLabsWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            butterfly_labs$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(18).string(message.url);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ButterflyLabsWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = butterfly_labs$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.url = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.ButterflyLabsWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.ButterflyLabsWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.ButterflyLabsWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = butterfly_labs$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
            object.url = "";
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = butterfly_labs$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.ButterflyLabsWebhookSource";
        }
      }

      ButterflyLabsWebhookSource.prototype.subscribedEventTypes = null;
      ButterflyLabsWebhookSource.prototype.url = "";

      return ButterflyLabsWebhookSource;
    })();

    v2.AcuityWebhookSource = (() => {
      class AcuityWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new AcuityWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            acuity$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(18).string(message.url);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new AcuityWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = acuity$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.url = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.AcuityWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.AcuityWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.AcuityWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = acuity$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
            object.url = "";
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = acuity$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.AcuityWebhookSource";
        }
      }

      AcuityWebhookSource.prototype.subscribedEventTypes = null;
      AcuityWebhookSource.prototype.url = "";

      return AcuityWebhookSource;
    })();

    v2.JunctionWebhookSource = (() => {
      class JunctionWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new JunctionWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            junction$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(18).string(message.url);
          }
          if (message.environment != null && Object.hasOwnProperty.call(message, "environment")) {
            writer.uint32(24).int32(message.environment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new JunctionWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = junction$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.url = reader.string();
                break;
              }
              case 3: {
                message.environment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.JunctionWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.JunctionWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.JunctionWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = junction$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          if (object.environment != null) {
            switch (object.environment) {
              case "UNDEFINED_ILLEGAL":
              case 0: {
                message.environment = 0;
                break;
              }
              case "STAGING":
              case 1: {
                message.environment = 1;
                break;
              }
              case "PRODUCTION":
              case 2: {
                message.environment = 2;
                break;
              }
              default: {
                if (typeof object.environment == "number") {
                  message.environment = object.environment;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
            object.url = "";
            object.environment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = junction$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          if (message.environment != null && message.hasOwnProperty("environment")) {
            object.environment = options.enums === String ? junction$1.v1.JunctionEnvironment[message.environment] === undefined ? message.environment : junction$1.v1.JunctionEnvironment[message.environment] : message.environment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.JunctionWebhookSource";
        }
      }

      JunctionWebhookSource.prototype.subscribedEventTypes = null;
      JunctionWebhookSource.prototype.url = "";
      JunctionWebhookSource.prototype.environment = 0;

      return JunctionWebhookSource;
    })();

    v2.SegmentWebhookSource = (() => {
      class SegmentWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SegmentWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            segment$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(18).string(message.url);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SegmentWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = segment$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.url = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.SegmentWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.SegmentWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.SegmentWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = segment$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
            object.url = "";
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = segment$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.SegmentWebhookSource";
        }
      }

      SegmentWebhookSource.prototype.subscribedEventTypes = null;
      SegmentWebhookSource.prototype.url = "";

      return SegmentWebhookSource;
    })();

    v2.ActiveCampaignWebhookSource = (() => {
      class ActiveCampaignWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ActiveCampaignWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            active_campaign$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(18).string(message.url);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ActiveCampaignWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = active_campaign$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.url = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.ActiveCampaignWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.ActiveCampaignWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.ActiveCampaignWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = active_campaign$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
            object.url = "";
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = active_campaign$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.ActiveCampaignWebhookSource";
        }
      }

      ActiveCampaignWebhookSource.prototype.subscribedEventTypes = null;
      ActiveCampaignWebhookSource.prototype.url = "";

      return ActiveCampaignWebhookSource;
    })();

    v2.MorfAnalyticsWebhookSource = (() => {
      class MorfAnalyticsWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MorfAnalyticsWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(10).string(message.url);
          }
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            morf_analytics$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MorfAnalyticsWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.url = reader.string();
                break;
              }
              case 2: {
                message.subscribedEventTypes = morf_analytics$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.MorfAnalyticsWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.MorfAnalyticsWebhookSource();
          if (object.url != null) {
            message.url = String(object.url);
          }
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.MorfAnalyticsWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = morf_analytics$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.url = "";
            object.subscribedEventTypes = null;
          }
          let keys;
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = morf_analytics$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.MorfAnalyticsWebhookSource";
        }
      }

      MorfAnalyticsWebhookSource.prototype.url = "";
      MorfAnalyticsWebhookSource.prototype.subscribedEventTypes = null;

      return MorfAnalyticsWebhookSource;
    })();

    v2.MorfAppointmentWebhookSource = (() => {
      class MorfAppointmentWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MorfAppointmentWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            morf_appointment$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          if (message.appointmentUpcomingUrl != null && Object.hasOwnProperty.call(message, "appointmentUpcomingUrl")) {
            writer.uint32(18).string(message.appointmentUpcomingUrl);
          }
          if (message.appointmentEndedUrl != null && Object.hasOwnProperty.call(message, "appointmentEndedUrl")) {
            writer.uint32(26).string(message.appointmentEndedUrl);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MorfAppointmentWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = morf_appointment$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.appointmentUpcomingUrl = reader.string();
                break;
              }
              case 3: {
                message.appointmentEndedUrl = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.MorfAppointmentWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.MorfAppointmentWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.MorfAppointmentWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = morf_appointment$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          if (object.appointmentUpcomingUrl != null) {
            message.appointmentUpcomingUrl = String(object.appointmentUpcomingUrl);
          }
          if (object.appointmentEndedUrl != null) {
            message.appointmentEndedUrl = String(object.appointmentEndedUrl);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
            object.appointmentUpcomingUrl = "";
            object.appointmentEndedUrl = "";
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = morf_appointment$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          if (message.appointmentUpcomingUrl != null && message.hasOwnProperty("appointmentUpcomingUrl")) {
            object.appointmentUpcomingUrl = message.appointmentUpcomingUrl;
          }
          if (message.appointmentEndedUrl != null && message.hasOwnProperty("appointmentEndedUrl")) {
            object.appointmentEndedUrl = message.appointmentEndedUrl;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.MorfAppointmentWebhookSource";
        }
      }

      MorfAppointmentWebhookSource.prototype.subscribedEventTypes = null;
      MorfAppointmentWebhookSource.prototype.appointmentUpcomingUrl = "";
      MorfAppointmentWebhookSource.prototype.appointmentEndedUrl = "";

      return MorfAppointmentWebhookSource;
    })();

    v2.PushJsonWebhookSource = (() => {
      class PushJsonWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PushJsonWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(10).string(message.url);
          }
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            push_json$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PushJsonWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.url = reader.string();
                break;
              }
              case 2: {
                message.subscribedEventTypes = push_json$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.PushJsonWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.PushJsonWebhookSource();
          if (object.url != null) {
            message.url = String(object.url);
          }
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.PushJsonWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = push_json$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.url = "";
            object.subscribedEventTypes = null;
          }
          let keys;
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = push_json$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.PushJsonWebhookSource";
        }
      }

      PushJsonWebhookSource.prototype.url = "";
      PushJsonWebhookSource.prototype.subscribedEventTypes = null;

      return PushJsonWebhookSource;
    })();

    v2.IntakeQWebhookSource = (() => {
      class IntakeQWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new IntakeQWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            intakeq$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(18).string(message.url);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new IntakeQWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = intakeq$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.url = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.IntakeQWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.IntakeQWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.IntakeQWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = intakeq$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
            object.url = "";
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = intakeq$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.IntakeQWebhookSource";
        }
      }

      IntakeQWebhookSource.prototype.subscribedEventTypes = null;
      IntakeQWebhookSource.prototype.url = "";

      return IntakeQWebhookSource;
    })();

    v2.CustomerIoWebhookSource = (() => {
      class CustomerIoWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CustomerIoWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            customerio$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(18).string(message.url);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CustomerIoWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = customerio$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.url = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.CustomerIoWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.CustomerIoWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.CustomerIoWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = customerio$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
            object.url = "";
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = customerio$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.CustomerIoWebhookSource";
        }
      }

      CustomerIoWebhookSource.prototype.subscribedEventTypes = null;
      CustomerIoWebhookSource.prototype.url = "";

      return CustomerIoWebhookSource;
    })();

    v2.HubspotWebhookSource = (() => {
      class HubspotWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new HubspotWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            hubspot$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(18).string(message.url);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new HubspotWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = hubspot$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.url = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.HubspotWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.HubspotWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.HubspotWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = hubspot$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
            object.url = "";
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = hubspot$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.HubspotWebhookSource";
        }
      }

      HubspotWebhookSource.prototype.subscribedEventTypes = null;
      HubspotWebhookSource.prototype.url = "";

      return HubspotWebhookSource;
    })();

    v2.CalendlyWebhookSource = (() => {
      class CalendlyWebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CalendlyWebhookSource(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.subscribedEventTypes != null && Object.hasOwnProperty.call(message, "subscribedEventTypes")) {
            calendly$1.v1.SubscribedEventTypes.encode(message.subscribedEventTypes, writer.uint32(10).fork()).ldelim();
          }
          if (message.url != null && Object.hasOwnProperty.call(message, "url")) {
            writer.uint32(18).string(message.url);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CalendlyWebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.subscribedEventTypes = calendly$1.v1.SubscribedEventTypes.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.url = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v2.CalendlyWebhookSource) {
            return object;
          }
          const message = new $root.sources.v2.CalendlyWebhookSource();
          if (object.subscribedEventTypes != null) {
            if (typeof object.subscribedEventTypes !== "object") {
              throw new TypeError(".sources.v2.CalendlyWebhookSource.subscribedEventTypes: object expected, but got " + (typeof object.subscribedEventTypes));
            }
            message.subscribedEventTypes = calendly$1.v1.SubscribedEventTypes.fromObject(object.subscribedEventTypes);
          }
          if (object.url != null) {
            message.url = String(object.url);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.subscribedEventTypes = null;
            object.url = "";
          }
          let keys;
          if (message.subscribedEventTypes != null && message.hasOwnProperty("subscribedEventTypes")) {
            object.subscribedEventTypes = calendly$1.v1.SubscribedEventTypes.toObject(message.subscribedEventTypes, options);
          }
          if (message.url != null && message.hasOwnProperty("url")) {
            object.url = message.url;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "sources.v2.CalendlyWebhookSource";
        }
      }

      CalendlyWebhookSource.prototype.subscribedEventTypes = null;
      CalendlyWebhookSource.prototype.url = "";

      return CalendlyWebhookSource;
    })();

    return v2;
  })();

  return sources;
})();

export const sources = $root.sources;
