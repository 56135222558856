import _ from 'lodash';
import { Button } from '../../Button';
import { ChangeEvent, FC, memo, useState } from 'react';
import { FilterDropdownProps } from './types';
import { FilterOption as FilterOptionType } from './FilterOption/types';
import { FilterOption } from './FilterOption';
import { Flexbox } from '../../Flexbox';
import { Icon } from '../../Icon';
import { Input } from '../../Input';
import { Text } from '../../Typography';
import { useTheme } from '@morf/theming';

const FilterDropdown: FC<FilterDropdownProps> = ({
  allOptions,
  name,
  onApplyClick,
  onResetClick,
  options,
}) => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState<string>('');
  const [updatedOptions, setUpdatedOptions] =
    useState<FilterOptionType[]>(options);

  const shouldFilterOptions = allOptions.length > 5;

  const filteredOptions = shouldFilterOptions
    ? _.filter(
        allOptions,
        ({ name, id }) =>
          _.includes(_.toLower(name), _.toLower(searchValue)) ||
          _.includes(_.toLower(id), _.toLower(searchValue))
      )
    : allOptions;

  const isAlreadyChecked = (option: FilterOptionType): boolean =>
    updatedOptions.some(({ id }) => id === option.id);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleOptionClick = (option: FilterOptionType) => {
    setUpdatedOptions((prevSelected) =>
      isAlreadyChecked(option)
        ? prevSelected.filter(({ id }) => id !== option.id)
        : [...prevSelected, option]
    );
  };

  const handleApplyClick = () => {
    onApplyClick(updatedOptions);
  };

  return (
    <Flexbox
      data-testid={_.toLower(name) + '-dropdown'}
      direction='column'
      height='auto'
      p={0.5}
      gap={0.5}
    >
      <Flexbox
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        height='auto'
        pr={0.25}
      >
        <Text tag='p3' color={theme.colors.text.muted}>
          {name}:
        </Text>
        <Icon
          name='trash'
          cursor='pointer'
          stroke={theme.colors.ui.dark}
          size={0.75}
          strokeWidth={2}
          onClick={onResetClick}
        />
      </Flexbox>

      {shouldFilterOptions && (
        <Input
          id='search-options'
          placeholder='Search'
          tag='p3'
          leftElement={
            <Icon
              name='search'
              stroke={theme.colors.ui.dark}
              size={0.75}
              strokeWidth={2}
            />
          }
          autoFocus
          onChange={handleSearchChange}
          height='1.5rem'
          p={0.25}
        />
      )}

      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        overflow='auto'
        height='auto'
        maxHeight='8.875rem'
        gap={0}
      >
        {filteredOptions.map((option, index) => (
          <FilterOption
            key={index}
            isChecked={isAlreadyChecked(option)}
            multiSelect
            onClick={handleOptionClick}
            option={option}
          />
        ))}
      </Flexbox>

      <Button
        width='100%'
        isDisabled={_.isEqual(options, updatedOptions)}
        onClick={handleApplyClick}
      >
        <Text tag='h6' color='inherit'>
          Apply
        </Text>
      </Button>
    </Flexbox>
  );
};

export const MemoizedFilterDropdown = memo(FilterDropdown);
