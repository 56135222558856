import { FC, memo } from 'react';
import { Flexbox } from '../../../../../../Flexbox';
import { PayloadField } from './PayloadField';
import { PayloadFieldsProps } from './types';

const PayloadFields: FC<PayloadFieldsProps> = ({ onClick, payloadFields }) => {
  return (
    <Flexbox
      data-testid='payload-fields'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      overflow='auto'
      gap={0}
    >
      {payloadFields.map((payloadField, index) => {
        return (
          <PayloadField
            key={index}
            payloadField={payloadField}
            onClick={onClick}
          />
        );
      })}
    </Flexbox>
  );
};

export const MemoizedPayloadFields = memo(PayloadFields);
