"use strict";

import * as $protobuf from "protobufjs/minimal";
import { google as google$1 } from "../../struct_ts_proto";
import { timing as timing$1 } from "../../timing/v1/timing_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.feathery = (() => {
  const feathery = {};
  feathery.v1 = (() => {
    const v1 = {};
    v1.SubscribedEventTypes = (() => {
      class SubscribedEventTypes {
        constructor(properties) {
          this.eventTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SubscribedEventTypes(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.eventTypes != null && Object.hasOwnProperty.call(message, "eventTypes")) {
            writer.uint32(10).fork();
            for (const element of message.eventTypes) {
              writer.int32(element)
            }
            writer.ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SubscribedEventTypes();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.eventTypes || !message.eventTypes.length) {
                  message.eventTypes = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2) message.eventTypes.push(reader.int32());
                } else {
                  message.eventTypes.push(reader.int32());
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.feathery.v1.SubscribedEventTypes) {
            return object;
          }
          const message = new $root.feathery.v1.SubscribedEventTypes();
          if (object.eventTypes) {
            if (!Array.isArray(object.eventTypes)) {
              throw new TypeError(".feathery.v1.SubscribedEventTypes.eventTypes: array type expected, but got " + (typeof object.eventTypes))
            }
            message.eventTypes = new Array(object.eventTypes.length);
            for (let i = 0; i < object.eventTypes.length; ++i) {
              switch (object.eventTypes[i]) {
                case "UNSPECIFIED_ILLEGAL_FEATHERY_WEBHOOK_EVENT_TYPE":
                case 0: {
                  message.eventTypes[i] = 0;
                  break;
                }
                case "FEATHERY_FORM_STEP_SUBMISSION":
                case 1: {
                  message.eventTypes[i] = 1;
                  break;
                }
                default: {
                  if (typeof object.eventTypes[i] == "number") {
                    message.eventTypes[i] = object.eventTypes[i];
                    break;
                  }
                  message.eventTypes[i] = 0;
                  break;
                }
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.eventTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.eventTypes && message.eventTypes.length) {
            object.eventTypes = new Array(message.eventTypes.length);
            for (let i = 0; i < message.eventTypes.length; ++i) {
              object.eventTypes[i] = options.enums === String ? $root.feathery.v1.FeatheryEventType[message.eventTypes[i]] === undefined ? message.eventTypes[i] : $root.feathery.v1.FeatheryEventType[message.eventTypes[i]] : message.eventTypes[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "feathery.v1.SubscribedEventTypes";
        }
      }

      SubscribedEventTypes.prototype.eventTypes = $util.emptyArray;

      return SubscribedEventTypes;
    })();

    v1.FeatheryFormResponse = (() => {
      class FeatheryFormResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FeatheryFormResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.answers != null && Object.hasOwnProperty.call(message, "answers")) {
            google$1.protobuf.Struct.encode(message.answers, writer.uint32(10).fork()).ldelim();
          }
          if (message.userId != null && Object.hasOwnProperty.call(message, "userId")) {
            writer.uint32(18).string(message.userId);
          }
          if (message.formName != null && Object.hasOwnProperty.call(message, "formName")) {
            writer.uint32(26).string(message.formName);
          }
          if (message.formId != null && Object.hasOwnProperty.call(message, "formId")) {
            writer.uint32(34).string(message.formId);
          }
          if (message.stepId != null && Object.hasOwnProperty.call(message, "stepId")) {
            writer.uint32(42).string(message.stepId);
          }
          if (message.receivedAt != null && Object.hasOwnProperty.call(message, "receivedAt")) {
            timing$1.v1.Timestamp.encode(message.receivedAt, writer.uint32(50).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FeatheryFormResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.answers = google$1.protobuf.Struct.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.userId = reader.string();
                break;
              }
              case 3: {
                message.formName = reader.string();
                break;
              }
              case 4: {
                message.formId = reader.string();
                break;
              }
              case 5: {
                message.stepId = reader.string();
                break;
              }
              case 6: {
                message.receivedAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.feathery.v1.FeatheryFormResponse) {
            return object;
          }
          const message = new $root.feathery.v1.FeatheryFormResponse();
          if (object.answers != null) {
            if (typeof object.answers !== "object") {
              throw new TypeError(".feathery.v1.FeatheryFormResponse.answers: object expected, but got " + (typeof object.answers));
            }
            message.answers = google$1.protobuf.Struct.fromObject(object.answers);
          }
          if (object.userId != null) {
            message.userId = String(object.userId);
          }
          if (object.formName != null) {
            message.formName = String(object.formName);
          }
          if (object.formId != null) {
            message.formId = String(object.formId);
          }
          if (object.stepId != null) {
            message.stepId = String(object.stepId);
          }
          if (object.receivedAt != null) {
            if (typeof object.receivedAt !== "object") {
              throw new TypeError(".feathery.v1.FeatheryFormResponse.receivedAt: object expected, but got " + (typeof object.receivedAt));
            }
            message.receivedAt = timing$1.v1.Timestamp.fromObject(object.receivedAt);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.answers = null;
            object.userId = "";
            object.formName = "";
            object.formId = "";
            object.stepId = "";
            object.receivedAt = null;
          }
          let keys;
          if (message.answers != null && message.hasOwnProperty("answers")) {
            object.answers = google$1.protobuf.Struct.toObject(message.answers, options);
          }
          if (message.userId != null && message.hasOwnProperty("userId")) {
            object.userId = message.userId;
          }
          if (message.formName != null && message.hasOwnProperty("formName")) {
            object.formName = message.formName;
          }
          if (message.formId != null && message.hasOwnProperty("formId")) {
            object.formId = message.formId;
          }
          if (message.stepId != null && message.hasOwnProperty("stepId")) {
            object.stepId = message.stepId;
          }
          if (message.receivedAt != null && message.hasOwnProperty("receivedAt")) {
            object.receivedAt = timing$1.v1.Timestamp.toObject(message.receivedAt, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "feathery.v1.FeatheryFormResponse";
        }
      }

      FeatheryFormResponse.prototype.answers = null;
      FeatheryFormResponse.prototype.userId = "";
      FeatheryFormResponse.prototype.formName = "";
      FeatheryFormResponse.prototype.formId = "";
      FeatheryFormResponse.prototype.stepId = "";
      FeatheryFormResponse.prototype.receivedAt = null;

      return FeatheryFormResponse;
    })();

    v1.Form = (() => {
      class Form {
        constructor(properties) {
          this.tags = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Form(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(18).string(message.name);
          }
          if (message.active != null && Object.hasOwnProperty.call(message, "active")) {
            writer.uint32(24).bool(message.active);
          }
          if (message.tags != null && Object.hasOwnProperty.call(message, "tags")) {
            for (const element of message.tags) {
              writer.uint32(34).string(element);
            }
          }
          if (message.internalId != null && Object.hasOwnProperty.call(message, "internalId")) {
            writer.uint32(42).string(message.internalId);
          }
          if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt")) {
            timing$1.v1.Timestamp.encode(message.createdAt, writer.uint32(50).fork()).ldelim();
          }
          if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt")) {
            timing$1.v1.Timestamp.encode(message.updatedAt, writer.uint32(58).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Form();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.active = reader.bool();
                break;
              }
              case 4: {
                if (!message.tags || !message.tags.length) {
                  message.tags = [];
                }
                message.tags.push(reader.string());
                break;
              }
              case 5: {
                message.internalId = reader.string();
                break;
              }
              case 6: {
                message.createdAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.updatedAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.feathery.v1.Form) {
            return object;
          }
          const message = new $root.feathery.v1.Form();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.active != null) {
            message.active = Boolean(object.active);
          }
          if (object.tags) {
            if (!Array.isArray(object.tags)) {
              throw new TypeError(".feathery.v1.Form.tags: array type expected, but got " + (typeof object.tags))
            }
            message.tags = new Array(object.tags.length);
            for (let i = 0; i < object.tags.length; ++i) {
              message.tags[i] = String(object.tags[i]);
            }
          }
          if (object.internalId != null) {
            message.internalId = String(object.internalId);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== "object") {
              throw new TypeError(".feathery.v1.Form.createdAt: object expected, but got " + (typeof object.createdAt));
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(object.createdAt);
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== "object") {
              throw new TypeError(".feathery.v1.Form.updatedAt: object expected, but got " + (typeof object.updatedAt));
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(object.updatedAt);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.tags = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
            object.name = "";
            object.active = false;
            object.createdAt = null;
            object.updatedAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.active != null && message.hasOwnProperty("active")) {
            object.active = message.active;
          }
          if (message.tags && message.tags.length) {
            object.tags = new Array(message.tags.length);
            for (let i = 0; i < message.tags.length; ++i) {
              object.tags[i] = message.tags[i];
            }
          }
          if (message.internalId != null && message.hasOwnProperty("internalId")) {
            object.internalId = message.internalId;
          }
          if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
            object.createdAt = timing$1.v1.Timestamp.toObject(message.createdAt, options);
          }
          if (message.updatedAt != null && message.hasOwnProperty("updatedAt")) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(message.updatedAt, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "feathery.v1.Form";
        }
      }

      Form.prototype.id = "";
      Form.prototype.name = "";
      Form.prototype.active = false;
      Form.prototype.tags = $util.emptyArray;
      Form.prototype.internalId = null;
      Form.prototype.createdAt = null;
      Form.prototype.updatedAt = null;

      return Form;
    })();

    v1.FormSchema = (() => {
      class FormSchema {
        constructor(properties) {
          this.steps = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormSchema(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(18).string(message.name);
          }
          if (message.steps != null && Object.hasOwnProperty.call(message, "steps")) {
            for (const element of message.steps) {
              $root.feathery.v1.FormSchemaStep.encode(element, writer.uint32(26).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormSchema();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                if (!message.steps || !message.steps.length) {
                  message.steps = [];
                }
                message.steps.push($root.feathery.v1.FormSchemaStep.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.feathery.v1.FormSchema) {
            return object;
          }
          const message = new $root.feathery.v1.FormSchema();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.steps) {
            if (!Array.isArray(object.steps)) {
              throw new TypeError(".feathery.v1.FormSchema.steps: array type expected, but got " + (typeof object.steps))
            }
            message.steps = new Array(object.steps.length);
            for (let i = 0; i < object.steps.length; ++i) {
              if (typeof object.steps[i] !== "object") {
                throw new TypeError(".feathery.v1.FormSchema.steps: object expected, but got " + (typeof object.steps[i]));
              }
              message.steps[i] = $root.feathery.v1.FormSchemaStep.fromObject(object.steps[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.steps = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
            object.name = "";
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.steps && message.steps.length) {
            object.steps = new Array(message.steps.length);
            for (let i = 0; i < message.steps.length; ++i) {
              object.steps[i] = $root.feathery.v1.FormSchemaStep.toObject(message.steps[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "feathery.v1.FormSchema";
        }
      }

      FormSchema.prototype.id = "";
      FormSchema.prototype.name = "";
      FormSchema.prototype.steps = $util.emptyArray;

      return FormSchema;
    })();

    v1.FormSchemaStep = (() => {
      class FormSchemaStep {
        constructor(properties) {
          this.fields = {};
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormSchemaStep(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          if (message.fields != null && Object.hasOwnProperty.call(message, "fields")) {
            for (const key of Object.keys(message.fields)) {
              writer.uint32(18).fork().uint32(10).string(key);
              $root.feathery.v1.FormField.encode(message.fields[key], writer.uint32(18).fork()).ldelim().ldelim();
            }
          }
          if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt")) {
            timing$1.v1.Timestamp.encode(message.createdAt, writer.uint32(26).fork()).ldelim();
          }
          if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt")) {
            timing$1.v1.Timestamp.encode(message.updatedAt, writer.uint32(34).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormSchemaStep();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                if (message.fields === $util.emptyObject) message.fields = {};
                let end2 = reader.uint32() + reader.pos;
                key = "";
                value = null;
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value = $root.feathery.v1.FormField.decode(reader, reader.uint32());
                      break;
                    }
                  }
                  message.fields[key] = value;
                }
                break;
              }
              case 3: {
                message.createdAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.updatedAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.feathery.v1.FormSchemaStep) {
            return object;
          }
          const message = new $root.feathery.v1.FormSchemaStep();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.fields) {
            if (typeof object.fields !== "object") {
              throw new TypeError(".feathery.v1.FormSchemaStep.fields: object expected, but got " + (typeof object.fields));
            }
            message.fields = {};
            for (let keys = Object.keys(object.fields), i = 0; i < keys.length; ++i) {
              if (typeof object.fields[keys[i]] !== "object") {
                throw new TypeError(".feathery.v1.FormSchemaStep.value: object expected, but got " + (typeof object.fields[keys[i]]));
              }
              message.fields[keys[i]] = $root.feathery.v1.FormField.fromObject(object.fields[keys[i]]);
            }
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== "object") {
              throw new TypeError(".feathery.v1.FormSchemaStep.createdAt: object expected, but got " + (typeof object.createdAt));
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(object.createdAt);
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== "object") {
              throw new TypeError(".feathery.v1.FormSchemaStep.updatedAt: object expected, but got " + (typeof object.updatedAt));
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(object.updatedAt);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
            object.fields = {};
          }
          if (options.defaults) {
            object.id = "";
            object.createdAt = null;
            object.updatedAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.fields && (keys = Object.keys(message.fields)).length) {
            object.fields = {};
            for (let i = 0; i < keys.length; ++i) {
              object.fields[keys[i]] = $root.feathery.v1.FormField.toObject(message.fields[keys[i]], options);
            }
          }
          if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
            object.createdAt = timing$1.v1.Timestamp.toObject(message.createdAt, options);
          }
          if (message.updatedAt != null && message.hasOwnProperty("updatedAt")) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(message.updatedAt, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "feathery.v1.FormSchemaStep";
        }
      }

      FormSchemaStep.prototype.id = "";
      FormSchemaStep.prototype.fields = $util.emptyObject;
      FormSchemaStep.prototype.createdAt = null;
      FormSchemaStep.prototype.updatedAt = null;

      return FormSchemaStep;
    })();

    v1.FormField = (() => {
      class FormField {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormField(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          if (message.stableId != null && Object.hasOwnProperty.call(message, "stableId")) {
            writer.uint32(18).string(message.stableId);
          }
          if (message.isRequired != null && Object.hasOwnProperty.call(message, "isRequired")) {
            writer.uint32(24).bool(message.isRequired);
          }
          if (message.isList != null && Object.hasOwnProperty.call(message, "isList")) {
            writer.uint32(32).bool(message.isList);
          }
          if (message.fieldType != null && Object.hasOwnProperty.call(message, "fieldType")) {
            writer.uint32(42).string(message.fieldType);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormField();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.stableId = reader.string();
                break;
              }
              case 3: {
                message.isRequired = reader.bool();
                break;
              }
              case 4: {
                message.isList = reader.bool();
                break;
              }
              case 5: {
                message.fieldType = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.feathery.v1.FormField) {
            return object;
          }
          const message = new $root.feathery.v1.FormField();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.stableId != null) {
            message.stableId = String(object.stableId);
          }
          if (object.isRequired != null) {
            message.isRequired = Boolean(object.isRequired);
          }
          if (object.isList != null) {
            message.isList = Boolean(object.isList);
          }
          if (object.fieldType != null) {
            message.fieldType = String(object.fieldType);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
            object.stableId = "";
            object.isRequired = false;
            object.isList = false;
            object.fieldType = "";
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.stableId != null && message.hasOwnProperty("stableId")) {
            object.stableId = message.stableId;
          }
          if (message.isRequired != null && message.hasOwnProperty("isRequired")) {
            object.isRequired = message.isRequired;
          }
          if (message.isList != null && message.hasOwnProperty("isList")) {
            object.isList = message.isList;
          }
          if (message.fieldType != null && message.hasOwnProperty("fieldType")) {
            object.fieldType = message.fieldType;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "feathery.v1.FormField";
        }
      }

      FormField.prototype.id = "";
      FormField.prototype.stableId = "";
      FormField.prototype.isRequired = false;
      FormField.prototype.isList = false;
      FormField.prototype.fieldType = "";

      return FormField;
    })();

    v1.FeatheryEventType = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED_ILLEGAL_FEATHERY_WEBHOOK_EVENT_TYPE"] = 0;
      values[valuesById[1] = "FEATHERY_FORM_STEP_SUBMISSION"] = 1;
      return values;
    })();

    return v1;
  })();

  return feathery;
})();

export const feathery = $root.feathery;
