import { Button } from '../Button';
import { DeviceType, ResponsiveIframeViewerProps } from './types';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Tooltip } from '../Tooltip';
import { useState, memo, FC } from 'react';
import { useTheme } from '@morf/theming';

const ResponsiveIframeViewer: FC<ResponsiveIframeViewerProps> = (props) => {
  const theme = useTheme();
  const [width, setWidth] = useState<string>('768px');
  const [selectedDevice, setSelectedDevice] = useState<DeviceType>('tablet');

  const devices: { name: DeviceType; icon: string; width: string }[] = [
    { name: 'mobile', icon: 'devices-mobile', width: '320px' },
    { name: 'tablet', icon: 'devices-tablet', width: '768px' },
    { name: 'desktop', icon: 'devices-desktop', width: '1280px' },
  ];

  const handleUpdateIframeSize = (selectedDevice: DeviceType) => {
    setSelectedDevice(selectedDevice);
    const device = devices.find((device) => device.name === selectedDevice);
    if (device) {
      setWidth(device.width);
    }
  };

  return (
    <Flexbox
      data-testid='responsive-iframe-viewer'
      direction='column'
      justifyContent='center'
      alignItems='center'
      width='70rem'
      backgroundColor={theme.colors.ui.body}
      gap={0.5}
    >
      <Flexbox
        direction='row'
        justifyContent='center'
        alignItems='center'
        height='auto'
        gap={0.5}
        py={1}
      >
        {devices.map(({ name, icon }) => {
          const isSelected = selectedDevice === name;

          return (
            <Button
              key={name}
              variant='custom'
              backgroundColor={
                isSelected
                  ? theme.colors.main.light.light
                  : theme.colors.ui.card
              }
              borderColor={
                isSelected
                  ? theme.colors.main.primary.darker
                  : theme.colors.ui.card
              }
              hoverBorderColor={
                isSelected
                  ? theme.colors.main.primary.darker
                  : theme.colors.main.light.light
              }
              hoverBackgroundColor={theme.colors.main.light.light}
              onClick={() => handleUpdateIframeSize(name)}
              size='sm'
            >
              <Tooltip
                tooltipDirection='bottom'
                arrowDirection='center'
                tooltipText={`Responsive ${name}`}
              >
                <Icon
                  name={icon}
                  stroke={
                    isSelected
                      ? theme.colors.main.primary.darker
                      : theme.colors.text.body
                  }
                />
              </Tooltip>
            </Button>
          );
        })}
      </Flexbox>

      <Flexbox
        data-testid='iframe-container'
        justifyContent='center'
        alignItems='center'
        width='100%'
        height='100%'
        px={1}
        overflow='auto'
      >
        <iframe
          {...props}
          width={width}
          height='100%'
          style={{ border: 'none' }}
        />
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedResponsiveIframeViewer = memo(ResponsiveIframeViewer);
