export const NextDoor = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23.7044 9.00464C19.5864 9.00464 16.0785 10.9039 14.1096 13.8108C13.9456 14.0523 13.6683 14.5701 13.2939 14.5724C11.4914 14.5863 11.3805 12.355 11.3227 10.3652C11.3135 10.0727 11.0639 9.83121 10.7727 9.83121L5.55475 9.80103C5.2474 9.80103 4.99321 10.0564 5.00014 10.3652C5.11568 15.2271 6.00537 18.5288 11.8681 19.8406C12.1893 19.9126 12.4134 20.2051 12.4134 20.5348C12.4134 22.5548 12.4134 29.446 12.4134 31.4497C12.4134 31.7562 12.6607 32 12.9657 32H18.0335C18.3385 32 18.5858 31.7516 18.5858 31.4451V20.1726C18.5858 17.6674 20.3536 14.8255 23.7067 14.8255C27.0598 14.8255 28.8276 17.6674 28.8276 20.1726V31.4451C28.8276 31.7516 29.0749 32 29.3799 32H34.4477C34.7527 32 35 31.7516 35 31.4451V19.3368C35 13.5461 30.0824 9 23.709 9L23.7044 9.00464Z'
        fill='#8ED500'
      />
    </svg>
  );
};
