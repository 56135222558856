import { FC, memo } from 'react';
import { Flexbox } from '../../../../../../Flexbox';
import { PropertiesProps } from './types';
import { Property } from './Property';

const Properties: FC<PropertiesProps> = ({ onClick, properties }) => {
  return (
    <Flexbox
      data-testid='properties'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      overflow='auto'
      gap={0}
    >
      {properties.map((property, index) => {
        return <Property key={index} property={property} onClick={onClick} />;
      })}
    </Flexbox>
  );
};

export const MemoizedProperties = memo(Properties);
