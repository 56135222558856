import { ActionItem as ActionItemList } from './ActionList/types';
import { ActionItem } from '../../../../ActionItem';
import { ActionList } from './ActionList';
import { AddEdgeModalProps } from './types';
import { CategoryLabel } from '../../../../CategoryLabel';
import { Container } from '../../../../Container';
import { FC, memo, useState } from 'react';
import { Flexbox } from '../../../../Flexbox';
import { Icon } from '../../../../Icon';
import { Input } from '../../../../Input';
import { NodeType } from '../../../types';
import { SearchMessage } from '../../../../SearchMessage';
import { Text } from '../../../../Typography';
import { WrapperModal } from '../../../../WrapperModal';
import { filterActionList } from './filterActionList';
import { generateNewNodeData } from './generateNewNodeData';
import { iconNameNodeTypeMap } from '../../../CustomNode/constants';
import { useTheme } from '@morf/theming';
import { useWorkflow } from '../../../../../../apps/admin/components/context/workflow/useWorkflow';

const AddEdgeModal: FC<AddEdgeModalProps> = ({
  destinationActions,
  fetchActions,
  isOpen,
  left,
  onClick,
  onClose,
  top,
}) => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState<string>('');
  const { nodes } = useWorkflow();
  const filteredDestinationActions = filterActionList(
    destinationActions,
    searchValue
  );
  const filteredFetchActions = filterActionList(fetchActions, searchValue);

  const [isBranch, setIsBranch] = useState(false);
  const [isDestinationAction, setIsDestinationAction] = useState(false);
  const [isFetchAction, setIsFetchAction] = useState(false);

  const isBranchOnly = isBranch && !isDestinationAction && !isFetchAction;

  const categories = [
    {
      label: 'Branch actions',
      state: isBranchOnly,
      setState: setIsBranch,
    },
    {
      label: 'Destination actions',
      state: isDestinationAction,
      setState: setIsDestinationAction,
    },
    {
      label: 'Fetch actions',
      state: isFetchAction,
      setState: setIsFetchAction,
    },
  ];

  const noSelectedCategory = !categories.some((category) => category.state);

  const highlightWords: string[] = [searchValue];

  const handleClose = () => {
    setIsBranch(false);
    setIsDestinationAction(false);
    setIsFetchAction(false);
    onClose();
  };

  const handleItemClick = (nodeType: NodeType, action?: ActionItemList) => {
    const data = generateNewNodeData(nodeType, isBranch, nodes.length, action);
    if (data) {
      onClick(data);
      handleClose();
    }
  };

  return (
    <WrapperModal
      borderRadius={1}
      isOpen={isOpen}
      left={left}
      onClose={handleClose}
      top={top}
    >
      <Flexbox
        data-testid='add-edge-modal'
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='21.25rem'
        width='21.25rem'
        gap={0}
      >
        <Container height='auto' borderType='borderBottom'>
          <Input
            id='search'
            borderType='none'
            placeholder='Search actions'
            leftElement={
              <Icon
                name='search'
                stroke={theme.colors.ui.dark}
                size={1.25}
                strokeWidth={1.75}
              />
            }
            onChange={(e) => {
              setSearchValue(e.target.value);
              setIsDestinationAction(false);
              setIsFetchAction(false);
            }}
            autoFocus
          />
        </Container>

        {!!searchValue ? (
          <Container height='auto' width='auto' p={0.75} pb={0.5}>
            <SearchMessage
              nrOfRows={
                filteredDestinationActions.length + filteredFetchActions.length
              }
              searchValue={searchValue}
            />
          </Container>
        ) : (
          <Flexbox
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            height='auto'
            px={0.75}
            pt={0.75}
            pb={0.5}
          >
            {categories.map((category, index) =>
              category.state ? (
                <CategoryLabel
                  key={index}
                  label={category.label}
                  onClick={category.setState}
                />
              ) : null
            )}
            {noSelectedCategory && (
              <Text tag='p2' color={theme.colors.text.muted}>
                Morf actions
              </Text>
            )}
          </Flexbox>
        )}

        {!!searchValue ? (
          <ActionList
            key={searchValue}
            highlightWords={highlightWords}
            list={[...filteredDestinationActions, ...filteredFetchActions]}
            onClick={(action) =>
              handleItemClick(
                filteredDestinationActions.includes(action)
                  ? NodeType.DestinationActionNode
                  : NodeType.FetchActionNode,
                action
              )
            }
          />
        ) : (
          <>
            {isDestinationAction && (
              <ActionList
                highlightWords={highlightWords}
                list={destinationActions}
                onClick={(action) =>
                  handleItemClick(NodeType.DestinationActionNode, action)
                }
              />
            )}
            {isFetchAction && (
              <ActionList
                highlightWords={highlightWords}
                list={fetchActions}
                onClick={(action) =>
                  handleItemClick(NodeType.FetchActionNode, action)
                }
              />
            )}
            {(noSelectedCategory || isBranchOnly) && (
              <Flexbox
                direction='column'
                justifyContent='flex-start'
                alignItems='flex-start'
                gap={0}
              >
                <ActionItem
                  iconName={iconNameNodeTypeMap.filterNode}
                  label='Filter'
                  onClick={() => handleItemClick(NodeType.FilterNode)}
                />
                <ActionItem
                  iconName={iconNameNodeTypeMap.pauseNode}
                  label='Wait'
                  onClick={() => handleItemClick(NodeType.PauseNode)}
                />
                <ActionItem
                  iconName={iconNameNodeTypeMap.restartNode}
                  label='Restart Workflow'
                  onClick={() => handleItemClick(NodeType.RestartNode)}
                />
                {!isBranch && (
                  <ActionItem
                    iconName='conditional'
                    isExpandable
                    label='Branch'
                    onClick={() => setIsBranch(true)}
                  />
                )}
                <ActionItem
                  iconName={iconNameNodeTypeMap.destinationActionNode}
                  isExpandable
                  label='Destination action'
                  onClick={() => setIsDestinationAction(true)}
                />
                <ActionItem
                  iconName={iconNameNodeTypeMap.fetchActionNode}
                  isExpandable
                  label='Fetch action'
                  onClick={() => setIsFetchAction(true)}
                />
              </Flexbox>
            )}
          </>
        )}
      </Flexbox>
    </WrapperModal>
  );
};

export const MemoizedAddEdgeModal = memo(AddEdgeModal);
