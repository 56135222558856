import { CustomNode } from '../../types';
import { Edge } from 'reactflow';
import { ProfileLookupNodeType } from '../types';
import { profiles } from '@morf/proto/profiles/v1/profiles_ts_proto';
import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';

export const profileLookupSubflowMultipleProfilesFound = (
  index: number,
  lookup: profiles.v1.Lookup,
  lookupType: profiles.v1.LookupType | undefined
): {
  nodes: CustomNode[];
  edges: Edge[];
} => {
  let nodes: CustomNode[] = [];
  let edges: Edge[] = [];

  if (
    lookupType === profiles.v1.LookupType.LOOKUP_TYPE_LEAD_AND_PATIENT &&
    !!lookup.index?.email
  ) {
    nodes.push({
      id: `lookup-subflow-multiple-profiles-found`,
      type: ProfileLookupNodeType.BranchNode,
      data: {
        value: {
          name: 'multiple profiles found',
          title: 'Multiple profiles found',
          configuration: new workflows.v1.WorkflowNode({}),
        },
      },
      parentNode: `lookup-subflow-field-lookup-${index}`,
      position: { x: -500, y: 100 },
    });

    edges.push({
      id: `lookup-subflow-field-lookup-${index}-lookup-subflow-multiple-profiles-found`,
      source: `lookup-subflow-field-lookup-${index}`,
      target: `lookup-subflow-multiple-profiles-found`,
    });

    switch (lookup.index.email.multipleProfilesAction) {
      case profiles.v1.MultipleProfilesAction
        .MULTIPLE_PROFILES_ACTION_CHOOSE_LEAD:
        nodes.push({
          id: `lookup-subflow-multiple-profiles-found-action`,
          type: ProfileLookupNodeType.ActionNode,
          data: {
            value: {
              name: 'choose lead',
              title: 'Choose Lead',
              configuration: new workflows.v1.WorkflowNode({}),
            },
          },
          parentNode: `lookup-subflow-multiple-profiles-found`,
          position: { x: 0, y: 100 },
        });

        edges.push({
          id: `lookup-subflow-multiple-profiles-found-lookup-subflow-multiple-profiles-found-action`,
          source: `lookup-subflow-multiple-profiles-found`,
          target: `lookup-subflow-multiple-profiles-found-action`,
        });
        break;

      case profiles.v1.MultipleProfilesAction
        .MULTIPLE_PROFILES_ACTION_CHOOSE_PATIENT:
        nodes.push({
          id: `lookup-subflow-multiple-profiles-found-action`,
          type: ProfileLookupNodeType.ActionNode,
          data: {
            value: {
              name: 'choose patient',
              title: 'Choose Patient',
              configuration: new workflows.v1.WorkflowNode({}),
            },
          },
          parentNode: `lookup-subflow-multiple-profiles-found`,
          position: { x: 0, y: 100 },
        });

        edges.push({
          id: `lookup-subflow-multiple-profiles-found-lookup-subflow-multiple-profiles-found-action`,
          source: `lookup-subflow-multiple-profiles-found`,
          target: `lookup-subflow-multiple-profiles-found-action`,
        });
        break;
    }

    edges.push({
      id: `lookup-subflow-multiple-profiles-found-action-lookup-subflow-return-to-workflow`,
      source: `lookup-subflow-multiple-profiles-found-action`,
      target: `lookup-subflow-return-to-workflow`,
    });
  }
  return { nodes, edges };
};
