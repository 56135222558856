import { DefaultTheme } from '../Theme';
import { ThemeContainerProps } from '../types';
import { defaultColors } from '../ColorPalette';
import {
  defaultFontTypes,
  defaultH1,
  defaultH2,
  defaultH3,
  defaultH4,
  defaultH5,
  defaultH6,
  defaultH7,
  defaultLink,
  defaultLongform,
  defaultMaxNrOfSlots,
  defaultP1,
  defaultP2,
  defaultP3,
  defaultP4,
  defaultSubtitle,
} from '../ColorPalette/constants';

export const np2go: ThemeContainerProps = {
  ...DefaultTheme,
  colors: {
    ...defaultColors,
    text: {
      body: '#000000',
      ...defaultColors.text,
    },
    main: {
      primary: {
        darkest: '#404040',
        darker: '#C6905E',
        dark: '',
        normal: '',
        light: '',
        lighter: '',
        lightest: '',
      },
      dark: {
        darkest: '#E1B668',
        darker: '',
        dark: '',
        normal: '',
        light: '',
        lighter: '',
        lightest: '',
      },
      light: {
        darkest: '',
        darker: '',
        dark: '',
        normal: '#FFE090',
        light: '#404040',
        lighter: '',
        lightest: '',
      },
    },
  },
  container: {
    borderType: 'border',
    borderColor: '#818181',
    borderWidth: 0.125,
    borderRadius: 0.5,
  },
  contentOrder: {
    bio: 1,
    specialties: 2,
  },
  datePicker: {
    borderRadius: 0.5,
    style: 'nofill',
  },
  fontTypes: {
    ...defaultFontTypes,
    h1: {
      ...defaultH1,
      family: 'philosopher-bold',
    },
    h2: {
      ...defaultH2,
      family: 'philosopher-bold',
    },
    h3: {
      ...defaultH3,
      family: 'philosopher-bold',
    },
    h4: {
      ...defaultH4,
      family: 'philosopher-bold',
    },
    h5: {
      ...defaultH5,
      family: 'philosopher-bold',
    },
    h6: {
      ...defaultH6,
      family: 'philosopher-bold',
    },
    h7: {
      ...defaultH7,
      family: 'philosopher-bold',
    },
    p1: {
      ...defaultP1,
      family: 'gantari-light',
    },
    p2: {
      ...defaultP2,
      family: 'gantari-light',
    },
    p3: {
      ...defaultP3,
      family: 'gantari-light',
    },
    p4: {
      ...defaultP4,
      family: 'gantari-light',
    },
    subtitle: {
      ...defaultSubtitle,
      family: 'gantari-light',
    },
    longform: {
      ...defaultLongform,
      family: 'gantari-light',
    },
    link: {
      ...defaultLink,
      family: 'gantari-light',
      decoration: 'underline',
    },
  },
  input: { borderRadius: 0.5 },
  maxNrOfSlots: defaultMaxNrOfSlots,
  timePicker: {
    borderRadius: 0.5,
    style: 'darkFill',
  },
  tooltip: {
    borderRadius: 0.5,
    type: 'light',
  },
};
