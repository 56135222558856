import { EmptyTableBody } from '../Table/TableBody/EmptyTableBody';
import { ExtendedRowData, VirtualTableProps } from './types';
import { StyledVirtualTable } from './VirtualTable.css';
import { VirtualTableBody } from './VirtualTableBody';
import { VirtualTableHead } from './VirtualTableHead';
import { memo, useEffect, useMemo, useState } from 'react';
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';

const VirtualTable: <RowData>(
  props: VirtualTableProps<ExtendedRowData<RowData>>
) => JSX.Element = ({
  cellBackgroundColor,
  cellHeight,
  cellHoveredBackgroundColor,
  cellHoveredBorderColor,
  cellSelectedBackgroundColor,
  cellSelectedBorderColor,
  columns,
  containerRef,
  data,
  emptyText,
  fetchNextPage,
  hasNextPage,
  isFetching,
  isLoading,
  onRowClick,
  selectedRow,
}) => {
  let initialRowSelection: { [key: number]: boolean } = {};
  if (selectedRow !== undefined && selectedRow !== null) {
    initialRowSelection[selectedRow] = true;
  }

  const memoizedDataWithPlaceholder = useMemo(
    () => (isLoading ? Array(20).fill({}) : data),
    [isLoading, data]
  );

  const [rowSelection, setRowSelection] = useState(initialRowSelection);
  const { getHeaderGroups, getRowModel, resetRowSelection } = useReactTable({
    data: memoizedDataWithPlaceholder,
    columns,
    state: {
      rowSelection,
    },
    enableRowSelection: !!onRowClick,
    enableMultiRowSelection: false,
    enableExpanding: false,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    defaultColumn: {
      size: 200,
      minSize: 80,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
  });

  useEffect(() => {
    if (selectedRow === null) {
      resetRowSelection();
    }
  }, [selectedRow]);

  const headerGroups = getHeaderGroups();
  const rows = getRowModel().rows;

  return (
    <StyledVirtualTable
      data-testid='virtual-table'
      height={!rows.length ? '100%' : 'auto'}
    >
      <VirtualTableHead headerGroups={headerGroups} />
      {!rows.length ? (
        <EmptyTableBody numberOfColumns={columns.length} text={emptyText} />
      ) : (
        <VirtualTableBody
          cellBackgroundColor={cellBackgroundColor}
          cellHeight={cellHeight}
          cellHoveredBackgroundColor={cellHoveredBackgroundColor}
          cellHoveredBorderColor={cellHoveredBorderColor}
          cellSelectedBackgroundColor={cellSelectedBackgroundColor}
          cellSelectedBorderColor={cellSelectedBorderColor}
          containerRef={containerRef}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetching={isFetching}
          isLoading={isLoading}
          numberOfColumns={columns.length}
          onRowClick={onRowClick}
          rows={rows}
        />
      )}
    </StyledVirtualTable>
  );
};

export const MemoizedVirtualTable = memo(VirtualTable) as typeof VirtualTable;
