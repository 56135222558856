import { EditorView } from '@tiptap/pm/view';
import { PluginKey } from '@tiptap/pm/state';
import { SlashCommandItem } from './SlashCommandTypes';
import { SlashCommandPluginState, filterItems } from './SlashCommandState';
import {
  serializeParameterSource,
  getLabelFromParameterSource,
} from '../CelExpression/CelExpressionUtils';

/**
 * Creates a handler for selecting an item
 */
export function createSelectHandler(
  view: EditorView,
  state: SlashCommandPluginState
) {
  return (item: SlashCommandItem) => {
    if (state.slashPos === null) return;

    const tr = view.state.tr;
    const { from } = view.state.selection;

    tr.delete(state.slashPos, from);

    const nodeType = view.state.schema.nodes.celExpression;
    if (nodeType) {
      const parameterSourceBase64 = serializeParameterSource(
        item.parameterSource
      );

      const label =
        item.label || getLabelFromParameterSource(item.parameterSource);

      tr.replaceWith(
        state.slashPos,
        state.slashPos,
        nodeType.create({
          parameterSource: parameterSourceBase64,
          label: label,
        })
      );
    }

    view.dispatch(tr);
  };
}

/**
 * Creates a handler for mouse over events on menu items
 */
export function createMouseOverHandler(view: EditorView, pluginKey: PluginKey) {
  return (index: number) => {
    view.dispatch(
      view.state.tr.setMeta(pluginKey, {
        type: 'SELECT_ITEM',
        index,
      })
    );
  };
}

/**
 * Handles keyboard events for the slash command menu
 */
export function handleKeyDown(
  view: EditorView,
  event: KeyboardEvent,
  pluginState: SlashCommandPluginState,
  pluginKey: PluginKey
): boolean {
  if (!pluginState.isActive) {
    if (event.key === '/') {
      const { from } = view.state.selection;
      event.preventDefault();

      view.dispatch(
        view.state.tr.setMeta(pluginKey, {
          type: 'ACTIVATE',
          position: from,
        })
      );

      return false;
    }
    return false;
  }

  const filteredItems = filterItems(pluginState.items, pluginState.query);

  if (filteredItems.length === 0) {
    if (event.key === 'Escape') {
      view.dispatch(
        view.state.tr.setMeta(pluginKey, {
          type: 'CLOSE',
        })
      );
      return true;
    }
    return false;
  }

  switch (event.key) {
    case 'Enter': {
      if (pluginState.slashPos !== null) {
        const safeIndex = Math.min(
          pluginState.selectedIndex,
          filteredItems.length - 1
        );
        const selectedItem = filteredItems[safeIndex];

        setTimeout(() => {
          const selectHandler = createSelectHandler(view, pluginState);
          selectHandler(selectedItem);
        }, 0);

        view.dispatch(
          view.state.tr.setMeta(pluginKey, {
            type: 'CLOSE',
          })
        );

        return true;
      }
      return false;
    }

    case 'Escape':
      view.dispatch(
        view.state.tr.setMeta(pluginKey, {
          type: 'CLOSE',
        })
      );
      return true;

    default:
      return false;
  }
}
