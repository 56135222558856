export const Mixpanel = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.478 17.7272H17.9101C17.0507 17.1837 16.7298 16.4261 16.3001 15.0207L15.011 10.2006C14.4236 8.03209 13.9395 7.00549 11.5789 7.00549H6.64558V8.30659H7.34723C8.79404 8.30659 8.95722 8.85008 9.38691 10.4751L10.5128 14.7023C11.1002 16.7555 12.0195 17.7272 14.478 17.7272ZM22.4246 17.7272H25.8567C28.3261 17.7272 29.18 16.75 29.7729 14.6968L30.8988 10.4696C31.3285 8.84459 31.5406 8.3011 32.9385 8.3011H33.6401V7H28.7558C26.3408 7 25.8567 7.9772 25.3182 10.1951L24.0291 15.0152C23.6049 16.4755 23.2785 17.1837 22.4246 17.7272ZM17.9155 22.2728H22.4246V17.7217H17.9155V22.2728ZM6.64558 33H11.5789C13.9395 33 14.4236 31.9679 15.011 29.8049L16.3001 24.9848C16.7298 23.5794 17.0507 22.8163 17.9101 22.2783H14.478C12.0086 22.2783 11.0948 23.2555 10.5074 25.3087L9.38147 29.5359C8.95178 31.1609 8.79404 31.7044 7.34179 31.7044H6.64014L6.64558 33ZM28.7503 33H33.6347V31.6989H32.933C31.5406 31.6989 31.3231 31.1554 30.8934 29.5304L29.7675 25.3032C29.18 23.2445 28.3206 22.2728 25.8513 22.2728H22.4246C23.284 22.8163 23.594 23.519 24.0237 24.9793L25.3128 29.7994C25.8513 32.0228 26.3353 33 28.7503 33Z'
        fill='#7856FF'
      />
    </svg>
  );
};
