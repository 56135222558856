"use strict";

import Long from "long";
import * as $protobuf from "protobufjs/minimal";
import { pagination as pagination$1 } from "../../pagination/v1/pagination_ts_proto";
import { timing as timing$1 } from "../../timing/v1/timing_ts_proto";
import { values as values$1 } from "../../values/v1/values_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.butterfly_labs = (() => {
  const butterfly_labs = {};
  butterfly_labs.v1 = (() => {
    const v1 = {};
    v1.SubscribedEventTypes = (() => {
      class SubscribedEventTypes {
        constructor(properties) {
          this.eventTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SubscribedEventTypes(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.eventTypes != null && Object.hasOwnProperty.call(message, "eventTypes")) {
            writer.uint32(10).fork();
            for (const element of message.eventTypes) {
              writer.int32(element)
            }
            writer.ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SubscribedEventTypes();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.eventTypes || !message.eventTypes.length) {
                  message.eventTypes = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2) message.eventTypes.push(reader.int32());
                } else {
                  message.eventTypes.push(reader.int32());
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.butterfly_labs.v1.SubscribedEventTypes) {
            return object;
          }
          const message = new $root.butterfly_labs.v1.SubscribedEventTypes();
          if (object.eventTypes) {
            if (!Array.isArray(object.eventTypes)) {
              throw new TypeError(".butterfly_labs.v1.SubscribedEventTypes.eventTypes: array type expected, but got " + (typeof object.eventTypes))
            }
            message.eventTypes = new Array(object.eventTypes.length);
            for (let i = 0; i < object.eventTypes.length; ++i) {
              switch (object.eventTypes[i]) {
                case "UNSPECIFIED_ILLEGAL_BUTTERFLY_LABS_EVENT_TYPE":
                case 0: {
                  message.eventTypes[i] = 0;
                  break;
                }
                case "BUTTERFLY_LABS_LAB_ORDER_STATUS_UPDATE":
                case 1: {
                  message.eventTypes[i] = 1;
                  break;
                }
                case "BUTTERFLY_LABS_LAB_ORDER_RESULTS_UPDATE":
                case 2: {
                  message.eventTypes[i] = 2;
                  break;
                }
                default: {
                  if (typeof object.eventTypes[i] == "number") {
                    message.eventTypes[i] = object.eventTypes[i];
                    break;
                  }
                  message.eventTypes[i] = 0;
                  break;
                }
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.eventTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.eventTypes && message.eventTypes.length) {
            object.eventTypes = new Array(message.eventTypes.length);
            for (let i = 0; i < message.eventTypes.length; ++i) {
              object.eventTypes[i] = options.enums === String ? $root.butterfly_labs.v1.ButterflyLabsEventType[message.eventTypes[i]] === undefined ? message.eventTypes[i] : $root.butterfly_labs.v1.ButterflyLabsEventType[message.eventTypes[i]] : message.eventTypes[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "butterfly_labs.v1.SubscribedEventTypes";
        }
      }

      SubscribedEventTypes.prototype.eventTypes = $util.emptyArray;

      return SubscribedEventTypes;
    })();

    v1.Order = (() => {
      class Order {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Order(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          if (message.patientId != null && Object.hasOwnProperty.call(message, "patientId")) {
            writer.uint32(18).string(message.patientId);
          }
          if (message.patientEmailAddress != null && Object.hasOwnProperty.call(message, "patientEmailAddress")) {
            writer.uint32(26).string(message.patientEmailAddress);
          }
          if (message.status != null && Object.hasOwnProperty.call(message, "status")) {
            writer.uint32(34).string(message.status);
          }
          if (message.definitionCode != null && Object.hasOwnProperty.call(message, "definitionCode")) {
            writer.uint32(42).string(message.definitionCode);
          }
          if (message.definitionEncounterType != null && Object.hasOwnProperty.call(message, "definitionEncounterType")) {
            writer.uint32(50).string(message.definitionEncounterType);
          }
          if (message.definitionName != null && Object.hasOwnProperty.call(message, "definitionName")) {
            writer.uint32(58).string(message.definitionName);
          }
          if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt")) {
            timing$1.v1.Timestamp.encode(message.createdAt, writer.uint32(66).fork()).ldelim();
          }
          if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt")) {
            timing$1.v1.Timestamp.encode(message.updatedAt, writer.uint32(74).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Order();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.patientId = reader.string();
                break;
              }
              case 3: {
                message.patientEmailAddress = reader.string();
                break;
              }
              case 4: {
                message.status = reader.string();
                break;
              }
              case 5: {
                message.definitionCode = reader.string();
                break;
              }
              case 6: {
                message.definitionEncounterType = reader.string();
                break;
              }
              case 7: {
                message.definitionName = reader.string();
                break;
              }
              case 8: {
                message.createdAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 9: {
                message.updatedAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.butterfly_labs.v1.Order) {
            return object;
          }
          const message = new $root.butterfly_labs.v1.Order();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.patientId != null) {
            message.patientId = String(object.patientId);
          }
          if (object.patientEmailAddress != null) {
            message.patientEmailAddress = String(object.patientEmailAddress);
          }
          if (object.status != null) {
            message.status = String(object.status);
          }
          if (object.definitionCode != null) {
            message.definitionCode = String(object.definitionCode);
          }
          if (object.definitionEncounterType != null) {
            message.definitionEncounterType = String(object.definitionEncounterType);
          }
          if (object.definitionName != null) {
            message.definitionName = String(object.definitionName);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== "object") {
              throw new TypeError(".butterfly_labs.v1.Order.createdAt: object expected, but got " + (typeof object.createdAt));
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(object.createdAt);
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== "object") {
              throw new TypeError(".butterfly_labs.v1.Order.updatedAt: object expected, but got " + (typeof object.updatedAt));
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(object.updatedAt);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
            object.patientId = "";
            object.patientEmailAddress = "";
            object.status = "";
            object.definitionCode = "";
            object.definitionEncounterType = "";
            object.definitionName = "";
            object.createdAt = null;
            object.updatedAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.patientId != null && message.hasOwnProperty("patientId")) {
            object.patientId = message.patientId;
          }
          if (message.patientEmailAddress != null && message.hasOwnProperty("patientEmailAddress")) {
            object.patientEmailAddress = message.patientEmailAddress;
          }
          if (message.status != null && message.hasOwnProperty("status")) {
            object.status = message.status;
          }
          if (message.definitionCode != null && message.hasOwnProperty("definitionCode")) {
            object.definitionCode = message.definitionCode;
          }
          if (message.definitionEncounterType != null && message.hasOwnProperty("definitionEncounterType")) {
            object.definitionEncounterType = message.definitionEncounterType;
          }
          if (message.definitionName != null && message.hasOwnProperty("definitionName")) {
            object.definitionName = message.definitionName;
          }
          if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
            object.createdAt = timing$1.v1.Timestamp.toObject(message.createdAt, options);
          }
          if (message.updatedAt != null && message.hasOwnProperty("updatedAt")) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(message.updatedAt, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "butterfly_labs.v1.Order";
        }
      }

      Order.prototype.id = "";
      Order.prototype.patientId = "";
      Order.prototype.patientEmailAddress = "";
      Order.prototype.status = "";
      Order.prototype.definitionCode = "";
      Order.prototype.definitionEncounterType = "";
      Order.prototype.definitionName = "";
      Order.prototype.createdAt = null;
      Order.prototype.updatedAt = null;

      return Order;
    })();

    v1.LabResults = (() => {
      class LabResults {
        constructor(properties) {
          this.results = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new LabResults(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId")) {
            writer.uint32(10).string(message.orderId);
          }
          if (message.patientId != null && Object.hasOwnProperty.call(message, "patientId")) {
            writer.uint32(18).string(message.patientId);
          }
          if (message.orderDefinitionCode != null && Object.hasOwnProperty.call(message, "orderDefinitionCode")) {
            writer.uint32(26).string(message.orderDefinitionCode);
          }
          if (message.reportGeneratedDate != null && Object.hasOwnProperty.call(message, "reportGeneratedDate")) {
            timing$1.v1.Timestamp.encode(message.reportGeneratedDate, writer.uint32(34).fork()).ldelim();
          }
          if (message.results != null && Object.hasOwnProperty.call(message, "results")) {
            for (const element of message.results) {
              $root.butterfly_labs.v1.Result.encode(element, writer.uint32(42).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new LabResults();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.orderId = reader.string();
                break;
              }
              case 2: {
                message.patientId = reader.string();
                break;
              }
              case 3: {
                message.orderDefinitionCode = reader.string();
                break;
              }
              case 4: {
                message.reportGeneratedDate = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                if (!message.results || !message.results.length) {
                  message.results = [];
                }
                message.results.push($root.butterfly_labs.v1.Result.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.butterfly_labs.v1.LabResults) {
            return object;
          }
          const message = new $root.butterfly_labs.v1.LabResults();
          if (object.orderId != null) {
            message.orderId = String(object.orderId);
          }
          if (object.patientId != null) {
            message.patientId = String(object.patientId);
          }
          if (object.orderDefinitionCode != null) {
            message.orderDefinitionCode = String(object.orderDefinitionCode);
          }
          if (object.reportGeneratedDate != null) {
            if (typeof object.reportGeneratedDate !== "object") {
              throw new TypeError(".butterfly_labs.v1.LabResults.reportGeneratedDate: object expected, but got " + (typeof object.reportGeneratedDate));
            }
            message.reportGeneratedDate = timing$1.v1.Timestamp.fromObject(object.reportGeneratedDate);
          }
          if (object.results) {
            if (!Array.isArray(object.results)) {
              throw new TypeError(".butterfly_labs.v1.LabResults.results: array type expected, but got " + (typeof object.results))
            }
            message.results = new Array(object.results.length);
            for (let i = 0; i < object.results.length; ++i) {
              if (typeof object.results[i] !== "object") {
                throw new TypeError(".butterfly_labs.v1.LabResults.results: object expected, but got " + (typeof object.results[i]));
              }
              message.results[i] = $root.butterfly_labs.v1.Result.fromObject(object.results[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.results = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.orderId = "";
            object.patientId = "";
            object.orderDefinitionCode = "";
            object.reportGeneratedDate = null;
          }
          let keys;
          if (message.orderId != null && message.hasOwnProperty("orderId")) {
            object.orderId = message.orderId;
          }
          if (message.patientId != null && message.hasOwnProperty("patientId")) {
            object.patientId = message.patientId;
          }
          if (message.orderDefinitionCode != null && message.hasOwnProperty("orderDefinitionCode")) {
            object.orderDefinitionCode = message.orderDefinitionCode;
          }
          if (message.reportGeneratedDate != null && message.hasOwnProperty("reportGeneratedDate")) {
            object.reportGeneratedDate = timing$1.v1.Timestamp.toObject(message.reportGeneratedDate, options);
          }
          if (message.results && message.results.length) {
            object.results = new Array(message.results.length);
            for (let i = 0; i < message.results.length; ++i) {
              object.results[i] = $root.butterfly_labs.v1.Result.toObject(message.results[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "butterfly_labs.v1.LabResults";
        }
      }

      LabResults.prototype.orderId = "";
      LabResults.prototype.patientId = "";
      LabResults.prototype.orderDefinitionCode = "";
      LabResults.prototype.reportGeneratedDate = null;
      LabResults.prototype.results = $util.emptyArray;

      return LabResults;
    })();

    v1.Result = (() => {
      class Result {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Result(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(10).string(message.name);
          }
          if (message.range != null && Object.hasOwnProperty.call(message, "range")) {
            values$1.v1.Value.encode(message.range, writer.uint32(18).fork()).ldelim();
          }
          if (message.value != null && Object.hasOwnProperty.call(message, "value")) {
            values$1.v1.Value.encode(message.value, writer.uint32(26).fork()).ldelim();
          }
          if (message.unit != null && Object.hasOwnProperty.call(message, "unit")) {
            writer.uint32(34).string(message.unit);
          }
          if (message.labComments != null && Object.hasOwnProperty.call(message, "labComments")) {
            writer.uint32(42).string(message.labComments);
          }
          if (message.resultInterpretation != null && Object.hasOwnProperty.call(message, "resultInterpretation")) {
            writer.uint32(50).string(message.resultInterpretation);
          }
          if (message.referenceRangeLow != null && Object.hasOwnProperty.call(message, "referenceRangeLow")) {
            values$1.v1.Value.encode(message.referenceRangeLow, writer.uint32(58).fork()).ldelim();
          }
          if (message.referenceRangeHigh != null && Object.hasOwnProperty.call(message, "referenceRangeHigh")) {
            values$1.v1.Value.encode(message.referenceRangeHigh, writer.uint32(66).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Result();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.range = values$1.v1.Value.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.value = values$1.v1.Value.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.unit = reader.string();
                break;
              }
              case 5: {
                message.labComments = reader.string();
                break;
              }
              case 6: {
                message.resultInterpretation = reader.string();
                break;
              }
              case 7: {
                message.referenceRangeLow = values$1.v1.Value.decode(reader, reader.uint32());
                break;
              }
              case 8: {
                message.referenceRangeHigh = values$1.v1.Value.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.butterfly_labs.v1.Result) {
            return object;
          }
          const message = new $root.butterfly_labs.v1.Result();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.range != null) {
            if (typeof object.range !== "object") {
              throw new TypeError(".butterfly_labs.v1.Result.range: object expected, but got " + (typeof object.range));
            }
            message.range = values$1.v1.Value.fromObject(object.range);
          }
          if (object.value != null) {
            if (typeof object.value !== "object") {
              throw new TypeError(".butterfly_labs.v1.Result.value: object expected, but got " + (typeof object.value));
            }
            message.value = values$1.v1.Value.fromObject(object.value);
          }
          if (object.unit != null) {
            message.unit = String(object.unit);
          }
          if (object.labComments != null) {
            message.labComments = String(object.labComments);
          }
          if (object.resultInterpretation != null) {
            message.resultInterpretation = String(object.resultInterpretation);
          }
          if (object.referenceRangeLow != null) {
            if (typeof object.referenceRangeLow !== "object") {
              throw new TypeError(".butterfly_labs.v1.Result.referenceRangeLow: object expected, but got " + (typeof object.referenceRangeLow));
            }
            message.referenceRangeLow = values$1.v1.Value.fromObject(object.referenceRangeLow);
          }
          if (object.referenceRangeHigh != null) {
            if (typeof object.referenceRangeHigh !== "object") {
              throw new TypeError(".butterfly_labs.v1.Result.referenceRangeHigh: object expected, but got " + (typeof object.referenceRangeHigh));
            }
            message.referenceRangeHigh = values$1.v1.Value.fromObject(object.referenceRangeHigh);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = "";
            object.value = null;
            object.resultInterpretation = "";
          }
          let keys;
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.range != null && message.hasOwnProperty("range")) {
            object.range = values$1.v1.Value.toObject(message.range, options);
          }
          if (message.value != null && message.hasOwnProperty("value")) {
            object.value = values$1.v1.Value.toObject(message.value, options);
          }
          if (message.unit != null && message.hasOwnProperty("unit")) {
            object.unit = message.unit;
          }
          if (message.labComments != null && message.hasOwnProperty("labComments")) {
            object.labComments = message.labComments;
          }
          if (message.resultInterpretation != null && message.hasOwnProperty("resultInterpretation")) {
            object.resultInterpretation = message.resultInterpretation;
          }
          if (message.referenceRangeLow != null && message.hasOwnProperty("referenceRangeLow")) {
            object.referenceRangeLow = values$1.v1.Value.toObject(message.referenceRangeLow, options);
          }
          if (message.referenceRangeHigh != null && message.hasOwnProperty("referenceRangeHigh")) {
            object.referenceRangeHigh = values$1.v1.Value.toObject(message.referenceRangeHigh, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "butterfly_labs.v1.Result";
        }
      }

      Result.prototype.name = "";
      Result.prototype.range = null;
      Result.prototype.value = null;
      Result.prototype.unit = null;
      Result.prototype.labComments = null;
      Result.prototype.resultInterpretation = "";
      Result.prototype.referenceRangeLow = null;
      Result.prototype.referenceRangeHigh = null;

      return Result;
    })();

    v1.Patient = (() => {
      class Patient {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Patient(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          if (message.externalId != null && Object.hasOwnProperty.call(message, "externalId")) {
            writer.uint32(18).string(message.externalId);
          }
          if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress")) {
            writer.uint32(26).string(message.emailAddress);
          }
          if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName")) {
            writer.uint32(34).string(message.firstName);
          }
          if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName")) {
            writer.uint32(42).string(message.lastName);
          }
          if (message.dateOfBirth != null && Object.hasOwnProperty.call(message, "dateOfBirth")) {
            values$1.v1.Date.encode(message.dateOfBirth, writer.uint32(50).fork()).ldelim();
          }
          if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt")) {
            timing$1.v1.Timestamp.encode(message.createdAt, writer.uint32(58).fork()).ldelim();
          }
          if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt")) {
            timing$1.v1.Timestamp.encode(message.updatedAt, writer.uint32(66).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Patient();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.externalId = reader.string();
                break;
              }
              case 3: {
                message.emailAddress = reader.string();
                break;
              }
              case 4: {
                message.firstName = reader.string();
                break;
              }
              case 5: {
                message.lastName = reader.string();
                break;
              }
              case 6: {
                message.dateOfBirth = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.createdAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 8: {
                message.updatedAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.butterfly_labs.v1.Patient) {
            return object;
          }
          const message = new $root.butterfly_labs.v1.Patient();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.externalId != null) {
            message.externalId = String(object.externalId);
          }
          if (object.emailAddress != null) {
            message.emailAddress = String(object.emailAddress);
          }
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.dateOfBirth != null) {
            if (typeof object.dateOfBirth !== "object") {
              throw new TypeError(".butterfly_labs.v1.Patient.dateOfBirth: object expected, but got " + (typeof object.dateOfBirth));
            }
            message.dateOfBirth = values$1.v1.Date.fromObject(object.dateOfBirth);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== "object") {
              throw new TypeError(".butterfly_labs.v1.Patient.createdAt: object expected, but got " + (typeof object.createdAt));
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(object.createdAt);
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== "object") {
              throw new TypeError(".butterfly_labs.v1.Patient.updatedAt: object expected, but got " + (typeof object.updatedAt));
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(object.updatedAt);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
            object.externalId = "";
            object.createdAt = null;
            object.updatedAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.externalId != null && message.hasOwnProperty("externalId")) {
            object.externalId = message.externalId;
          }
          if (message.emailAddress != null && message.hasOwnProperty("emailAddress")) {
            object.emailAddress = message.emailAddress;
          }
          if (message.firstName != null && message.hasOwnProperty("firstName")) {
            object.firstName = message.firstName;
          }
          if (message.lastName != null && message.hasOwnProperty("lastName")) {
            object.lastName = message.lastName;
          }
          if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth")) {
            object.dateOfBirth = values$1.v1.Date.toObject(message.dateOfBirth, options);
          }
          if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
            object.createdAt = timing$1.v1.Timestamp.toObject(message.createdAt, options);
          }
          if (message.updatedAt != null && message.hasOwnProperty("updatedAt")) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(message.updatedAt, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "butterfly_labs.v1.Patient";
        }
      }

      Patient.prototype.id = "";
      Patient.prototype.externalId = "";
      Patient.prototype.emailAddress = null;
      Patient.prototype.firstName = null;
      Patient.prototype.lastName = null;
      Patient.prototype.dateOfBirth = null;
      Patient.prototype.createdAt = null;
      Patient.prototype.updatedAt = null;

      return Patient;
    })();

    v1.ListPatientsRequest = (() => {
      class ListPatientsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListPatientsRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.page != null && Object.hasOwnProperty.call(message, "page")) {
            pagination$1.v1.Pagination.encode(message.page, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListPatientsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.page = pagination$1.v1.Pagination.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.butterfly_labs.v1.ListPatientsRequest) {
            return object;
          }
          const message = new $root.butterfly_labs.v1.ListPatientsRequest();
          if (object.page != null) {
            if (typeof object.page !== "object") {
              throw new TypeError(".butterfly_labs.v1.ListPatientsRequest.page: object expected, but got " + (typeof object.page));
            }
            message.page = pagination$1.v1.Pagination.fromObject(object.page);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.page = null;
          }
          let keys;
          if (message.page != null && message.hasOwnProperty("page")) {
            object.page = pagination$1.v1.Pagination.toObject(message.page, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "butterfly_labs.v1.ListPatientsRequest";
        }
      }

      ListPatientsRequest.prototype.page = null;

      return ListPatientsRequest;
    })();

    v1.ListPatientsResponse = (() => {
      class ListPatientsResponse {
        constructor(properties) {
          this.patients = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListPatientsResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.total != null && Object.hasOwnProperty.call(message, "total")) {
            writer.uint32(8).uint64(message.total);
          }
          if (message.patients != null && Object.hasOwnProperty.call(message, "patients")) {
            for (const element of message.patients) {
              $root.butterfly_labs.v1.Patient.encode(element, writer.uint32(18).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListPatientsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.total = reader.uint64();
                break;
              }
              case 2: {
                if (!message.patients || !message.patients.length) {
                  message.patients = [];
                }
                message.patients.push($root.butterfly_labs.v1.Patient.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.butterfly_labs.v1.ListPatientsResponse) {
            return object;
          }
          const message = new $root.butterfly_labs.v1.ListPatientsResponse();
          if (object.total != null) {
            if ($util.Long) {
              (message.total = $util.Long.fromValue(object.total)).unsigned = true;
            } else if (typeof object.total === "string") {
              message.total = parseInt(object.total, 10);
            } else if (typeof object.total === "number") {
              message.total = object.total;
            } else if (typeof object.total === "object") {
              message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber(true);
            }
          }
          if (object.patients) {
            if (!Array.isArray(object.patients)) {
              throw new TypeError(".butterfly_labs.v1.ListPatientsResponse.patients: array type expected, but got " + (typeof object.patients))
            }
            message.patients = new Array(object.patients.length);
            for (let i = 0; i < object.patients.length; ++i) {
              if (typeof object.patients[i] !== "object") {
                throw new TypeError(".butterfly_labs.v1.ListPatientsResponse.patients: object expected, but got " + (typeof object.patients[i]));
              }
              message.patients[i] = $root.butterfly_labs.v1.Patient.fromObject(object.patients[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.patients = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, true)
              object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.total = options.longs === String ? "0" : 0;
            }
          }
          let keys;
          if (message.total != null && message.hasOwnProperty("total")) {
            object.total = typeof message.total === "number" ? (options.longs === String ? String(message.total) : message.total) : (options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber(true) : message.total);
          }
          if (message.patients && message.patients.length) {
            object.patients = new Array(message.patients.length);
            for (let i = 0; i < message.patients.length; ++i) {
              object.patients[i] = $root.butterfly_labs.v1.Patient.toObject(message.patients[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "butterfly_labs.v1.ListPatientsResponse";
        }
      }

      ListPatientsResponse.prototype.total = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
      ListPatientsResponse.prototype.patients = $util.emptyArray;

      return ListPatientsResponse;
    })();

    v1.ListOrdersRequest = (() => {
      class ListOrdersRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListOrdersRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.page != null && Object.hasOwnProperty.call(message, "page")) {
            pagination$1.v1.Pagination.encode(message.page, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListOrdersRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.page = pagination$1.v1.Pagination.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.butterfly_labs.v1.ListOrdersRequest) {
            return object;
          }
          const message = new $root.butterfly_labs.v1.ListOrdersRequest();
          if (object.page != null) {
            if (typeof object.page !== "object") {
              throw new TypeError(".butterfly_labs.v1.ListOrdersRequest.page: object expected, but got " + (typeof object.page));
            }
            message.page = pagination$1.v1.Pagination.fromObject(object.page);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.page = null;
          }
          let keys;
          if (message.page != null && message.hasOwnProperty("page")) {
            object.page = pagination$1.v1.Pagination.toObject(message.page, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "butterfly_labs.v1.ListOrdersRequest";
        }
      }

      ListOrdersRequest.prototype.page = null;

      return ListOrdersRequest;
    })();

    v1.ListOrdersResponse = (() => {
      class ListOrdersResponse {
        constructor(properties) {
          this.orders = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListOrdersResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.total != null && Object.hasOwnProperty.call(message, "total")) {
            writer.uint32(8).uint64(message.total);
          }
          if (message.orders != null && Object.hasOwnProperty.call(message, "orders")) {
            for (const element of message.orders) {
              $root.butterfly_labs.v1.Order.encode(element, writer.uint32(18).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListOrdersResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.total = reader.uint64();
                break;
              }
              case 2: {
                if (!message.orders || !message.orders.length) {
                  message.orders = [];
                }
                message.orders.push($root.butterfly_labs.v1.Order.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.butterfly_labs.v1.ListOrdersResponse) {
            return object;
          }
          const message = new $root.butterfly_labs.v1.ListOrdersResponse();
          if (object.total != null) {
            if ($util.Long) {
              (message.total = $util.Long.fromValue(object.total)).unsigned = true;
            } else if (typeof object.total === "string") {
              message.total = parseInt(object.total, 10);
            } else if (typeof object.total === "number") {
              message.total = object.total;
            } else if (typeof object.total === "object") {
              message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber(true);
            }
          }
          if (object.orders) {
            if (!Array.isArray(object.orders)) {
              throw new TypeError(".butterfly_labs.v1.ListOrdersResponse.orders: array type expected, but got " + (typeof object.orders))
            }
            message.orders = new Array(object.orders.length);
            for (let i = 0; i < object.orders.length; ++i) {
              if (typeof object.orders[i] !== "object") {
                throw new TypeError(".butterfly_labs.v1.ListOrdersResponse.orders: object expected, but got " + (typeof object.orders[i]));
              }
              message.orders[i] = $root.butterfly_labs.v1.Order.fromObject(object.orders[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.orders = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, true)
              object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.total = options.longs === String ? "0" : 0;
            }
          }
          let keys;
          if (message.total != null && message.hasOwnProperty("total")) {
            object.total = typeof message.total === "number" ? (options.longs === String ? String(message.total) : message.total) : (options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber(true) : message.total);
          }
          if (message.orders && message.orders.length) {
            object.orders = new Array(message.orders.length);
            for (let i = 0; i < message.orders.length; ++i) {
              object.orders[i] = $root.butterfly_labs.v1.Order.toObject(message.orders[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "butterfly_labs.v1.ListOrdersResponse";
        }
      }

      ListOrdersResponse.prototype.total = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
      ListOrdersResponse.prototype.orders = $util.emptyArray;

      return ListOrdersResponse;
    })();

    v1.ListOrdersForPatientRequest = (() => {
      class ListOrdersForPatientRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListOrdersForPatientRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.patientId != null && Object.hasOwnProperty.call(message, "patientId")) {
            writer.uint32(10).string(message.patientId);
          }
          if (message.page != null && Object.hasOwnProperty.call(message, "page")) {
            pagination$1.v1.Pagination.encode(message.page, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListOrdersForPatientRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.patientId = reader.string();
                break;
              }
              case 2: {
                message.page = pagination$1.v1.Pagination.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.butterfly_labs.v1.ListOrdersForPatientRequest) {
            return object;
          }
          const message = new $root.butterfly_labs.v1.ListOrdersForPatientRequest();
          if (object.patientId != null) {
            message.patientId = String(object.patientId);
          }
          if (object.page != null) {
            if (typeof object.page !== "object") {
              throw new TypeError(".butterfly_labs.v1.ListOrdersForPatientRequest.page: object expected, but got " + (typeof object.page));
            }
            message.page = pagination$1.v1.Pagination.fromObject(object.page);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.patientId = "";
            object.page = null;
          }
          let keys;
          if (message.patientId != null && message.hasOwnProperty("patientId")) {
            object.patientId = message.patientId;
          }
          if (message.page != null && message.hasOwnProperty("page")) {
            object.page = pagination$1.v1.Pagination.toObject(message.page, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "butterfly_labs.v1.ListOrdersForPatientRequest";
        }
      }

      ListOrdersForPatientRequest.prototype.patientId = "";
      ListOrdersForPatientRequest.prototype.page = null;

      return ListOrdersForPatientRequest;
    })();

    v1.ListOrdersForPatientResponse = (() => {
      class ListOrdersForPatientResponse {
        constructor(properties) {
          this.orders = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListOrdersForPatientResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.total != null && Object.hasOwnProperty.call(message, "total")) {
            writer.uint32(8).uint64(message.total);
          }
          if (message.orders != null && Object.hasOwnProperty.call(message, "orders")) {
            for (const element of message.orders) {
              $root.butterfly_labs.v1.Order.encode(element, writer.uint32(18).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListOrdersForPatientResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.total = reader.uint64();
                break;
              }
              case 2: {
                if (!message.orders || !message.orders.length) {
                  message.orders = [];
                }
                message.orders.push($root.butterfly_labs.v1.Order.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.butterfly_labs.v1.ListOrdersForPatientResponse) {
            return object;
          }
          const message = new $root.butterfly_labs.v1.ListOrdersForPatientResponse();
          if (object.total != null) {
            if ($util.Long) {
              (message.total = $util.Long.fromValue(object.total)).unsigned = true;
            } else if (typeof object.total === "string") {
              message.total = parseInt(object.total, 10);
            } else if (typeof object.total === "number") {
              message.total = object.total;
            } else if (typeof object.total === "object") {
              message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber(true);
            }
          }
          if (object.orders) {
            if (!Array.isArray(object.orders)) {
              throw new TypeError(".butterfly_labs.v1.ListOrdersForPatientResponse.orders: array type expected, but got " + (typeof object.orders))
            }
            message.orders = new Array(object.orders.length);
            for (let i = 0; i < object.orders.length; ++i) {
              if (typeof object.orders[i] !== "object") {
                throw new TypeError(".butterfly_labs.v1.ListOrdersForPatientResponse.orders: object expected, but got " + (typeof object.orders[i]));
              }
              message.orders[i] = $root.butterfly_labs.v1.Order.fromObject(object.orders[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.orders = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, true)
              object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else {
              object.total = options.longs === String ? "0" : 0;
            }
          }
          let keys;
          if (message.total != null && message.hasOwnProperty("total")) {
            object.total = typeof message.total === "number" ? (options.longs === String ? String(message.total) : message.total) : (options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber(true) : message.total);
          }
          if (message.orders && message.orders.length) {
            object.orders = new Array(message.orders.length);
            for (let i = 0; i < message.orders.length; ++i) {
              object.orders[i] = $root.butterfly_labs.v1.Order.toObject(message.orders[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "butterfly_labs.v1.ListOrdersForPatientResponse";
        }
      }

      ListOrdersForPatientResponse.prototype.total = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
      ListOrdersForPatientResponse.prototype.orders = $util.emptyArray;

      return ListOrdersForPatientResponse;
    })();

    v1.GetOrderRequest = (() => {
      class GetOrderRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetOrderRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(10).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetOrderRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.butterfly_labs.v1.GetOrderRequest) {
            return object;
          }
          const message = new $root.butterfly_labs.v1.GetOrderRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = "";
          }
          let keys;
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "butterfly_labs.v1.GetOrderRequest";
        }
      }

      GetOrderRequest.prototype.id = "";

      return GetOrderRequest;
    })();

    v1.GetOrderResponse = (() => {
      class GetOrderResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetOrderResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.order != null && Object.hasOwnProperty.call(message, "order")) {
            $root.butterfly_labs.v1.Order.encode(message.order, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetOrderResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.order = $root.butterfly_labs.v1.Order.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.butterfly_labs.v1.GetOrderResponse) {
            return object;
          }
          const message = new $root.butterfly_labs.v1.GetOrderResponse();
          if (object.order != null) {
            if (typeof object.order !== "object") {
              throw new TypeError(".butterfly_labs.v1.GetOrderResponse.order: object expected, but got " + (typeof object.order));
            }
            message.order = $root.butterfly_labs.v1.Order.fromObject(object.order);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.order = null;
          }
          let keys;
          if (message.order != null && message.hasOwnProperty("order")) {
            object.order = $root.butterfly_labs.v1.Order.toObject(message.order, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "butterfly_labs.v1.GetOrderResponse";
        }
      }

      GetOrderResponse.prototype.order = null;

      return GetOrderResponse;
    })();

    v1.GetLabResultsRequest = (() => {
      class GetLabResultsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetLabResultsRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId")) {
            writer.uint32(10).string(message.orderId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetLabResultsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.orderId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.butterfly_labs.v1.GetLabResultsRequest) {
            return object;
          }
          const message = new $root.butterfly_labs.v1.GetLabResultsRequest();
          if (object.orderId != null) {
            message.orderId = String(object.orderId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.orderId = "";
          }
          let keys;
          if (message.orderId != null && message.hasOwnProperty("orderId")) {
            object.orderId = message.orderId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "butterfly_labs.v1.GetLabResultsRequest";
        }
      }

      GetLabResultsRequest.prototype.orderId = "";

      return GetLabResultsRequest;
    })();

    v1.GetLabResultsResponse = (() => {
      class GetLabResultsResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetLabResultsResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.labResults != null && Object.hasOwnProperty.call(message, "labResults")) {
            $root.butterfly_labs.v1.LabResults.encode(message.labResults, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetLabResultsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.labResults = $root.butterfly_labs.v1.LabResults.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.butterfly_labs.v1.GetLabResultsResponse) {
            return object;
          }
          const message = new $root.butterfly_labs.v1.GetLabResultsResponse();
          if (object.labResults != null) {
            if (typeof object.labResults !== "object") {
              throw new TypeError(".butterfly_labs.v1.GetLabResultsResponse.labResults: object expected, but got " + (typeof object.labResults));
            }
            message.labResults = $root.butterfly_labs.v1.LabResults.fromObject(object.labResults);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.labResults = null;
          }
          let keys;
          if (message.labResults != null && message.hasOwnProperty("labResults")) {
            object.labResults = $root.butterfly_labs.v1.LabResults.toObject(message.labResults, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "butterfly_labs.v1.GetLabResultsResponse";
        }
      }

      GetLabResultsResponse.prototype.labResults = null;

      return GetLabResultsResponse;
    })();

    v1.ButterflyLabsEventType = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED_ILLEGAL_BUTTERFLY_LABS_EVENT_TYPE"] = 0;
      values[valuesById[1] = "BUTTERFLY_LABS_LAB_ORDER_STATUS_UPDATE"] = 1;
      values[valuesById[2] = "BUTTERFLY_LABS_LAB_ORDER_RESULTS_UPDATE"] = 2;
      return values;
    })();

    v1.ButterflyLabsService = (() => {
      class ButterflyLabsService extends $protobuf.rpc.Service {
        constructor(rpcImpl, requestDelimited = false, responseDelimited = false) {
          super(rpcImpl, requestDelimited, responseDelimited);
        }

        static create(rpcImpl, requestDelimited = false, responseDelimited = false) {
          return new ButterflyLabsService(rpcImpl, requestDelimited, responseDelimited);
        }
      }

      Object.defineProperty(ButterflyLabsService.prototype.listPatients = function listPatients(request, callback) {
        return this.rpcCall(listPatients, $root.butterfly_labs.v1.ListPatientsRequest, $root.butterfly_labs.v1.ListPatientsResponse, request, callback);
      }, "name", { value: "ListPatients" });

      Object.defineProperty(ButterflyLabsService.prototype.listOrders = function listOrders(request, callback) {
        return this.rpcCall(listOrders, $root.butterfly_labs.v1.ListOrdersRequest, $root.butterfly_labs.v1.ListOrdersResponse, request, callback);
      }, "name", { value: "ListOrders" });

      Object.defineProperty(ButterflyLabsService.prototype.listOrdersForPatient = function listOrdersForPatient(request, callback) {
        return this.rpcCall(listOrdersForPatient, $root.butterfly_labs.v1.ListOrdersForPatientRequest, $root.butterfly_labs.v1.ListOrdersForPatientResponse, request, callback);
      }, "name", { value: "ListOrdersForPatient" });

      Object.defineProperty(ButterflyLabsService.prototype.getOrder = function getOrder(request, callback) {
        return this.rpcCall(getOrder, $root.butterfly_labs.v1.GetOrderRequest, $root.butterfly_labs.v1.GetOrderResponse, request, callback);
      }, "name", { value: "GetOrder" });

      Object.defineProperty(ButterflyLabsService.prototype.getLabResults = function getLabResults(request, callback) {
        return this.rpcCall(getLabResults, $root.butterfly_labs.v1.GetLabResultsRequest, $root.butterfly_labs.v1.GetLabResultsResponse, request, callback);
      }, "name", { value: "GetLabResults" });

      return ButterflyLabsService;
    })();

    return v1;
  })();

  return butterfly_labs;
})();

export const butterfly_labs = $root.butterfly_labs;
