import { Node } from 'reactflow';
import { ProfileLookupNodeType } from './ProfileLookupNode/types';
import { TimelineRowStatusProps } from '../../TimelineRow/TimeRowStatus/types';
import { organization } from '@morf/proto/organization/v1/organization_ts_proto';
import { profiles } from '@morf/proto/profiles/v1/profiles_ts_proto';
import { workflow_monitoring } from '@morf/proto/workflow_monitoring/v2/workflow_monitoring_ts_proto';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';
import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';

export enum NodeType {
  DestinationActionNode = 'destinationActionNode',
  FetchActionNode = 'fetchActionNode',
  FilterNode = 'filterNode',
  PauseNode = 'pauseNode',
  ProfileConvertNode = 'profileConvertNode',
  ProfileLookupNode = 'profileLookupNode',
  ProfileUpdateNode = 'profileUpdateNode',
  RestartNode = 'restartNode',
  TrackProfilePropertiesNode = 'trackProfilePropertiesNode',
  TriggerNode = 'triggerNode',
}

export enum SupportNodeType {
  HiddenNode = 'hiddenNode',
}

export type CustomNodeType = NodeType | SupportNodeType | ProfileLookupNodeType;

export type WorkflowNodeOneOf =
  | 'destinationAction'
  | 'filter'
  | 'pause'
  | 'fetchAction'
  | 'restart';

type WorkflowNodeOneOfMapping = {
  destinationAction: workflows.v1.WorkflowDestinationAction;
  filter: workflows.v1.WorkflowFilter;
  pause: workflows.v1.WorkflowPause;
  fetchAction: workflows.v1.WorkflowFetchAction;
  restart: workflows.v1.WorkflowRestart;
};

export type ParameterizedWorkflowNode<T extends WorkflowNodeOneOf> =
  T extends keyof WorkflowNodeOneOfMapping
    ? { [K in T]: WorkflowNodeOneOfMapping[K] }
    : never;

export type WorkflowNodeType<T extends WorkflowNodeOneOf> =
  | workflows.v1.Trigger
  | profiles.v1.LookupConfiguration
  | workflows.v1.WorkflowNode
  | (workflows.v1.WorkflowNode & Partial<ParameterizedWorkflowNode<T>>);

export type CustomNodeData<T extends WorkflowNodeType<WorkflowNodeOneOf>> = {
  value: {
    configuration: T;
    description?: string | null | undefined;
    iconName?: string;
    integrationIconName?: string;
    isMorfAnalyticsEventPayload?: boolean;
    isMorfAnalyticsEventPayloadTrack?: boolean;
    isReadOnly?: boolean;
    name: string;
    nodeSpecificEventPayloadFieldSchemas?: workflow_parameters.v1.WorkflowEventPayloadFieldSchema[];
    onAddNode?: (data: onAddNodeData) => void;
    title: string;
    onCreateCustomProperty?: (
      data: organization.v1.CreateProfilePropertyRequest
    ) => void;
    onRemoveNode?: (data: onRemoveNodeData) => void;
    onUpdateNode?: (data: onUpdateNodeData) => void;
    onUpdateProfileLookup?: (data: onUpdateProfileLookupData) => void;
    onUpdateTrigger?: (data: onUpdateTriggerData) => void;
    status?:
      | workflow_monitoring.v2.ActionExecutionAttempts.Status
      | TimelineRowStatusProps['type']
      | 'inactive'
      | 'invalid'
      | 'default';
    workflowEventPayloadFieldSchemas?: workflow_parameters.v1.WorkflowEventPayloadFieldSchema[];
  };
};

export type CustomNode<
  T extends WorkflowNodeType<WorkflowNodeOneOf> = WorkflowNodeType<WorkflowNodeOneOf>
> = Node<CustomNodeData<T>, CustomNodeType>;

export type onUpdateTriggerData = {
  trigger: workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger;
};
export type onUpdateProfileLookupData = {
  profileLookupConfiguration: profiles.v1.LookupConfiguration;
};
export type onAddNodeData = { node: workflows.v1.CreateWorkflowNodeRequest };
export type onUpdateNodeData = {
  nodeId: string;
  type: workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType;
};
export type onRemoveNodeData = { nodeId: string };
