import React from 'react';
import { useTheme } from '@morf/theming';
import { Container } from '../Container';
import { sizes, SwitchButton } from './Switch.css';

export interface SwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  'aria-label'?: string;
  onClick?: (e: React.MouseEvent) => void;
}

export const Switch: React.FC<SwitchProps> = ({
  checked,
  onChange,
  disabled = false,
  size = 'medium',
  'aria-label': ariaLabel,
  onClick,
}) => {
  const theme = useTheme();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!disabled) {
      onChange(!checked);
      onClick?.(e);
    }
  };

  return (
    <Container
      data-testid='switch'
      width={`${sizes[size].width / 16}rem`}
      height={`${sizes[size].height / 16}rem`}
      backgroundColor={
        disabled
          ? theme.colors.ui.muted
          : checked
          ? theme.colors.main.primary.normal
          : theme.colors.ui.divider
      }
      borderRadius='full'
      cursor={disabled ? 'not-allowed' : 'pointer'}
      opacity={disabled ? 0.5 : 1}
      p={0.125}
      position='relative'
    >
      <SwitchButton
        aria-checked={checked}
        aria-label={ariaLabel}
        disabled={disabled}
        onClick={handleClick}
      >
        <Container
          position='absolute'
          width={`${sizes[size].thumbSize / 16}rem`}
          height={`${sizes[size].thumbSize / 16}rem`}
          backgroundColor={theme.colors.ui.body}
          borderRadius='full'
          shadow='base'
          top='50%'
          left='0.125rem'
          style={{
            transform: checked
              ? `translate3d(${
                  (sizes[size].width - sizes[size].thumbSize - 4) / 16
                }rem, -50%, 0)`
              : 'translate3d(0, -50%, 0)',
            transition: 'transform 0.2s ease-in-out',
            pointerEvents: 'none',
          }}
        />
      </SwitchButton>
    </Container>
  );
};

export default Switch;
