import { InputRule } from '@tiptap/react';
import { NodeType } from '@tiptap/pm/model';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';
import { serializeParameterSource } from './CelExpressionUtils';

const celExpressionRegex = /{{([^{}]+)}}/g;

type DestinationActionParameterSource =
  workflow_parameters.v1.DestinationActionParameterSource;

/**
 * Input rule for creating a new CEL expression node, {{ }} style
 * @param nodeType - The node type to create
 * @returns An InputRule object
 */
export const celExpressionInputRule = (nodeType: NodeType) => {
  return new InputRule({
    find: celExpressionRegex,
    handler: ({ state, range, match, chain }) => {
      const content = match[1].trim();
      if (!content) return;

      const { tr } = state;
      const start = range.from;
      const end = range.to;

      const id = `cel-${Date.now()}-${Math.floor(Math.random() * 1000)}`;

      const parameterSource = {
        calculatedValue: {
          celExpressionStr: content,
          runtimeType: undefined,
        },
      } as DestinationActionParameterSource;

      const parameterSourceBase64 = serializeParameterSource(parameterSource);

      tr.replaceWith(
        start,
        end,
        nodeType.create({
          parameterSource: parameterSourceBase64,
          id,
          label: content,
        })
      );

      chain().run();
    },
  });
};
