import { FC, useRef, useState } from 'react';
import { Flexbox } from '../../../../../../Flexbox';
import { Container } from '../../../../../../Container';
import { Button } from '../../../../../../Button';
import {
  TemplateEditor,
  TemplateEditorRef,
} from '../../../../../../TemplateEditor';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';
import { SlashCommandItem } from '../../../../../../TemplateEditor/extensions/SlashCommand/SlashCommandTypes';
import { CelExpressionAttributes } from '../../../../../../TemplateEditor/extensions/CelExpression/CelExpression';

export interface TemplateEditorSectionProps {
  initialValue?: string;
  slashCommandItems?: SlashCommandItem[];
  onSubmit: (
    templateContent: string,
    celExpressions: CelExpressionAttributes[]
  ) => void;
  onSubmitTemplateSource?: (
    templateSource: workflow_parameters.v1.TemplateSource
  ) => void;
}

export const TemplateEditorSection: FC<TemplateEditorSectionProps> = ({
  initialValue = '',
  slashCommandItems = [],
  onSubmit,
  onSubmitTemplateSource,
}) => {
  const templateEditorRef = useRef<TemplateEditorRef>(null);
  const [templateContent, setTemplateContent] = useState<string>(initialValue);

  const handleSubmit = () => {
    if (onSubmitTemplateSource && templateEditorRef.current) {
      const templateSource = templateEditorRef.current.getTemplateSource();
      onSubmitTemplateSource(templateSource);
    } else {
      const celExpressions =
        templateEditorRef.current?.getAllCelExpressions() || [];
      onSubmit(templateContent, celExpressions);
    }
  };

  return (
    <Flexbox direction='column' gap={0.5}>
      <Container
        height='100%'
        width='100%'
        px={0.75}
        maxHeight='25rem'
        overflow='auto'
      >
        <TemplateEditor
          ref={templateEditorRef}
          initialValue={templateContent}
          onChange={({ html }: { html: string; text: string }) => {
            setTemplateContent(html);
          }}
          height='100%'
          width='100%'
          slashCommandItems={slashCommandItems}
          autoFocus
          minHeight='12.5rem'
        />
      </Container>
      <Flexbox justifyContent='flex-end' width='100%' pb={0.5} pr={0.5}>
        <Button variant='primary' onClick={handleSubmit}>
          Save Template
        </Button>
      </Flexbox>
    </Flexbox>
  );
};

export default TemplateEditorSection;
