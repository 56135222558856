"use strict";

import * as $protobuf from "protobufjs/minimal";
import { google as google$1 } from "../../empty_ts_proto";
import { values as values$1 } from "../../values/v1/values_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.stedi = (() => {
  const stedi = {};
  stedi.v1 = (() => {
    const v1 = {};
    v1.ListSupportedPayersRequest = (() => {
      class ListSupportedPayersRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListSupportedPayersRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.stub != null && Object.hasOwnProperty.call(message, "stub")) {
            google$1.protobuf.Empty.encode(message.stub, writer.uint32(10).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListSupportedPayersRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stub = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.ListSupportedPayersRequest) {
            return object;
          }
          const message = new $root.stedi.v1.ListSupportedPayersRequest();
          if (object.stub != null) {
            if (typeof object.stub !== "object") {
              throw new TypeError(".stedi.v1.ListSupportedPayersRequest.stub: object expected, but got " + (typeof object.stub));
            }
            message.stub = google$1.protobuf.Empty.fromObject(object.stub);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.stub = null;
          }
          let keys;
          if (message.stub != null && message.hasOwnProperty("stub")) {
            object.stub = google$1.protobuf.Empty.toObject(message.stub, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.ListSupportedPayersRequest";
        }
      }

      ListSupportedPayersRequest.prototype.stub = null;

      return ListSupportedPayersRequest;
    })();

    v1.ListSupportedPayersResponse = (() => {
      class ListSupportedPayersResponse {
        constructor(properties) {
          this.supportedPayers = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListSupportedPayersResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.supportedPayers != null && Object.hasOwnProperty.call(message, "supportedPayers")) {
            for (const element of message.supportedPayers) {
              $root.stedi.v1.SupportedPayer.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListSupportedPayersResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.supportedPayers || !message.supportedPayers.length) {
                  message.supportedPayers = [];
                }
                message.supportedPayers.push($root.stedi.v1.SupportedPayer.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.ListSupportedPayersResponse) {
            return object;
          }
          const message = new $root.stedi.v1.ListSupportedPayersResponse();
          if (object.supportedPayers) {
            if (!Array.isArray(object.supportedPayers)) {
              throw new TypeError(".stedi.v1.ListSupportedPayersResponse.supportedPayers: array type expected, but got " + (typeof object.supportedPayers))
            }
            message.supportedPayers = new Array(object.supportedPayers.length);
            for (let i = 0; i < object.supportedPayers.length; ++i) {
              if (typeof object.supportedPayers[i] !== "object") {
                throw new TypeError(".stedi.v1.ListSupportedPayersResponse.supportedPayers: object expected, but got " + (typeof object.supportedPayers[i]));
              }
              message.supportedPayers[i] = $root.stedi.v1.SupportedPayer.fromObject(object.supportedPayers[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.supportedPayers = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.supportedPayers && message.supportedPayers.length) {
            object.supportedPayers = new Array(message.supportedPayers.length);
            for (let i = 0; i < message.supportedPayers.length; ++i) {
              object.supportedPayers[i] = $root.stedi.v1.SupportedPayer.toObject(message.supportedPayers[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.ListSupportedPayersResponse";
        }
      }

      ListSupportedPayersResponse.prototype.supportedPayers = $util.emptyArray;

      return ListSupportedPayersResponse;
    })();

    v1.CheckEligibilityRequest = (() => {
      class CheckEligibilityRequest {
        constructor(properties) {
          this.dependents = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CheckEligibilityRequest(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.controlNumber != null && Object.hasOwnProperty.call(message, "controlNumber")) {
            writer.uint32(10).string(message.controlNumber);
          }
          if (message.tradingPartnerServiceId != null && Object.hasOwnProperty.call(message, "tradingPartnerServiceId")) {
            writer.uint32(18).string(message.tradingPartnerServiceId);
          }
          if (message.subscriber != null && Object.hasOwnProperty.call(message, "subscriber")) {
            $root.stedi.v1.Subscriber.encode(message.subscriber, writer.uint32(26).fork()).ldelim();
          }
          if (message.provider != null && Object.hasOwnProperty.call(message, "provider")) {
            $root.stedi.v1.Provider.encode(message.provider, writer.uint32(34).fork()).ldelim();
          }
          if (message.submitterTransactionIdentifier != null && Object.hasOwnProperty.call(message, "submitterTransactionIdentifier")) {
            writer.uint32(42).string(message.submitterTransactionIdentifier);
          }
          if (message.tradingPartnerName != null && Object.hasOwnProperty.call(message, "tradingPartnerName")) {
            writer.uint32(50).string(message.tradingPartnerName);
          }
          if (message.encounter != null && Object.hasOwnProperty.call(message, "encounter")) {
            $root.stedi.v1.Encounter.encode(message.encounter, writer.uint32(58).fork()).ldelim();
          }
          if (message.externalPatientId != null && Object.hasOwnProperty.call(message, "externalPatientId")) {
            writer.uint32(66).string(message.externalPatientId);
          }
          if (message.eligibilitySearchId != null && Object.hasOwnProperty.call(message, "eligibilitySearchId")) {
            writer.uint32(74).string(message.eligibilitySearchId);
          }
          if (message.dependents != null && Object.hasOwnProperty.call(message, "dependents")) {
            for (const element of message.dependents) {
              $root.stedi.v1.Dependent.encode(element, writer.uint32(82).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CheckEligibilityRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.controlNumber = reader.string();
                break;
              }
              case 2: {
                message.tradingPartnerServiceId = reader.string();
                break;
              }
              case 3: {
                message.subscriber = $root.stedi.v1.Subscriber.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.provider = $root.stedi.v1.Provider.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.submitterTransactionIdentifier = reader.string();
                break;
              }
              case 6: {
                message.tradingPartnerName = reader.string();
                break;
              }
              case 7: {
                message.encounter = $root.stedi.v1.Encounter.decode(reader, reader.uint32());
                break;
              }
              case 8: {
                message.externalPatientId = reader.string();
                break;
              }
              case 9: {
                message.eligibilitySearchId = reader.string();
                break;
              }
              case 10: {
                if (!message.dependents || !message.dependents.length) {
                  message.dependents = [];
                }
                message.dependents.push($root.stedi.v1.Dependent.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.CheckEligibilityRequest) {
            return object;
          }
          const message = new $root.stedi.v1.CheckEligibilityRequest();
          if (object.controlNumber != null) {
            message.controlNumber = String(object.controlNumber);
          }
          if (object.tradingPartnerServiceId != null) {
            message.tradingPartnerServiceId = String(object.tradingPartnerServiceId);
          }
          if (object.subscriber != null) {
            if (typeof object.subscriber !== "object") {
              throw new TypeError(".stedi.v1.CheckEligibilityRequest.subscriber: object expected, but got " + (typeof object.subscriber));
            }
            message.subscriber = $root.stedi.v1.Subscriber.fromObject(object.subscriber);
          }
          if (object.provider != null) {
            if (typeof object.provider !== "object") {
              throw new TypeError(".stedi.v1.CheckEligibilityRequest.provider: object expected, but got " + (typeof object.provider));
            }
            message.provider = $root.stedi.v1.Provider.fromObject(object.provider);
          }
          if (object.submitterTransactionIdentifier != null) {
            message.submitterTransactionIdentifier = String(object.submitterTransactionIdentifier);
          }
          if (object.tradingPartnerName != null) {
            message.tradingPartnerName = String(object.tradingPartnerName);
          }
          if (object.encounter != null) {
            if (typeof object.encounter !== "object") {
              throw new TypeError(".stedi.v1.CheckEligibilityRequest.encounter: object expected, but got " + (typeof object.encounter));
            }
            message.encounter = $root.stedi.v1.Encounter.fromObject(object.encounter);
          }
          if (object.externalPatientId != null) {
            message.externalPatientId = String(object.externalPatientId);
          }
          if (object.eligibilitySearchId != null) {
            message.eligibilitySearchId = String(object.eligibilitySearchId);
          }
          if (object.dependents) {
            if (!Array.isArray(object.dependents)) {
              throw new TypeError(".stedi.v1.CheckEligibilityRequest.dependents: array type expected, but got " + (typeof object.dependents))
            }
            message.dependents = new Array(object.dependents.length);
            for (let i = 0; i < object.dependents.length; ++i) {
              if (typeof object.dependents[i] !== "object") {
                throw new TypeError(".stedi.v1.CheckEligibilityRequest.dependents: object expected, but got " + (typeof object.dependents[i]));
              }
              message.dependents[i] = $root.stedi.v1.Dependent.fromObject(object.dependents[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.dependents = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.controlNumber = "";
            object.tradingPartnerServiceId = "";
            object.subscriber = null;
            object.provider = null;
            object.encounter = null;
          }
          let keys;
          if (message.controlNumber != null && message.hasOwnProperty("controlNumber")) {
            object.controlNumber = message.controlNumber;
          }
          if (message.tradingPartnerServiceId != null && message.hasOwnProperty("tradingPartnerServiceId")) {
            object.tradingPartnerServiceId = message.tradingPartnerServiceId;
          }
          if (message.subscriber != null && message.hasOwnProperty("subscriber")) {
            object.subscriber = $root.stedi.v1.Subscriber.toObject(message.subscriber, options);
          }
          if (message.provider != null && message.hasOwnProperty("provider")) {
            object.provider = $root.stedi.v1.Provider.toObject(message.provider, options);
          }
          if (message.submitterTransactionIdentifier != null && message.hasOwnProperty("submitterTransactionIdentifier")) {
            object.submitterTransactionIdentifier = message.submitterTransactionIdentifier;
          }
          if (message.tradingPartnerName != null && message.hasOwnProperty("tradingPartnerName")) {
            object.tradingPartnerName = message.tradingPartnerName;
          }
          if (message.encounter != null && message.hasOwnProperty("encounter")) {
            object.encounter = $root.stedi.v1.Encounter.toObject(message.encounter, options);
          }
          if (message.externalPatientId != null && message.hasOwnProperty("externalPatientId")) {
            object.externalPatientId = message.externalPatientId;
          }
          if (message.eligibilitySearchId != null && message.hasOwnProperty("eligibilitySearchId")) {
            object.eligibilitySearchId = message.eligibilitySearchId;
          }
          if (message.dependents && message.dependents.length) {
            object.dependents = new Array(message.dependents.length);
            for (let i = 0; i < message.dependents.length; ++i) {
              object.dependents[i] = $root.stedi.v1.Dependent.toObject(message.dependents[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.CheckEligibilityRequest";
        }
      }

      CheckEligibilityRequest.prototype.controlNumber = "";
      CheckEligibilityRequest.prototype.tradingPartnerServiceId = "";
      CheckEligibilityRequest.prototype.subscriber = null;
      CheckEligibilityRequest.prototype.provider = null;
      CheckEligibilityRequest.prototype.submitterTransactionIdentifier = null;
      CheckEligibilityRequest.prototype.tradingPartnerName = null;
      CheckEligibilityRequest.prototype.encounter = null;
      CheckEligibilityRequest.prototype.externalPatientId = null;
      CheckEligibilityRequest.prototype.eligibilitySearchId = null;
      CheckEligibilityRequest.prototype.dependents = $util.emptyArray;

      return CheckEligibilityRequest;
    })();

    v1.CheckEligibilityResponse = (() => {
      class CheckEligibilityResponse {
        constructor(properties) {
          this.subscriberTraceNumbers = [];
          this.errors = [];
          this.warnings = [];
          this.dependents = [];
          this.benefitsInformation = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CheckEligibilityResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.meta != null && Object.hasOwnProperty.call(message, "meta")) {
            $root.stedi.v1.Metadata.encode(message.meta, writer.uint32(10).fork()).ldelim();
          }
          if (message.controlNumber != null && Object.hasOwnProperty.call(message, "controlNumber")) {
            writer.uint32(18).string(message.controlNumber);
          }
          if (message.tradingPartnerServiceId != null && Object.hasOwnProperty.call(message, "tradingPartnerServiceId")) {
            writer.uint32(26).string(message.tradingPartnerServiceId);
          }
          if (message.provider != null && Object.hasOwnProperty.call(message, "provider")) {
            $root.stedi.v1.Provider.encode(message.provider, writer.uint32(34).fork()).ldelim();
          }
          if (message.subscriber != null && Object.hasOwnProperty.call(message, "subscriber")) {
            $root.stedi.v1.Subscriber.encode(message.subscriber, writer.uint32(42).fork()).ldelim();
          }
          if (message.subscriberTraceNumbers != null && Object.hasOwnProperty.call(message, "subscriberTraceNumbers")) {
            for (const element of message.subscriberTraceNumbers) {
              $root.stedi.v1.SubscriberTraceNumber.encode(element, writer.uint32(50).fork()).ldelim();
            }
          }
          if (message.payer != null && Object.hasOwnProperty.call(message, "payer")) {
            $root.stedi.v1.Payer.encode(message.payer, writer.uint32(58).fork()).ldelim();
          }
          if (message.planInformation != null && Object.hasOwnProperty.call(message, "planInformation")) {
            $root.stedi.v1.PlanInformation.encode(message.planInformation, writer.uint32(66).fork()).ldelim();
          }
          if (message.planDateInformation != null && Object.hasOwnProperty.call(message, "planDateInformation")) {
            $root.stedi.v1.PlanDateInformation.encode(message.planDateInformation, writer.uint32(74).fork()).ldelim();
          }
          if (message.errors != null && Object.hasOwnProperty.call(message, "errors")) {
            for (const element of message.errors) {
              $root.stedi.v1.AaaError.encode(element, writer.uint32(82).fork()).ldelim();
            }
          }
          if (message.warnings != null && Object.hasOwnProperty.call(message, "warnings")) {
            for (const element of message.warnings) {
              $root.stedi.v1.Warning.encode(element, writer.uint32(90).fork()).ldelim();
            }
          }
          if (message.status != null && Object.hasOwnProperty.call(message, "status")) {
            writer.uint32(98).string(message.status);
          }
          if (message.transactionSetAcknowledgement != null && Object.hasOwnProperty.call(message, "transactionSetAcknowledgement")) {
            writer.uint32(106).string(message.transactionSetAcknowledgement);
          }
          if (message.implementationTransactionSetSyntaxError != null && Object.hasOwnProperty.call(message, "implementationTransactionSetSyntaxError")) {
            writer.uint32(114).string(message.implementationTransactionSetSyntaxError);
          }
          if (message.dependents != null && Object.hasOwnProperty.call(message, "dependents")) {
            for (const element of message.dependents) {
              $root.stedi.v1.Dependent.encode(element, writer.uint32(122).fork()).ldelim();
            }
          }
          if (message.benefitsInformation != null && Object.hasOwnProperty.call(message, "benefitsInformation")) {
            for (const element of message.benefitsInformation) {
              $root.stedi.v1.Benefits.encode(element, writer.uint32(130).fork()).ldelim();
            }
          }
          if (message.inNetworkEligibilitySummary != null && Object.hasOwnProperty.call(message, "inNetworkEligibilitySummary")) {
            $root.stedi.v1.InNetworkEligibilitySummary.encode(message.inNetworkEligibilitySummary, writer.uint32(138).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CheckEligibilityResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.meta = $root.stedi.v1.Metadata.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.controlNumber = reader.string();
                break;
              }
              case 3: {
                message.tradingPartnerServiceId = reader.string();
                break;
              }
              case 4: {
                message.provider = $root.stedi.v1.Provider.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.subscriber = $root.stedi.v1.Subscriber.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                if (!message.subscriberTraceNumbers || !message.subscriberTraceNumbers.length) {
                  message.subscriberTraceNumbers = [];
                }
                message.subscriberTraceNumbers.push($root.stedi.v1.SubscriberTraceNumber.decode(reader, reader.uint32()));
                break;
              }
              case 7: {
                message.payer = $root.stedi.v1.Payer.decode(reader, reader.uint32());
                break;
              }
              case 8: {
                message.planInformation = $root.stedi.v1.PlanInformation.decode(reader, reader.uint32());
                break;
              }
              case 9: {
                message.planDateInformation = $root.stedi.v1.PlanDateInformation.decode(reader, reader.uint32());
                break;
              }
              case 10: {
                if (!message.errors || !message.errors.length) {
                  message.errors = [];
                }
                message.errors.push($root.stedi.v1.AaaError.decode(reader, reader.uint32()));
                break;
              }
              case 11: {
                if (!message.warnings || !message.warnings.length) {
                  message.warnings = [];
                }
                message.warnings.push($root.stedi.v1.Warning.decode(reader, reader.uint32()));
                break;
              }
              case 12: {
                message.status = reader.string();
                break;
              }
              case 13: {
                message.transactionSetAcknowledgement = reader.string();
                break;
              }
              case 14: {
                message.implementationTransactionSetSyntaxError = reader.string();
                break;
              }
              case 15: {
                if (!message.dependents || !message.dependents.length) {
                  message.dependents = [];
                }
                message.dependents.push($root.stedi.v1.Dependent.decode(reader, reader.uint32()));
                break;
              }
              case 16: {
                if (!message.benefitsInformation || !message.benefitsInformation.length) {
                  message.benefitsInformation = [];
                }
                message.benefitsInformation.push($root.stedi.v1.Benefits.decode(reader, reader.uint32()));
                break;
              }
              case 17: {
                message.inNetworkEligibilitySummary = $root.stedi.v1.InNetworkEligibilitySummary.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.CheckEligibilityResponse) {
            return object;
          }
          const message = new $root.stedi.v1.CheckEligibilityResponse();
          if (object.meta != null) {
            if (typeof object.meta !== "object") {
              throw new TypeError(".stedi.v1.CheckEligibilityResponse.meta: object expected, but got " + (typeof object.meta));
            }
            message.meta = $root.stedi.v1.Metadata.fromObject(object.meta);
          }
          if (object.controlNumber != null) {
            message.controlNumber = String(object.controlNumber);
          }
          if (object.tradingPartnerServiceId != null) {
            message.tradingPartnerServiceId = String(object.tradingPartnerServiceId);
          }
          if (object.provider != null) {
            if (typeof object.provider !== "object") {
              throw new TypeError(".stedi.v1.CheckEligibilityResponse.provider: object expected, but got " + (typeof object.provider));
            }
            message.provider = $root.stedi.v1.Provider.fromObject(object.provider);
          }
          if (object.subscriber != null) {
            if (typeof object.subscriber !== "object") {
              throw new TypeError(".stedi.v1.CheckEligibilityResponse.subscriber: object expected, but got " + (typeof object.subscriber));
            }
            message.subscriber = $root.stedi.v1.Subscriber.fromObject(object.subscriber);
          }
          if (object.subscriberTraceNumbers) {
            if (!Array.isArray(object.subscriberTraceNumbers)) {
              throw new TypeError(".stedi.v1.CheckEligibilityResponse.subscriberTraceNumbers: array type expected, but got " + (typeof object.subscriberTraceNumbers))
            }
            message.subscriberTraceNumbers = new Array(object.subscriberTraceNumbers.length);
            for (let i = 0; i < object.subscriberTraceNumbers.length; ++i) {
              if (typeof object.subscriberTraceNumbers[i] !== "object") {
                throw new TypeError(".stedi.v1.CheckEligibilityResponse.subscriberTraceNumbers: object expected, but got " + (typeof object.subscriberTraceNumbers[i]));
              }
              message.subscriberTraceNumbers[i] = $root.stedi.v1.SubscriberTraceNumber.fromObject(object.subscriberTraceNumbers[i]);
            }
          }
          if (object.payer != null) {
            if (typeof object.payer !== "object") {
              throw new TypeError(".stedi.v1.CheckEligibilityResponse.payer: object expected, but got " + (typeof object.payer));
            }
            message.payer = $root.stedi.v1.Payer.fromObject(object.payer);
          }
          if (object.planInformation != null) {
            if (typeof object.planInformation !== "object") {
              throw new TypeError(".stedi.v1.CheckEligibilityResponse.planInformation: object expected, but got " + (typeof object.planInformation));
            }
            message.planInformation = $root.stedi.v1.PlanInformation.fromObject(object.planInformation);
          }
          if (object.planDateInformation != null) {
            if (typeof object.planDateInformation !== "object") {
              throw new TypeError(".stedi.v1.CheckEligibilityResponse.planDateInformation: object expected, but got " + (typeof object.planDateInformation));
            }
            message.planDateInformation = $root.stedi.v1.PlanDateInformation.fromObject(object.planDateInformation);
          }
          if (object.errors) {
            if (!Array.isArray(object.errors)) {
              throw new TypeError(".stedi.v1.CheckEligibilityResponse.errors: array type expected, but got " + (typeof object.errors))
            }
            message.errors = new Array(object.errors.length);
            for (let i = 0; i < object.errors.length; ++i) {
              if (typeof object.errors[i] !== "object") {
                throw new TypeError(".stedi.v1.CheckEligibilityResponse.errors: object expected, but got " + (typeof object.errors[i]));
              }
              message.errors[i] = $root.stedi.v1.AaaError.fromObject(object.errors[i]);
            }
          }
          if (object.warnings) {
            if (!Array.isArray(object.warnings)) {
              throw new TypeError(".stedi.v1.CheckEligibilityResponse.warnings: array type expected, but got " + (typeof object.warnings))
            }
            message.warnings = new Array(object.warnings.length);
            for (let i = 0; i < object.warnings.length; ++i) {
              if (typeof object.warnings[i] !== "object") {
                throw new TypeError(".stedi.v1.CheckEligibilityResponse.warnings: object expected, but got " + (typeof object.warnings[i]));
              }
              message.warnings[i] = $root.stedi.v1.Warning.fromObject(object.warnings[i]);
            }
          }
          if (object.status != null) {
            message.status = String(object.status);
          }
          if (object.transactionSetAcknowledgement != null) {
            message.transactionSetAcknowledgement = String(object.transactionSetAcknowledgement);
          }
          if (object.implementationTransactionSetSyntaxError != null) {
            message.implementationTransactionSetSyntaxError = String(object.implementationTransactionSetSyntaxError);
          }
          if (object.dependents) {
            if (!Array.isArray(object.dependents)) {
              throw new TypeError(".stedi.v1.CheckEligibilityResponse.dependents: array type expected, but got " + (typeof object.dependents))
            }
            message.dependents = new Array(object.dependents.length);
            for (let i = 0; i < object.dependents.length; ++i) {
              if (typeof object.dependents[i] !== "object") {
                throw new TypeError(".stedi.v1.CheckEligibilityResponse.dependents: object expected, but got " + (typeof object.dependents[i]));
              }
              message.dependents[i] = $root.stedi.v1.Dependent.fromObject(object.dependents[i]);
            }
          }
          if (object.benefitsInformation) {
            if (!Array.isArray(object.benefitsInformation)) {
              throw new TypeError(".stedi.v1.CheckEligibilityResponse.benefitsInformation: array type expected, but got " + (typeof object.benefitsInformation))
            }
            message.benefitsInformation = new Array(object.benefitsInformation.length);
            for (let i = 0; i < object.benefitsInformation.length; ++i) {
              if (typeof object.benefitsInformation[i] !== "object") {
                throw new TypeError(".stedi.v1.CheckEligibilityResponse.benefitsInformation: object expected, but got " + (typeof object.benefitsInformation[i]));
              }
              message.benefitsInformation[i] = $root.stedi.v1.Benefits.fromObject(object.benefitsInformation[i]);
            }
          }
          if (object.inNetworkEligibilitySummary != null) {
            if (typeof object.inNetworkEligibilitySummary !== "object") {
              throw new TypeError(".stedi.v1.CheckEligibilityResponse.inNetworkEligibilitySummary: object expected, but got " + (typeof object.inNetworkEligibilitySummary));
            }
            message.inNetworkEligibilitySummary = $root.stedi.v1.InNetworkEligibilitySummary.fromObject(object.inNetworkEligibilitySummary);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.subscriberTraceNumbers = [];
            object.errors = [];
            object.warnings = [];
            object.dependents = [];
            object.benefitsInformation = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.inNetworkEligibilitySummary = null;
          }
          let keys;
          if (message.meta != null && message.hasOwnProperty("meta")) {
            object.meta = $root.stedi.v1.Metadata.toObject(message.meta, options);
          }
          if (message.controlNumber != null && message.hasOwnProperty("controlNumber")) {
            object.controlNumber = message.controlNumber;
          }
          if (message.tradingPartnerServiceId != null && message.hasOwnProperty("tradingPartnerServiceId")) {
            object.tradingPartnerServiceId = message.tradingPartnerServiceId;
          }
          if (message.provider != null && message.hasOwnProperty("provider")) {
            object.provider = $root.stedi.v1.Provider.toObject(message.provider, options);
          }
          if (message.subscriber != null && message.hasOwnProperty("subscriber")) {
            object.subscriber = $root.stedi.v1.Subscriber.toObject(message.subscriber, options);
          }
          if (message.subscriberTraceNumbers && message.subscriberTraceNumbers.length) {
            object.subscriberTraceNumbers = new Array(message.subscriberTraceNumbers.length);
            for (let i = 0; i < message.subscriberTraceNumbers.length; ++i) {
              object.subscriberTraceNumbers[i] = $root.stedi.v1.SubscriberTraceNumber.toObject(message.subscriberTraceNumbers[i], options);
            }
          }
          if (message.payer != null && message.hasOwnProperty("payer")) {
            object.payer = $root.stedi.v1.Payer.toObject(message.payer, options);
          }
          if (message.planInformation != null && message.hasOwnProperty("planInformation")) {
            object.planInformation = $root.stedi.v1.PlanInformation.toObject(message.planInformation, options);
          }
          if (message.planDateInformation != null && message.hasOwnProperty("planDateInformation")) {
            object.planDateInformation = $root.stedi.v1.PlanDateInformation.toObject(message.planDateInformation, options);
          }
          if (message.errors && message.errors.length) {
            object.errors = new Array(message.errors.length);
            for (let i = 0; i < message.errors.length; ++i) {
              object.errors[i] = $root.stedi.v1.AaaError.toObject(message.errors[i], options);
            }
          }
          if (message.warnings && message.warnings.length) {
            object.warnings = new Array(message.warnings.length);
            for (let i = 0; i < message.warnings.length; ++i) {
              object.warnings[i] = $root.stedi.v1.Warning.toObject(message.warnings[i], options);
            }
          }
          if (message.status != null && message.hasOwnProperty("status")) {
            object.status = message.status;
          }
          if (message.transactionSetAcknowledgement != null && message.hasOwnProperty("transactionSetAcknowledgement")) {
            object.transactionSetAcknowledgement = message.transactionSetAcknowledgement;
          }
          if (message.implementationTransactionSetSyntaxError != null && message.hasOwnProperty("implementationTransactionSetSyntaxError")) {
            object.implementationTransactionSetSyntaxError = message.implementationTransactionSetSyntaxError;
          }
          if (message.dependents && message.dependents.length) {
            object.dependents = new Array(message.dependents.length);
            for (let i = 0; i < message.dependents.length; ++i) {
              object.dependents[i] = $root.stedi.v1.Dependent.toObject(message.dependents[i], options);
            }
          }
          if (message.benefitsInformation && message.benefitsInformation.length) {
            object.benefitsInformation = new Array(message.benefitsInformation.length);
            for (let i = 0; i < message.benefitsInformation.length; ++i) {
              object.benefitsInformation[i] = $root.stedi.v1.Benefits.toObject(message.benefitsInformation[i], options);
            }
          }
          if (message.inNetworkEligibilitySummary != null && message.hasOwnProperty("inNetworkEligibilitySummary")) {
            object.inNetworkEligibilitySummary = $root.stedi.v1.InNetworkEligibilitySummary.toObject(message.inNetworkEligibilitySummary, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.CheckEligibilityResponse";
        }
      }

      CheckEligibilityResponse.prototype.meta = null;
      CheckEligibilityResponse.prototype.controlNumber = null;
      CheckEligibilityResponse.prototype.tradingPartnerServiceId = null;
      CheckEligibilityResponse.prototype.provider = null;
      CheckEligibilityResponse.prototype.subscriber = null;
      CheckEligibilityResponse.prototype.subscriberTraceNumbers = $util.emptyArray;
      CheckEligibilityResponse.prototype.payer = null;
      CheckEligibilityResponse.prototype.planInformation = null;
      CheckEligibilityResponse.prototype.planDateInformation = null;
      CheckEligibilityResponse.prototype.errors = $util.emptyArray;
      CheckEligibilityResponse.prototype.warnings = $util.emptyArray;
      CheckEligibilityResponse.prototype.status = null;
      CheckEligibilityResponse.prototype.transactionSetAcknowledgement = null;
      CheckEligibilityResponse.prototype.implementationTransactionSetSyntaxError = null;
      CheckEligibilityResponse.prototype.dependents = $util.emptyArray;
      CheckEligibilityResponse.prototype.benefitsInformation = $util.emptyArray;
      CheckEligibilityResponse.prototype.inNetworkEligibilitySummary = null;

      return CheckEligibilityResponse;
    })();

    v1.Subscriber = (() => {
      class Subscriber {
        constructor(properties) {
          this.healthCareCodeInformation = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Subscriber(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.memberId != null && Object.hasOwnProperty.call(message, "memberId")) {
            writer.uint32(10).string(message.memberId);
          }
          if (message.dateOfBirth != null && Object.hasOwnProperty.call(message, "dateOfBirth")) {
            values$1.v1.Date.encode(message.dateOfBirth, writer.uint32(18).fork()).ldelim();
          }
          if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName")) {
            writer.uint32(26).string(message.firstName);
          }
          if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName")) {
            writer.uint32(34).string(message.middleName);
          }
          if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName")) {
            writer.uint32(42).string(message.lastName);
          }
          if (message.suffix != null && Object.hasOwnProperty.call(message, "suffix")) {
            writer.uint32(50).string(message.suffix);
          }
          if (message.gender != null && Object.hasOwnProperty.call(message, "gender")) {
            writer.uint32(58).string(message.gender);
          }
          if (message.ssn != null && Object.hasOwnProperty.call(message, "ssn")) {
            writer.uint32(66).string(message.ssn);
          }
          if (message.groupNumber != null && Object.hasOwnProperty.call(message, "groupNumber")) {
            writer.uint32(74).string(message.groupNumber);
          }
          if (message.idCard != null && Object.hasOwnProperty.call(message, "idCard")) {
            writer.uint32(82).string(message.idCard);
          }
          if (message.providerCode != null && Object.hasOwnProperty.call(message, "providerCode")) {
            writer.uint32(90).string(message.providerCode);
          }
          if (message.referenceIdentificationQualifier != null && Object.hasOwnProperty.call(message, "referenceIdentificationQualifier")) {
            writer.uint32(98).string(message.referenceIdentificationQualifier);
          }
          if (message.providerIdentifier != null && Object.hasOwnProperty.call(message, "providerIdentifier")) {
            writer.uint32(106).string(message.providerIdentifier);
          }
          if (message.healthCareCodeInformation != null && Object.hasOwnProperty.call(message, "healthCareCodeInformation")) {
            for (const element of message.healthCareCodeInformation) {
              $root.stedi.v1.HealthCareCodeInformation.encode(element, writer.uint32(114).fork()).ldelim();
            }
          }
          if (message.address != null && Object.hasOwnProperty.call(message, "address")) {
            $root.stedi.v1.Address.encode(message.address, writer.uint32(122).fork()).ldelim();
          }
          if (message.additionalIdentification != null && Object.hasOwnProperty.call(message, "additionalIdentification")) {
            $root.stedi.v1.AdditionalIdentification.encode(message.additionalIdentification, writer.uint32(130).fork()).ldelim();
          }
          if (message.birthSequenceNumber != null && Object.hasOwnProperty.call(message, "birthSequenceNumber")) {
            writer.uint32(138).string(message.birthSequenceNumber);
          }
          if (message.caseNumber != null && Object.hasOwnProperty.call(message, "caseNumber")) {
            writer.uint32(146).string(message.caseNumber);
          }
          if (message.medicaidRecipientIdentificationNumber != null && Object.hasOwnProperty.call(message, "medicaidRecipientIdentificationNumber")) {
            writer.uint32(154).string(message.medicaidRecipientIdentificationNumber);
          }
          if (message.spendDownAmount != null && Object.hasOwnProperty.call(message, "spendDownAmount")) {
            writer.uint32(161).double(message.spendDownAmount);
          }
          if (message.spendDownTotalBilledAmount != null && Object.hasOwnProperty.call(message, "spendDownTotalBilledAmount")) {
            writer.uint32(169).double(message.spendDownTotalBilledAmount);
          }
          if (message.coverageLevelCode != null && Object.hasOwnProperty.call(message, "coverageLevelCode")) {
            writer.uint32(178).string(message.coverageLevelCode);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Subscriber();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.memberId = reader.string();
                break;
              }
              case 2: {
                message.dateOfBirth = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.firstName = reader.string();
                break;
              }
              case 4: {
                message.middleName = reader.string();
                break;
              }
              case 5: {
                message.lastName = reader.string();
                break;
              }
              case 6: {
                message.suffix = reader.string();
                break;
              }
              case 7: {
                message.gender = reader.string();
                break;
              }
              case 8: {
                message.ssn = reader.string();
                break;
              }
              case 9: {
                message.groupNumber = reader.string();
                break;
              }
              case 10: {
                message.idCard = reader.string();
                break;
              }
              case 11: {
                message.providerCode = reader.string();
                break;
              }
              case 12: {
                message.referenceIdentificationQualifier = reader.string();
                break;
              }
              case 13: {
                message.providerIdentifier = reader.string();
                break;
              }
              case 14: {
                if (!message.healthCareCodeInformation || !message.healthCareCodeInformation.length) {
                  message.healthCareCodeInformation = [];
                }
                message.healthCareCodeInformation.push($root.stedi.v1.HealthCareCodeInformation.decode(reader, reader.uint32()));
                break;
              }
              case 15: {
                message.address = $root.stedi.v1.Address.decode(reader, reader.uint32());
                break;
              }
              case 16: {
                message.additionalIdentification = $root.stedi.v1.AdditionalIdentification.decode(reader, reader.uint32());
                break;
              }
              case 17: {
                message.birthSequenceNumber = reader.string();
                break;
              }
              case 18: {
                message.caseNumber = reader.string();
                break;
              }
              case 19: {
                message.medicaidRecipientIdentificationNumber = reader.string();
                break;
              }
              case 20: {
                message.spendDownAmount = reader.double();
                break;
              }
              case 21: {
                message.spendDownTotalBilledAmount = reader.double();
                break;
              }
              case 22: {
                message.coverageLevelCode = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.Subscriber) {
            return object;
          }
          const message = new $root.stedi.v1.Subscriber();
          if (object.memberId != null) {
            message.memberId = String(object.memberId);
          }
          if (object.dateOfBirth != null) {
            if (typeof object.dateOfBirth !== "object") {
              throw new TypeError(".stedi.v1.Subscriber.dateOfBirth: object expected, but got " + (typeof object.dateOfBirth));
            }
            message.dateOfBirth = values$1.v1.Date.fromObject(object.dateOfBirth);
          }
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.middleName != null) {
            message.middleName = String(object.middleName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.suffix != null) {
            message.suffix = String(object.suffix);
          }
          if (object.gender != null) {
            message.gender = String(object.gender);
          }
          if (object.ssn != null) {
            message.ssn = String(object.ssn);
          }
          if (object.groupNumber != null) {
            message.groupNumber = String(object.groupNumber);
          }
          if (object.idCard != null) {
            message.idCard = String(object.idCard);
          }
          if (object.providerCode != null) {
            message.providerCode = String(object.providerCode);
          }
          if (object.referenceIdentificationQualifier != null) {
            message.referenceIdentificationQualifier = String(object.referenceIdentificationQualifier);
          }
          if (object.providerIdentifier != null) {
            message.providerIdentifier = String(object.providerIdentifier);
          }
          if (object.healthCareCodeInformation) {
            if (!Array.isArray(object.healthCareCodeInformation)) {
              throw new TypeError(".stedi.v1.Subscriber.healthCareCodeInformation: array type expected, but got " + (typeof object.healthCareCodeInformation))
            }
            message.healthCareCodeInformation = new Array(object.healthCareCodeInformation.length);
            for (let i = 0; i < object.healthCareCodeInformation.length; ++i) {
              if (typeof object.healthCareCodeInformation[i] !== "object") {
                throw new TypeError(".stedi.v1.Subscriber.healthCareCodeInformation: object expected, but got " + (typeof object.healthCareCodeInformation[i]));
              }
              message.healthCareCodeInformation[i] = $root.stedi.v1.HealthCareCodeInformation.fromObject(object.healthCareCodeInformation[i]);
            }
          }
          if (object.address != null) {
            if (typeof object.address !== "object") {
              throw new TypeError(".stedi.v1.Subscriber.address: object expected, but got " + (typeof object.address));
            }
            message.address = $root.stedi.v1.Address.fromObject(object.address);
          }
          if (object.additionalIdentification != null) {
            if (typeof object.additionalIdentification !== "object") {
              throw new TypeError(".stedi.v1.Subscriber.additionalIdentification: object expected, but got " + (typeof object.additionalIdentification));
            }
            message.additionalIdentification = $root.stedi.v1.AdditionalIdentification.fromObject(object.additionalIdentification);
          }
          if (object.birthSequenceNumber != null) {
            message.birthSequenceNumber = String(object.birthSequenceNumber);
          }
          if (object.caseNumber != null) {
            message.caseNumber = String(object.caseNumber);
          }
          if (object.medicaidRecipientIdentificationNumber != null) {
            message.medicaidRecipientIdentificationNumber = String(object.medicaidRecipientIdentificationNumber);
          }
          if (object.spendDownAmount != null) {
            message.spendDownAmount = Number(object.spendDownAmount);
          }
          if (object.spendDownTotalBilledAmount != null) {
            message.spendDownTotalBilledAmount = Number(object.spendDownTotalBilledAmount);
          }
          if (object.coverageLevelCode != null) {
            message.coverageLevelCode = String(object.coverageLevelCode);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.healthCareCodeInformation = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.memberId != null && message.hasOwnProperty("memberId")) {
            object.memberId = message.memberId;
          }
          if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth")) {
            object.dateOfBirth = values$1.v1.Date.toObject(message.dateOfBirth, options);
          }
          if (message.firstName != null && message.hasOwnProperty("firstName")) {
            object.firstName = message.firstName;
          }
          if (message.middleName != null && message.hasOwnProperty("middleName")) {
            object.middleName = message.middleName;
          }
          if (message.lastName != null && message.hasOwnProperty("lastName")) {
            object.lastName = message.lastName;
          }
          if (message.suffix != null && message.hasOwnProperty("suffix")) {
            object.suffix = message.suffix;
          }
          if (message.gender != null && message.hasOwnProperty("gender")) {
            object.gender = message.gender;
          }
          if (message.ssn != null && message.hasOwnProperty("ssn")) {
            object.ssn = message.ssn;
          }
          if (message.groupNumber != null && message.hasOwnProperty("groupNumber")) {
            object.groupNumber = message.groupNumber;
          }
          if (message.idCard != null && message.hasOwnProperty("idCard")) {
            object.idCard = message.idCard;
          }
          if (message.providerCode != null && message.hasOwnProperty("providerCode")) {
            object.providerCode = message.providerCode;
          }
          if (message.referenceIdentificationQualifier != null && message.hasOwnProperty("referenceIdentificationQualifier")) {
            object.referenceIdentificationQualifier = message.referenceIdentificationQualifier;
          }
          if (message.providerIdentifier != null && message.hasOwnProperty("providerIdentifier")) {
            object.providerIdentifier = message.providerIdentifier;
          }
          if (message.healthCareCodeInformation && message.healthCareCodeInformation.length) {
            object.healthCareCodeInformation = new Array(message.healthCareCodeInformation.length);
            for (let i = 0; i < message.healthCareCodeInformation.length; ++i) {
              object.healthCareCodeInformation[i] = $root.stedi.v1.HealthCareCodeInformation.toObject(message.healthCareCodeInformation[i], options);
            }
          }
          if (message.address != null && message.hasOwnProperty("address")) {
            object.address = $root.stedi.v1.Address.toObject(message.address, options);
          }
          if (message.additionalIdentification != null && message.hasOwnProperty("additionalIdentification")) {
            object.additionalIdentification = $root.stedi.v1.AdditionalIdentification.toObject(message.additionalIdentification, options);
          }
          if (message.birthSequenceNumber != null && message.hasOwnProperty("birthSequenceNumber")) {
            object.birthSequenceNumber = message.birthSequenceNumber;
          }
          if (message.caseNumber != null && message.hasOwnProperty("caseNumber")) {
            object.caseNumber = message.caseNumber;
          }
          if (message.medicaidRecipientIdentificationNumber != null && message.hasOwnProperty("medicaidRecipientIdentificationNumber")) {
            object.medicaidRecipientIdentificationNumber = message.medicaidRecipientIdentificationNumber;
          }
          if (message.spendDownAmount != null && message.hasOwnProperty("spendDownAmount")) {
            object.spendDownAmount = options.json && !isFinite(message.spendDownAmount) ? String(message.spendDownAmount) : message.spendDownAmount;
          }
          if (message.spendDownTotalBilledAmount != null && message.hasOwnProperty("spendDownTotalBilledAmount")) {
            object.spendDownTotalBilledAmount = options.json && !isFinite(message.spendDownTotalBilledAmount) ? String(message.spendDownTotalBilledAmount) : message.spendDownTotalBilledAmount;
          }
          if (message.coverageLevelCode != null && message.hasOwnProperty("coverageLevelCode")) {
            object.coverageLevelCode = message.coverageLevelCode;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.Subscriber";
        }
      }

      Subscriber.prototype.memberId = null;
      Subscriber.prototype.dateOfBirth = null;
      Subscriber.prototype.firstName = null;
      Subscriber.prototype.middleName = null;
      Subscriber.prototype.lastName = null;
      Subscriber.prototype.suffix = null;
      Subscriber.prototype.gender = null;
      Subscriber.prototype.ssn = null;
      Subscriber.prototype.groupNumber = null;
      Subscriber.prototype.idCard = null;
      Subscriber.prototype.providerCode = null;
      Subscriber.prototype.referenceIdentificationQualifier = null;
      Subscriber.prototype.providerIdentifier = null;
      Subscriber.prototype.healthCareCodeInformation = $util.emptyArray;
      Subscriber.prototype.address = null;
      Subscriber.prototype.additionalIdentification = null;
      Subscriber.prototype.birthSequenceNumber = null;
      Subscriber.prototype.caseNumber = null;
      Subscriber.prototype.medicaidRecipientIdentificationNumber = null;
      Subscriber.prototype.spendDownAmount = null;
      Subscriber.prototype.spendDownTotalBilledAmount = null;
      Subscriber.prototype.coverageLevelCode = null;

      return Subscriber;
    })();

    v1.HealthCareCodeInformation = (() => {
      class HealthCareCodeInformation {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new HealthCareCodeInformation(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.diagnosisTypeCode != null && Object.hasOwnProperty.call(message, "diagnosisTypeCode")) {
            writer.uint32(10).string(message.diagnosisTypeCode);
          }
          if (message.diagnosisCode != null && Object.hasOwnProperty.call(message, "diagnosisCode")) {
            writer.uint32(18).string(message.diagnosisCode);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new HealthCareCodeInformation();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.diagnosisTypeCode = reader.string();
                break;
              }
              case 2: {
                message.diagnosisCode = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.HealthCareCodeInformation) {
            return object;
          }
          const message = new $root.stedi.v1.HealthCareCodeInformation();
          if (object.diagnosisTypeCode != null) {
            message.diagnosisTypeCode = String(object.diagnosisTypeCode);
          }
          if (object.diagnosisCode != null) {
            message.diagnosisCode = String(object.diagnosisCode);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.diagnosisTypeCode != null && message.hasOwnProperty("diagnosisTypeCode")) {
            object.diagnosisTypeCode = message.diagnosisTypeCode;
          }
          if (message.diagnosisCode != null && message.hasOwnProperty("diagnosisCode")) {
            object.diagnosisCode = message.diagnosisCode;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.HealthCareCodeInformation";
        }
      }

      HealthCareCodeInformation.prototype.diagnosisTypeCode = null;
      HealthCareCodeInformation.prototype.diagnosisCode = null;

      return HealthCareCodeInformation;
    })();

    v1.Address = (() => {
      class Address {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Address(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.address1 != null && Object.hasOwnProperty.call(message, "address1")) {
            writer.uint32(10).string(message.address1);
          }
          if (message.address2 != null && Object.hasOwnProperty.call(message, "address2")) {
            writer.uint32(18).string(message.address2);
          }
          if (message.city != null && Object.hasOwnProperty.call(message, "city")) {
            writer.uint32(26).string(message.city);
          }
          if (message.state != null && Object.hasOwnProperty.call(message, "state")) {
            writer.uint32(34).string(message.state);
          }
          if (message.postalCode != null && Object.hasOwnProperty.call(message, "postalCode")) {
            writer.uint32(42).string(message.postalCode);
          }
          if (message.countryCode != null && Object.hasOwnProperty.call(message, "countryCode")) {
            writer.uint32(50).string(message.countryCode);
          }
          if (message.countrySubDivisionCode != null && Object.hasOwnProperty.call(message, "countrySubDivisionCode")) {
            writer.uint32(58).string(message.countrySubDivisionCode);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Address();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.address1 = reader.string();
                break;
              }
              case 2: {
                message.address2 = reader.string();
                break;
              }
              case 3: {
                message.city = reader.string();
                break;
              }
              case 4: {
                message.state = reader.string();
                break;
              }
              case 5: {
                message.postalCode = reader.string();
                break;
              }
              case 6: {
                message.countryCode = reader.string();
                break;
              }
              case 7: {
                message.countrySubDivisionCode = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.Address) {
            return object;
          }
          const message = new $root.stedi.v1.Address();
          if (object.address1 != null) {
            message.address1 = String(object.address1);
          }
          if (object.address2 != null) {
            message.address2 = String(object.address2);
          }
          if (object.city != null) {
            message.city = String(object.city);
          }
          if (object.state != null) {
            message.state = String(object.state);
          }
          if (object.postalCode != null) {
            message.postalCode = String(object.postalCode);
          }
          if (object.countryCode != null) {
            message.countryCode = String(object.countryCode);
          }
          if (object.countrySubDivisionCode != null) {
            message.countrySubDivisionCode = String(object.countrySubDivisionCode);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.address1 = "";
            object.city = "";
            object.postalCode = "";
            object.countryCode = "";
          }
          let keys;
          if (message.address1 != null && message.hasOwnProperty("address1")) {
            object.address1 = message.address1;
          }
          if (message.address2 != null && message.hasOwnProperty("address2")) {
            object.address2 = message.address2;
          }
          if (message.city != null && message.hasOwnProperty("city")) {
            object.city = message.city;
          }
          if (message.state != null && message.hasOwnProperty("state")) {
            object.state = message.state;
          }
          if (message.postalCode != null && message.hasOwnProperty("postalCode")) {
            object.postalCode = message.postalCode;
          }
          if (message.countryCode != null && message.hasOwnProperty("countryCode")) {
            object.countryCode = message.countryCode;
          }
          if (message.countrySubDivisionCode != null && message.hasOwnProperty("countrySubDivisionCode")) {
            object.countrySubDivisionCode = message.countrySubDivisionCode;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.Address";
        }
      }

      Address.prototype.address1 = "";
      Address.prototype.address2 = null;
      Address.prototype.city = "";
      Address.prototype.state = null;
      Address.prototype.postalCode = "";
      Address.prototype.countryCode = "";
      Address.prototype.countrySubDivisionCode = null;

      return Address;
    })();

    v1.AdditionalIdentification = (() => {
      class AdditionalIdentification {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new AdditionalIdentification(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.planNumber != null && Object.hasOwnProperty.call(message, "planNumber")) {
            writer.uint32(10).string(message.planNumber);
          }
          if (message.policyNumber != null && Object.hasOwnProperty.call(message, "policyNumber")) {
            writer.uint32(18).string(message.policyNumber);
          }
          if (message.memberIdentificationNumber != null && Object.hasOwnProperty.call(message, "memberIdentificationNumber")) {
            writer.uint32(26).string(message.memberIdentificationNumber);
          }
          if (message.contractNumber != null && Object.hasOwnProperty.call(message, "contractNumber")) {
            writer.uint32(34).string(message.contractNumber);
          }
          if (message.medicalRecordIdentificationNumber != null && Object.hasOwnProperty.call(message, "medicalRecordIdentificationNumber")) {
            writer.uint32(42).string(message.medicalRecordIdentificationNumber);
          }
          if (message.patientAccountNumber != null && Object.hasOwnProperty.call(message, "patientAccountNumber")) {
            writer.uint32(50).string(message.patientAccountNumber);
          }
          if (message.healthInsuranceClaimNumber != null && Object.hasOwnProperty.call(message, "healthInsuranceClaimNumber")) {
            writer.uint32(58).string(message.healthInsuranceClaimNumber);
          }
          if (message.identificationCardSerialNumber != null && Object.hasOwnProperty.call(message, "identificationCardSerialNumber")) {
            writer.uint32(66).string(message.identificationCardSerialNumber);
          }
          if (message.insurancePolicyNumber != null && Object.hasOwnProperty.call(message, "insurancePolicyNumber")) {
            writer.uint32(74).string(message.insurancePolicyNumber);
          }
          if (message.planNetworkIdentificationNumber != null && Object.hasOwnProperty.call(message, "planNetworkIdentificationNumber")) {
            writer.uint32(82).string(message.planNetworkIdentificationNumber);
          }
          if (message.agencyClaimNumber != null && Object.hasOwnProperty.call(message, "agencyClaimNumber")) {
            writer.uint32(90).string(message.agencyClaimNumber);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new AdditionalIdentification();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.planNumber = reader.string();
                break;
              }
              case 2: {
                message.policyNumber = reader.string();
                break;
              }
              case 3: {
                message.memberIdentificationNumber = reader.string();
                break;
              }
              case 4: {
                message.contractNumber = reader.string();
                break;
              }
              case 5: {
                message.medicalRecordIdentificationNumber = reader.string();
                break;
              }
              case 6: {
                message.patientAccountNumber = reader.string();
                break;
              }
              case 7: {
                message.healthInsuranceClaimNumber = reader.string();
                break;
              }
              case 8: {
                message.identificationCardSerialNumber = reader.string();
                break;
              }
              case 9: {
                message.insurancePolicyNumber = reader.string();
                break;
              }
              case 10: {
                message.planNetworkIdentificationNumber = reader.string();
                break;
              }
              case 11: {
                message.agencyClaimNumber = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.AdditionalIdentification) {
            return object;
          }
          const message = new $root.stedi.v1.AdditionalIdentification();
          if (object.planNumber != null) {
            message.planNumber = String(object.planNumber);
          }
          if (object.policyNumber != null) {
            message.policyNumber = String(object.policyNumber);
          }
          if (object.memberIdentificationNumber != null) {
            message.memberIdentificationNumber = String(object.memberIdentificationNumber);
          }
          if (object.contractNumber != null) {
            message.contractNumber = String(object.contractNumber);
          }
          if (object.medicalRecordIdentificationNumber != null) {
            message.medicalRecordIdentificationNumber = String(object.medicalRecordIdentificationNumber);
          }
          if (object.patientAccountNumber != null) {
            message.patientAccountNumber = String(object.patientAccountNumber);
          }
          if (object.healthInsuranceClaimNumber != null) {
            message.healthInsuranceClaimNumber = String(object.healthInsuranceClaimNumber);
          }
          if (object.identificationCardSerialNumber != null) {
            message.identificationCardSerialNumber = String(object.identificationCardSerialNumber);
          }
          if (object.insurancePolicyNumber != null) {
            message.insurancePolicyNumber = String(object.insurancePolicyNumber);
          }
          if (object.planNetworkIdentificationNumber != null) {
            message.planNetworkIdentificationNumber = String(object.planNetworkIdentificationNumber);
          }
          if (object.agencyClaimNumber != null) {
            message.agencyClaimNumber = String(object.agencyClaimNumber);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.planNumber != null && message.hasOwnProperty("planNumber")) {
            object.planNumber = message.planNumber;
          }
          if (message.policyNumber != null && message.hasOwnProperty("policyNumber")) {
            object.policyNumber = message.policyNumber;
          }
          if (message.memberIdentificationNumber != null && message.hasOwnProperty("memberIdentificationNumber")) {
            object.memberIdentificationNumber = message.memberIdentificationNumber;
          }
          if (message.contractNumber != null && message.hasOwnProperty("contractNumber")) {
            object.contractNumber = message.contractNumber;
          }
          if (message.medicalRecordIdentificationNumber != null && message.hasOwnProperty("medicalRecordIdentificationNumber")) {
            object.medicalRecordIdentificationNumber = message.medicalRecordIdentificationNumber;
          }
          if (message.patientAccountNumber != null && message.hasOwnProperty("patientAccountNumber")) {
            object.patientAccountNumber = message.patientAccountNumber;
          }
          if (message.healthInsuranceClaimNumber != null && message.hasOwnProperty("healthInsuranceClaimNumber")) {
            object.healthInsuranceClaimNumber = message.healthInsuranceClaimNumber;
          }
          if (message.identificationCardSerialNumber != null && message.hasOwnProperty("identificationCardSerialNumber")) {
            object.identificationCardSerialNumber = message.identificationCardSerialNumber;
          }
          if (message.insurancePolicyNumber != null && message.hasOwnProperty("insurancePolicyNumber")) {
            object.insurancePolicyNumber = message.insurancePolicyNumber;
          }
          if (message.planNetworkIdentificationNumber != null && message.hasOwnProperty("planNetworkIdentificationNumber")) {
            object.planNetworkIdentificationNumber = message.planNetworkIdentificationNumber;
          }
          if (message.agencyClaimNumber != null && message.hasOwnProperty("agencyClaimNumber")) {
            object.agencyClaimNumber = message.agencyClaimNumber;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.AdditionalIdentification";
        }
      }

      AdditionalIdentification.prototype.planNumber = null;
      AdditionalIdentification.prototype.policyNumber = null;
      AdditionalIdentification.prototype.memberIdentificationNumber = null;
      AdditionalIdentification.prototype.contractNumber = null;
      AdditionalIdentification.prototype.medicalRecordIdentificationNumber = null;
      AdditionalIdentification.prototype.patientAccountNumber = null;
      AdditionalIdentification.prototype.healthInsuranceClaimNumber = null;
      AdditionalIdentification.prototype.identificationCardSerialNumber = null;
      AdditionalIdentification.prototype.insurancePolicyNumber = null;
      AdditionalIdentification.prototype.planNetworkIdentificationNumber = null;
      AdditionalIdentification.prototype.agencyClaimNumber = null;

      return AdditionalIdentification;
    })();

    v1.Provider = (() => {
      class Provider {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Provider(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.organizationName != null && Object.hasOwnProperty.call(message, "organizationName")) {
            writer.uint32(10).string(message.organizationName);
          }
          if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName")) {
            writer.uint32(18).string(message.firstName);
          }
          if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName")) {
            writer.uint32(26).string(message.lastName);
          }
          if (message.npi != null && Object.hasOwnProperty.call(message, "npi")) {
            writer.uint32(34).string(message.npi);
          }
          if (message.serviceProviderNumber != null && Object.hasOwnProperty.call(message, "serviceProviderNumber")) {
            writer.uint32(42).string(message.serviceProviderNumber);
          }
          if (message.payorId != null && Object.hasOwnProperty.call(message, "payorId")) {
            writer.uint32(50).string(message.payorId);
          }
          if (message.taxId != null && Object.hasOwnProperty.call(message, "taxId")) {
            writer.uint32(58).string(message.taxId);
          }
          if (message.ssn != null && Object.hasOwnProperty.call(message, "ssn")) {
            writer.uint32(66).string(message.ssn);
          }
          if (message.pharmacyProcessorNumber != null && Object.hasOwnProperty.call(message, "pharmacyProcessorNumber")) {
            writer.uint32(74).string(message.pharmacyProcessorNumber);
          }
          if (message.providerCode != null && Object.hasOwnProperty.call(message, "providerCode")) {
            writer.uint32(82).string(message.providerCode);
          }
          if (message.referenceIdentification != null && Object.hasOwnProperty.call(message, "referenceIdentification")) {
            writer.uint32(90).string(message.referenceIdentification);
          }
          if (message.providerType != null && Object.hasOwnProperty.call(message, "providerType")) {
            writer.uint32(98).string(message.providerType);
          }
          if (message.stateLicenceNumber != null && Object.hasOwnProperty.call(message, "stateLicenceNumber")) {
            writer.uint32(106).string(message.stateLicenceNumber);
          }
          if (message.medicareProviderNumber != null && Object.hasOwnProperty.call(message, "medicareProviderNumber")) {
            writer.uint32(114).string(message.medicareProviderNumber);
          }
          if (message.medicaidProviderNumber != null && Object.hasOwnProperty.call(message, "medicaidProviderNumber")) {
            writer.uint32(122).string(message.medicaidProviderNumber);
          }
          if (message.facilityIdNumber != null && Object.hasOwnProperty.call(message, "facilityIdNumber")) {
            writer.uint32(130).string(message.facilityIdNumber);
          }
          if (message.contractNumber != null && Object.hasOwnProperty.call(message, "contractNumber")) {
            writer.uint32(138).string(message.contractNumber);
          }
          if (message.devicePinNumber != null && Object.hasOwnProperty.call(message, "devicePinNumber")) {
            writer.uint32(146).string(message.devicePinNumber);
          }
          if (message.submitterIdNumber != null && Object.hasOwnProperty.call(message, "submitterIdNumber")) {
            writer.uint32(154).string(message.submitterIdNumber);
          }
          if (message.providerPlanNetworkIdNumber != null && Object.hasOwnProperty.call(message, "providerPlanNetworkIdNumber")) {
            writer.uint32(162).string(message.providerPlanNetworkIdNumber);
          }
          if (message.facilityNetworkIdNumber != null && Object.hasOwnProperty.call(message, "facilityNetworkIdNumber")) {
            writer.uint32(170).string(message.facilityNetworkIdNumber);
          }
          if (message.priorIdentifierNumber != null && Object.hasOwnProperty.call(message, "priorIdentifierNumber")) {
            writer.uint32(178).string(message.priorIdentifierNumber);
          }
          if (message.informationReceiverAdditionalIdentifierState != null && Object.hasOwnProperty.call(message, "informationReceiverAdditionalIdentifierState")) {
            writer.uint32(186).string(message.informationReceiverAdditionalIdentifierState);
          }
          if (message.address != null && Object.hasOwnProperty.call(message, "address")) {
            $root.stedi.v1.Address.encode(message.address, writer.uint32(210).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Provider();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.organizationName = reader.string();
                break;
              }
              case 2: {
                message.firstName = reader.string();
                break;
              }
              case 3: {
                message.lastName = reader.string();
                break;
              }
              case 4: {
                message.npi = reader.string();
                break;
              }
              case 5: {
                message.serviceProviderNumber = reader.string();
                break;
              }
              case 6: {
                message.payorId = reader.string();
                break;
              }
              case 7: {
                message.taxId = reader.string();
                break;
              }
              case 8: {
                message.ssn = reader.string();
                break;
              }
              case 9: {
                message.pharmacyProcessorNumber = reader.string();
                break;
              }
              case 10: {
                message.providerCode = reader.string();
                break;
              }
              case 11: {
                message.referenceIdentification = reader.string();
                break;
              }
              case 12: {
                message.providerType = reader.string();
                break;
              }
              case 13: {
                message.stateLicenceNumber = reader.string();
                break;
              }
              case 14: {
                message.medicareProviderNumber = reader.string();
                break;
              }
              case 15: {
                message.medicaidProviderNumber = reader.string();
                break;
              }
              case 16: {
                message.facilityIdNumber = reader.string();
                break;
              }
              case 17: {
                message.contractNumber = reader.string();
                break;
              }
              case 18: {
                message.devicePinNumber = reader.string();
                break;
              }
              case 19: {
                message.submitterIdNumber = reader.string();
                break;
              }
              case 20: {
                message.providerPlanNetworkIdNumber = reader.string();
                break;
              }
              case 21: {
                message.facilityNetworkIdNumber = reader.string();
                break;
              }
              case 22: {
                message.priorIdentifierNumber = reader.string();
                break;
              }
              case 23: {
                message.informationReceiverAdditionalIdentifierState = reader.string();
                break;
              }
              case 26: {
                message.address = $root.stedi.v1.Address.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.Provider) {
            return object;
          }
          const message = new $root.stedi.v1.Provider();
          if (object.organizationName != null) {
            message.organizationName = String(object.organizationName);
          }
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.npi != null) {
            message.npi = String(object.npi);
          }
          if (object.serviceProviderNumber != null) {
            message.serviceProviderNumber = String(object.serviceProviderNumber);
          }
          if (object.payorId != null) {
            message.payorId = String(object.payorId);
          }
          if (object.taxId != null) {
            message.taxId = String(object.taxId);
          }
          if (object.ssn != null) {
            message.ssn = String(object.ssn);
          }
          if (object.pharmacyProcessorNumber != null) {
            message.pharmacyProcessorNumber = String(object.pharmacyProcessorNumber);
          }
          if (object.providerCode != null) {
            message.providerCode = String(object.providerCode);
          }
          if (object.referenceIdentification != null) {
            message.referenceIdentification = String(object.referenceIdentification);
          }
          if (object.providerType != null) {
            message.providerType = String(object.providerType);
          }
          if (object.stateLicenceNumber != null) {
            message.stateLicenceNumber = String(object.stateLicenceNumber);
          }
          if (object.medicareProviderNumber != null) {
            message.medicareProviderNumber = String(object.medicareProviderNumber);
          }
          if (object.medicaidProviderNumber != null) {
            message.medicaidProviderNumber = String(object.medicaidProviderNumber);
          }
          if (object.facilityIdNumber != null) {
            message.facilityIdNumber = String(object.facilityIdNumber);
          }
          if (object.contractNumber != null) {
            message.contractNumber = String(object.contractNumber);
          }
          if (object.devicePinNumber != null) {
            message.devicePinNumber = String(object.devicePinNumber);
          }
          if (object.submitterIdNumber != null) {
            message.submitterIdNumber = String(object.submitterIdNumber);
          }
          if (object.providerPlanNetworkIdNumber != null) {
            message.providerPlanNetworkIdNumber = String(object.providerPlanNetworkIdNumber);
          }
          if (object.facilityNetworkIdNumber != null) {
            message.facilityNetworkIdNumber = String(object.facilityNetworkIdNumber);
          }
          if (object.priorIdentifierNumber != null) {
            message.priorIdentifierNumber = String(object.priorIdentifierNumber);
          }
          if (object.informationReceiverAdditionalIdentifierState != null) {
            message.informationReceiverAdditionalIdentifierState = String(object.informationReceiverAdditionalIdentifierState);
          }
          if (object.address != null) {
            if (typeof object.address !== "object") {
              throw new TypeError(".stedi.v1.Provider.address: object expected, but got " + (typeof object.address));
            }
            message.address = $root.stedi.v1.Address.fromObject(object.address);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.organizationName != null && message.hasOwnProperty("organizationName")) {
            object.organizationName = message.organizationName;
          }
          if (message.firstName != null && message.hasOwnProperty("firstName")) {
            object.firstName = message.firstName;
          }
          if (message.lastName != null && message.hasOwnProperty("lastName")) {
            object.lastName = message.lastName;
          }
          if (message.npi != null && message.hasOwnProperty("npi")) {
            object.npi = message.npi;
          }
          if (message.serviceProviderNumber != null && message.hasOwnProperty("serviceProviderNumber")) {
            object.serviceProviderNumber = message.serviceProviderNumber;
          }
          if (message.payorId != null && message.hasOwnProperty("payorId")) {
            object.payorId = message.payorId;
          }
          if (message.taxId != null && message.hasOwnProperty("taxId")) {
            object.taxId = message.taxId;
          }
          if (message.ssn != null && message.hasOwnProperty("ssn")) {
            object.ssn = message.ssn;
          }
          if (message.pharmacyProcessorNumber != null && message.hasOwnProperty("pharmacyProcessorNumber")) {
            object.pharmacyProcessorNumber = message.pharmacyProcessorNumber;
          }
          if (message.providerCode != null && message.hasOwnProperty("providerCode")) {
            object.providerCode = message.providerCode;
          }
          if (message.referenceIdentification != null && message.hasOwnProperty("referenceIdentification")) {
            object.referenceIdentification = message.referenceIdentification;
          }
          if (message.providerType != null && message.hasOwnProperty("providerType")) {
            object.providerType = message.providerType;
          }
          if (message.stateLicenceNumber != null && message.hasOwnProperty("stateLicenceNumber")) {
            object.stateLicenceNumber = message.stateLicenceNumber;
          }
          if (message.medicareProviderNumber != null && message.hasOwnProperty("medicareProviderNumber")) {
            object.medicareProviderNumber = message.medicareProviderNumber;
          }
          if (message.medicaidProviderNumber != null && message.hasOwnProperty("medicaidProviderNumber")) {
            object.medicaidProviderNumber = message.medicaidProviderNumber;
          }
          if (message.facilityIdNumber != null && message.hasOwnProperty("facilityIdNumber")) {
            object.facilityIdNumber = message.facilityIdNumber;
          }
          if (message.contractNumber != null && message.hasOwnProperty("contractNumber")) {
            object.contractNumber = message.contractNumber;
          }
          if (message.devicePinNumber != null && message.hasOwnProperty("devicePinNumber")) {
            object.devicePinNumber = message.devicePinNumber;
          }
          if (message.submitterIdNumber != null && message.hasOwnProperty("submitterIdNumber")) {
            object.submitterIdNumber = message.submitterIdNumber;
          }
          if (message.providerPlanNetworkIdNumber != null && message.hasOwnProperty("providerPlanNetworkIdNumber")) {
            object.providerPlanNetworkIdNumber = message.providerPlanNetworkIdNumber;
          }
          if (message.facilityNetworkIdNumber != null && message.hasOwnProperty("facilityNetworkIdNumber")) {
            object.facilityNetworkIdNumber = message.facilityNetworkIdNumber;
          }
          if (message.priorIdentifierNumber != null && message.hasOwnProperty("priorIdentifierNumber")) {
            object.priorIdentifierNumber = message.priorIdentifierNumber;
          }
          if (message.informationReceiverAdditionalIdentifierState != null && message.hasOwnProperty("informationReceiverAdditionalIdentifierState")) {
            object.informationReceiverAdditionalIdentifierState = message.informationReceiverAdditionalIdentifierState;
          }
          if (message.address != null && message.hasOwnProperty("address")) {
            object.address = $root.stedi.v1.Address.toObject(message.address, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.Provider";
        }
      }

      Provider.prototype.organizationName = null;
      Provider.prototype.firstName = null;
      Provider.prototype.lastName = null;
      Provider.prototype.npi = null;
      Provider.prototype.serviceProviderNumber = null;
      Provider.prototype.payorId = null;
      Provider.prototype.taxId = null;
      Provider.prototype.ssn = null;
      Provider.prototype.pharmacyProcessorNumber = null;
      Provider.prototype.providerCode = null;
      Provider.prototype.referenceIdentification = null;
      Provider.prototype.providerType = null;
      Provider.prototype.stateLicenceNumber = null;
      Provider.prototype.medicareProviderNumber = null;
      Provider.prototype.medicaidProviderNumber = null;
      Provider.prototype.facilityIdNumber = null;
      Provider.prototype.contractNumber = null;
      Provider.prototype.devicePinNumber = null;
      Provider.prototype.submitterIdNumber = null;
      Provider.prototype.providerPlanNetworkIdNumber = null;
      Provider.prototype.facilityNetworkIdNumber = null;
      Provider.prototype.priorIdentifierNumber = null;
      Provider.prototype.informationReceiverAdditionalIdentifierState = null;
      Provider.prototype.address = null;

      return Provider;
    })();

    v1.Encounter = (() => {
      class Encounter {
        constructor(properties) {
          this.serviceTypeCodes = [];
          this.procedureModifiers = [];
          this.diagnosisCodePointer = [];
          this.medicalProcedures = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Encounter(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.beginningDateOfService != null && Object.hasOwnProperty.call(message, "beginningDateOfService")) {
            values$1.v1.Date.encode(message.beginningDateOfService, writer.uint32(10).fork()).ldelim();
          }
          if (message.endDateOfService != null && Object.hasOwnProperty.call(message, "endDateOfService")) {
            values$1.v1.Date.encode(message.endDateOfService, writer.uint32(18).fork()).ldelim();
          }
          if (message.dateOfService != null && Object.hasOwnProperty.call(message, "dateOfService")) {
            values$1.v1.Date.encode(message.dateOfService, writer.uint32(26).fork()).ldelim();
          }
          if (message.serviceTypeCodes != null && Object.hasOwnProperty.call(message, "serviceTypeCodes")) {
            for (const element of message.serviceTypeCodes) {
              writer.uint32(34).string(element);
            }
          }
          if (message.priorAuthorizationOrReferralNumber != null && Object.hasOwnProperty.call(message, "priorAuthorizationOrReferralNumber")) {
            writer.uint32(42).string(message.priorAuthorizationOrReferralNumber);
          }
          if (message.referenceIdentificationQualifier != null && Object.hasOwnProperty.call(message, "referenceIdentificationQualifier")) {
            writer.uint32(50).string(message.referenceIdentificationQualifier);
          }
          if (message.industryCode != null && Object.hasOwnProperty.call(message, "industryCode")) {
            writer.uint32(58).string(message.industryCode);
          }
          if (message.productOrServiceIdQualifier != null && Object.hasOwnProperty.call(message, "productOrServiceIdQualifier")) {
            writer.uint32(66).string(message.productOrServiceIdQualifier);
          }
          if (message.procedureCode != null && Object.hasOwnProperty.call(message, "procedureCode")) {
            writer.uint32(74).string(message.procedureCode);
          }
          if (message.procedureModifiers != null && Object.hasOwnProperty.call(message, "procedureModifiers")) {
            for (const element of message.procedureModifiers) {
              writer.uint32(82).string(element);
            }
          }
          if (message.diagnosisCodePointer != null && Object.hasOwnProperty.call(message, "diagnosisCodePointer")) {
            for (const element of message.diagnosisCodePointer) {
              writer.uint32(90).string(element);
            }
          }
          if (message.medicalProcedures != null && Object.hasOwnProperty.call(message, "medicalProcedures")) {
            for (const element of message.medicalProcedures) {
              $root.stedi.v1.MedicalProcedure.encode(element, writer.uint32(98).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Encounter();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.beginningDateOfService = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.endDateOfService = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.dateOfService = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                if (!message.serviceTypeCodes || !message.serviceTypeCodes.length) {
                  message.serviceTypeCodes = [];
                }
                message.serviceTypeCodes.push(reader.string());
                break;
              }
              case 5: {
                message.priorAuthorizationOrReferralNumber = reader.string();
                break;
              }
              case 6: {
                message.referenceIdentificationQualifier = reader.string();
                break;
              }
              case 7: {
                message.industryCode = reader.string();
                break;
              }
              case 8: {
                message.productOrServiceIdQualifier = reader.string();
                break;
              }
              case 9: {
                message.procedureCode = reader.string();
                break;
              }
              case 10: {
                if (!message.procedureModifiers || !message.procedureModifiers.length) {
                  message.procedureModifiers = [];
                }
                message.procedureModifiers.push(reader.string());
                break;
              }
              case 11: {
                if (!message.diagnosisCodePointer || !message.diagnosisCodePointer.length) {
                  message.diagnosisCodePointer = [];
                }
                message.diagnosisCodePointer.push(reader.string());
                break;
              }
              case 12: {
                if (!message.medicalProcedures || !message.medicalProcedures.length) {
                  message.medicalProcedures = [];
                }
                message.medicalProcedures.push($root.stedi.v1.MedicalProcedure.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.Encounter) {
            return object;
          }
          const message = new $root.stedi.v1.Encounter();
          if (object.beginningDateOfService != null) {
            if (typeof object.beginningDateOfService !== "object") {
              throw new TypeError(".stedi.v1.Encounter.beginningDateOfService: object expected, but got " + (typeof object.beginningDateOfService));
            }
            message.beginningDateOfService = values$1.v1.Date.fromObject(object.beginningDateOfService);
          }
          if (object.endDateOfService != null) {
            if (typeof object.endDateOfService !== "object") {
              throw new TypeError(".stedi.v1.Encounter.endDateOfService: object expected, but got " + (typeof object.endDateOfService));
            }
            message.endDateOfService = values$1.v1.Date.fromObject(object.endDateOfService);
          }
          if (object.dateOfService != null) {
            if (typeof object.dateOfService !== "object") {
              throw new TypeError(".stedi.v1.Encounter.dateOfService: object expected, but got " + (typeof object.dateOfService));
            }
            message.dateOfService = values$1.v1.Date.fromObject(object.dateOfService);
          }
          if (object.serviceTypeCodes) {
            if (!Array.isArray(object.serviceTypeCodes)) {
              throw new TypeError(".stedi.v1.Encounter.serviceTypeCodes: array type expected, but got " + (typeof object.serviceTypeCodes))
            }
            message.serviceTypeCodes = new Array(object.serviceTypeCodes.length);
            for (let i = 0; i < object.serviceTypeCodes.length; ++i) {
              message.serviceTypeCodes[i] = String(object.serviceTypeCodes[i]);
            }
          }
          if (object.priorAuthorizationOrReferralNumber != null) {
            message.priorAuthorizationOrReferralNumber = String(object.priorAuthorizationOrReferralNumber);
          }
          if (object.referenceIdentificationQualifier != null) {
            message.referenceIdentificationQualifier = String(object.referenceIdentificationQualifier);
          }
          if (object.industryCode != null) {
            message.industryCode = String(object.industryCode);
          }
          if (object.productOrServiceIdQualifier != null) {
            message.productOrServiceIdQualifier = String(object.productOrServiceIdQualifier);
          }
          if (object.procedureCode != null) {
            message.procedureCode = String(object.procedureCode);
          }
          if (object.procedureModifiers) {
            if (!Array.isArray(object.procedureModifiers)) {
              throw new TypeError(".stedi.v1.Encounter.procedureModifiers: array type expected, but got " + (typeof object.procedureModifiers))
            }
            message.procedureModifiers = new Array(object.procedureModifiers.length);
            for (let i = 0; i < object.procedureModifiers.length; ++i) {
              message.procedureModifiers[i] = String(object.procedureModifiers[i]);
            }
          }
          if (object.diagnosisCodePointer) {
            if (!Array.isArray(object.diagnosisCodePointer)) {
              throw new TypeError(".stedi.v1.Encounter.diagnosisCodePointer: array type expected, but got " + (typeof object.diagnosisCodePointer))
            }
            message.diagnosisCodePointer = new Array(object.diagnosisCodePointer.length);
            for (let i = 0; i < object.diagnosisCodePointer.length; ++i) {
              message.diagnosisCodePointer[i] = String(object.diagnosisCodePointer[i]);
            }
          }
          if (object.medicalProcedures) {
            if (!Array.isArray(object.medicalProcedures)) {
              throw new TypeError(".stedi.v1.Encounter.medicalProcedures: array type expected, but got " + (typeof object.medicalProcedures))
            }
            message.medicalProcedures = new Array(object.medicalProcedures.length);
            for (let i = 0; i < object.medicalProcedures.length; ++i) {
              if (typeof object.medicalProcedures[i] !== "object") {
                throw new TypeError(".stedi.v1.Encounter.medicalProcedures: object expected, but got " + (typeof object.medicalProcedures[i]));
              }
              message.medicalProcedures[i] = $root.stedi.v1.MedicalProcedure.fromObject(object.medicalProcedures[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.serviceTypeCodes = [];
            object.procedureModifiers = [];
            object.diagnosisCodePointer = [];
            object.medicalProcedures = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.beginningDateOfService != null && message.hasOwnProperty("beginningDateOfService")) {
            object.beginningDateOfService = values$1.v1.Date.toObject(message.beginningDateOfService, options);
          }
          if (message.endDateOfService != null && message.hasOwnProperty("endDateOfService")) {
            object.endDateOfService = values$1.v1.Date.toObject(message.endDateOfService, options);
          }
          if (message.dateOfService != null && message.hasOwnProperty("dateOfService")) {
            object.dateOfService = values$1.v1.Date.toObject(message.dateOfService, options);
          }
          if (message.serviceTypeCodes && message.serviceTypeCodes.length) {
            object.serviceTypeCodes = new Array(message.serviceTypeCodes.length);
            for (let i = 0; i < message.serviceTypeCodes.length; ++i) {
              object.serviceTypeCodes[i] = message.serviceTypeCodes[i];
            }
          }
          if (message.priorAuthorizationOrReferralNumber != null && message.hasOwnProperty("priorAuthorizationOrReferralNumber")) {
            object.priorAuthorizationOrReferralNumber = message.priorAuthorizationOrReferralNumber;
          }
          if (message.referenceIdentificationQualifier != null && message.hasOwnProperty("referenceIdentificationQualifier")) {
            object.referenceIdentificationQualifier = message.referenceIdentificationQualifier;
          }
          if (message.industryCode != null && message.hasOwnProperty("industryCode")) {
            object.industryCode = message.industryCode;
          }
          if (message.productOrServiceIdQualifier != null && message.hasOwnProperty("productOrServiceIdQualifier")) {
            object.productOrServiceIdQualifier = message.productOrServiceIdQualifier;
          }
          if (message.procedureCode != null && message.hasOwnProperty("procedureCode")) {
            object.procedureCode = message.procedureCode;
          }
          if (message.procedureModifiers && message.procedureModifiers.length) {
            object.procedureModifiers = new Array(message.procedureModifiers.length);
            for (let i = 0; i < message.procedureModifiers.length; ++i) {
              object.procedureModifiers[i] = message.procedureModifiers[i];
            }
          }
          if (message.diagnosisCodePointer && message.diagnosisCodePointer.length) {
            object.diagnosisCodePointer = new Array(message.diagnosisCodePointer.length);
            for (let i = 0; i < message.diagnosisCodePointer.length; ++i) {
              object.diagnosisCodePointer[i] = message.diagnosisCodePointer[i];
            }
          }
          if (message.medicalProcedures && message.medicalProcedures.length) {
            object.medicalProcedures = new Array(message.medicalProcedures.length);
            for (let i = 0; i < message.medicalProcedures.length; ++i) {
              object.medicalProcedures[i] = $root.stedi.v1.MedicalProcedure.toObject(message.medicalProcedures[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.Encounter";
        }
      }

      Encounter.prototype.beginningDateOfService = null;
      Encounter.prototype.endDateOfService = null;
      Encounter.prototype.dateOfService = null;
      Encounter.prototype.serviceTypeCodes = $util.emptyArray;
      Encounter.prototype.priorAuthorizationOrReferralNumber = null;
      Encounter.prototype.referenceIdentificationQualifier = null;
      Encounter.prototype.industryCode = null;
      Encounter.prototype.productOrServiceIdQualifier = null;
      Encounter.prototype.procedureCode = null;
      Encounter.prototype.procedureModifiers = $util.emptyArray;
      Encounter.prototype.diagnosisCodePointer = $util.emptyArray;
      Encounter.prototype.medicalProcedures = $util.emptyArray;

      return Encounter;
    })();

    v1.MedicalProcedure = (() => {
      class MedicalProcedure {
        constructor(properties) {
          this.procedureModifiers = [];
          this.diagnosisCodePointer = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MedicalProcedure(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.productOrServiceIdQualifier != null && Object.hasOwnProperty.call(message, "productOrServiceIdQualifier")) {
            writer.uint32(10).string(message.productOrServiceIdQualifier);
          }
          if (message.procedureCode != null && Object.hasOwnProperty.call(message, "procedureCode")) {
            writer.uint32(18).string(message.procedureCode);
          }
          if (message.procedureModifiers != null && Object.hasOwnProperty.call(message, "procedureModifiers")) {
            for (const element of message.procedureModifiers) {
              writer.uint32(26).string(element);
            }
          }
          if (message.diagnosisCodePointer != null && Object.hasOwnProperty.call(message, "diagnosisCodePointer")) {
            for (const element of message.diagnosisCodePointer) {
              writer.uint32(34).string(element);
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MedicalProcedure();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.productOrServiceIdQualifier = reader.string();
                break;
              }
              case 2: {
                message.procedureCode = reader.string();
                break;
              }
              case 3: {
                if (!message.procedureModifiers || !message.procedureModifiers.length) {
                  message.procedureModifiers = [];
                }
                message.procedureModifiers.push(reader.string());
                break;
              }
              case 4: {
                if (!message.diagnosisCodePointer || !message.diagnosisCodePointer.length) {
                  message.diagnosisCodePointer = [];
                }
                message.diagnosisCodePointer.push(reader.string());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.MedicalProcedure) {
            return object;
          }
          const message = new $root.stedi.v1.MedicalProcedure();
          if (object.productOrServiceIdQualifier != null) {
            message.productOrServiceIdQualifier = String(object.productOrServiceIdQualifier);
          }
          if (object.procedureCode != null) {
            message.procedureCode = String(object.procedureCode);
          }
          if (object.procedureModifiers) {
            if (!Array.isArray(object.procedureModifiers)) {
              throw new TypeError(".stedi.v1.MedicalProcedure.procedureModifiers: array type expected, but got " + (typeof object.procedureModifiers))
            }
            message.procedureModifiers = new Array(object.procedureModifiers.length);
            for (let i = 0; i < object.procedureModifiers.length; ++i) {
              message.procedureModifiers[i] = String(object.procedureModifiers[i]);
            }
          }
          if (object.diagnosisCodePointer) {
            if (!Array.isArray(object.diagnosisCodePointer)) {
              throw new TypeError(".stedi.v1.MedicalProcedure.diagnosisCodePointer: array type expected, but got " + (typeof object.diagnosisCodePointer))
            }
            message.diagnosisCodePointer = new Array(object.diagnosisCodePointer.length);
            for (let i = 0; i < object.diagnosisCodePointer.length; ++i) {
              message.diagnosisCodePointer[i] = String(object.diagnosisCodePointer[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.procedureModifiers = [];
            object.diagnosisCodePointer = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.productOrServiceIdQualifier != null && message.hasOwnProperty("productOrServiceIdQualifier")) {
            object.productOrServiceIdQualifier = message.productOrServiceIdQualifier;
          }
          if (message.procedureCode != null && message.hasOwnProperty("procedureCode")) {
            object.procedureCode = message.procedureCode;
          }
          if (message.procedureModifiers && message.procedureModifiers.length) {
            object.procedureModifiers = new Array(message.procedureModifiers.length);
            for (let i = 0; i < message.procedureModifiers.length; ++i) {
              object.procedureModifiers[i] = message.procedureModifiers[i];
            }
          }
          if (message.diagnosisCodePointer && message.diagnosisCodePointer.length) {
            object.diagnosisCodePointer = new Array(message.diagnosisCodePointer.length);
            for (let i = 0; i < message.diagnosisCodePointer.length; ++i) {
              object.diagnosisCodePointer[i] = message.diagnosisCodePointer[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.MedicalProcedure";
        }
      }

      MedicalProcedure.prototype.productOrServiceIdQualifier = null;
      MedicalProcedure.prototype.procedureCode = null;
      MedicalProcedure.prototype.procedureModifiers = $util.emptyArray;
      MedicalProcedure.prototype.diagnosisCodePointer = $util.emptyArray;

      return MedicalProcedure;
    })();

    v1.Dependent = (() => {
      class Dependent {
        constructor(properties) {
          this.healthCareCodeInformation = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Dependent(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.memberId != null && Object.hasOwnProperty.call(message, "memberId")) {
            writer.uint32(10).string(message.memberId);
          }
          if (message.dateOfBirth != null && Object.hasOwnProperty.call(message, "dateOfBirth")) {
            values$1.v1.Date.encode(message.dateOfBirth, writer.uint32(18).fork()).ldelim();
          }
          if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName")) {
            writer.uint32(26).string(message.firstName);
          }
          if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName")) {
            writer.uint32(34).string(message.middleName);
          }
          if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName")) {
            writer.uint32(42).string(message.lastName);
          }
          if (message.suffix != null && Object.hasOwnProperty.call(message, "suffix")) {
            writer.uint32(50).string(message.suffix);
          }
          if (message.gender != null && Object.hasOwnProperty.call(message, "gender")) {
            writer.uint32(58).string(message.gender);
          }
          if (message.ssn != null && Object.hasOwnProperty.call(message, "ssn")) {
            writer.uint32(66).string(message.ssn);
          }
          if (message.groupNumber != null && Object.hasOwnProperty.call(message, "groupNumber")) {
            writer.uint32(74).string(message.groupNumber);
          }
          if (message.idCard != null && Object.hasOwnProperty.call(message, "idCard")) {
            writer.uint32(82).string(message.idCard);
          }
          if (message.providerCode != null && Object.hasOwnProperty.call(message, "providerCode")) {
            writer.uint32(90).string(message.providerCode);
          }
          if (message.referenceIdentificationQualifier != null && Object.hasOwnProperty.call(message, "referenceIdentificationQualifier")) {
            writer.uint32(98).string(message.referenceIdentificationQualifier);
          }
          if (message.providerIdentifier != null && Object.hasOwnProperty.call(message, "providerIdentifier")) {
            writer.uint32(106).string(message.providerIdentifier);
          }
          if (message.healthCareCodeInformation != null && Object.hasOwnProperty.call(message, "healthCareCodeInformation")) {
            for (const element of message.healthCareCodeInformation) {
              $root.stedi.v1.HealthCareCodeInformation.encode(element, writer.uint32(114).fork()).ldelim();
            }
          }
          if (message.address != null && Object.hasOwnProperty.call(message, "address")) {
            $root.stedi.v1.Address.encode(message.address, writer.uint32(122).fork()).ldelim();
          }
          if (message.additionalIdentification != null && Object.hasOwnProperty.call(message, "additionalIdentification")) {
            $root.stedi.v1.AdditionalIdentification.encode(message.additionalIdentification, writer.uint32(130).fork()).ldelim();
          }
          if (message.birthSequenceNumber != null && Object.hasOwnProperty.call(message, "birthSequenceNumber")) {
            writer.uint32(138).string(message.birthSequenceNumber);
          }
          if (message.caseNumber != null && Object.hasOwnProperty.call(message, "caseNumber")) {
            writer.uint32(146).string(message.caseNumber);
          }
          if (message.medicaidRecipientIdentificationNumber != null && Object.hasOwnProperty.call(message, "medicaidRecipientIdentificationNumber")) {
            writer.uint32(154).string(message.medicaidRecipientIdentificationNumber);
          }
          if (message.spendDownAmount != null && Object.hasOwnProperty.call(message, "spendDownAmount")) {
            writer.uint32(161).double(message.spendDownAmount);
          }
          if (message.spendDownTotalBilledAmount != null && Object.hasOwnProperty.call(message, "spendDownTotalBilledAmount")) {
            writer.uint32(169).double(message.spendDownTotalBilledAmount);
          }
          if (message.coverageLevelCode != null && Object.hasOwnProperty.call(message, "coverageLevelCode")) {
            writer.uint32(178).string(message.coverageLevelCode);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Dependent();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.memberId = reader.string();
                break;
              }
              case 2: {
                message.dateOfBirth = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.firstName = reader.string();
                break;
              }
              case 4: {
                message.middleName = reader.string();
                break;
              }
              case 5: {
                message.lastName = reader.string();
                break;
              }
              case 6: {
                message.suffix = reader.string();
                break;
              }
              case 7: {
                message.gender = reader.string();
                break;
              }
              case 8: {
                message.ssn = reader.string();
                break;
              }
              case 9: {
                message.groupNumber = reader.string();
                break;
              }
              case 10: {
                message.idCard = reader.string();
                break;
              }
              case 11: {
                message.providerCode = reader.string();
                break;
              }
              case 12: {
                message.referenceIdentificationQualifier = reader.string();
                break;
              }
              case 13: {
                message.providerIdentifier = reader.string();
                break;
              }
              case 14: {
                if (!message.healthCareCodeInformation || !message.healthCareCodeInformation.length) {
                  message.healthCareCodeInformation = [];
                }
                message.healthCareCodeInformation.push($root.stedi.v1.HealthCareCodeInformation.decode(reader, reader.uint32()));
                break;
              }
              case 15: {
                message.address = $root.stedi.v1.Address.decode(reader, reader.uint32());
                break;
              }
              case 16: {
                message.additionalIdentification = $root.stedi.v1.AdditionalIdentification.decode(reader, reader.uint32());
                break;
              }
              case 17: {
                message.birthSequenceNumber = reader.string();
                break;
              }
              case 18: {
                message.caseNumber = reader.string();
                break;
              }
              case 19: {
                message.medicaidRecipientIdentificationNumber = reader.string();
                break;
              }
              case 20: {
                message.spendDownAmount = reader.double();
                break;
              }
              case 21: {
                message.spendDownTotalBilledAmount = reader.double();
                break;
              }
              case 22: {
                message.coverageLevelCode = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.Dependent) {
            return object;
          }
          const message = new $root.stedi.v1.Dependent();
          if (object.memberId != null) {
            message.memberId = String(object.memberId);
          }
          if (object.dateOfBirth != null) {
            if (typeof object.dateOfBirth !== "object") {
              throw new TypeError(".stedi.v1.Dependent.dateOfBirth: object expected, but got " + (typeof object.dateOfBirth));
            }
            message.dateOfBirth = values$1.v1.Date.fromObject(object.dateOfBirth);
          }
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.middleName != null) {
            message.middleName = String(object.middleName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.suffix != null) {
            message.suffix = String(object.suffix);
          }
          if (object.gender != null) {
            message.gender = String(object.gender);
          }
          if (object.ssn != null) {
            message.ssn = String(object.ssn);
          }
          if (object.groupNumber != null) {
            message.groupNumber = String(object.groupNumber);
          }
          if (object.idCard != null) {
            message.idCard = String(object.idCard);
          }
          if (object.providerCode != null) {
            message.providerCode = String(object.providerCode);
          }
          if (object.referenceIdentificationQualifier != null) {
            message.referenceIdentificationQualifier = String(object.referenceIdentificationQualifier);
          }
          if (object.providerIdentifier != null) {
            message.providerIdentifier = String(object.providerIdentifier);
          }
          if (object.healthCareCodeInformation) {
            if (!Array.isArray(object.healthCareCodeInformation)) {
              throw new TypeError(".stedi.v1.Dependent.healthCareCodeInformation: array type expected, but got " + (typeof object.healthCareCodeInformation))
            }
            message.healthCareCodeInformation = new Array(object.healthCareCodeInformation.length);
            for (let i = 0; i < object.healthCareCodeInformation.length; ++i) {
              if (typeof object.healthCareCodeInformation[i] !== "object") {
                throw new TypeError(".stedi.v1.Dependent.healthCareCodeInformation: object expected, but got " + (typeof object.healthCareCodeInformation[i]));
              }
              message.healthCareCodeInformation[i] = $root.stedi.v1.HealthCareCodeInformation.fromObject(object.healthCareCodeInformation[i]);
            }
          }
          if (object.address != null) {
            if (typeof object.address !== "object") {
              throw new TypeError(".stedi.v1.Dependent.address: object expected, but got " + (typeof object.address));
            }
            message.address = $root.stedi.v1.Address.fromObject(object.address);
          }
          if (object.additionalIdentification != null) {
            if (typeof object.additionalIdentification !== "object") {
              throw new TypeError(".stedi.v1.Dependent.additionalIdentification: object expected, but got " + (typeof object.additionalIdentification));
            }
            message.additionalIdentification = $root.stedi.v1.AdditionalIdentification.fromObject(object.additionalIdentification);
          }
          if (object.birthSequenceNumber != null) {
            message.birthSequenceNumber = String(object.birthSequenceNumber);
          }
          if (object.caseNumber != null) {
            message.caseNumber = String(object.caseNumber);
          }
          if (object.medicaidRecipientIdentificationNumber != null) {
            message.medicaidRecipientIdentificationNumber = String(object.medicaidRecipientIdentificationNumber);
          }
          if (object.spendDownAmount != null) {
            message.spendDownAmount = Number(object.spendDownAmount);
          }
          if (object.spendDownTotalBilledAmount != null) {
            message.spendDownTotalBilledAmount = Number(object.spendDownTotalBilledAmount);
          }
          if (object.coverageLevelCode != null) {
            message.coverageLevelCode = String(object.coverageLevelCode);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.healthCareCodeInformation = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.firstName = "";
            object.lastName = "";
          }
          let keys;
          if (message.memberId != null && message.hasOwnProperty("memberId")) {
            object.memberId = message.memberId;
          }
          if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth")) {
            object.dateOfBirth = values$1.v1.Date.toObject(message.dateOfBirth, options);
          }
          if (message.firstName != null && message.hasOwnProperty("firstName")) {
            object.firstName = message.firstName;
          }
          if (message.middleName != null && message.hasOwnProperty("middleName")) {
            object.middleName = message.middleName;
          }
          if (message.lastName != null && message.hasOwnProperty("lastName")) {
            object.lastName = message.lastName;
          }
          if (message.suffix != null && message.hasOwnProperty("suffix")) {
            object.suffix = message.suffix;
          }
          if (message.gender != null && message.hasOwnProperty("gender")) {
            object.gender = message.gender;
          }
          if (message.ssn != null && message.hasOwnProperty("ssn")) {
            object.ssn = message.ssn;
          }
          if (message.groupNumber != null && message.hasOwnProperty("groupNumber")) {
            object.groupNumber = message.groupNumber;
          }
          if (message.idCard != null && message.hasOwnProperty("idCard")) {
            object.idCard = message.idCard;
          }
          if (message.providerCode != null && message.hasOwnProperty("providerCode")) {
            object.providerCode = message.providerCode;
          }
          if (message.referenceIdentificationQualifier != null && message.hasOwnProperty("referenceIdentificationQualifier")) {
            object.referenceIdentificationQualifier = message.referenceIdentificationQualifier;
          }
          if (message.providerIdentifier != null && message.hasOwnProperty("providerIdentifier")) {
            object.providerIdentifier = message.providerIdentifier;
          }
          if (message.healthCareCodeInformation && message.healthCareCodeInformation.length) {
            object.healthCareCodeInformation = new Array(message.healthCareCodeInformation.length);
            for (let i = 0; i < message.healthCareCodeInformation.length; ++i) {
              object.healthCareCodeInformation[i] = $root.stedi.v1.HealthCareCodeInformation.toObject(message.healthCareCodeInformation[i], options);
            }
          }
          if (message.address != null && message.hasOwnProperty("address")) {
            object.address = $root.stedi.v1.Address.toObject(message.address, options);
          }
          if (message.additionalIdentification != null && message.hasOwnProperty("additionalIdentification")) {
            object.additionalIdentification = $root.stedi.v1.AdditionalIdentification.toObject(message.additionalIdentification, options);
          }
          if (message.birthSequenceNumber != null && message.hasOwnProperty("birthSequenceNumber")) {
            object.birthSequenceNumber = message.birthSequenceNumber;
          }
          if (message.caseNumber != null && message.hasOwnProperty("caseNumber")) {
            object.caseNumber = message.caseNumber;
          }
          if (message.medicaidRecipientIdentificationNumber != null && message.hasOwnProperty("medicaidRecipientIdentificationNumber")) {
            object.medicaidRecipientIdentificationNumber = message.medicaidRecipientIdentificationNumber;
          }
          if (message.spendDownAmount != null && message.hasOwnProperty("spendDownAmount")) {
            object.spendDownAmount = options.json && !isFinite(message.spendDownAmount) ? String(message.spendDownAmount) : message.spendDownAmount;
          }
          if (message.spendDownTotalBilledAmount != null && message.hasOwnProperty("spendDownTotalBilledAmount")) {
            object.spendDownTotalBilledAmount = options.json && !isFinite(message.spendDownTotalBilledAmount) ? String(message.spendDownTotalBilledAmount) : message.spendDownTotalBilledAmount;
          }
          if (message.coverageLevelCode != null && message.hasOwnProperty("coverageLevelCode")) {
            object.coverageLevelCode = message.coverageLevelCode;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.Dependent";
        }
      }

      Dependent.prototype.memberId = null;
      Dependent.prototype.dateOfBirth = null;
      Dependent.prototype.firstName = "";
      Dependent.prototype.middleName = null;
      Dependent.prototype.lastName = "";
      Dependent.prototype.suffix = null;
      Dependent.prototype.gender = null;
      Dependent.prototype.ssn = null;
      Dependent.prototype.groupNumber = null;
      Dependent.prototype.idCard = null;
      Dependent.prototype.providerCode = null;
      Dependent.prototype.referenceIdentificationQualifier = null;
      Dependent.prototype.providerIdentifier = null;
      Dependent.prototype.healthCareCodeInformation = $util.emptyArray;
      Dependent.prototype.address = null;
      Dependent.prototype.additionalIdentification = null;
      Dependent.prototype.birthSequenceNumber = null;
      Dependent.prototype.caseNumber = null;
      Dependent.prototype.medicaidRecipientIdentificationNumber = null;
      Dependent.prototype.spendDownAmount = null;
      Dependent.prototype.spendDownTotalBilledAmount = null;
      Dependent.prototype.coverageLevelCode = null;

      return Dependent;
    })();

    v1.Metadata = (() => {
      class Metadata {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Metadata(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.senderId != null && Object.hasOwnProperty.call(message, "senderId")) {
            writer.uint32(10).string(message.senderId);
          }
          if (message.submitterId != null && Object.hasOwnProperty.call(message, "submitterId")) {
            writer.uint32(18).string(message.submitterId);
          }
          if (message.billerId != null && Object.hasOwnProperty.call(message, "billerId")) {
            writer.uint32(26).string(message.billerId);
          }
          if (message.applicationMode != null && Object.hasOwnProperty.call(message, "applicationMode")) {
            writer.uint32(34).string(message.applicationMode);
          }
          if (message.traceId != null && Object.hasOwnProperty.call(message, "traceId")) {
            writer.uint32(42).string(message.traceId);
          }
          if (message.outboundTraceId != null && Object.hasOwnProperty.call(message, "outboundTraceId")) {
            writer.uint32(50).string(message.outboundTraceId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Metadata();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.senderId = reader.string();
                break;
              }
              case 2: {
                message.submitterId = reader.string();
                break;
              }
              case 3: {
                message.billerId = reader.string();
                break;
              }
              case 4: {
                message.applicationMode = reader.string();
                break;
              }
              case 5: {
                message.traceId = reader.string();
                break;
              }
              case 6: {
                message.outboundTraceId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.Metadata) {
            return object;
          }
          const message = new $root.stedi.v1.Metadata();
          if (object.senderId != null) {
            message.senderId = String(object.senderId);
          }
          if (object.submitterId != null) {
            message.submitterId = String(object.submitterId);
          }
          if (object.billerId != null) {
            message.billerId = String(object.billerId);
          }
          if (object.applicationMode != null) {
            message.applicationMode = String(object.applicationMode);
          }
          if (object.traceId != null) {
            message.traceId = String(object.traceId);
          }
          if (object.outboundTraceId != null) {
            message.outboundTraceId = String(object.outboundTraceId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.senderId != null && message.hasOwnProperty("senderId")) {
            object.senderId = message.senderId;
          }
          if (message.submitterId != null && message.hasOwnProperty("submitterId")) {
            object.submitterId = message.submitterId;
          }
          if (message.billerId != null && message.hasOwnProperty("billerId")) {
            object.billerId = message.billerId;
          }
          if (message.applicationMode != null && message.hasOwnProperty("applicationMode")) {
            object.applicationMode = message.applicationMode;
          }
          if (message.traceId != null && message.hasOwnProperty("traceId")) {
            object.traceId = message.traceId;
          }
          if (message.outboundTraceId != null && message.hasOwnProperty("outboundTraceId")) {
            object.outboundTraceId = message.outboundTraceId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.Metadata";
        }
      }

      Metadata.prototype.senderId = null;
      Metadata.prototype.submitterId = null;
      Metadata.prototype.billerId = null;
      Metadata.prototype.applicationMode = null;
      Metadata.prototype.traceId = null;
      Metadata.prototype.outboundTraceId = null;

      return Metadata;
    })();

    v1.SubscriberTraceNumber = (() => {
      class SubscriberTraceNumber {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SubscriberTraceNumber(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.traceTypeCode != null && Object.hasOwnProperty.call(message, "traceTypeCode")) {
            writer.uint32(10).string(message.traceTypeCode);
          }
          if (message.traceType != null && Object.hasOwnProperty.call(message, "traceType")) {
            writer.uint32(18).string(message.traceType);
          }
          if (message.referenceIdentification != null && Object.hasOwnProperty.call(message, "referenceIdentification")) {
            writer.uint32(26).string(message.referenceIdentification);
          }
          if (message.originatingCompanyIdentifier != null && Object.hasOwnProperty.call(message, "originatingCompanyIdentifier")) {
            writer.uint32(34).string(message.originatingCompanyIdentifier);
          }
          if (message.secondaryReferenceIdentification != null && Object.hasOwnProperty.call(message, "secondaryReferenceIdentification")) {
            writer.uint32(42).string(message.secondaryReferenceIdentification);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SubscriberTraceNumber();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.traceTypeCode = reader.string();
                break;
              }
              case 2: {
                message.traceType = reader.string();
                break;
              }
              case 3: {
                message.referenceIdentification = reader.string();
                break;
              }
              case 4: {
                message.originatingCompanyIdentifier = reader.string();
                break;
              }
              case 5: {
                message.secondaryReferenceIdentification = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.SubscriberTraceNumber) {
            return object;
          }
          const message = new $root.stedi.v1.SubscriberTraceNumber();
          if (object.traceTypeCode != null) {
            message.traceTypeCode = String(object.traceTypeCode);
          }
          if (object.traceType != null) {
            message.traceType = String(object.traceType);
          }
          if (object.referenceIdentification != null) {
            message.referenceIdentification = String(object.referenceIdentification);
          }
          if (object.originatingCompanyIdentifier != null) {
            message.originatingCompanyIdentifier = String(object.originatingCompanyIdentifier);
          }
          if (object.secondaryReferenceIdentification != null) {
            message.secondaryReferenceIdentification = String(object.secondaryReferenceIdentification);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.traceTypeCode != null && message.hasOwnProperty("traceTypeCode")) {
            object.traceTypeCode = message.traceTypeCode;
          }
          if (message.traceType != null && message.hasOwnProperty("traceType")) {
            object.traceType = message.traceType;
          }
          if (message.referenceIdentification != null && message.hasOwnProperty("referenceIdentification")) {
            object.referenceIdentification = message.referenceIdentification;
          }
          if (message.originatingCompanyIdentifier != null && message.hasOwnProperty("originatingCompanyIdentifier")) {
            object.originatingCompanyIdentifier = message.originatingCompanyIdentifier;
          }
          if (message.secondaryReferenceIdentification != null && message.hasOwnProperty("secondaryReferenceIdentification")) {
            object.secondaryReferenceIdentification = message.secondaryReferenceIdentification;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.SubscriberTraceNumber";
        }
      }

      SubscriberTraceNumber.prototype.traceTypeCode = null;
      SubscriberTraceNumber.prototype.traceType = null;
      SubscriberTraceNumber.prototype.referenceIdentification = null;
      SubscriberTraceNumber.prototype.originatingCompanyIdentifier = null;
      SubscriberTraceNumber.prototype.secondaryReferenceIdentification = null;

      return SubscriberTraceNumber;
    })();

    v1.Payer = (() => {
      class Payer {
        constructor(properties) {
          this.aaaErrors = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Payer(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.entityIdentifier != null && Object.hasOwnProperty.call(message, "entityIdentifier")) {
            writer.uint32(10).string(message.entityIdentifier);
          }
          if (message.entityType != null && Object.hasOwnProperty.call(message, "entityType")) {
            writer.uint32(18).string(message.entityType);
          }
          if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName")) {
            writer.uint32(26).string(message.firstName);
          }
          if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName")) {
            writer.uint32(34).string(message.lastName);
          }
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(42).string(message.name);
          }
          if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName")) {
            writer.uint32(50).string(message.middleName);
          }
          if (message.suffix != null && Object.hasOwnProperty.call(message, "suffix")) {
            writer.uint32(58).string(message.suffix);
          }
          if (message.federalTaxpayersIdNumber != null && Object.hasOwnProperty.call(message, "federalTaxpayersIdNumber")) {
            writer.uint32(66).string(message.federalTaxpayersIdNumber);
          }
          if (message.naic != null && Object.hasOwnProperty.call(message, "naic")) {
            writer.uint32(74).string(message.naic);
          }
          if (message.npi != null && Object.hasOwnProperty.call(message, "npi")) {
            writer.uint32(82).string(message.npi);
          }
          if (message.centersForMedicareAndMedicaidPlanId != null && Object.hasOwnProperty.call(message, "centersForMedicareAndMedicaidPlanId")) {
            writer.uint32(90).string(message.centersForMedicareAndMedicaidPlanId);
          }
          if (message.payorIdentification != null && Object.hasOwnProperty.call(message, "payorIdentification")) {
            writer.uint32(98).string(message.payorIdentification);
          }
          if (message.contactInformation != null && Object.hasOwnProperty.call(message, "contactInformation")) {
            $root.stedi.v1.ContactInformation.encode(message.contactInformation, writer.uint32(106).fork()).ldelim();
          }
          if (message.aaaErrors != null && Object.hasOwnProperty.call(message, "aaaErrors")) {
            for (const element of message.aaaErrors) {
              $root.stedi.v1.AaaError.encode(element, writer.uint32(114).fork()).ldelim();
            }
          }
          if (message.etin != null && Object.hasOwnProperty.call(message, "etin")) {
            writer.uint32(122).string(message.etin);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Payer();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.entityIdentifier = reader.string();
                break;
              }
              case 2: {
                message.entityType = reader.string();
                break;
              }
              case 3: {
                message.firstName = reader.string();
                break;
              }
              case 4: {
                message.lastName = reader.string();
                break;
              }
              case 5: {
                message.name = reader.string();
                break;
              }
              case 6: {
                message.middleName = reader.string();
                break;
              }
              case 7: {
                message.suffix = reader.string();
                break;
              }
              case 8: {
                message.federalTaxpayersIdNumber = reader.string();
                break;
              }
              case 9: {
                message.naic = reader.string();
                break;
              }
              case 10: {
                message.npi = reader.string();
                break;
              }
              case 11: {
                message.centersForMedicareAndMedicaidPlanId = reader.string();
                break;
              }
              case 12: {
                message.payorIdentification = reader.string();
                break;
              }
              case 13: {
                message.contactInformation = $root.stedi.v1.ContactInformation.decode(reader, reader.uint32());
                break;
              }
              case 14: {
                if (!message.aaaErrors || !message.aaaErrors.length) {
                  message.aaaErrors = [];
                }
                message.aaaErrors.push($root.stedi.v1.AaaError.decode(reader, reader.uint32()));
                break;
              }
              case 15: {
                message.etin = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.Payer) {
            return object;
          }
          const message = new $root.stedi.v1.Payer();
          if (object.entityIdentifier != null) {
            message.entityIdentifier = String(object.entityIdentifier);
          }
          if (object.entityType != null) {
            message.entityType = String(object.entityType);
          }
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.middleName != null) {
            message.middleName = String(object.middleName);
          }
          if (object.suffix != null) {
            message.suffix = String(object.suffix);
          }
          if (object.federalTaxpayersIdNumber != null) {
            message.federalTaxpayersIdNumber = String(object.federalTaxpayersIdNumber);
          }
          if (object.naic != null) {
            message.naic = String(object.naic);
          }
          if (object.npi != null) {
            message.npi = String(object.npi);
          }
          if (object.centersForMedicareAndMedicaidPlanId != null) {
            message.centersForMedicareAndMedicaidPlanId = String(object.centersForMedicareAndMedicaidPlanId);
          }
          if (object.payorIdentification != null) {
            message.payorIdentification = String(object.payorIdentification);
          }
          if (object.contactInformation != null) {
            if (typeof object.contactInformation !== "object") {
              throw new TypeError(".stedi.v1.Payer.contactInformation: object expected, but got " + (typeof object.contactInformation));
            }
            message.contactInformation = $root.stedi.v1.ContactInformation.fromObject(object.contactInformation);
          }
          if (object.aaaErrors) {
            if (!Array.isArray(object.aaaErrors)) {
              throw new TypeError(".stedi.v1.Payer.aaaErrors: array type expected, but got " + (typeof object.aaaErrors))
            }
            message.aaaErrors = new Array(object.aaaErrors.length);
            for (let i = 0; i < object.aaaErrors.length; ++i) {
              if (typeof object.aaaErrors[i] !== "object") {
                throw new TypeError(".stedi.v1.Payer.aaaErrors: object expected, but got " + (typeof object.aaaErrors[i]));
              }
              message.aaaErrors[i] = $root.stedi.v1.AaaError.fromObject(object.aaaErrors[i]);
            }
          }
          if (object.etin != null) {
            message.etin = String(object.etin);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.aaaErrors = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = "";
          }
          let keys;
          if (message.entityIdentifier != null && message.hasOwnProperty("entityIdentifier")) {
            object.entityIdentifier = message.entityIdentifier;
          }
          if (message.entityType != null && message.hasOwnProperty("entityType")) {
            object.entityType = message.entityType;
          }
          if (message.firstName != null && message.hasOwnProperty("firstName")) {
            object.firstName = message.firstName;
          }
          if (message.lastName != null && message.hasOwnProperty("lastName")) {
            object.lastName = message.lastName;
          }
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.middleName != null && message.hasOwnProperty("middleName")) {
            object.middleName = message.middleName;
          }
          if (message.suffix != null && message.hasOwnProperty("suffix")) {
            object.suffix = message.suffix;
          }
          if (message.federalTaxpayersIdNumber != null && message.hasOwnProperty("federalTaxpayersIdNumber")) {
            object.federalTaxpayersIdNumber = message.federalTaxpayersIdNumber;
          }
          if (message.naic != null && message.hasOwnProperty("naic")) {
            object.naic = message.naic;
          }
          if (message.npi != null && message.hasOwnProperty("npi")) {
            object.npi = message.npi;
          }
          if (message.centersForMedicareAndMedicaidPlanId != null && message.hasOwnProperty("centersForMedicareAndMedicaidPlanId")) {
            object.centersForMedicareAndMedicaidPlanId = message.centersForMedicareAndMedicaidPlanId;
          }
          if (message.payorIdentification != null && message.hasOwnProperty("payorIdentification")) {
            object.payorIdentification = message.payorIdentification;
          }
          if (message.contactInformation != null && message.hasOwnProperty("contactInformation")) {
            object.contactInformation = $root.stedi.v1.ContactInformation.toObject(message.contactInformation, options);
          }
          if (message.aaaErrors && message.aaaErrors.length) {
            object.aaaErrors = new Array(message.aaaErrors.length);
            for (let i = 0; i < message.aaaErrors.length; ++i) {
              object.aaaErrors[i] = $root.stedi.v1.AaaError.toObject(message.aaaErrors[i], options);
            }
          }
          if (message.etin != null && message.hasOwnProperty("etin")) {
            object.etin = message.etin;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.Payer";
        }
      }

      Payer.prototype.entityIdentifier = null;
      Payer.prototype.entityType = null;
      Payer.prototype.firstName = null;
      Payer.prototype.lastName = null;
      Payer.prototype.name = "";
      Payer.prototype.middleName = null;
      Payer.prototype.suffix = null;
      Payer.prototype.federalTaxpayersIdNumber = null;
      Payer.prototype.naic = null;
      Payer.prototype.npi = null;
      Payer.prototype.centersForMedicareAndMedicaidPlanId = null;
      Payer.prototype.payorIdentification = null;
      Payer.prototype.contactInformation = null;
      Payer.prototype.aaaErrors = $util.emptyArray;
      Payer.prototype.etin = null;

      return Payer;
    })();

    v1.ContactInformation = (() => {
      class ContactInformation {
        constructor(properties) {
          this.contacts = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ContactInformation(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(10).string(message.name);
          }
          if (message.contacts != null && Object.hasOwnProperty.call(message, "contacts")) {
            for (const element of message.contacts) {
              $root.stedi.v1.Contact.encode(element, writer.uint32(18).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ContactInformation();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                if (!message.contacts || !message.contacts.length) {
                  message.contacts = [];
                }
                message.contacts.push($root.stedi.v1.Contact.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.ContactInformation) {
            return object;
          }
          const message = new $root.stedi.v1.ContactInformation();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.contacts) {
            if (!Array.isArray(object.contacts)) {
              throw new TypeError(".stedi.v1.ContactInformation.contacts: array type expected, but got " + (typeof object.contacts))
            }
            message.contacts = new Array(object.contacts.length);
            for (let i = 0; i < object.contacts.length; ++i) {
              if (typeof object.contacts[i] !== "object") {
                throw new TypeError(".stedi.v1.ContactInformation.contacts: object expected, but got " + (typeof object.contacts[i]));
              }
              message.contacts[i] = $root.stedi.v1.Contact.fromObject(object.contacts[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.contacts = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.contacts && message.contacts.length) {
            object.contacts = new Array(message.contacts.length);
            for (let i = 0; i < message.contacts.length; ++i) {
              object.contacts[i] = $root.stedi.v1.Contact.toObject(message.contacts[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.ContactInformation";
        }
      }

      ContactInformation.prototype.name = null;
      ContactInformation.prototype.contacts = $util.emptyArray;

      return ContactInformation;
    })();

    v1.Contact = (() => {
      class Contact {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Contact(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.communicationMode != null && Object.hasOwnProperty.call(message, "communicationMode")) {
            writer.uint32(10).string(message.communicationMode);
          }
          if (message.communicationNumber != null && Object.hasOwnProperty.call(message, "communicationNumber")) {
            writer.uint32(18).string(message.communicationNumber);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Contact();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.communicationMode = reader.string();
                break;
              }
              case 2: {
                message.communicationNumber = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.Contact) {
            return object;
          }
          const message = new $root.stedi.v1.Contact();
          if (object.communicationMode != null) {
            message.communicationMode = String(object.communicationMode);
          }
          if (object.communicationNumber != null) {
            message.communicationNumber = String(object.communicationNumber);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.communicationMode != null && message.hasOwnProperty("communicationMode")) {
            object.communicationMode = message.communicationMode;
          }
          if (message.communicationNumber != null && message.hasOwnProperty("communicationNumber")) {
            object.communicationNumber = message.communicationNumber;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.Contact";
        }
      }

      Contact.prototype.communicationMode = null;
      Contact.prototype.communicationNumber = null;

      return Contact;
    })();

    v1.AaaError = (() => {
      class AaaError {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new AaaError(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.code != null && Object.hasOwnProperty.call(message, "code")) {
            writer.uint32(10).string(message.code);
          }
          if (message.description != null && Object.hasOwnProperty.call(message, "description")) {
            writer.uint32(18).string(message.description);
          }
          if (message.location != null && Object.hasOwnProperty.call(message, "location")) {
            writer.uint32(26).string(message.location);
          }
          if (message.possibleResolutions != null && Object.hasOwnProperty.call(message, "possibleResolutions")) {
            writer.uint32(34).string(message.possibleResolutions);
          }
          if (message.followupAction != null && Object.hasOwnProperty.call(message, "followupAction")) {
            writer.uint32(42).string(message.followupAction);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new AaaError();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.code = reader.string();
                break;
              }
              case 2: {
                message.description = reader.string();
                break;
              }
              case 3: {
                message.location = reader.string();
                break;
              }
              case 4: {
                message.possibleResolutions = reader.string();
                break;
              }
              case 5: {
                message.followupAction = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.AaaError) {
            return object;
          }
          const message = new $root.stedi.v1.AaaError();
          if (object.code != null) {
            message.code = String(object.code);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.location != null) {
            message.location = String(object.location);
          }
          if (object.possibleResolutions != null) {
            message.possibleResolutions = String(object.possibleResolutions);
          }
          if (object.followupAction != null) {
            message.followupAction = String(object.followupAction);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.code != null && message.hasOwnProperty("code")) {
            object.code = message.code;
          }
          if (message.description != null && message.hasOwnProperty("description")) {
            object.description = message.description;
          }
          if (message.location != null && message.hasOwnProperty("location")) {
            object.location = message.location;
          }
          if (message.possibleResolutions != null && message.hasOwnProperty("possibleResolutions")) {
            object.possibleResolutions = message.possibleResolutions;
          }
          if (message.followupAction != null && message.hasOwnProperty("followupAction")) {
            object.followupAction = message.followupAction;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.AaaError";
        }
      }

      AaaError.prototype.code = null;
      AaaError.prototype.description = null;
      AaaError.prototype.location = null;
      AaaError.prototype.possibleResolutions = null;
      AaaError.prototype.followupAction = null;

      return AaaError;
    })();

    v1.PlanInformation = (() => {
      class PlanInformation {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PlanInformation(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.stateLicenseNumber != null && Object.hasOwnProperty.call(message, "stateLicenseNumber")) {
            writer.uint32(10).string(message.stateLicenseNumber);
          }
          if (message.medicareProviderNumber != null && Object.hasOwnProperty.call(message, "medicareProviderNumber")) {
            writer.uint32(18).string(message.medicareProviderNumber);
          }
          if (message.medicaidProviderNumber != null && Object.hasOwnProperty.call(message, "medicaidProviderNumber")) {
            writer.uint32(26).string(message.medicaidProviderNumber);
          }
          if (message.facilityIdNumber != null && Object.hasOwnProperty.call(message, "facilityIdNumber")) {
            writer.uint32(34).string(message.facilityIdNumber);
          }
          if (message.personalIdentificationNumber != null && Object.hasOwnProperty.call(message, "personalIdentificationNumber")) {
            writer.uint32(42).string(message.personalIdentificationNumber);
          }
          if (message.planNumber != null && Object.hasOwnProperty.call(message, "planNumber")) {
            writer.uint32(50).string(message.planNumber);
          }
          if (message.planDescription != null && Object.hasOwnProperty.call(message, "planDescription")) {
            writer.uint32(58).string(message.planDescription);
          }
          if (message.policyNumber != null && Object.hasOwnProperty.call(message, "policyNumber")) {
            writer.uint32(66).string(message.policyNumber);
          }
          if (message.memberId != null && Object.hasOwnProperty.call(message, "memberId")) {
            writer.uint32(74).string(message.memberId);
          }
          if (message.caseNumber != null && Object.hasOwnProperty.call(message, "caseNumber")) {
            writer.uint32(82).string(message.caseNumber);
          }
          if (message.familyUnitNumber != null && Object.hasOwnProperty.call(message, "familyUnitNumber")) {
            writer.uint32(90).string(message.familyUnitNumber);
          }
          if (message.groupNumber != null && Object.hasOwnProperty.call(message, "groupNumber")) {
            writer.uint32(98).string(message.groupNumber);
          }
          if (message.groupDescription != null && Object.hasOwnProperty.call(message, "groupDescription")) {
            writer.uint32(106).string(message.groupDescription);
          }
          if (message.referralNumber != null && Object.hasOwnProperty.call(message, "referralNumber")) {
            writer.uint32(114).string(message.referralNumber);
          }
          if (message.alternativeListId != null && Object.hasOwnProperty.call(message, "alternativeListId")) {
            writer.uint32(122).string(message.alternativeListId);
          }
          if (message.classOfContractCode != null && Object.hasOwnProperty.call(message, "classOfContractCode")) {
            writer.uint32(130).string(message.classOfContractCode);
          }
          if (message.coverageListId != null && Object.hasOwnProperty.call(message, "coverageListId")) {
            writer.uint32(138).string(message.coverageListId);
          }
          if (message.contractNumber != null && Object.hasOwnProperty.call(message, "contractNumber")) {
            writer.uint32(146).string(message.contractNumber);
          }
          if (message.medicalRecordIdentificationNumber != null && Object.hasOwnProperty.call(message, "medicalRecordIdentificationNumber")) {
            writer.uint32(154).string(message.medicalRecordIdentificationNumber);
          }
          if (message.electronicDevicePin != null && Object.hasOwnProperty.call(message, "electronicDevicePin")) {
            writer.uint32(162).string(message.electronicDevicePin);
          }
          if (message.submitterIdentificationNumber != null && Object.hasOwnProperty.call(message, "submitterIdentificationNumber")) {
            writer.uint32(170).string(message.submitterIdentificationNumber);
          }
          if (message.patientAccountNumber != null && Object.hasOwnProperty.call(message, "patientAccountNumber")) {
            writer.uint32(178).string(message.patientAccountNumber);
          }
          if (message.hicNumber != null && Object.hasOwnProperty.call(message, "hicNumber")) {
            writer.uint32(186).string(message.hicNumber);
          }
          if (message.drugFormularyNumber != null && Object.hasOwnProperty.call(message, "drugFormularyNumber")) {
            writer.uint32(194).string(message.drugFormularyNumber);
          }
          if (message.priorAuthorizationNumber != null && Object.hasOwnProperty.call(message, "priorAuthorizationNumber")) {
            writer.uint32(202).string(message.priorAuthorizationNumber);
          }
          if (message.idCardSerialNumber != null && Object.hasOwnProperty.call(message, "idCardSerialNumber")) {
            writer.uint32(210).string(message.idCardSerialNumber);
          }
          if (message.idCardNumber != null && Object.hasOwnProperty.call(message, "idCardNumber")) {
            writer.uint32(218).string(message.idCardNumber);
          }
          if (message.centersForMedicareAndMedicaidServicesNpi != null && Object.hasOwnProperty.call(message, "centersForMedicareAndMedicaidServicesNpi")) {
            writer.uint32(226).string(message.centersForMedicareAndMedicaidServicesNpi);
          }
          if (message.issueNumber != null && Object.hasOwnProperty.call(message, "issueNumber")) {
            writer.uint32(234).string(message.issueNumber);
          }
          if (message.insurancePolicyNumber != null && Object.hasOwnProperty.call(message, "insurancePolicyNumber")) {
            writer.uint32(242).string(message.insurancePolicyNumber);
          }
          if (message.userIdentification != null && Object.hasOwnProperty.call(message, "userIdentification")) {
            writer.uint32(250).string(message.userIdentification);
          }
          if (message.medicalAssistanceCategory != null && Object.hasOwnProperty.call(message, "medicalAssistanceCategory")) {
            writer.uint32(258).string(message.medicalAssistanceCategory);
          }
          if (message.eligibilityCategory != null && Object.hasOwnProperty.call(message, "eligibilityCategory")) {
            writer.uint32(266).string(message.eligibilityCategory);
          }
          if (message.planNetworkIdNumber != null && Object.hasOwnProperty.call(message, "planNetworkIdNumber")) {
            writer.uint32(274).string(message.planNetworkIdNumber);
          }
          if (message.planNetworkIdDescription != null && Object.hasOwnProperty.call(message, "planNetworkIdDescription")) {
            writer.uint32(282).string(message.planNetworkIdDescription);
          }
          if (message.facilityNetworkIdentificationNumber != null && Object.hasOwnProperty.call(message, "facilityNetworkIdentificationNumber")) {
            writer.uint32(290).string(message.facilityNetworkIdentificationNumber);
          }
          if (message.medicaidRecipientIdNumber != null && Object.hasOwnProperty.call(message, "medicaidRecipientIdNumber")) {
            writer.uint32(298).string(message.medicaidRecipientIdNumber);
          }
          if (message.priorIdNumber != null && Object.hasOwnProperty.call(message, "priorIdNumber")) {
            writer.uint32(306).string(message.priorIdNumber);
          }
          if (message.socialSecurityNumber != null && Object.hasOwnProperty.call(message, "socialSecurityNumber")) {
            writer.uint32(314).string(message.socialSecurityNumber);
          }
          if (message.federalTaxpayersIdentificationNumber != null && Object.hasOwnProperty.call(message, "federalTaxpayersIdentificationNumber")) {
            writer.uint32(322).string(message.federalTaxpayersIdentificationNumber);
          }
          if (message.agencyClaimNumber != null && Object.hasOwnProperty.call(message, "agencyClaimNumber")) {
            writer.uint32(330).string(message.agencyClaimNumber);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PlanInformation();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stateLicenseNumber = reader.string();
                break;
              }
              case 2: {
                message.medicareProviderNumber = reader.string();
                break;
              }
              case 3: {
                message.medicaidProviderNumber = reader.string();
                break;
              }
              case 4: {
                message.facilityIdNumber = reader.string();
                break;
              }
              case 5: {
                message.personalIdentificationNumber = reader.string();
                break;
              }
              case 6: {
                message.planNumber = reader.string();
                break;
              }
              case 7: {
                message.planDescription = reader.string();
                break;
              }
              case 8: {
                message.policyNumber = reader.string();
                break;
              }
              case 9: {
                message.memberId = reader.string();
                break;
              }
              case 10: {
                message.caseNumber = reader.string();
                break;
              }
              case 11: {
                message.familyUnitNumber = reader.string();
                break;
              }
              case 12: {
                message.groupNumber = reader.string();
                break;
              }
              case 13: {
                message.groupDescription = reader.string();
                break;
              }
              case 14: {
                message.referralNumber = reader.string();
                break;
              }
              case 15: {
                message.alternativeListId = reader.string();
                break;
              }
              case 16: {
                message.classOfContractCode = reader.string();
                break;
              }
              case 17: {
                message.coverageListId = reader.string();
                break;
              }
              case 18: {
                message.contractNumber = reader.string();
                break;
              }
              case 19: {
                message.medicalRecordIdentificationNumber = reader.string();
                break;
              }
              case 20: {
                message.electronicDevicePin = reader.string();
                break;
              }
              case 21: {
                message.submitterIdentificationNumber = reader.string();
                break;
              }
              case 22: {
                message.patientAccountNumber = reader.string();
                break;
              }
              case 23: {
                message.hicNumber = reader.string();
                break;
              }
              case 24: {
                message.drugFormularyNumber = reader.string();
                break;
              }
              case 25: {
                message.priorAuthorizationNumber = reader.string();
                break;
              }
              case 26: {
                message.idCardSerialNumber = reader.string();
                break;
              }
              case 27: {
                message.idCardNumber = reader.string();
                break;
              }
              case 28: {
                message.centersForMedicareAndMedicaidServicesNpi = reader.string();
                break;
              }
              case 29: {
                message.issueNumber = reader.string();
                break;
              }
              case 30: {
                message.insurancePolicyNumber = reader.string();
                break;
              }
              case 31: {
                message.userIdentification = reader.string();
                break;
              }
              case 32: {
                message.medicalAssistanceCategory = reader.string();
                break;
              }
              case 33: {
                message.eligibilityCategory = reader.string();
                break;
              }
              case 34: {
                message.planNetworkIdNumber = reader.string();
                break;
              }
              case 35: {
                message.planNetworkIdDescription = reader.string();
                break;
              }
              case 36: {
                message.facilityNetworkIdentificationNumber = reader.string();
                break;
              }
              case 37: {
                message.medicaidRecipientIdNumber = reader.string();
                break;
              }
              case 38: {
                message.priorIdNumber = reader.string();
                break;
              }
              case 39: {
                message.socialSecurityNumber = reader.string();
                break;
              }
              case 40: {
                message.federalTaxpayersIdentificationNumber = reader.string();
                break;
              }
              case 41: {
                message.agencyClaimNumber = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.PlanInformation) {
            return object;
          }
          const message = new $root.stedi.v1.PlanInformation();
          if (object.stateLicenseNumber != null) {
            message.stateLicenseNumber = String(object.stateLicenseNumber);
          }
          if (object.medicareProviderNumber != null) {
            message.medicareProviderNumber = String(object.medicareProviderNumber);
          }
          if (object.medicaidProviderNumber != null) {
            message.medicaidProviderNumber = String(object.medicaidProviderNumber);
          }
          if (object.facilityIdNumber != null) {
            message.facilityIdNumber = String(object.facilityIdNumber);
          }
          if (object.personalIdentificationNumber != null) {
            message.personalIdentificationNumber = String(object.personalIdentificationNumber);
          }
          if (object.planNumber != null) {
            message.planNumber = String(object.planNumber);
          }
          if (object.planDescription != null) {
            message.planDescription = String(object.planDescription);
          }
          if (object.policyNumber != null) {
            message.policyNumber = String(object.policyNumber);
          }
          if (object.memberId != null) {
            message.memberId = String(object.memberId);
          }
          if (object.caseNumber != null) {
            message.caseNumber = String(object.caseNumber);
          }
          if (object.familyUnitNumber != null) {
            message.familyUnitNumber = String(object.familyUnitNumber);
          }
          if (object.groupNumber != null) {
            message.groupNumber = String(object.groupNumber);
          }
          if (object.groupDescription != null) {
            message.groupDescription = String(object.groupDescription);
          }
          if (object.referralNumber != null) {
            message.referralNumber = String(object.referralNumber);
          }
          if (object.alternativeListId != null) {
            message.alternativeListId = String(object.alternativeListId);
          }
          if (object.classOfContractCode != null) {
            message.classOfContractCode = String(object.classOfContractCode);
          }
          if (object.coverageListId != null) {
            message.coverageListId = String(object.coverageListId);
          }
          if (object.contractNumber != null) {
            message.contractNumber = String(object.contractNumber);
          }
          if (object.medicalRecordIdentificationNumber != null) {
            message.medicalRecordIdentificationNumber = String(object.medicalRecordIdentificationNumber);
          }
          if (object.electronicDevicePin != null) {
            message.electronicDevicePin = String(object.electronicDevicePin);
          }
          if (object.submitterIdentificationNumber != null) {
            message.submitterIdentificationNumber = String(object.submitterIdentificationNumber);
          }
          if (object.patientAccountNumber != null) {
            message.patientAccountNumber = String(object.patientAccountNumber);
          }
          if (object.hicNumber != null) {
            message.hicNumber = String(object.hicNumber);
          }
          if (object.drugFormularyNumber != null) {
            message.drugFormularyNumber = String(object.drugFormularyNumber);
          }
          if (object.priorAuthorizationNumber != null) {
            message.priorAuthorizationNumber = String(object.priorAuthorizationNumber);
          }
          if (object.idCardSerialNumber != null) {
            message.idCardSerialNumber = String(object.idCardSerialNumber);
          }
          if (object.idCardNumber != null) {
            message.idCardNumber = String(object.idCardNumber);
          }
          if (object.centersForMedicareAndMedicaidServicesNpi != null) {
            message.centersForMedicareAndMedicaidServicesNpi = String(object.centersForMedicareAndMedicaidServicesNpi);
          }
          if (object.issueNumber != null) {
            message.issueNumber = String(object.issueNumber);
          }
          if (object.insurancePolicyNumber != null) {
            message.insurancePolicyNumber = String(object.insurancePolicyNumber);
          }
          if (object.userIdentification != null) {
            message.userIdentification = String(object.userIdentification);
          }
          if (object.medicalAssistanceCategory != null) {
            message.medicalAssistanceCategory = String(object.medicalAssistanceCategory);
          }
          if (object.eligibilityCategory != null) {
            message.eligibilityCategory = String(object.eligibilityCategory);
          }
          if (object.planNetworkIdNumber != null) {
            message.planNetworkIdNumber = String(object.planNetworkIdNumber);
          }
          if (object.planNetworkIdDescription != null) {
            message.planNetworkIdDescription = String(object.planNetworkIdDescription);
          }
          if (object.facilityNetworkIdentificationNumber != null) {
            message.facilityNetworkIdentificationNumber = String(object.facilityNetworkIdentificationNumber);
          }
          if (object.medicaidRecipientIdNumber != null) {
            message.medicaidRecipientIdNumber = String(object.medicaidRecipientIdNumber);
          }
          if (object.priorIdNumber != null) {
            message.priorIdNumber = String(object.priorIdNumber);
          }
          if (object.socialSecurityNumber != null) {
            message.socialSecurityNumber = String(object.socialSecurityNumber);
          }
          if (object.federalTaxpayersIdentificationNumber != null) {
            message.federalTaxpayersIdentificationNumber = String(object.federalTaxpayersIdentificationNumber);
          }
          if (object.agencyClaimNumber != null) {
            message.agencyClaimNumber = String(object.agencyClaimNumber);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.stateLicenseNumber != null && message.hasOwnProperty("stateLicenseNumber")) {
            object.stateLicenseNumber = message.stateLicenseNumber;
          }
          if (message.medicareProviderNumber != null && message.hasOwnProperty("medicareProviderNumber")) {
            object.medicareProviderNumber = message.medicareProviderNumber;
          }
          if (message.medicaidProviderNumber != null && message.hasOwnProperty("medicaidProviderNumber")) {
            object.medicaidProviderNumber = message.medicaidProviderNumber;
          }
          if (message.facilityIdNumber != null && message.hasOwnProperty("facilityIdNumber")) {
            object.facilityIdNumber = message.facilityIdNumber;
          }
          if (message.personalIdentificationNumber != null && message.hasOwnProperty("personalIdentificationNumber")) {
            object.personalIdentificationNumber = message.personalIdentificationNumber;
          }
          if (message.planNumber != null && message.hasOwnProperty("planNumber")) {
            object.planNumber = message.planNumber;
          }
          if (message.planDescription != null && message.hasOwnProperty("planDescription")) {
            object.planDescription = message.planDescription;
          }
          if (message.policyNumber != null && message.hasOwnProperty("policyNumber")) {
            object.policyNumber = message.policyNumber;
          }
          if (message.memberId != null && message.hasOwnProperty("memberId")) {
            object.memberId = message.memberId;
          }
          if (message.caseNumber != null && message.hasOwnProperty("caseNumber")) {
            object.caseNumber = message.caseNumber;
          }
          if (message.familyUnitNumber != null && message.hasOwnProperty("familyUnitNumber")) {
            object.familyUnitNumber = message.familyUnitNumber;
          }
          if (message.groupNumber != null && message.hasOwnProperty("groupNumber")) {
            object.groupNumber = message.groupNumber;
          }
          if (message.groupDescription != null && message.hasOwnProperty("groupDescription")) {
            object.groupDescription = message.groupDescription;
          }
          if (message.referralNumber != null && message.hasOwnProperty("referralNumber")) {
            object.referralNumber = message.referralNumber;
          }
          if (message.alternativeListId != null && message.hasOwnProperty("alternativeListId")) {
            object.alternativeListId = message.alternativeListId;
          }
          if (message.classOfContractCode != null && message.hasOwnProperty("classOfContractCode")) {
            object.classOfContractCode = message.classOfContractCode;
          }
          if (message.coverageListId != null && message.hasOwnProperty("coverageListId")) {
            object.coverageListId = message.coverageListId;
          }
          if (message.contractNumber != null && message.hasOwnProperty("contractNumber")) {
            object.contractNumber = message.contractNumber;
          }
          if (message.medicalRecordIdentificationNumber != null && message.hasOwnProperty("medicalRecordIdentificationNumber")) {
            object.medicalRecordIdentificationNumber = message.medicalRecordIdentificationNumber;
          }
          if (message.electronicDevicePin != null && message.hasOwnProperty("electronicDevicePin")) {
            object.electronicDevicePin = message.electronicDevicePin;
          }
          if (message.submitterIdentificationNumber != null && message.hasOwnProperty("submitterIdentificationNumber")) {
            object.submitterIdentificationNumber = message.submitterIdentificationNumber;
          }
          if (message.patientAccountNumber != null && message.hasOwnProperty("patientAccountNumber")) {
            object.patientAccountNumber = message.patientAccountNumber;
          }
          if (message.hicNumber != null && message.hasOwnProperty("hicNumber")) {
            object.hicNumber = message.hicNumber;
          }
          if (message.drugFormularyNumber != null && message.hasOwnProperty("drugFormularyNumber")) {
            object.drugFormularyNumber = message.drugFormularyNumber;
          }
          if (message.priorAuthorizationNumber != null && message.hasOwnProperty("priorAuthorizationNumber")) {
            object.priorAuthorizationNumber = message.priorAuthorizationNumber;
          }
          if (message.idCardSerialNumber != null && message.hasOwnProperty("idCardSerialNumber")) {
            object.idCardSerialNumber = message.idCardSerialNumber;
          }
          if (message.idCardNumber != null && message.hasOwnProperty("idCardNumber")) {
            object.idCardNumber = message.idCardNumber;
          }
          if (message.centersForMedicareAndMedicaidServicesNpi != null && message.hasOwnProperty("centersForMedicareAndMedicaidServicesNpi")) {
            object.centersForMedicareAndMedicaidServicesNpi = message.centersForMedicareAndMedicaidServicesNpi;
          }
          if (message.issueNumber != null && message.hasOwnProperty("issueNumber")) {
            object.issueNumber = message.issueNumber;
          }
          if (message.insurancePolicyNumber != null && message.hasOwnProperty("insurancePolicyNumber")) {
            object.insurancePolicyNumber = message.insurancePolicyNumber;
          }
          if (message.userIdentification != null && message.hasOwnProperty("userIdentification")) {
            object.userIdentification = message.userIdentification;
          }
          if (message.medicalAssistanceCategory != null && message.hasOwnProperty("medicalAssistanceCategory")) {
            object.medicalAssistanceCategory = message.medicalAssistanceCategory;
          }
          if (message.eligibilityCategory != null && message.hasOwnProperty("eligibilityCategory")) {
            object.eligibilityCategory = message.eligibilityCategory;
          }
          if (message.planNetworkIdNumber != null && message.hasOwnProperty("planNetworkIdNumber")) {
            object.planNetworkIdNumber = message.planNetworkIdNumber;
          }
          if (message.planNetworkIdDescription != null && message.hasOwnProperty("planNetworkIdDescription")) {
            object.planNetworkIdDescription = message.planNetworkIdDescription;
          }
          if (message.facilityNetworkIdentificationNumber != null && message.hasOwnProperty("facilityNetworkIdentificationNumber")) {
            object.facilityNetworkIdentificationNumber = message.facilityNetworkIdentificationNumber;
          }
          if (message.medicaidRecipientIdNumber != null && message.hasOwnProperty("medicaidRecipientIdNumber")) {
            object.medicaidRecipientIdNumber = message.medicaidRecipientIdNumber;
          }
          if (message.priorIdNumber != null && message.hasOwnProperty("priorIdNumber")) {
            object.priorIdNumber = message.priorIdNumber;
          }
          if (message.socialSecurityNumber != null && message.hasOwnProperty("socialSecurityNumber")) {
            object.socialSecurityNumber = message.socialSecurityNumber;
          }
          if (message.federalTaxpayersIdentificationNumber != null && message.hasOwnProperty("federalTaxpayersIdentificationNumber")) {
            object.federalTaxpayersIdentificationNumber = message.federalTaxpayersIdentificationNumber;
          }
          if (message.agencyClaimNumber != null && message.hasOwnProperty("agencyClaimNumber")) {
            object.agencyClaimNumber = message.agencyClaimNumber;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.PlanInformation";
        }
      }

      PlanInformation.prototype.stateLicenseNumber = null;
      PlanInformation.prototype.medicareProviderNumber = null;
      PlanInformation.prototype.medicaidProviderNumber = null;
      PlanInformation.prototype.facilityIdNumber = null;
      PlanInformation.prototype.personalIdentificationNumber = null;
      PlanInformation.prototype.planNumber = null;
      PlanInformation.prototype.planDescription = null;
      PlanInformation.prototype.policyNumber = null;
      PlanInformation.prototype.memberId = null;
      PlanInformation.prototype.caseNumber = null;
      PlanInformation.prototype.familyUnitNumber = null;
      PlanInformation.prototype.groupNumber = null;
      PlanInformation.prototype.groupDescription = null;
      PlanInformation.prototype.referralNumber = null;
      PlanInformation.prototype.alternativeListId = null;
      PlanInformation.prototype.classOfContractCode = null;
      PlanInformation.prototype.coverageListId = null;
      PlanInformation.prototype.contractNumber = null;
      PlanInformation.prototype.medicalRecordIdentificationNumber = null;
      PlanInformation.prototype.electronicDevicePin = null;
      PlanInformation.prototype.submitterIdentificationNumber = null;
      PlanInformation.prototype.patientAccountNumber = null;
      PlanInformation.prototype.hicNumber = null;
      PlanInformation.prototype.drugFormularyNumber = null;
      PlanInformation.prototype.priorAuthorizationNumber = null;
      PlanInformation.prototype.idCardSerialNumber = null;
      PlanInformation.prototype.idCardNumber = null;
      PlanInformation.prototype.centersForMedicareAndMedicaidServicesNpi = null;
      PlanInformation.prototype.issueNumber = null;
      PlanInformation.prototype.insurancePolicyNumber = null;
      PlanInformation.prototype.userIdentification = null;
      PlanInformation.prototype.medicalAssistanceCategory = null;
      PlanInformation.prototype.eligibilityCategory = null;
      PlanInformation.prototype.planNetworkIdNumber = null;
      PlanInformation.prototype.planNetworkIdDescription = null;
      PlanInformation.prototype.facilityNetworkIdentificationNumber = null;
      PlanInformation.prototype.medicaidRecipientIdNumber = null;
      PlanInformation.prototype.priorIdNumber = null;
      PlanInformation.prototype.socialSecurityNumber = null;
      PlanInformation.prototype.federalTaxpayersIdentificationNumber = null;
      PlanInformation.prototype.agencyClaimNumber = null;

      return PlanInformation;
    })();

    v1.PlanDateInformation = (() => {
      class PlanDateInformation {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PlanDateInformation(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.discharge != null && Object.hasOwnProperty.call(message, "discharge")) {
            values$1.v1.Date.encode(message.discharge, writer.uint32(10).fork()).ldelim();
          }
          if (message.issue != null && Object.hasOwnProperty.call(message, "issue")) {
            values$1.v1.Date.encode(message.issue, writer.uint32(18).fork()).ldelim();
          }
          if (message.effectiveDateOfChange != null && Object.hasOwnProperty.call(message, "effectiveDateOfChange")) {
            values$1.v1.Date.encode(message.effectiveDateOfChange, writer.uint32(26).fork()).ldelim();
          }
          if (message.plan != null && Object.hasOwnProperty.call(message, "plan")) {
            values$1.v1.Date.encode(message.plan, writer.uint32(34).fork()).ldelim();
          }
          if (message.eligibility != null && Object.hasOwnProperty.call(message, "eligibility")) {
            values$1.v1.Date.encode(message.eligibility, writer.uint32(42).fork()).ldelim();
          }
          if (message.added != null && Object.hasOwnProperty.call(message, "added")) {
            values$1.v1.Date.encode(message.added, writer.uint32(50).fork()).ldelim();
          }
          if (message.cobraBegin != null && Object.hasOwnProperty.call(message, "cobraBegin")) {
            values$1.v1.Date.encode(message.cobraBegin, writer.uint32(58).fork()).ldelim();
          }
          if (message.cobraEnd != null && Object.hasOwnProperty.call(message, "cobraEnd")) {
            values$1.v1.Date.encode(message.cobraEnd, writer.uint32(66).fork()).ldelim();
          }
          if (message.premiumPaidToDateBegin != null && Object.hasOwnProperty.call(message, "premiumPaidToDateBegin")) {
            values$1.v1.Date.encode(message.premiumPaidToDateBegin, writer.uint32(74).fork()).ldelim();
          }
          if (message.premiumPaidToDateEnd != null && Object.hasOwnProperty.call(message, "premiumPaidToDateEnd")) {
            values$1.v1.Date.encode(message.premiumPaidToDateEnd, writer.uint32(82).fork()).ldelim();
          }
          if (message.planBegin != null && Object.hasOwnProperty.call(message, "planBegin")) {
            values$1.v1.Date.encode(message.planBegin, writer.uint32(90).fork()).ldelim();
          }
          if (message.planEnd != null && Object.hasOwnProperty.call(message, "planEnd")) {
            values$1.v1.Date.encode(message.planEnd, writer.uint32(98).fork()).ldelim();
          }
          if (message.eligibilityBegin != null && Object.hasOwnProperty.call(message, "eligibilityBegin")) {
            values$1.v1.Date.encode(message.eligibilityBegin, writer.uint32(106).fork()).ldelim();
          }
          if (message.eligibilityEnd != null && Object.hasOwnProperty.call(message, "eligibilityEnd")) {
            values$1.v1.Date.encode(message.eligibilityEnd, writer.uint32(114).fork()).ldelim();
          }
          if (message.enrollment != null && Object.hasOwnProperty.call(message, "enrollment")) {
            values$1.v1.Date.encode(message.enrollment, writer.uint32(122).fork()).ldelim();
          }
          if (message.admission != null && Object.hasOwnProperty.call(message, "admission")) {
            values$1.v1.Date.encode(message.admission, writer.uint32(130).fork()).ldelim();
          }
          if (message.dateOfDeath != null && Object.hasOwnProperty.call(message, "dateOfDeath")) {
            values$1.v1.Date.encode(message.dateOfDeath, writer.uint32(138).fork()).ldelim();
          }
          if (message.certification != null && Object.hasOwnProperty.call(message, "certification")) {
            values$1.v1.Date.encode(message.certification, writer.uint32(146).fork()).ldelim();
          }
          if (message.service != null && Object.hasOwnProperty.call(message, "service")) {
            values$1.v1.Date.encode(message.service, writer.uint32(154).fork()).ldelim();
          }
          if (message.policyEffective != null && Object.hasOwnProperty.call(message, "policyEffective")) {
            values$1.v1.Date.encode(message.policyEffective, writer.uint32(162).fork()).ldelim();
          }
          if (message.policyExpiration != null && Object.hasOwnProperty.call(message, "policyExpiration")) {
            values$1.v1.Date.encode(message.policyExpiration, writer.uint32(170).fork()).ldelim();
          }
          if (message.dateOfLastUpdate != null && Object.hasOwnProperty.call(message, "dateOfLastUpdate")) {
            values$1.v1.Date.encode(message.dateOfLastUpdate, writer.uint32(178).fork()).ldelim();
          }
          if (message.status != null && Object.hasOwnProperty.call(message, "status")) {
            values$1.v1.Date.encode(message.status, writer.uint32(186).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PlanDateInformation();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.discharge = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.issue = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.effectiveDateOfChange = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.plan = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.eligibility = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.added = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.cobraBegin = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 8: {
                message.cobraEnd = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 9: {
                message.premiumPaidToDateBegin = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 10: {
                message.premiumPaidToDateEnd = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 11: {
                message.planBegin = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 12: {
                message.planEnd = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 13: {
                message.eligibilityBegin = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 14: {
                message.eligibilityEnd = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 15: {
                message.enrollment = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 16: {
                message.admission = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 17: {
                message.dateOfDeath = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 18: {
                message.certification = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 19: {
                message.service = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 20: {
                message.policyEffective = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 21: {
                message.policyExpiration = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 22: {
                message.dateOfLastUpdate = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 23: {
                message.status = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.PlanDateInformation) {
            return object;
          }
          const message = new $root.stedi.v1.PlanDateInformation();
          if (object.discharge != null) {
            if (typeof object.discharge !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.discharge: object expected, but got " + (typeof object.discharge));
            }
            message.discharge = values$1.v1.Date.fromObject(object.discharge);
          }
          if (object.issue != null) {
            if (typeof object.issue !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.issue: object expected, but got " + (typeof object.issue));
            }
            message.issue = values$1.v1.Date.fromObject(object.issue);
          }
          if (object.effectiveDateOfChange != null) {
            if (typeof object.effectiveDateOfChange !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.effectiveDateOfChange: object expected, but got " + (typeof object.effectiveDateOfChange));
            }
            message.effectiveDateOfChange = values$1.v1.Date.fromObject(object.effectiveDateOfChange);
          }
          if (object.plan != null) {
            if (typeof object.plan !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.plan: object expected, but got " + (typeof object.plan));
            }
            message.plan = values$1.v1.Date.fromObject(object.plan);
          }
          if (object.eligibility != null) {
            if (typeof object.eligibility !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.eligibility: object expected, but got " + (typeof object.eligibility));
            }
            message.eligibility = values$1.v1.Date.fromObject(object.eligibility);
          }
          if (object.added != null) {
            if (typeof object.added !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.added: object expected, but got " + (typeof object.added));
            }
            message.added = values$1.v1.Date.fromObject(object.added);
          }
          if (object.cobraBegin != null) {
            if (typeof object.cobraBegin !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.cobraBegin: object expected, but got " + (typeof object.cobraBegin));
            }
            message.cobraBegin = values$1.v1.Date.fromObject(object.cobraBegin);
          }
          if (object.cobraEnd != null) {
            if (typeof object.cobraEnd !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.cobraEnd: object expected, but got " + (typeof object.cobraEnd));
            }
            message.cobraEnd = values$1.v1.Date.fromObject(object.cobraEnd);
          }
          if (object.premiumPaidToDateBegin != null) {
            if (typeof object.premiumPaidToDateBegin !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.premiumPaidToDateBegin: object expected, but got " + (typeof object.premiumPaidToDateBegin));
            }
            message.premiumPaidToDateBegin = values$1.v1.Date.fromObject(object.premiumPaidToDateBegin);
          }
          if (object.premiumPaidToDateEnd != null) {
            if (typeof object.premiumPaidToDateEnd !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.premiumPaidToDateEnd: object expected, but got " + (typeof object.premiumPaidToDateEnd));
            }
            message.premiumPaidToDateEnd = values$1.v1.Date.fromObject(object.premiumPaidToDateEnd);
          }
          if (object.planBegin != null) {
            if (typeof object.planBegin !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.planBegin: object expected, but got " + (typeof object.planBegin));
            }
            message.planBegin = values$1.v1.Date.fromObject(object.planBegin);
          }
          if (object.planEnd != null) {
            if (typeof object.planEnd !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.planEnd: object expected, but got " + (typeof object.planEnd));
            }
            message.planEnd = values$1.v1.Date.fromObject(object.planEnd);
          }
          if (object.eligibilityBegin != null) {
            if (typeof object.eligibilityBegin !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.eligibilityBegin: object expected, but got " + (typeof object.eligibilityBegin));
            }
            message.eligibilityBegin = values$1.v1.Date.fromObject(object.eligibilityBegin);
          }
          if (object.eligibilityEnd != null) {
            if (typeof object.eligibilityEnd !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.eligibilityEnd: object expected, but got " + (typeof object.eligibilityEnd));
            }
            message.eligibilityEnd = values$1.v1.Date.fromObject(object.eligibilityEnd);
          }
          if (object.enrollment != null) {
            if (typeof object.enrollment !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.enrollment: object expected, but got " + (typeof object.enrollment));
            }
            message.enrollment = values$1.v1.Date.fromObject(object.enrollment);
          }
          if (object.admission != null) {
            if (typeof object.admission !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.admission: object expected, but got " + (typeof object.admission));
            }
            message.admission = values$1.v1.Date.fromObject(object.admission);
          }
          if (object.dateOfDeath != null) {
            if (typeof object.dateOfDeath !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.dateOfDeath: object expected, but got " + (typeof object.dateOfDeath));
            }
            message.dateOfDeath = values$1.v1.Date.fromObject(object.dateOfDeath);
          }
          if (object.certification != null) {
            if (typeof object.certification !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.certification: object expected, but got " + (typeof object.certification));
            }
            message.certification = values$1.v1.Date.fromObject(object.certification);
          }
          if (object.service != null) {
            if (typeof object.service !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.service: object expected, but got " + (typeof object.service));
            }
            message.service = values$1.v1.Date.fromObject(object.service);
          }
          if (object.policyEffective != null) {
            if (typeof object.policyEffective !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.policyEffective: object expected, but got " + (typeof object.policyEffective));
            }
            message.policyEffective = values$1.v1.Date.fromObject(object.policyEffective);
          }
          if (object.policyExpiration != null) {
            if (typeof object.policyExpiration !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.policyExpiration: object expected, but got " + (typeof object.policyExpiration));
            }
            message.policyExpiration = values$1.v1.Date.fromObject(object.policyExpiration);
          }
          if (object.dateOfLastUpdate != null) {
            if (typeof object.dateOfLastUpdate !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.dateOfLastUpdate: object expected, but got " + (typeof object.dateOfLastUpdate));
            }
            message.dateOfLastUpdate = values$1.v1.Date.fromObject(object.dateOfLastUpdate);
          }
          if (object.status != null) {
            if (typeof object.status !== "object") {
              throw new TypeError(".stedi.v1.PlanDateInformation.status: object expected, but got " + (typeof object.status));
            }
            message.status = values$1.v1.Date.fromObject(object.status);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.discharge != null && message.hasOwnProperty("discharge")) {
            object.discharge = values$1.v1.Date.toObject(message.discharge, options);
          }
          if (message.issue != null && message.hasOwnProperty("issue")) {
            object.issue = values$1.v1.Date.toObject(message.issue, options);
          }
          if (message.effectiveDateOfChange != null && message.hasOwnProperty("effectiveDateOfChange")) {
            object.effectiveDateOfChange = values$1.v1.Date.toObject(message.effectiveDateOfChange, options);
          }
          if (message.plan != null && message.hasOwnProperty("plan")) {
            object.plan = values$1.v1.Date.toObject(message.plan, options);
          }
          if (message.eligibility != null && message.hasOwnProperty("eligibility")) {
            object.eligibility = values$1.v1.Date.toObject(message.eligibility, options);
          }
          if (message.added != null && message.hasOwnProperty("added")) {
            object.added = values$1.v1.Date.toObject(message.added, options);
          }
          if (message.cobraBegin != null && message.hasOwnProperty("cobraBegin")) {
            object.cobraBegin = values$1.v1.Date.toObject(message.cobraBegin, options);
          }
          if (message.cobraEnd != null && message.hasOwnProperty("cobraEnd")) {
            object.cobraEnd = values$1.v1.Date.toObject(message.cobraEnd, options);
          }
          if (message.premiumPaidToDateBegin != null && message.hasOwnProperty("premiumPaidToDateBegin")) {
            object.premiumPaidToDateBegin = values$1.v1.Date.toObject(message.premiumPaidToDateBegin, options);
          }
          if (message.premiumPaidToDateEnd != null && message.hasOwnProperty("premiumPaidToDateEnd")) {
            object.premiumPaidToDateEnd = values$1.v1.Date.toObject(message.premiumPaidToDateEnd, options);
          }
          if (message.planBegin != null && message.hasOwnProperty("planBegin")) {
            object.planBegin = values$1.v1.Date.toObject(message.planBegin, options);
          }
          if (message.planEnd != null && message.hasOwnProperty("planEnd")) {
            object.planEnd = values$1.v1.Date.toObject(message.planEnd, options);
          }
          if (message.eligibilityBegin != null && message.hasOwnProperty("eligibilityBegin")) {
            object.eligibilityBegin = values$1.v1.Date.toObject(message.eligibilityBegin, options);
          }
          if (message.eligibilityEnd != null && message.hasOwnProperty("eligibilityEnd")) {
            object.eligibilityEnd = values$1.v1.Date.toObject(message.eligibilityEnd, options);
          }
          if (message.enrollment != null && message.hasOwnProperty("enrollment")) {
            object.enrollment = values$1.v1.Date.toObject(message.enrollment, options);
          }
          if (message.admission != null && message.hasOwnProperty("admission")) {
            object.admission = values$1.v1.Date.toObject(message.admission, options);
          }
          if (message.dateOfDeath != null && message.hasOwnProperty("dateOfDeath")) {
            object.dateOfDeath = values$1.v1.Date.toObject(message.dateOfDeath, options);
          }
          if (message.certification != null && message.hasOwnProperty("certification")) {
            object.certification = values$1.v1.Date.toObject(message.certification, options);
          }
          if (message.service != null && message.hasOwnProperty("service")) {
            object.service = values$1.v1.Date.toObject(message.service, options);
          }
          if (message.policyEffective != null && message.hasOwnProperty("policyEffective")) {
            object.policyEffective = values$1.v1.Date.toObject(message.policyEffective, options);
          }
          if (message.policyExpiration != null && message.hasOwnProperty("policyExpiration")) {
            object.policyExpiration = values$1.v1.Date.toObject(message.policyExpiration, options);
          }
          if (message.dateOfLastUpdate != null && message.hasOwnProperty("dateOfLastUpdate")) {
            object.dateOfLastUpdate = values$1.v1.Date.toObject(message.dateOfLastUpdate, options);
          }
          if (message.status != null && message.hasOwnProperty("status")) {
            object.status = values$1.v1.Date.toObject(message.status, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.PlanDateInformation";
        }
      }

      PlanDateInformation.prototype.discharge = null;
      PlanDateInformation.prototype.issue = null;
      PlanDateInformation.prototype.effectiveDateOfChange = null;
      PlanDateInformation.prototype.plan = null;
      PlanDateInformation.prototype.eligibility = null;
      PlanDateInformation.prototype.added = null;
      PlanDateInformation.prototype.cobraBegin = null;
      PlanDateInformation.prototype.cobraEnd = null;
      PlanDateInformation.prototype.premiumPaidToDateBegin = null;
      PlanDateInformation.prototype.premiumPaidToDateEnd = null;
      PlanDateInformation.prototype.planBegin = null;
      PlanDateInformation.prototype.planEnd = null;
      PlanDateInformation.prototype.eligibilityBegin = null;
      PlanDateInformation.prototype.eligibilityEnd = null;
      PlanDateInformation.prototype.enrollment = null;
      PlanDateInformation.prototype.admission = null;
      PlanDateInformation.prototype.dateOfDeath = null;
      PlanDateInformation.prototype.certification = null;
      PlanDateInformation.prototype.service = null;
      PlanDateInformation.prototype.policyEffective = null;
      PlanDateInformation.prototype.policyExpiration = null;
      PlanDateInformation.prototype.dateOfLastUpdate = null;
      PlanDateInformation.prototype.status = null;

      return PlanDateInformation;
    })();

    v1.Warning = (() => {
      class Warning {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Warning(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.code != null && Object.hasOwnProperty.call(message, "code")) {
            writer.uint32(10).string(message.code);
          }
          if (message.description != null && Object.hasOwnProperty.call(message, "description")) {
            writer.uint32(18).string(message.description);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Warning();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.code = reader.string();
                break;
              }
              case 2: {
                message.description = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.Warning) {
            return object;
          }
          const message = new $root.stedi.v1.Warning();
          if (object.code != null) {
            message.code = String(object.code);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.code != null && message.hasOwnProperty("code")) {
            object.code = message.code;
          }
          if (message.description != null && message.hasOwnProperty("description")) {
            object.description = message.description;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.Warning";
        }
      }

      Warning.prototype.code = null;
      Warning.prototype.description = null;

      return Warning;
    })();

    v1.DependentResponse = (() => {
      class DependentResponse {
        constructor(properties) {
          this.aaaErrors = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DependentResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.memberId != null && Object.hasOwnProperty.call(message, "memberId")) {
            writer.uint32(10).string(message.memberId);
          }
          if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName")) {
            writer.uint32(18).string(message.firstName);
          }
          if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName")) {
            writer.uint32(26).string(message.lastName);
          }
          if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName")) {
            writer.uint32(34).string(message.middleName);
          }
          if (message.suffix != null && Object.hasOwnProperty.call(message, "suffix")) {
            writer.uint32(42).string(message.suffix);
          }
          if (message.gender != null && Object.hasOwnProperty.call(message, "gender")) {
            writer.uint32(50).string(message.gender);
          }
          if (message.entityIdentifier != null && Object.hasOwnProperty.call(message, "entityIdentifier")) {
            writer.uint32(58).string(message.entityIdentifier);
          }
          if (message.entityType != null && Object.hasOwnProperty.call(message, "entityType")) {
            writer.uint32(66).string(message.entityType);
          }
          if (message.uniqueHealthIdentifier != null && Object.hasOwnProperty.call(message, "uniqueHealthIdentifier")) {
            writer.uint32(74).string(message.uniqueHealthIdentifier);
          }
          if (message.dateOfBirth != null && Object.hasOwnProperty.call(message, "dateOfBirth")) {
            values$1.v1.Date.encode(message.dateOfBirth, writer.uint32(82).fork()).ldelim();
          }
          if (message.informationStatusCode != null && Object.hasOwnProperty.call(message, "informationStatusCode")) {
            writer.uint32(90).string(message.informationStatusCode);
          }
          if (message.employmentStatusCode != null && Object.hasOwnProperty.call(message, "employmentStatusCode")) {
            writer.uint32(98).string(message.employmentStatusCode);
          }
          if (message.governmentServiceAffiliationCode != null && Object.hasOwnProperty.call(message, "governmentServiceAffiliationCode")) {
            writer.uint32(106).string(message.governmentServiceAffiliationCode);
          }
          if (message.description != null && Object.hasOwnProperty.call(message, "description")) {
            writer.uint32(114).string(message.description);
          }
          if (message.militaryServiceRankCode != null && Object.hasOwnProperty.call(message, "militaryServiceRankCode")) {
            writer.uint32(122).string(message.militaryServiceRankCode);
          }
          if (message.dateTimePeriodFormatQualifier != null && Object.hasOwnProperty.call(message, "dateTimePeriodFormatQualifier")) {
            writer.uint32(130).string(message.dateTimePeriodFormatQualifier);
          }
          if (message.dateTimePeriod != null && Object.hasOwnProperty.call(message, "dateTimePeriod")) {
            values$1.v1.Date.encode(message.dateTimePeriod, writer.uint32(138).fork()).ldelim();
          }
          if (message.startDateTimePeriod != null && Object.hasOwnProperty.call(message, "startDateTimePeriod")) {
            values$1.v1.Date.encode(message.startDateTimePeriod, writer.uint32(146).fork()).ldelim();
          }
          if (message.endDateTimePeriod != null && Object.hasOwnProperty.call(message, "endDateTimePeriod")) {
            values$1.v1.Date.encode(message.endDateTimePeriod, writer.uint32(154).fork()).ldelim();
          }
          if (message.ssn != null && Object.hasOwnProperty.call(message, "ssn")) {
            writer.uint32(162).string(message.ssn);
          }
          if (message.groupNumber != null && Object.hasOwnProperty.call(message, "groupNumber")) {
            writer.uint32(170).string(message.groupNumber);
          }
          if (message.planNumber != null && Object.hasOwnProperty.call(message, "planNumber")) {
            writer.uint32(178).string(message.planNumber);
          }
          if (message.planNetworkIdNumber != null && Object.hasOwnProperty.call(message, "planNetworkIdNumber")) {
            writer.uint32(186).string(message.planNetworkIdNumber);
          }
          if (message.groupDescription != null && Object.hasOwnProperty.call(message, "groupDescription")) {
            writer.uint32(194).string(message.groupDescription);
          }
          if (message.planDescription != null && Object.hasOwnProperty.call(message, "planDescription")) {
            writer.uint32(202).string(message.planDescription);
          }
          if (message.planNetworkDescription != null && Object.hasOwnProperty.call(message, "planNetworkDescription")) {
            writer.uint32(210).string(message.planNetworkDescription);
          }
          if (message.relationToSubscriber != null && Object.hasOwnProperty.call(message, "relationToSubscriber")) {
            writer.uint32(218).string(message.relationToSubscriber);
          }
          if (message.relationToSubscriberCode != null && Object.hasOwnProperty.call(message, "relationToSubscriberCode")) {
            writer.uint32(226).string(message.relationToSubscriberCode);
          }
          if (message.insuredIndicator != null && Object.hasOwnProperty.call(message, "insuredIndicator")) {
            writer.uint32(234).string(message.insuredIndicator);
          }
          if (message.maintenanceTypeCode != null && Object.hasOwnProperty.call(message, "maintenanceTypeCode")) {
            writer.uint32(242).string(message.maintenanceTypeCode);
          }
          if (message.maintenanceReasonCode != null && Object.hasOwnProperty.call(message, "maintenanceReasonCode")) {
            writer.uint32(250).string(message.maintenanceReasonCode);
          }
          if (message.birthSequenceNumber != null && Object.hasOwnProperty.call(message, "birthSequenceNumber")) {
            writer.uint32(258).string(message.birthSequenceNumber);
          }
          if (message.address != null && Object.hasOwnProperty.call(message, "address")) {
            $root.stedi.v1.Address.encode(message.address, writer.uint32(266).fork()).ldelim();
          }
          if (message.responseProvider != null && Object.hasOwnProperty.call(message, "responseProvider")) {
            $root.stedi.v1.Provider.encode(message.responseProvider, writer.uint32(274).fork()).ldelim();
          }
          if (message.aaaErrors != null && Object.hasOwnProperty.call(message, "aaaErrors")) {
            for (const element of message.aaaErrors) {
              $root.stedi.v1.AaaError.encode(element, writer.uint32(282).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DependentResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.memberId = reader.string();
                break;
              }
              case 2: {
                message.firstName = reader.string();
                break;
              }
              case 3: {
                message.lastName = reader.string();
                break;
              }
              case 4: {
                message.middleName = reader.string();
                break;
              }
              case 5: {
                message.suffix = reader.string();
                break;
              }
              case 6: {
                message.gender = reader.string();
                break;
              }
              case 7: {
                message.entityIdentifier = reader.string();
                break;
              }
              case 8: {
                message.entityType = reader.string();
                break;
              }
              case 9: {
                message.uniqueHealthIdentifier = reader.string();
                break;
              }
              case 10: {
                message.dateOfBirth = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 11: {
                message.informationStatusCode = reader.string();
                break;
              }
              case 12: {
                message.employmentStatusCode = reader.string();
                break;
              }
              case 13: {
                message.governmentServiceAffiliationCode = reader.string();
                break;
              }
              case 14: {
                message.description = reader.string();
                break;
              }
              case 15: {
                message.militaryServiceRankCode = reader.string();
                break;
              }
              case 16: {
                message.dateTimePeriodFormatQualifier = reader.string();
                break;
              }
              case 17: {
                message.dateTimePeriod = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 18: {
                message.startDateTimePeriod = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 19: {
                message.endDateTimePeriod = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 20: {
                message.ssn = reader.string();
                break;
              }
              case 21: {
                message.groupNumber = reader.string();
                break;
              }
              case 22: {
                message.planNumber = reader.string();
                break;
              }
              case 23: {
                message.planNetworkIdNumber = reader.string();
                break;
              }
              case 24: {
                message.groupDescription = reader.string();
                break;
              }
              case 25: {
                message.planDescription = reader.string();
                break;
              }
              case 26: {
                message.planNetworkDescription = reader.string();
                break;
              }
              case 27: {
                message.relationToSubscriber = reader.string();
                break;
              }
              case 28: {
                message.relationToSubscriberCode = reader.string();
                break;
              }
              case 29: {
                message.insuredIndicator = reader.string();
                break;
              }
              case 30: {
                message.maintenanceTypeCode = reader.string();
                break;
              }
              case 31: {
                message.maintenanceReasonCode = reader.string();
                break;
              }
              case 32: {
                message.birthSequenceNumber = reader.string();
                break;
              }
              case 33: {
                message.address = $root.stedi.v1.Address.decode(reader, reader.uint32());
                break;
              }
              case 34: {
                message.responseProvider = $root.stedi.v1.Provider.decode(reader, reader.uint32());
                break;
              }
              case 35: {
                if (!message.aaaErrors || !message.aaaErrors.length) {
                  message.aaaErrors = [];
                }
                message.aaaErrors.push($root.stedi.v1.AaaError.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.DependentResponse) {
            return object;
          }
          const message = new $root.stedi.v1.DependentResponse();
          if (object.memberId != null) {
            message.memberId = String(object.memberId);
          }
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.middleName != null) {
            message.middleName = String(object.middleName);
          }
          if (object.suffix != null) {
            message.suffix = String(object.suffix);
          }
          if (object.gender != null) {
            message.gender = String(object.gender);
          }
          if (object.entityIdentifier != null) {
            message.entityIdentifier = String(object.entityIdentifier);
          }
          if (object.entityType != null) {
            message.entityType = String(object.entityType);
          }
          if (object.uniqueHealthIdentifier != null) {
            message.uniqueHealthIdentifier = String(object.uniqueHealthIdentifier);
          }
          if (object.dateOfBirth != null) {
            if (typeof object.dateOfBirth !== "object") {
              throw new TypeError(".stedi.v1.DependentResponse.dateOfBirth: object expected, but got " + (typeof object.dateOfBirth));
            }
            message.dateOfBirth = values$1.v1.Date.fromObject(object.dateOfBirth);
          }
          if (object.informationStatusCode != null) {
            message.informationStatusCode = String(object.informationStatusCode);
          }
          if (object.employmentStatusCode != null) {
            message.employmentStatusCode = String(object.employmentStatusCode);
          }
          if (object.governmentServiceAffiliationCode != null) {
            message.governmentServiceAffiliationCode = String(object.governmentServiceAffiliationCode);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.militaryServiceRankCode != null) {
            message.militaryServiceRankCode = String(object.militaryServiceRankCode);
          }
          if (object.dateTimePeriodFormatQualifier != null) {
            message.dateTimePeriodFormatQualifier = String(object.dateTimePeriodFormatQualifier);
          }
          if (object.dateTimePeriod != null) {
            if (typeof object.dateTimePeriod !== "object") {
              throw new TypeError(".stedi.v1.DependentResponse.dateTimePeriod: object expected, but got " + (typeof object.dateTimePeriod));
            }
            message.dateTimePeriod = values$1.v1.Date.fromObject(object.dateTimePeriod);
          }
          if (object.startDateTimePeriod != null) {
            if (typeof object.startDateTimePeriod !== "object") {
              throw new TypeError(".stedi.v1.DependentResponse.startDateTimePeriod: object expected, but got " + (typeof object.startDateTimePeriod));
            }
            message.startDateTimePeriod = values$1.v1.Date.fromObject(object.startDateTimePeriod);
          }
          if (object.endDateTimePeriod != null) {
            if (typeof object.endDateTimePeriod !== "object") {
              throw new TypeError(".stedi.v1.DependentResponse.endDateTimePeriod: object expected, but got " + (typeof object.endDateTimePeriod));
            }
            message.endDateTimePeriod = values$1.v1.Date.fromObject(object.endDateTimePeriod);
          }
          if (object.ssn != null) {
            message.ssn = String(object.ssn);
          }
          if (object.groupNumber != null) {
            message.groupNumber = String(object.groupNumber);
          }
          if (object.planNumber != null) {
            message.planNumber = String(object.planNumber);
          }
          if (object.planNetworkIdNumber != null) {
            message.planNetworkIdNumber = String(object.planNetworkIdNumber);
          }
          if (object.groupDescription != null) {
            message.groupDescription = String(object.groupDescription);
          }
          if (object.planDescription != null) {
            message.planDescription = String(object.planDescription);
          }
          if (object.planNetworkDescription != null) {
            message.planNetworkDescription = String(object.planNetworkDescription);
          }
          if (object.relationToSubscriber != null) {
            message.relationToSubscriber = String(object.relationToSubscriber);
          }
          if (object.relationToSubscriberCode != null) {
            message.relationToSubscriberCode = String(object.relationToSubscriberCode);
          }
          if (object.insuredIndicator != null) {
            message.insuredIndicator = String(object.insuredIndicator);
          }
          if (object.maintenanceTypeCode != null) {
            message.maintenanceTypeCode = String(object.maintenanceTypeCode);
          }
          if (object.maintenanceReasonCode != null) {
            message.maintenanceReasonCode = String(object.maintenanceReasonCode);
          }
          if (object.birthSequenceNumber != null) {
            message.birthSequenceNumber = String(object.birthSequenceNumber);
          }
          if (object.address != null) {
            if (typeof object.address !== "object") {
              throw new TypeError(".stedi.v1.DependentResponse.address: object expected, but got " + (typeof object.address));
            }
            message.address = $root.stedi.v1.Address.fromObject(object.address);
          }
          if (object.responseProvider != null) {
            if (typeof object.responseProvider !== "object") {
              throw new TypeError(".stedi.v1.DependentResponse.responseProvider: object expected, but got " + (typeof object.responseProvider));
            }
            message.responseProvider = $root.stedi.v1.Provider.fromObject(object.responseProvider);
          }
          if (object.aaaErrors) {
            if (!Array.isArray(object.aaaErrors)) {
              throw new TypeError(".stedi.v1.DependentResponse.aaaErrors: array type expected, but got " + (typeof object.aaaErrors))
            }
            message.aaaErrors = new Array(object.aaaErrors.length);
            for (let i = 0; i < object.aaaErrors.length; ++i) {
              if (typeof object.aaaErrors[i] !== "object") {
                throw new TypeError(".stedi.v1.DependentResponse.aaaErrors: object expected, but got " + (typeof object.aaaErrors[i]));
              }
              message.aaaErrors[i] = $root.stedi.v1.AaaError.fromObject(object.aaaErrors[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.aaaErrors = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.memberId != null && message.hasOwnProperty("memberId")) {
            object.memberId = message.memberId;
          }
          if (message.firstName != null && message.hasOwnProperty("firstName")) {
            object.firstName = message.firstName;
          }
          if (message.lastName != null && message.hasOwnProperty("lastName")) {
            object.lastName = message.lastName;
          }
          if (message.middleName != null && message.hasOwnProperty("middleName")) {
            object.middleName = message.middleName;
          }
          if (message.suffix != null && message.hasOwnProperty("suffix")) {
            object.suffix = message.suffix;
          }
          if (message.gender != null && message.hasOwnProperty("gender")) {
            object.gender = message.gender;
          }
          if (message.entityIdentifier != null && message.hasOwnProperty("entityIdentifier")) {
            object.entityIdentifier = message.entityIdentifier;
          }
          if (message.entityType != null && message.hasOwnProperty("entityType")) {
            object.entityType = message.entityType;
          }
          if (message.uniqueHealthIdentifier != null && message.hasOwnProperty("uniqueHealthIdentifier")) {
            object.uniqueHealthIdentifier = message.uniqueHealthIdentifier;
          }
          if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth")) {
            object.dateOfBirth = values$1.v1.Date.toObject(message.dateOfBirth, options);
          }
          if (message.informationStatusCode != null && message.hasOwnProperty("informationStatusCode")) {
            object.informationStatusCode = message.informationStatusCode;
          }
          if (message.employmentStatusCode != null && message.hasOwnProperty("employmentStatusCode")) {
            object.employmentStatusCode = message.employmentStatusCode;
          }
          if (message.governmentServiceAffiliationCode != null && message.hasOwnProperty("governmentServiceAffiliationCode")) {
            object.governmentServiceAffiliationCode = message.governmentServiceAffiliationCode;
          }
          if (message.description != null && message.hasOwnProperty("description")) {
            object.description = message.description;
          }
          if (message.militaryServiceRankCode != null && message.hasOwnProperty("militaryServiceRankCode")) {
            object.militaryServiceRankCode = message.militaryServiceRankCode;
          }
          if (message.dateTimePeriodFormatQualifier != null && message.hasOwnProperty("dateTimePeriodFormatQualifier")) {
            object.dateTimePeriodFormatQualifier = message.dateTimePeriodFormatQualifier;
          }
          if (message.dateTimePeriod != null && message.hasOwnProperty("dateTimePeriod")) {
            object.dateTimePeriod = values$1.v1.Date.toObject(message.dateTimePeriod, options);
          }
          if (message.startDateTimePeriod != null && message.hasOwnProperty("startDateTimePeriod")) {
            object.startDateTimePeriod = values$1.v1.Date.toObject(message.startDateTimePeriod, options);
          }
          if (message.endDateTimePeriod != null && message.hasOwnProperty("endDateTimePeriod")) {
            object.endDateTimePeriod = values$1.v1.Date.toObject(message.endDateTimePeriod, options);
          }
          if (message.ssn != null && message.hasOwnProperty("ssn")) {
            object.ssn = message.ssn;
          }
          if (message.groupNumber != null && message.hasOwnProperty("groupNumber")) {
            object.groupNumber = message.groupNumber;
          }
          if (message.planNumber != null && message.hasOwnProperty("planNumber")) {
            object.planNumber = message.planNumber;
          }
          if (message.planNetworkIdNumber != null && message.hasOwnProperty("planNetworkIdNumber")) {
            object.planNetworkIdNumber = message.planNetworkIdNumber;
          }
          if (message.groupDescription != null && message.hasOwnProperty("groupDescription")) {
            object.groupDescription = message.groupDescription;
          }
          if (message.planDescription != null && message.hasOwnProperty("planDescription")) {
            object.planDescription = message.planDescription;
          }
          if (message.planNetworkDescription != null && message.hasOwnProperty("planNetworkDescription")) {
            object.planNetworkDescription = message.planNetworkDescription;
          }
          if (message.relationToSubscriber != null && message.hasOwnProperty("relationToSubscriber")) {
            object.relationToSubscriber = message.relationToSubscriber;
          }
          if (message.relationToSubscriberCode != null && message.hasOwnProperty("relationToSubscriberCode")) {
            object.relationToSubscriberCode = message.relationToSubscriberCode;
          }
          if (message.insuredIndicator != null && message.hasOwnProperty("insuredIndicator")) {
            object.insuredIndicator = message.insuredIndicator;
          }
          if (message.maintenanceTypeCode != null && message.hasOwnProperty("maintenanceTypeCode")) {
            object.maintenanceTypeCode = message.maintenanceTypeCode;
          }
          if (message.maintenanceReasonCode != null && message.hasOwnProperty("maintenanceReasonCode")) {
            object.maintenanceReasonCode = message.maintenanceReasonCode;
          }
          if (message.birthSequenceNumber != null && message.hasOwnProperty("birthSequenceNumber")) {
            object.birthSequenceNumber = message.birthSequenceNumber;
          }
          if (message.address != null && message.hasOwnProperty("address")) {
            object.address = $root.stedi.v1.Address.toObject(message.address, options);
          }
          if (message.responseProvider != null && message.hasOwnProperty("responseProvider")) {
            object.responseProvider = $root.stedi.v1.Provider.toObject(message.responseProvider, options);
          }
          if (message.aaaErrors && message.aaaErrors.length) {
            object.aaaErrors = new Array(message.aaaErrors.length);
            for (let i = 0; i < message.aaaErrors.length; ++i) {
              object.aaaErrors[i] = $root.stedi.v1.AaaError.toObject(message.aaaErrors[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.DependentResponse";
        }
      }

      DependentResponse.prototype.memberId = null;
      DependentResponse.prototype.firstName = null;
      DependentResponse.prototype.lastName = null;
      DependentResponse.prototype.middleName = null;
      DependentResponse.prototype.suffix = null;
      DependentResponse.prototype.gender = null;
      DependentResponse.prototype.entityIdentifier = null;
      DependentResponse.prototype.entityType = null;
      DependentResponse.prototype.uniqueHealthIdentifier = null;
      DependentResponse.prototype.dateOfBirth = null;
      DependentResponse.prototype.informationStatusCode = null;
      DependentResponse.prototype.employmentStatusCode = null;
      DependentResponse.prototype.governmentServiceAffiliationCode = null;
      DependentResponse.prototype.description = null;
      DependentResponse.prototype.militaryServiceRankCode = null;
      DependentResponse.prototype.dateTimePeriodFormatQualifier = null;
      DependentResponse.prototype.dateTimePeriod = null;
      DependentResponse.prototype.startDateTimePeriod = null;
      DependentResponse.prototype.endDateTimePeriod = null;
      DependentResponse.prototype.ssn = null;
      DependentResponse.prototype.groupNumber = null;
      DependentResponse.prototype.planNumber = null;
      DependentResponse.prototype.planNetworkIdNumber = null;
      DependentResponse.prototype.groupDescription = null;
      DependentResponse.prototype.planDescription = null;
      DependentResponse.prototype.planNetworkDescription = null;
      DependentResponse.prototype.relationToSubscriber = null;
      DependentResponse.prototype.relationToSubscriberCode = null;
      DependentResponse.prototype.insuredIndicator = null;
      DependentResponse.prototype.maintenanceTypeCode = null;
      DependentResponse.prototype.maintenanceReasonCode = null;
      DependentResponse.prototype.birthSequenceNumber = null;
      DependentResponse.prototype.address = null;
      DependentResponse.prototype.responseProvider = null;
      DependentResponse.prototype.aaaErrors = $util.emptyArray;

      return DependentResponse;
    })();

    v1.Benefits = (() => {
      class Benefits {
        constructor(properties) {
          this.serviceTypeCodes = [];
          this.benefitsServiceDelivery = [];
          this.additionalInformation = [];
          this.eligibilityAdditionalInformationList = [];
          this.benefitsRelatedEntities = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Benefits(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.code != null && Object.hasOwnProperty.call(message, "code")) {
            writer.uint32(10).string(message.code);
          }
          if (message.name != null && Object.hasOwnProperty.call(message, "name")) {
            writer.uint32(18).string(message.name);
          }
          if (message.coverageLevelCode != null && Object.hasOwnProperty.call(message, "coverageLevelCode")) {
            writer.uint32(26).string(message.coverageLevelCode);
          }
          if (message.coverageLevel != null && Object.hasOwnProperty.call(message, "coverageLevel")) {
            writer.uint32(34).string(message.coverageLevel);
          }
          if (message.serviceTypeCodes != null && Object.hasOwnProperty.call(message, "serviceTypeCodes")) {
            for (const element of message.serviceTypeCodes) {
              writer.uint32(42).string(element);
            }
          }
          if (message.insuranceTypeCode != null && Object.hasOwnProperty.call(message, "insuranceTypeCode")) {
            writer.uint32(50).string(message.insuranceTypeCode);
          }
          if (message.insuranceType != null && Object.hasOwnProperty.call(message, "insuranceType")) {
            writer.uint32(58).string(message.insuranceType);
          }
          if (message.planCoverage != null && Object.hasOwnProperty.call(message, "planCoverage")) {
            writer.uint32(66).string(message.planCoverage);
          }
          if (message.timeQualifierCode != null && Object.hasOwnProperty.call(message, "timeQualifierCode")) {
            writer.uint32(74).string(message.timeQualifierCode);
          }
          if (message.timeQualifier != null && Object.hasOwnProperty.call(message, "timeQualifier")) {
            writer.uint32(82).string(message.timeQualifier);
          }
          if (message.benefitAmount != null && Object.hasOwnProperty.call(message, "benefitAmount")) {
            writer.uint32(90).string(message.benefitAmount);
          }
          if (message.benefitPercent != null && Object.hasOwnProperty.call(message, "benefitPercent")) {
            writer.uint32(98).string(message.benefitPercent);
          }
          if (message.quantityQualifierCode != null && Object.hasOwnProperty.call(message, "quantityQualifierCode")) {
            writer.uint32(106).string(message.quantityQualifierCode);
          }
          if (message.quantityQualifier != null && Object.hasOwnProperty.call(message, "quantityQualifier")) {
            writer.uint32(114).string(message.quantityQualifier);
          }
          if (message.benefitQuantity != null && Object.hasOwnProperty.call(message, "benefitQuantity")) {
            writer.uint32(122).string(message.benefitQuantity);
          }
          if (message.authOrCertIndicator != null && Object.hasOwnProperty.call(message, "authOrCertIndicator")) {
            writer.uint32(130).string(message.authOrCertIndicator);
          }
          if (message.inPlanNetworkIndicator != null && Object.hasOwnProperty.call(message, "inPlanNetworkIndicator")) {
            writer.uint32(138).string(message.inPlanNetworkIndicator);
          }
          if (message.headerLoopIdentifier != null && Object.hasOwnProperty.call(message, "headerLoopIdentifier")) {
            writer.uint32(146).string(message.headerLoopIdentifier);
          }
          if (message.trailerLoopIdentifier != null && Object.hasOwnProperty.call(message, "trailerLoopIdentifier")) {
            writer.uint32(154).string(message.trailerLoopIdentifier);
          }
          if (message.compositeMedicalProcedureIdentifier != null && Object.hasOwnProperty.call(message, "compositeMedicalProcedureIdentifier")) {
            $root.stedi.v1.CompositeMedicalProcedureIdentifier.encode(message.compositeMedicalProcedureIdentifier, writer.uint32(162).fork()).ldelim();
          }
          if (message.benefitsAdditionalInformation != null && Object.hasOwnProperty.call(message, "benefitsAdditionalInformation")) {
            $root.stedi.v1.BenefitsAdditionalInformation.encode(message.benefitsAdditionalInformation, writer.uint32(170).fork()).ldelim();
          }
          if (message.benefitsServiceDelivery != null && Object.hasOwnProperty.call(message, "benefitsServiceDelivery")) {
            for (const element of message.benefitsServiceDelivery) {
              $root.stedi.v1.BenefitsServiceDelivery.encode(element, writer.uint32(234).fork()).ldelim();
            }
          }
          if (message.additionalInformation != null && Object.hasOwnProperty.call(message, "additionalInformation")) {
            for (const element of message.additionalInformation) {
              $root.stedi.v1.AdditionalInformationDescription.encode(element, writer.uint32(186).fork()).ldelim();
            }
          }
          if (message.eligibilityAdditionalInformationList != null && Object.hasOwnProperty.call(message, "eligibilityAdditionalInformationList")) {
            for (const element of message.eligibilityAdditionalInformationList) {
              $root.stedi.v1.EligibilityAdditionalInformation.encode(element, writer.uint32(194).fork()).ldelim();
            }
          }
          if (message.benefitsDateInformation != null && Object.hasOwnProperty.call(message, "benefitsDateInformation")) {
            $root.stedi.v1.BenefitsDateInformation.encode(message.benefitsDateInformation, writer.uint32(202).fork()).ldelim();
          }
          if (message.benefitsRelatedEntity != null && Object.hasOwnProperty.call(message, "benefitsRelatedEntity")) {
            $root.stedi.v1.BenefitsRelatedEntity.encode(message.benefitsRelatedEntity, writer.uint32(210).fork()).ldelim();
          }
          if (message.benefitsRelatedEntities != null && Object.hasOwnProperty.call(message, "benefitsRelatedEntities")) {
            for (const element of message.benefitsRelatedEntities) {
              $root.stedi.v1.BenefitsRelatedEntity.encode(element, writer.uint32(218).fork()).ldelim();
            }
          }
          if (message.eligibilityAdditionalInformation != null && Object.hasOwnProperty.call(message, "eligibilityAdditionalInformation")) {
            $root.stedi.v1.EligibilityAdditionalInformation.encode(message.eligibilityAdditionalInformation, writer.uint32(226).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Benefits();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.code = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.coverageLevelCode = reader.string();
                break;
              }
              case 4: {
                message.coverageLevel = reader.string();
                break;
              }
              case 5: {
                if (!message.serviceTypeCodes || !message.serviceTypeCodes.length) {
                  message.serviceTypeCodes = [];
                }
                message.serviceTypeCodes.push(reader.string());
                break;
              }
              case 6: {
                message.insuranceTypeCode = reader.string();
                break;
              }
              case 7: {
                message.insuranceType = reader.string();
                break;
              }
              case 8: {
                message.planCoverage = reader.string();
                break;
              }
              case 9: {
                message.timeQualifierCode = reader.string();
                break;
              }
              case 10: {
                message.timeQualifier = reader.string();
                break;
              }
              case 11: {
                message.benefitAmount = reader.string();
                break;
              }
              case 12: {
                message.benefitPercent = reader.string();
                break;
              }
              case 13: {
                message.quantityQualifierCode = reader.string();
                break;
              }
              case 14: {
                message.quantityQualifier = reader.string();
                break;
              }
              case 15: {
                message.benefitQuantity = reader.string();
                break;
              }
              case 16: {
                message.authOrCertIndicator = reader.string();
                break;
              }
              case 17: {
                message.inPlanNetworkIndicator = reader.string();
                break;
              }
              case 18: {
                message.headerLoopIdentifier = reader.string();
                break;
              }
              case 19: {
                message.trailerLoopIdentifier = reader.string();
                break;
              }
              case 20: {
                message.compositeMedicalProcedureIdentifier = $root.stedi.v1.CompositeMedicalProcedureIdentifier.decode(reader, reader.uint32());
                break;
              }
              case 21: {
                message.benefitsAdditionalInformation = $root.stedi.v1.BenefitsAdditionalInformation.decode(reader, reader.uint32());
                break;
              }
              case 29: {
                if (!message.benefitsServiceDelivery || !message.benefitsServiceDelivery.length) {
                  message.benefitsServiceDelivery = [];
                }
                message.benefitsServiceDelivery.push($root.stedi.v1.BenefitsServiceDelivery.decode(reader, reader.uint32()));
                break;
              }
              case 23: {
                if (!message.additionalInformation || !message.additionalInformation.length) {
                  message.additionalInformation = [];
                }
                message.additionalInformation.push($root.stedi.v1.AdditionalInformationDescription.decode(reader, reader.uint32()));
                break;
              }
              case 24: {
                if (!message.eligibilityAdditionalInformationList || !message.eligibilityAdditionalInformationList.length) {
                  message.eligibilityAdditionalInformationList = [];
                }
                message.eligibilityAdditionalInformationList.push($root.stedi.v1.EligibilityAdditionalInformation.decode(reader, reader.uint32()));
                break;
              }
              case 25: {
                message.benefitsDateInformation = $root.stedi.v1.BenefitsDateInformation.decode(reader, reader.uint32());
                break;
              }
              case 26: {
                message.benefitsRelatedEntity = $root.stedi.v1.BenefitsRelatedEntity.decode(reader, reader.uint32());
                break;
              }
              case 27: {
                if (!message.benefitsRelatedEntities || !message.benefitsRelatedEntities.length) {
                  message.benefitsRelatedEntities = [];
                }
                message.benefitsRelatedEntities.push($root.stedi.v1.BenefitsRelatedEntity.decode(reader, reader.uint32()));
                break;
              }
              case 28: {
                message.eligibilityAdditionalInformation = $root.stedi.v1.EligibilityAdditionalInformation.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.Benefits) {
            return object;
          }
          const message = new $root.stedi.v1.Benefits();
          if (object.code != null) {
            message.code = String(object.code);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.coverageLevelCode != null) {
            message.coverageLevelCode = String(object.coverageLevelCode);
          }
          if (object.coverageLevel != null) {
            message.coverageLevel = String(object.coverageLevel);
          }
          if (object.serviceTypeCodes) {
            if (!Array.isArray(object.serviceTypeCodes)) {
              throw new TypeError(".stedi.v1.Benefits.serviceTypeCodes: array type expected, but got " + (typeof object.serviceTypeCodes))
            }
            message.serviceTypeCodes = new Array(object.serviceTypeCodes.length);
            for (let i = 0; i < object.serviceTypeCodes.length; ++i) {
              message.serviceTypeCodes[i] = String(object.serviceTypeCodes[i]);
            }
          }
          if (object.insuranceTypeCode != null) {
            message.insuranceTypeCode = String(object.insuranceTypeCode);
          }
          if (object.insuranceType != null) {
            message.insuranceType = String(object.insuranceType);
          }
          if (object.planCoverage != null) {
            message.planCoverage = String(object.planCoverage);
          }
          if (object.timeQualifierCode != null) {
            message.timeQualifierCode = String(object.timeQualifierCode);
          }
          if (object.timeQualifier != null) {
            message.timeQualifier = String(object.timeQualifier);
          }
          if (object.benefitAmount != null) {
            message.benefitAmount = String(object.benefitAmount);
          }
          if (object.benefitPercent != null) {
            message.benefitPercent = String(object.benefitPercent);
          }
          if (object.quantityQualifierCode != null) {
            message.quantityQualifierCode = String(object.quantityQualifierCode);
          }
          if (object.quantityQualifier != null) {
            message.quantityQualifier = String(object.quantityQualifier);
          }
          if (object.benefitQuantity != null) {
            message.benefitQuantity = String(object.benefitQuantity);
          }
          if (object.authOrCertIndicator != null) {
            message.authOrCertIndicator = String(object.authOrCertIndicator);
          }
          if (object.inPlanNetworkIndicator != null) {
            message.inPlanNetworkIndicator = String(object.inPlanNetworkIndicator);
          }
          if (object.headerLoopIdentifier != null) {
            message.headerLoopIdentifier = String(object.headerLoopIdentifier);
          }
          if (object.trailerLoopIdentifier != null) {
            message.trailerLoopIdentifier = String(object.trailerLoopIdentifier);
          }
          if (object.compositeMedicalProcedureIdentifier != null) {
            if (typeof object.compositeMedicalProcedureIdentifier !== "object") {
              throw new TypeError(".stedi.v1.Benefits.compositeMedicalProcedureIdentifier: object expected, but got " + (typeof object.compositeMedicalProcedureIdentifier));
            }
            message.compositeMedicalProcedureIdentifier = $root.stedi.v1.CompositeMedicalProcedureIdentifier.fromObject(object.compositeMedicalProcedureIdentifier);
          }
          if (object.benefitsAdditionalInformation != null) {
            if (typeof object.benefitsAdditionalInformation !== "object") {
              throw new TypeError(".stedi.v1.Benefits.benefitsAdditionalInformation: object expected, but got " + (typeof object.benefitsAdditionalInformation));
            }
            message.benefitsAdditionalInformation = $root.stedi.v1.BenefitsAdditionalInformation.fromObject(object.benefitsAdditionalInformation);
          }
          if (object.benefitsServiceDelivery) {
            if (!Array.isArray(object.benefitsServiceDelivery)) {
              throw new TypeError(".stedi.v1.Benefits.benefitsServiceDelivery: array type expected, but got " + (typeof object.benefitsServiceDelivery))
            }
            message.benefitsServiceDelivery = new Array(object.benefitsServiceDelivery.length);
            for (let i = 0; i < object.benefitsServiceDelivery.length; ++i) {
              if (typeof object.benefitsServiceDelivery[i] !== "object") {
                throw new TypeError(".stedi.v1.Benefits.benefitsServiceDelivery: object expected, but got " + (typeof object.benefitsServiceDelivery[i]));
              }
              message.benefitsServiceDelivery[i] = $root.stedi.v1.BenefitsServiceDelivery.fromObject(object.benefitsServiceDelivery[i]);
            }
          }
          if (object.additionalInformation) {
            if (!Array.isArray(object.additionalInformation)) {
              throw new TypeError(".stedi.v1.Benefits.additionalInformation: array type expected, but got " + (typeof object.additionalInformation))
            }
            message.additionalInformation = new Array(object.additionalInformation.length);
            for (let i = 0; i < object.additionalInformation.length; ++i) {
              if (typeof object.additionalInformation[i] !== "object") {
                throw new TypeError(".stedi.v1.Benefits.additionalInformation: object expected, but got " + (typeof object.additionalInformation[i]));
              }
              message.additionalInformation[i] = $root.stedi.v1.AdditionalInformationDescription.fromObject(object.additionalInformation[i]);
            }
          }
          if (object.eligibilityAdditionalInformationList) {
            if (!Array.isArray(object.eligibilityAdditionalInformationList)) {
              throw new TypeError(".stedi.v1.Benefits.eligibilityAdditionalInformationList: array type expected, but got " + (typeof object.eligibilityAdditionalInformationList))
            }
            message.eligibilityAdditionalInformationList = new Array(object.eligibilityAdditionalInformationList.length);
            for (let i = 0; i < object.eligibilityAdditionalInformationList.length; ++i) {
              if (typeof object.eligibilityAdditionalInformationList[i] !== "object") {
                throw new TypeError(".stedi.v1.Benefits.eligibilityAdditionalInformationList: object expected, but got " + (typeof object.eligibilityAdditionalInformationList[i]));
              }
              message.eligibilityAdditionalInformationList[i] = $root.stedi.v1.EligibilityAdditionalInformation.fromObject(object.eligibilityAdditionalInformationList[i]);
            }
          }
          if (object.benefitsDateInformation != null) {
            if (typeof object.benefitsDateInformation !== "object") {
              throw new TypeError(".stedi.v1.Benefits.benefitsDateInformation: object expected, but got " + (typeof object.benefitsDateInformation));
            }
            message.benefitsDateInformation = $root.stedi.v1.BenefitsDateInformation.fromObject(object.benefitsDateInformation);
          }
          if (object.benefitsRelatedEntity != null) {
            if (typeof object.benefitsRelatedEntity !== "object") {
              throw new TypeError(".stedi.v1.Benefits.benefitsRelatedEntity: object expected, but got " + (typeof object.benefitsRelatedEntity));
            }
            message.benefitsRelatedEntity = $root.stedi.v1.BenefitsRelatedEntity.fromObject(object.benefitsRelatedEntity);
          }
          if (object.benefitsRelatedEntities) {
            if (!Array.isArray(object.benefitsRelatedEntities)) {
              throw new TypeError(".stedi.v1.Benefits.benefitsRelatedEntities: array type expected, but got " + (typeof object.benefitsRelatedEntities))
            }
            message.benefitsRelatedEntities = new Array(object.benefitsRelatedEntities.length);
            for (let i = 0; i < object.benefitsRelatedEntities.length; ++i) {
              if (typeof object.benefitsRelatedEntities[i] !== "object") {
                throw new TypeError(".stedi.v1.Benefits.benefitsRelatedEntities: object expected, but got " + (typeof object.benefitsRelatedEntities[i]));
              }
              message.benefitsRelatedEntities[i] = $root.stedi.v1.BenefitsRelatedEntity.fromObject(object.benefitsRelatedEntities[i]);
            }
          }
          if (object.eligibilityAdditionalInformation != null) {
            if (typeof object.eligibilityAdditionalInformation !== "object") {
              throw new TypeError(".stedi.v1.Benefits.eligibilityAdditionalInformation: object expected, but got " + (typeof object.eligibilityAdditionalInformation));
            }
            message.eligibilityAdditionalInformation = $root.stedi.v1.EligibilityAdditionalInformation.fromObject(object.eligibilityAdditionalInformation);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.serviceTypeCodes = [];
            object.benefitsServiceDelivery = [];
            object.additionalInformation = [];
            object.eligibilityAdditionalInformationList = [];
            object.benefitsRelatedEntities = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.code != null && message.hasOwnProperty("code")) {
            object.code = message.code;
          }
          if (message.name != null && message.hasOwnProperty("name")) {
            object.name = message.name;
          }
          if (message.coverageLevelCode != null && message.hasOwnProperty("coverageLevelCode")) {
            object.coverageLevelCode = message.coverageLevelCode;
          }
          if (message.coverageLevel != null && message.hasOwnProperty("coverageLevel")) {
            object.coverageLevel = message.coverageLevel;
          }
          if (message.serviceTypeCodes && message.serviceTypeCodes.length) {
            object.serviceTypeCodes = new Array(message.serviceTypeCodes.length);
            for (let i = 0; i < message.serviceTypeCodes.length; ++i) {
              object.serviceTypeCodes[i] = message.serviceTypeCodes[i];
            }
          }
          if (message.insuranceTypeCode != null && message.hasOwnProperty("insuranceTypeCode")) {
            object.insuranceTypeCode = message.insuranceTypeCode;
          }
          if (message.insuranceType != null && message.hasOwnProperty("insuranceType")) {
            object.insuranceType = message.insuranceType;
          }
          if (message.planCoverage != null && message.hasOwnProperty("planCoverage")) {
            object.planCoverage = message.planCoverage;
          }
          if (message.timeQualifierCode != null && message.hasOwnProperty("timeQualifierCode")) {
            object.timeQualifierCode = message.timeQualifierCode;
          }
          if (message.timeQualifier != null && message.hasOwnProperty("timeQualifier")) {
            object.timeQualifier = message.timeQualifier;
          }
          if (message.benefitAmount != null && message.hasOwnProperty("benefitAmount")) {
            object.benefitAmount = message.benefitAmount;
          }
          if (message.benefitPercent != null && message.hasOwnProperty("benefitPercent")) {
            object.benefitPercent = message.benefitPercent;
          }
          if (message.quantityQualifierCode != null && message.hasOwnProperty("quantityQualifierCode")) {
            object.quantityQualifierCode = message.quantityQualifierCode;
          }
          if (message.quantityQualifier != null && message.hasOwnProperty("quantityQualifier")) {
            object.quantityQualifier = message.quantityQualifier;
          }
          if (message.benefitQuantity != null && message.hasOwnProperty("benefitQuantity")) {
            object.benefitQuantity = message.benefitQuantity;
          }
          if (message.authOrCertIndicator != null && message.hasOwnProperty("authOrCertIndicator")) {
            object.authOrCertIndicator = message.authOrCertIndicator;
          }
          if (message.inPlanNetworkIndicator != null && message.hasOwnProperty("inPlanNetworkIndicator")) {
            object.inPlanNetworkIndicator = message.inPlanNetworkIndicator;
          }
          if (message.headerLoopIdentifier != null && message.hasOwnProperty("headerLoopIdentifier")) {
            object.headerLoopIdentifier = message.headerLoopIdentifier;
          }
          if (message.trailerLoopIdentifier != null && message.hasOwnProperty("trailerLoopIdentifier")) {
            object.trailerLoopIdentifier = message.trailerLoopIdentifier;
          }
          if (message.compositeMedicalProcedureIdentifier != null && message.hasOwnProperty("compositeMedicalProcedureIdentifier")) {
            object.compositeMedicalProcedureIdentifier = $root.stedi.v1.CompositeMedicalProcedureIdentifier.toObject(message.compositeMedicalProcedureIdentifier, options);
          }
          if (message.benefitsAdditionalInformation != null && message.hasOwnProperty("benefitsAdditionalInformation")) {
            object.benefitsAdditionalInformation = $root.stedi.v1.BenefitsAdditionalInformation.toObject(message.benefitsAdditionalInformation, options);
          }
          if (message.benefitsServiceDelivery && message.benefitsServiceDelivery.length) {
            object.benefitsServiceDelivery = new Array(message.benefitsServiceDelivery.length);
            for (let i = 0; i < message.benefitsServiceDelivery.length; ++i) {
              object.benefitsServiceDelivery[i] = $root.stedi.v1.BenefitsServiceDelivery.toObject(message.benefitsServiceDelivery[i], options);
            }
          }
          if (message.additionalInformation && message.additionalInformation.length) {
            object.additionalInformation = new Array(message.additionalInformation.length);
            for (let i = 0; i < message.additionalInformation.length; ++i) {
              object.additionalInformation[i] = $root.stedi.v1.AdditionalInformationDescription.toObject(message.additionalInformation[i], options);
            }
          }
          if (message.eligibilityAdditionalInformationList && message.eligibilityAdditionalInformationList.length) {
            object.eligibilityAdditionalInformationList = new Array(message.eligibilityAdditionalInformationList.length);
            for (let i = 0; i < message.eligibilityAdditionalInformationList.length; ++i) {
              object.eligibilityAdditionalInformationList[i] = $root.stedi.v1.EligibilityAdditionalInformation.toObject(message.eligibilityAdditionalInformationList[i], options);
            }
          }
          if (message.benefitsDateInformation != null && message.hasOwnProperty("benefitsDateInformation")) {
            object.benefitsDateInformation = $root.stedi.v1.BenefitsDateInformation.toObject(message.benefitsDateInformation, options);
          }
          if (message.benefitsRelatedEntity != null && message.hasOwnProperty("benefitsRelatedEntity")) {
            object.benefitsRelatedEntity = $root.stedi.v1.BenefitsRelatedEntity.toObject(message.benefitsRelatedEntity, options);
          }
          if (message.benefitsRelatedEntities && message.benefitsRelatedEntities.length) {
            object.benefitsRelatedEntities = new Array(message.benefitsRelatedEntities.length);
            for (let i = 0; i < message.benefitsRelatedEntities.length; ++i) {
              object.benefitsRelatedEntities[i] = $root.stedi.v1.BenefitsRelatedEntity.toObject(message.benefitsRelatedEntities[i], options);
            }
          }
          if (message.eligibilityAdditionalInformation != null && message.hasOwnProperty("eligibilityAdditionalInformation")) {
            object.eligibilityAdditionalInformation = $root.stedi.v1.EligibilityAdditionalInformation.toObject(message.eligibilityAdditionalInformation, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.Benefits";
        }
      }

      Benefits.prototype.code = null;
      Benefits.prototype.name = null;
      Benefits.prototype.coverageLevelCode = null;
      Benefits.prototype.coverageLevel = null;
      Benefits.prototype.serviceTypeCodes = $util.emptyArray;
      Benefits.prototype.insuranceTypeCode = null;
      Benefits.prototype.insuranceType = null;
      Benefits.prototype.planCoverage = null;
      Benefits.prototype.timeQualifierCode = null;
      Benefits.prototype.timeQualifier = null;
      Benefits.prototype.benefitAmount = null;
      Benefits.prototype.benefitPercent = null;
      Benefits.prototype.quantityQualifierCode = null;
      Benefits.prototype.quantityQualifier = null;
      Benefits.prototype.benefitQuantity = null;
      Benefits.prototype.authOrCertIndicator = null;
      Benefits.prototype.inPlanNetworkIndicator = null;
      Benefits.prototype.headerLoopIdentifier = null;
      Benefits.prototype.trailerLoopIdentifier = null;
      Benefits.prototype.compositeMedicalProcedureIdentifier = null;
      Benefits.prototype.benefitsAdditionalInformation = null;
      Benefits.prototype.benefitsServiceDelivery = $util.emptyArray;
      Benefits.prototype.additionalInformation = $util.emptyArray;
      Benefits.prototype.eligibilityAdditionalInformationList = $util.emptyArray;
      Benefits.prototype.benefitsDateInformation = null;
      Benefits.prototype.benefitsRelatedEntity = null;
      Benefits.prototype.benefitsRelatedEntities = $util.emptyArray;
      Benefits.prototype.eligibilityAdditionalInformation = null;

      return Benefits;
    })();

    v1.CompositeMedicalProcedureIdentifier = (() => {
      class CompositeMedicalProcedureIdentifier {
        constructor(properties) {
          this.procedureModifiers = [];
          this.diagnosisCodePointer = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CompositeMedicalProcedureIdentifier(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.productOrServiceIdQualifier != null && Object.hasOwnProperty.call(message, "productOrServiceIdQualifier")) {
            writer.uint32(10).string(message.productOrServiceIdQualifier);
          }
          if (message.procedureCode != null && Object.hasOwnProperty.call(message, "procedureCode")) {
            writer.uint32(18).string(message.procedureCode);
          }
          if (message.procedureModifiers != null && Object.hasOwnProperty.call(message, "procedureModifiers")) {
            for (const element of message.procedureModifiers) {
              writer.uint32(26).string(element);
            }
          }
          if (message.productOrServiceId != null && Object.hasOwnProperty.call(message, "productOrServiceId")) {
            writer.uint32(34).string(message.productOrServiceId);
          }
          if (message.diagnosisCodePointer != null && Object.hasOwnProperty.call(message, "diagnosisCodePointer")) {
            for (const element of message.diagnosisCodePointer) {
              writer.uint32(42).string(element);
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CompositeMedicalProcedureIdentifier();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.productOrServiceIdQualifier = reader.string();
                break;
              }
              case 2: {
                message.procedureCode = reader.string();
                break;
              }
              case 3: {
                if (!message.procedureModifiers || !message.procedureModifiers.length) {
                  message.procedureModifiers = [];
                }
                message.procedureModifiers.push(reader.string());
                break;
              }
              case 4: {
                message.productOrServiceId = reader.string();
                break;
              }
              case 5: {
                if (!message.diagnosisCodePointer || !message.diagnosisCodePointer.length) {
                  message.diagnosisCodePointer = [];
                }
                message.diagnosisCodePointer.push(reader.string());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.CompositeMedicalProcedureIdentifier) {
            return object;
          }
          const message = new $root.stedi.v1.CompositeMedicalProcedureIdentifier();
          if (object.productOrServiceIdQualifier != null) {
            message.productOrServiceIdQualifier = String(object.productOrServiceIdQualifier);
          }
          if (object.procedureCode != null) {
            message.procedureCode = String(object.procedureCode);
          }
          if (object.procedureModifiers) {
            if (!Array.isArray(object.procedureModifiers)) {
              throw new TypeError(".stedi.v1.CompositeMedicalProcedureIdentifier.procedureModifiers: array type expected, but got " + (typeof object.procedureModifiers))
            }
            message.procedureModifiers = new Array(object.procedureModifiers.length);
            for (let i = 0; i < object.procedureModifiers.length; ++i) {
              message.procedureModifiers[i] = String(object.procedureModifiers[i]);
            }
          }
          if (object.productOrServiceId != null) {
            message.productOrServiceId = String(object.productOrServiceId);
          }
          if (object.diagnosisCodePointer) {
            if (!Array.isArray(object.diagnosisCodePointer)) {
              throw new TypeError(".stedi.v1.CompositeMedicalProcedureIdentifier.diagnosisCodePointer: array type expected, but got " + (typeof object.diagnosisCodePointer))
            }
            message.diagnosisCodePointer = new Array(object.diagnosisCodePointer.length);
            for (let i = 0; i < object.diagnosisCodePointer.length; ++i) {
              message.diagnosisCodePointer[i] = String(object.diagnosisCodePointer[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.procedureModifiers = [];
            object.diagnosisCodePointer = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.productOrServiceIdQualifier != null && message.hasOwnProperty("productOrServiceIdQualifier")) {
            object.productOrServiceIdQualifier = message.productOrServiceIdQualifier;
          }
          if (message.procedureCode != null && message.hasOwnProperty("procedureCode")) {
            object.procedureCode = message.procedureCode;
          }
          if (message.procedureModifiers && message.procedureModifiers.length) {
            object.procedureModifiers = new Array(message.procedureModifiers.length);
            for (let i = 0; i < message.procedureModifiers.length; ++i) {
              object.procedureModifiers[i] = message.procedureModifiers[i];
            }
          }
          if (message.productOrServiceId != null && message.hasOwnProperty("productOrServiceId")) {
            object.productOrServiceId = message.productOrServiceId;
          }
          if (message.diagnosisCodePointer && message.diagnosisCodePointer.length) {
            object.diagnosisCodePointer = new Array(message.diagnosisCodePointer.length);
            for (let i = 0; i < message.diagnosisCodePointer.length; ++i) {
              object.diagnosisCodePointer[i] = message.diagnosisCodePointer[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.CompositeMedicalProcedureIdentifier";
        }
      }

      CompositeMedicalProcedureIdentifier.prototype.productOrServiceIdQualifier = null;
      CompositeMedicalProcedureIdentifier.prototype.procedureCode = null;
      CompositeMedicalProcedureIdentifier.prototype.procedureModifiers = $util.emptyArray;
      CompositeMedicalProcedureIdentifier.prototype.productOrServiceId = null;
      CompositeMedicalProcedureIdentifier.prototype.diagnosisCodePointer = $util.emptyArray;

      return CompositeMedicalProcedureIdentifier;
    })();

    v1.BenefitsAdditionalInformation = (() => {
      class BenefitsAdditionalInformation {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new BenefitsAdditionalInformation(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.planNumber != null && Object.hasOwnProperty.call(message, "planNumber")) {
            writer.uint32(10).string(message.planNumber);
          }
          if (message.policyNumber != null && Object.hasOwnProperty.call(message, "policyNumber")) {
            writer.uint32(18).string(message.policyNumber);
          }
          if (message.memberId != null && Object.hasOwnProperty.call(message, "memberId")) {
            writer.uint32(26).string(message.memberId);
          }
          if (message.familyUnitNumber != null && Object.hasOwnProperty.call(message, "familyUnitNumber")) {
            writer.uint32(34).string(message.familyUnitNumber);
          }
          if (message.groupNumber != null && Object.hasOwnProperty.call(message, "groupNumber")) {
            writer.uint32(42).string(message.groupNumber);
          }
          if (message.referralNumber != null && Object.hasOwnProperty.call(message, "referralNumber")) {
            writer.uint32(50).string(message.referralNumber);
          }
          if (message.alternativeListId != null && Object.hasOwnProperty.call(message, "alternativeListId")) {
            writer.uint32(58).string(message.alternativeListId);
          }
          if (message.coverageListId != null && Object.hasOwnProperty.call(message, "coverageListId")) {
            writer.uint32(66).string(message.coverageListId);
          }
          if (message.hicNumber != null && Object.hasOwnProperty.call(message, "hicNumber")) {
            writer.uint32(74).string(message.hicNumber);
          }
          if (message.drugFormularyNumber != null && Object.hasOwnProperty.call(message, "drugFormularyNumber")) {
            writer.uint32(82).string(message.drugFormularyNumber);
          }
          if (message.priorAuthorizationNumber != null && Object.hasOwnProperty.call(message, "priorAuthorizationNumber")) {
            writer.uint32(90).string(message.priorAuthorizationNumber);
          }
          if (message.insurancePolicyNumber != null && Object.hasOwnProperty.call(message, "insurancePolicyNumber")) {
            writer.uint32(98).string(message.insurancePolicyNumber);
          }
          if (message.medicalAssistanceCategory != null && Object.hasOwnProperty.call(message, "medicalAssistanceCategory")) {
            writer.uint32(106).string(message.medicalAssistanceCategory);
          }
          if (message.planNetworkIdNumber != null && Object.hasOwnProperty.call(message, "planNetworkIdNumber")) {
            writer.uint32(114).string(message.planNetworkIdNumber);
          }
          if (message.medicaidRecipientIdNumber != null && Object.hasOwnProperty.call(message, "medicaidRecipientIdNumber")) {
            writer.uint32(122).string(message.medicaidRecipientIdNumber);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new BenefitsAdditionalInformation();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.planNumber = reader.string();
                break;
              }
              case 2: {
                message.policyNumber = reader.string();
                break;
              }
              case 3: {
                message.memberId = reader.string();
                break;
              }
              case 4: {
                message.familyUnitNumber = reader.string();
                break;
              }
              case 5: {
                message.groupNumber = reader.string();
                break;
              }
              case 6: {
                message.referralNumber = reader.string();
                break;
              }
              case 7: {
                message.alternativeListId = reader.string();
                break;
              }
              case 8: {
                message.coverageListId = reader.string();
                break;
              }
              case 9: {
                message.hicNumber = reader.string();
                break;
              }
              case 10: {
                message.drugFormularyNumber = reader.string();
                break;
              }
              case 11: {
                message.priorAuthorizationNumber = reader.string();
                break;
              }
              case 12: {
                message.insurancePolicyNumber = reader.string();
                break;
              }
              case 13: {
                message.medicalAssistanceCategory = reader.string();
                break;
              }
              case 14: {
                message.planNetworkIdNumber = reader.string();
                break;
              }
              case 15: {
                message.medicaidRecipientIdNumber = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.BenefitsAdditionalInformation) {
            return object;
          }
          const message = new $root.stedi.v1.BenefitsAdditionalInformation();
          if (object.planNumber != null) {
            message.planNumber = String(object.planNumber);
          }
          if (object.policyNumber != null) {
            message.policyNumber = String(object.policyNumber);
          }
          if (object.memberId != null) {
            message.memberId = String(object.memberId);
          }
          if (object.familyUnitNumber != null) {
            message.familyUnitNumber = String(object.familyUnitNumber);
          }
          if (object.groupNumber != null) {
            message.groupNumber = String(object.groupNumber);
          }
          if (object.referralNumber != null) {
            message.referralNumber = String(object.referralNumber);
          }
          if (object.alternativeListId != null) {
            message.alternativeListId = String(object.alternativeListId);
          }
          if (object.coverageListId != null) {
            message.coverageListId = String(object.coverageListId);
          }
          if (object.hicNumber != null) {
            message.hicNumber = String(object.hicNumber);
          }
          if (object.drugFormularyNumber != null) {
            message.drugFormularyNumber = String(object.drugFormularyNumber);
          }
          if (object.priorAuthorizationNumber != null) {
            message.priorAuthorizationNumber = String(object.priorAuthorizationNumber);
          }
          if (object.insurancePolicyNumber != null) {
            message.insurancePolicyNumber = String(object.insurancePolicyNumber);
          }
          if (object.medicalAssistanceCategory != null) {
            message.medicalAssistanceCategory = String(object.medicalAssistanceCategory);
          }
          if (object.planNetworkIdNumber != null) {
            message.planNetworkIdNumber = String(object.planNetworkIdNumber);
          }
          if (object.medicaidRecipientIdNumber != null) {
            message.medicaidRecipientIdNumber = String(object.medicaidRecipientIdNumber);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.planNumber != null && message.hasOwnProperty("planNumber")) {
            object.planNumber = message.planNumber;
          }
          if (message.policyNumber != null && message.hasOwnProperty("policyNumber")) {
            object.policyNumber = message.policyNumber;
          }
          if (message.memberId != null && message.hasOwnProperty("memberId")) {
            object.memberId = message.memberId;
          }
          if (message.familyUnitNumber != null && message.hasOwnProperty("familyUnitNumber")) {
            object.familyUnitNumber = message.familyUnitNumber;
          }
          if (message.groupNumber != null && message.hasOwnProperty("groupNumber")) {
            object.groupNumber = message.groupNumber;
          }
          if (message.referralNumber != null && message.hasOwnProperty("referralNumber")) {
            object.referralNumber = message.referralNumber;
          }
          if (message.alternativeListId != null && message.hasOwnProperty("alternativeListId")) {
            object.alternativeListId = message.alternativeListId;
          }
          if (message.coverageListId != null && message.hasOwnProperty("coverageListId")) {
            object.coverageListId = message.coverageListId;
          }
          if (message.hicNumber != null && message.hasOwnProperty("hicNumber")) {
            object.hicNumber = message.hicNumber;
          }
          if (message.drugFormularyNumber != null && message.hasOwnProperty("drugFormularyNumber")) {
            object.drugFormularyNumber = message.drugFormularyNumber;
          }
          if (message.priorAuthorizationNumber != null && message.hasOwnProperty("priorAuthorizationNumber")) {
            object.priorAuthorizationNumber = message.priorAuthorizationNumber;
          }
          if (message.insurancePolicyNumber != null && message.hasOwnProperty("insurancePolicyNumber")) {
            object.insurancePolicyNumber = message.insurancePolicyNumber;
          }
          if (message.medicalAssistanceCategory != null && message.hasOwnProperty("medicalAssistanceCategory")) {
            object.medicalAssistanceCategory = message.medicalAssistanceCategory;
          }
          if (message.planNetworkIdNumber != null && message.hasOwnProperty("planNetworkIdNumber")) {
            object.planNetworkIdNumber = message.planNetworkIdNumber;
          }
          if (message.medicaidRecipientIdNumber != null && message.hasOwnProperty("medicaidRecipientIdNumber")) {
            object.medicaidRecipientIdNumber = message.medicaidRecipientIdNumber;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.BenefitsAdditionalInformation";
        }
      }

      BenefitsAdditionalInformation.prototype.planNumber = null;
      BenefitsAdditionalInformation.prototype.policyNumber = null;
      BenefitsAdditionalInformation.prototype.memberId = null;
      BenefitsAdditionalInformation.prototype.familyUnitNumber = null;
      BenefitsAdditionalInformation.prototype.groupNumber = null;
      BenefitsAdditionalInformation.prototype.referralNumber = null;
      BenefitsAdditionalInformation.prototype.alternativeListId = null;
      BenefitsAdditionalInformation.prototype.coverageListId = null;
      BenefitsAdditionalInformation.prototype.hicNumber = null;
      BenefitsAdditionalInformation.prototype.drugFormularyNumber = null;
      BenefitsAdditionalInformation.prototype.priorAuthorizationNumber = null;
      BenefitsAdditionalInformation.prototype.insurancePolicyNumber = null;
      BenefitsAdditionalInformation.prototype.medicalAssistanceCategory = null;
      BenefitsAdditionalInformation.prototype.planNetworkIdNumber = null;
      BenefitsAdditionalInformation.prototype.medicaidRecipientIdNumber = null;

      return BenefitsAdditionalInformation;
    })();

    v1.BenefitsServiceDelivery = (() => {
      class BenefitsServiceDelivery {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new BenefitsServiceDelivery(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.quantityQualifierCode != null && Object.hasOwnProperty.call(message, "quantityQualifierCode")) {
            writer.uint32(10).string(message.quantityQualifierCode);
          }
          if (message.quantityQualifier != null && Object.hasOwnProperty.call(message, "quantityQualifier")) {
            writer.uint32(18).string(message.quantityQualifier);
          }
          if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity")) {
            writer.uint32(26).string(message.quantity);
          }
          if (message.unitForMeasurementCode != null && Object.hasOwnProperty.call(message, "unitForMeasurementCode")) {
            writer.uint32(34).string(message.unitForMeasurementCode);
          }
          if (message.unitForMeasurementQualifierCode != null && Object.hasOwnProperty.call(message, "unitForMeasurementQualifierCode")) {
            writer.uint32(42).string(message.unitForMeasurementQualifierCode);
          }
          if (message.unitForMeasurementQualifier != null && Object.hasOwnProperty.call(message, "unitForMeasurementQualifier")) {
            writer.uint32(50).string(message.unitForMeasurementQualifier);
          }
          if (message.sampleSelectionModulus != null && Object.hasOwnProperty.call(message, "sampleSelectionModulus")) {
            writer.uint32(58).string(message.sampleSelectionModulus);
          }
          if (message.timePeriodQualifierCode != null && Object.hasOwnProperty.call(message, "timePeriodQualifierCode")) {
            writer.uint32(66).string(message.timePeriodQualifierCode);
          }
          if (message.timePeriodQualifier != null && Object.hasOwnProperty.call(message, "timePeriodQualifier")) {
            writer.uint32(74).string(message.timePeriodQualifier);
          }
          if (message.numOfPeriods != null && Object.hasOwnProperty.call(message, "numOfPeriods")) {
            writer.uint32(82).string(message.numOfPeriods);
          }
          if (message.deliveryOrCalendarPatternCode != null && Object.hasOwnProperty.call(message, "deliveryOrCalendarPatternCode")) {
            writer.uint32(90).string(message.deliveryOrCalendarPatternCode);
          }
          if (message.deliveryOrCalendarPatternQualifierCode != null && Object.hasOwnProperty.call(message, "deliveryOrCalendarPatternQualifierCode")) {
            writer.uint32(98).string(message.deliveryOrCalendarPatternQualifierCode);
          }
          if (message.deliveryOrCalendarPatternQualifier != null && Object.hasOwnProperty.call(message, "deliveryOrCalendarPatternQualifier")) {
            writer.uint32(106).string(message.deliveryOrCalendarPatternQualifier);
          }
          if (message.deliveryPatternTimeCode != null && Object.hasOwnProperty.call(message, "deliveryPatternTimeCode")) {
            writer.uint32(114).string(message.deliveryPatternTimeCode);
          }
          if (message.deliveryPatternTimeQualifierCode != null && Object.hasOwnProperty.call(message, "deliveryPatternTimeQualifierCode")) {
            writer.uint32(122).string(message.deliveryPatternTimeQualifierCode);
          }
          if (message.deliveryPatternTimeQualifier != null && Object.hasOwnProperty.call(message, "deliveryPatternTimeQualifier")) {
            writer.uint32(130).string(message.deliveryPatternTimeQualifier);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new BenefitsServiceDelivery();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.quantityQualifierCode = reader.string();
                break;
              }
              case 2: {
                message.quantityQualifier = reader.string();
                break;
              }
              case 3: {
                message.quantity = reader.string();
                break;
              }
              case 4: {
                message.unitForMeasurementCode = reader.string();
                break;
              }
              case 5: {
                message.unitForMeasurementQualifierCode = reader.string();
                break;
              }
              case 6: {
                message.unitForMeasurementQualifier = reader.string();
                break;
              }
              case 7: {
                message.sampleSelectionModulus = reader.string();
                break;
              }
              case 8: {
                message.timePeriodQualifierCode = reader.string();
                break;
              }
              case 9: {
                message.timePeriodQualifier = reader.string();
                break;
              }
              case 10: {
                message.numOfPeriods = reader.string();
                break;
              }
              case 11: {
                message.deliveryOrCalendarPatternCode = reader.string();
                break;
              }
              case 12: {
                message.deliveryOrCalendarPatternQualifierCode = reader.string();
                break;
              }
              case 13: {
                message.deliveryOrCalendarPatternQualifier = reader.string();
                break;
              }
              case 14: {
                message.deliveryPatternTimeCode = reader.string();
                break;
              }
              case 15: {
                message.deliveryPatternTimeQualifierCode = reader.string();
                break;
              }
              case 16: {
                message.deliveryPatternTimeQualifier = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.BenefitsServiceDelivery) {
            return object;
          }
          const message = new $root.stedi.v1.BenefitsServiceDelivery();
          if (object.quantityQualifierCode != null) {
            message.quantityQualifierCode = String(object.quantityQualifierCode);
          }
          if (object.quantityQualifier != null) {
            message.quantityQualifier = String(object.quantityQualifier);
          }
          if (object.quantity != null) {
            message.quantity = String(object.quantity);
          }
          if (object.unitForMeasurementCode != null) {
            message.unitForMeasurementCode = String(object.unitForMeasurementCode);
          }
          if (object.unitForMeasurementQualifierCode != null) {
            message.unitForMeasurementQualifierCode = String(object.unitForMeasurementQualifierCode);
          }
          if (object.unitForMeasurementQualifier != null) {
            message.unitForMeasurementQualifier = String(object.unitForMeasurementQualifier);
          }
          if (object.sampleSelectionModulus != null) {
            message.sampleSelectionModulus = String(object.sampleSelectionModulus);
          }
          if (object.timePeriodQualifierCode != null) {
            message.timePeriodQualifierCode = String(object.timePeriodQualifierCode);
          }
          if (object.timePeriodQualifier != null) {
            message.timePeriodQualifier = String(object.timePeriodQualifier);
          }
          if (object.numOfPeriods != null) {
            message.numOfPeriods = String(object.numOfPeriods);
          }
          if (object.deliveryOrCalendarPatternCode != null) {
            message.deliveryOrCalendarPatternCode = String(object.deliveryOrCalendarPatternCode);
          }
          if (object.deliveryOrCalendarPatternQualifierCode != null) {
            message.deliveryOrCalendarPatternQualifierCode = String(object.deliveryOrCalendarPatternQualifierCode);
          }
          if (object.deliveryOrCalendarPatternQualifier != null) {
            message.deliveryOrCalendarPatternQualifier = String(object.deliveryOrCalendarPatternQualifier);
          }
          if (object.deliveryPatternTimeCode != null) {
            message.deliveryPatternTimeCode = String(object.deliveryPatternTimeCode);
          }
          if (object.deliveryPatternTimeQualifierCode != null) {
            message.deliveryPatternTimeQualifierCode = String(object.deliveryPatternTimeQualifierCode);
          }
          if (object.deliveryPatternTimeQualifier != null) {
            message.deliveryPatternTimeQualifier = String(object.deliveryPatternTimeQualifier);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.quantityQualifierCode != null && message.hasOwnProperty("quantityQualifierCode")) {
            object.quantityQualifierCode = message.quantityQualifierCode;
          }
          if (message.quantityQualifier != null && message.hasOwnProperty("quantityQualifier")) {
            object.quantityQualifier = message.quantityQualifier;
          }
          if (message.quantity != null && message.hasOwnProperty("quantity")) {
            object.quantity = message.quantity;
          }
          if (message.unitForMeasurementCode != null && message.hasOwnProperty("unitForMeasurementCode")) {
            object.unitForMeasurementCode = message.unitForMeasurementCode;
          }
          if (message.unitForMeasurementQualifierCode != null && message.hasOwnProperty("unitForMeasurementQualifierCode")) {
            object.unitForMeasurementQualifierCode = message.unitForMeasurementQualifierCode;
          }
          if (message.unitForMeasurementQualifier != null && message.hasOwnProperty("unitForMeasurementQualifier")) {
            object.unitForMeasurementQualifier = message.unitForMeasurementQualifier;
          }
          if (message.sampleSelectionModulus != null && message.hasOwnProperty("sampleSelectionModulus")) {
            object.sampleSelectionModulus = message.sampleSelectionModulus;
          }
          if (message.timePeriodQualifierCode != null && message.hasOwnProperty("timePeriodQualifierCode")) {
            object.timePeriodQualifierCode = message.timePeriodQualifierCode;
          }
          if (message.timePeriodQualifier != null && message.hasOwnProperty("timePeriodQualifier")) {
            object.timePeriodQualifier = message.timePeriodQualifier;
          }
          if (message.numOfPeriods != null && message.hasOwnProperty("numOfPeriods")) {
            object.numOfPeriods = message.numOfPeriods;
          }
          if (message.deliveryOrCalendarPatternCode != null && message.hasOwnProperty("deliveryOrCalendarPatternCode")) {
            object.deliveryOrCalendarPatternCode = message.deliveryOrCalendarPatternCode;
          }
          if (message.deliveryOrCalendarPatternQualifierCode != null && message.hasOwnProperty("deliveryOrCalendarPatternQualifierCode")) {
            object.deliveryOrCalendarPatternQualifierCode = message.deliveryOrCalendarPatternQualifierCode;
          }
          if (message.deliveryOrCalendarPatternQualifier != null && message.hasOwnProperty("deliveryOrCalendarPatternQualifier")) {
            object.deliveryOrCalendarPatternQualifier = message.deliveryOrCalendarPatternQualifier;
          }
          if (message.deliveryPatternTimeCode != null && message.hasOwnProperty("deliveryPatternTimeCode")) {
            object.deliveryPatternTimeCode = message.deliveryPatternTimeCode;
          }
          if (message.deliveryPatternTimeQualifierCode != null && message.hasOwnProperty("deliveryPatternTimeQualifierCode")) {
            object.deliveryPatternTimeQualifierCode = message.deliveryPatternTimeQualifierCode;
          }
          if (message.deliveryPatternTimeQualifier != null && message.hasOwnProperty("deliveryPatternTimeQualifier")) {
            object.deliveryPatternTimeQualifier = message.deliveryPatternTimeQualifier;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.BenefitsServiceDelivery";
        }
      }

      BenefitsServiceDelivery.prototype.quantityQualifierCode = null;
      BenefitsServiceDelivery.prototype.quantityQualifier = null;
      BenefitsServiceDelivery.prototype.quantity = null;
      BenefitsServiceDelivery.prototype.unitForMeasurementCode = null;
      BenefitsServiceDelivery.prototype.unitForMeasurementQualifierCode = null;
      BenefitsServiceDelivery.prototype.unitForMeasurementQualifier = null;
      BenefitsServiceDelivery.prototype.sampleSelectionModulus = null;
      BenefitsServiceDelivery.prototype.timePeriodQualifierCode = null;
      BenefitsServiceDelivery.prototype.timePeriodQualifier = null;
      BenefitsServiceDelivery.prototype.numOfPeriods = null;
      BenefitsServiceDelivery.prototype.deliveryOrCalendarPatternCode = null;
      BenefitsServiceDelivery.prototype.deliveryOrCalendarPatternQualifierCode = null;
      BenefitsServiceDelivery.prototype.deliveryOrCalendarPatternQualifier = null;
      BenefitsServiceDelivery.prototype.deliveryPatternTimeCode = null;
      BenefitsServiceDelivery.prototype.deliveryPatternTimeQualifierCode = null;
      BenefitsServiceDelivery.prototype.deliveryPatternTimeQualifier = null;

      return BenefitsServiceDelivery;
    })();

    v1.EligibilityAdditionalInformation = (() => {
      class EligibilityAdditionalInformation {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new EligibilityAdditionalInformation(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.codeListQualifierCode != null && Object.hasOwnProperty.call(message, "codeListQualifierCode")) {
            writer.uint32(10).string(message.codeListQualifierCode);
          }
          if (message.codeListQualifier != null && Object.hasOwnProperty.call(message, "codeListQualifier")) {
            writer.uint32(18).string(message.codeListQualifier);
          }
          if (message.industryCode != null && Object.hasOwnProperty.call(message, "industryCode")) {
            writer.uint32(26).string(message.industryCode);
          }
          if (message.industry != null && Object.hasOwnProperty.call(message, "industry")) {
            writer.uint32(34).string(message.industry);
          }
          if (message.codeCategory != null && Object.hasOwnProperty.call(message, "codeCategory")) {
            writer.uint32(42).string(message.codeCategory);
          }
          if (message.injuredBodyPartName != null && Object.hasOwnProperty.call(message, "injuredBodyPartName")) {
            writer.uint32(50).string(message.injuredBodyPartName);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new EligibilityAdditionalInformation();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.codeListQualifierCode = reader.string();
                break;
              }
              case 2: {
                message.codeListQualifier = reader.string();
                break;
              }
              case 3: {
                message.industryCode = reader.string();
                break;
              }
              case 4: {
                message.industry = reader.string();
                break;
              }
              case 5: {
                message.codeCategory = reader.string();
                break;
              }
              case 6: {
                message.injuredBodyPartName = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.EligibilityAdditionalInformation) {
            return object;
          }
          const message = new $root.stedi.v1.EligibilityAdditionalInformation();
          if (object.codeListQualifierCode != null) {
            message.codeListQualifierCode = String(object.codeListQualifierCode);
          }
          if (object.codeListQualifier != null) {
            message.codeListQualifier = String(object.codeListQualifier);
          }
          if (object.industryCode != null) {
            message.industryCode = String(object.industryCode);
          }
          if (object.industry != null) {
            message.industry = String(object.industry);
          }
          if (object.codeCategory != null) {
            message.codeCategory = String(object.codeCategory);
          }
          if (object.injuredBodyPartName != null) {
            message.injuredBodyPartName = String(object.injuredBodyPartName);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.codeListQualifierCode != null && message.hasOwnProperty("codeListQualifierCode")) {
            object.codeListQualifierCode = message.codeListQualifierCode;
          }
          if (message.codeListQualifier != null && message.hasOwnProperty("codeListQualifier")) {
            object.codeListQualifier = message.codeListQualifier;
          }
          if (message.industryCode != null && message.hasOwnProperty("industryCode")) {
            object.industryCode = message.industryCode;
          }
          if (message.industry != null && message.hasOwnProperty("industry")) {
            object.industry = message.industry;
          }
          if (message.codeCategory != null && message.hasOwnProperty("codeCategory")) {
            object.codeCategory = message.codeCategory;
          }
          if (message.injuredBodyPartName != null && message.hasOwnProperty("injuredBodyPartName")) {
            object.injuredBodyPartName = message.injuredBodyPartName;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.EligibilityAdditionalInformation";
        }
      }

      EligibilityAdditionalInformation.prototype.codeListQualifierCode = null;
      EligibilityAdditionalInformation.prototype.codeListQualifier = null;
      EligibilityAdditionalInformation.prototype.industryCode = null;
      EligibilityAdditionalInformation.prototype.industry = null;
      EligibilityAdditionalInformation.prototype.codeCategory = null;
      EligibilityAdditionalInformation.prototype.injuredBodyPartName = null;

      return EligibilityAdditionalInformation;
    })();

    v1.BenefitsDateInformation = (() => {
      class BenefitsDateInformation {
        constructor(properties) {
          this.discharges = [];
          this.admission = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new BenefitsDateInformation(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.discharges != null && Object.hasOwnProperty.call(message, "discharges")) {
            for (const element of message.discharges) {
              $root.stedi.v1.Discharge.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          if (message.issue != null && Object.hasOwnProperty.call(message, "issue")) {
            values$1.v1.Date.encode(message.issue, writer.uint32(18).fork()).ldelim();
          }
          if (message.effectiveDateOfChange != null && Object.hasOwnProperty.call(message, "effectiveDateOfChange")) {
            values$1.v1.Date.encode(message.effectiveDateOfChange, writer.uint32(26).fork()).ldelim();
          }
          if (message.periodStart != null && Object.hasOwnProperty.call(message, "periodStart")) {
            values$1.v1.Date.encode(message.periodStart, writer.uint32(34).fork()).ldelim();
          }
          if (message.periodEnd != null && Object.hasOwnProperty.call(message, "periodEnd")) {
            values$1.v1.Date.encode(message.periodEnd, writer.uint32(42).fork()).ldelim();
          }
          if (message.completion != null && Object.hasOwnProperty.call(message, "completion")) {
            values$1.v1.Date.encode(message.completion, writer.uint32(50).fork()).ldelim();
          }
          if (message.coordinationOfBenefits != null && Object.hasOwnProperty.call(message, "coordinationOfBenefits")) {
            values$1.v1.Date.encode(message.coordinationOfBenefits, writer.uint32(58).fork()).ldelim();
          }
          if (message.plan != null && Object.hasOwnProperty.call(message, "plan")) {
            values$1.v1.Date.encode(message.plan, writer.uint32(66).fork()).ldelim();
          }
          if (message.benefit != null && Object.hasOwnProperty.call(message, "benefit")) {
            values$1.v1.Date.encode(message.benefit, writer.uint32(74).fork()).ldelim();
          }
          if (message.primaryCareProvider != null && Object.hasOwnProperty.call(message, "primaryCareProvider")) {
            values$1.v1.Date.encode(message.primaryCareProvider, writer.uint32(82).fork()).ldelim();
          }
          if (message.latestVisitOrConsultation != null && Object.hasOwnProperty.call(message, "latestVisitOrConsultation")) {
            values$1.v1.Date.encode(message.latestVisitOrConsultation, writer.uint32(90).fork()).ldelim();
          }
          if (message.eligibility != null && Object.hasOwnProperty.call(message, "eligibility")) {
            values$1.v1.Date.encode(message.eligibility, writer.uint32(98).fork()).ldelim();
          }
          if (message.added != null && Object.hasOwnProperty.call(message, "added")) {
            values$1.v1.Date.encode(message.added, writer.uint32(106).fork()).ldelim();
          }
          if (message.cobraBegin != null && Object.hasOwnProperty.call(message, "cobraBegin")) {
            values$1.v1.Date.encode(message.cobraBegin, writer.uint32(114).fork()).ldelim();
          }
          if (message.cobraEnd != null && Object.hasOwnProperty.call(message, "cobraEnd")) {
            values$1.v1.Date.encode(message.cobraEnd, writer.uint32(122).fork()).ldelim();
          }
          if (message.premiumPaidToDateBegin != null && Object.hasOwnProperty.call(message, "premiumPaidToDateBegin")) {
            values$1.v1.Date.encode(message.premiumPaidToDateBegin, writer.uint32(130).fork()).ldelim();
          }
          if (message.premiumPaidToDateEnd != null && Object.hasOwnProperty.call(message, "premiumPaidToDateEnd")) {
            values$1.v1.Date.encode(message.premiumPaidToDateEnd, writer.uint32(138).fork()).ldelim();
          }
          if (message.planBegin != null && Object.hasOwnProperty.call(message, "planBegin")) {
            values$1.v1.Date.encode(message.planBegin, writer.uint32(146).fork()).ldelim();
          }
          if (message.planEnd != null && Object.hasOwnProperty.call(message, "planEnd")) {
            values$1.v1.Date.encode(message.planEnd, writer.uint32(154).fork()).ldelim();
          }
          if (message.benefitBegin != null && Object.hasOwnProperty.call(message, "benefitBegin")) {
            values$1.v1.Date.encode(message.benefitBegin, writer.uint32(162).fork()).ldelim();
          }
          if (message.benefitEnd != null && Object.hasOwnProperty.call(message, "benefitEnd")) {
            values$1.v1.Date.encode(message.benefitEnd, writer.uint32(170).fork()).ldelim();
          }
          if (message.eligibilityBegin != null && Object.hasOwnProperty.call(message, "eligibilityBegin")) {
            values$1.v1.Date.encode(message.eligibilityBegin, writer.uint32(178).fork()).ldelim();
          }
          if (message.eligibilityEnd != null && Object.hasOwnProperty.call(message, "eligibilityEnd")) {
            values$1.v1.Date.encode(message.eligibilityEnd, writer.uint32(186).fork()).ldelim();
          }
          if (message.enrollment != null && Object.hasOwnProperty.call(message, "enrollment")) {
            values$1.v1.Date.encode(message.enrollment, writer.uint32(194).fork()).ldelim();
          }
          if (message.admission != null && Object.hasOwnProperty.call(message, "admission")) {
            for (const element of message.admission) {
              $root.stedi.v1.Admission.encode(element, writer.uint32(202).fork()).ldelim();
            }
          }
          if (message.dateOfDeath != null && Object.hasOwnProperty.call(message, "dateOfDeath")) {
            values$1.v1.Date.encode(message.dateOfDeath, writer.uint32(210).fork()).ldelim();
          }
          if (message.certification != null && Object.hasOwnProperty.call(message, "certification")) {
            values$1.v1.Date.encode(message.certification, writer.uint32(218).fork()).ldelim();
          }
          if (message.service != null && Object.hasOwnProperty.call(message, "service")) {
            values$1.v1.Date.encode(message.service, writer.uint32(226).fork()).ldelim();
          }
          if (message.policyEffective != null && Object.hasOwnProperty.call(message, "policyEffective")) {
            values$1.v1.Date.encode(message.policyEffective, writer.uint32(234).fork()).ldelim();
          }
          if (message.policyExpiration != null && Object.hasOwnProperty.call(message, "policyExpiration")) {
            values$1.v1.Date.encode(message.policyExpiration, writer.uint32(242).fork()).ldelim();
          }
          if (message.dateOfLastUpdate != null && Object.hasOwnProperty.call(message, "dateOfLastUpdate")) {
            values$1.v1.Date.encode(message.dateOfLastUpdate, writer.uint32(250).fork()).ldelim();
          }
          if (message.status != null && Object.hasOwnProperty.call(message, "status")) {
            values$1.v1.Date.encode(message.status, writer.uint32(258).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new BenefitsDateInformation();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.discharges || !message.discharges.length) {
                  message.discharges = [];
                }
                message.discharges.push($root.stedi.v1.Discharge.decode(reader, reader.uint32()));
                break;
              }
              case 2: {
                message.issue = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.effectiveDateOfChange = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 4: {
                message.periodStart = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.periodEnd = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.completion = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.coordinationOfBenefits = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 8: {
                message.plan = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 9: {
                message.benefit = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 10: {
                message.primaryCareProvider = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 11: {
                message.latestVisitOrConsultation = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 12: {
                message.eligibility = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 13: {
                message.added = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 14: {
                message.cobraBegin = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 15: {
                message.cobraEnd = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 16: {
                message.premiumPaidToDateBegin = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 17: {
                message.premiumPaidToDateEnd = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 18: {
                message.planBegin = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 19: {
                message.planEnd = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 20: {
                message.benefitBegin = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 21: {
                message.benefitEnd = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 22: {
                message.eligibilityBegin = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 23: {
                message.eligibilityEnd = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 24: {
                message.enrollment = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 25: {
                if (!message.admission || !message.admission.length) {
                  message.admission = [];
                }
                message.admission.push($root.stedi.v1.Admission.decode(reader, reader.uint32()));
                break;
              }
              case 26: {
                message.dateOfDeath = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 27: {
                message.certification = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 28: {
                message.service = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 29: {
                message.policyEffective = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 30: {
                message.policyExpiration = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 31: {
                message.dateOfLastUpdate = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 32: {
                message.status = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.BenefitsDateInformation) {
            return object;
          }
          const message = new $root.stedi.v1.BenefitsDateInformation();
          if (object.discharges) {
            if (!Array.isArray(object.discharges)) {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.discharges: array type expected, but got " + (typeof object.discharges))
            }
            message.discharges = new Array(object.discharges.length);
            for (let i = 0; i < object.discharges.length; ++i) {
              if (typeof object.discharges[i] !== "object") {
                throw new TypeError(".stedi.v1.BenefitsDateInformation.discharges: object expected, but got " + (typeof object.discharges[i]));
              }
              message.discharges[i] = $root.stedi.v1.Discharge.fromObject(object.discharges[i]);
            }
          }
          if (object.issue != null) {
            if (typeof object.issue !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.issue: object expected, but got " + (typeof object.issue));
            }
            message.issue = values$1.v1.Date.fromObject(object.issue);
          }
          if (object.effectiveDateOfChange != null) {
            if (typeof object.effectiveDateOfChange !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.effectiveDateOfChange: object expected, but got " + (typeof object.effectiveDateOfChange));
            }
            message.effectiveDateOfChange = values$1.v1.Date.fromObject(object.effectiveDateOfChange);
          }
          if (object.periodStart != null) {
            if (typeof object.periodStart !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.periodStart: object expected, but got " + (typeof object.periodStart));
            }
            message.periodStart = values$1.v1.Date.fromObject(object.periodStart);
          }
          if (object.periodEnd != null) {
            if (typeof object.periodEnd !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.periodEnd: object expected, but got " + (typeof object.periodEnd));
            }
            message.periodEnd = values$1.v1.Date.fromObject(object.periodEnd);
          }
          if (object.completion != null) {
            if (typeof object.completion !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.completion: object expected, but got " + (typeof object.completion));
            }
            message.completion = values$1.v1.Date.fromObject(object.completion);
          }
          if (object.coordinationOfBenefits != null) {
            if (typeof object.coordinationOfBenefits !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.coordinationOfBenefits: object expected, but got " + (typeof object.coordinationOfBenefits));
            }
            message.coordinationOfBenefits = values$1.v1.Date.fromObject(object.coordinationOfBenefits);
          }
          if (object.plan != null) {
            if (typeof object.plan !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.plan: object expected, but got " + (typeof object.plan));
            }
            message.plan = values$1.v1.Date.fromObject(object.plan);
          }
          if (object.benefit != null) {
            if (typeof object.benefit !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.benefit: object expected, but got " + (typeof object.benefit));
            }
            message.benefit = values$1.v1.Date.fromObject(object.benefit);
          }
          if (object.primaryCareProvider != null) {
            if (typeof object.primaryCareProvider !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.primaryCareProvider: object expected, but got " + (typeof object.primaryCareProvider));
            }
            message.primaryCareProvider = values$1.v1.Date.fromObject(object.primaryCareProvider);
          }
          if (object.latestVisitOrConsultation != null) {
            if (typeof object.latestVisitOrConsultation !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.latestVisitOrConsultation: object expected, but got " + (typeof object.latestVisitOrConsultation));
            }
            message.latestVisitOrConsultation = values$1.v1.Date.fromObject(object.latestVisitOrConsultation);
          }
          if (object.eligibility != null) {
            if (typeof object.eligibility !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.eligibility: object expected, but got " + (typeof object.eligibility));
            }
            message.eligibility = values$1.v1.Date.fromObject(object.eligibility);
          }
          if (object.added != null) {
            if (typeof object.added !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.added: object expected, but got " + (typeof object.added));
            }
            message.added = values$1.v1.Date.fromObject(object.added);
          }
          if (object.cobraBegin != null) {
            if (typeof object.cobraBegin !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.cobraBegin: object expected, but got " + (typeof object.cobraBegin));
            }
            message.cobraBegin = values$1.v1.Date.fromObject(object.cobraBegin);
          }
          if (object.cobraEnd != null) {
            if (typeof object.cobraEnd !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.cobraEnd: object expected, but got " + (typeof object.cobraEnd));
            }
            message.cobraEnd = values$1.v1.Date.fromObject(object.cobraEnd);
          }
          if (object.premiumPaidToDateBegin != null) {
            if (typeof object.premiumPaidToDateBegin !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.premiumPaidToDateBegin: object expected, but got " + (typeof object.premiumPaidToDateBegin));
            }
            message.premiumPaidToDateBegin = values$1.v1.Date.fromObject(object.premiumPaidToDateBegin);
          }
          if (object.premiumPaidToDateEnd != null) {
            if (typeof object.premiumPaidToDateEnd !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.premiumPaidToDateEnd: object expected, but got " + (typeof object.premiumPaidToDateEnd));
            }
            message.premiumPaidToDateEnd = values$1.v1.Date.fromObject(object.premiumPaidToDateEnd);
          }
          if (object.planBegin != null) {
            if (typeof object.planBegin !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.planBegin: object expected, but got " + (typeof object.planBegin));
            }
            message.planBegin = values$1.v1.Date.fromObject(object.planBegin);
          }
          if (object.planEnd != null) {
            if (typeof object.planEnd !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.planEnd: object expected, but got " + (typeof object.planEnd));
            }
            message.planEnd = values$1.v1.Date.fromObject(object.planEnd);
          }
          if (object.benefitBegin != null) {
            if (typeof object.benefitBegin !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.benefitBegin: object expected, but got " + (typeof object.benefitBegin));
            }
            message.benefitBegin = values$1.v1.Date.fromObject(object.benefitBegin);
          }
          if (object.benefitEnd != null) {
            if (typeof object.benefitEnd !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.benefitEnd: object expected, but got " + (typeof object.benefitEnd));
            }
            message.benefitEnd = values$1.v1.Date.fromObject(object.benefitEnd);
          }
          if (object.eligibilityBegin != null) {
            if (typeof object.eligibilityBegin !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.eligibilityBegin: object expected, but got " + (typeof object.eligibilityBegin));
            }
            message.eligibilityBegin = values$1.v1.Date.fromObject(object.eligibilityBegin);
          }
          if (object.eligibilityEnd != null) {
            if (typeof object.eligibilityEnd !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.eligibilityEnd: object expected, but got " + (typeof object.eligibilityEnd));
            }
            message.eligibilityEnd = values$1.v1.Date.fromObject(object.eligibilityEnd);
          }
          if (object.enrollment != null) {
            if (typeof object.enrollment !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.enrollment: object expected, but got " + (typeof object.enrollment));
            }
            message.enrollment = values$1.v1.Date.fromObject(object.enrollment);
          }
          if (object.admission) {
            if (!Array.isArray(object.admission)) {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.admission: array type expected, but got " + (typeof object.admission))
            }
            message.admission = new Array(object.admission.length);
            for (let i = 0; i < object.admission.length; ++i) {
              if (typeof object.admission[i] !== "object") {
                throw new TypeError(".stedi.v1.BenefitsDateInformation.admission: object expected, but got " + (typeof object.admission[i]));
              }
              message.admission[i] = $root.stedi.v1.Admission.fromObject(object.admission[i]);
            }
          }
          if (object.dateOfDeath != null) {
            if (typeof object.dateOfDeath !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.dateOfDeath: object expected, but got " + (typeof object.dateOfDeath));
            }
            message.dateOfDeath = values$1.v1.Date.fromObject(object.dateOfDeath);
          }
          if (object.certification != null) {
            if (typeof object.certification !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.certification: object expected, but got " + (typeof object.certification));
            }
            message.certification = values$1.v1.Date.fromObject(object.certification);
          }
          if (object.service != null) {
            if (typeof object.service !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.service: object expected, but got " + (typeof object.service));
            }
            message.service = values$1.v1.Date.fromObject(object.service);
          }
          if (object.policyEffective != null) {
            if (typeof object.policyEffective !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.policyEffective: object expected, but got " + (typeof object.policyEffective));
            }
            message.policyEffective = values$1.v1.Date.fromObject(object.policyEffective);
          }
          if (object.policyExpiration != null) {
            if (typeof object.policyExpiration !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.policyExpiration: object expected, but got " + (typeof object.policyExpiration));
            }
            message.policyExpiration = values$1.v1.Date.fromObject(object.policyExpiration);
          }
          if (object.dateOfLastUpdate != null) {
            if (typeof object.dateOfLastUpdate !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.dateOfLastUpdate: object expected, but got " + (typeof object.dateOfLastUpdate));
            }
            message.dateOfLastUpdate = values$1.v1.Date.fromObject(object.dateOfLastUpdate);
          }
          if (object.status != null) {
            if (typeof object.status !== "object") {
              throw new TypeError(".stedi.v1.BenefitsDateInformation.status: object expected, but got " + (typeof object.status));
            }
            message.status = values$1.v1.Date.fromObject(object.status);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.discharges = [];
            object.admission = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.discharges && message.discharges.length) {
            object.discharges = new Array(message.discharges.length);
            for (let i = 0; i < message.discharges.length; ++i) {
              object.discharges[i] = $root.stedi.v1.Discharge.toObject(message.discharges[i], options);
            }
          }
          if (message.issue != null && message.hasOwnProperty("issue")) {
            object.issue = values$1.v1.Date.toObject(message.issue, options);
          }
          if (message.effectiveDateOfChange != null && message.hasOwnProperty("effectiveDateOfChange")) {
            object.effectiveDateOfChange = values$1.v1.Date.toObject(message.effectiveDateOfChange, options);
          }
          if (message.periodStart != null && message.hasOwnProperty("periodStart")) {
            object.periodStart = values$1.v1.Date.toObject(message.periodStart, options);
          }
          if (message.periodEnd != null && message.hasOwnProperty("periodEnd")) {
            object.periodEnd = values$1.v1.Date.toObject(message.periodEnd, options);
          }
          if (message.completion != null && message.hasOwnProperty("completion")) {
            object.completion = values$1.v1.Date.toObject(message.completion, options);
          }
          if (message.coordinationOfBenefits != null && message.hasOwnProperty("coordinationOfBenefits")) {
            object.coordinationOfBenefits = values$1.v1.Date.toObject(message.coordinationOfBenefits, options);
          }
          if (message.plan != null && message.hasOwnProperty("plan")) {
            object.plan = values$1.v1.Date.toObject(message.plan, options);
          }
          if (message.benefit != null && message.hasOwnProperty("benefit")) {
            object.benefit = values$1.v1.Date.toObject(message.benefit, options);
          }
          if (message.primaryCareProvider != null && message.hasOwnProperty("primaryCareProvider")) {
            object.primaryCareProvider = values$1.v1.Date.toObject(message.primaryCareProvider, options);
          }
          if (message.latestVisitOrConsultation != null && message.hasOwnProperty("latestVisitOrConsultation")) {
            object.latestVisitOrConsultation = values$1.v1.Date.toObject(message.latestVisitOrConsultation, options);
          }
          if (message.eligibility != null && message.hasOwnProperty("eligibility")) {
            object.eligibility = values$1.v1.Date.toObject(message.eligibility, options);
          }
          if (message.added != null && message.hasOwnProperty("added")) {
            object.added = values$1.v1.Date.toObject(message.added, options);
          }
          if (message.cobraBegin != null && message.hasOwnProperty("cobraBegin")) {
            object.cobraBegin = values$1.v1.Date.toObject(message.cobraBegin, options);
          }
          if (message.cobraEnd != null && message.hasOwnProperty("cobraEnd")) {
            object.cobraEnd = values$1.v1.Date.toObject(message.cobraEnd, options);
          }
          if (message.premiumPaidToDateBegin != null && message.hasOwnProperty("premiumPaidToDateBegin")) {
            object.premiumPaidToDateBegin = values$1.v1.Date.toObject(message.premiumPaidToDateBegin, options);
          }
          if (message.premiumPaidToDateEnd != null && message.hasOwnProperty("premiumPaidToDateEnd")) {
            object.premiumPaidToDateEnd = values$1.v1.Date.toObject(message.premiumPaidToDateEnd, options);
          }
          if (message.planBegin != null && message.hasOwnProperty("planBegin")) {
            object.planBegin = values$1.v1.Date.toObject(message.planBegin, options);
          }
          if (message.planEnd != null && message.hasOwnProperty("planEnd")) {
            object.planEnd = values$1.v1.Date.toObject(message.planEnd, options);
          }
          if (message.benefitBegin != null && message.hasOwnProperty("benefitBegin")) {
            object.benefitBegin = values$1.v1.Date.toObject(message.benefitBegin, options);
          }
          if (message.benefitEnd != null && message.hasOwnProperty("benefitEnd")) {
            object.benefitEnd = values$1.v1.Date.toObject(message.benefitEnd, options);
          }
          if (message.eligibilityBegin != null && message.hasOwnProperty("eligibilityBegin")) {
            object.eligibilityBegin = values$1.v1.Date.toObject(message.eligibilityBegin, options);
          }
          if (message.eligibilityEnd != null && message.hasOwnProperty("eligibilityEnd")) {
            object.eligibilityEnd = values$1.v1.Date.toObject(message.eligibilityEnd, options);
          }
          if (message.enrollment != null && message.hasOwnProperty("enrollment")) {
            object.enrollment = values$1.v1.Date.toObject(message.enrollment, options);
          }
          if (message.admission && message.admission.length) {
            object.admission = new Array(message.admission.length);
            for (let i = 0; i < message.admission.length; ++i) {
              object.admission[i] = $root.stedi.v1.Admission.toObject(message.admission[i], options);
            }
          }
          if (message.dateOfDeath != null && message.hasOwnProperty("dateOfDeath")) {
            object.dateOfDeath = values$1.v1.Date.toObject(message.dateOfDeath, options);
          }
          if (message.certification != null && message.hasOwnProperty("certification")) {
            object.certification = values$1.v1.Date.toObject(message.certification, options);
          }
          if (message.service != null && message.hasOwnProperty("service")) {
            object.service = values$1.v1.Date.toObject(message.service, options);
          }
          if (message.policyEffective != null && message.hasOwnProperty("policyEffective")) {
            object.policyEffective = values$1.v1.Date.toObject(message.policyEffective, options);
          }
          if (message.policyExpiration != null && message.hasOwnProperty("policyExpiration")) {
            object.policyExpiration = values$1.v1.Date.toObject(message.policyExpiration, options);
          }
          if (message.dateOfLastUpdate != null && message.hasOwnProperty("dateOfLastUpdate")) {
            object.dateOfLastUpdate = values$1.v1.Date.toObject(message.dateOfLastUpdate, options);
          }
          if (message.status != null && message.hasOwnProperty("status")) {
            object.status = values$1.v1.Date.toObject(message.status, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.BenefitsDateInformation";
        }
      }

      BenefitsDateInformation.prototype.discharges = $util.emptyArray;
      BenefitsDateInformation.prototype.issue = null;
      BenefitsDateInformation.prototype.effectiveDateOfChange = null;
      BenefitsDateInformation.prototype.periodStart = null;
      BenefitsDateInformation.prototype.periodEnd = null;
      BenefitsDateInformation.prototype.completion = null;
      BenefitsDateInformation.prototype.coordinationOfBenefits = null;
      BenefitsDateInformation.prototype.plan = null;
      BenefitsDateInformation.prototype.benefit = null;
      BenefitsDateInformation.prototype.primaryCareProvider = null;
      BenefitsDateInformation.prototype.latestVisitOrConsultation = null;
      BenefitsDateInformation.prototype.eligibility = null;
      BenefitsDateInformation.prototype.added = null;
      BenefitsDateInformation.prototype.cobraBegin = null;
      BenefitsDateInformation.prototype.cobraEnd = null;
      BenefitsDateInformation.prototype.premiumPaidToDateBegin = null;
      BenefitsDateInformation.prototype.premiumPaidToDateEnd = null;
      BenefitsDateInformation.prototype.planBegin = null;
      BenefitsDateInformation.prototype.planEnd = null;
      BenefitsDateInformation.prototype.benefitBegin = null;
      BenefitsDateInformation.prototype.benefitEnd = null;
      BenefitsDateInformation.prototype.eligibilityBegin = null;
      BenefitsDateInformation.prototype.eligibilityEnd = null;
      BenefitsDateInformation.prototype.enrollment = null;
      BenefitsDateInformation.prototype.admission = $util.emptyArray;
      BenefitsDateInformation.prototype.dateOfDeath = null;
      BenefitsDateInformation.prototype.certification = null;
      BenefitsDateInformation.prototype.service = null;
      BenefitsDateInformation.prototype.policyEffective = null;
      BenefitsDateInformation.prototype.policyExpiration = null;
      BenefitsDateInformation.prototype.dateOfLastUpdate = null;
      BenefitsDateInformation.prototype.status = null;

      return BenefitsDateInformation;
    })();

    v1.Discharge = (() => {
      class Discharge {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Discharge(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.date != null && Object.hasOwnProperty.call(message, "date")) {
            values$1.v1.Date.encode(message.date, writer.uint32(10).fork()).ldelim();
          }
          if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate")) {
            values$1.v1.Date.encode(message.startDate, writer.uint32(18).fork()).ldelim();
          }
          if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate")) {
            values$1.v1.Date.encode(message.endDate, writer.uint32(26).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Discharge();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.date = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.startDate = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.endDate = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.Discharge) {
            return object;
          }
          const message = new $root.stedi.v1.Discharge();
          if (object.date != null) {
            if (typeof object.date !== "object") {
              throw new TypeError(".stedi.v1.Discharge.date: object expected, but got " + (typeof object.date));
            }
            message.date = values$1.v1.Date.fromObject(object.date);
          }
          if (object.startDate != null) {
            if (typeof object.startDate !== "object") {
              throw new TypeError(".stedi.v1.Discharge.startDate: object expected, but got " + (typeof object.startDate));
            }
            message.startDate = values$1.v1.Date.fromObject(object.startDate);
          }
          if (object.endDate != null) {
            if (typeof object.endDate !== "object") {
              throw new TypeError(".stedi.v1.Discharge.endDate: object expected, but got " + (typeof object.endDate));
            }
            message.endDate = values$1.v1.Date.fromObject(object.endDate);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.date != null && message.hasOwnProperty("date")) {
            object.date = values$1.v1.Date.toObject(message.date, options);
          }
          if (message.startDate != null && message.hasOwnProperty("startDate")) {
            object.startDate = values$1.v1.Date.toObject(message.startDate, options);
          }
          if (message.endDate != null && message.hasOwnProperty("endDate")) {
            object.endDate = values$1.v1.Date.toObject(message.endDate, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.Discharge";
        }
      }

      Discharge.prototype.date = null;
      Discharge.prototype.startDate = null;
      Discharge.prototype.endDate = null;

      return Discharge;
    })();

    v1.Admission = (() => {
      class Admission {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Admission(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.date != null && Object.hasOwnProperty.call(message, "date")) {
            values$1.v1.Date.encode(message.date, writer.uint32(10).fork()).ldelim();
          }
          if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate")) {
            values$1.v1.Date.encode(message.startDate, writer.uint32(18).fork()).ldelim();
          }
          if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate")) {
            values$1.v1.Date.encode(message.endDate, writer.uint32(26).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Admission();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.date = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.startDate = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.endDate = values$1.v1.Date.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.Admission) {
            return object;
          }
          const message = new $root.stedi.v1.Admission();
          if (object.date != null) {
            if (typeof object.date !== "object") {
              throw new TypeError(".stedi.v1.Admission.date: object expected, but got " + (typeof object.date));
            }
            message.date = values$1.v1.Date.fromObject(object.date);
          }
          if (object.startDate != null) {
            if (typeof object.startDate !== "object") {
              throw new TypeError(".stedi.v1.Admission.startDate: object expected, but got " + (typeof object.startDate));
            }
            message.startDate = values$1.v1.Date.fromObject(object.startDate);
          }
          if (object.endDate != null) {
            if (typeof object.endDate !== "object") {
              throw new TypeError(".stedi.v1.Admission.endDate: object expected, but got " + (typeof object.endDate));
            }
            message.endDate = values$1.v1.Date.fromObject(object.endDate);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.date != null && message.hasOwnProperty("date")) {
            object.date = values$1.v1.Date.toObject(message.date, options);
          }
          if (message.startDate != null && message.hasOwnProperty("startDate")) {
            object.startDate = values$1.v1.Date.toObject(message.startDate, options);
          }
          if (message.endDate != null && message.hasOwnProperty("endDate")) {
            object.endDate = values$1.v1.Date.toObject(message.endDate, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.Admission";
        }
      }

      Admission.prototype.date = null;
      Admission.prototype.startDate = null;
      Admission.prototype.endDate = null;

      return Admission;
    })();

    v1.BenefitsRelatedEntity = (() => {
      class BenefitsRelatedEntity {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new BenefitsRelatedEntity(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.entityIdentifier != null && Object.hasOwnProperty.call(message, "entityIdentifier")) {
            writer.uint32(10).string(message.entityIdentifier);
          }
          if (message.entityType != null && Object.hasOwnProperty.call(message, "entityType")) {
            writer.uint32(18).string(message.entityType);
          }
          if (message.entityName != null && Object.hasOwnProperty.call(message, "entityName")) {
            writer.uint32(26).string(message.entityName);
          }
          if (message.entityFirstName != null && Object.hasOwnProperty.call(message, "entityFirstName")) {
            writer.uint32(34).string(message.entityFirstName);
          }
          if (message.entityMiddleName != null && Object.hasOwnProperty.call(message, "entityMiddleName")) {
            writer.uint32(42).string(message.entityMiddleName);
          }
          if (message.entitySuffix != null && Object.hasOwnProperty.call(message, "entitySuffix")) {
            writer.uint32(50).string(message.entitySuffix);
          }
          if (message.entityIdentification != null && Object.hasOwnProperty.call(message, "entityIdentification")) {
            $root.stedi.v1.EntityIdentification.encode(message.entityIdentification, writer.uint32(58).fork()).ldelim();
          }
          if (message.entityRelationship != null && Object.hasOwnProperty.call(message, "entityRelationship")) {
            writer.uint32(66).string(message.entityRelationship);
          }
          if (message.address != null && Object.hasOwnProperty.call(message, "address")) {
            $root.stedi.v1.Address.encode(message.address, writer.uint32(74).fork()).ldelim();
          }
          if (message.contactInformation != null && Object.hasOwnProperty.call(message, "contactInformation")) {
            $root.stedi.v1.ContactInformation.encode(message.contactInformation, writer.uint32(82).fork()).ldelim();
          }
          if (message.providerInformation != null && Object.hasOwnProperty.call(message, "providerInformation")) {
            $root.stedi.v1.ProviderInformation.encode(message.providerInformation, writer.uint32(90).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new BenefitsRelatedEntity();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.entityIdentifier = reader.string();
                break;
              }
              case 2: {
                message.entityType = reader.string();
                break;
              }
              case 3: {
                message.entityName = reader.string();
                break;
              }
              case 4: {
                message.entityFirstName = reader.string();
                break;
              }
              case 5: {
                message.entityMiddleName = reader.string();
                break;
              }
              case 6: {
                message.entitySuffix = reader.string();
                break;
              }
              case 7: {
                message.entityIdentification = $root.stedi.v1.EntityIdentification.decode(reader, reader.uint32());
                break;
              }
              case 8: {
                message.entityRelationship = reader.string();
                break;
              }
              case 9: {
                message.address = $root.stedi.v1.Address.decode(reader, reader.uint32());
                break;
              }
              case 10: {
                message.contactInformation = $root.stedi.v1.ContactInformation.decode(reader, reader.uint32());
                break;
              }
              case 11: {
                message.providerInformation = $root.stedi.v1.ProviderInformation.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.BenefitsRelatedEntity) {
            return object;
          }
          const message = new $root.stedi.v1.BenefitsRelatedEntity();
          if (object.entityIdentifier != null) {
            message.entityIdentifier = String(object.entityIdentifier);
          }
          if (object.entityType != null) {
            message.entityType = String(object.entityType);
          }
          if (object.entityName != null) {
            message.entityName = String(object.entityName);
          }
          if (object.entityFirstName != null) {
            message.entityFirstName = String(object.entityFirstName);
          }
          if (object.entityMiddleName != null) {
            message.entityMiddleName = String(object.entityMiddleName);
          }
          if (object.entitySuffix != null) {
            message.entitySuffix = String(object.entitySuffix);
          }
          if (object.entityIdentification != null) {
            if (typeof object.entityIdentification !== "object") {
              throw new TypeError(".stedi.v1.BenefitsRelatedEntity.entityIdentification: object expected, but got " + (typeof object.entityIdentification));
            }
            message.entityIdentification = $root.stedi.v1.EntityIdentification.fromObject(object.entityIdentification);
          }
          if (object.entityRelationship != null) {
            message.entityRelationship = String(object.entityRelationship);
          }
          if (object.address != null) {
            if (typeof object.address !== "object") {
              throw new TypeError(".stedi.v1.BenefitsRelatedEntity.address: object expected, but got " + (typeof object.address));
            }
            message.address = $root.stedi.v1.Address.fromObject(object.address);
          }
          if (object.contactInformation != null) {
            if (typeof object.contactInformation !== "object") {
              throw new TypeError(".stedi.v1.BenefitsRelatedEntity.contactInformation: object expected, but got " + (typeof object.contactInformation));
            }
            message.contactInformation = $root.stedi.v1.ContactInformation.fromObject(object.contactInformation);
          }
          if (object.providerInformation != null) {
            if (typeof object.providerInformation !== "object") {
              throw new TypeError(".stedi.v1.BenefitsRelatedEntity.providerInformation: object expected, but got " + (typeof object.providerInformation));
            }
            message.providerInformation = $root.stedi.v1.ProviderInformation.fromObject(object.providerInformation);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.entityIdentifier != null && message.hasOwnProperty("entityIdentifier")) {
            object.entityIdentifier = message.entityIdentifier;
          }
          if (message.entityType != null && message.hasOwnProperty("entityType")) {
            object.entityType = message.entityType;
          }
          if (message.entityName != null && message.hasOwnProperty("entityName")) {
            object.entityName = message.entityName;
          }
          if (message.entityFirstName != null && message.hasOwnProperty("entityFirstName")) {
            object.entityFirstName = message.entityFirstName;
          }
          if (message.entityMiddleName != null && message.hasOwnProperty("entityMiddleName")) {
            object.entityMiddleName = message.entityMiddleName;
          }
          if (message.entitySuffix != null && message.hasOwnProperty("entitySuffix")) {
            object.entitySuffix = message.entitySuffix;
          }
          if (message.entityIdentification != null && message.hasOwnProperty("entityIdentification")) {
            object.entityIdentification = $root.stedi.v1.EntityIdentification.toObject(message.entityIdentification, options);
          }
          if (message.entityRelationship != null && message.hasOwnProperty("entityRelationship")) {
            object.entityRelationship = message.entityRelationship;
          }
          if (message.address != null && message.hasOwnProperty("address")) {
            object.address = $root.stedi.v1.Address.toObject(message.address, options);
          }
          if (message.contactInformation != null && message.hasOwnProperty("contactInformation")) {
            object.contactInformation = $root.stedi.v1.ContactInformation.toObject(message.contactInformation, options);
          }
          if (message.providerInformation != null && message.hasOwnProperty("providerInformation")) {
            object.providerInformation = $root.stedi.v1.ProviderInformation.toObject(message.providerInformation, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.BenefitsRelatedEntity";
        }
      }

      BenefitsRelatedEntity.prototype.entityIdentifier = null;
      BenefitsRelatedEntity.prototype.entityType = null;
      BenefitsRelatedEntity.prototype.entityName = null;
      BenefitsRelatedEntity.prototype.entityFirstName = null;
      BenefitsRelatedEntity.prototype.entityMiddleName = null;
      BenefitsRelatedEntity.prototype.entitySuffix = null;
      BenefitsRelatedEntity.prototype.entityIdentification = null;
      BenefitsRelatedEntity.prototype.entityRelationship = null;
      BenefitsRelatedEntity.prototype.address = null;
      BenefitsRelatedEntity.prototype.contactInformation = null;
      BenefitsRelatedEntity.prototype.providerInformation = null;

      return BenefitsRelatedEntity;
    })();

    v1.EntityIdentification = (() => {
      class EntityIdentification {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new EntityIdentification(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.code != null && Object.hasOwnProperty.call(message, "code")) {
            writer.uint32(10).string(message.code);
          }
          if (message.value != null && Object.hasOwnProperty.call(message, "value")) {
            writer.uint32(18).string(message.value);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new EntityIdentification();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.code = reader.string();
                break;
              }
              case 2: {
                message.value = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.EntityIdentification) {
            return object;
          }
          const message = new $root.stedi.v1.EntityIdentification();
          if (object.code != null) {
            message.code = String(object.code);
          }
          if (object.value != null) {
            message.value = String(object.value);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.code != null && message.hasOwnProperty("code")) {
            object.code = message.code;
          }
          if (message.value != null && message.hasOwnProperty("value")) {
            object.value = message.value;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.EntityIdentification";
        }
      }

      EntityIdentification.prototype.code = null;
      EntityIdentification.prototype.value = null;

      return EntityIdentification;
    })();

    v1.ProviderInformation = (() => {
      class ProviderInformation {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ProviderInformation(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.providerCode != null && Object.hasOwnProperty.call(message, "providerCode")) {
            writer.uint32(10).string(message.providerCode);
          }
          if (message.referenceIdentification != null && Object.hasOwnProperty.call(message, "referenceIdentification")) {
            writer.uint32(18).string(message.referenceIdentification);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ProviderInformation();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.providerCode = reader.string();
                break;
              }
              case 2: {
                message.referenceIdentification = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.ProviderInformation) {
            return object;
          }
          const message = new $root.stedi.v1.ProviderInformation();
          if (object.providerCode != null) {
            message.providerCode = String(object.providerCode);
          }
          if (object.referenceIdentification != null) {
            message.referenceIdentification = String(object.referenceIdentification);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.providerCode != null && message.hasOwnProperty("providerCode")) {
            object.providerCode = message.providerCode;
          }
          if (message.referenceIdentification != null && message.hasOwnProperty("referenceIdentification")) {
            object.referenceIdentification = message.referenceIdentification;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.ProviderInformation";
        }
      }

      ProviderInformation.prototype.providerCode = null;
      ProviderInformation.prototype.referenceIdentification = null;

      return ProviderInformation;
    })();

    v1.AdditionalInformationDescription = (() => {
      class AdditionalInformationDescription {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new AdditionalInformationDescription(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.description != null && Object.hasOwnProperty.call(message, "description")) {
            writer.uint32(10).string(message.description);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new AdditionalInformationDescription();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.description = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.AdditionalInformationDescription) {
            return object;
          }
          const message = new $root.stedi.v1.AdditionalInformationDescription();
          if (object.description != null) {
            message.description = String(object.description);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.description = "";
          }
          let keys;
          if (message.description != null && message.hasOwnProperty("description")) {
            object.description = message.description;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.AdditionalInformationDescription";
        }
      }

      AdditionalInformationDescription.prototype.description = "";

      return AdditionalInformationDescription;
    })();

    v1.TransactionSupport = (() => {
      class TransactionSupport {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new TransactionSupport(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.eligibilityCheck != null && Object.hasOwnProperty.call(message, "eligibilityCheck")) {
            writer.uint32(8).int32(message.eligibilityCheck);
          }
          if (message.claimStatus != null && Object.hasOwnProperty.call(message, "claimStatus")) {
            writer.uint32(16).int32(message.claimStatus);
          }
          if (message.claimSubmission != null && Object.hasOwnProperty.call(message, "claimSubmission")) {
            writer.uint32(24).int32(message.claimSubmission);
          }
          if (message.professionalClaimSubmission != null && Object.hasOwnProperty.call(message, "professionalClaimSubmission")) {
            writer.uint32(32).int32(message.professionalClaimSubmission);
          }
          if (message.institutionalClaimSubmission != null && Object.hasOwnProperty.call(message, "institutionalClaimSubmission")) {
            writer.uint32(40).int32(message.institutionalClaimSubmission);
          }
          if (message.claimPayment != null && Object.hasOwnProperty.call(message, "claimPayment")) {
            writer.uint32(48).int32(message.claimPayment);
          }
          if (message.coordinationOfBenefits != null && Object.hasOwnProperty.call(message, "coordinationOfBenefits")) {
            writer.uint32(56).int32(message.coordinationOfBenefits);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new TransactionSupport();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eligibilityCheck = reader.int32();
                break;
              }
              case 2: {
                message.claimStatus = reader.int32();
                break;
              }
              case 3: {
                message.claimSubmission = reader.int32();
                break;
              }
              case 4: {
                message.professionalClaimSubmission = reader.int32();
                break;
              }
              case 5: {
                message.institutionalClaimSubmission = reader.int32();
                break;
              }
              case 6: {
                message.claimPayment = reader.int32();
                break;
              }
              case 7: {
                message.coordinationOfBenefits = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.TransactionSupport) {
            return object;
          }
          const message = new $root.stedi.v1.TransactionSupport();
          if (object.eligibilityCheck != null) {
            switch (object.eligibilityCheck) {
              case "TRANSACTION_SUPPORT_STATUS_UNSPECIFIED":
              case 0: {
                message.eligibilityCheck = 0;
                break;
              }
              case "SUPPORTED":
              case 1: {
                message.eligibilityCheck = 1;
                break;
              }
              case "NOT_SUPPORTED":
              case 2: {
                message.eligibilityCheck = 2;
                break;
              }
              case "ENROLLMENT_REQUIRED":
              case 3: {
                message.eligibilityCheck = 3;
                break;
              }
              default: {
                if (typeof object.eligibilityCheck == "number") {
                  message.eligibilityCheck = object.eligibilityCheck;
                  break;
                }
                break;
              }
            }
          }
          if (object.claimStatus != null) {
            switch (object.claimStatus) {
              case "TRANSACTION_SUPPORT_STATUS_UNSPECIFIED":
              case 0: {
                message.claimStatus = 0;
                break;
              }
              case "SUPPORTED":
              case 1: {
                message.claimStatus = 1;
                break;
              }
              case "NOT_SUPPORTED":
              case 2: {
                message.claimStatus = 2;
                break;
              }
              case "ENROLLMENT_REQUIRED":
              case 3: {
                message.claimStatus = 3;
                break;
              }
              default: {
                if (typeof object.claimStatus == "number") {
                  message.claimStatus = object.claimStatus;
                  break;
                }
                break;
              }
            }
          }
          if (object.claimSubmission != null) {
            switch (object.claimSubmission) {
              case "TRANSACTION_SUPPORT_STATUS_UNSPECIFIED":
              case 0: {
                message.claimSubmission = 0;
                break;
              }
              case "SUPPORTED":
              case 1: {
                message.claimSubmission = 1;
                break;
              }
              case "NOT_SUPPORTED":
              case 2: {
                message.claimSubmission = 2;
                break;
              }
              case "ENROLLMENT_REQUIRED":
              case 3: {
                message.claimSubmission = 3;
                break;
              }
              default: {
                if (typeof object.claimSubmission == "number") {
                  message.claimSubmission = object.claimSubmission;
                  break;
                }
                break;
              }
            }
          }
          if (object.professionalClaimSubmission != null) {
            switch (object.professionalClaimSubmission) {
              case "TRANSACTION_SUPPORT_STATUS_UNSPECIFIED":
              case 0: {
                message.professionalClaimSubmission = 0;
                break;
              }
              case "SUPPORTED":
              case 1: {
                message.professionalClaimSubmission = 1;
                break;
              }
              case "NOT_SUPPORTED":
              case 2: {
                message.professionalClaimSubmission = 2;
                break;
              }
              case "ENROLLMENT_REQUIRED":
              case 3: {
                message.professionalClaimSubmission = 3;
                break;
              }
              default: {
                if (typeof object.professionalClaimSubmission == "number") {
                  message.professionalClaimSubmission = object.professionalClaimSubmission;
                  break;
                }
                break;
              }
            }
          }
          if (object.institutionalClaimSubmission != null) {
            switch (object.institutionalClaimSubmission) {
              case "TRANSACTION_SUPPORT_STATUS_UNSPECIFIED":
              case 0: {
                message.institutionalClaimSubmission = 0;
                break;
              }
              case "SUPPORTED":
              case 1: {
                message.institutionalClaimSubmission = 1;
                break;
              }
              case "NOT_SUPPORTED":
              case 2: {
                message.institutionalClaimSubmission = 2;
                break;
              }
              case "ENROLLMENT_REQUIRED":
              case 3: {
                message.institutionalClaimSubmission = 3;
                break;
              }
              default: {
                if (typeof object.institutionalClaimSubmission == "number") {
                  message.institutionalClaimSubmission = object.institutionalClaimSubmission;
                  break;
                }
                break;
              }
            }
          }
          if (object.claimPayment != null) {
            switch (object.claimPayment) {
              case "TRANSACTION_SUPPORT_STATUS_UNSPECIFIED":
              case 0: {
                message.claimPayment = 0;
                break;
              }
              case "SUPPORTED":
              case 1: {
                message.claimPayment = 1;
                break;
              }
              case "NOT_SUPPORTED":
              case 2: {
                message.claimPayment = 2;
                break;
              }
              case "ENROLLMENT_REQUIRED":
              case 3: {
                message.claimPayment = 3;
                break;
              }
              default: {
                if (typeof object.claimPayment == "number") {
                  message.claimPayment = object.claimPayment;
                  break;
                }
                break;
              }
            }
          }
          if (object.coordinationOfBenefits != null) {
            switch (object.coordinationOfBenefits) {
              case "TRANSACTION_SUPPORT_STATUS_UNSPECIFIED":
              case 0: {
                message.coordinationOfBenefits = 0;
                break;
              }
              case "SUPPORTED":
              case 1: {
                message.coordinationOfBenefits = 1;
                break;
              }
              case "NOT_SUPPORTED":
              case 2: {
                message.coordinationOfBenefits = 2;
                break;
              }
              case "ENROLLMENT_REQUIRED":
              case 3: {
                message.coordinationOfBenefits = 3;
                break;
              }
              default: {
                if (typeof object.coordinationOfBenefits == "number") {
                  message.coordinationOfBenefits = object.coordinationOfBenefits;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eligibilityCheck = options.enums === String ? "TRANSACTION_SUPPORT_STATUS_UNSPECIFIED" : 0
            object.claimStatus = options.enums === String ? "TRANSACTION_SUPPORT_STATUS_UNSPECIFIED" : 0
            object.claimSubmission = options.enums === String ? "TRANSACTION_SUPPORT_STATUS_UNSPECIFIED" : 0
            object.professionalClaimSubmission = options.enums === String ? "TRANSACTION_SUPPORT_STATUS_UNSPECIFIED" : 0
            object.institutionalClaimSubmission = options.enums === String ? "TRANSACTION_SUPPORT_STATUS_UNSPECIFIED" : 0
            object.claimPayment = options.enums === String ? "TRANSACTION_SUPPORT_STATUS_UNSPECIFIED" : 0
            object.coordinationOfBenefits = options.enums === String ? "TRANSACTION_SUPPORT_STATUS_UNSPECIFIED" : 0
          }
          let keys;
          if (message.eligibilityCheck != null && message.hasOwnProperty("eligibilityCheck")) {
            object.eligibilityCheck = options.enums === String ? $root.stedi.v1.TransactionSupportStatus[message.eligibilityCheck] === undefined ? message.eligibilityCheck : $root.stedi.v1.TransactionSupportStatus[message.eligibilityCheck] : message.eligibilityCheck;
          }
          if (message.claimStatus != null && message.hasOwnProperty("claimStatus")) {
            object.claimStatus = options.enums === String ? $root.stedi.v1.TransactionSupportStatus[message.claimStatus] === undefined ? message.claimStatus : $root.stedi.v1.TransactionSupportStatus[message.claimStatus] : message.claimStatus;
          }
          if (message.claimSubmission != null && message.hasOwnProperty("claimSubmission")) {
            object.claimSubmission = options.enums === String ? $root.stedi.v1.TransactionSupportStatus[message.claimSubmission] === undefined ? message.claimSubmission : $root.stedi.v1.TransactionSupportStatus[message.claimSubmission] : message.claimSubmission;
          }
          if (message.professionalClaimSubmission != null && message.hasOwnProperty("professionalClaimSubmission")) {
            object.professionalClaimSubmission = options.enums === String ? $root.stedi.v1.TransactionSupportStatus[message.professionalClaimSubmission] === undefined ? message.professionalClaimSubmission : $root.stedi.v1.TransactionSupportStatus[message.professionalClaimSubmission] : message.professionalClaimSubmission;
          }
          if (message.institutionalClaimSubmission != null && message.hasOwnProperty("institutionalClaimSubmission")) {
            object.institutionalClaimSubmission = options.enums === String ? $root.stedi.v1.TransactionSupportStatus[message.institutionalClaimSubmission] === undefined ? message.institutionalClaimSubmission : $root.stedi.v1.TransactionSupportStatus[message.institutionalClaimSubmission] : message.institutionalClaimSubmission;
          }
          if (message.claimPayment != null && message.hasOwnProperty("claimPayment")) {
            object.claimPayment = options.enums === String ? $root.stedi.v1.TransactionSupportStatus[message.claimPayment] === undefined ? message.claimPayment : $root.stedi.v1.TransactionSupportStatus[message.claimPayment] : message.claimPayment;
          }
          if (message.coordinationOfBenefits != null && message.hasOwnProperty("coordinationOfBenefits")) {
            object.coordinationOfBenefits = options.enums === String ? $root.stedi.v1.TransactionSupportStatus[message.coordinationOfBenefits] === undefined ? message.coordinationOfBenefits : $root.stedi.v1.TransactionSupportStatus[message.coordinationOfBenefits] : message.coordinationOfBenefits;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.TransactionSupport";
        }
      }

      TransactionSupport.prototype.eligibilityCheck = 0;
      TransactionSupport.prototype.claimStatus = 0;
      TransactionSupport.prototype.claimSubmission = 0;
      TransactionSupport.prototype.professionalClaimSubmission = 0;
      TransactionSupport.prototype.institutionalClaimSubmission = 0;
      TransactionSupport.prototype.claimPayment = 0;
      TransactionSupport.prototype.coordinationOfBenefits = 0;

      return TransactionSupport;
    })();

    v1.SupportedPayer = (() => {
      class SupportedPayer {
        constructor(properties) {
          this.aliases = [];
          this.names = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SupportedPayer(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.stediId != null && Object.hasOwnProperty.call(message, "stediId")) {
            writer.uint32(10).string(message.stediId);
          }
          if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName")) {
            writer.uint32(18).string(message.displayName);
          }
          if (message.primaryPayerId != null && Object.hasOwnProperty.call(message, "primaryPayerId")) {
            writer.uint32(26).string(message.primaryPayerId);
          }
          if (message.aliases != null && Object.hasOwnProperty.call(message, "aliases")) {
            for (const element of message.aliases) {
              writer.uint32(34).string(element);
            }
          }
          if (message.names != null && Object.hasOwnProperty.call(message, "names")) {
            for (const element of message.names) {
              writer.uint32(42).string(element);
            }
          }
          if (message.transactionSupport != null && Object.hasOwnProperty.call(message, "transactionSupport")) {
            $root.stedi.v1.TransactionSupport.encode(message.transactionSupport, writer.uint32(50).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SupportedPayer();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stediId = reader.string();
                break;
              }
              case 2: {
                message.displayName = reader.string();
                break;
              }
              case 3: {
                message.primaryPayerId = reader.string();
                break;
              }
              case 4: {
                if (!message.aliases || !message.aliases.length) {
                  message.aliases = [];
                }
                message.aliases.push(reader.string());
                break;
              }
              case 5: {
                if (!message.names || !message.names.length) {
                  message.names = [];
                }
                message.names.push(reader.string());
                break;
              }
              case 6: {
                message.transactionSupport = $root.stedi.v1.TransactionSupport.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.SupportedPayer) {
            return object;
          }
          const message = new $root.stedi.v1.SupportedPayer();
          if (object.stediId != null) {
            message.stediId = String(object.stediId);
          }
          if (object.displayName != null) {
            message.displayName = String(object.displayName);
          }
          if (object.primaryPayerId != null) {
            message.primaryPayerId = String(object.primaryPayerId);
          }
          if (object.aliases) {
            if (!Array.isArray(object.aliases)) {
              throw new TypeError(".stedi.v1.SupportedPayer.aliases: array type expected, but got " + (typeof object.aliases))
            }
            message.aliases = new Array(object.aliases.length);
            for (let i = 0; i < object.aliases.length; ++i) {
              message.aliases[i] = String(object.aliases[i]);
            }
          }
          if (object.names) {
            if (!Array.isArray(object.names)) {
              throw new TypeError(".stedi.v1.SupportedPayer.names: array type expected, but got " + (typeof object.names))
            }
            message.names = new Array(object.names.length);
            for (let i = 0; i < object.names.length; ++i) {
              message.names[i] = String(object.names[i]);
            }
          }
          if (object.transactionSupport != null) {
            if (typeof object.transactionSupport !== "object") {
              throw new TypeError(".stedi.v1.SupportedPayer.transactionSupport: object expected, but got " + (typeof object.transactionSupport));
            }
            message.transactionSupport = $root.stedi.v1.TransactionSupport.fromObject(object.transactionSupport);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.aliases = [];
            object.names = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.stediId = "";
            object.displayName = "";
            object.primaryPayerId = "";
            object.transactionSupport = null;
          }
          let keys;
          if (message.stediId != null && message.hasOwnProperty("stediId")) {
            object.stediId = message.stediId;
          }
          if (message.displayName != null && message.hasOwnProperty("displayName")) {
            object.displayName = message.displayName;
          }
          if (message.primaryPayerId != null && message.hasOwnProperty("primaryPayerId")) {
            object.primaryPayerId = message.primaryPayerId;
          }
          if (message.aliases && message.aliases.length) {
            object.aliases = new Array(message.aliases.length);
            for (let i = 0; i < message.aliases.length; ++i) {
              object.aliases[i] = message.aliases[i];
            }
          }
          if (message.names && message.names.length) {
            object.names = new Array(message.names.length);
            for (let i = 0; i < message.names.length; ++i) {
              object.names[i] = message.names[i];
            }
          }
          if (message.transactionSupport != null && message.hasOwnProperty("transactionSupport")) {
            object.transactionSupport = $root.stedi.v1.TransactionSupport.toObject(message.transactionSupport, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.SupportedPayer";
        }
      }

      SupportedPayer.prototype.stediId = "";
      SupportedPayer.prototype.displayName = "";
      SupportedPayer.prototype.primaryPayerId = "";
      SupportedPayer.prototype.aliases = $util.emptyArray;
      SupportedPayer.prototype.names = $util.emptyArray;
      SupportedPayer.prototype.transactionSupport = null;

      return SupportedPayer;
    })();

    v1.InNetworkEligibilitySummary = (() => {
      class InNetworkEligibilitySummary {
        constructor(properties) {
          this.limitations = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new InNetworkEligibilitySummary(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.isActiveAndCovered != null && Object.hasOwnProperty.call(message, "isActiveAndCovered")) {
            writer.uint32(32).bool(message.isActiveAndCovered);
          }
          if (message.insuranceType != null && Object.hasOwnProperty.call(message, "insuranceType")) {
            writer.uint32(42).string(message.insuranceType);
          }
          if (message.individualDeductibleDollars != null && Object.hasOwnProperty.call(message, "individualDeductibleDollars")) {
            writer.uint32(49).double(message.individualDeductibleDollars);
          }
          if (message.familyDeductibleDollars != null && Object.hasOwnProperty.call(message, "familyDeductibleDollars")) {
            writer.uint32(57).double(message.familyDeductibleDollars);
          }
          if (message.individualDeductibleRemainingDollars != null && Object.hasOwnProperty.call(message, "individualDeductibleRemainingDollars")) {
            writer.uint32(65).double(message.individualDeductibleRemainingDollars);
          }
          if (message.familyDeductibleRemainingDollars != null && Object.hasOwnProperty.call(message, "familyDeductibleRemainingDollars")) {
            writer.uint32(73).double(message.familyDeductibleRemainingDollars);
          }
          if (message.coPayDollars != null && Object.hasOwnProperty.call(message, "coPayDollars")) {
            writer.uint32(81).double(message.coPayDollars);
          }
          if (message.coinsurancePercentage != null && Object.hasOwnProperty.call(message, "coinsurancePercentage")) {
            writer.uint32(89).double(message.coinsurancePercentage);
          }
          if (message.limitations != null && Object.hasOwnProperty.call(message, "limitations")) {
            for (const element of message.limitations) {
              writer.uint32(98).string(element);
            }
          }
          if (message.planName != null && Object.hasOwnProperty.call(message, "planName")) {
            writer.uint32(106).string(message.planName);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new InNetworkEligibilitySummary();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 4: {
                message.isActiveAndCovered = reader.bool();
                break;
              }
              case 5: {
                message.insuranceType = reader.string();
                break;
              }
              case 6: {
                message.individualDeductibleDollars = reader.double();
                break;
              }
              case 7: {
                message.familyDeductibleDollars = reader.double();
                break;
              }
              case 8: {
                message.individualDeductibleRemainingDollars = reader.double();
                break;
              }
              case 9: {
                message.familyDeductibleRemainingDollars = reader.double();
                break;
              }
              case 10: {
                message.coPayDollars = reader.double();
                break;
              }
              case 11: {
                message.coinsurancePercentage = reader.double();
                break;
              }
              case 12: {
                if (!message.limitations || !message.limitations.length) {
                  message.limitations = [];
                }
                message.limitations.push(reader.string());
                break;
              }
              case 13: {
                message.planName = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.stedi.v1.InNetworkEligibilitySummary) {
            return object;
          }
          const message = new $root.stedi.v1.InNetworkEligibilitySummary();
          if (object.isActiveAndCovered != null) {
            message.isActiveAndCovered = Boolean(object.isActiveAndCovered);
          }
          if (object.insuranceType != null) {
            message.insuranceType = String(object.insuranceType);
          }
          if (object.individualDeductibleDollars != null) {
            message.individualDeductibleDollars = Number(object.individualDeductibleDollars);
          }
          if (object.familyDeductibleDollars != null) {
            message.familyDeductibleDollars = Number(object.familyDeductibleDollars);
          }
          if (object.individualDeductibleRemainingDollars != null) {
            message.individualDeductibleRemainingDollars = Number(object.individualDeductibleRemainingDollars);
          }
          if (object.familyDeductibleRemainingDollars != null) {
            message.familyDeductibleRemainingDollars = Number(object.familyDeductibleRemainingDollars);
          }
          if (object.coPayDollars != null) {
            message.coPayDollars = Number(object.coPayDollars);
          }
          if (object.coinsurancePercentage != null) {
            message.coinsurancePercentage = Number(object.coinsurancePercentage);
          }
          if (object.limitations) {
            if (!Array.isArray(object.limitations)) {
              throw new TypeError(".stedi.v1.InNetworkEligibilitySummary.limitations: array type expected, but got " + (typeof object.limitations))
            }
            message.limitations = new Array(object.limitations.length);
            for (let i = 0; i < object.limitations.length; ++i) {
              message.limitations[i] = String(object.limitations[i]);
            }
          }
          if (object.planName != null) {
            message.planName = String(object.planName);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.limitations = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.isActiveAndCovered = false;
            object.insuranceType = "";
          }
          let keys;
          if (message.isActiveAndCovered != null && message.hasOwnProperty("isActiveAndCovered")) {
            object.isActiveAndCovered = message.isActiveAndCovered;
          }
          if (message.insuranceType != null && message.hasOwnProperty("insuranceType")) {
            object.insuranceType = message.insuranceType;
          }
          if (message.individualDeductibleDollars != null && message.hasOwnProperty("individualDeductibleDollars")) {
            object.individualDeductibleDollars = options.json && !isFinite(message.individualDeductibleDollars) ? String(message.individualDeductibleDollars) : message.individualDeductibleDollars;
          }
          if (message.familyDeductibleDollars != null && message.hasOwnProperty("familyDeductibleDollars")) {
            object.familyDeductibleDollars = options.json && !isFinite(message.familyDeductibleDollars) ? String(message.familyDeductibleDollars) : message.familyDeductibleDollars;
          }
          if (message.individualDeductibleRemainingDollars != null && message.hasOwnProperty("individualDeductibleRemainingDollars")) {
            object.individualDeductibleRemainingDollars = options.json && !isFinite(message.individualDeductibleRemainingDollars) ? String(message.individualDeductibleRemainingDollars) : message.individualDeductibleRemainingDollars;
          }
          if (message.familyDeductibleRemainingDollars != null && message.hasOwnProperty("familyDeductibleRemainingDollars")) {
            object.familyDeductibleRemainingDollars = options.json && !isFinite(message.familyDeductibleRemainingDollars) ? String(message.familyDeductibleRemainingDollars) : message.familyDeductibleRemainingDollars;
          }
          if (message.coPayDollars != null && message.hasOwnProperty("coPayDollars")) {
            object.coPayDollars = options.json && !isFinite(message.coPayDollars) ? String(message.coPayDollars) : message.coPayDollars;
          }
          if (message.coinsurancePercentage != null && message.hasOwnProperty("coinsurancePercentage")) {
            object.coinsurancePercentage = options.json && !isFinite(message.coinsurancePercentage) ? String(message.coinsurancePercentage) : message.coinsurancePercentage;
          }
          if (message.limitations && message.limitations.length) {
            object.limitations = new Array(message.limitations.length);
            for (let i = 0; i < message.limitations.length; ++i) {
              object.limitations[i] = message.limitations[i];
            }
          }
          if (message.planName != null && message.hasOwnProperty("planName")) {
            object.planName = message.planName;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "stedi.v1.InNetworkEligibilitySummary";
        }
      }

      InNetworkEligibilitySummary.prototype.isActiveAndCovered = false;
      InNetworkEligibilitySummary.prototype.insuranceType = "";
      InNetworkEligibilitySummary.prototype.individualDeductibleDollars = null;
      InNetworkEligibilitySummary.prototype.familyDeductibleDollars = null;
      InNetworkEligibilitySummary.prototype.individualDeductibleRemainingDollars = null;
      InNetworkEligibilitySummary.prototype.familyDeductibleRemainingDollars = null;
      InNetworkEligibilitySummary.prototype.coPayDollars = null;
      InNetworkEligibilitySummary.prototype.coinsurancePercentage = null;
      InNetworkEligibilitySummary.prototype.limitations = $util.emptyArray;
      InNetworkEligibilitySummary.prototype.planName = null;

      return InNetworkEligibilitySummary;
    })();

    v1.TransactionSupportStatus = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "TRANSACTION_SUPPORT_STATUS_UNSPECIFIED"] = 0;
      values[valuesById[1] = "SUPPORTED"] = 1;
      values[valuesById[2] = "NOT_SUPPORTED"] = 2;
      values[valuesById[3] = "ENROLLMENT_REQUIRED"] = 3;
      return values;
    })();

    v1.StediService = (() => {
      class StediService extends $protobuf.rpc.Service {
        constructor(rpcImpl, requestDelimited = false, responseDelimited = false) {
          super(rpcImpl, requestDelimited, responseDelimited);
        }

        static create(rpcImpl, requestDelimited = false, responseDelimited = false) {
          return new StediService(rpcImpl, requestDelimited, responseDelimited);
        }
      }

      Object.defineProperty(StediService.prototype.listSupportedPayers = function listSupportedPayers(request, callback) {
        return this.rpcCall(listSupportedPayers, $root.stedi.v1.ListSupportedPayersRequest, $root.stedi.v1.ListSupportedPayersResponse, request, callback);
      }, "name", { value: "ListSupportedPayers" });

      Object.defineProperty(StediService.prototype.checkEligibility = function checkEligibility(request, callback) {
        return this.rpcCall(checkEligibility, $root.stedi.v1.CheckEligibilityRequest, $root.stedi.v1.CheckEligibilityResponse, request, callback);
      }, "name", { value: "CheckEligibility" });

      return StediService;
    })();

    return v1;
  })();

  return stedi;
})();

export const stedi = $root.stedi;
