import { Button } from '../../../Button';
import { Flexbox } from '../../../Flexbox';
import { LoadMoreRowProps } from './types';
import { Text } from '../../../Typography';
import { memo } from 'react';

const LoadMoreRow: React.FC<LoadMoreRowProps> = ({
  numberOfColumns,
  fetchNextPage,
  isFetching,
  isLoading,
  virtualRow,
  virtualizer,
}) => {
  const style: React.CSSProperties = {
    transform: `translateY(${virtualRow.start}px)`,
    width: '100%',
  };

  return (
    <tr
      data-index={virtualRow.index}
      ref={virtualizer.measureElement}
      style={style}
    >
      <td colSpan={numberOfColumns}>
        <Flexbox
          justifyContent='center'
          alignItems='center'
          borderType='borderTop'
          p={0.5}
        >
          <Button
            size='base'
            variant='primary'
            onClick={fetchNextPage}
            isDisabled={isFetching || isLoading}
          >
            <Text tag='h5' color='inherit'>
              Load More...
            </Text>
          </Button>
        </Flexbox>
      </td>
    </tr>
  );
};

export const MemoizedLoadMoreRow = memo(LoadMoreRow);
