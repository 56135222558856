import { Button } from '../Button';
import { Container } from '../Container';
import { ErrorBoundaryProps } from './types';
import { FC, memo, useEffect, useState } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';

const ErrorBoundary: FC<ErrorBoundaryProps> = ({ children, logError }) => {
  const theme = useTheme();
  const [hasError, setHasError] = useState<boolean>(false);

  const handleRetryClick = () => {
    setHasError(false);
  };

  useEffect(() => {
    const handleWindowError = (error: ErrorEvent) => {
      if (
        error.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        return;
      }
      logError(error);
      setHasError(true);
    };

    window.addEventListener('error', handleWindowError);

    return () => {
      window.removeEventListener('error', handleWindowError);
    };
  }, []);

  if (hasError) {
    return (
      <Flexbox
        direction='column'
        justifyContent='center'
        alignItems='center'
        p={2}
      >
        <Icon name='warning' size={4} stroke={theme.colors.text.muted} />
        <Text tag='h1'>Oops, something went wrong!</Text>
        <Text tag='p1'>
          We're sorry for the inconvenience. Please try again by clicking the
          button below.
        </Text>
        <Container pt={2} height='auto' width='auto'>
          <Button onClick={handleRetryClick} variant='primary' size='base'>
            <Text tag='h3' color='inherit'>
              Retry
            </Text>
          </Button>
        </Container>
      </Flexbox>
    );
  }

  return children;
};

export const MemoizedErrorBoundary = memo(ErrorBoundary);
