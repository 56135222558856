import { ThemeContainerProps } from '@morf/theming/types';
import { styled } from '@morf/theming';
import React from 'react';

export const CelExpressionSpan = styled.span<{
  theme: ThemeContainerProps;
  children?: React.ReactNode;
}>`
  border: ${(props) => props.theme.borderWidth[0.0625]} solid
    ${({ theme }) => theme.colors.ui.divider};
  background-color: ${({ theme }) => theme.colors.ui.card};
  border-radius: ${(props) => props.theme.borderRadius[0.25]};
  padding: ${(props) => props.theme.spacing[0.125]}
    ${(props) => props.theme.spacing[0.25]};
  margin: 0 ${(props) => props.theme.spacing[0.25]};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spacing[0.25]};
  white-space: nowrap;
  vertical-align: baseline;
  font-size: ${(props) => props.theme.fontSizes[0.625]};
  color: ${(props) => props.theme.colors.ui.dark};
`;

export const NodeViewWrapperStyled = styled.span`
  &.cel-expression-wrapper {
    display: inline;
    white-space: normal;
    vertical-align: baseline;
  }
`;
