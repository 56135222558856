import { Edge } from 'reactflow';
import { CustomNode } from './CustomNode/types';

export const nodesWithMultipleEdges = (
  nodes: CustomNode[],
  edges: Edge[]
): string[] => {
  const nodesWithMultipleEdges: string[] = [];

  nodes.forEach((node) => {
    const sourceEdges = edges.filter((edge) => edge.source === node.id);

    if (sourceEdges.length > 1) {
      nodesWithMultipleEdges.push(node.id);
    }
  });

  return nodesWithMultipleEdges;
};
