"use strict";

import * as $protobuf from "protobufjs/minimal";
import { timing as timing$1 } from "../../timing/v1/timing_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.morf_appointment = (() => {
  const morf_appointment = {};
  morf_appointment.v1 = (() => {
    const v1 = {};
    v1.SubscribedEventTypes = (() => {
      class SubscribedEventTypes {
        constructor(properties) {
          this.eventTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SubscribedEventTypes(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.eventTypes != null && Object.hasOwnProperty.call(message, "eventTypes")) {
            writer.uint32(10).fork();
            for (const element of message.eventTypes) {
              writer.int32(element)
            }
            writer.ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SubscribedEventTypes();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.eventTypes || !message.eventTypes.length) {
                  message.eventTypes = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2) message.eventTypes.push(reader.int32());
                } else {
                  message.eventTypes.push(reader.int32());
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.morf_appointment.v1.SubscribedEventTypes) {
            return object;
          }
          const message = new $root.morf_appointment.v1.SubscribedEventTypes();
          if (object.eventTypes) {
            if (!Array.isArray(object.eventTypes)) {
              throw new TypeError(".morf_appointment.v1.SubscribedEventTypes.eventTypes: array type expected, but got " + (typeof object.eventTypes))
            }
            message.eventTypes = new Array(object.eventTypes.length);
            for (let i = 0; i < object.eventTypes.length; ++i) {
              switch (object.eventTypes[i]) {
                case "UNSPECIFIED_ILLEGAL_MORF_APPOINTMENT_EVENT_TYPE":
                case 0: {
                  message.eventTypes[i] = 0;
                  break;
                }
                case "MORF_APPOINTMENT_UPCOMING":
                case 1: {
                  message.eventTypes[i] = 1;
                  break;
                }
                case "MORF_APPOINTMENT_ENDED":
                case 2: {
                  message.eventTypes[i] = 2;
                  break;
                }
                default: {
                  if (typeof object.eventTypes[i] == "number") {
                    message.eventTypes[i] = object.eventTypes[i];
                    break;
                  }
                  message.eventTypes[i] = 0;
                  break;
                }
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.eventTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.eventTypes && message.eventTypes.length) {
            object.eventTypes = new Array(message.eventTypes.length);
            for (let i = 0; i < message.eventTypes.length; ++i) {
              object.eventTypes[i] = options.enums === String ? $root.morf_appointment.v1.MorfAppointmentEventType[message.eventTypes[i]] === undefined ? message.eventTypes[i] : $root.morf_appointment.v1.MorfAppointmentEventType[message.eventTypes[i]] : message.eventTypes[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "morf_appointment.v1.SubscribedEventTypes";
        }
      }

      SubscribedEventTypes.prototype.eventTypes = $util.emptyArray;

      return SubscribedEventTypes;
    })();

    v1.MorfAppointment = (() => {
      class MorfAppointment {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MorfAppointment(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.daysUntilAppointment != null && Object.hasOwnProperty.call(message, "daysUntilAppointment")) {
            writer.uint32(8).int32(message.daysUntilAppointment);
          }
          if (message.hoursUntilAppointment != null && Object.hasOwnProperty.call(message, "hoursUntilAppointment")) {
            writer.uint32(16).int32(message.hoursUntilAppointment);
          }
          if (message.addToCalLink != null && Object.hasOwnProperty.call(message, "addToCalLink")) {
            writer.uint32(26).string(message.addToCalLink);
          }
          if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt")) {
            timing$1.v1.Timestamp.encode(message.createdAt, writer.uint32(34).fork()).ldelim();
          }
          if (message.createdBy != null && Object.hasOwnProperty.call(message, "createdBy")) {
            writer.uint32(42).string(message.createdBy);
          }
          if (message.datetime != null && Object.hasOwnProperty.call(message, "datetime")) {
            timing$1.v1.Timestamp.encode(message.datetime, writer.uint32(50).fork()).ldelim();
          }
          if (message.id != null && Object.hasOwnProperty.call(message, "id")) {
            writer.uint32(58).string(message.id);
          }
          if (message.lengthMinutes != null && Object.hasOwnProperty.call(message, "lengthMinutes")) {
            writer.uint32(66).string(message.lengthMinutes);
          }
          if (message.locationLabel != null && Object.hasOwnProperty.call(message, "locationLabel")) {
            writer.uint32(74).string(message.locationLabel);
          }
          if (message.locationType != null && Object.hasOwnProperty.call(message, "locationType")) {
            writer.uint32(82).string(message.locationType);
          }
          if (message.locationDetail != null && Object.hasOwnProperty.call(message, "locationDetail")) {
            writer.uint32(90).string(message.locationDetail);
          }
          if (message.providerFullName != null && Object.hasOwnProperty.call(message, "providerFullName")) {
            writer.uint32(98).string(message.providerFullName);
          }
          if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId")) {
            writer.uint32(106).string(message.providerId);
          }
          if (message.patientTimezoneIdentifier != null && Object.hasOwnProperty.call(message, "patientTimezoneIdentifier")) {
            writer.uint32(114).string(message.patientTimezoneIdentifier);
          }
          if (message.timezoneOffset != null && Object.hasOwnProperty.call(message, "timezoneOffset")) {
            writer.uint32(122).string(message.timezoneOffset);
          }
          if (message.timezoneShortName != null && Object.hasOwnProperty.call(message, "timezoneShortName")) {
            writer.uint32(130).string(message.timezoneShortName);
          }
          if (message.typeId != null && Object.hasOwnProperty.call(message, "typeId")) {
            writer.uint32(138).string(message.typeId);
          }
          if (message.typeTitle != null && Object.hasOwnProperty.call(message, "typeTitle")) {
            writer.uint32(146).string(message.typeTitle);
          }
          if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt")) {
            timing$1.v1.Timestamp.encode(message.updatedAt, writer.uint32(154).fork()).ldelim();
          }
          if (message.morfAppointmentEventType != null && Object.hasOwnProperty.call(message, "morfAppointmentEventType")) {
            writer.uint32(160).int32(message.morfAppointmentEventType);
          }
          if (message.minutesUntilAppointment != null && Object.hasOwnProperty.call(message, "minutesUntilAppointment")) {
            writer.uint32(168).int32(message.minutesUntilAppointment);
          }
          if (message.customerProvidedId != null && Object.hasOwnProperty.call(message, "customerProvidedId")) {
            writer.uint32(178).string(message.customerProvidedId);
          }
          if (message.healthiePatientId != null && Object.hasOwnProperty.call(message, "healthiePatientId")) {
            writer.uint32(186).string(message.healthiePatientId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MorfAppointment();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.daysUntilAppointment = reader.int32();
                break;
              }
              case 2: {
                message.hoursUntilAppointment = reader.int32();
                break;
              }
              case 3: {
                message.addToCalLink = reader.string();
                break;
              }
              case 4: {
                message.createdAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.createdBy = reader.string();
                break;
              }
              case 6: {
                message.datetime = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.id = reader.string();
                break;
              }
              case 8: {
                message.lengthMinutes = reader.string();
                break;
              }
              case 9: {
                message.locationLabel = reader.string();
                break;
              }
              case 10: {
                message.locationType = reader.string();
                break;
              }
              case 11: {
                message.locationDetail = reader.string();
                break;
              }
              case 12: {
                message.providerFullName = reader.string();
                break;
              }
              case 13: {
                message.providerId = reader.string();
                break;
              }
              case 14: {
                message.patientTimezoneIdentifier = reader.string();
                break;
              }
              case 15: {
                message.timezoneOffset = reader.string();
                break;
              }
              case 16: {
                message.timezoneShortName = reader.string();
                break;
              }
              case 17: {
                message.typeId = reader.string();
                break;
              }
              case 18: {
                message.typeTitle = reader.string();
                break;
              }
              case 19: {
                message.updatedAt = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 20: {
                message.morfAppointmentEventType = reader.int32();
                break;
              }
              case 21: {
                message.minutesUntilAppointment = reader.int32();
                break;
              }
              case 22: {
                message.customerProvidedId = reader.string();
                break;
              }
              case 23: {
                message.healthiePatientId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.morf_appointment.v1.MorfAppointment) {
            return object;
          }
          const message = new $root.morf_appointment.v1.MorfAppointment();
          if (object.daysUntilAppointment != null) {
            message.daysUntilAppointment = object.daysUntilAppointment | 0;
          }
          if (object.hoursUntilAppointment != null) {
            message.hoursUntilAppointment = object.hoursUntilAppointment | 0;
          }
          if (object.addToCalLink != null) {
            message.addToCalLink = String(object.addToCalLink);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== "object") {
              throw new TypeError(".morf_appointment.v1.MorfAppointment.createdAt: object expected, but got " + (typeof object.createdAt));
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(object.createdAt);
          }
          if (object.createdBy != null) {
            message.createdBy = String(object.createdBy);
          }
          if (object.datetime != null) {
            if (typeof object.datetime !== "object") {
              throw new TypeError(".morf_appointment.v1.MorfAppointment.datetime: object expected, but got " + (typeof object.datetime));
            }
            message.datetime = timing$1.v1.Timestamp.fromObject(object.datetime);
          }
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.lengthMinutes != null) {
            message.lengthMinutes = String(object.lengthMinutes);
          }
          if (object.locationLabel != null) {
            message.locationLabel = String(object.locationLabel);
          }
          if (object.locationType != null) {
            message.locationType = String(object.locationType);
          }
          if (object.locationDetail != null) {
            message.locationDetail = String(object.locationDetail);
          }
          if (object.providerFullName != null) {
            message.providerFullName = String(object.providerFullName);
          }
          if (object.providerId != null) {
            message.providerId = String(object.providerId);
          }
          if (object.patientTimezoneIdentifier != null) {
            message.patientTimezoneIdentifier = String(object.patientTimezoneIdentifier);
          }
          if (object.timezoneOffset != null) {
            message.timezoneOffset = String(object.timezoneOffset);
          }
          if (object.timezoneShortName != null) {
            message.timezoneShortName = String(object.timezoneShortName);
          }
          if (object.typeId != null) {
            message.typeId = String(object.typeId);
          }
          if (object.typeTitle != null) {
            message.typeTitle = String(object.typeTitle);
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== "object") {
              throw new TypeError(".morf_appointment.v1.MorfAppointment.updatedAt: object expected, but got " + (typeof object.updatedAt));
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(object.updatedAt);
          }
          if (object.morfAppointmentEventType != null) {
            switch (object.morfAppointmentEventType) {
              case "UNSPECIFIED_ILLEGAL_MORF_APPOINTMENT_EVENT_TYPE":
              case 0: {
                message.morfAppointmentEventType = 0;
                break;
              }
              case "MORF_APPOINTMENT_UPCOMING":
              case 1: {
                message.morfAppointmentEventType = 1;
                break;
              }
              case "MORF_APPOINTMENT_ENDED":
              case 2: {
                message.morfAppointmentEventType = 2;
                break;
              }
              default: {
                if (typeof object.morfAppointmentEventType == "number") {
                  message.morfAppointmentEventType = object.morfAppointmentEventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.minutesUntilAppointment != null) {
            message.minutesUntilAppointment = object.minutesUntilAppointment | 0;
          }
          if (object.customerProvidedId != null) {
            message.customerProvidedId = String(object.customerProvidedId);
          }
          if (object.healthiePatientId != null) {
            message.healthiePatientId = String(object.healthiePatientId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.daysUntilAppointment = 0;
            object.hoursUntilAppointment = 0;
            object.addToCalLink = "";
            object.createdAt = null;
            object.createdBy = "";
            object.datetime = null;
            object.id = "";
            object.lengthMinutes = "";
            object.locationLabel = "";
            object.locationType = "";
            object.locationDetail = "";
            object.providerFullName = "";
            object.providerId = "";
            object.patientTimezoneIdentifier = "";
            object.timezoneOffset = "";
            object.timezoneShortName = "";
            object.typeId = "";
            object.typeTitle = "";
            object.updatedAt = null;
            object.morfAppointmentEventType = options.enums === String ? "UNSPECIFIED_ILLEGAL_MORF_APPOINTMENT_EVENT_TYPE" : 0
            object.minutesUntilAppointment = 0;
          }
          let keys;
          if (message.daysUntilAppointment != null && message.hasOwnProperty("daysUntilAppointment")) {
            object.daysUntilAppointment = message.daysUntilAppointment;
          }
          if (message.hoursUntilAppointment != null && message.hasOwnProperty("hoursUntilAppointment")) {
            object.hoursUntilAppointment = message.hoursUntilAppointment;
          }
          if (message.addToCalLink != null && message.hasOwnProperty("addToCalLink")) {
            object.addToCalLink = message.addToCalLink;
          }
          if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
            object.createdAt = timing$1.v1.Timestamp.toObject(message.createdAt, options);
          }
          if (message.createdBy != null && message.hasOwnProperty("createdBy")) {
            object.createdBy = message.createdBy;
          }
          if (message.datetime != null && message.hasOwnProperty("datetime")) {
            object.datetime = timing$1.v1.Timestamp.toObject(message.datetime, options);
          }
          if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
          }
          if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes")) {
            object.lengthMinutes = message.lengthMinutes;
          }
          if (message.locationLabel != null && message.hasOwnProperty("locationLabel")) {
            object.locationLabel = message.locationLabel;
          }
          if (message.locationType != null && message.hasOwnProperty("locationType")) {
            object.locationType = message.locationType;
          }
          if (message.locationDetail != null && message.hasOwnProperty("locationDetail")) {
            object.locationDetail = message.locationDetail;
          }
          if (message.providerFullName != null && message.hasOwnProperty("providerFullName")) {
            object.providerFullName = message.providerFullName;
          }
          if (message.providerId != null && message.hasOwnProperty("providerId")) {
            object.providerId = message.providerId;
          }
          if (message.patientTimezoneIdentifier != null && message.hasOwnProperty("patientTimezoneIdentifier")) {
            object.patientTimezoneIdentifier = message.patientTimezoneIdentifier;
          }
          if (message.timezoneOffset != null && message.hasOwnProperty("timezoneOffset")) {
            object.timezoneOffset = message.timezoneOffset;
          }
          if (message.timezoneShortName != null && message.hasOwnProperty("timezoneShortName")) {
            object.timezoneShortName = message.timezoneShortName;
          }
          if (message.typeId != null && message.hasOwnProperty("typeId")) {
            object.typeId = message.typeId;
          }
          if (message.typeTitle != null && message.hasOwnProperty("typeTitle")) {
            object.typeTitle = message.typeTitle;
          }
          if (message.updatedAt != null && message.hasOwnProperty("updatedAt")) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(message.updatedAt, options);
          }
          if (message.morfAppointmentEventType != null && message.hasOwnProperty("morfAppointmentEventType")) {
            object.morfAppointmentEventType = options.enums === String ? $root.morf_appointment.v1.MorfAppointmentEventType[message.morfAppointmentEventType] === undefined ? message.morfAppointmentEventType : $root.morf_appointment.v1.MorfAppointmentEventType[message.morfAppointmentEventType] : message.morfAppointmentEventType;
          }
          if (message.minutesUntilAppointment != null && message.hasOwnProperty("minutesUntilAppointment")) {
            object.minutesUntilAppointment = message.minutesUntilAppointment;
          }
          if (message.customerProvidedId != null && message.hasOwnProperty("customerProvidedId")) {
            object.customerProvidedId = message.customerProvidedId;
          }
          if (message.healthiePatientId != null && message.hasOwnProperty("healthiePatientId")) {
            object.healthiePatientId = message.healthiePatientId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "morf_appointment.v1.MorfAppointment";
        }
      }

      MorfAppointment.prototype.daysUntilAppointment = 0;
      MorfAppointment.prototype.hoursUntilAppointment = 0;
      MorfAppointment.prototype.addToCalLink = "";
      MorfAppointment.prototype.createdAt = null;
      MorfAppointment.prototype.createdBy = "";
      MorfAppointment.prototype.datetime = null;
      MorfAppointment.prototype.id = "";
      MorfAppointment.prototype.lengthMinutes = "";
      MorfAppointment.prototype.locationLabel = "";
      MorfAppointment.prototype.locationType = "";
      MorfAppointment.prototype.locationDetail = "";
      MorfAppointment.prototype.providerFullName = "";
      MorfAppointment.prototype.providerId = "";
      MorfAppointment.prototype.patientTimezoneIdentifier = "";
      MorfAppointment.prototype.timezoneOffset = "";
      MorfAppointment.prototype.timezoneShortName = "";
      MorfAppointment.prototype.typeId = "";
      MorfAppointment.prototype.typeTitle = "";
      MorfAppointment.prototype.updatedAt = null;
      MorfAppointment.prototype.morfAppointmentEventType = 0;
      MorfAppointment.prototype.minutesUntilAppointment = 0;
      MorfAppointment.prototype.customerProvidedId = null;
      MorfAppointment.prototype.healthiePatientId = null;

      return MorfAppointment;
    })();

    v1.MorfAppointmentEventType = (function() {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[valuesById[0] = "UNSPECIFIED_ILLEGAL_MORF_APPOINTMENT_EVENT_TYPE"] = 0;
      values[valuesById[1] = "MORF_APPOINTMENT_UPCOMING"] = 1;
      values[valuesById[2] = "MORF_APPOINTMENT_ENDED"] = 2;
      return values;
    })();

    return v1;
  })();

  return morf_appointment;
})();

export const morf_appointment = $root.morf_appointment;
