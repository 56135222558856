import { CustomNode } from '../../types';
import { Edge } from 'reactflow';
import { ProfileLookupNodeType } from '../types';
import { calculateProfileLookupSubflowHeight } from './calculateProfileLookupSubflowHeight';
import { profileLookupSubflowFieldNotFound } from './profileLookupSubflowFieldNotFound';
import { profileLookupSubflowMultipleProfilesFound } from './profileLookupSubflowMultipleProfilesFound';
import { profileLookupSubflowProfileFound } from './profileLookupSubflowProfileFound';
import { profileLookupSubflowProfileNotFound } from './profileLookupSubflowProfileNotFound';
import { profileLookupSubflowProfileNotFoundAction } from './profileLookupSubflowProfileNotFoundAction';
import { profiles } from '@morf/proto/profiles/v1/profiles_ts_proto';
import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';

export const profileLookupSubflow = (
  configuration: profiles.v1.LookupConfiguration | null | undefined,
  nextNodeId: string | undefined
) => {
  let nodes: CustomNode[] = [];
  let edges: Edge[] = [];

  const lookupsLength = configuration?.lookupsSequence?.length || 0;

  /* edge connecting lookup node to subflow */
  edges.push({
    id: 'profile-lookup-node-to-field-lookup0',
    source: 'profile-lookup-node',
    target: 'lookup-subflow-field-lookup-0',
  });

  /* loop through lookup array */
  (configuration?.lookupsSequence || []).flatMap((lookup, index, lookups) => {
    nodes.push({
      id: `lookup-subflow-field-lookup-${index}`,
      type: ProfileLookupNodeType.FieldNode,
      data: {
        value: {
          name: `${
            lookup.index?.id?.idFieldLookup?.celExpressionStr ||
            lookup.index?.email?.uniqueIndexFieldLookup?.celExpressionStr ||
            lookup.index?.phone?.uniqueIndexFieldLookup?.celExpressionStr
          }`,
          title: '',
          configuration: new workflows.v1.WorkflowNode({}),
        },
      },
      parentNode:
        index === 0
          ? 'profile-lookup-node'
          : `lookup-subflow-profile-not-found-${index - 1}`,
      position: { x: 0, y: 100 },
    });

    /* multiple profile found branch */
    const {
      nodes: multipleProfilesFoundNodes,
      edges: multipleProfilesFoundEdges,
    } = profileLookupSubflowMultipleProfilesFound(
      index,
      lookup,
      configuration?.lookupType
    );
    nodes.push(...multipleProfilesFoundNodes);
    edges.push(...multipleProfilesFoundEdges);

    /* profile found branch */
    const { nodes: profileFoundNodes, edges: profileFoundEdges } =
      profileLookupSubflowProfileFound(index);
    nodes.push(...profileFoundNodes);
    edges.push(...profileFoundEdges);

    /* profile not found branch */
    const { nodes: profileNotFoundNodes, edges: profileNotFoundEdges } =
      profileLookupSubflowProfileNotFound(index);
    nodes.push(...profileNotFoundNodes);
    edges.push(...profileNotFoundEdges);

    /* field not found branch */
    const { nodes: fieldNotFoundNodes, edges: fieldNotFoundEdges } =
      profileLookupSubflowFieldNotFound(index, lookup, lookupsLength);
    nodes.push(...fieldNotFoundNodes);
    edges.push(...fieldNotFoundEdges);
  });

  /* profile not found action branch */
  const {
    nodes: profileNotFoundActionNodes,
    edges: profileNotFoundActionEdges,
  } = profileLookupSubflowProfileNotFoundAction(configuration, lookupsLength);
  nodes.push(...profileNotFoundActionNodes);
  edges.push(...profileNotFoundActionEdges);

  edges.push({
    id: `lookup-subflow-profile-not-found-${
      lookupsLength - 1
    }-profile-not-found-action`,
    source: `lookup-subflow-profile-not-found-${lookupsLength - 1}`,
    target: `lookup-subflow-profile-not-found-action`,
  });

  /* return to workflow node */
  nodes.push({
    id: 'lookup-subflow-return-to-workflow',
    type: ProfileLookupNodeType.ReturnWorkflowNode,
    data: {
      value: {
        name: 'return workflow',
        title: 'Return Workflow',
        configuration: new workflows.v1.WorkflowNode({}),
      },
    },
    parentNode: `lookup-subflow-profile-found-${lookupsLength - 1}`,
    position: {
      x: 0,
      y:
        configuration?.noProfileFoundAction ===
          profiles.v1.NoProfileFoundAction
            .NO_PROFILE_FOUND_ACTION_CREATE_LEAD ||
        configuration?.noProfileFoundAction ===
          profiles.v1.NoProfileFoundAction
            .NO_PROFILE_FOUND_ACTION_CREATE_PATIENT
          ? 200
          : 100,
    },
  });

  /* edge connecting return to workflow to the next node in the flow */
  edges.push({
    id: `lookup-subflow-return-to-workflow-${nextNodeId}`,
    source: `lookup-subflow-return-to-workflow`,
    target: `${nextNodeId}`,
  });

  const lookupSubflowNodes: CustomNode[] = nodes.map((node) => ({
    ...node,
    selectable: false,
    selected: false,
    focusable: false,
  }));

  const lookupSubflowEdges = edges.map((edge) => ({
    ...edge,
    selectable: false,
    focusable: false,
  }));

  const subflowHeight = calculateProfileLookupSubflowHeight(configuration);

  return { lookupSubflowNodes, lookupSubflowEdges, subflowHeight };
};
