import Long from 'long';
import { timing } from '@morf/proto/timing/v1/timing_ts_proto';

export const dateToTimestamp = (date: Date): timing.v1.Timestamp => {
  const seconds = Long.fromNumber(Math.floor(date.getTime() / 1000));
  const nanoseconds = (date.getTime() % 1000) * 1e6;
  return {
    seconds,
    nanoseconds,
  };
};
