import { ProfileProperty } from './Property/types';
import { profile_properties } from '@morf/proto/profile_properties/v1/profile_properties_ts_proto';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';

export const formatProperties = (
  properties: Record<string, profile_properties.v1.ProfileProperty> | undefined,
  selectedSource: workflow_parameters.v1.DestinationActionParameterSource | null
): ProfileProperty[] => {
  if (!properties) return [];

  return Object.values(properties).flatMap((property) => {
    let objectFieldsProfileProperties: ProfileProperty[] = [];
    let currentIndex = 0;

    if (property.propertySchema?.schema?.object) {
      objectFieldsProfileProperties = Object.values(
        property.propertySchema?.schema?.object.fields
      ).map((field) => ({
        index: currentIndex++,
        isSelected:
          selectedSource?.profilePropertyLookup?.propertyName ===
            property.name &&
          selectedSource?.profilePropertyLookup?.celExpressionStr ===
            field.scalar?.celExpressionStr,
        ...new profile_properties.v1.ProfileProperty({
          name: property.name,
          description: property.description,
          metadata: property.metadata,
          timing: property.timing,
          propertySchema: new profile_properties.v1.PropertySchema({
            schema: new profile_properties.v1.PropertyTypeSchema({
              scalar: new profile_properties.v1.PropertyTypeSchema.ScalarSchema(
                {
                  celExpressionStr: field.scalar?.celExpressionStr,
                  indexHash: field.scalar?.indexHash,
                  sensitivityAnnotations: field.scalar?.sensitivityAnnotations,
                  valueType: field.scalar?.valueType,
                }
              ),
            }),
          }),
          morfDefaultProperty: property.morfDefaultProperty,
        }),
      }));
    }

    const profileProperties = {
      ...property,
      index: currentIndex++,
      isSelected:
        selectedSource?.profilePropertyLookup?.propertyName === property.name,
    };

    return [...objectFieldsProfileProperties, profileProperties];
  });
};
