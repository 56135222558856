import Switch from '../Switch/Switch';
import { FieldValues, Path, PathValue } from 'react-hook-form';
import { Flexbox } from '../Flexbox';
import { SwitchFieldProps } from './types';
import { Text } from '../Typography';
import { memo } from 'react';
import { useTheme } from '@morf/theming';

const SwitchField = <T extends FieldValues>({
  id,
  label,
  setValue,
  required,
  ...props
}: SwitchFieldProps<T>) => {
  const theme = useTheme();

  const handleChange = (checked: boolean) => {
    setValue(id, checked as PathValue<T, Path<T>>);
  };

  return (
    <Flexbox
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      gap={0.5}
      height='auto'
    >
      <Flexbox direction='row' justifyContent='flex-start' gap={0.25}>
        <Text tag='h5'>{label}</Text>
        {required && (
          <Text tag='h5' color={theme.colors.support.red.darkest}>
            *
          </Text>
        )}
      </Flexbox>

      <Switch {...props} onChange={handleChange} />
    </Flexbox>
  );
};

export const MemoizedSwitchField = memo(SwitchField) as typeof SwitchField;
