import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';
import { CustomNode } from '../../types';
import { Edge } from 'reactflow';
import { ProfileLookupNodeType } from '../types';

export const profileLookupSubflowProfileNotFound = (
  index: number
): {
  nodes: CustomNode[];
  edges: Edge[];
} => {
  let nodes: CustomNode[] = [];
  let edges: Edge[] = [];

  nodes.push({
    id: `lookup-subflow-profile-not-found-${index}`,
    type: ProfileLookupNodeType.BranchNode,
    data: {
      value: {
        name: 'profile not found',
        title: 'Profile not found',
        configuration: new workflows.v1.WorkflowNode({}),
      },
    },
    parentNode: `lookup-subflow-field-lookup-${index}`,
    position: { x: 0, y: 100 },
  });

  edges.push({
    id: `lookup-subflow-field-lookup-${index}-profile-not-found-${index}`,
    source: `lookup-subflow-field-lookup-${index}`,
    target: `lookup-subflow-profile-not-found-${index}`,
  });

  edges.push({
    id: `lookup-subflow-profile-not-found-${index}-field-lookup-${index + 1}`,
    source: `lookup-subflow-profile-not-found-${index}`,
    target: `lookup-subflow-field-lookup-${index + 1}`,
  });

  return { nodes, edges };
};
