import _ from 'lodash';
import { Container } from '../../../../../../../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../../../../../../../Flexbox';
import { Icon } from '../../../../../../../Icon';
import { PropertyProps } from './types';
import { Text, TooltipText } from '../../../../../../../Typography';
import { formatSensitivities } from '../../../../../../../Helpers/formatSensitivities';
import { useTheme } from '@morf/theming';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';

const Property: FC<PropertyProps> = ({ property, onClick }) => {
  const theme = useTheme();
  const {
    description,
    index,
    isSelected,
    morfDefaultProperty,
    name,
    propertySchema,
  } = property;

  const tooltipDirection = index < 3 ? 'bottom' : 'top';
  const scalar = propertySchema?.schema?.scalar;
  const object = propertySchema?.schema?.object;

  const handleClick = () => {
    const profilePropertyLookups: workflow_parameters.v1.ProfilePropertyLookup[] =
      [];

    if (scalar) {
      profilePropertyLookups.push(
        new workflow_parameters.v1.ProfilePropertyLookup({
          indexHash: scalar.indexHash,
          isMorfDefault: morfDefaultProperty,
          propertyName: name,
          sensitivities: scalar.sensitivityAnnotations,
          celExpressionStr: scalar?.celExpressionStr,
          valueType: scalar.valueType,
        })
      );
    } else if (object) {
      for (const fieldName in object.fields) {
        const field = object?.fields[fieldName].scalar;
        profilePropertyLookups.push(
          new workflow_parameters.v1.ProfilePropertyLookup({
            indexHash: field?.indexHash,
            isMorfDefault: morfDefaultProperty,
            propertyName: name,
            sensitivities: field?.sensitivityAnnotations,
            celExpressionStr: field?.celExpressionStr,
            valueType: field?.valueType,
          })
        );
      }
    }

    onClick(
      profilePropertyLookups.map(
        (profilePropertyLookup) =>
          new workflow_parameters.v1.DestinationActionParameterSource({
            profilePropertyLookup: profilePropertyLookup,
          })
      )
    );
  };

  return (
    <Flexbox
      key={index}
      data-testid='property'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      cursor='pointer'
      height='auto'
      backgroundColor={isSelected ? theme.colors.ui.body : theme.colors.ui.card}
      hoverBackgroundColor={theme.colors.ui.body}
      onClick={handleClick}
      px={0.75}
      py={0.5}
      gap={0.5}
    >
      <Container width='auto' height='auto'>
        <Icon
          name={morfDefaultProperty ? 'properties' : 'tag'}
          size={1}
          stroke={theme.colors.ui.dark}
        />
      </Container>

      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        maxWidth='20.5rem'
        height='auto'
        gap={0}
      >
        <Container width='auto' height='auto'>
          {scalar?.celExpressionStr ? (
            <Flexbox
              direction='row'
              justifyContent='flex-start'
              alignItems='flex-start'
              flexWrap='wrap'
              gap={0}
            >
              <Text tag='p2' color={theme.colors.text.muted}>
                {name + '.'}
              </Text>
              <Text tag='p2'>{scalar.celExpressionStr}</Text>
            </Flexbox>
          ) : (
            <Text tag='p2'>{name}</Text>
          )}
        </Container>

        <TooltipText
          tag='p3'
          color={theme.colors.text.muted}
          whiteSpace='nowrap'
          tooltipWidth='19.5rem'
          tooltipDirection={tooltipDirection}
        >
          {description}
        </TooltipText>

        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          height='auto'
          gap={0.25}
        >
          <Text tag='p3' color={theme.colors.text.muted}>
            {scalar ? scalar.valueType?.type : 'object'}
          </Text>

          <Text tag='p3' color={theme.colors.text.muted}>
            {scalar
              ? formatSensitivities(scalar.sensitivityAnnotations)
              : formatSensitivities(
                  _.uniq(
                    _.flatMap(
                      object?.fields,
                      (field) => field?.scalar?.sensitivityAnnotations || []
                    )
                  )
                )}
          </Text>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedProperty = memo(Property);
