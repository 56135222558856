import { IconProps } from './types';
import { FC } from 'react';
import { IconMapping } from './IconMapping';
import { Flexbox } from '../Flexbox';

export const Icon: FC<IconProps> = ({
  name,
  onClick,
  fill = 'none',
  viewBox = '0 0 24 24',
  strokeWidth = 1.5,
  stroke = 'currentColor',
  size = 1.5,
  ...props
}) => {
  const icons = IconMapping();
  return (
    <Flexbox
      id={name}
      width={size + 'rem'}
      height={size + 'rem'}
      data-testid={name}
      cursor={props.cursor || 'inherit'}
      onClick={onClick}
      justifyContent='center'
      alignItems='center'
      {...props}
    >
      <svg
        id={name}
        data-testid={'svg-' + name}
        fill={fill}
        viewBox={viewBox}
        strokeWidth={strokeWidth}
        stroke={stroke}
        className={`w-${size} h-${size}`}
      >
        {icons[name]}
      </svg>
    </Flexbox>
  );
};
