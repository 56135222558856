import moment from 'moment';
import { convertTimestampToMoment } from './convertTimestampToMoment';
import { defaultDateFormat, defaultTimeFormat } from './constants';
import { timing } from '@morf/proto/timing/v1/timing_ts_proto';

export const determineTimestampFormat = (
  timestamp: timing.v1.Timestamp | null | undefined,
  now: moment.Moment = moment()
): string => {
  if (!timestamp) {
    return defaultDateFormat;
  }
  const timestampMoment = convertTimestampToMoment(timestamp);
  const oneDayAgo = now.subtract(1, 'days').startOf('day');

  return timestampMoment.isSameOrAfter(oneDayAgo)
    ? defaultTimeFormat
    : defaultDateFormat;
};
