import { styled } from '@morf/theming';
import { VirtualTableRowStyling } from './types';

export const StyledVirtualTableRow = styled.tr<
  {
    isSelected: boolean;
    isClickable: boolean;
    ref: (node: Element | null | undefined) => void;
  } & VirtualTableRowStyling &
    React.HTMLAttributes<HTMLTableRowElement>
>`
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
  background-color: ${(props) => props.cellBackgroundColor};

  ${(props) =>
    props.isSelected &&
    `
    background-color: ${props.cellSelectedBackgroundColor};
    border-color: ${props.cellSelectedBorderColor};
  `}
`;
