import rpcService from '../../../../../../server/browser-server';
import { ActionItem } from '../../../../../ActionItem';
import { CalculatedValue } from './CalculatedValue';
import { CategoryLabel } from '../../../../../CategoryLabel';
import { ConstantValue } from './ConstantValue';
import { Container } from '../../../../../Container';
import { CreateCustomProperty } from './CreateCustomProperty';
import { FC, Fragment, memo, useEffect, useRef, useState } from 'react';
import { Flexbox } from '../../../../../Flexbox';
import { Icon } from '../../../../../Icon';
import { Identifier } from './Identifiers/Identifier';
import { Identifiers } from './Identifiers';
import { Input } from '../../../../../Input';
import { ObjectTypeId } from './ObjectTypeIds/ObjectTypeId';
import { ObjectTypeIds } from './ObjectTypeIds';
import { PayloadField } from './PayloadFields/PayloadField';
import { PayloadFields } from './PayloadFields';
import { Properties } from './Properties';
import { Property } from './Properties/Property';
import { SearchMessage } from '../../../../../SearchMessage';
import { SelectValuePairCategory, SelectValuePairModalProps } from './types';
import { Text } from '../../../../../Typography';
import { WrapperModal } from '../../../../../WrapperModal';
import { filterIdentifiers } from './Identifiers/filterIdentifiers';
import { filterObjectTypeIds } from './ObjectTypeIds/filterObjectTypeIds';
import { filterPayloadFields } from './PayloadFields/filterPayloadFields';
import { filterProperties } from './Properties/filterProperties';
import { formatIdentifiers } from './Identifiers/formatIdentifiers';
import { formatObjectTypeIds } from './ObjectTypeIds/formatObjectTypeIds';
import { formatPayloadFields } from './PayloadFields/formatPayloadFields';
import { formatProperties } from './Properties/formatProperties';
import { getObjectTypeIdValuePairCategory } from './getObjectTypeIdValuePairCategory';
import { getVisibleValuePairCategories } from './getVisibleValuePairCategories';
import { google } from '@morf/proto/empty_ts_proto';
import { removeObjectKeys } from '../../../../../../../apps/admin/components/helpers/removeObjectKeys';
import { useQuery } from '@tanstack/react-query';
import { useTheme } from '@morf/theming';
import { useUrlParams } from '../../../../../../../apps/admin/components/helpers/useUrlParams';
import { useWorkflow } from '../../../../../../../apps/admin/components/context/workflow/useWorkflow';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';
import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';
import { TemplateEditorSection } from './TemplateEditorSection/TemplateEditorSection';
import {
  createTemplateParameterSource,
  convertTemplateSourceToTipTapFormat,
} from '../../../../../TemplateEditor/extensions/CelExpression/templateUtils';
import { ParameterizedWorkflowNode, WorkflowNodeOneOf } from '../../../types';
import { NodeType, WorkflowNodeType } from '../../../types';

const SelectValuePairContent: FC<SelectValuePairModalProps> = ({
  eventPayloadFieldSchemas,
  includeEmail,
  includePhone,
  onClick,
  onClose,
  onCreateCustomProperty,
  selectedParam,
  selectedSource,
  selectedSubRow,
  configuration,
  visibleValuePairCategories = [],
  autofocus = false,
}) => {
  const theme = useTheme();
  const { organizationId } = useUrlParams();

  const {
    selectedNode,
    customProperties,
    customPropertySchemas,
    defaultProperties,
  } = useWorkflow();

  if (visibleValuePairCategories.length === 0) {
    visibleValuePairCategories.push(
      ...getVisibleValuePairCategories(selectedParam, selectedSubRow)
    );
  }

  const hasStaticObjectTypeIdContext =
    !!selectedParam?.static?.objectTypeIdContext;

  const hasDynamicObjectTypeIdContext =
    !!selectedParam?.dynamic?.objectTypeIdContext;

  const hasObjectTypeIdContext =
    hasStaticObjectTypeIdContext || hasDynamicObjectTypeIdContext;

  const fieldName =
    selectedParam?.static?.fieldName || selectedParam?.dynamic?.fieldName;

  const {
    data: getActionFieldObjectTypeIDsResponse,
    isLoading: getActionFieldObjectTypeIDsResponseIsLoading,
  } = useQuery<workflows.v1.GetActionFieldObjectTypeIDsResponse>(
    [
      '/workflows.v1.WorkflowsService/GetActionFieldObjectTypeIDs',
      fieldName,
      organizationId,
      selectedNode?.id,
    ],
    async () => {
      const configuration = removeObjectKeys<
        WorkflowNodeType<WorkflowNodeOneOf>
      >(selectedNode!.data.value.configuration, ['runtimeType']);

      return await rpcService.workflowsV1Service.getActionFieldObjectTypeIDs(
        new workflows.v1.GetActionFieldObjectTypeIDsRequest({
          fieldName: fieldName,
          ...(selectedNode?.type === NodeType.FetchActionNode
            ? {
                fetchAction: new workflows.v1.WorkflowFetchAction({
                  ...(
                    configuration as workflows.v1.WorkflowNode &
                      ParameterizedWorkflowNode<'destinationAction'>
                  ).fetchAction,
                  // The value is not actually read by the back-end, but the protobuf needs a value to pass the interceptor's validation.
                  resultObjectKey: 'default',
                }),
              }
            : {
                destinationAction: (
                  configuration as workflows.v1.WorkflowNode &
                    ParameterizedWorkflowNode<'destinationAction'>
                ).destinationAction,
              }),
        })
      );
    },
    {
      enabled: hasObjectTypeIdContext && !!fieldName && !!selectedNode,
    }
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const filteredObjectTypeIds = filterObjectTypeIds(
    formatObjectTypeIds(
      getActionFieldObjectTypeIDsResponse?.objectTypeIds || [],
      selectedSource
    ),
    searchValue
  );

  const filteredIdentifiers = filterIdentifiers(
    formatIdentifiers(selectedSource, includeEmail, includePhone),
    searchValue
  );

  const filteredPayloadFields = filterPayloadFields(
    formatPayloadFields(
      [
        ...(eventPayloadFieldSchemas || []),
        ...(selectedNode?.data.value.workflowEventPayloadFieldSchemas || []),
        ...(selectedNode?.data.value.nodeSpecificEventPayloadFieldSchemas ||
          []),
      ],
      selectedSource
    ),
    searchValue
  );

  const slashCommandItems = filteredPayloadFields.map((payloadField) => ({
    title: payloadField.field.fieldIndex,
    description: payloadField.field.description!,
    parameterSource:
      new workflow_parameters.v1.DestinationActionParameterSource({
        eventPayloadFieldLookup: new workflow_parameters.v1.CalculatedValue({
          celExpressionStr: payloadField.field.fieldIndex,
        }),
      }),
  }));

  if (
    visibleValuePairCategories.includes(SelectValuePairCategory.UnsubscribeUrl)
  ) {
    slashCommandItems.push({
      title: 'Unsubscribe URL',
      description: 'Unsubscribe URL',
      parameterSource:
        new workflow_parameters.v1.DestinationActionParameterSource({
          calculatedValue: {
            celExpressionStr: '.UnsubscribeUrl',
            runtimeType: undefined,
          },
        }),
    });
  }

  const filteredDefaultProperties = filterProperties(
    formatProperties(defaultProperties, selectedSource),
    searchValue
  );

  const filteredCustomProperties = filterProperties(
    formatProperties(customProperties, selectedSource),
    searchValue
  );

  const [isBuildArray, setIsBuildArray] = useState<boolean>(false);
  const [isBuildObject, setIsBuildObject] = useState<boolean>(false);
  const [isCalculatedValue, setIsCalculatedValue] = useState<boolean>(
    !!selectedSource?.calculatedValue
  );
  const [isConstantValue, setIsConstantValue] = useState(
    !!selectedSource?.constantValue
  );
  const [isCustomProperties, setIsCustomProperties] = useState<boolean>(false);
  const [isElidedValue, setIsElidedValue] = useState<boolean>(false);
  const [isIdentifiers, setIsIdentifiers] = useState<boolean>(false);
  const [isMorfEventTime, setIsMorfEventTime] = useState<boolean>(false);
  const [isMorfEventType, setIsMorfEventType] = useState<boolean>(false);
  const [isMorfProperties, setIsMorfProperties] = useState<boolean>(false);
  const [isNewCustomProperty, setIsNewCustomProperty] =
    useState<boolean>(false);
  const [isObjectTypeIds, setIsObjectTypeIds] = useState<boolean>(false);
  const [isPayloadField, setIsPayloadField] = useState<boolean>(false);
  const [isTemplateEditor, setIsTemplateEditor] = useState<boolean>(
    !!selectedSource?.templateSource ||
      (selectedParam?.static?.fieldName === 'Body' &&
        configuration?.morfV1?.type === 'sendEmail')
  );
  const [isUnsubscribeUrl, setIsUnsubscribeUrl] = useState<boolean>(false);

  const [templateContent, setTemplateContent] = useState<string | null>(
    selectedSource?.templateSource
      ? convertTemplateSourceToTipTapFormat(selectedSource.templateSource)
      : null
  );

  const handleClick = (
    sources: workflow_parameters.v1.DestinationActionParameterSource[]
  ) => {
    onClick(sources);
    onClose();
  };

  const createCustomPropertyCategory = {
    label: 'New custom property',
    isExpandable: true,
    state: isNewCustomProperty,
    setState: setIsNewCustomProperty,
    actionItem: (
      <ActionItem
        isExpandable
        iconName='tag'
        label='Create new custom property'
        onClick={() => {
          setIsNewCustomProperty(true), setIsCustomProperties(false);
        }}
      />
    ),
    component: (
      <CreateCustomProperty
        customPropertySchemas={customPropertySchemas}
        onClick={(data) => {
          onCreateCustomProperty(data);
          setIsNewCustomProperty(false);
          setIsCustomProperties(true);
        }}
      />
    ),
  };

  const valuePairCategories = [
    {
      label: getObjectTypeIdValuePairCategory(selectedParam),
      isExpandable: true,
      state: isObjectTypeIds,
      setState: setIsObjectTypeIds,
      actionItem: (
        <ActionItem
          isExpandable
          iconName='curly-brackets'
          label={getObjectTypeIdValuePairCategory(selectedParam)}
          onClick={() => setIsObjectTypeIds(true)}
        />
      ),
      component: (
        <ObjectTypeIds
          isLoading={getActionFieldObjectTypeIDsResponseIsLoading}
          objectTypeIds={filteredObjectTypeIds}
          onClick={(source) => handleClick([source])}
        />
      ),
    },
    {
      label: SelectValuePairCategory.Identifiers,
      isExpandable: true,
      state: isIdentifiers,
      setState: setIsIdentifiers,
      actionItem: (
        <ActionItem
          isExpandable
          iconName='patient-circle'
          label={SelectValuePairCategory.Identifiers}
          onClick={() => setIsIdentifiers(true)}
        />
      ),
      component: (
        <Identifiers
          identifiers={filteredIdentifiers}
          onClick={(source) => handleClick([source])}
        />
      ),
    },
    {
      label: SelectValuePairCategory.MorfProperties,
      isExpandable: true,
      state: isMorfProperties,
      setState: setIsMorfProperties,
      actionItem: (
        <ActionItem
          isExpandable
          iconName='properties'
          label={SelectValuePairCategory.MorfProperties}
          onClick={() => setIsMorfProperties(true)}
        />
      ),
      component: (
        <Properties
          properties={filteredDefaultProperties}
          onClick={handleClick}
        />
      ),
    },
    {
      label: SelectValuePairCategory.CustomProperties,
      isExpandable: true,
      state: isCustomProperties,
      setState: setIsCustomProperties,
      actionItem: (
        <ActionItem
          isExpandable
          iconName='tag'
          label={SelectValuePairCategory.CustomProperties}
          onClick={() => setIsCustomProperties(true)}
        />
      ),
      component: (
        <>
          {createCustomPropertyCategory.actionItem}
          <Properties
            properties={filteredCustomProperties}
            onClick={handleClick}
          />
        </>
      ),
    },
    {
      label: SelectValuePairCategory.PayloadField,
      isExpandable: true,
      state: isPayloadField,
      setState: setIsPayloadField,
      actionItem: (
        <ActionItem
          isExpandable
          iconName='code-bracket'
          label={SelectValuePairCategory.PayloadField}
          onClick={() => setIsPayloadField(true)}
        />
      ),
      component: (
        <PayloadFields
          onClick={(source) => handleClick([source])}
          payloadFields={filteredPayloadFields}
        />
      ),
    },
    {
      label: SelectValuePairCategory.CalculatedValue,
      isExpandable: true,
      state: isCalculatedValue,
      setState: setIsCalculatedValue,
      actionItem: (
        <ActionItem
          isExpandable
          iconName='variable'
          label={SelectValuePairCategory.CalculatedValue}
          onClick={() => setIsCalculatedValue(true)}
        />
      ),
      component: (
        <CalculatedValue
          selectedSource={selectedSource}
          onClick={(source) => handleClick([source])}
        />
      ),
    },
    {
      label: SelectValuePairCategory.ConstantValue,
      isExpandable: true,
      state: isConstantValue,
      setState: setIsConstantValue,
      actionItem: (
        <ActionItem
          isExpandable
          iconName='text'
          label={SelectValuePairCategory.ConstantValue}
          onClick={() => setIsConstantValue(true)}
        />
      ),
      component: (
        <ConstantValue
          selectedParam={selectedParam}
          selectedSource={selectedSource}
          onClick={(source) => handleClick([source])}
        />
      ),
    },
    {
      label: SelectValuePairCategory.ElidedValue,
      isExpandable: false,
      state: isElidedValue,
      setState: setIsElidedValue,
      actionItem: (
        <ActionItem
          iconName='close'
          label={SelectValuePairCategory.ElidedValue}
          onClick={() => {
            setIsElidedValue(true);
            handleClick([
              new workflow_parameters.v1.DestinationActionParameterSource({
                elidedValue: new google.protobuf.Empty({}),
              }),
            ]);
          }}
        />
      ),
      component: null,
    },
    {
      label: SelectValuePairCategory.UnsubscribeUrl,
      isExpandable: false,
      state: isUnsubscribeUrl,
      setState: setIsUnsubscribeUrl,
      actionItem: (
        <ActionItem
          iconName='link'
          label={SelectValuePairCategory.UnsubscribeUrl}
          onClick={() => {
            setIsUnsubscribeUrl(true);
            handleClick([
              new workflow_parameters.v1.DestinationActionParameterSource({
                calculatedValue: {
                  celExpressionStr: '.UnsubscribeUrl',
                  runtimeType: undefined,
                },
              }),
            ]);
          }}
        />
      ),
      component: null,
    },
    {
      label: SelectValuePairCategory.MorfEventType,
      isExpandable: false,
      state: isMorfEventType,
      setState: setIsMorfEventType,
      actionItem: (
        <ActionItem
          iconName='details'
          label={SelectValuePairCategory.MorfEventType}
          onClick={() => {
            setIsMorfEventType(true);
            handleClick([
              new workflow_parameters.v1.DestinationActionParameterSource({
                morfEventType: new google.protobuf.Empty({}),
              }),
            ]);
          }}
        />
      ),
      component: null,
    },
    {
      label: SelectValuePairCategory.MorfEventTime,
      isExpandable: false,
      state: isMorfEventTime,
      setState: setIsMorfEventTime,
      actionItem: (
        <ActionItem
          iconName='clock'
          label={SelectValuePairCategory.MorfEventTime}
          onClick={() => {
            setIsMorfEventTime(true);
            handleClick([
              new workflow_parameters.v1.DestinationActionParameterSource({
                morfEventTime: new google.protobuf.Empty({}),
              }),
            ]);
          }}
        />
      ),
      component: null,
    },
    {
      label: SelectValuePairCategory.BuildArray,
      isExpandable: false,
      state: isBuildArray,
      setState: setIsBuildArray,
      actionItem: (
        <ActionItem
          iconName='table-comfortable'
          label={SelectValuePairCategory.BuildArray}
          onClick={() => {
            handleClick([
              new workflow_parameters.v1.DestinationActionParameterSource({
                elidedValue: new google.protobuf.Empty({}),
              }),
            ]);
          }}
        />
      ),
      component: null,
    },
    {
      label: SelectValuePairCategory.BuildObject,
      isExpandable: false,
      state: isBuildObject,
      setState: setIsBuildObject,
      actionItem: (
        <ActionItem
          iconName='table'
          label={SelectValuePairCategory.BuildObject}
          onClick={() => {
            handleClick([
              new workflow_parameters.v1.DestinationActionParameterSource({
                elidedValue: new google.protobuf.Empty({}),
              }),
            ]);
          }}
        />
      ),
      component: null,
    },
    {
      label: SelectValuePairCategory.TemplateEditor,
      isExpandable: true,
      state: isTemplateEditor,
      setState: setIsTemplateEditor,
      actionItem: (
        <ActionItem
          iconName='payload'
          label={SelectValuePairCategory.TemplateEditor}
          onClick={() => {
            setIsTemplateEditor(true);
          }}
        />
      ),
      component: (
        <TemplateEditorSection
          initialValue={
            templateContent ||
            (selectedSource?.templateSource
              ? convertTemplateSourceToTipTapFormat(
                  selectedSource.templateSource
                )
              : '')
          }
          slashCommandItems={slashCommandItems}
          onSubmit={(content, celExpressions) => {
            setTemplateContent(content);
            const parameterSource = createTemplateParameterSource(
              content,
              celExpressions
            );
            handleClick([parameterSource]);
          }}
        />
      ),
    },
  ].filter(({ label }) => visibleValuePairCategories.includes(label));

  const activeCategory = valuePairCategories.find(
    ({ isExpandable, state }) => isExpandable && state
  );

  const selectValuePairCategoryLabel = (
    <Flexbox
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
      p={0.75}
      pb={0.5}
    >
      {isNewCustomProperty ? (
        <CategoryLabel
          label={createCustomPropertyCategory.label}
          onClick={createCustomPropertyCategory.setState}
        />
      ) : activeCategory ? (
        <CategoryLabel
          label={activeCategory.label}
          onClick={activeCategory.setState}
        />
      ) : (
        <Text tag='p2' color={theme.colors.text.muted}>
          Categories
        </Text>
      )}
    </Flexbox>
  );

  const selectedValuePairComponent = (
    <>
      {isNewCustomProperty ? (
        createCustomPropertyCategory.component
      ) : (
        <>
          {activeCategory ? (
            activeCategory.component
          ) : (
            <Flexbox
              data-testid='all-categories'
              direction='column'
              justifyContent='flex-start'
              alignItems='flex-start'
              overflow='auto'
              gap={0}
            >
              {valuePairCategories.map(({ actionItem, label }) => (
                <Fragment key={label}>{actionItem}</Fragment>
              ))}
            </Flexbox>
          )}
        </>
      )}
    </>
  );

  const filterableCategories = [
    {
      label: SelectValuePairCategory.PayloadField,
      data: filteredPayloadFields,
      component: filteredPayloadFields.map((payloadField, index) => (
        <PayloadField
          key={index}
          onClick={(source) => handleClick([source])}
          payloadField={payloadField}
        />
      )),
    },
    {
      label: SelectValuePairCategory.ObjectTypeIds,
      data: filteredObjectTypeIds,
      component: filteredObjectTypeIds.map((objectTypeId, index) => (
        <ObjectTypeId
          key={index}
          objectTypeId={objectTypeId}
          onClick={(source) => handleClick([source])}
        />
      )),
    },
    {
      label: SelectValuePairCategory.MorfProperties,
      data: filteredDefaultProperties,
      component: filteredDefaultProperties.map((property, index) => (
        <Property
          key={index}
          onClick={(source) => handleClick(source)}
          property={property}
        />
      )),
    },
    {
      label: SelectValuePairCategory.CustomProperties,
      data: filteredCustomProperties,
      component: filteredCustomProperties.map((property, index) => (
        <Property
          key={index}
          onClick={(source) => handleClick(source)}
          property={property}
        />
      )),
    },
    {
      label: SelectValuePairCategory.Identifiers,
      data: filteredIdentifiers,
      component: filteredIdentifiers.map((identifier, index) => (
        <Identifier
          key={index}
          {...identifier}
          onClick={(source) => handleClick([source])}
        />
      )),
    },
  ];

  let filteredNrOfItems = 0;

  filterableCategories.forEach(({ label, data }) => {
    if (
      (!activeCategory || activeCategory.label === label) &&
      visibleValuePairCategories.includes(label)
    ) {
      filteredNrOfItems += data.length;
    }
  });

  const filteredValuePairComponent = (
    <Flexbox
      data-testid='filtered-categories'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      overflow='auto'
      gap={0}
    >
      {filterableCategories.map(({ label, component }) => {
        if (
          (!activeCategory || activeCategory.label === label) &&
          visibleValuePairCategories.includes(label)
        ) {
          return component;
        }
      })}
    </Flexbox>
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  useEffect(() => {
    if (autofocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autofocus, inputRef.current]);

  useEffect(() => {
    if (selectedSource?.templateSource) {
      setIsTemplateEditor(true);

      if (selectedSource.templateSource.body) {
        setTemplateContent(selectedSource.templateSource.body);
      }
    }
  }, [fieldName, selectedSource]);

  return (
    <Flexbox
      data-testid='select-value-pair-modal'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      height={isTemplateEditor ? 'fit-content' : '23.75rem'}
      width='23.75rem'
      overflow='auto'
      borderRadius={1}
      gap={0}
    >
      {!isNewCustomProperty &&
        (!activeCategory ||
          filterableCategories
            .map((filterableCategory) => filterableCategory.label)
            .includes(activeCategory.label as SelectValuePairCategory)) && (
          <Container height='auto' borderType='borderBottom'>
            <Input
              ref={inputRef}
              id='search-key-values'
              borderType='none'
              placeholder='Search key values'
              leftElement={
                <Icon
                  name='search'
                  stroke={theme.colors.ui.dark}
                  size={1.25}
                  strokeWidth={1.75}
                />
              }
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              autoFocus
            />
          </Container>
        )}

      {!!searchValue ? (
        <Container height='auto' width='auto' p={0.75} pb={0.5}>
          <SearchMessage
            nrOfRows={filteredNrOfItems}
            searchValue={searchValue}
          />
        </Container>
      ) : (
        selectValuePairCategoryLabel
      )}

      {!!searchValue ? filteredValuePairComponent : selectedValuePairComponent}
    </Flexbox>
  );
};

const SelectValuePairModal: FC<SelectValuePairModalProps> = ({
  useModalPositioning = true,
  autofocus = false,
  width = '23.75rem',
  ...props
}) => {
  const theme = useTheme();

  if (useModalPositioning) {
    return (
      <WrapperModal onClose={props.onClose}>
        <SelectValuePairContent {...props} />
      </WrapperModal>
    );
  }

  return (
    <Container
      backgroundColor={theme.colors.ui.card}
      borderRadius={1}
      shadow='base'
      width={width}
    >
      <SelectValuePairContent {...props} />
    </Container>
  );
};

export const MemoizedSelectValuePairModal = memo(SelectValuePairModal);
