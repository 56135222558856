"use strict";

import * as $protobuf from "protobufjs/minimal";
import { timing as timing$1 } from "../../timing/v1/timing_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.google = (() => {
  const google = {};
  google.v1 = (() => {
    const v1 = {};
    v1.GoogleAdsUserIdentifier = (() => {
      class GoogleAdsUserIdentifier {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GoogleAdsUserIdentifier(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.source != null && Object.hasOwnProperty.call(message, "source")) {
            writer.uint32(10).string(message.source);
          }
          if (message.hashedEmail != null && Object.hasOwnProperty.call(message, "hashedEmail")) {
            writer.uint32(18).string(message.hashedEmail);
          }
          if (message.hashedPhoneNumber != null && Object.hasOwnProperty.call(message, "hashedPhoneNumber")) {
            writer.uint32(26).string(message.hashedPhoneNumber);
          }
          if (message.mobileId != null && Object.hasOwnProperty.call(message, "mobileId")) {
            writer.uint32(34).string(message.mobileId);
          }
          if (message.thirdPartyUserId != null && Object.hasOwnProperty.call(message, "thirdPartyUserId")) {
            writer.uint32(42).string(message.thirdPartyUserId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GoogleAdsUserIdentifier();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.source = reader.string();
                break;
              }
              case 2: {
                message.hashedEmail = reader.string();
                break;
              }
              case 3: {
                message.hashedPhoneNumber = reader.string();
                break;
              }
              case 4: {
                message.mobileId = reader.string();
                break;
              }
              case 5: {
                message.thirdPartyUserId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.google.v1.GoogleAdsUserIdentifier) {
            return object;
          }
          const message = new $root.google.v1.GoogleAdsUserIdentifier();
          if (object.source != null) {
            message.source = String(object.source);
          }
          if (object.hashedEmail != null) {
            message.hashedEmail = String(object.hashedEmail);
          }
          if (object.hashedPhoneNumber != null) {
            message.hashedPhoneNumber = String(object.hashedPhoneNumber);
          }
          if (object.mobileId != null) {
            message.mobileId = String(object.mobileId);
          }
          if (object.thirdPartyUserId != null) {
            message.thirdPartyUserId = String(object.thirdPartyUserId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.source != null && message.hasOwnProperty("source")) {
            object.source = message.source;
          }
          if (message.hashedEmail != null && message.hasOwnProperty("hashedEmail")) {
            object.hashedEmail = message.hashedEmail;
          }
          if (message.hashedPhoneNumber != null && message.hasOwnProperty("hashedPhoneNumber")) {
            object.hashedPhoneNumber = message.hashedPhoneNumber;
          }
          if (message.mobileId != null && message.hasOwnProperty("mobileId")) {
            object.mobileId = message.mobileId;
          }
          if (message.thirdPartyUserId != null && message.hasOwnProperty("thirdPartyUserId")) {
            object.thirdPartyUserId = message.thirdPartyUserId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "google.v1.GoogleAdsUserIdentifier";
        }
      }

      GoogleAdsUserIdentifier.prototype.source = null;
      GoogleAdsUserIdentifier.prototype.hashedEmail = null;
      GoogleAdsUserIdentifier.prototype.hashedPhoneNumber = null;
      GoogleAdsUserIdentifier.prototype.mobileId = null;
      GoogleAdsUserIdentifier.prototype.thirdPartyUserId = null;

      return GoogleAdsUserIdentifier;
    })();

    v1.GoogleAdsSendConversionEventResult = (() => {
      class GoogleAdsSendConversionEventResult {
        constructor(properties) {
          this.userIdentifiers = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GoogleAdsSendConversionEventResult(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.gclid != null && Object.hasOwnProperty.call(message, "gclid")) {
            writer.uint32(10).string(message.gclid);
          }
          if (message.gbraid != null && Object.hasOwnProperty.call(message, "gbraid")) {
            writer.uint32(18).string(message.gbraid);
          }
          if (message.wbraid != null && Object.hasOwnProperty.call(message, "wbraid")) {
            writer.uint32(26).string(message.wbraid);
          }
          if (message.userIdentifiers != null && Object.hasOwnProperty.call(message, "userIdentifiers")) {
            for (const element of message.userIdentifiers) {
              $root.google.v1.GoogleAdsUserIdentifier.encode(element, writer.uint32(34).fork()).ldelim();
            }
          }
          if (message.conversionAction != null && Object.hasOwnProperty.call(message, "conversionAction")) {
            writer.uint32(42).string(message.conversionAction);
          }
          if (message.conversionDateTime != null && Object.hasOwnProperty.call(message, "conversionDateTime")) {
            timing$1.v1.Timestamp.encode(message.conversionDateTime, writer.uint32(50).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GoogleAdsSendConversionEventResult();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.gclid = reader.string();
                break;
              }
              case 2: {
                message.gbraid = reader.string();
                break;
              }
              case 3: {
                message.wbraid = reader.string();
                break;
              }
              case 4: {
                if (!message.userIdentifiers || !message.userIdentifiers.length) {
                  message.userIdentifiers = [];
                }
                message.userIdentifiers.push($root.google.v1.GoogleAdsUserIdentifier.decode(reader, reader.uint32()));
                break;
              }
              case 5: {
                message.conversionAction = reader.string();
                break;
              }
              case 6: {
                message.conversionDateTime = timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.google.v1.GoogleAdsSendConversionEventResult) {
            return object;
          }
          const message = new $root.google.v1.GoogleAdsSendConversionEventResult();
          if (object.gclid != null) {
            message.gclid = String(object.gclid);
          }
          if (object.gbraid != null) {
            message.gbraid = String(object.gbraid);
          }
          if (object.wbraid != null) {
            message.wbraid = String(object.wbraid);
          }
          if (object.userIdentifiers) {
            if (!Array.isArray(object.userIdentifiers)) {
              throw new TypeError(".google.v1.GoogleAdsSendConversionEventResult.userIdentifiers: array type expected, but got " + (typeof object.userIdentifiers))
            }
            message.userIdentifiers = new Array(object.userIdentifiers.length);
            for (let i = 0; i < object.userIdentifiers.length; ++i) {
              if (typeof object.userIdentifiers[i] !== "object") {
                throw new TypeError(".google.v1.GoogleAdsSendConversionEventResult.userIdentifiers: object expected, but got " + (typeof object.userIdentifiers[i]));
              }
              message.userIdentifiers[i] = $root.google.v1.GoogleAdsUserIdentifier.fromObject(object.userIdentifiers[i]);
            }
          }
          if (object.conversionAction != null) {
            message.conversionAction = String(object.conversionAction);
          }
          if (object.conversionDateTime != null) {
            if (typeof object.conversionDateTime !== "object") {
              throw new TypeError(".google.v1.GoogleAdsSendConversionEventResult.conversionDateTime: object expected, but got " + (typeof object.conversionDateTime));
            }
            message.conversionDateTime = timing$1.v1.Timestamp.fromObject(object.conversionDateTime);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.userIdentifiers = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.gclid != null && message.hasOwnProperty("gclid")) {
            object.gclid = message.gclid;
          }
          if (message.gbraid != null && message.hasOwnProperty("gbraid")) {
            object.gbraid = message.gbraid;
          }
          if (message.wbraid != null && message.hasOwnProperty("wbraid")) {
            object.wbraid = message.wbraid;
          }
          if (message.userIdentifiers && message.userIdentifiers.length) {
            object.userIdentifiers = new Array(message.userIdentifiers.length);
            for (let i = 0; i < message.userIdentifiers.length; ++i) {
              object.userIdentifiers[i] = $root.google.v1.GoogleAdsUserIdentifier.toObject(message.userIdentifiers[i], options);
            }
          }
          if (message.conversionAction != null && message.hasOwnProperty("conversionAction")) {
            object.conversionAction = message.conversionAction;
          }
          if (message.conversionDateTime != null && message.hasOwnProperty("conversionDateTime")) {
            object.conversionDateTime = timing$1.v1.Timestamp.toObject(message.conversionDateTime, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "google.v1.GoogleAdsSendConversionEventResult";
        }
      }

      GoogleAdsSendConversionEventResult.prototype.gclid = null;
      GoogleAdsSendConversionEventResult.prototype.gbraid = null;
      GoogleAdsSendConversionEventResult.prototype.wbraid = null;
      GoogleAdsSendConversionEventResult.prototype.userIdentifiers = $util.emptyArray;
      GoogleAdsSendConversionEventResult.prototype.conversionAction = null;
      GoogleAdsSendConversionEventResult.prototype.conversionDateTime = null;

      return GoogleAdsSendConversionEventResult;
    })();

    v1.GoogleAdsSendConversionEventResponse = (() => {
      class GoogleAdsSendConversionEventResponse {
        constructor(properties) {
          this.results = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GoogleAdsSendConversionEventResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.jobId != null && Object.hasOwnProperty.call(message, "jobId")) {
            writer.uint32(10).string(message.jobId);
          }
          if (message.results != null && Object.hasOwnProperty.call(message, "results")) {
            for (const element of message.results) {
              $root.google.v1.GoogleAdsSendConversionEventResult.encode(element, writer.uint32(18).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GoogleAdsSendConversionEventResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.jobId = reader.string();
                break;
              }
              case 2: {
                if (!message.results || !message.results.length) {
                  message.results = [];
                }
                message.results.push($root.google.v1.GoogleAdsSendConversionEventResult.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.google.v1.GoogleAdsSendConversionEventResponse) {
            return object;
          }
          const message = new $root.google.v1.GoogleAdsSendConversionEventResponse();
          if (object.jobId != null) {
            message.jobId = String(object.jobId);
          }
          if (object.results) {
            if (!Array.isArray(object.results)) {
              throw new TypeError(".google.v1.GoogleAdsSendConversionEventResponse.results: array type expected, but got " + (typeof object.results))
            }
            message.results = new Array(object.results.length);
            for (let i = 0; i < object.results.length; ++i) {
              if (typeof object.results[i] !== "object") {
                throw new TypeError(".google.v1.GoogleAdsSendConversionEventResponse.results: object expected, but got " + (typeof object.results[i]));
              }
              message.results[i] = $root.google.v1.GoogleAdsSendConversionEventResult.fromObject(object.results[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.results = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.jobId = "";
          }
          let keys;
          if (message.jobId != null && message.hasOwnProperty("jobId")) {
            object.jobId = message.jobId;
          }
          if (message.results && message.results.length) {
            object.results = new Array(message.results.length);
            for (let i = 0; i < message.results.length; ++i) {
              object.results[i] = $root.google.v1.GoogleAdsSendConversionEventResult.toObject(message.results[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "google.v1.GoogleAdsSendConversionEventResponse";
        }
      }

      GoogleAdsSendConversionEventResponse.prototype.jobId = "";
      GoogleAdsSendConversionEventResponse.prototype.results = $util.emptyArray;

      return GoogleAdsSendConversionEventResponse;
    })();

    return v1;
  })();

  return google;
})();

export const google = $root.google;
