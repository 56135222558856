import { Transaction, PluginKey } from '@tiptap/pm/state';
import { SlashCommandItem } from './SlashCommandTypes';

/**
 * Plugin state for the slash command extension
 */
export interface SlashCommandPluginState {
  isActive: boolean;
  items: SlashCommandItem[];
  selectedIndex: number;
  slashPos: number | null;
  query: string;
}

export type SlashCommandAction =
  | { type: 'SELECT_ITEM'; index: number }
  | { type: 'CLOSE' }
  | { type: 'ACTIVATE'; position: number };

export const defaultState: SlashCommandPluginState = {
  isActive: false,
  items: [],
  selectedIndex: 0,
  slashPos: null,
  query: '',
};

export const filterItems = (
  items: SlashCommandItem[],
  query: string
): SlashCommandItem[] => {
  if (!query) return items;

  const lowerQuery = query.toLowerCase();
  return items.filter(
    (item) =>
      item.title.toLowerCase().includes(lowerQuery) ||
      (item.description &&
        item.description.toLowerCase().includes(lowerQuery)) ||
      item.parameterSource.toString().toLowerCase().includes(lowerQuery)
  );
};

export function initState(items: SlashCommandItem[]): SlashCommandPluginState {
  return {
    ...defaultState,
    items,
  };
}

export function handleMetaAction(
  state: SlashCommandPluginState,
  action: SlashCommandAction
): SlashCommandPluginState {
  switch (action.type) {
    case 'SELECT_ITEM':
      return {
        ...state,
        selectedIndex: action.index,
      };
    case 'CLOSE':
      return {
        ...state,
        isActive: false,
        slashPos: null,
        query: '',
        selectedIndex: 0,
      };
    case 'ACTIVATE':
      return {
        ...state,
        isActive: true,
        slashPos: action.position,
        query: '',
        selectedIndex: 0,
      };
    default:
      return state;
  }
}

export function handleDocChanges(
  state: SlashCommandPluginState,
  tr: Transaction,
  meta: SlashCommandAction | null
): SlashCommandPluginState {
  const { from } = tr.selection;
  const newState = { ...state };

  if (newState.isActive && newState.slashPos !== null) {
    if (from < newState.slashPos) {
      return {
        ...newState,
        isActive: false,
        slashPos: null,
        query: '',
      };
    }

    const query = tr.doc.textBetween(newState.slashPos + 1, from, '\n');
    if (query !== newState.query) {
      return {
        ...newState,
        query,
        selectedIndex: 0,
      };
    }
  }

  return newState;
}

export function reducerFn(
  tr: Transaction,
  state: SlashCommandPluginState,
  pluginKey: PluginKey
): SlashCommandPluginState {
  let newState = { ...state };

  const meta = tr.getMeta(pluginKey) as SlashCommandAction | null;
  if (meta) {
    newState = handleMetaAction(newState, meta);
  }

  if (tr.docChanged || tr.selectionSet) {
    newState = handleDocChanges(newState, tr, meta);
  }

  return newState;
}
