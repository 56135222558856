import { profileLookupSubflow } from './profileLookupSubflow';
import { profiles } from '@morf/proto/profiles/v1/profiles_ts_proto';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';

export const LeadLookupConfiguration = {
  lookupType: 1,
  lookupsSequence: [
    {
      fieldNotPresentAction: 1,
      index: new profiles.v1.LookupIndex({
        id: new profiles.v1.IdIndex({
          thirdPartyIdType: 1,
          idFieldLookup: new workflow_parameters.v1.EventPayloadFieldLookup({
            celExpressionStr: 'payload.responder_uuid',
          }),
        }),
      }),
    },
  ],
  noProfileFoundAction: 5,
  validationIssue: null,
};

export const {
  lookupSubflowNodes: LeadLookupNodes,
  lookupSubflowEdges: LeadLookupEdges,
} = profileLookupSubflow(
  LeadLookupConfiguration,
  '1605449d-2dc4-4dd2-a22f-5f137e8331ed'
);

export const PatientLookupConfiguration = {
  lookupType: 2,
  lookupsSequence: [
    {
      fieldNotPresentAction: 1,
      index: new profiles.v1.LookupIndex({
        email: new profiles.v1.UniqueIndex({
          uniqueIndexFieldLookup:
            new workflow_parameters.v1.EventPayloadFieldLookup({
              celExpressionStr: 'appointment_email_address',
            }),
          multipleProfilesAction: 1,
        }),
      }),
    },
  ],
  noProfileFoundAction: 2,
  validationIssue: null,
};

export const {
  lookupSubflowNodes: PatientLookupNodes,
  lookupSubflowEdges: PatientLookupEdges,
} = profileLookupSubflow(
  PatientLookupConfiguration,
  '1605449d-2dc4-4dd2-a22f-5f137e8331ed'
);

export const PatientAndLeadEmailLookupConfiguration = {
  lookupType: 3,
  lookupsSequence: [
    {
      fieldNotPresentAction: 1,
      index: new profiles.v1.LookupIndex({
        email: new profiles.v1.UniqueIndex({
          uniqueIndexFieldLookup:
            new workflow_parameters.v1.EventPayloadFieldLookup({
              celExpressionStr: 'appointment_email_address',
            }),
          multipleProfilesAction: 1,
        }),
      }),
    },
  ],
  noProfileFoundAction: 2,
  validationIssue: null,
};

export const {
  lookupSubflowNodes: PatientAndLeadEmailLookupNodes,
  lookupSubflowEdges: PatientAndLeadEmailLookupEdges,
} = profileLookupSubflow(
  PatientAndLeadEmailLookupConfiguration,
  '1605449d-2dc4-4dd2-a22f-5f137e8331ed'
);

export const PatientAndLeadEmailAndIdLookupConfiguration = {
  lookupType: 3,
  lookupsSequence: [
    {
      fieldNotPresentAction: 1,
      index: new profiles.v1.LookupIndex({
        email: new profiles.v1.UniqueIndex({
          uniqueIndexFieldLookup:
            new workflow_parameters.v1.EventPayloadFieldLookup({
              celExpressionStr: 'appointment_email_address',
            }),
          multipleProfilesAction: 1,
        }),
      }),
    },
    {
      fieldNotPresentAction: 1,
      index: new profiles.v1.LookupIndex({
        id: new profiles.v1.IdIndex({
          thirdPartyIdType: 1,
          idFieldLookup: new workflow_parameters.v1.EventPayloadFieldLookup({
            celExpressionStr: 'payload.responder_uuid',
          }),
        }),
      }),
    },
  ],
  noProfileFoundAction: 1,
  validationIssue: null,
};

export const {
  lookupSubflowNodes: PatientAndLeadEmailAndIdLookupNodes,
  lookupSubflowEdges: PatientAndLeadEmailAndIdLookupEdges,
} = profileLookupSubflow(
  PatientAndLeadEmailAndIdLookupConfiguration,
  '1605449d-2dc4-4dd2-a22f-5f137e8331ed'
);

export const PatientAndLeadOnlyIdLookupConfiguration = {
  lookupType: 3,
  lookupsSequence: [
    {
      fieldNotPresentAction: 2,
      index: new profiles.v1.LookupIndex({
        id: new profiles.v1.IdIndex({
          thirdPartyIdType: 11,
          idFieldLookup: new workflow_parameters.v1.EventPayloadFieldLookup({
            celExpressionStr: 'payload.ajs_anonymous_id',
          }),
        }),
      }),
    },
    {
      fieldNotPresentAction: 1,
      index: new profiles.v1.LookupIndex({
        id: new profiles.v1.IdIndex({
          thirdPartyIdType: 1,
          idFieldLookup: new workflow_parameters.v1.EventPayloadFieldLookup({
            celExpressionStr: 'payload.responder_uuid',
          }),
        }),
      }),
    },
  ],
  noProfileFoundAction: 1,
  validationIssue: null,
};

export const {
  lookupSubflowNodes: PatientAndLeadOnlyIdLookupNodes,
  lookupSubflowEdges: PatientAndLeadOnlyIdLookupEdges,
} = profileLookupSubflow(
  PatientAndLeadOnlyIdLookupConfiguration,
  '1605449d-2dc4-4dd2-a22f-5f137e8331ed'
);
