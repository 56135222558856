"use strict";

import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.mixpanel = (() => {
  const mixpanel = {};
  mixpanel.v1 = (() => {
    const v1 = {};
    v1.TrackEventResult = (() => {
      class TrackEventResult {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new TrackEventResult(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.eventId != null && Object.hasOwnProperty.call(message, "eventId")) {
            writer.uint32(10).string(message.eventId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new TrackEventResult();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.mixpanel.v1.TrackEventResult) {
            return object;
          }
          const message = new $root.mixpanel.v1.TrackEventResult();
          if (object.eventId != null) {
            message.eventId = String(object.eventId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventId = "";
          }
          let keys;
          if (message.eventId != null && message.hasOwnProperty("eventId")) {
            object.eventId = message.eventId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "mixpanel.v1.TrackEventResult";
        }
      }

      TrackEventResult.prototype.eventId = "";

      return TrackEventResult;
    })();

    return v1;
  })();

  return mixpanel;
})();

export const mixpanel = $root.mixpanel;
