import { Button } from '../../../../../../Button';
import { CalculatedValueProps } from './types';
import { FC, memo, useState } from 'react';
import { Flexbox } from '../../../../../../Flexbox';
import { Text } from '../../../../../../Typography';
import { Textarea } from '../../../../../../Textarea';
import { ValidationIssueIndicator } from '../../../../../../ValidationIssueIndicator';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';
const CalculatedValue: FC<CalculatedValueProps> = ({
  selectedSource,
  onClick,
}) => {
  const defaultCelExpressionStr =
    selectedSource?.calculatedValue?.celExpressionStr;

  const validationIssue = selectedSource?.validationIssue;

  const [updatedCelExpressionStr, setUpdatedCelExpressionStr] = useState(
    defaultCelExpressionStr
  );

  const isDisabled =
    !updatedCelExpressionStr ||
    updatedCelExpressionStr === defaultCelExpressionStr;

  const handleClick = async () => {
    onClick(
      new workflow_parameters.v1.DestinationActionParameterSource({
        calculatedValue: new workflow_parameters.v1.CalculatedValue({
          celExpressionStr: updatedCelExpressionStr,
        }),
      })
    );
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUpdatedCelExpressionStr(e.target.value);
  };

  return (
    <Flexbox
      data-testid='calculated-value'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      p={0.75}
      gap={0.5}
    >
      <Textarea
        name='cel-expression-text-area'
        placeholder='Custom CEL expression'
        value={updatedCelExpressionStr}
        onChange={handleTextChange}
        error={!!validationIssue}
        style={{ flexGrow: 1 }}
      />

      {validationIssue && (
        <ValidationIssueIndicator validationIssue={validationIssue} />
      )}

      <Button
        variant='primary'
        onClick={handleClick}
        isDisabled={isDisabled}
        width='100%'
      >
        <Text tag='h5' color='inherit'>
          Apply
        </Text>
      </Button>
    </Flexbox>
  );
};

export const MemoizedCalculatedValue = memo(CalculatedValue);
