import { CustomNodeLabel } from '../CustomNodeLabel';
import { FC, memo } from 'react';
import { Handle, Position } from 'reactflow';
import { CustomNode } from '../types';

const FetchActionNode: FC<CustomNode> = (props) => {
  return (
    <>
      <Handle type='target' position={Position.Top} />
      <CustomNodeLabel {...props} />
      <Handle type='source' position={Position.Bottom} />
    </>
  );
};

export const MemoizedFetchActionNode = memo(FetchActionNode);
