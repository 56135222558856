import { CustomNode } from '../../types';
import { Edge } from 'reactflow';
import { ProfileLookupNodeType } from '../types';
import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';

export const profileLookupSubflowProfileFound = (
  index: number
): {
  nodes: CustomNode[];
  edges: Edge[];
} => {
  let nodes: CustomNode[] = [];
  let edges: Edge[] = [];

  nodes.push({
    id: `lookup-subflow-profile-found-${index}`,
    type: ProfileLookupNodeType.BranchNode,
    data: {
      value: {
        name: 'profile found',
        title: 'Profile found',
        configuration: new workflows.v1.WorkflowNode({}),
      },
    },
    parentNode: `lookup-subflow-field-lookup-${index}`,
    position: { x: -250, y: 100 },
  });

  edges.push({
    id: `lookup-subflow-field-lookup-${index}-profile-found-${index}`,
    source: `lookup-subflow-field-lookup-${index}`,
    target: `lookup-subflow-profile-found-${index}`,
  });

  nodes
    .filter((node) => node.id.startsWith('lookup-subflow-profile-found'))
    .forEach((profileFoundNode) => {
      edges.push({
        id: `lookup-subflow-${profileFoundNode.id}-to-lookup-subflow-return-to-workflow`,
        source: profileFoundNode.id,
        target: 'lookup-subflow-return-to-workflow',
      });
    });

  return { nodes, edges };
};
