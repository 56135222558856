import { SpotlightSearchProps } from '@morf/ui/SpotlightSearch/types';
import { convertToKebabCase } from '@morf/ui';
import { getDefaultProfilePath } from '../../../components/helpers/getDefaultProfilePath';
import { profiles } from '@morf/proto/profiles/v1/profiles_ts_proto';
import { thirdparties } from '@morf/proto/thirdparties/v1/thirdparties_ts_proto';
import { timestampToString } from '../../helpers';

export const formatSpotlightSearchRows = (
  profiles: profiles.v1.IndexSearchResponse.ProfileHit[] | undefined,
  organizationId: string,
  isLoading: boolean
): SpotlightSearchProps['rows'] => {
  if (isLoading)
    return Array(4).fill({
      email: '',
      integrationIconNames: [],
      isLoading: true,
      link: '',
      matchString: <></>,
      matchType: '',
      name: '',
      type: 'anonymous',
      updatedAt: { isoDateString: '', timezone: '' },
    });

  if (!profiles) {
    return [];
  }
  return profiles.map((p) => {
    const { profile: profile, highlight, discoveredProfileIndex } = p;
    const { patient, lead, anonymous, thirdPartyIds, type, timing } =
      profile || {};

    const email = patient?.emailAddress || lead?.emailAddress || 'n/a';

    const integrationIconNames = Object.keys(thirdPartyIds || {}).map(
      convertToKebabCase
    );

    const thirdPartyId = profile?.thirdPartyIds?.customer?.id || '';
    const link = getDefaultProfilePath(
      organizationId,
      'customer',
      thirdPartyId
    );

    const matchString = <div dangerouslySetInnerHTML={{ __html: highlight }} />;

    const matchType = discoveredProfileIndex
      ? convertToKebabCase(
          thirdparties.v1.ThirdPartyIdType[discoveredProfileIndex.type]
        )
      : 'profile';

    const name =
      patient?.fullName || lead?.fullName || anonymous?.fullName || 'n/a';

    const updatedAt = timestampToString(timing?.updatedAt);

    return {
      email,
      integrationIconNames,
      isLoading: false,
      link,
      matchString,
      matchType,
      name,
      type: type || 'anonymous',
      updatedAt,
    };
  });
};
