import { Container } from '../../../../../../../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../../../../../../../Flexbox';
import { Icon } from '../../../../../../../Icon';
import { ObjectTypeIdProps } from './types';
import { Text, TooltipText } from '../../../../../../../Typography';
import { useTheme } from '@morf/theming';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';

const ObjectTypeId: FC<ObjectTypeIdProps> = ({ objectTypeId, onClick }) => {
  const theme = useTheme();
  const { description, index, id, isSelected, label } = objectTypeId;

  const tooltipDirection = index < 3 ? 'bottom' : 'top';

  const handleClick = () => {
    onClick(
      new workflow_parameters.v1.DestinationActionParameterSource({
        objectTypeId: new workflow_parameters.v1.ObjectTypeID({
          id: objectTypeId.id,
          label: objectTypeId.label,
          description: objectTypeId.description,
        }),
      })
    );
  };

  return (
    <Flexbox
      key={id}
      data-testid='object-type-id'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      height='auto'
      cursor='pointer'
      backgroundColor={isSelected ? theme.colors.ui.body : theme.colors.ui.card}
      hoverBackgroundColor={theme.colors.ui.body}
      onClick={handleClick}
      px={0.75}
      py={0.5}
      gap={0.5}
    >
      <Container width='auto' height='auto'>
        <Icon name='curly-brackets' stroke={theme.colors.ui.dark} size={1} />
      </Container>
      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        maxWidth='20.5rem'
        gap={0}
      >
        <Text tag='p2'>{label || id}</Text>
        <TooltipText
          tag='p3'
          color={theme.colors.text.muted}
          whiteSpace='nowrap'
          tooltipWidth='19.5rem'
          tooltipDirection={tooltipDirection}
        >
          {description}
        </TooltipText>
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedObjectTypeId = memo(ObjectTypeId);
