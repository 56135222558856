import { profile_properties } from '@morf/proto/profile_properties/v1/profile_properties_ts_proto';

export const formatUsAddress = (
  usAddress: profile_properties.v1.UsAddress | null | undefined
) => {
  if (
    !usAddress ||
    (!usAddress.address1 &&
      !usAddress.address2 &&
      !usAddress.city &&
      !usAddress.state &&
      !usAddress.zipCode)
  ) {
    return 'n/a';
  }

  return [
    usAddress.address1,
    usAddress.address2,
    usAddress.city,
    usAddress.state,
    usAddress.zipCode,
  ]
    .filter(Boolean)
    .join(', ');
};
