"use strict";

import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.nextdoor = (() => {
  const nextdoor = {};
  nextdoor.v1 = (() => {
    const v1 = {};
    v1.SendConversionEventResponse = (() => {
      class SendConversionEventResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SendConversionEventResponse(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.traceId != null && Object.hasOwnProperty.call(message, "traceId")) {
            writer.uint32(10).string(message.traceId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SendConversionEventResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.traceId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.nextdoor.v1.SendConversionEventResponse) {
            return object;
          }
          const message = new $root.nextdoor.v1.SendConversionEventResponse();
          if (object.traceId != null) {
            message.traceId = String(object.traceId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.traceId = "";
          }
          let keys;
          if (message.traceId != null && message.hasOwnProperty("traceId")) {
            object.traceId = message.traceId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "nextdoor.v1.SendConversionEventResponse";
        }
      }

      SendConversionEventResponse.prototype.traceId = "";

      return SendConversionEventResponse;
    })();

    return v1;
  })();

  return nextdoor;
})();

export const nextdoor = $root.nextdoor;
