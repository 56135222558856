import { useEffect, useState, forwardRef } from 'react';
import { useEditor, EditorContent, Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import CodeBlock from '@tiptap/extension-code-block';
import { useTheme } from '@morf/theming';
import {
  EditorContainer,
  LineNumbers,
  EditorWrapper,
} from './TemplateEditor.css';
import CelExpression, {
  CelExpressionAttributes,
  getAllCelExpressions,
} from './extensions/CelExpression/CelExpression';
import SlashCommandExtension from './extensions/SlashCommand/SlashCommandExtension';
import type { SlashCommandItem } from './extensions/SlashCommand/SlashCommandTypes';
import { SlashCommandProvider } from './extensions/SlashCommand/SlashCommandProvider';
import { createTemplateSource } from './extensions/CelExpression/templateUtils';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';

export interface TemplateEditorProps {
  initialValue?: string;
  onChange?: (value: { html: string; text: string }) => void;
  height?: string;
  width?: string;
  placeholder?: string;
  readOnly?: boolean;
  onCelExpressionsChange?: (expressions: CelExpressionAttributes[]) => void;
  slashCommandItems?: SlashCommandItem[];
  showLineNumbers?: boolean;
  minHeight?: string;
  autoFocus?: boolean;
}

export interface TemplateEditorRef {
  getAllCelExpressions: () => CelExpressionAttributes[];
  getHTML: () => string;
  getText: () => string;
  getTemplateSource: () => workflow_parameters.v1.TemplateSource;
  editor: Editor | null;
}

export const TemplateEditor = forwardRef<
  TemplateEditorRef,
  TemplateEditorProps
>(
  (
    {
      initialValue = '',
      onChange,
      height = '100%',
      width = '100%',
      readOnly = false,
      onCelExpressionsChange,
      slashCommandItems,
      showLineNumbers = false,
      minHeight = 'auto',
      autoFocus = false,
    },
    ref
  ) => {
    const theme = useTheme();
    const [lineCount, setLineCount] = useState(0);
    const [content, setContent] = useState<{ html: string; text: string }>({
      html: initialValue,
      text: '',
    });

    const editor = useEditor({
      extensions: [
        StarterKit.configure({
          codeBlock: false,
        }),
        CodeBlock,
        CelExpression,
        SlashCommandExtension.configure({
          items: slashCommandItems || [],
        }),
      ],
      content: initialValue,
      editable: !readOnly,
      onUpdate: ({ editor }) => {
        const html = editor.getHTML();
        const text = editor.getText();

        setContent({ html, text });

        if (onChange) {
          onChange({ html, text });
        }

        const lines = text.split('\n');
        setLineCount(lines.length);

        if (onCelExpressionsChange) {
          const expressions = getAllCelExpressions(editor);
          onCelExpressionsChange(expressions);
        }
      },
      onCreate: ({ editor }) => {
        const text = editor.getText();
        setContent({ html: initialValue, text });

        const lines = text.split('\n');
        setLineCount(lines.length);

        if (onCelExpressionsChange) {
          const expressions = getAllCelExpressions(editor);
          onCelExpressionsChange(expressions);
        }
      },
    });

    useEffect(() => {
      if (editor && initialValue !== editor.getHTML()) {
        editor.commands.setContent(initialValue);
        const lines = editor.getText().split('\n').length;
        setLineCount(lines || 1);
      }
    }, [initialValue, editor, onCelExpressionsChange]);

    // Expose methods via ref
    useEffect(() => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref({
          getAllCelExpressions: () => {
            if (!editor) return [];
            return getAllCelExpressions(editor);
          },
          getHTML: () => {
            if (!editor) return '';
            return editor.getHTML();
          },
          getText: () => {
            if (!editor) return '';
            return editor.getText();
          },
          getTemplateSource: () => {
            if (!editor) {
              return new workflow_parameters.v1.TemplateSource({
                body: '',
                inputs: {},
              });
            }
            const html = editor.getHTML();
            const expressions = getAllCelExpressions(editor);
            return createTemplateSource(html, expressions);
          },
          editor: editor,
        });
      } else {
        ref.current = {
          getAllCelExpressions: () => {
            if (!editor) return [];
            return getAllCelExpressions(editor);
          },
          getHTML: () => {
            if (!editor) return '';
            return editor.getHTML();
          },
          getText: () => {
            if (!editor) return '';
            return editor.getText();
          },
          getTemplateSource: () => {
            if (!editor) {
              return new workflow_parameters.v1.TemplateSource({
                body: '',
                inputs: {},
              });
            }
            const html = editor.getHTML();
            const expressions = getAllCelExpressions(editor);
            return createTemplateSource(html, expressions);
          },
          editor: editor,
        };
      }
    }, [editor, ref]);

    useEffect(() => {
      if (editor && autoFocus && !readOnly) {
        setTimeout(() => {
          editor.commands.focus();
        }, 0);
      }
    }, [editor, autoFocus, readOnly]);

    return (
      <EditorContainer
        height={height}
        width={width}
        minHeight={minHeight}
        theme={theme}
      >
        {showLineNumbers && (
          <LineNumbers data-testid='line-numbers' theme={theme}>
            {Array.from({ length: lineCount }).map((_, i) => (
              <div key={i}>{i + 1}</div>
            ))}
          </LineNumbers>
        )}
        <EditorWrapper theme={theme} readOnly={readOnly}>
          <SlashCommandProvider editor={editor}>
            <EditorContent editor={editor} />
          </SlashCommandProvider>
        </EditorWrapper>
      </EditorContainer>
    );
  }
);

export default TemplateEditor;
