import { NodeType } from '../../../types';
import { defaultParameterMissingPolicy } from './defaultParameterMissingPolicy';
import { defaultRetryPolicy } from './defaultRetryPolicy';
import { destinationactions } from '@morf/proto/destinationactions/morf/v1/morf_ts_proto';
import { newNodeData } from './types';
import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';

export const defaultTrackProfilePropertiesDestinationAction =
  new workflows.v1.WorkflowDestinationAction({
    retryPolicy: defaultRetryPolicy,
    parameterMissingPolicy: defaultParameterMissingPolicy,
    morfV1: new destinationactions.morf.v1.DestinationAction({
      trackProfileProperties:
        new destinationactions.morf.v1.TrackProfileProperties({}),
    }),
  });

export const addTrackProfileProperties = (isBranch: boolean): newNodeData => {
  return {
    configuration: new workflows.v1.WorkflowNode({
      destinationAction: defaultTrackProfilePropertiesDestinationAction,
    }),
    integrationIconName: 'morf',
    isBranchNode: isBranch,
    name: 'Track Profile Properties',
    type: NodeType.TrackProfilePropertiesNode,
  };
};
