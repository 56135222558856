import { hasField } from '../../../../../../Helpers/hasField';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';

export const formatConstantValue = (
  source: workflow_parameters.v1.DestinationActionParameterSource | null
): string | undefined => {
  if (source && source.constantValue) {
    const v = source.constantValue;

    if (hasField(v, 'boolValue')) {
      return v.boolValue ? 'true' : 'false';
    }

    if (hasField(v, 'doubleValue')) {
      return `${v.doubleValue}`;
    }

    if (hasField(v, 'int64Value')) {
      return `${v.int64Value}`;
    }

    if (hasField(v, 'stringValue')) {
      return `${v.stringValue}`;
    }

    if (hasField(v, 'uint64Value')) {
      return `${v.uint64Value}`;
    }

    return 'not supported';
  }
};
