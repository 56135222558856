import { useEffect } from 'react';

const FontPreload = () => {
  useEffect(() => {
    const fontLinks = [
      '/fonts/apercu-bold-pro.woff2',
      '/fonts/apercu-medium-pro.woff2',
      '/fonts/apercu-mono-regular-pro.woff2',
      '/fonts/apercu-regular-pro.woff2',
    ];

    const preloadFonts = () => {
      fontLinks.forEach((link) => {
        const preloadLink = document.createElement('link');
        preloadLink.href = link;
        preloadLink.rel = 'preload';
        preloadLink.as = 'font';
        preloadLink.type = 'font/woff2';
        preloadLink.crossOrigin = 'anonymous';
        document.head.appendChild(preloadLink);
      });
    };

    preloadFonts();
  }, []);

  return null;
};

export default FontPreload;
