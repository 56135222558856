import { ThemeProps, createGlobalStyle } from 'styled-components';
import { ThemeContainerProps } from './types';

export const GlobalStyle = createGlobalStyle<ThemeProps<ThemeContainerProps>>`
  body, p {
    margin: 0;
  }

  svg {
    width: 100%;
    height: 100%;
  }
  
  mark {
    background-color:  ${(props) => props.theme.colors.main.light.normal};
  }
  
  #admin {
    background-color: ${(props) => props.theme.colors.ui.body};
    min-height: 100vh;
  }

  #questions {
    background-color: ${(props) => props.theme.colors.main.light.light};
  }

  a { 
    text-decoration: none; 
    width: 100%;
  }

  [role="separator"] {
    background-color: ${(props) => props.theme.colors.ui.divider};
    width: 0.0875rem;
  }

  /* Scrollbar */

  * {
    scrollbar-width: thin;
    scrollbar-color: ${(props) => props.theme.colors.ui.muted} transparent;
  }

  *::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  *::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.ui.muted};
    border-radius: 4px;
  }

  *::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 4px;
  }
 
  /* Scrollbar */

  :root {
    --modal-z-index: 8;
    --tooltip-z-index: 7;
    --menu-z-index: 6;
    --dropdown-z-index: 5;
    --navigation-top-bar-z-index: 4;
    --layout-child-z-index: 0;
    --table-header-cell-index: 3;
    --table-cell-pinned-index: 4;
    --table-header-cell-pinned-index: 5;

    --separator-border: ${(props) => props.theme.colors.ui.divider};
    --focus-border: ${(props) => props.theme.colors.ui.divider};
    --sash-size: 8px;
    --sash-hover-size: 4px;
  }

  // Hide recaptcha badge
  .grecaptcha-badge {
    display: none;
  }
`;
