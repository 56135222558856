import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';
import { NodeType } from '../../../types';
import { newNodeData } from './types';

export const addRestartNode = (isBranch: boolean): newNodeData => {
  return {
    configuration: new workflows.v1.WorkflowNode({
      restart: new workflows.v1.WorkflowRestart({}),
    }),
    isBranchNode: isBranch,
    name: 'Restart Workflow',
    type: NodeType.RestartNode,
  };
};
