export const Feathery = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.0949 3.17719C29.3257 3.68345 29.6552 4.52722 29.886 5.67473C30.3144 7.76727 29.4904 10.6698 28.1722 12.1548C27.1215 13.3412 25.454 14.2795 23.998 15.0988C23.6731 15.2817 23.3585 15.4586 23.0638 15.6312C22.0422 16.2387 21.0536 16.8123 20.065 17.386C19.4059 17.7741 18.7547 18.1624 18.1038 18.5505C17.4529 18.9387 16.802 19.3269 16.1429 19.715C16.0401 19.7743 15.937 19.8337 15.8335 19.8932C14.643 20.5782 13.4167 21.2836 12.3858 22.2463C11.7266 22.8875 11.2652 23.5963 11.0016 24.5075C10.9357 24.7438 10.5731 24.8113 10.4413 24.575C10.2745 24.3188 10.1312 22.5259 10.0706 21.7684C10.0594 21.6278 10.051 21.5228 10.0458 21.47C9.914 19.3437 10.0129 17.1499 11.0675 15.2937C11.7266 14.1124 12.7153 13.2011 13.8029 12.4586C14.6186 11.9017 15.4899 11.4587 16.3612 11.0158C16.6516 10.8681 16.9421 10.7204 17.2304 10.5686C25.8322 6.11349 27.8426 4.45971 28.5347 3.17719C28.6665 2.94094 28.9961 2.94094 29.0949 3.17719ZM27.207 16.9451C27.5391 16.643 27.7716 16.8109 28.0041 17.18C28.7015 18.3883 28.934 20.3013 28.8676 21.711C28.7127 25.1218 25.9595 26.432 23.219 27.7361C23.0203 27.8308 22.8216 27.9253 22.624 28.0206L22.5984 28.0331C20.8017 28.9073 17.7731 30.3808 17.3435 32.6522C17.0446 34.1289 17.5427 35.3707 18.2401 36.4782C18.4062 36.7468 18.1737 37.0824 17.8748 36.9817C15.1183 36.1426 13.2253 34.0954 12.4615 31.6788C10.7253 26.29 14.8354 24.1476 19.6313 21.6477C19.9728 21.4697 20.3179 21.2898 20.6645 21.1068C23.886 19.3951 26.0115 18.0527 27.207 16.9451Z'
        fill='black'
      />
    </svg>
  );
};
