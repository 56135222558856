import { AddOptionProps } from './types';
import { Button } from '../../Button';
import { Container } from '../../Container';
import { FC } from 'react';
import { Flexbox } from '../../Flexbox';
import { Icon } from '../../Icon';
import { Text } from '../../Typography';

export const AddOption: FC<AddOptionProps> = ({
  value,
  onClick,
  isDisabled,
}) => {
  const handleClick = () => {
    const newOption = {
      label: value,
      value: value,
    };

    onClick(newOption);
  };

  return (
    <Container borderType='borderTop' pt={0.5}>
      <Button
        variant='tertiary'
        onClick={handleClick}
        isDisabled={isDisabled}
        size='xs'
        width='100%'
      >
        <Flexbox
          direction='row'
          gap={0.5}
          justifyContent='center'
          alignItems='center'
        >
          <Icon name='plus' size={1.25} />
          <Text color='inherit' tag='h5'>
            {`Add ${value}`}
          </Text>
        </Flexbox>
      </Button>
    </Container>
  );
};
