"use strict";

import * as $protobuf from "protobufjs/minimal";
import { google as google$1 } from "../../empty_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.validation = (() => {
  const validation = {};
  validation.v1 = (() => {
    const v1 = {};
    v1.Validation = (() => {
      class Validation {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Validation(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.kind != null && Object.hasOwnProperty.call(message, "kind")) {
            writer.uint32(8).int32(message.kind);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Validation();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.kind = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.validation.v1.Validation) {
            return object;
          }
          const message = new $root.validation.v1.Validation();
          if (object.kind != null) {
            switch (object.kind) {
              case "ONE_OR_MORE":
              case 0: {
                message.kind = 0;
                break;
              }
              case "NON_EMPTY":
              case 1: {
                message.kind = 1;
                break;
              }
              case "UUID_V4":
              case 2: {
                message.kind = 2;
                break;
              }
              case "EMAIL":
              case 3: {
                message.kind = 3;
                break;
              }
              case "URL":
              case 4: {
                message.kind = 4;
                break;
              }
              case "DNS_NAME":
              case 5: {
                message.kind = 5;
                break;
              }
              case "SSN":
              case 6: {
                message.kind = 6;
                break;
              }
              case "ONE_OFS_OPTIONAL":
              case 7: {
                message.kind = 7;
                break;
              }
              case "PROPERTY_NAME":
              case 8: {
                message.kind = 8;
                break;
              }
              case "UNIQUE_ELEMENTS":
              case 9: {
                message.kind = 9;
                break;
              }
              case "RETURNED_FROM_API_ONLY":
              case 10: {
                message.kind = 10;
                break;
              }
              case "BYPASS":
              case 11: {
                message.kind = 11;
                break;
              }
              case "RFC3339_TIMESTAMP":
              case 12: {
                message.kind = 12;
                break;
              }
              case "TWO_CHAR_STATE_ABBREVIATION":
              case 13: {
                message.kind = 13;
                break;
              }
              case "ISO_TIMEZONE_LOCALE":
              case 14: {
                message.kind = 14;
                break;
              }
              default: {
                if (typeof object.kind == "number") {
                  message.kind = object.kind;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.kind = options.enums === String ? "ONE_OR_MORE" : 0
          }
          let keys;
          if (message.kind != null && message.hasOwnProperty("kind")) {
            object.kind = options.enums === String ? $root.validation.v1.Validation.Value[message.kind] === undefined ? message.kind : $root.validation.v1.Validation.Value[message.kind] : message.kind;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "validation.v1.Validation";
        }
      }

      Validation.prototype.kind = 0;
      Validation.Value = (function() {
        const valuesById = {};
        const values = Object.create(valuesById);
        values[valuesById[0] = "ONE_OR_MORE"] = 0;
        values[valuesById[1] = "NON_EMPTY"] = 1;
        values[valuesById[2] = "UUID_V4"] = 2;
        values[valuesById[3] = "EMAIL"] = 3;
        values[valuesById[4] = "URL"] = 4;
        values[valuesById[5] = "DNS_NAME"] = 5;
        values[valuesById[6] = "SSN"] = 6;
        values[valuesById[7] = "ONE_OFS_OPTIONAL"] = 7;
        values[valuesById[8] = "PROPERTY_NAME"] = 8;
        values[valuesById[9] = "UNIQUE_ELEMENTS"] = 9;
        values[valuesById[10] = "RETURNED_FROM_API_ONLY"] = 10;
        values[valuesById[11] = "BYPASS"] = 11;
        values[valuesById[12] = "RFC3339_TIMESTAMP"] = 12;
        values[valuesById[13] = "TWO_CHAR_STATE_ABBREVIATION"] = 13;
        values[valuesById[14] = "ISO_TIMEZONE_LOCALE"] = 14;
        return values;
      })();

      return Validation;
    })();

    v1.CELCompilationErrorMessage = (() => {
      class CELCompilationErrorMessage {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CELCompilationErrorMessage(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.message != null && Object.hasOwnProperty.call(message, "message")) {
            writer.uint32(10).string(message.message);
          }
          if (message.location != null && Object.hasOwnProperty.call(message, "location")) {
            $root.validation.v1.CELCompilationErrorMessage.Location.encode(message.location, writer.uint32(18).fork()).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CELCompilationErrorMessage();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.message = reader.string();
                break;
              }
              case 2: {
                message.location = $root.validation.v1.CELCompilationErrorMessage.Location.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.validation.v1.CELCompilationErrorMessage) {
            return object;
          }
          const message = new $root.validation.v1.CELCompilationErrorMessage();
          if (object.message != null) {
            message.message = String(object.message);
          }
          if (object.location != null) {
            if (typeof object.location !== "object") {
              throw new TypeError(".validation.v1.CELCompilationErrorMessage.location: object expected, but got " + (typeof object.location));
            }
            message.location = $root.validation.v1.CELCompilationErrorMessage.Location.fromObject(object.location);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.message = "";
          }
          let keys;
          if (message.message != null && message.hasOwnProperty("message")) {
            object.message = message.message;
          }
          if (message.location != null && message.hasOwnProperty("location")) {
            object.location = $root.validation.v1.CELCompilationErrorMessage.Location.toObject(message.location, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "validation.v1.CELCompilationErrorMessage";
        }
      }

      CELCompilationErrorMessage.prototype.message = "";
      CELCompilationErrorMessage.prototype.location = null;
      CELCompilationErrorMessage.Location = (() => {
        class Location {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Location(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.line != null && Object.hasOwnProperty.call(message, "line")) {
              writer.uint32(8).int32(message.line);
            }
            if (message.column != null && Object.hasOwnProperty.call(message, "column")) {
              writer.uint32(16).int32(message.column);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Location();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.line = reader.int32();
                  break;
                }
                case 2: {
                  message.column = reader.int32();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.validation.v1.CELCompilationErrorMessage.Location) {
              return object;
            }
            const message = new $root.validation.v1.CELCompilationErrorMessage.Location();
            if (object.line != null) {
              message.line = object.line | 0;
            }
            if (object.column != null) {
              message.column = object.column | 0;
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.line = 0;
              object.column = 0;
            }
            let keys;
            if (message.line != null && message.hasOwnProperty("line")) {
              object.line = message.line;
            }
            if (message.column != null && message.hasOwnProperty("column")) {
              object.column = message.column;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "validation.v1.CELCompilationErrorMessage.Location";
          }
        }

        Location.prototype.line = 0;
        Location.prototype.column = 0;

        return Location;
      })();

      return CELCompilationErrorMessage;
    })();

    v1.CELCompilationErrorMessages = (() => {
      class CELCompilationErrorMessages {
        constructor(properties) {
          this.errors = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CELCompilationErrorMessages(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.errors != null && Object.hasOwnProperty.call(message, "errors")) {
            for (const element of message.errors) {
              $root.validation.v1.CELCompilationErrorMessage.encode(element, writer.uint32(10).fork()).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CELCompilationErrorMessages();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.errors || !message.errors.length) {
                  message.errors = [];
                }
                message.errors.push($root.validation.v1.CELCompilationErrorMessage.decode(reader, reader.uint32()));
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.validation.v1.CELCompilationErrorMessages) {
            return object;
          }
          const message = new $root.validation.v1.CELCompilationErrorMessages();
          if (object.errors) {
            if (!Array.isArray(object.errors)) {
              throw new TypeError(".validation.v1.CELCompilationErrorMessages.errors: array type expected, but got " + (typeof object.errors))
            }
            message.errors = new Array(object.errors.length);
            for (let i = 0; i < object.errors.length; ++i) {
              if (typeof object.errors[i] !== "object") {
                throw new TypeError(".validation.v1.CELCompilationErrorMessages.errors: object expected, but got " + (typeof object.errors[i]));
              }
              message.errors[i] = $root.validation.v1.CELCompilationErrorMessage.fromObject(object.errors[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.errors = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.errors && message.errors.length) {
            object.errors = new Array(message.errors.length);
            for (let i = 0; i < message.errors.length; ++i) {
              object.errors[i] = $root.validation.v1.CELCompilationErrorMessage.toObject(message.errors[i], options);
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "validation.v1.CELCompilationErrorMessages";
        }
      }

      CELCompilationErrorMessages.prototype.errors = $util.emptyArray;

      return CELCompilationErrorMessages;
    })();

    v1.CELCompilationErrors = (() => {
      class CELCompilationErrors {
        get type() {
          for (const key of ["compilationErrorMessages", "typeError"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["compilationErrorMessages", "typeError"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CELCompilationErrors(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.compilationErrorMessages != null && Object.hasOwnProperty.call(message, "compilationErrorMessages")) {
            $root.validation.v1.CELCompilationErrorMessages.encode(message.compilationErrorMessages, writer.uint32(10).fork()).ldelim();
          }
          if (message.typeError != null && Object.hasOwnProperty.call(message, "typeError")) {
            writer.uint32(18).string(message.typeError);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CELCompilationErrors();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.compilationErrorMessages = $root.validation.v1.CELCompilationErrorMessages.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.typeError = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.validation.v1.CELCompilationErrors) {
            return object;
          }
          const message = new $root.validation.v1.CELCompilationErrors();
          if (object.compilationErrorMessages != null) {
            if (typeof object.compilationErrorMessages !== "object") {
              throw new TypeError(".validation.v1.CELCompilationErrors.compilationErrorMessages: object expected, but got " + (typeof object.compilationErrorMessages));
            }
            message.compilationErrorMessages = $root.validation.v1.CELCompilationErrorMessages.fromObject(object.compilationErrorMessages);
          }
          if (object.typeError != null) {
            message.typeError = String(object.typeError);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.compilationErrorMessages != null && message.hasOwnProperty("compilationErrorMessages")) {
            object.compilationErrorMessages = $root.validation.v1.CELCompilationErrorMessages.toObject(message.compilationErrorMessages, options);
            if (options.oneofs) {
              object.type = "compilationErrorMessages";
            }
          }
          if (message.typeError != null && message.hasOwnProperty("typeError")) {
            object.typeError = message.typeError;
            if (options.oneofs) {
              object.type = "typeError";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "validation.v1.CELCompilationErrors";
        }
      }

      CELCompilationErrors.prototype.compilationErrorMessages = null;
      CELCompilationErrors.prototype.typeError = "";

      return CELCompilationErrors;
    })();

    v1.WorkflowValidationIssue = (() => {
      class WorkflowValidationIssue {
        get type() {
          for (const key of ["summaryTotalIssuesCount", "celCompilationErrors", "parameterMissingIsRequired", "issueDescription", "duplicatedResultObjectKey", "contactMorfSupport", "constantValueTypeAssertionError"]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ["summaryTotalIssuesCount", "celCompilationErrors", "parameterMissingIsRequired", "issueDescription", "duplicatedResultObjectKey", "contactMorfSupport", "constantValueTypeAssertionError"]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowValidationIssue(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.summaryTotalIssuesCount != null && Object.hasOwnProperty.call(message, "summaryTotalIssuesCount")) {
            writer.uint32(8).uint32(message.summaryTotalIssuesCount);
          }
          if (message.celCompilationErrors != null && Object.hasOwnProperty.call(message, "celCompilationErrors")) {
            $root.validation.v1.CELCompilationErrors.encode(message.celCompilationErrors, writer.uint32(18).fork()).ldelim();
          }
          if (message.parameterMissingIsRequired != null && Object.hasOwnProperty.call(message, "parameterMissingIsRequired")) {
            writer.uint32(26).string(message.parameterMissingIsRequired);
          }
          if (message.issueDescription != null && Object.hasOwnProperty.call(message, "issueDescription")) {
            writer.uint32(34).string(message.issueDescription);
          }
          if (message.duplicatedResultObjectKey != null && Object.hasOwnProperty.call(message, "duplicatedResultObjectKey")) {
            google$1.protobuf.Empty.encode(message.duplicatedResultObjectKey, writer.uint32(42).fork()).ldelim();
          }
          if (message.contactMorfSupport != null && Object.hasOwnProperty.call(message, "contactMorfSupport")) {
            google$1.protobuf.Empty.encode(message.contactMorfSupport, writer.uint32(50).fork()).ldelim();
          }
          if (message.constantValueTypeAssertionError != null && Object.hasOwnProperty.call(message, "constantValueTypeAssertionError")) {
            writer.uint32(58).string(message.constantValueTypeAssertionError);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowValidationIssue();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.summaryTotalIssuesCount = reader.uint32();
                break;
              }
              case 2: {
                message.celCompilationErrors = $root.validation.v1.CELCompilationErrors.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.parameterMissingIsRequired = reader.string();
                break;
              }
              case 4: {
                message.issueDescription = reader.string();
                break;
              }
              case 5: {
                message.duplicatedResultObjectKey = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 6: {
                message.contactMorfSupport = google$1.protobuf.Empty.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.constantValueTypeAssertionError = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.validation.v1.WorkflowValidationIssue) {
            return object;
          }
          const message = new $root.validation.v1.WorkflowValidationIssue();
          if (object.summaryTotalIssuesCount != null) {
            message.summaryTotalIssuesCount = object.summaryTotalIssuesCount >>> 0;
          }
          if (object.celCompilationErrors != null) {
            if (typeof object.celCompilationErrors !== "object") {
              throw new TypeError(".validation.v1.WorkflowValidationIssue.celCompilationErrors: object expected, but got " + (typeof object.celCompilationErrors));
            }
            message.celCompilationErrors = $root.validation.v1.CELCompilationErrors.fromObject(object.celCompilationErrors);
          }
          if (object.parameterMissingIsRequired != null) {
            message.parameterMissingIsRequired = String(object.parameterMissingIsRequired);
          }
          if (object.issueDescription != null) {
            message.issueDescription = String(object.issueDescription);
          }
          if (object.duplicatedResultObjectKey != null) {
            if (typeof object.duplicatedResultObjectKey !== "object") {
              throw new TypeError(".validation.v1.WorkflowValidationIssue.duplicatedResultObjectKey: object expected, but got " + (typeof object.duplicatedResultObjectKey));
            }
            message.duplicatedResultObjectKey = google$1.protobuf.Empty.fromObject(object.duplicatedResultObjectKey);
          }
          if (object.contactMorfSupport != null) {
            if (typeof object.contactMorfSupport !== "object") {
              throw new TypeError(".validation.v1.WorkflowValidationIssue.contactMorfSupport: object expected, but got " + (typeof object.contactMorfSupport));
            }
            message.contactMorfSupport = google$1.protobuf.Empty.fromObject(object.contactMorfSupport);
          }
          if (object.constantValueTypeAssertionError != null) {
            message.constantValueTypeAssertionError = String(object.constantValueTypeAssertionError);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.summaryTotalIssuesCount != null && message.hasOwnProperty("summaryTotalIssuesCount")) {
            object.summaryTotalIssuesCount = message.summaryTotalIssuesCount;
            if (options.oneofs) {
              object.type = "summaryTotalIssuesCount";
            }
          }
          if (message.celCompilationErrors != null && message.hasOwnProperty("celCompilationErrors")) {
            object.celCompilationErrors = $root.validation.v1.CELCompilationErrors.toObject(message.celCompilationErrors, options);
            if (options.oneofs) {
              object.type = "celCompilationErrors";
            }
          }
          if (message.parameterMissingIsRequired != null && message.hasOwnProperty("parameterMissingIsRequired")) {
            object.parameterMissingIsRequired = message.parameterMissingIsRequired;
            if (options.oneofs) {
              object.type = "parameterMissingIsRequired";
            }
          }
          if (message.issueDescription != null && message.hasOwnProperty("issueDescription")) {
            object.issueDescription = message.issueDescription;
            if (options.oneofs) {
              object.type = "issueDescription";
            }
          }
          if (message.duplicatedResultObjectKey != null && message.hasOwnProperty("duplicatedResultObjectKey")) {
            object.duplicatedResultObjectKey = google$1.protobuf.Empty.toObject(message.duplicatedResultObjectKey, options);
            if (options.oneofs) {
              object.type = "duplicatedResultObjectKey";
            }
          }
          if (message.contactMorfSupport != null && message.hasOwnProperty("contactMorfSupport")) {
            object.contactMorfSupport = google$1.protobuf.Empty.toObject(message.contactMorfSupport, options);
            if (options.oneofs) {
              object.type = "contactMorfSupport";
            }
          }
          if (message.constantValueTypeAssertionError != null && message.hasOwnProperty("constantValueTypeAssertionError")) {
            object.constantValueTypeAssertionError = message.constantValueTypeAssertionError;
            if (options.oneofs) {
              object.type = "constantValueTypeAssertionError";
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "validation.v1.WorkflowValidationIssue";
        }
      }

      WorkflowValidationIssue.prototype.summaryTotalIssuesCount = 0;
      WorkflowValidationIssue.prototype.celCompilationErrors = null;
      WorkflowValidationIssue.prototype.parameterMissingIsRequired = "";
      WorkflowValidationIssue.prototype.issueDescription = "";
      WorkflowValidationIssue.prototype.duplicatedResultObjectKey = null;
      WorkflowValidationIssue.prototype.contactMorfSupport = null;
      WorkflowValidationIssue.prototype.constantValueTypeAssertionError = "";

      return WorkflowValidationIssue;
    })();

    return v1;
  })();

  return validation;
})();

export const validation = $root.validation;
