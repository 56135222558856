import defaultRequiredThirdPartyIdMissingBehavior from './defaultRequiredThirdPartyIdMissingBehavior';
import { ActionItem } from './ActionList/types';
import { NodeType } from '../../../types';
import { defaultParameterMissingPolicy } from './defaultParameterMissingPolicy';
import { defaultRetryPolicy } from './defaultRetryPolicy';
import { hasRequiredThirdPartyIdMissingBehavior } from '../../../CustomNode/FetchActionNode/FetchActionSettings/hasRequiredThirdPartyIdMissingBehaviour';
import { healthie } from '@morf/proto/healthie/v1/healthie_ts_proto';
import { newNodeData } from './types';
import { snakeCase } from 'lodash';
import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';

const formatDefaultResultObjectKey = (
  applicationType: string,
  title: string,
  index: string
) =>
  snakeCase(applicationType).replace(/(.*)_v_(\d)$/, '$1_v$2') + // The regex is to get healthie_v1 and not healthie_v_1
  '_' +
  snakeCase(title) +
  '_result_' +
  index;

export const addFetchActionNode = (
  action: ActionItem,
  isBranch: boolean,
  index: number
): newNodeData => {
  const resultObjectKey = formatDefaultResultObjectKey(
    action.application,
    action.action,
    index.toString()
  );

  return {
    configuration: new workflows.v1.WorkflowNode({
      fetchAction: new workflows.v1.WorkflowFetchAction({
        resultObjectKey,
        retryPolicy: defaultRetryPolicy,
        parameterMissingPolicy: defaultParameterMissingPolicy,
        [action.application]: {
          [action.action]: {
            configs: action.configs,
            params: action.params,
            ...(action.application.includes('healthie') && {
              healthieEnvironment: healthie.v1.HealthieEnvironment.SANDBOX,
            }),
            ...(hasRequiredThirdPartyIdMissingBehavior(
              action.application,
              action.action
            ) && {
              requiredThirdPartyIdMissingBehavior:
                defaultRequiredThirdPartyIdMissingBehavior,
            }),
          },
        },
      }),
    }),
    integrationIconName: action.integrationIconName,
    isBranchNode: isBranch,
    name: action.name,
    type: NodeType.FetchActionNode,
  };
};
