import { FontSizes, FontWeights, LineHeights } from '../Theme';

const GreenShades = {
  darkest: '#0FB0A7',
  darker: '#1EC8BE',
  dark: '#51D9D1',
  normal: '#93ECE6',
  light: '#A3F5F0',
  lighter: '#BFF8F4',
  lightest: '#DAFBF9',
};

const PinkShades = {
  darkest: '#DF3888',
  darker: '#E15993',
  dark: '#E97DA2',
  normal: '#F0A7C0',
  light: '#F6CAD6',
  lighter: '#F9D3E0',
  lightest: '#FCEAF0',
};

const PurpleShades = {
  darkest: '#AF47FF',
  darker: '#BF6AFF',
  dark: '#CF8DFF',
  normal: '#DFB0FF',
  light: '#EFD3FF',
  lighter: '#F7E4FF',
  lightest: '#FBF2FF',
};

const RedShades = {
  darkest: '#FD4B4B',
  darker: '#FD5E5E',
  dark: '#FD7272',
  normal: '#FE8686',
  light: '#FEA4A4',
  lighter: '#FEB8B8',
  lightest: '#FFE6E6',
};

const YellowShades = {
  darkest: '#FFC940',
  darker: '#FBD36A',
  dark: '#FCD87E',
  normal: '#FCDE92',
  light: '#FFE299',
  lighter: '#FFEBB8',
  lightest: '#FFF3D6',
};

const UIColors = {
  dark: '#8285AC',
  muted: '#C7C9DE',
  body: '#F6F8FA',
  divider: '#E7E8F1',
  card: '#FFFFFF',
};

const TextColors = {
  hover: '#FFFFFF',
  muted: '#999BAF',
  disabled: '#595A5E',
  inverse: '#FFFFFF',
};

export const defaultColors = {
  support: {
    green: GreenShades,
    pink: PinkShades,
    purple: PurpleShades,
    red: RedShades,
    yellow: YellowShades,
  },
  ui: UIColors,
  text: {
    ...TextColors,
  },
  avatar: [],
};

export const defaultH1 = {
  decoration: 'none',
  family: 'apercu-bold-pro',
  letterSpacing: '0rem',
  lineHeight: LineHeights['2.25'],
  size: FontSizes['2'],
  weight: FontWeights.bold,
};

export const defaultH2 = {
  decoration: 'none',
  family: 'apercu-bold-pro',
  letterSpacing: '0rem',
  lineHeight: LineHeights['1.75'],
  size: FontSizes['1.5'],
  weight: FontWeights.bold,
};

export const defaultH3 = {
  decoration: 'none',
  family: 'apercu-bold-pro',
  letterSpacing: '0rem',
  lineHeight: LineHeights['1.5'],
  size: FontSizes['1.125'],
  weight: FontWeights.bold,
};

export const defaultH4 = {
  decoration: 'none',
  family: 'apercu-bold-pro',
  letterSpacing: '0rem',
  lineHeight: LineHeights['1.375'],
  size: FontSizes['1'],
  weight: FontWeights.bold,
};

export const defaultH5 = {
  decoration: 'none',
  family: 'apercu-bold-pro',
  letterSpacing: '0rem',
  lineHeight: LineHeights['1.25'],
  size: FontSizes['0.875'],
  weight: FontWeights.bold,
};

export const defaultH6 = {
  decoration: 'none',
  family: 'apercu-medium-pro',
  letterSpacing: '2%',
  lineHeight: LineHeights['0.875'],
  size: FontSizes['0.75'],
  weight: FontWeights.bold,
};

export const defaultH7 = {
  decoration: 'none',
  family: 'apercu-medium-pro',
  letterSpacing: '0rem',
  lineHeight: LineHeights['0.875'],
  size: FontSizes['0.5'],
  weight: FontWeights.bold,
};

export const defaultP1 = {
  decoration: 'none',
  family: 'apercu-regular-pro',
  letterSpacing: '0rem',
  lineHeight: LineHeights['1.375'],
  size: FontSizes['1'],
  weight: FontWeights.normal,
};

export const defaultP2 = {
  decoration: 'none',
  family: 'apercu-regular-pro',
  letterSpacing: '0rem',
  lineHeight: LineHeights['1.25'],
  size: FontSizes['0.875'],
  weight: FontWeights.normal,
};

export const defaultP3 = {
  decoration: 'none',
  family: 'apercu-regular-pro',
  letterSpacing: '0rem',
  lineHeight: LineHeights['1.125'],
  size: FontSizes['0.75'],
  weight: FontWeights.normal,
};

export const defaultP4 = {
  decoration: 'none',
  family: 'apercu-regular-pro',
  letterSpacing: '0rem',
  lineHeight: LineHeights['0.875'],
  size: FontSizes['0.5'],
  weight: FontWeights.normal,
};

export const defaultCode1 = {
  decoration: 'none',
  family: 'apercu-mono-regular-pro',
  letterSpacing: '-2%',
  lineHeight: LineHeights['1.375'],
  size: FontSizes['1'],
  weight: FontWeights.normal,
};

export const defaultCode2 = {
  decoration: 'none',
  family: 'apercu-mono-regular-pro',
  letterSpacing: '-2%',
  lineHeight: LineHeights['1.25'],
  size: FontSizes['0.875'],
  weight: FontWeights.normal,
};

export const defaultCode3 = {
  decoration: 'none',
  family: 'apercu-mono-regular-pro',
  letterSpacing: '0%',
  lineHeight: LineHeights['1'],
  size: FontSizes['0.75'],
  weight: FontWeights.normal,
};

export const defaultSubtitle = {
  decoration: 'none',
  family: 'apercu-regular-pro',
  letterSpacing: '0rem',
  lineHeight: LineHeights['1.75'],
  size: FontSizes['1.125'],
  weight: FontWeights.normal,
};

export const defaultLongform = {
  decoration: 'none',
  family: 'apercu-regular-pro',
  letterSpacing: '0rem',
  lineHeight: LineHeights['1.5'],
  size: FontSizes['1'],
  weight: FontWeights.normal,
};

export const defaultLink = {
  decoration: 'none',
  family: 'apercu-medium-pro',
  letterSpacing: '0rem',
  lineHeight: LineHeights['1.25'],
  size: FontSizes['0.875'],
  weight: FontWeights.bold,
};

export const defaultFontTypes = {
  h1: defaultH1,
  h2: defaultH2,
  h3: defaultH3,
  h4: defaultH4,
  h5: defaultH5,
  h6: defaultH6,
  h7: defaultH7,
  p1: defaultP1,
  p2: defaultP2,
  p3: defaultP3,
  p4: defaultP4,
  code1: defaultCode1,
  code2: defaultCode2,
  code3: defaultCode3,
  subtitle: defaultSubtitle,
  longform: defaultLongform,
  link: defaultLink,
};

export const defaultMaxNrOfSlots = {
  xxl: '15',
  xl: '15',
  lg: '10',
  md: '10',
  sm: '5',
  xs: '5',
};
