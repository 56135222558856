export const Junction = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          d='M25.3298 7.07397H15.9548V9.851H25.3298V7.07397Z'
          fill='#3D1836'
        />
        <path
          d='M25.3753 24.1715C25.3753 26.7448 23.3143 28.8369 20.779 28.8369H20.3139C17.4504 28.8369 15.745 27.1059 15.745 24.1992H13C13 28.6332 15.9365 31.6139 20.3048 31.6139H20.7699C24.819 31.6139 28.1021 28.2722 28.1021 24.1715V9.85126H25.3662V24.1715H25.3753Z'
          fill='#3D1836'
        />
      </g>
      <defs>
        <clipPath id='clip0_2246_4526'>
          <rect
            width='15'
            height='25'
            fill='white'
            transform='translate(13 7)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
