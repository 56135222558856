"use strict";

import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.workflow_destination_applications = (() => {
  const workflow_destination_applications = {};
  workflow_destination_applications.v1 = (() => {
    const v1 = {};
    v1.DestinationApplication = (() => {
      class DestinationApplication {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DestinationApplication(properties)
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.application != null && Object.hasOwnProperty.call(message, "application")) {
            writer.uint32(8).int32(message.application);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DestinationApplication();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.application = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflow_destination_applications.v1.DestinationApplication) {
            return object;
          }
          const message = new $root.workflow_destination_applications.v1.DestinationApplication();
          if (object.application != null) {
            switch (object.application) {
              case "UNSPECIFIED_DESTINATION_APPLICATION":
              case 0: {
                message.application = 0;
                break;
              }
              case "ACTIVE_CAMPAIGN":
              case 1: {
                message.application = 1;
                break;
              }
              case "CUSTOMER_IO":
              case 2: {
                message.application = 2;
                break;
              }
              case "FACEBOOK":
              case 3: {
                message.application = 3;
                break;
              }
              case "GOOGLE":
              case 4: {
                message.application = 4;
                break;
              }
              case "HEALTHIE":
              case 5: {
                message.application = 5;
                break;
              }
              case "MORF":
              case 6: {
                message.application = 6;
                break;
              }
              case "INTAKEQ":
              case 7: {
                message.application = 7;
                break;
              }
              case "SEGMENT":
              case 8: {
                message.application = 8;
                break;
              }
              case "SPRUCE":
              case 9: {
                message.application = 9;
                break;
              }
              case "SLACK":
              case 10: {
                message.application = 10;
                break;
              }
              case "FRESHDESK":
              case 11: {
                message.application = 11;
                break;
              }
              case "HUBSPOT":
              case 12: {
                message.application = 12;
                break;
              }
              case "INTERCOM":
              case 13: {
                message.application = 13;
                break;
              }
              case "TIKTOK":
              case 14: {
                message.application = 14;
                break;
              }
              case "TWILIO":
              case 15: {
                message.application = 15;
                break;
              }
              case "NEXTDOOR":
              case 16: {
                message.application = 16;
                break;
              }
              case "STRIPE":
              case 17: {
                message.application = 17;
                break;
              }
              case "MIXPANEL":
              case 18: {
                message.application = 18;
                break;
              }
              case "STEDI":
              case 19: {
                message.application = 19;
                break;
              }
              default: {
                if (typeof object.application == "number") {
                  message.application = object.application;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.application = options.enums === String ? "UNSPECIFIED_DESTINATION_APPLICATION" : 0
          }
          let keys;
          if (message.application != null && message.hasOwnProperty("application")) {
            object.application = options.enums === String ? $root.workflow_destination_applications.v1.DestinationApplication.Application[message.application] === undefined ? message.application : $root.workflow_destination_applications.v1.DestinationApplication.Application[message.application] : message.application;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
          return typeUrlPrefix + "workflow_destination_applications.v1.DestinationApplication";
        }
      }

      DestinationApplication.prototype.application = 0;
      DestinationApplication.Application = (function() {
        const valuesById = {};
        const values = Object.create(valuesById);
        values[valuesById[0] = "UNSPECIFIED_DESTINATION_APPLICATION"] = 0;
        values[valuesById[1] = "ACTIVE_CAMPAIGN"] = 1;
        values[valuesById[2] = "CUSTOMER_IO"] = 2;
        values[valuesById[3] = "FACEBOOK"] = 3;
        values[valuesById[4] = "GOOGLE"] = 4;
        values[valuesById[5] = "HEALTHIE"] = 5;
        values[valuesById[6] = "MORF"] = 6;
        values[valuesById[7] = "INTAKEQ"] = 7;
        values[valuesById[8] = "SEGMENT"] = 8;
        values[valuesById[9] = "SPRUCE"] = 9;
        values[valuesById[10] = "SLACK"] = 10;
        values[valuesById[11] = "FRESHDESK"] = 11;
        values[valuesById[12] = "HUBSPOT"] = 12;
        values[valuesById[13] = "INTERCOM"] = 13;
        values[valuesById[14] = "TIKTOK"] = 14;
        values[valuesById[15] = "TWILIO"] = 15;
        values[valuesById[16] = "NEXTDOOR"] = 16;
        values[valuesById[17] = "STRIPE"] = 17;
        values[valuesById[18] = "MIXPANEL"] = 18;
        values[valuesById[19] = "STEDI"] = 19;
        return values;
      })();

      return DestinationApplication;
    })();

    return v1;
  })();

  return workflow_destination_applications;
})();

export const workflow_destination_applications = $root.workflow_destination_applications;
