import { ExtendedColumnDef } from '../../../types';
import { Flexbox } from '../../../../Flexbox';
import { RowData, flexRender } from '@tanstack/react-table';
import { SkeletonLoadable } from '../../../../Skeleton';
import { StyledVirtualTableCell } from './VirtualTableCell.css';
import { VirtualTableCellProps } from './types';
import { memo } from 'react';

const VirtualTableCell: <RowData>(
  props: VirtualTableCellProps<RowData>
) => JSX.Element = ({
  cell,
  cellHeight,
  id,
  isFirstColumnCell,
  isFirstRow,
  isLastColumnCell,
  isLoading,
  ...props
}) => {
  const columnDef: ExtendedColumnDef<RowData> = cell.column
    .columnDef as ExtendedColumnDef<RowData>;
  const width: number = cell.column.getSize();
  const style: React.CSSProperties = {
    flex: `1 1 ${width}px`,
    minWidth: width,
  };

  return (
    <StyledVirtualTableCell
      key={cell.id}
      style={style}
      borderType={columnDef.cellBorderType}
      cellPt={columnDef.cellPt}
      cellPb={columnDef.cellPb}
      cellPl={columnDef.cellPl}
      cellPr={columnDef.cellPr}
      isFirstRow={isFirstRow}
      {...props}
    >
      <Flexbox
        data-testid={`cell-${cell.id}`}
        justifyContent='flex-start'
        alignItems='center'
        zIndex={0}
        height={cellHeight}
      >
        <Flexbox
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <SkeletonLoadable isLoading={isLoading} height='1.5rem'>
            <Flexbox gap={0.25} justifyContent='flex-start' alignItems='center'>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Flexbox>
          </SkeletonLoadable>
        </Flexbox>
      </Flexbox>
    </StyledVirtualTableCell>
  );
};

export const MemoizedVirtualTableCell = memo(
  VirtualTableCell
) as typeof VirtualTableCell;
