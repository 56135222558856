import { DefaultTheme } from '../../Theme';
import { ThemeContainerProps } from '../../types';
import { defaultColors } from '../../ColorPalette';
import {
  defaultFontTypes,
  defaultMaxNrOfSlots,
} from '../../ColorPalette/constants';

export const morfScheduling: ThemeContainerProps = {
  ...DefaultTheme,
  cancellationPolicyHeader: 'Late Change Policy',
  cancellationPolicyDescription: `<p>In order to protect our provider's time, appointment changes within 24hrs of the scheduled time will incur a $100 fee per our <a style="color: black; text-decoration: underline;" href="https://morf.health/" target='_blank'>policies</a>.`,
  colors: {
    ...defaultColors,
    text: {
      body: '#202236',
      ...defaultColors.text,
    },
    main: {
      primary: {
        darkest: '#50685f',
        darker: '#B3B3B3',
        dark: '',
        normal: '',
        light: '',
        lighter: '',
        lightest: '',
      },
      dark: {
        darkest: '#50685f',
        darker: '',
        dark: '',
        normal: '',
        light: '',
        lighter: '',
        lightest: '',
      },
      light: {
        darkest: '',
        darker: '',
        dark: '',
        normal: '#B3B3B3',
        light: '#f6f4ed',
        lighter: '',
        lightest: '',
      },
    },
  },
  container: {
    borderType: 'none',
    borderRadius: 0.5,
  },
  contentOrder: {
    bio: 1,
    specialties: 2,
  },
  datePicker: {
    borderRadius: 1.25,
    style: 'nofill',
  },
  fontTypes: defaultFontTypes,
  input: { borderRadius: 0.5 },
  maxNrOfSlots: defaultMaxNrOfSlots,
  timePicker: {
    borderRadius: 0.5,
    style: 'darkFill',
  },
  tooltip: {
    borderRadius: 0.5,
    type: 'light',
  },
  privacyPolicy: 'https://morf.health/',
  termsOfService: 'https://morf.health/',
  withinTimeWindowHours: 24,
  withinTimeWindowMessage: `<p>In order to protect our provider's time, our system automatically applies a late change fee ($100) which you agreed to when making your booking. <br/><br/> If you believe this is in error, you can reach out to us directly at support@berrystreet.co.</p>`,
  withinTimeWindowTitle: 'An appointment change within 24hrs will incur a fee',
};
