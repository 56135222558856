import { Container } from '../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { WrapperModalHeaderProps } from './types';
import { useTheme } from '@morf/theming';

const WrapperModalHeader: FC<WrapperModalHeaderProps> = ({
  title,
  description,
  onClose,
}) => {
  const theme = useTheme();

  return (
    <Flexbox
      data-testid='wrapper-modal-header'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
      gap={0.5}
    >
      <Flexbox
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        height='auto'
      >
        <Text tag='h3'>{title}</Text>
        <Container width='auto' height='auto'>
          <Icon
            name='close'
            stroke={theme.colors.text.muted}
            onClick={onClose}
            cursor='pointer'
          />
        </Container>
      </Flexbox>

      {typeof description === 'string' ? (
        <Text tag='p2'>{description}</Text>
      ) : (
        description
      )}
    </Flexbox>
  );
};

export const MemoizedWrapperModalHeader = memo(WrapperModalHeader);
