import Long from 'long';
import { NodeType, ParameterizedWorkflowNode } from '../types';
import { ProfileUpdateNodeProps } from './types';
import { defaultParameterMissingPolicy } from '../../CustomEdge/AddEdge/AddEdgeModal/defaultParameterMissingPolicy';
import { defaultRetryPolicy } from '../../CustomEdge/AddEdge/AddEdgeModal/defaultRetryPolicy';
import { destinationactions } from '@morf/proto/destinationactions/morf/v1/morf_ts_proto';
import { google } from '@morf/proto/checked_ts_proto';
import { sensitivity } from '@morf/proto/sensitivity/v1/sensitivity_ts_proto';
import { thirdparties } from '@morf/proto/thirdparties/v1/thirdparties_ts_proto';
import { values } from '@morf/proto/values/v1/values_ts_proto';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';
import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';

export const mockUpdateProfilePropertiesAction =
  new workflows.v1.WorkflowDestinationAction({
    retryPolicy: defaultRetryPolicy,
    parameterMissingPolicy: defaultParameterMissingPolicy,
    morfV1: new destinationactions.morf.v1.DestinationAction({
      updateProfileProperties:
        new destinationactions.morf.v1.UpdateProfileProperties({
          propertyUpdateSources: [
            new destinationactions.morf.v1.UpdateProfileProperties.PropertyMapping(
              {
                property: new workflow_parameters.v1.ProfilePropertyLookup({
                  sensitivities: [
                    sensitivity.v1.Sensitivity.Value.PII,
                    sensitivity.v1.Sensitivity.Value.ID,
                    sensitivity.v1.Sensitivity.Value.HI,
                  ],
                  propertyName: 'date_of_birth',
                  indexHash: new Long(1),
                  isMorfDefault: false,
                  valueType: new values.v1.ValueType({
                    string: {},
                  }),
                }),
                source:
                  new workflow_parameters.v1.DestinationActionParameterSource({
                    calculatedValue:
                      new workflow_parameters.v1.EventPayloadFieldLookup({
                        celExpressionStr: 'answers.dob',
                        runtimeType: new google.api.expr.v1alpha1.Type({
                          dyn: {},
                        }),
                      }),
                  }),
              }
            ),
          ],
          phoneNumberUpdateSource:
            new workflow_parameters.v1.DestinationActionParameterSource({
              eventPayloadFieldLookup:
                new workflow_parameters.v1.EventPayloadFieldLookup({
                  celExpressionStr: 'answers.phone_number',
                  runtimeType: new google.api.expr.v1alpha1.Type({ dyn: {} }),
                }),
            }),
          emailAddressUpdateSource:
            new workflow_parameters.v1.DestinationActionParameterSource({
              eventPayloadFieldLookup:
                new workflow_parameters.v1.EventPayloadFieldLookup({
                  celExpressionStr: 'answers.email_address',
                  runtimeType: new google.api.expr.v1alpha1.Type({ dyn: {} }),
                }),
            }),
          firstNameUpdateSource:
            new workflow_parameters.v1.DestinationActionParameterSource({
              calculatedValue:
                new workflow_parameters.v1.EventPayloadFieldLookup({
                  celExpressionStr: 'answers.first_name',
                  runtimeType: new google.api.expr.v1alpha1.Type({ dyn: {} }),
                }),
            }),
          lastNameUpdateSource:
            new workflow_parameters.v1.DestinationActionParameterSource({
              eventPayloadFieldLookup:
                new workflow_parameters.v1.EventPayloadFieldLookup({
                  celExpressionStr: 'answers.last_name',
                  runtimeType: new google.api.expr.v1alpha1.Type({ dyn: {} }),
                }),
            }),
          thirdPartyProfileIdUpdateSources: [
            new destinationactions.morf.v1.UpdateProfileProperties.ThirdPartyProfileIDMapping(
              {
                type: thirdparties.v1.ThirdPartyIdType.FORMSORT,
                source:
                  new workflow_parameters.v1.DestinationActionParameterSource({
                    eventPayloadFieldLookup:
                      new workflow_parameters.v1.EventPayloadFieldLookup({
                        celExpressionStr: 'answers.formsort_id',
                        runtimeType: new google.api.expr.v1alpha1.Type({
                          dyn: {},
                        }),
                      }),
                  }),
              }
            ),
            new destinationactions.morf.v1.UpdateProfileProperties.ThirdPartyProfileIDMapping(
              {
                type: thirdparties.v1.ThirdPartyIdType.HEALTHIE,
                source:
                  new workflow_parameters.v1.DestinationActionParameterSource({
                    eventPayloadFieldLookup:
                      new workflow_parameters.v1.EventPayloadFieldLookup({
                        celExpressionStr: 'answers.healthie_id',
                        runtimeType: new google.api.expr.v1alpha1.Type({
                          dyn: {},
                        }),
                      }),
                  }),
              }
            ),
          ],
          mergeIfEmailAddressAlreadyExists: true,
          mergeIfPhoneNumberAlreadyExists: false,
        }),
    }),
  });

export const profileUpdateNode: ProfileUpdateNodeProps = {
  id: '1605449d-2dc4-4dd2-a22f-5f137e8331ed',
  type: NodeType.ProfileUpdateNode,
  data: {
    value: {
      configuration: new workflows.v1.WorkflowNode({
        id: '1605449d-2dc4-4dd2-a22f-5f137e8331ed',
        name: 'Update Profile Properties',
        destinationAction: mockUpdateProfilePropertiesAction,
        validationIssues: [],
      }) as workflows.v1.WorkflowNode &
        ParameterizedWorkflowNode<'destinationAction'>,
      integrationIconName: 'morf',
      name: 'Update Profile Properties',
      onCreateCustomProperty: () => console.log('onCreateCustomProperty'),
      title: 'Profile update',
    },
  },
  position: { x: 0, y: 0 },
};
