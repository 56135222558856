import _ from 'lodash';
import { getObjectTypeIdFieldType } from '../Workflow/CustomNode/DestinationActionNode/DestinationActionConfigure/SelectValuePairModal/getObjectTypeIdFieldType';
import { google } from '@morf/proto/checked_ts_proto';
import { hasField } from './hasField';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';

export const formatType = (
  type: google.api.expr.v1alpha1.Type | null | undefined,
  objectTypeIdContext:
    | workflow_parameters.v1.ObjectTypeIDContext
    | null
    | undefined
): string => {
  if (!type) {
    return 'n/a';
  }

  if (hasField(type, 'dyn')) {
    return 'dynamic';
  }

  if (hasField(type, 'null')) {
    return 'null';
  }

  if (hasField(type, 'primitive')) {
    const primitiveType =
      google.api.expr.v1alpha1.Type.PrimitiveType[type.primitive];
    return _.toLower(primitiveType);
  }

  if (hasField(type, 'wrapper')) {
    const wrapperType =
      google.api.expr.v1alpha1.Type.PrimitiveType[type.wrapper];
    return 'wrapper ' + _.toLower(wrapperType);
  }

  if (hasField(type, 'wellKnown')) {
    const wellKnownType =
      google.api.expr.v1alpha1.Type.WellKnownType[type.wellKnown];
    return _.toLower(wellKnownType);
  }

  if (hasField(type, 'listType')) {
    return `${formatType(type.listType?.elemType, objectTypeIdContext)}[]`;
  }

  if (hasField(type, 'mapType')) {
    return `map <${formatType(
      type.mapType?.keyType,
      objectTypeIdContext
    )},${formatType(type.mapType?.valueType, objectTypeIdContext)}>`;
  }

  if (hasField(type, 'function')) {
    return `function { resultType: ${formatType(
      type.function?.resultType,
      objectTypeIdContext
    )}, argTypes: [${type.function?.argTypes
      .map((argType) => formatType(argType, objectTypeIdContext))
      .join(',')}] }`;
  }

  if (hasField(type, 'messageType')) {
    return _.isEqual(type.messageType, 'workflow_parameters.v1.ObjectTypeID')
      ? getObjectTypeIdFieldType(objectTypeIdContext)
      : 'messageType';
  }

  if (hasField(type, 'typeParam')) {
    return type.typeParam;
  }

  if (hasField(type, 'type')) {
    return formatType(type.type, objectTypeIdContext);
  }

  if (hasField(type, 'error')) {
    return 'error';
  }

  if (hasField(type, 'abstractType')) {
    const name = type.abstractType?.name;
    const parameterTypes =
      type.abstractType?.parameterTypes.map((parameterType) =>
        formatType(parameterType, objectTypeIdContext)
      ) || [];

    if (name === 'optional_type' && parameterTypes.length === 1) {
      return `${parameterTypes[0]} (optional)`;
    }

    return `abstractType { name: ${name}, parameterTypes: [${parameterTypes?.join(
      ','
    )}] }`;
  }

  return 'n/a';
};
