import React, { useEffect, useState, useRef, useCallback } from 'react';
import { FloatingPortal } from '@floating-ui/react';
import { EditorView } from '@tiptap/pm/view';
import { SlashCommandItem } from './SlashCommandTypes';
import {
  pluginKey,
  registerSlashCommandViewCallback,
} from './SlashCommandExtension';
import { createSelectHandler } from './SlashCommandActions';
import { SelectValuePairModal } from '../../../Workflow/CustomNode/DestinationActionNode/DestinationActionConfigure/SelectValuePairModal';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';
import { SelectValuePairCategory } from '../../../Workflow/CustomNode/DestinationActionNode/DestinationActionConfigure/SelectValuePairModal/types';

interface SlashCommandFloatingMenuProps {
  editor: EditorView;
}

export const SlashCommandFloatingMenu: React.FC<
  SlashCommandFloatingMenuProps
> = ({ editor }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState<SlashCommandItem[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const menuRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
    return undefined;
  }, [isOpen, handleClickOutside]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!isOpen) return;

      const pluginState = pluginKey.getState(editor.state);
      if (!pluginState) return;

      switch (event.key) {
        case 'Enter':
          event.preventDefault();
          if (items[selectedIndex]) {
            handleSelect(items[selectedIndex]);
          }
          break;
        case 'Escape':
          event.preventDefault();
          setIsOpen(false);
          break;
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, selectedIndex, items, editor]);

  useEffect(() => {
    const unregister = registerSlashCommandViewCallback((event) => {
      if (event.type === 'update') {
        if (event.position) {
          setPosition(event.position);
        }
        setItems(event.items);
        setSelectedIndex(event.state.selectedIndex);
        setIsOpen(true);
      } else if (event.type === 'hide') {
        setIsOpen(false);
      }
    });

    return () => {
      unregister();
    };
  }, []);

  const handleSelect = (item: SlashCommandItem) => {
    setIsOpen(false);

    const pluginState = pluginKey.getState(editor.state);
    if (!pluginState) return;

    const selectHandler = createSelectHandler(editor, pluginState);
    selectHandler(item);
  };

  const handleValuePairSelect = (
    sources: workflow_parameters.v1.DestinationActionParameterSource[]
  ) => {
    const toAdd = sources[0];
    handleSelect({
      title: toAdd.toString(),
      parameterSource: toAdd,
    });
    setIsOpen(false);
  };

  if (!isOpen || items.length === 0) {
    return null;
  }

  return (
    <FloatingPortal>
      <div
        ref={menuRef}
        style={{
          position: 'absolute',
          left: `${position.x}px`,
          top: `${position.y}px`,
          zIndex: 1000,
        }}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <SelectValuePairModal
          onClick={handleValuePairSelect}
          onClose={() => setIsOpen(false)}
          onCreateCustomProperty={() =>
            console.warn(
              'onCreateCustomProperty called in a TemplateEditor SlashMenu'
            )
          }
          selectedSource={null}
          eventPayloadFieldSchemas={[]}
          useModalPositioning={false} // Use inline positioning instead of modal
          autofocus={true}
          width='23.75rem'
          visibleValuePairCategories={[
            SelectValuePairCategory.PayloadField,
            SelectValuePairCategory.Identifiers,
            SelectValuePairCategory.MorfProperties,
            SelectValuePairCategory.CustomProperties,
            SelectValuePairCategory.CalculatedValue,
            SelectValuePairCategory.ConstantValue,
            SelectValuePairCategory.ElidedValue,
            SelectValuePairCategory.MorfEventType,
            SelectValuePairCategory.MorfEventTime,
            SelectValuePairCategory.UnsubscribeUrl,
          ]}
        />
      </div>
    </FloatingPortal>
  );
};

export default SlashCommandFloatingMenu;
