import { NodeType, ParameterizedWorkflowNode } from '../types';
import { RestartNodeProps } from './types';
import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';

export const restartNode: RestartNodeProps = {
  id: '1d35449d-2dc4-4dd2-a22f-5f137e8331ed',
  type: NodeType.RestartNode,
  data: {
    value: {
      configuration: new workflows.v1.WorkflowNode({
        id: '1d35449d-2dc4-4dd2-a22f-5f137e8331ed',
        name: 'Restart Workflow',
        restart: new workflows.v1.WorkflowRestart({}),
        validationIssues: [],
      }) as workflows.v1.WorkflowNode & ParameterizedWorkflowNode<'restart'>,
      name: 'Restart Workflow',
      title: 'Restart workflow',
    },
  },
  position: { x: 0, y: 0 },
};
