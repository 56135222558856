import { NodeType } from '../../../types';
import { newNodeData } from './types';
import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';

export const addFilterNode = (isBranch: boolean): newNodeData => {
  return {
    configuration: new workflows.v1.WorkflowNode({
      filter: new workflows.v1.WorkflowFilter({
        celExpressionStr: '',
      }),
    }),
    isBranchNode: isBranch,
    name: 'Filter',
    type: NodeType.FilterNode,
  };
};
