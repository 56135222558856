"use strict";

import * as $protobuf from "protobufjs/minimal";
import { intakeq as intakeq$1 } from "../../../intakeq/v1/intakeq_ts_proto";
import { workflow_parameters as workflow_parameters$1 } from "../../../workflow_parameters/v1/workflow_parameters_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.fetchactions = (() => {
  const fetchactions = {};
  fetchactions.intakeq = (() => {
    const intakeq = {};
    intakeq.v1 = (() => {
      const v1 = {};
      v1.FetchAction = (() => {
        class FetchAction {
          get type() {
            for (const key of ["getClient", "listAppointments", "getLastAndNextAppointments"]) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set type(name) {
            for (const key of ["getClient", "listAppointments", "getLastAndNextAppointments"]) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new FetchAction(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.getClient != null && Object.hasOwnProperty.call(message, "getClient")) {
              $root.fetchactions.intakeq.v1.GetClient.encode(message.getClient, writer.uint32(10).fork()).ldelim();
            }
            if (message.listAppointments != null && Object.hasOwnProperty.call(message, "listAppointments")) {
              $root.fetchactions.intakeq.v1.ListAppointments.encode(message.listAppointments, writer.uint32(18).fork()).ldelim();
            }
            if (message.getLastAndNextAppointments != null && Object.hasOwnProperty.call(message, "getLastAndNextAppointments")) {
              $root.fetchactions.intakeq.v1.GetLastAndNextAppointments.encode(message.getLastAndNextAppointments, writer.uint32(26).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new FetchAction();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.getClient = $root.fetchactions.intakeq.v1.GetClient.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.listAppointments = $root.fetchactions.intakeq.v1.ListAppointments.decode(reader, reader.uint32());
                  break;
                }
                case 3: {
                  message.getLastAndNextAppointments = $root.fetchactions.intakeq.v1.GetLastAndNextAppointments.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.intakeq.v1.FetchAction) {
              return object;
            }
            const message = new $root.fetchactions.intakeq.v1.FetchAction();
            if (object.getClient != null) {
              if (typeof object.getClient !== "object") {
                throw new TypeError(".fetchactions.intakeq.v1.FetchAction.getClient: object expected, but got " + (typeof object.getClient));
              }
              message.getClient = $root.fetchactions.intakeq.v1.GetClient.fromObject(object.getClient);
            }
            if (object.listAppointments != null) {
              if (typeof object.listAppointments !== "object") {
                throw new TypeError(".fetchactions.intakeq.v1.FetchAction.listAppointments: object expected, but got " + (typeof object.listAppointments));
              }
              message.listAppointments = $root.fetchactions.intakeq.v1.ListAppointments.fromObject(object.listAppointments);
            }
            if (object.getLastAndNextAppointments != null) {
              if (typeof object.getLastAndNextAppointments !== "object") {
                throw new TypeError(".fetchactions.intakeq.v1.FetchAction.getLastAndNextAppointments: object expected, but got " + (typeof object.getLastAndNextAppointments));
              }
              message.getLastAndNextAppointments = $root.fetchactions.intakeq.v1.GetLastAndNextAppointments.fromObject(object.getLastAndNextAppointments);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.getClient != null && message.hasOwnProperty("getClient")) {
              object.getClient = $root.fetchactions.intakeq.v1.GetClient.toObject(message.getClient, options);
              if (options.oneofs) {
                object.type = "getClient";
              }
            }
            if (message.listAppointments != null && message.hasOwnProperty("listAppointments")) {
              object.listAppointments = $root.fetchactions.intakeq.v1.ListAppointments.toObject(message.listAppointments, options);
              if (options.oneofs) {
                object.type = "listAppointments";
              }
            }
            if (message.getLastAndNextAppointments != null && message.hasOwnProperty("getLastAndNextAppointments")) {
              object.getLastAndNextAppointments = $root.fetchactions.intakeq.v1.GetLastAndNextAppointments.toObject(message.getLastAndNextAppointments, options);
              if (options.oneofs) {
                object.type = "getLastAndNextAppointments";
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.intakeq.v1.FetchAction";
          }
        }

        FetchAction.prototype.getClient = null;
        FetchAction.prototype.listAppointments = null;
        FetchAction.prototype.getLastAndNextAppointments = null;

        return FetchAction;
      })();

      v1.GetClient = (() => {
        class GetClient {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new GetClient(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              intakeq$1.v1.Client.encode(message.result, writer.uint32(26).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new GetClient();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.result = intakeq$1.v1.Client.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.intakeq.v1.GetClient) {
              return object;
            }
            const message = new $root.fetchactions.intakeq.v1.GetClient();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.intakeq.v1.GetClient.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.intakeq.v1.GetClient.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.intakeq.v1.GetClient.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.intakeq.v1.GetClient.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.intakeq.v1.GetClient.result: object expected, but got " + (typeof object.result));
              }
              message.result = intakeq$1.v1.Client.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = intakeq$1.v1.Client.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.intakeq.v1.GetClient";
          }
        }

        GetClient.prototype.params = $util.emptyArray;
        GetClient.prototype.configs = $util.emptyArray;
        GetClient.prototype.result = null;

        return GetClient;
      })();

      v1.ListAppointments = (() => {
        class ListAppointments {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            this.result = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ListAppointments(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              for (const element of message.result) {
                intakeq$1.v1.Appointment.encode(element, writer.uint32(26).fork()).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ListAppointments();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  if (!message.result || !message.result.length) {
                    message.result = [];
                  }
                  message.result.push(intakeq$1.v1.Appointment.decode(reader, reader.uint32()));
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.intakeq.v1.ListAppointments) {
              return object;
            }
            const message = new $root.fetchactions.intakeq.v1.ListAppointments();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.intakeq.v1.ListAppointments.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.intakeq.v1.ListAppointments.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.intakeq.v1.ListAppointments.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.intakeq.v1.ListAppointments.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.result) {
              if (!Array.isArray(object.result)) {
                throw new TypeError(".fetchactions.intakeq.v1.ListAppointments.result: array type expected, but got " + (typeof object.result))
              }
              message.result = new Array(object.result.length);
              for (let i = 0; i < object.result.length; ++i) {
                if (typeof object.result[i] !== "object") {
                  throw new TypeError(".fetchactions.intakeq.v1.ListAppointments.result: object expected, but got " + (typeof object.result[i]));
                }
                message.result[i] = intakeq$1.v1.Appointment.fromObject(object.result[i]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
              object.result = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.result && message.result.length) {
              object.result = new Array(message.result.length);
              for (let i = 0; i < message.result.length; ++i) {
                object.result[i] = intakeq$1.v1.Appointment.toObject(message.result[i], options);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.intakeq.v1.ListAppointments";
          }
        }

        ListAppointments.prototype.params = $util.emptyArray;
        ListAppointments.prototype.configs = $util.emptyArray;
        ListAppointments.prototype.result = $util.emptyArray;

        return ListAppointments;
      })();

      v1.GetLastAndNextAppointments = (() => {
        class GetLastAndNextAppointments {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new GetLastAndNextAppointments(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              intakeq$1.v1.LastAndNextAppointments.encode(message.result, writer.uint32(26).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new GetLastAndNextAppointments();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.result = intakeq$1.v1.LastAndNextAppointments.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.intakeq.v1.GetLastAndNextAppointments) {
              return object;
            }
            const message = new $root.fetchactions.intakeq.v1.GetLastAndNextAppointments();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.intakeq.v1.GetLastAndNextAppointments.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.intakeq.v1.GetLastAndNextAppointments.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.intakeq.v1.GetLastAndNextAppointments.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.intakeq.v1.GetLastAndNextAppointments.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.intakeq.v1.GetLastAndNextAppointments.result: object expected, but got " + (typeof object.result));
              }
              message.result = intakeq$1.v1.LastAndNextAppointments.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = intakeq$1.v1.LastAndNextAppointments.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.intakeq.v1.GetLastAndNextAppointments";
          }
        }

        GetLastAndNextAppointments.prototype.params = $util.emptyArray;
        GetLastAndNextAppointments.prototype.configs = $util.emptyArray;
        GetLastAndNextAppointments.prototype.result = null;

        return GetLastAndNextAppointments;
      })();

      return v1;
    })();

    return intakeq;
  })();

  return fetchactions;
})();

export const fetchactions = $root.fetchactions;
