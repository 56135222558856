import Cookies from 'js-cookie';
import { FC, memo, useRef, useState } from 'react';
import { STYTCH_SESSION_JWT } from '@morf/constants';
import { StyledUserImage } from './UserMenu.css';
import { Tooltip, Menu, Container } from '@morf/ui';
import { UserMenuProps } from './types';
import { copyTextToClipboard } from '@morf/ui/Helpers/copyTextToClipboard';
import { useClickOutside } from '@morf/ui/Hooks/useClickOutside';
import { usePermissions } from '../../../../context/permissions/usePermissions';
import { useTheme } from '@morf/theming';
import { LetteredAvatar } from '@morf/ui/LetteredAvatar';
import { getDecodedUser } from '../../../../context/permissions/getDecodedUser';

const UserMenu: FC<UserMenuProps> = ({ onLogout, userImage }) => {
  const theme = useTheme();
  const userMenuRef = useRef<HTMLDivElement>(null);
  const { isMorfInternalUser } = usePermissions();
  const session_jwt = Cookies.get(STYTCH_SESSION_JWT);
  const decodedUser = getDecodedUser();

  const [userMenu, setUserMenu] = useState(false);

  useClickOutside(userMenuRef, () => {
    setUserMenu(false);
  });

  const items = [
    {
      label: 'Logout',
      icon: {
        name: 'logout',
        fill: 'none',
        stroke: theme.colors.ui.dark,
      },
      onClick: onLogout,
    },
  ];

  if (isMorfInternalUser) {
    items.splice(0, 0, {
      label: 'Copy JWT to Clipboard',
      icon: {
        name: 'copy',
        stroke: theme.colors.ui.dark,
        fill: 'none',
      },
      onClick: () => {
        session_jwt && copyTextToClipboard(session_jwt);
        setUserMenu(false);
      },
    });
  }

  const handleUserMenuToggle = () => {
    setUserMenu((prevUserMenu) => !prevUserMenu);
  };

  return (
    <Tooltip
      tooltipText='Profile and Settings'
      tooltipDirection='right'
      isActive={!userMenu}
    >
      <Container
        ref={userMenuRef}
        data-testid='user-menu'
        cursor='pointer'
        width='auto'
        height='auto'
        pt={0.5}
      >
        {userImage ? (
          <StyledUserImage
            alt='user'
            src={userImage}
            onClick={handleUserMenuToggle}
            isActive={userMenu}
          />
        ) : (
          <div onClick={handleUserMenuToggle}>
            <LetteredAvatar
              name={decodedUser?.email || 'User'}
              width='1.5rem'
              height='1.5rem'
              tag='p2'
            />
          </div>
        )}
        {userMenu && (
          <Menu position='absolute' bottom='0' left='3.5rem' items={items} />
        )}
      </Container>
    </Tooltip>
  );
};

export const MemoizedUserMenu = memo(UserMenu);
