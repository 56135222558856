import { VirtualTableHeadCell } from './VirtualTableHeadCell';
import { VirtualTableHeadProps } from './types';
import { memo } from 'react';

const VirtualTableHead: <RowData>(
  props: VirtualTableHeadProps<RowData>
) => JSX.Element = ({ headerGroups, ...props }) => {
  return (
    <thead data-testid='virtual-head-group'>
      {headerGroups.map((headerGroup, index) => (
        <tr key={index}>
          {headerGroup.headers.map((header, index) => {
            return (
              <VirtualTableHeadCell key={index} header={header} {...props} />
            );
          })}
        </tr>
      ))}
    </thead>
  );
};

export const MemoizedVirtualTableHead = memo(
  VirtualTableHead
) as typeof VirtualTableHead;
