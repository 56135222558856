import { NodeType, ParameterizedWorkflowNode } from '../types';
import { PauseNodeProps } from './types';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';
import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';

export const mockPause = new workflows.v1.WorkflowPause({
  timestamps: {
    sources: [
      new workflow_parameters.v1.DestinationActionParameterSource({
        eventPayloadFieldLookup:
          new workflow_parameters.v1.EventPayloadFieldLookup({
            celExpressionStr: 'created_at.add(duration("5m"))',
          }),
      }),
    ],
  },
  refreshEventPayload: true,
});

export const pauseNode: PauseNodeProps = {
  id: 'ed419936-842a-4479-87a2-19f6b70e3924',
  type: NodeType.PauseNode,
  data: {
    value: {
      configuration: new workflows.v1.WorkflowNode({
        id: 'ed419936-842a-4479-87a2-19f6b70e3924',
        name: 'After 5 min',
        pause: mockPause,
        validationIssues: [],
      }) as workflows.v1.WorkflowNode & ParameterizedWorkflowNode<'pause'>,
      name: 'After 5 min',
      title: 'Wait until...',
    },
  },
  position: { x: 0, y: 0 },
};
