import defaultRequiredThirdPartyIdMissingBehavior from './defaultRequiredThirdPartyIdMissingBehavior';
import { ActionItem } from './ActionList/types';
import { NodeType } from '../../../types';
import { defaultParameterMissingPolicy } from './defaultParameterMissingPolicy';
import { defaultRetryPolicy } from './defaultRetryPolicy';
import { hasRequiredThirdPartyIdMissingBehavior } from '../../../CustomNode/DestinationActionNode/DestinationActionSettings/hasRequiredThirdPartyIdMissingBehaviour';
import { healthie } from '@morf/proto/healthie/v1/healthie_ts_proto';
import { newNodeData } from './types';
import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';

export const addDestinationActionNode = (
  action: ActionItem,
  isBranch: boolean
): newNodeData => {
  return {
    configuration: new workflows.v1.WorkflowNode({
      destinationAction: new workflows.v1.WorkflowDestinationAction({
        retryPolicy: defaultRetryPolicy,
        parameterMissingPolicy: defaultParameterMissingPolicy,
        [action.application]: {
          [action.action]: {
            params: action.params,
            configs: action.configs,
            ...(action.application.includes('healthie') && {
              healthieEnvironment: healthie.v1.HealthieEnvironment.SANDBOX,
            }),
            ...(hasRequiredThirdPartyIdMissingBehavior(
              action.application,
              action.action
            ) && {
              requiredThirdPartyIdMissingBehavior:
                defaultRequiredThirdPartyIdMissingBehavior,
            }),
          },
        },
      }),
    }),
    integrationIconName: action.integrationIconName,
    isBranchNode: isBranch,
    name: action.name,
    type: NodeType.DestinationActionNode,
  };
};
