import { StyledVirtualTableRow } from './VirtualTableRow.css';
import { VirtualTableRowProps } from './types';
import { VirtualTableCell } from './VirtualTableCell';
import { memo } from 'react';

const VirtualTableRow: <RowData>(
  props: VirtualTableRowProps<RowData>
) => JSX.Element = ({
  cellBackgroundColor,
  cellHeight,
  cellHoveredBackgroundColor,
  cellHoveredBorderColor,
  cellSelectedBackgroundColor,
  cellSelectedBorderColor,
  firstRowId,
  isLoading,
  onRowClick,
  row,
  virtualRow,
  virtualizer,
}) => {
  const cells = row.getVisibleCells();
  const isRowClickable: boolean = row.getCanSelect() && !isLoading;
  const isSelected: boolean =
    row.getIsSelected() || row.getParentRow()?.getIsSelected() || false;

  const handleRowClick = () => {
    if (!isLoading) {
      row.toggleSelected();
      const [rowId, _subRowId] = String(row.id).split('.').map(Number);
      onRowClick && onRowClick(isSelected ? null : rowId);
    }
  };

  const style: React.CSSProperties = {
    display: 'flex',
    position: 'absolute',
    transform: `translateY(${virtualRow.start}px)`,
    width: '100%',
  };

  return (
    <StyledVirtualTableRow
      key={row.id}
      data-testid='row'
      data-index={virtualRow.index}
      onClick={handleRowClick}
      isSelected={isSelected}
      isClickable={isRowClickable}
      cellBackgroundColor={cellBackgroundColor}
      cellHoveredBackgroundColor={cellHoveredBackgroundColor}
      cellHoveredBorderColor={cellHoveredBorderColor}
      cellSelectedBackgroundColor={cellSelectedBackgroundColor}
      cellSelectedBorderColor={cellSelectedBorderColor}
      ref={virtualizer.measureElement}
      style={style}
    >
      {cells.map((cell, cellIndex) => {
        const isFirstColumnCell = cellIndex === 0;
        const isLastColumnCell = cellIndex === cells.length - 1;
        const isFirstRow = cell.row.id === firstRowId;
        return (
          <VirtualTableCell
            key={cellIndex}
            cell={cell}
            cellHeight={cellHeight}
            isFirstColumnCell={isFirstColumnCell}
            isFirstRow={isFirstRow}
            isLastColumnCell={isLastColumnCell}
            isLoading={isLoading}
          />
        );
      })}
    </StyledVirtualTableRow>
  );
};
export const MemoizedVirtualTableRow = memo(
  VirtualTableRow
) as typeof VirtualTableRow;
