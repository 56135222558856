import { DefaultTheme } from '../../Theme';
import { ThemeContainerProps } from '../../types';
import { defaultColors } from '../../ColorPalette';
import {
  defaultFontTypes,
  defaultMaxNrOfSlots,
} from '../../ColorPalette/constants';

export const morfDefault: ThemeContainerProps = {
  ...DefaultTheme,
  cancellationPolicyHeader: 'Late Change Policy',
  cancellationPolicyDescription: `<p>In order to protect our provider's time, appointment changes within 24hrs of the scheduled time will incur a $100 fee per our <a style="color: black; text-decoration: underline;" href="https://morf.health/" target='_blank'>policies</a>.`,
  colors: {
    ...defaultColors,
    text: {
      body: '#202236',
      ...defaultColors.text,
    },
    main: {
      primary: {
        darkest: '#1120B1',
        darker: '#3C4CEC',
        dark: '#384DF5',
        normal: '#5063F6',
        light: '#6878F7',
        lighter: '#818EF9',
        lightest: '#99A4FA',
      },
      dark: {
        darkest: '#202236',
        darker: '#3F4155',
        dark: '#818398',
        normal: '#B0B2BF',
        light: '#BEC0DA',
        lighter: '#D2D3E5',
        lightest: '#E5E6F0',
      },
      light: {
        darkest: '#CACFFC',
        darker: '#E2E5FE',
        dark: '#E3E9FF',
        normal: '#E3E9FF',
        light: '#eff0fe',
        lighter: '#f7f8ff',
        lightest: '#fafaff',
      },
    },
    avatar: [
      '#7E9373',
      '#C79A86',
      '#498BBB',
      '#5D5FA3',
      '#D57FA9',
      '#333E6C',
      '#B19746',
      '#7ACBC6',
    ],
  },
  container: {
    borderType: 'none',
    borderRadius: 0.5,
  },
  contentOrder: {
    bio: 1,
    specialties: 2,
  },
  datePicker: {
    borderRadius: 1.25,
    style: 'nofill',
  },
  fontTypes: defaultFontTypes,
  input: { borderRadius: 0.5 },
  maxNrOfSlots: defaultMaxNrOfSlots,
  timePicker: {
    borderRadius: 0.5,
    style: 'outline',
  },
  tooltip: {
    borderRadius: 0.5,
    type: 'light',
  },
  privacyPolicy: 'https://morf.health/',
  termsOfService: 'https://morf.health/',
  withinTimeWindowHours: 24,
  withinTimeWindowMessage: `<p>In order to protect our provider's time, our system automatically applies a late change fee ($100) which you agreed to when making your booking. <br/><br/> If you believe this is in error, you can reach out to us directly at support@berrystreet.co.</p>`,
  withinTimeWindowTitle: 'An appointment change within 24hrs will incur a fee',
};
