import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';
import { PayloadField } from './PayloadField/types';
import { getEventPayloadFieldLookup } from './getEventPayloadFieldLookup';

export const formatPayloadFields = (
  payloadFields: workflow_parameters.v1.WorkflowEventPayloadFieldSchema[],
  selectedSource: workflow_parameters.v1.DestinationActionParameterSource | null
): PayloadField[] => {
  return payloadFields.map((payloadField, index) => {
    const eventPayloadFieldLookup = getEventPayloadFieldLookup(payloadField);
    return {
      index: index,
      field: payloadField,
      isSelected:
        selectedSource?.eventPayloadFieldLookup?.celExpressionStr ===
        eventPayloadFieldLookup?.celExpressionStr,
    };
  });
};
