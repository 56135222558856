import { styled } from '@morf/theming';

export const sizes = {
  small: {
    width: 32,
    height: 20,
    thumbSize: 16,
  },
  medium: {
    width: 44,
    height: 24,
    thumbSize: 20,
  },
  large: {
    width: 56,
    height: 32,
    thumbSize: 28,
  },
} as const;

export const SwitchButton = styled.button.attrs({
  type: 'button',
  role: 'switch',
})`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: inherit;
` as unknown as React.ForwardRefExoticComponent<
  React.ButtonHTMLAttributes<HTMLButtonElement> &
    React.RefAttributes<HTMLButtonElement>
>;
