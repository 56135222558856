import { NodeType } from '../../../types';
import { defaultParameterMissingPolicy } from './defaultParameterMissingPolicy';
import { defaultRetryPolicy } from './defaultRetryPolicy';
import { destinationactions } from '@morf/proto/destinationactions/morf/v1/morf_ts_proto';
import { newNodeData } from './types';
import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';

const defaultConvertToPatient =
  new destinationactions.morf.v1.ConvertProfileToPatient({
    mergeIfEmailAddressAlreadyExists: false,
    mergeIfPhoneNumberAlreadyExists: false,
  });

export const addProfileConvertNode = (isBranch: boolean): newNodeData => {
  return {
    configuration: new workflows.v1.WorkflowNode({
      destinationAction: new workflows.v1.WorkflowDestinationAction({
        retryPolicy: defaultRetryPolicy,
        parameterMissingPolicy: defaultParameterMissingPolicy,
        morfV1: new destinationactions.morf.v1.DestinationAction({
          convertProfileToPatient: defaultConvertToPatient,
        }),
      }),
    }),
    integrationIconName: 'morf',
    isBranchNode: isBranch,
    name: 'Convert Profile to Patient',
    type: NodeType.ProfileConvertNode,
  };
};
