"use strict";

import * as $protobuf from "protobufjs/minimal";
import { active_campaign as active_campaign$1 } from "../../../active_campaign/v1/active_campaign_ts_proto";
import { workflow_parameters as workflow_parameters$1 } from "../../../workflow_parameters/v1/workflow_parameters_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.fetchactions = (() => {
  const fetchactions = {};
  fetchactions.activecampaign = (() => {
    const activecampaign = {};
    activecampaign.v1 = (() => {
      const v1 = {};
      v1.FetchAction = (() => {
        class FetchAction {
          get type() {
            for (const key of ["getContactDeals", "createOrUpdateAccount"]) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set type(name) {
            for (const key of ["getContactDeals", "createOrUpdateAccount"]) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new FetchAction(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.getContactDeals != null && Object.hasOwnProperty.call(message, "getContactDeals")) {
              $root.fetchactions.activecampaign.v1.GetContactDeals.encode(message.getContactDeals, writer.uint32(10).fork()).ldelim();
            }
            if (message.createOrUpdateAccount != null && Object.hasOwnProperty.call(message, "createOrUpdateAccount")) {
              $root.fetchactions.activecampaign.v1.CreateOrUpdateAccount.encode(message.createOrUpdateAccount, writer.uint32(18).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new FetchAction();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.getContactDeals = $root.fetchactions.activecampaign.v1.GetContactDeals.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.createOrUpdateAccount = $root.fetchactions.activecampaign.v1.CreateOrUpdateAccount.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.activecampaign.v1.FetchAction) {
              return object;
            }
            const message = new $root.fetchactions.activecampaign.v1.FetchAction();
            if (object.getContactDeals != null) {
              if (typeof object.getContactDeals !== "object") {
                throw new TypeError(".fetchactions.activecampaign.v1.FetchAction.getContactDeals: object expected, but got " + (typeof object.getContactDeals));
              }
              message.getContactDeals = $root.fetchactions.activecampaign.v1.GetContactDeals.fromObject(object.getContactDeals);
            }
            if (object.createOrUpdateAccount != null) {
              if (typeof object.createOrUpdateAccount !== "object") {
                throw new TypeError(".fetchactions.activecampaign.v1.FetchAction.createOrUpdateAccount: object expected, but got " + (typeof object.createOrUpdateAccount));
              }
              message.createOrUpdateAccount = $root.fetchactions.activecampaign.v1.CreateOrUpdateAccount.fromObject(object.createOrUpdateAccount);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.getContactDeals != null && message.hasOwnProperty("getContactDeals")) {
              object.getContactDeals = $root.fetchactions.activecampaign.v1.GetContactDeals.toObject(message.getContactDeals, options);
              if (options.oneofs) {
                object.type = "getContactDeals";
              }
            }
            if (message.createOrUpdateAccount != null && message.hasOwnProperty("createOrUpdateAccount")) {
              object.createOrUpdateAccount = $root.fetchactions.activecampaign.v1.CreateOrUpdateAccount.toObject(message.createOrUpdateAccount, options);
              if (options.oneofs) {
                object.type = "createOrUpdateAccount";
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.activecampaign.v1.FetchAction";
          }
        }

        FetchAction.prototype.getContactDeals = null;
        FetchAction.prototype.createOrUpdateAccount = null;

        return FetchAction;
      })();

      v1.GetContactDeals = (() => {
        class GetContactDeals {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            this.result = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new GetContactDeals(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              for (const element of message.result) {
                active_campaign$1.v1.Deal.encode(element, writer.uint32(34).fork()).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new GetContactDeals();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  if (!message.result || !message.result.length) {
                    message.result = [];
                  }
                  message.result.push(active_campaign$1.v1.Deal.decode(reader, reader.uint32()));
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.activecampaign.v1.GetContactDeals) {
              return object;
            }
            const message = new $root.fetchactions.activecampaign.v1.GetContactDeals();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.activecampaign.v1.GetContactDeals.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.activecampaign.v1.GetContactDeals.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.activecampaign.v1.GetContactDeals.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.activecampaign.v1.GetContactDeals.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result) {
              if (!Array.isArray(object.result)) {
                throw new TypeError(".fetchactions.activecampaign.v1.GetContactDeals.result: array type expected, but got " + (typeof object.result))
              }
              message.result = new Array(object.result.length);
              for (let i = 0; i < object.result.length; ++i) {
                if (typeof object.result[i] !== "object") {
                  throw new TypeError(".fetchactions.activecampaign.v1.GetContactDeals.result: object expected, but got " + (typeof object.result[i]));
                }
                message.result[i] = active_campaign$1.v1.Deal.fromObject(object.result[i]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
              object.result = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.result && message.result.length) {
              object.result = new Array(message.result.length);
              for (let i = 0; i < message.result.length; ++i) {
                object.result[i] = active_campaign$1.v1.Deal.toObject(message.result[i], options);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.activecampaign.v1.GetContactDeals";
          }
        }

        GetContactDeals.prototype.params = $util.emptyArray;
        GetContactDeals.prototype.configs = $util.emptyArray;
        GetContactDeals.prototype.requiredThirdPartyIdMissingBehavior = 0;
        GetContactDeals.prototype.result = $util.emptyArray;

        return GetContactDeals;
      })();

      v1.CreateOrUpdateAccount = (() => {
        class CreateOrUpdateAccount {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateOrUpdateAccount(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              active_campaign$1.v1.Account.encode(message.result, writer.uint32(34).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateOrUpdateAccount();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.result = active_campaign$1.v1.Account.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.activecampaign.v1.CreateOrUpdateAccount) {
              return object;
            }
            const message = new $root.fetchactions.activecampaign.v1.CreateOrUpdateAccount();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.activecampaign.v1.CreateOrUpdateAccount.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.activecampaign.v1.CreateOrUpdateAccount.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.activecampaign.v1.CreateOrUpdateAccount.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.activecampaign.v1.CreateOrUpdateAccount.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.activecampaign.v1.CreateOrUpdateAccount.result: object expected, but got " + (typeof object.result));
              }
              message.result = active_campaign$1.v1.Account.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = active_campaign$1.v1.Account.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.activecampaign.v1.CreateOrUpdateAccount";
          }
        }

        CreateOrUpdateAccount.prototype.params = $util.emptyArray;
        CreateOrUpdateAccount.prototype.configs = $util.emptyArray;
        CreateOrUpdateAccount.prototype.requiredThirdPartyIdMissingBehavior = 0;
        CreateOrUpdateAccount.prototype.result = null;

        return CreateOrUpdateAccount;
      })();

      return v1;
    })();

    return activecampaign;
  })();

  return fetchactions;
})();

export const fetchactions = $root.fetchactions;
