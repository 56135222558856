import { CustomNode } from '../../types';
import { Edge } from 'reactflow';
import { ProfileLookupNodeType } from '../types';
import { profiles } from '@morf/proto/profiles/v1/profiles_ts_proto';
import { workflows } from '@morf/proto/workflows/v1/workflows_ts_proto';

export const profileLookupSubflowProfileNotFoundAction = (
  configuration: profiles.v1.LookupConfiguration | null | undefined,
  lookupsLength: number
): {
  nodes: CustomNode[];
  edges: Edge[];
} => {
  let nodes: CustomNode[] = [];
  let edges: Edge[] = [];

  switch (configuration?.noProfileFoundAction) {
    case profiles.v1.NoProfileFoundAction.NO_PROFILE_FOUND_ACTION_CREATE_LEAD:
      nodes.push({
        id: `lookup-subflow-profile-not-found-action`,
        type: ProfileLookupNodeType.ActionNode,
        data: {
          value: {
            name: 'create lead',
            title: 'Create lead',
            configuration: new workflows.v1.WorkflowNode({}),
          },
        },
        parentNode: `lookup-subflow-profile-not-found-${lookupsLength - 1}`,
        position: { x: 0, y: 100 },
      });

      edges.push({
        id: `lookup-subflow-profile-not-found-action-lookup-subflow-return-to-workflow`,
        source: `lookup-subflow-profile-not-found-action`,
        target: `lookup-subflow-return-to-workflow`,
      });

      break;
    case profiles.v1.NoProfileFoundAction
      .NO_PROFILE_FOUND_ACTION_CREATE_PATIENT:
      nodes.push({
        id: `lookup-subflow-profile-not-found-action`,
        type: ProfileLookupNodeType.ActionNode,
        data: {
          value: {
            name: 'create patient',
            title: 'Create patient',
            configuration: new workflows.v1.WorkflowNode({}),
          },
        },
        parentNode: `lookup-subflow-profile-not-found-${lookupsLength - 1}`,
        position: { x: 0, y: 100 },
      });

      edges.push({
        id: `lookup-subflow-profile-not-found-action-lookup-subflow-return-to-workflow`,
        source: `lookup-subflow-profile-not-found-action`,
        target: `lookup-subflow-return-to-workflow`,
      });

      break;
    case profiles.v1.NoProfileFoundAction.NO_PROFILE_FOUND_ACTION_ERROR:
      nodes.push({
        id: `lookup-subflow-profile-not-found-action`,
        type: ProfileLookupNodeType.ErrorNode,
        data: {
          value: {
            name: 'error',
            title: 'Error',
            configuration: new workflows.v1.WorkflowNode({}),
          },
        },
        parentNode: `lookup-subflow-profile-not-found-${lookupsLength - 1}`,

        position: { x: 0, y: 100 },
      });
      break;
    case profiles.v1.NoProfileFoundAction
      .NO_PROFILE_FOUND_ACTION_TERMINATE_WORKFLOW_GRACEFULLY:
      nodes.push({
        id: `lookup-subflow-profile-not-found-action`,
        type: ProfileLookupNodeType.TerminateNode,
        data: {
          value: {
            name: 'terminate',
            title: 'Terminate',
            configuration: new workflows.v1.WorkflowNode({}),
          },
        },
        parentNode: `lookup-subflow-profile-not-found-${lookupsLength - 1}`,
        position: { x: 0, y: 100 },
      });
      break;
    case profiles.v1.NoProfileFoundAction.NO_PROFILE_FOUND_ACTION_RETRY:
      nodes.push({
        id: `lookup-subflow-profile-not-found-action`,
        type: ProfileLookupNodeType.RetryNode,
        data: {
          value: {
            name: 'retry',
            title: 'Retry',
            configuration: new workflows.v1.WorkflowNode({}),
          },
        },
        parentNode: `lookup-subflow-profile-not-found-${lookupsLength - 1}`,
        position: { x: 0, y: 100 },
      });
      break;
  }
  return { nodes, edges };
};
