export const Stedi = () => {
  return (
    <svg
      width='41'
      height='40'
      viewBox='0 0 41 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_2034_48442'
        maskUnits='userSpaceOnUse'
        x='3'
        y='14'
        width='35'
        height='13'
      >
        <path d='M38 14H3V26.25H38V14Z' fill='white' />
      </mask>
      <g mask='url(#mask0_2034_48442)'>
        <path
          d='M6.19375 24.325C6.80625 24.325 7.33125 24.1062 7.33125 23.5375C7.33125 23.1437 7.06875 22.925 6.5875 22.7938L5.5375 22.5312C3.875 22.0938 3.2625 21.2625 3.2625 20.2125C3.2625 18.8125 4.3125 17.7188 6.2375 17.7188C7.76875 17.7188 8.99375 18.4625 9.2125 19.775L7.375 20.2125C7.33125 19.6875 6.85 19.3813 6.28125 19.3813C5.66875 19.3813 5.275 19.6875 5.275 20.1688C5.275 20.5625 5.5375 20.7812 6.15 20.9562L7.15625 21.2188C8.6 21.6125 9.34375 22.4 9.34375 23.5375C9.34375 24.9812 8.20625 25.9437 6.19375 25.9437C4.225 25.9437 3.175 24.9813 3 23.7125L4.88125 23.4062C4.96875 23.975 5.45 24.325 6.19375 24.325Z'
          fill='#181819'
        />
        <path
          d='M10.4375 15.5312V17.9375V19.6438V25.7688H12.45V19.6438H13.9375V17.9375H12.45V15.5312H10.4375Z'
          fill='#181819'
        />
        <path
          d='M16.4751 21.175H20.2376C20.1501 19.9938 19.4501 19.425 18.4438 19.425C17.3501 19.425 16.6938 20.0375 16.4751 21.175ZM22.3376 22.575H16.4751C16.6063 23.7563 17.5251 24.1938 18.4438 24.1938C19.4063 24.1938 19.9751 23.7563 20.3251 23.0563L22.0313 23.7125C21.4626 25.0688 20.0626 25.9 18.4438 25.9C16.1251 25.9 14.5063 24.2813 14.5063 21.7875C14.5063 19.3375 16.0813 17.6313 18.4438 17.6313C20.7626 17.6313 22.3376 19.25 22.3376 21.7875C22.3376 21.875 22.3376 22.575 22.3376 22.575Z'
          fill='#181819'
        />
        <path
          d='M35.5938 17.9375H33.5376V25.725H35.5938V17.9375Z'
          fill='#181819'
        />
        <path
          d='M27.6312 24.1062C26.4062 24.1062 25.4875 23.2312 25.4875 21.8312C25.4875 20.4312 26.4062 19.5563 27.6312 19.5563C28.9 19.5563 29.775 20.4312 29.775 21.8312C29.775 23.2312 28.9 24.1062 27.6312 24.1062ZM31.875 23.7562V22.1813V14H29.8188V18.7687C29.25 18.025 28.2437 17.7188 27.325 17.7188C25.05 17.7188 23.3438 19.3375 23.3438 21.875C23.3438 24.5875 25.0063 25.9875 27.2812 25.9875C28.3313 25.9875 29.3375 25.55 29.8625 24.8063C29.9063 25.2 29.9062 25.5062 29.9937 25.7688H31.875V23.7562Z'
          fill='#181819'
        />
        <path
          d='M35.588 14.0007L35.582 16.057L37.6383 16.0629L37.6442 14.0067L35.588 14.0007Z'
          fill='#4AD2C2'
        />
      </g>
    </svg>
  );
};
