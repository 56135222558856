import _ from 'lodash';
import { getObjectTypeIdFieldType } from './SelectValuePairModal/getObjectTypeIdFieldType';
import { google } from '@morf/proto/checked_ts_proto';
import { hasField } from '../../../../Helpers/hasField';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';

export const formatStaticDestinationActionParameterType = (
  fieldType:
    | workflow_parameters.v1.StaticDestinationActionParameterType
    | null
    | undefined,
  objectTypeIdContext:
    | workflow_parameters.v1.ObjectTypeIDContext
    | null
    | undefined
): string => {
  if (fieldType) {
    if (objectTypeIdContext) {
      return getObjectTypeIdFieldType(objectTypeIdContext);
    }

    if (hasField(fieldType, 'primitiveType')) {
      return _.toLower(
        google.api.expr.v1alpha1.Type.PrimitiveType[fieldType.primitiveType]
      );
    }

    if (hasField(fieldType, 'wellKnownType')) {
      return _.toLower(
        google.api.expr.v1alpha1.Type.WellKnownType[fieldType.wellKnownType]
      );
    }

    if (hasField(fieldType, 'extendedType')) {
      return _.toLower(
        workflow_parameters.v1.ExtendedType.Type[fieldType.extendedType]
      );
    }
  }
  return '';
};
