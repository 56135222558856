import { ActiveCampaign } from './Icons/ActiveCampaign';
import { Acuity } from './Icons/Acuity';
import { AxleHealth } from './Icons/AxleHealth';
import { ButterflyLabs } from './Icons/ButterflyLabs';
import { Calendly } from './Icons/Calendly';
import { CustomerIO } from './Icons/CustomerIO';
import { Facebook } from './Icons/Facebook';
import { Feathery } from './Icons/Feathery';
import { Formsort } from './Icons/Formsort';
import { Freshdesk } from './Icons/Freshdesk';
import { Freshpaint } from './Icons/Freshpaint';
import { Google } from './Icons/Google';
import { Healthie } from './Icons/Healthie';
import { Hubspot } from './Icons/Hubspot';
import { IntakeQ } from './Icons/IntakeQ';
import { Intercom } from './Icons/Intercom';
import { Junction } from './Icons/Junction';
import { Mixpanel } from './Icons/Mixpanel';
import { Morf, MorfBlack, MorfGreen, MorfLavender } from './Icons/Morf';
import { NextDoor } from './Icons/NextDoor';
import { ReactNode, useMemo } from 'react';
import { Recurly } from './Icons/Recurly';
import { SanaBenefits } from './Icons/SanaBenefits';
import { Slack } from './Icons/Slack';
import { Spruce } from './Icons/Spruce';
import { Stedi } from './Icons/Stedi';
import { Stripe } from './Icons/Stripe';
import { Tiktok } from './Icons/Tiktok';
import { Twilio } from './Icons/Twilio';
import { useIsBerrySt } from '../Hooks/useIsBerrySt';

export const IntegrationIconMapping = (): { [key: string]: ReactNode } => {
  const isBerrySt = useIsBerrySt();

  return useMemo(
    () => ({
      acuity: <Acuity />,
      'active-campaign': <ActiveCampaign />,
      'axle-health': <AxleHealth />,
      'butterfly-labs': <ButterflyLabs />,
      calendly: <Calendly />,
      customer: <Morf />,
      'customer-io': isBerrySt ? <MorfLavender /> : <CustomerIO />,
      facebook: <Facebook />,
      feathery: <Feathery />,
      formsort: <Formsort />,
      freshdesk: <Freshdesk />,
      freshpaint: <Freshpaint />,
      google: <Google />,
      healthie: <Healthie />,
      hubspot: <Hubspot />,
      intakeq: <IntakeQ />,
      intercom: <Intercom />,
      junction: <Junction />,
      mixpanel: <Mixpanel />,
      morf: <Morf />,
      nextdoor: <NextDoor />,
      'push-json': <MorfBlack />,
      'sana-benefits': <SanaBenefits />,
      recurly: <Recurly />,
      segment: <MorfGreen />,
      slack: <Slack />,
      spruce: <Spruce />,
      stedi: <Stedi />,
      stripe: <Stripe />,
      tiktok: <Tiktok />,
      twilio: <Twilio />,
    }),
    [isBerrySt]
  );
};
