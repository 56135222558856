import { BadgeType } from '../Badge';
import { DotBadge } from '../DotBadge';
import { ErrorClassProps } from './types';
import { FC, memo } from 'react';
import { workflow_evaluation_results } from '@morf/proto/workflow_evaluation_results/v1/workflow_evaluation_results_ts_proto';

const ErrorClass: FC<ErrorClassProps> = ({ errorClass }) => {
  const errorClassMap: Record<
    workflow_evaluation_results.v1.ErrorClass,
    BadgeType
  > = {
    [workflow_evaluation_results.v1.ErrorClass
      .UNSPECIFIED_ILLEGAL_WORKFLOW_ERROR_CLASS]: 'inactive',
    [workflow_evaluation_results.v1.ErrorClass.ERROR]: 'alert',
    [workflow_evaluation_results.v1.ErrorClass.WARNING]: 'warning',
    [workflow_evaluation_results.v1.ErrorClass.INTERNAL_ERROR]: 'internal',
  };

  return <DotBadge type={errorClassMap[errorClass]} />;
};

export const MemoizedErrorClass = memo(ErrorClass);
