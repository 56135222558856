import { values } from '@morf/proto/values/v1/values_ts_proto';

export const formatValueDate = (
  date: values.v1.IDate | null | undefined
): string => {
  if (!date || !date.year || !date.month || !date.day) {
    return 'n/a';
  }
  return [date.month, date.day, date.year].filter(Boolean).join('/');
};
