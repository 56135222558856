import { ThemeContainerProps } from '@morf/theming/types';
import { styled } from '@morf/theming';
import React from 'react';

export interface EditorContainerProps {
  height?: string;
  width?: string;
  theme: ThemeContainerProps;
  children?: React.ReactNode;
  minHeight?: string;
}

export const EditorContainer = styled.div<EditorContainerProps>`
  display: flex;
  height: ${(props) => props.height || '300px'};
  width: ${(props) => props.width || '100%'};
  border: ${(props) => props.theme.borderWidth[0.0625]} solid
    ${(props) => props.theme.colors.ui.divider};
  border-radius: ${(props) => props.theme.borderRadius[0.25]};
  overflow: hidden;
  font-family: apercu-regular-pro;
  min-height: ${(props) => props.minHeight || '100%'};
`;

export const LineNumbers = styled.div<{
  theme: ThemeContainerProps;
  children?: React.ReactNode;
}>`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing[0.5]}
    ${(props) => props.theme.spacing[0.75]};
  background-color: ${(props) => props.theme.colors.ui.muted};
  color: ${(props) => props.theme.colors.text.muted};
  font-size: ${(props) => props.theme.fontSizes[0.875]};
  text-align: right;
  user-select: none;
  overflow-y: hidden;
  min-width: 3rem;

  div {
    line-height: 1.5;
  }
`;

export const EditorWrapper = styled.div<{
  theme: ThemeContainerProps;
  children?: React.ReactNode;
  readOnly?: boolean;
}>`
  flex: 1;
  overflow: auto;
  opacity: ${(props) => (props.readOnly ? 0.7 : 1)};
  pointer-events: ${(props) => (props.readOnly ? 'none' : 'auto')};
  background-color: ${(props) =>
    props.readOnly ? props.theme.colors.ui.body : 'transparent'};

  .ProseMirror {
    height: 100%;
    outline: none;
    padding: ${(props) => props.theme.spacing[0.5]};
    font-size: ${(props) => props.theme.fontSizes[0.875]};
    line-height: 1.5;
    color: ${(props) => props.theme.colors.text.body};

    .cel-expression {
      background-color: ${(props) => props.theme.colors.ui.highlight};
      border-radius: ${(props) => props.theme.borderRadius[0.25]};
      padding: 0 ${(props) => props.theme.spacing[0.25]};
    }

    .cel-expression-hide {
      display: none;
    }

    .inline-node-wrapper {
      display: inline;
      white-space: normal;
      vertical-align: baseline;
    }

    pre {
      margin: 0;
      padding: 0;
      background: transparent;
      font-family: inherit;
    }

    code {
      font-family: inherit;
      background: transparent;
      padding: 0;
      white-space: pre;
    }

    p {
      margin: 0;
    }
  }
`;
