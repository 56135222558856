import { DefaultTheme } from '../Theme';
import { ThemeContainerProps } from '../types';
import { defaultColors } from '../ColorPalette';
import {
  defaultFontTypes,
  defaultH1,
  defaultH2,
  defaultH3,
  defaultH4,
  defaultH5,
  defaultH6,
  defaultH7,
  defaultLink,
  defaultLongform,
  defaultMaxNrOfSlots,
  defaultP1,
  defaultP2,
  defaultP3,
  defaultP4,
  defaultSubtitle,
} from '../ColorPalette/constants';

export const noma: ThemeContainerProps = {
  ...DefaultTheme,
  colors: {
    ...defaultColors,
    text: {
      body: '#6394CC',
      ...defaultColors.text,
    },
    main: {
      primary: {
        darkest: '#6394CC',
        darker: '#6394CC',
        dark: '',
        normal: '',
        light: '',
        lighter: '',
        lightest: '',
      },
      dark: {
        darkest: '#6394CC',
        darker: '',
        dark: '',
        normal: '',
        light: '',
        lighter: '',
        lightest: '',
      },
      light: {
        darkest: '',
        darker: '',
        dark: '',
        normal: '#F7F5EB',
        light: '#FDFDFD',
        lighter: '',
        lightest: '',
      },
    },
  },
  container: {
    borderType: 'border',
    borderRadius: 0.5,
  },
  contentOrder: {
    bio: 1,
    specialties: 2,
  },
  datePicker: {
    borderRadius: 1.5,
    style: 'fill',
  },
  fontTypes: {
    ...defaultFontTypes,
    h1: {
      ...defaultH1,
      family: 'ginto-nord-regular',
    },
    h2: {
      ...defaultH2,
      family: 'ginto-nord-regular',
    },
    h3: {
      ...defaultH3,
      family: 'ginto-nord-regular',
    },
    h4: {
      ...defaultH4,
      family: 'ginto-nord-regular',
    },
    h5: {
      ...defaultH5,
      family: 'ginto-nord-regular',
    },
    h6: {
      ...defaultH6,
      family: 'ginto-nord-regular',
    },
    h7: {
      ...defaultH7,
      family: 'ginto-nord-regular',
    },
    p1: {
      ...defaultP1,
      family: 'ginto-nord-regular',
    },
    p2: {
      ...defaultP2,
      family: 'ginto-nord-regular',
    },
    p3: {
      ...defaultP3,
      family: 'ginto-nord-regular',
    },
    p4: {
      ...defaultP4,
      family: 'ginto-nord-regular',
    },
    subtitle: {
      ...defaultSubtitle,
      family: 'ginto-nord-regular',
    },
    longform: {
      ...defaultLongform,
      family: 'ginto-nord-regular',
    },
    link: {
      ...defaultLink,
      family: 'ginto-nord-regular',
      decoration: 'underline',
    },
  },
  input: { borderRadius: 0.5 },
  maxNrOfSlots: defaultMaxNrOfSlots,
  timePicker: {
    borderRadius: 0.5,
    style: 'lightFill',
  },
  tooltip: {
    borderRadius: 0.5,
    type: 'dark',
  },
};
