import { ActionItem } from './ActionList/types';
import { NodeType } from '../../../types';
import { addDestinationActionNode } from './addDestinationActionNode';
import { addFetchActionNode } from './addFetchActionNode';
import { addFilterNode } from './addFilterNode';
import { addPauseNode } from './addPauseNode';
import { addProfileConvertNode } from './addProfileConvertNode';
import { addProfileUpdateNode } from './addProfileUpdateNode';
import { addRestartNode } from './addRestartNode';
import { addTrackProfileProperties } from './addTrackProfileProperties';
import { newNodeData } from './types';

export const generateNewNodeData = (
  nodeType: NodeType,
  isBranch: boolean,
  index: number,
  action?: ActionItem
): newNodeData | undefined => {
  switch (nodeType) {
    case NodeType.DestinationActionNode:
      if (!action) {
        return undefined;
      }
      if (
        action.integrationIconName === 'morf' &&
        action.action === 'convertProfileToPatient'
      ) {
        return addProfileConvertNode(isBranch);
      } else if (
        action.integrationIconName === 'morf' &&
        action.action === 'updateProfileProperties'
      ) {
        return addProfileUpdateNode(isBranch);
      } else if (
        action.integrationIconName === 'morf' &&
        action.action === 'trackProfileProperties'
      ) {
        return addTrackProfileProperties(isBranch);
      } else {
        return addDestinationActionNode(action, isBranch);
      }
    case NodeType.FetchActionNode:
      if (!action) {
        return undefined;
      }
      return addFetchActionNode(action, isBranch, index);
    case NodeType.FilterNode:
      return addFilterNode(isBranch);
    case NodeType.PauseNode:
      return addPauseNode(isBranch);
    case NodeType.RestartNode:
      return addRestartNode(isBranch);
  }
};
