import { convertToKebabCase } from '@morf/ui';
import { source_application } from '@morf/proto/source_application/v2/source_application_ts_proto';

export const formatSourceApplicationIcon = (
  applicationType: source_application.v2.SourceApplication.Application
): string => {
  return convertToKebabCase(
    source_application.v2.SourceApplication.Application[applicationType]
  );
};
