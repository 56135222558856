import { SelectValuePairCategory } from './types';
import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';

export const getObjectTypeIdValuePairCategory = (
  selectedParam:
    | workflow_parameters.v1.DestinationActionParameter
    | null
    | undefined
): string =>
  selectedParam?.static?.objectTypeIdContext?.name ||
  selectedParam?.dynamic?.objectTypeIdContext?.name ||
  SelectValuePairCategory.ObjectTypeIds;
