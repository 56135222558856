import { subscription_topics } from '@morf/proto/subscription_topics/v1/subscription_topics_ts_proto';

export const updateGlobalEmailPreference = (
  preferences: subscription_topics.v1.GetProfileSubscriptionTopicPreferencesResponse,
  checked: boolean
): subscription_topics.v1.GetProfileSubscriptionTopicPreferencesResponse => {
  const newPreferences = structuredClone(preferences);

  if (
    newPreferences.subscriptionTopicPreferences?.globalSubscriptionPreferences
  ) {
    newPreferences.subscriptionTopicPreferences.globalSubscriptionPreferences.emailSubscribed =
      !checked;
  }

  return newPreferences;
};

export const updateTopicPreference = (
  preferences: subscription_topics.v1.GetProfileSubscriptionTopicPreferencesResponse,
  topicId: string,
  mediaType: 'emailSubscribed' | 'smsSubscribed',
  checked: boolean
): subscription_topics.v1.GetProfileSubscriptionTopicPreferencesResponse => {
  const newPreferences = structuredClone(preferences);
  const topic =
    newPreferences.subscriptionTopicPreferences?.subscriptionTopicPreferences?.find(
      (t) => t.topic?.id === topicId
    );

  if (topic?.mediaTypeSubscriptionPreferences) {
    topic.mediaTypeSubscriptionPreferences[mediaType] = checked;
  }

  return newPreferences;
};
