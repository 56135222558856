import React, { ReactNode } from 'react';
import { styled } from '@morf/theming';
import { Container } from '../Container';
import { Flexbox, FlexItem } from '../Flexbox';
import { Text } from '../Typography/Text';

export interface LandingPageProps {
  /**
   * Main title text for the landing page
   * Can be a string or a ReactNode for custom rendering
   */
  title: ReactNode;
  /**
   * Description text that appears below the title
   * Can be a string or a ReactNode for custom rendering
   */
  description: ReactNode;
  /**
   * Optional component to render as a call-to-action below the description
   */
  ctaComponent?: ReactNode;
  /**
   * Optional image or video to show on the right side
   */
  heroContent?: ReactNode;
  /**
   * Optional maximum width for the content container
   * @default '1200px'
   */
  maxWidth?: string;
}

export const LandingPage: React.FC<LandingPageProps> = ({
  title,
  description,
  ctaComponent,
  heroContent,
  maxWidth = '1200px',
}) => {
  return (
    <Container p={2} width='100%' height='100%'>
      <Flexbox
        alignItems='center'
        justifyContent='center'
        width='100%'
        height='100%'
      >
        <ContentContainer
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          maxWidth={maxWidth}
          gap={1}
        >
          <ContentSide>
            <Flexbox
              direction='column'
              justifyContent='flex-end'
              alignItems='flex-start'
              height='100%'
            >
              {typeof title === 'string' ? (
                <Text tag='h1' color='textPrimary'>
                  {title}
                </Text>
              ) : (
                title
              )}
              <Container>
                {typeof description === 'string' ? (
                  <Text tag='p1' color='textSecondary'>
                    {description}
                  </Text>
                ) : (
                  description
                )}
              </Container>
              {ctaComponent && <Container>{ctaComponent}</Container>}
            </Flexbox>
          </ContentSide>
          <ContentSide>
            {heroContent ? heroContent : <PlaceholderContent />}
          </ContentSide>
        </ContentContainer>
      </Flexbox>
    </Container>
  );
};

const ContentContainer = styled(Flexbox)<{ maxWidth: string }>`
  max-width: ${({ maxWidth }) => maxWidth};
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

const ContentSide = styled(FlexItem)`
  flex: 0 0 calc(50% - 1.5rem);
  width: calc(50% - 1.5rem);

  @media (max-width: 768px) {
    flex: 1;
    width: 100%;
  }
`;

const PlaceholderContent = styled.div`
  width: 100%;
  height: 100%;
  min-height: 300px;
  background-color: #f1f1f4;
  border-radius: 0.5rem;
`;

export default LandingPage;
