import { styled } from '@morf/theming';
import { Text } from '../Typography';
import { Checkbox } from '../Checkbox';
import { Switch } from '../Switch';

export const Grid = styled.div<{ children?: React.ReactNode }>`
  display: grid;
  grid-template-columns: 1fr 80px 80px;
  gap: 1.5rem;
  width: 100%;
  max-width: 800px;
`;

export const GridHeader = styled.div<{ children?: React.ReactNode }>`
  text-align: center;
  color: ${({ theme }) => theme.colors.text.muted};

  &:first-child {
    text-align: left;
  }
`;

export const GridRow = styled.div<{
  isUnsubscribeRow?: boolean;
  children?: React.ReactNode;
}>`
  display: contents;

  ${({ isUnsubscribeRow }) =>
    isUnsubscribeRow &&
    `
    border: 1px solid red;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #eee;
  `}
`;

export const TopicRow = styled(GridRow)`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.ui.divider};
  }
`;

export const GridCell = styled.div<{
  isFirst?: boolean;
  isDisabled?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}>`
  display: flex;
  align-items: center;

  ${({ isFirst, theme }) =>
    isFirst &&
    `
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.spacing[0.25]};
  `}

  ${({ isFirst }) =>
    !isFirst &&
    `
    justify-content: center;
  `}

  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  `}
`;

export const Description = styled(Text)`
  color: ${({ theme }) => theme.colors.text.muted};
  font-size: ${({ theme }) => theme.fontSizes[0.875]};
`;

export const UnsubscribeGrid = styled(Grid)`
  grid-template-columns: 1fr auto;
`;

export const UnsubscribeSection = styled.div`
  width: 100%;
  max-width: 800px;
  background-color: ${({ theme }) => theme.colors.ui.body};
  border-radius: ${({ theme }) => theme.borderRadius[0.5]};

  ${GridCell} {
    padding: ${({ theme }) => theme.spacing[1]};
  }
`;

export const StyledCheckbox = styled(Checkbox)<{ isDisabled?: boolean }>`
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
`;

export const StyledSwitch = styled(Switch)<{ isDisabled?: boolean }>`
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
`;
