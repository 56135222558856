import Long from 'long';
import { TimeRange } from './types';
import { timing } from '@morf/proto/timing/v1/timing_ts_proto';

export const calculateTimeRange = (date: Date, hours: number): TimeRange => {
  const seconds = Math.floor(date.getTime() / 1000);
  const nanoseconds = date.getMilliseconds() * 1e6;

  return {
    startTimestamp: new timing.v1.Timestamp({
      seconds: Long.fromNumber(seconds - hours * 3600),
      nanoseconds,
    }),
    endTimestamp: new timing.v1.Timestamp({
      seconds: Long.fromNumber(seconds),
      nanoseconds,
    }),
  };
};
