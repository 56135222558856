import { FC, memo, useEffect, useState } from 'react';
import { FilterMenu } from '../FilterMenu';
import { FilterMenuOption } from '../FilterMenu/FilterMenuOption/types';
import { Flexbox } from '../Flexbox';
import { Panel } from './Panel';
import { ReadOnlyBadge } from '../ReadOnlyBadge';
import { Search } from './Search';
import { ToolbarProps } from './types';
import { TopPanel } from './TopPanel';
import { WorkflowFilter } from '../../../apps/admin/components/shared/Filters/WorkflowFilter';
import { getMatchingTimeOption, TimeFilter } from '../Filters';
import { useTheme } from '@morf/theming';
import { useUrlParams } from '../../../apps/admin/components/helpers/useUrlParams';

const Toolbar: FC<ToolbarProps> = ({
  borderType,
  filterMenuOptions = [],
  isPanelOpen,
  isSearchDisabled,
  isTopPanelOpen,
  searchPlaceholder,
  searchQueryName,
  searchTooltipText,
  searchValue,
  setIsPanelOpen,
  setIsTopPanelOpen,
  setSearchValue,
  showReadOnly = false,
  showTimeFilter = false,
  showWorkflowFilter = false,
  time,
  ...props
}) => {
  const theme = useTheme();
  const { workflowIds } = useUrlParams();

  const timeOption = getMatchingTimeOption(time);
  const hasWorkflowIds = !!workflowIds?.length;

  const [isTimeFilterSelected, setIsTimeFilterSelected] =
    useState<boolean>(false);
  const [isWorkflowFilterSelected, setIsWorkflowFilterSelected] =
    useState<boolean>(hasWorkflowIds);

  const updatedFilterMenuOptions: FilterMenuOption[] = [
    ...(showTimeFilter
      ? [
          {
            component: <TimeFilter timeOption={timeOption} />,
            isDisabled: false,
            isSelected: isTimeFilterSelected,
            label: 'Time',
            setIsSelected: setIsTimeFilterSelected,
          },
        ]
      : []),
    ...(showWorkflowFilter
      ? [
          {
            component: (
              <WorkflowFilter
                setIsWorkflowFilterSelected={setIsWorkflowFilterSelected}
              />
            ),
            isDisabled: false,
            isSelected: isWorkflowFilterSelected,
            label: 'Workflow',
            setIsSelected: setIsWorkflowFilterSelected,
          },
        ]
      : []),
    ...filterMenuOptions,
  ];

  const selectedFilterMenuOptions = updatedFilterMenuOptions.filter(
    ({ isSelected }) => isSelected
  );

  const unSelectedFilterMenuOptions = updatedFilterMenuOptions.filter(
    ({ isSelected }) => !isSelected
  );

  const handleClick = (filterOption: FilterMenuOption) => {
    filterOption.setIsSelected(true);
  };

  useEffect(() => {
    setIsTimeFilterSelected(!!time);
  }, [time]);

  return (
    <Flexbox
      data-testid='toolbar'
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      backgroundColor={theme.colors.ui.card}
      height='2.25rem'
      borderType={borderType}
      py={0.5}
      px={1}
      {...props}
    >
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        gap={0.5}
      >
        {isPanelOpen !== undefined && setIsPanelOpen && (
          <Panel isPanelOpen={isPanelOpen} setIsPanelOpen={setIsPanelOpen} />
        )}

        {isTopPanelOpen !== undefined && setIsTopPanelOpen && (
          <TopPanel
            isTopPanelOpen={isTopPanelOpen}
            setIsTopPanelOpen={setIsTopPanelOpen}
          />
        )}

        {searchValue !== undefined &&
          searchQueryName &&
          searchTooltipText &&
          setSearchValue && (
            <Search
              isSearchDisabled={isSearchDisabled}
              searchPlaceholder={searchPlaceholder}
              searchQueryName={searchQueryName}
              searchTooltipText={searchTooltipText}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          )}

        <FilterMenu
          filterMenuOptions={unSelectedFilterMenuOptions}
          onClick={handleClick}
        />

        <>{selectedFilterMenuOptions.map(({ component }) => component)}</>
      </Flexbox>

      {showReadOnly && <ReadOnlyBadge />}
    </Flexbox>
  );
};

export const MemoizedToolbar = memo(Toolbar);
