import { workflow_parameters } from '@morf/proto/workflow_parameters/v1/workflow_parameters_ts_proto';
import { CelExpressionAttributes } from './CelExpression';
import {
  deserializeParameterSource,
  serializeParameterSource,
  getLabelFromParameterSource,
} from './CelExpressionUtils';

/**
 * Generates a random alphabetical ID
 * @returns Random alphabetical string
 */
const generateAlphaId = (): string => {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = 0; i < 8; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
};

/**
 * Pattern to match CEL expression spans in HTML content
 */
const CEL_EXPRESSION_PATTERN =
  /<span[^>]*data-cel-expression="true"[^>]*data-id="([^"]*)"[^>]*class="cel-expression"[^>]*>.*?<\/span>/g;

/**
 * Creates a TemplateSource object from template content and CEL expressions
 * Each CEL expression span is replaced with a unique placeholder
 *
 * @param templateContent - The HTML content of the template
 * @param celExpressions - Array of CEL expressions extracted from the editor (optional, used for reference)
 * @returns A TemplateSource object
 */
export const createTemplateSource = (
  templateContent: string,
  celExpressions: CelExpressionAttributes[] = []
): workflow_parameters.v1.TemplateSource => {
  let transformedContent = templateContent;
  const inputs: Record<
    string,
    workflow_parameters.v1.DestinationActionParameterSource
  > = {};

  const expressionMap = new Map<string, string>();
  celExpressions.forEach((expr) => {
    if (expr.parameterSource) {
      expressionMap.set(expr.id, expr.parameterSource);
    }
  });

  const spanMatches = Array.from(
    templateContent.matchAll(CEL_EXPRESSION_PATTERN)
  );

  for (const match of spanMatches) {
    const fullMatch = match[0];
    const exprId = match[1];

    let paramSource: string | undefined;

    const sourceMatch = fullMatch.match(/data-parameter-source="([^"]*)"/);
    if (sourceMatch && sourceMatch[1]) {
      paramSource = sourceMatch[1];
    } else if (expressionMap.has(exprId)) {
      paramSource = expressionMap.get(exprId);
    }

    if (paramSource) {
      const alphaId = generateAlphaId();
      const deserializedSource = deserializeParameterSource(paramSource);

      if (deserializedSource) {
        inputs[alphaId] = deserializedSource;

        transformedContent = transformedContent.replace(
          fullMatch,
          `{{ index .Inputs "${alphaId}" }}`
        );
      }
    }
  }

  const templateSource = new workflow_parameters.v1.TemplateSource({
    body: transformedContent,
    inputs: inputs,
  });

  return templateSource;
};

/**
 * Creates a DestinationActionParameterSource with a TemplateSource
 *
 * @param templateContent - The HTML content of the template
 * @param celExpressions - Array of CEL expressions extracted from the editor
 * @returns A DestinationActionParameterSource with templateSource
 */
export const createTemplateParameterSource = (
  templateContent: string,
  celExpressions: CelExpressionAttributes[] = []
): workflow_parameters.v1.DestinationActionParameterSource => {
  const templateSource = createTemplateSource(templateContent, celExpressions);

  return new workflow_parameters.v1.DestinationActionParameterSource({
    templateSource,
  });
};

/**
 * Converts a TemplateSource object from the backend to TipTap format with CEL expressions
 *
 * @param templateSource - The TemplateSource object from the backend
 * @returns HTML content in TipTap format with proper span elements for CEL expressions
 */
export const convertTemplateSourceToTipTapFormat = (
  templateSource: workflow_parameters.v1.TemplateSource | null | undefined
): string => {
  if (!templateSource || !templateSource.body) return '';

  let tipTapContent = templateSource.body;
  const inputs = templateSource.inputs || {};

  Object.entries(inputs).forEach(([alphaId, parameterSource]) => {
    const id = `cel-${Date.now()}-${Math.floor(Math.random() * 1000)}`;

    const parameterSourceBase64 = serializeParameterSource(parameterSource);

    const label = getLabelFromParameterSource(parameterSource);

    const spanElement = `<span data-cel-expression="true" data-parameter-source="${parameterSourceBase64}" data-id="${id}" data-label="${label}" class="cel-expression">${label}</span>`;

    tipTapContent = tipTapContent.replace(
      new RegExp(`\\{\\{\\s*index\\s+\\.Inputs\\s+"${alphaId}"\\s*\\}\\}`, 'g'),
      spanElement
    );
  });

  return tipTapContent;
};
