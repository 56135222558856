import { CustomNode } from '../types';
import { CustomNodeLabel } from '../CustomNodeLabel';
import { FC, memo } from 'react';
import { Handle, Position } from 'reactflow';

const ProfileUpdateNode: FC<CustomNode> = (props) => {
  return (
    <>
      <Handle type='target' position={Position.Top} />
      <CustomNodeLabel {...props} />
      <Handle type='source' position={Position.Bottom} />
    </>
  );
};

export const MemoizedProfileUpdateNode = memo(ProfileUpdateNode);
