import _ from 'lodash';
import { Container } from '../../../Container';
import { CustomNode } from '../types';
import { CustomNodeLabelProps } from './types';
import { FC, memo } from 'react';
import { Flexbox } from '../../../Flexbox';
import { Icon } from '../../../Icon';
import { IntegrationIcon } from '../../../IntegrationIcon';
import { StyledCustomNodeLabel } from './CustomNodeLabel.css';
import { Text } from '../../../Typography';
import { Tooltip } from '../../../Tooltip';
import { iconNameNodeTypeMap } from '../constants';
import { nodeHeight, nodeWidth } from '../../constants';
import { useTheme } from '@morf/theming';
import { useWorkflow } from '../../../../../apps/admin/components/context/workflow/useWorkflow';
import { validation } from '@morf/proto/validation/v1/validation_ts_proto';
import { workflow_monitoring } from '@morf/proto/workflow_monitoring/v2/workflow_monitoring_ts_proto';

const CustomNodeLabel: FC<CustomNodeLabelProps> = ({ data, type, id }) => {
  const theme = useTheme();
  const { selectedNode } = useWorkflow();
  const { configuration, integrationIconName, name, status, title } =
    data.value;

  const isSelected = selectedNode?.id === id;

  const iconMapping: Record<
    NonNullable<CustomNode['data']['value']['status']>,
    { iconName: string | null; stroke: string | null }
  > = {
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.UNSPECIFIED_STATUS]:
      { iconName: 'alert', stroke: theme.colors.support.red.darkest },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.PROCESSING]: {
      iconName: 'circle-circle',
      stroke: theme.colors.ui.dark,
    },
    progress: {
      iconName: 'clock',
      stroke: theme.colors.ui.dark,
    },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.EXECUTED]: {
      iconName: 'check-circle',
      stroke: theme.colors.support.green.darkest,
    },
    success: {
      iconName: 'check-circle',
      stroke: theme.colors.support.green.darkest,
    },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.ERROR]: {
      iconName: 'alert',
      stroke: theme.colors.support.red.darkest,
    },
    error: {
      iconName: 'alert',
      stroke: theme.colors.support.red.darkest,
    },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.WARNING]: {
      iconName: 'alert',
      stroke: theme.colors.support.yellow.darkest,
    },
    warning: {
      iconName: 'alert',
      stroke: theme.colors.support.yellow.darkest,
    },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.WAITING]: {
      iconName: 'circle-circle',
      stroke: '#9747FF',
    },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.TERMINATED]: {
      iconName: 'x-circle',
      stroke: theme.colors.ui.dark,
    },
    filter: {
      iconName: 'x-circle',
      stroke: theme.colors.ui.dark,
    },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.RETRYING]: {
      iconName: 'refresh-circle',
      stroke: theme.colors.ui.dark,
    },
    retry: {
      iconName: 'refresh-circle',
      stroke: theme.colors.ui.dark,
    },
    invalid: {
      iconName: 'alert',
      stroke: theme.colors.support.red.darkest,
    },
    inactive: {
      iconName: null,
      stroke: null,
    },
    default: {
      iconName: null,
      stroke: null,
    },
  };

  let validationIssues: validation.v1.WorkflowValidationIssue[] = [];
  if (
    'validationIssues' in configuration &&
    Array.isArray(configuration.validationIssues)
  ) {
    validationIssues = configuration.validationIssues;
  }

  if ('validationIssue' in configuration && configuration.validationIssue) {
    validationIssues = [configuration.validationIssue];
  }

  if (
    'extraFilter' in configuration &&
    configuration.extraFilter?.validationIssue
  ) {
    validationIssues = [
      new validation.v1.WorkflowValidationIssue({
        summaryTotalIssuesCount: 1,
      }),
    ];
  }

  const summaryTotalIssuesCount =
    validationIssues?.[0]?.summaryTotalIssuesCount;

  const summaryTotalIssuesCountIcon = summaryTotalIssuesCount && (
    <Flexbox
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      position='relative'
      height='auto'
      width='auto'
      gap={0}
    >
      <Icon
        name='circle'
        size={1.5}
        strokeWidth={1.5}
        stroke={theme.colors.support.red.darker}
        fill={theme.colors.ui.card}
      />
      <Flexbox
        position='absolute'
        top='50%'
        left='50%'
        style={{ transform: 'translate(-50%, -50%)' }}
        height='auto'
        width='auto'
      >
        <Text tag='h6' color={theme.colors.support.red.darker}>
          {validationIssues?.[0]?.summaryTotalIssuesCount}
        </Text>
      </Flexbox>
    </Flexbox>
  );

  const tooltipText =
    summaryTotalIssuesCount === 1
      ? '1 configuration problem'
      : `${summaryTotalIssuesCount} configuration problems`;

  return (
    <Tooltip
      arrowDirection='right'
      p={0.75}
      isActive={!!summaryTotalIssuesCount}
      tooltipDirection='top'
      tooltipText={tooltipText}
    >
      <StyledCustomNodeLabel
        data-testid='custom-node-label'
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        position='relative'
        status={status}
        isSelected={isSelected}
        borderRadius={0.5}
        gap={0.25}
        p={0.5}
        shadow='base'
        width={nodeWidth}
        height={nodeHeight}
      >
        <Container
          position='absolute'
          height='auto'
          width='auto'
          right='-0.5rem'
          top='-0.5rem'
        >
          {summaryTotalIssuesCount
            ? summaryTotalIssuesCountIcon
            : status &&
              iconMapping[status] &&
              iconMapping[status].iconName &&
              iconMapping[status].stroke && (
                <Icon
                  name={iconMapping[status].iconName}
                  stroke={iconMapping[status].stroke}
                  fill={theme.colors.ui.card}
                  size={1.25}
                />
              )}
        </Container>
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={0.25}
        >
          {integrationIconName && (
            <IntegrationIcon name={integrationIconName} />
          )}

          {!integrationIconName && type && iconNameNodeTypeMap[type] && (
            <Icon
              name={iconNameNodeTypeMap[type]}
              stroke={theme.colors.text.muted}
              size={1.25}
            />
          )}
          <Text tag='p3' color={theme.colors.text.muted}>
            {title}
          </Text>
        </Flexbox>
        <Text tag='p2'>{name}</Text>
      </StyledCustomNodeLabel>
    </Tooltip>
  );
};

export const MemoizedCustomNodeLabel = memo(CustomNodeLabel);
