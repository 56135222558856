import { AddOption } from './AddOption';
import { Container } from '../Container';
import { DropdownInput } from './DropdownInput';
import { DropdownOption } from './DropdownOption';
import { DropdownProps, Option } from './types';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Input } from '../Input';
import { StyledDropdown } from './Dropdown.css';
import { WrapperModal } from '../WrapperModal';
import { includesLowerCase } from '../Helpers/includesLowercase';
import { memo, useRef, useState } from 'react';
import { useTheme } from '@morf/theming';

const Dropdown: React.FC<DropdownProps> = ({
  placeholder,
  defaultOptions = [],
  options: initialOptions,
  borderType = 'border',
  onChange,
  addable = false,
  searchable = false,
  clearable = false,
  multiSelect = false,
  height = '2.5rem',
  iconName,
  iconSize,
  sortOptions = true,
  readOnly = false,
}) => {
  const theme = useTheme();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const dropdownWidth = dropdownRef.current?.clientWidth
    ? `${dropdownRef.current.clientWidth / 16}rem`
    : 'auto';
  const optionsMaxHeight = '16rem';

  const [searchInput, setSearchInput] = useState<string>('');
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [options, setOptions] = useState<Option[]>(initialOptions);
  const [selectedOptions, setSelectedOptions] =
    useState<Option[]>(defaultOptions);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: '0rem',
    left: '0rem',
  });

  const handleOptionSelect = (option: Option) => {
    const updatedSelectedOptions = multiSelect
      ? isSelected(option)
        ? selectedOptions.filter(
            (selectedOption) => selectedOption.value !== option.value
          )
        : [...selectedOptions, option]
      : [option];

    setSelectedOptions(updatedSelectedOptions);
    !multiSelect && setShowOptions(false);
    if (onChange) {
      onChange(updatedSelectedOptions);
    }
  };

  let filteredOptions = options.filter((option) =>
    includesLowerCase(option.label, searchInput)
  );

  if (sortOptions) {
    filteredOptions = filteredOptions.sort((a, b) =>
      a.label.localeCompare(b.label)
    );
  }

  const isSelected = (option: Option) => {
    return selectedOptions.some(
      (selectedOption) =>
        selectedOption.value.toLowerCase() === option.value.toLowerCase()
    );
  };

  const handleAddNewOption = (newOption: Option) => {
    setOptions([...options, newOption]);
    handleOptionSelect(newOption);
  };

  const handleShowOptions = () => {
    if (dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.top / 16 + parseFloat(height) + 0.5 + 'rem',
        left: rect.left / 16 + 'rem',
      });
      setShowOptions(true);
    }
  };

  const handleCloseOptions = () => setShowOptions(false);

  const addValue: string = searchInput.trim();
  const isDisabled: boolean =
    !addValue ||
    options.some(({ value }) => value.toLowerCase() === addValue.toLowerCase());

  return (
    <StyledDropdown
      ref={dropdownRef}
      data-testid='dropdown'
      borderRadius={theme.input.borderRadius}
      backgroundColor={theme.colors.ui.card}
      borderType={borderType}
      borderStyle='solid'
      borderWidth={0.0625}
      showOptions={showOptions}
      borderColor={
        showOptions ? theme.colors.main.primary.darker : theme.colors.ui.divider
      }
      height={height}
    >
      <DropdownInput
        placeholder={placeholder}
        selectedOptions={selectedOptions}
        showOptions={showOptions}
        setShowOptions={handleShowOptions}
        onChange={handleOptionSelect}
        multiSelect={multiSelect}
        iconName={iconName}
        iconSize={iconSize}
        readOnly={readOnly}
      />

      {showOptions && (
        <WrapperModal
          {...dropdownPosition}
          backdrop={{
            backgroundColor: 'transparent',
            cursor: 'pointer',
          }}
          width={dropdownWidth}
          onClose={handleCloseOptions}
        >
          <Flexbox
            data-testid='options-container'
            direction='column'
            borderRadius={theme.input.borderRadius}
            backgroundColor={theme.colors.ui.card}
            height='auto'
            maxHeight={optionsMaxHeight}
            overflowY='auto'
            shadow='base'
            p={0.5}
            gap={0}
          >
            {searchable && (
              <Container pb={0.5}>
                <Input
                  id='search-input'
                  value={searchInput}
                  clearable={clearable}
                  height={height}
                  onChange={(e) => setSearchInput(e.target.value)}
                  leftElement={
                    <Icon
                      name='search'
                      size={1.25}
                      stroke={theme.colors.text.muted}
                      cursor='pointer'
                    />
                  }
                  autoFocus
                />
              </Container>
            )}

            {filteredOptions.map((option, index) => (
              <DropdownOption
                key={index}
                option={option}
                isSelected={isSelected(option)}
                onChange={handleOptionSelect}
                multiSelect={multiSelect}
                readOnly={readOnly}
              />
            ))}

            {addable && !readOnly && (
              <AddOption
                isDisabled={isDisabled}
                onClick={handleAddNewOption}
                value={addValue}
              />
            )}
          </Flexbox>
        </WrapperModal>
      )}
    </StyledDropdown>
  );
};

export const MemoizedDropdown = memo(Dropdown);
