"use strict";

import * as $protobuf from "protobufjs/minimal";
import { google as google$1 } from "../../../empty_ts_proto";
import { healthie as healthie$1 } from "../../../healthie/v1/healthie_ts_proto";
import { workflow_parameters as workflow_parameters$1 } from "../../../workflow_parameters/v1/workflow_parameters_ts_proto";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.fetchactions = (() => {
  const fetchactions = {};
  fetchactions.healthie = (() => {
    const healthie = {};
    healthie.v1 = (() => {
      const v1 = {};
      v1.FetchAction = (() => {
        class FetchAction {
          get type() {
            for (const key of ["listRequestedFormCompletion", "getAppointment", "getOnboardingFlow", "createTask", "createConversation", "searchMetricEntries", "updateAppointment", "updateMetricEntry", "createOrUpdateFormAnswerGroup", "createOrUpdateInsurancePolicy", "listAppointmentsForPatient", "createOrUpdateReferringProvider", "createOrUpdateNotificationContact", "getUser", "updateTags", "updateClientSource", "createDocument", "createBillingItem", "createInvoice", "listInsurancePlans", "createPatientStripeCreditCardV2", "createAllergySensitivity", "searchUsers", "createAppointment", "createReferral", "createFamilyHistory"]) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set type(name) {
            for (const key of ["listRequestedFormCompletion", "getAppointment", "getOnboardingFlow", "createTask", "createConversation", "searchMetricEntries", "updateAppointment", "updateMetricEntry", "createOrUpdateFormAnswerGroup", "createOrUpdateInsurancePolicy", "listAppointmentsForPatient", "createOrUpdateReferringProvider", "createOrUpdateNotificationContact", "getUser", "updateTags", "updateClientSource", "createDocument", "createBillingItem", "createInvoice", "listInsurancePlans", "createPatientStripeCreditCardV2", "createAllergySensitivity", "searchUsers", "createAppointment", "createReferral", "createFamilyHistory"]) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new FetchAction(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.listRequestedFormCompletion != null && Object.hasOwnProperty.call(message, "listRequestedFormCompletion")) {
              $root.fetchactions.healthie.v1.ListRequestedFormCompletion.encode(message.listRequestedFormCompletion, writer.uint32(10).fork()).ldelim();
            }
            if (message.getAppointment != null && Object.hasOwnProperty.call(message, "getAppointment")) {
              $root.fetchactions.healthie.v1.GetAppointment.encode(message.getAppointment, writer.uint32(18).fork()).ldelim();
            }
            if (message.getOnboardingFlow != null && Object.hasOwnProperty.call(message, "getOnboardingFlow")) {
              $root.fetchactions.healthie.v1.GetOnboardingFlow.encode(message.getOnboardingFlow, writer.uint32(26).fork()).ldelim();
            }
            if (message.createTask != null && Object.hasOwnProperty.call(message, "createTask")) {
              $root.fetchactions.healthie.v1.CreateTask.encode(message.createTask, writer.uint32(34).fork()).ldelim();
            }
            if (message.createConversation != null && Object.hasOwnProperty.call(message, "createConversation")) {
              $root.fetchactions.healthie.v1.CreateConversation.encode(message.createConversation, writer.uint32(42).fork()).ldelim();
            }
            if (message.searchMetricEntries != null && Object.hasOwnProperty.call(message, "searchMetricEntries")) {
              $root.fetchactions.healthie.v1.SearchMetricEntries.encode(message.searchMetricEntries, writer.uint32(50).fork()).ldelim();
            }
            if (message.updateAppointment != null && Object.hasOwnProperty.call(message, "updateAppointment")) {
              $root.fetchactions.healthie.v1.UpdateAppointment.encode(message.updateAppointment, writer.uint32(58).fork()).ldelim();
            }
            if (message.updateMetricEntry != null && Object.hasOwnProperty.call(message, "updateMetricEntry")) {
              $root.fetchactions.healthie.v1.UpdateMetricEntry.encode(message.updateMetricEntry, writer.uint32(66).fork()).ldelim();
            }
            if (message.createOrUpdateFormAnswerGroup != null && Object.hasOwnProperty.call(message, "createOrUpdateFormAnswerGroup")) {
              $root.fetchactions.healthie.v1.CreateOrUpdateFormAnswerGroup.encode(message.createOrUpdateFormAnswerGroup, writer.uint32(74).fork()).ldelim();
            }
            if (message.createOrUpdateInsurancePolicy != null && Object.hasOwnProperty.call(message, "createOrUpdateInsurancePolicy")) {
              $root.fetchactions.healthie.v1.CreateOrUpdateInsurancePolicy.encode(message.createOrUpdateInsurancePolicy, writer.uint32(82).fork()).ldelim();
            }
            if (message.listAppointmentsForPatient != null && Object.hasOwnProperty.call(message, "listAppointmentsForPatient")) {
              $root.fetchactions.healthie.v1.ListAppointmentsForPatient.encode(message.listAppointmentsForPatient, writer.uint32(90).fork()).ldelim();
            }
            if (message.createOrUpdateReferringProvider != null && Object.hasOwnProperty.call(message, "createOrUpdateReferringProvider")) {
              $root.fetchactions.healthie.v1.CreateOrUpdateReferringProvider.encode(message.createOrUpdateReferringProvider, writer.uint32(98).fork()).ldelim();
            }
            if (message.createOrUpdateNotificationContact != null && Object.hasOwnProperty.call(message, "createOrUpdateNotificationContact")) {
              $root.fetchactions.healthie.v1.CreateOrUpdateNotificationContact.encode(message.createOrUpdateNotificationContact, writer.uint32(106).fork()).ldelim();
            }
            if (message.getUser != null && Object.hasOwnProperty.call(message, "getUser")) {
              $root.fetchactions.healthie.v1.GetUser.encode(message.getUser, writer.uint32(114).fork()).ldelim();
            }
            if (message.updateTags != null && Object.hasOwnProperty.call(message, "updateTags")) {
              $root.fetchactions.healthie.v1.UpdateTags.encode(message.updateTags, writer.uint32(122).fork()).ldelim();
            }
            if (message.updateClientSource != null && Object.hasOwnProperty.call(message, "updateClientSource")) {
              $root.fetchactions.healthie.v1.UpdateClientSource.encode(message.updateClientSource, writer.uint32(130).fork()).ldelim();
            }
            if (message.createDocument != null && Object.hasOwnProperty.call(message, "createDocument")) {
              $root.fetchactions.healthie.v1.CreateDocument.encode(message.createDocument, writer.uint32(138).fork()).ldelim();
            }
            if (message.createBillingItem != null && Object.hasOwnProperty.call(message, "createBillingItem")) {
              $root.fetchactions.healthie.v1.CreateBillingItem.encode(message.createBillingItem, writer.uint32(146).fork()).ldelim();
            }
            if (message.createInvoice != null && Object.hasOwnProperty.call(message, "createInvoice")) {
              $root.fetchactions.healthie.v1.CreateInvoice.encode(message.createInvoice, writer.uint32(154).fork()).ldelim();
            }
            if (message.listInsurancePlans != null && Object.hasOwnProperty.call(message, "listInsurancePlans")) {
              $root.fetchactions.healthie.v1.ListInsurancePlans.encode(message.listInsurancePlans, writer.uint32(162).fork()).ldelim();
            }
            if (message.createPatientStripeCreditCardV2 != null && Object.hasOwnProperty.call(message, "createPatientStripeCreditCardV2")) {
              $root.fetchactions.healthie.v1.CreatePatientStripeCreditCardV2.encode(message.createPatientStripeCreditCardV2, writer.uint32(170).fork()).ldelim();
            }
            if (message.createAllergySensitivity != null && Object.hasOwnProperty.call(message, "createAllergySensitivity")) {
              $root.fetchactions.healthie.v1.CreateAllergySensitivity.encode(message.createAllergySensitivity, writer.uint32(178).fork()).ldelim();
            }
            if (message.searchUsers != null && Object.hasOwnProperty.call(message, "searchUsers")) {
              $root.fetchactions.healthie.v1.SearchUsers.encode(message.searchUsers, writer.uint32(186).fork()).ldelim();
            }
            if (message.createAppointment != null && Object.hasOwnProperty.call(message, "createAppointment")) {
              $root.fetchactions.healthie.v1.CreateAppointment.encode(message.createAppointment, writer.uint32(194).fork()).ldelim();
            }
            if (message.createReferral != null && Object.hasOwnProperty.call(message, "createReferral")) {
              $root.fetchactions.healthie.v1.CreateReferral.encode(message.createReferral, writer.uint32(202).fork()).ldelim();
            }
            if (message.createFamilyHistory != null && Object.hasOwnProperty.call(message, "createFamilyHistory")) {
              $root.fetchactions.healthie.v1.CreateFamilyHistory.encode(message.createFamilyHistory, writer.uint32(210).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new FetchAction();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.listRequestedFormCompletion = $root.fetchactions.healthie.v1.ListRequestedFormCompletion.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.getAppointment = $root.fetchactions.healthie.v1.GetAppointment.decode(reader, reader.uint32());
                  break;
                }
                case 3: {
                  message.getOnboardingFlow = $root.fetchactions.healthie.v1.GetOnboardingFlow.decode(reader, reader.uint32());
                  break;
                }
                case 4: {
                  message.createTask = $root.fetchactions.healthie.v1.CreateTask.decode(reader, reader.uint32());
                  break;
                }
                case 5: {
                  message.createConversation = $root.fetchactions.healthie.v1.CreateConversation.decode(reader, reader.uint32());
                  break;
                }
                case 6: {
                  message.searchMetricEntries = $root.fetchactions.healthie.v1.SearchMetricEntries.decode(reader, reader.uint32());
                  break;
                }
                case 7: {
                  message.updateAppointment = $root.fetchactions.healthie.v1.UpdateAppointment.decode(reader, reader.uint32());
                  break;
                }
                case 8: {
                  message.updateMetricEntry = $root.fetchactions.healthie.v1.UpdateMetricEntry.decode(reader, reader.uint32());
                  break;
                }
                case 9: {
                  message.createOrUpdateFormAnswerGroup = $root.fetchactions.healthie.v1.CreateOrUpdateFormAnswerGroup.decode(reader, reader.uint32());
                  break;
                }
                case 10: {
                  message.createOrUpdateInsurancePolicy = $root.fetchactions.healthie.v1.CreateOrUpdateInsurancePolicy.decode(reader, reader.uint32());
                  break;
                }
                case 11: {
                  message.listAppointmentsForPatient = $root.fetchactions.healthie.v1.ListAppointmentsForPatient.decode(reader, reader.uint32());
                  break;
                }
                case 12: {
                  message.createOrUpdateReferringProvider = $root.fetchactions.healthie.v1.CreateOrUpdateReferringProvider.decode(reader, reader.uint32());
                  break;
                }
                case 13: {
                  message.createOrUpdateNotificationContact = $root.fetchactions.healthie.v1.CreateOrUpdateNotificationContact.decode(reader, reader.uint32());
                  break;
                }
                case 14: {
                  message.getUser = $root.fetchactions.healthie.v1.GetUser.decode(reader, reader.uint32());
                  break;
                }
                case 15: {
                  message.updateTags = $root.fetchactions.healthie.v1.UpdateTags.decode(reader, reader.uint32());
                  break;
                }
                case 16: {
                  message.updateClientSource = $root.fetchactions.healthie.v1.UpdateClientSource.decode(reader, reader.uint32());
                  break;
                }
                case 17: {
                  message.createDocument = $root.fetchactions.healthie.v1.CreateDocument.decode(reader, reader.uint32());
                  break;
                }
                case 18: {
                  message.createBillingItem = $root.fetchactions.healthie.v1.CreateBillingItem.decode(reader, reader.uint32());
                  break;
                }
                case 19: {
                  message.createInvoice = $root.fetchactions.healthie.v1.CreateInvoice.decode(reader, reader.uint32());
                  break;
                }
                case 20: {
                  message.listInsurancePlans = $root.fetchactions.healthie.v1.ListInsurancePlans.decode(reader, reader.uint32());
                  break;
                }
                case 21: {
                  message.createPatientStripeCreditCardV2 = $root.fetchactions.healthie.v1.CreatePatientStripeCreditCardV2.decode(reader, reader.uint32());
                  break;
                }
                case 22: {
                  message.createAllergySensitivity = $root.fetchactions.healthie.v1.CreateAllergySensitivity.decode(reader, reader.uint32());
                  break;
                }
                case 23: {
                  message.searchUsers = $root.fetchactions.healthie.v1.SearchUsers.decode(reader, reader.uint32());
                  break;
                }
                case 24: {
                  message.createAppointment = $root.fetchactions.healthie.v1.CreateAppointment.decode(reader, reader.uint32());
                  break;
                }
                case 25: {
                  message.createReferral = $root.fetchactions.healthie.v1.CreateReferral.decode(reader, reader.uint32());
                  break;
                }
                case 26: {
                  message.createFamilyHistory = $root.fetchactions.healthie.v1.CreateFamilyHistory.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.FetchAction) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.FetchAction();
            if (object.listRequestedFormCompletion != null) {
              if (typeof object.listRequestedFormCompletion !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.listRequestedFormCompletion: object expected, but got " + (typeof object.listRequestedFormCompletion));
              }
              message.listRequestedFormCompletion = $root.fetchactions.healthie.v1.ListRequestedFormCompletion.fromObject(object.listRequestedFormCompletion);
            }
            if (object.getAppointment != null) {
              if (typeof object.getAppointment !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.getAppointment: object expected, but got " + (typeof object.getAppointment));
              }
              message.getAppointment = $root.fetchactions.healthie.v1.GetAppointment.fromObject(object.getAppointment);
            }
            if (object.getOnboardingFlow != null) {
              if (typeof object.getOnboardingFlow !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.getOnboardingFlow: object expected, but got " + (typeof object.getOnboardingFlow));
              }
              message.getOnboardingFlow = $root.fetchactions.healthie.v1.GetOnboardingFlow.fromObject(object.getOnboardingFlow);
            }
            if (object.createTask != null) {
              if (typeof object.createTask !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.createTask: object expected, but got " + (typeof object.createTask));
              }
              message.createTask = $root.fetchactions.healthie.v1.CreateTask.fromObject(object.createTask);
            }
            if (object.createConversation != null) {
              if (typeof object.createConversation !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.createConversation: object expected, but got " + (typeof object.createConversation));
              }
              message.createConversation = $root.fetchactions.healthie.v1.CreateConversation.fromObject(object.createConversation);
            }
            if (object.searchMetricEntries != null) {
              if (typeof object.searchMetricEntries !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.searchMetricEntries: object expected, but got " + (typeof object.searchMetricEntries));
              }
              message.searchMetricEntries = $root.fetchactions.healthie.v1.SearchMetricEntries.fromObject(object.searchMetricEntries);
            }
            if (object.updateAppointment != null) {
              if (typeof object.updateAppointment !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.updateAppointment: object expected, but got " + (typeof object.updateAppointment));
              }
              message.updateAppointment = $root.fetchactions.healthie.v1.UpdateAppointment.fromObject(object.updateAppointment);
            }
            if (object.updateMetricEntry != null) {
              if (typeof object.updateMetricEntry !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.updateMetricEntry: object expected, but got " + (typeof object.updateMetricEntry));
              }
              message.updateMetricEntry = $root.fetchactions.healthie.v1.UpdateMetricEntry.fromObject(object.updateMetricEntry);
            }
            if (object.createOrUpdateFormAnswerGroup != null) {
              if (typeof object.createOrUpdateFormAnswerGroup !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.createOrUpdateFormAnswerGroup: object expected, but got " + (typeof object.createOrUpdateFormAnswerGroup));
              }
              message.createOrUpdateFormAnswerGroup = $root.fetchactions.healthie.v1.CreateOrUpdateFormAnswerGroup.fromObject(object.createOrUpdateFormAnswerGroup);
            }
            if (object.createOrUpdateInsurancePolicy != null) {
              if (typeof object.createOrUpdateInsurancePolicy !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.createOrUpdateInsurancePolicy: object expected, but got " + (typeof object.createOrUpdateInsurancePolicy));
              }
              message.createOrUpdateInsurancePolicy = $root.fetchactions.healthie.v1.CreateOrUpdateInsurancePolicy.fromObject(object.createOrUpdateInsurancePolicy);
            }
            if (object.listAppointmentsForPatient != null) {
              if (typeof object.listAppointmentsForPatient !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.listAppointmentsForPatient: object expected, but got " + (typeof object.listAppointmentsForPatient));
              }
              message.listAppointmentsForPatient = $root.fetchactions.healthie.v1.ListAppointmentsForPatient.fromObject(object.listAppointmentsForPatient);
            }
            if (object.createOrUpdateReferringProvider != null) {
              if (typeof object.createOrUpdateReferringProvider !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.createOrUpdateReferringProvider: object expected, but got " + (typeof object.createOrUpdateReferringProvider));
              }
              message.createOrUpdateReferringProvider = $root.fetchactions.healthie.v1.CreateOrUpdateReferringProvider.fromObject(object.createOrUpdateReferringProvider);
            }
            if (object.createOrUpdateNotificationContact != null) {
              if (typeof object.createOrUpdateNotificationContact !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.createOrUpdateNotificationContact: object expected, but got " + (typeof object.createOrUpdateNotificationContact));
              }
              message.createOrUpdateNotificationContact = $root.fetchactions.healthie.v1.CreateOrUpdateNotificationContact.fromObject(object.createOrUpdateNotificationContact);
            }
            if (object.getUser != null) {
              if (typeof object.getUser !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.getUser: object expected, but got " + (typeof object.getUser));
              }
              message.getUser = $root.fetchactions.healthie.v1.GetUser.fromObject(object.getUser);
            }
            if (object.updateTags != null) {
              if (typeof object.updateTags !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.updateTags: object expected, but got " + (typeof object.updateTags));
              }
              message.updateTags = $root.fetchactions.healthie.v1.UpdateTags.fromObject(object.updateTags);
            }
            if (object.updateClientSource != null) {
              if (typeof object.updateClientSource !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.updateClientSource: object expected, but got " + (typeof object.updateClientSource));
              }
              message.updateClientSource = $root.fetchactions.healthie.v1.UpdateClientSource.fromObject(object.updateClientSource);
            }
            if (object.createDocument != null) {
              if (typeof object.createDocument !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.createDocument: object expected, but got " + (typeof object.createDocument));
              }
              message.createDocument = $root.fetchactions.healthie.v1.CreateDocument.fromObject(object.createDocument);
            }
            if (object.createBillingItem != null) {
              if (typeof object.createBillingItem !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.createBillingItem: object expected, but got " + (typeof object.createBillingItem));
              }
              message.createBillingItem = $root.fetchactions.healthie.v1.CreateBillingItem.fromObject(object.createBillingItem);
            }
            if (object.createInvoice != null) {
              if (typeof object.createInvoice !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.createInvoice: object expected, but got " + (typeof object.createInvoice));
              }
              message.createInvoice = $root.fetchactions.healthie.v1.CreateInvoice.fromObject(object.createInvoice);
            }
            if (object.listInsurancePlans != null) {
              if (typeof object.listInsurancePlans !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.listInsurancePlans: object expected, but got " + (typeof object.listInsurancePlans));
              }
              message.listInsurancePlans = $root.fetchactions.healthie.v1.ListInsurancePlans.fromObject(object.listInsurancePlans);
            }
            if (object.createPatientStripeCreditCardV2 != null) {
              if (typeof object.createPatientStripeCreditCardV2 !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.createPatientStripeCreditCardV2: object expected, but got " + (typeof object.createPatientStripeCreditCardV2));
              }
              message.createPatientStripeCreditCardV2 = $root.fetchactions.healthie.v1.CreatePatientStripeCreditCardV2.fromObject(object.createPatientStripeCreditCardV2);
            }
            if (object.createAllergySensitivity != null) {
              if (typeof object.createAllergySensitivity !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.createAllergySensitivity: object expected, but got " + (typeof object.createAllergySensitivity));
              }
              message.createAllergySensitivity = $root.fetchactions.healthie.v1.CreateAllergySensitivity.fromObject(object.createAllergySensitivity);
            }
            if (object.searchUsers != null) {
              if (typeof object.searchUsers !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.searchUsers: object expected, but got " + (typeof object.searchUsers));
              }
              message.searchUsers = $root.fetchactions.healthie.v1.SearchUsers.fromObject(object.searchUsers);
            }
            if (object.createAppointment != null) {
              if (typeof object.createAppointment !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.createAppointment: object expected, but got " + (typeof object.createAppointment));
              }
              message.createAppointment = $root.fetchactions.healthie.v1.CreateAppointment.fromObject(object.createAppointment);
            }
            if (object.createReferral != null) {
              if (typeof object.createReferral !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.createReferral: object expected, but got " + (typeof object.createReferral));
              }
              message.createReferral = $root.fetchactions.healthie.v1.CreateReferral.fromObject(object.createReferral);
            }
            if (object.createFamilyHistory != null) {
              if (typeof object.createFamilyHistory !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.FetchAction.createFamilyHistory: object expected, but got " + (typeof object.createFamilyHistory));
              }
              message.createFamilyHistory = $root.fetchactions.healthie.v1.CreateFamilyHistory.fromObject(object.createFamilyHistory);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.listRequestedFormCompletion != null && message.hasOwnProperty("listRequestedFormCompletion")) {
              object.listRequestedFormCompletion = $root.fetchactions.healthie.v1.ListRequestedFormCompletion.toObject(message.listRequestedFormCompletion, options);
              if (options.oneofs) {
                object.type = "listRequestedFormCompletion";
              }
            }
            if (message.getAppointment != null && message.hasOwnProperty("getAppointment")) {
              object.getAppointment = $root.fetchactions.healthie.v1.GetAppointment.toObject(message.getAppointment, options);
              if (options.oneofs) {
                object.type = "getAppointment";
              }
            }
            if (message.getOnboardingFlow != null && message.hasOwnProperty("getOnboardingFlow")) {
              object.getOnboardingFlow = $root.fetchactions.healthie.v1.GetOnboardingFlow.toObject(message.getOnboardingFlow, options);
              if (options.oneofs) {
                object.type = "getOnboardingFlow";
              }
            }
            if (message.createTask != null && message.hasOwnProperty("createTask")) {
              object.createTask = $root.fetchactions.healthie.v1.CreateTask.toObject(message.createTask, options);
              if (options.oneofs) {
                object.type = "createTask";
              }
            }
            if (message.createConversation != null && message.hasOwnProperty("createConversation")) {
              object.createConversation = $root.fetchactions.healthie.v1.CreateConversation.toObject(message.createConversation, options);
              if (options.oneofs) {
                object.type = "createConversation";
              }
            }
            if (message.searchMetricEntries != null && message.hasOwnProperty("searchMetricEntries")) {
              object.searchMetricEntries = $root.fetchactions.healthie.v1.SearchMetricEntries.toObject(message.searchMetricEntries, options);
              if (options.oneofs) {
                object.type = "searchMetricEntries";
              }
            }
            if (message.updateAppointment != null && message.hasOwnProperty("updateAppointment")) {
              object.updateAppointment = $root.fetchactions.healthie.v1.UpdateAppointment.toObject(message.updateAppointment, options);
              if (options.oneofs) {
                object.type = "updateAppointment";
              }
            }
            if (message.updateMetricEntry != null && message.hasOwnProperty("updateMetricEntry")) {
              object.updateMetricEntry = $root.fetchactions.healthie.v1.UpdateMetricEntry.toObject(message.updateMetricEntry, options);
              if (options.oneofs) {
                object.type = "updateMetricEntry";
              }
            }
            if (message.createOrUpdateFormAnswerGroup != null && message.hasOwnProperty("createOrUpdateFormAnswerGroup")) {
              object.createOrUpdateFormAnswerGroup = $root.fetchactions.healthie.v1.CreateOrUpdateFormAnswerGroup.toObject(message.createOrUpdateFormAnswerGroup, options);
              if (options.oneofs) {
                object.type = "createOrUpdateFormAnswerGroup";
              }
            }
            if (message.createOrUpdateInsurancePolicy != null && message.hasOwnProperty("createOrUpdateInsurancePolicy")) {
              object.createOrUpdateInsurancePolicy = $root.fetchactions.healthie.v1.CreateOrUpdateInsurancePolicy.toObject(message.createOrUpdateInsurancePolicy, options);
              if (options.oneofs) {
                object.type = "createOrUpdateInsurancePolicy";
              }
            }
            if (message.listAppointmentsForPatient != null && message.hasOwnProperty("listAppointmentsForPatient")) {
              object.listAppointmentsForPatient = $root.fetchactions.healthie.v1.ListAppointmentsForPatient.toObject(message.listAppointmentsForPatient, options);
              if (options.oneofs) {
                object.type = "listAppointmentsForPatient";
              }
            }
            if (message.createOrUpdateReferringProvider != null && message.hasOwnProperty("createOrUpdateReferringProvider")) {
              object.createOrUpdateReferringProvider = $root.fetchactions.healthie.v1.CreateOrUpdateReferringProvider.toObject(message.createOrUpdateReferringProvider, options);
              if (options.oneofs) {
                object.type = "createOrUpdateReferringProvider";
              }
            }
            if (message.createOrUpdateNotificationContact != null && message.hasOwnProperty("createOrUpdateNotificationContact")) {
              object.createOrUpdateNotificationContact = $root.fetchactions.healthie.v1.CreateOrUpdateNotificationContact.toObject(message.createOrUpdateNotificationContact, options);
              if (options.oneofs) {
                object.type = "createOrUpdateNotificationContact";
              }
            }
            if (message.getUser != null && message.hasOwnProperty("getUser")) {
              object.getUser = $root.fetchactions.healthie.v1.GetUser.toObject(message.getUser, options);
              if (options.oneofs) {
                object.type = "getUser";
              }
            }
            if (message.updateTags != null && message.hasOwnProperty("updateTags")) {
              object.updateTags = $root.fetchactions.healthie.v1.UpdateTags.toObject(message.updateTags, options);
              if (options.oneofs) {
                object.type = "updateTags";
              }
            }
            if (message.updateClientSource != null && message.hasOwnProperty("updateClientSource")) {
              object.updateClientSource = $root.fetchactions.healthie.v1.UpdateClientSource.toObject(message.updateClientSource, options);
              if (options.oneofs) {
                object.type = "updateClientSource";
              }
            }
            if (message.createDocument != null && message.hasOwnProperty("createDocument")) {
              object.createDocument = $root.fetchactions.healthie.v1.CreateDocument.toObject(message.createDocument, options);
              if (options.oneofs) {
                object.type = "createDocument";
              }
            }
            if (message.createBillingItem != null && message.hasOwnProperty("createBillingItem")) {
              object.createBillingItem = $root.fetchactions.healthie.v1.CreateBillingItem.toObject(message.createBillingItem, options);
              if (options.oneofs) {
                object.type = "createBillingItem";
              }
            }
            if (message.createInvoice != null && message.hasOwnProperty("createInvoice")) {
              object.createInvoice = $root.fetchactions.healthie.v1.CreateInvoice.toObject(message.createInvoice, options);
              if (options.oneofs) {
                object.type = "createInvoice";
              }
            }
            if (message.listInsurancePlans != null && message.hasOwnProperty("listInsurancePlans")) {
              object.listInsurancePlans = $root.fetchactions.healthie.v1.ListInsurancePlans.toObject(message.listInsurancePlans, options);
              if (options.oneofs) {
                object.type = "listInsurancePlans";
              }
            }
            if (message.createPatientStripeCreditCardV2 != null && message.hasOwnProperty("createPatientStripeCreditCardV2")) {
              object.createPatientStripeCreditCardV2 = $root.fetchactions.healthie.v1.CreatePatientStripeCreditCardV2.toObject(message.createPatientStripeCreditCardV2, options);
              if (options.oneofs) {
                object.type = "createPatientStripeCreditCardV2";
              }
            }
            if (message.createAllergySensitivity != null && message.hasOwnProperty("createAllergySensitivity")) {
              object.createAllergySensitivity = $root.fetchactions.healthie.v1.CreateAllergySensitivity.toObject(message.createAllergySensitivity, options);
              if (options.oneofs) {
                object.type = "createAllergySensitivity";
              }
            }
            if (message.searchUsers != null && message.hasOwnProperty("searchUsers")) {
              object.searchUsers = $root.fetchactions.healthie.v1.SearchUsers.toObject(message.searchUsers, options);
              if (options.oneofs) {
                object.type = "searchUsers";
              }
            }
            if (message.createAppointment != null && message.hasOwnProperty("createAppointment")) {
              object.createAppointment = $root.fetchactions.healthie.v1.CreateAppointment.toObject(message.createAppointment, options);
              if (options.oneofs) {
                object.type = "createAppointment";
              }
            }
            if (message.createReferral != null && message.hasOwnProperty("createReferral")) {
              object.createReferral = $root.fetchactions.healthie.v1.CreateReferral.toObject(message.createReferral, options);
              if (options.oneofs) {
                object.type = "createReferral";
              }
            }
            if (message.createFamilyHistory != null && message.hasOwnProperty("createFamilyHistory")) {
              object.createFamilyHistory = $root.fetchactions.healthie.v1.CreateFamilyHistory.toObject(message.createFamilyHistory, options);
              if (options.oneofs) {
                object.type = "createFamilyHistory";
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.FetchAction";
          }
        }

        FetchAction.prototype.listRequestedFormCompletion = null;
        FetchAction.prototype.getAppointment = null;
        FetchAction.prototype.getOnboardingFlow = null;
        FetchAction.prototype.createTask = null;
        FetchAction.prototype.createConversation = null;
        FetchAction.prototype.searchMetricEntries = null;
        FetchAction.prototype.updateAppointment = null;
        FetchAction.prototype.updateMetricEntry = null;
        FetchAction.prototype.createOrUpdateFormAnswerGroup = null;
        FetchAction.prototype.createOrUpdateInsurancePolicy = null;
        FetchAction.prototype.listAppointmentsForPatient = null;
        FetchAction.prototype.createOrUpdateReferringProvider = null;
        FetchAction.prototype.createOrUpdateNotificationContact = null;
        FetchAction.prototype.getUser = null;
        FetchAction.prototype.updateTags = null;
        FetchAction.prototype.updateClientSource = null;
        FetchAction.prototype.createDocument = null;
        FetchAction.prototype.createBillingItem = null;
        FetchAction.prototype.createInvoice = null;
        FetchAction.prototype.listInsurancePlans = null;
        FetchAction.prototype.createPatientStripeCreditCardV2 = null;
        FetchAction.prototype.createAllergySensitivity = null;
        FetchAction.prototype.searchUsers = null;
        FetchAction.prototype.createAppointment = null;
        FetchAction.prototype.createReferral = null;
        FetchAction.prototype.createFamilyHistory = null;

        return FetchAction;
      })();

      v1.ListRequestedFormCompletion = (() => {
        class ListRequestedFormCompletion {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            this.result = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ListRequestedFormCompletion(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              for (const element of message.result) {
                healthie$1.v1.RequestedFormCompletion.encode(element, writer.uint32(42).fork()).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ListRequestedFormCompletion();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 5: {
                  if (!message.result || !message.result.length) {
                    message.result = [];
                  }
                  message.result.push(healthie$1.v1.RequestedFormCompletion.decode(reader, reader.uint32()));
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.ListRequestedFormCompletion) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.ListRequestedFormCompletion();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.ListRequestedFormCompletion.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.ListRequestedFormCompletion.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.ListRequestedFormCompletion.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.ListRequestedFormCompletion.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result) {
              if (!Array.isArray(object.result)) {
                throw new TypeError(".fetchactions.healthie.v1.ListRequestedFormCompletion.result: array type expected, but got " + (typeof object.result))
              }
              message.result = new Array(object.result.length);
              for (let i = 0; i < object.result.length; ++i) {
                if (typeof object.result[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.ListRequestedFormCompletion.result: object expected, but got " + (typeof object.result[i]));
                }
                message.result[i] = healthie$1.v1.RequestedFormCompletion.fromObject(object.result[i]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
              object.result = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result && message.result.length) {
              object.result = new Array(message.result.length);
              for (let i = 0; i < message.result.length; ++i) {
                object.result[i] = healthie$1.v1.RequestedFormCompletion.toObject(message.result[i], options);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.ListRequestedFormCompletion";
          }
        }

        ListRequestedFormCompletion.prototype.params = $util.emptyArray;
        ListRequestedFormCompletion.prototype.configs = $util.emptyArray;
        ListRequestedFormCompletion.prototype.requiredThirdPartyIdMissingBehavior = 0;
        ListRequestedFormCompletion.prototype.healthieEnvironment = 0;
        ListRequestedFormCompletion.prototype.result = $util.emptyArray;

        return ListRequestedFormCompletion;
      })();

      v1.GetAppointment = (() => {
        class GetAppointment {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new GetAppointment(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              healthie$1.v1.Appointment.encode(message.result, writer.uint32(42).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new GetAppointment();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 5: {
                  message.result = healthie$1.v1.Appointment.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.GetAppointment) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.GetAppointment();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.GetAppointment.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.GetAppointment.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.GetAppointment.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.GetAppointment.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.GetAppointment.result: object expected, but got " + (typeof object.result));
              }
              message.result = healthie$1.v1.Appointment.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = healthie$1.v1.Appointment.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.GetAppointment";
          }
        }

        GetAppointment.prototype.params = $util.emptyArray;
        GetAppointment.prototype.configs = $util.emptyArray;
        GetAppointment.prototype.healthieEnvironment = 0;
        GetAppointment.prototype.result = null;

        return GetAppointment;
      })();

      v1.GetOnboardingFlow = (() => {
        class GetOnboardingFlow {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new GetOnboardingFlow(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              healthie$1.v1.OnboardingFlow.encode(message.result, writer.uint32(42).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new GetOnboardingFlow();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 5: {
                  message.result = healthie$1.v1.OnboardingFlow.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.GetOnboardingFlow) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.GetOnboardingFlow();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.GetOnboardingFlow.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.GetOnboardingFlow.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.GetOnboardingFlow.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.GetOnboardingFlow.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.GetOnboardingFlow.result: object expected, but got " + (typeof object.result));
              }
              message.result = healthie$1.v1.OnboardingFlow.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = healthie$1.v1.OnboardingFlow.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.GetOnboardingFlow";
          }
        }

        GetOnboardingFlow.prototype.params = $util.emptyArray;
        GetOnboardingFlow.prototype.configs = $util.emptyArray;
        GetOnboardingFlow.prototype.requiredThirdPartyIdMissingBehavior = 0;
        GetOnboardingFlow.prototype.healthieEnvironment = 0;
        GetOnboardingFlow.prototype.result = null;

        return GetOnboardingFlow;
      })();

      v1.CreateTask = (() => {
        class CreateTask {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateTask(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              healthie$1.v1.Task.encode(message.result, writer.uint32(42).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateTask();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 5: {
                  message.result = healthie$1.v1.Task.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.CreateTask) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.CreateTask();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateTask.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateTask.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateTask.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateTask.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.CreateTask.result: object expected, but got " + (typeof object.result));
              }
              message.result = healthie$1.v1.Task.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = healthie$1.v1.Task.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.CreateTask";
          }
        }

        CreateTask.prototype.params = $util.emptyArray;
        CreateTask.prototype.configs = $util.emptyArray;
        CreateTask.prototype.requiredThirdPartyIdMissingBehavior = 0;
        CreateTask.prototype.healthieEnvironment = 0;
        CreateTask.prototype.result = null;

        return CreateTask;
      })();

      v1.CreateConversation = (() => {
        class CreateConversation {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateConversation(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              healthie$1.v1.Conversation.encode(message.result, writer.uint32(42).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateConversation();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 5: {
                  message.result = healthie$1.v1.Conversation.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.CreateConversation) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.CreateConversation();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateConversation.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateConversation.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateConversation.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateConversation.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.CreateConversation.result: object expected, but got " + (typeof object.result));
              }
              message.result = healthie$1.v1.Conversation.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = healthie$1.v1.Conversation.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.CreateConversation";
          }
        }

        CreateConversation.prototype.params = $util.emptyArray;
        CreateConversation.prototype.configs = $util.emptyArray;
        CreateConversation.prototype.requiredThirdPartyIdMissingBehavior = 0;
        CreateConversation.prototype.healthieEnvironment = 0;
        CreateConversation.prototype.result = null;

        return CreateConversation;
      })();

      v1.SearchMetricEntries = (() => {
        class SearchMetricEntries {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new SearchMetricEntries(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              healthie$1.v1.MetricEntries.encode(message.result, writer.uint32(42).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new SearchMetricEntries();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 5: {
                  message.result = healthie$1.v1.MetricEntries.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.SearchMetricEntries) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.SearchMetricEntries();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.SearchMetricEntries.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.SearchMetricEntries.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.SearchMetricEntries.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.SearchMetricEntries.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.SearchMetricEntries.result: object expected, but got " + (typeof object.result));
              }
              message.result = healthie$1.v1.MetricEntries.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = healthie$1.v1.MetricEntries.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.SearchMetricEntries";
          }
        }

        SearchMetricEntries.prototype.params = $util.emptyArray;
        SearchMetricEntries.prototype.configs = $util.emptyArray;
        SearchMetricEntries.prototype.requiredThirdPartyIdMissingBehavior = 0;
        SearchMetricEntries.prototype.healthieEnvironment = 0;
        SearchMetricEntries.prototype.result = null;

        return SearchMetricEntries;
      })();

      v1.UpdateAppointment = (() => {
        class UpdateAppointment {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new UpdateAppointment(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(24).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              healthie$1.v1.Appointment.encode(message.result, writer.uint32(34).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new UpdateAppointment();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 4: {
                  message.result = healthie$1.v1.Appointment.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.UpdateAppointment) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.UpdateAppointment();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.UpdateAppointment.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.UpdateAppointment.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.UpdateAppointment.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.UpdateAppointment.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.UpdateAppointment.result: object expected, but got " + (typeof object.result));
              }
              message.result = healthie$1.v1.Appointment.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = healthie$1.v1.Appointment.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.UpdateAppointment";
          }
        }

        UpdateAppointment.prototype.params = $util.emptyArray;
        UpdateAppointment.prototype.configs = $util.emptyArray;
        UpdateAppointment.prototype.healthieEnvironment = 0;
        UpdateAppointment.prototype.result = null;

        return UpdateAppointment;
      })();

      v1.UpdateMetricEntry = (() => {
        class UpdateMetricEntry {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new UpdateMetricEntry(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(24).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              healthie$1.v1.MetricEntry.encode(message.result, writer.uint32(34).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new UpdateMetricEntry();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 4: {
                  message.result = healthie$1.v1.MetricEntry.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.UpdateMetricEntry) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.UpdateMetricEntry();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.UpdateMetricEntry.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.UpdateMetricEntry.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.UpdateMetricEntry.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.UpdateMetricEntry.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.UpdateMetricEntry.result: object expected, but got " + (typeof object.result));
              }
              message.result = healthie$1.v1.MetricEntry.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = healthie$1.v1.MetricEntry.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.UpdateMetricEntry";
          }
        }

        UpdateMetricEntry.prototype.params = $util.emptyArray;
        UpdateMetricEntry.prototype.configs = $util.emptyArray;
        UpdateMetricEntry.prototype.healthieEnvironment = 0;
        UpdateMetricEntry.prototype.result = null;

        return UpdateMetricEntry;
      })();

      v1.CreateOrUpdateFormAnswerGroup = (() => {
        class CreateOrUpdateFormAnswerGroup {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateOrUpdateFormAnswerGroup(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              healthie$1.v1.FormAnswerGroup.encode(message.result, writer.uint32(42).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateOrUpdateFormAnswerGroup();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 5: {
                  message.result = healthie$1.v1.FormAnswerGroup.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.CreateOrUpdateFormAnswerGroup) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.CreateOrUpdateFormAnswerGroup();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateOrUpdateFormAnswerGroup.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateOrUpdateFormAnswerGroup.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateOrUpdateFormAnswerGroup.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateOrUpdateFormAnswerGroup.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.CreateOrUpdateFormAnswerGroup.result: object expected, but got " + (typeof object.result));
              }
              message.result = healthie$1.v1.FormAnswerGroup.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = healthie$1.v1.FormAnswerGroup.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.CreateOrUpdateFormAnswerGroup";
          }
        }

        CreateOrUpdateFormAnswerGroup.prototype.params = $util.emptyArray;
        CreateOrUpdateFormAnswerGroup.prototype.configs = $util.emptyArray;
        CreateOrUpdateFormAnswerGroup.prototype.requiredThirdPartyIdMissingBehavior = 0;
        CreateOrUpdateFormAnswerGroup.prototype.healthieEnvironment = 0;
        CreateOrUpdateFormAnswerGroup.prototype.result = null;

        return CreateOrUpdateFormAnswerGroup;
      })();

      v1.CreateOrUpdateInsurancePolicy = (() => {
        class CreateOrUpdateInsurancePolicy {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateOrUpdateInsurancePolicy(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              healthie$1.v1.Policy.encode(message.result, writer.uint32(42).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateOrUpdateInsurancePolicy();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 5: {
                  message.result = healthie$1.v1.Policy.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.CreateOrUpdateInsurancePolicy) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.CreateOrUpdateInsurancePolicy();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateOrUpdateInsurancePolicy.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateOrUpdateInsurancePolicy.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateOrUpdateInsurancePolicy.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateOrUpdateInsurancePolicy.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.CreateOrUpdateInsurancePolicy.result: object expected, but got " + (typeof object.result));
              }
              message.result = healthie$1.v1.Policy.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = healthie$1.v1.Policy.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.CreateOrUpdateInsurancePolicy";
          }
        }

        CreateOrUpdateInsurancePolicy.prototype.params = $util.emptyArray;
        CreateOrUpdateInsurancePolicy.prototype.configs = $util.emptyArray;
        CreateOrUpdateInsurancePolicy.prototype.requiredThirdPartyIdMissingBehavior = 0;
        CreateOrUpdateInsurancePolicy.prototype.healthieEnvironment = 0;
        CreateOrUpdateInsurancePolicy.prototype.result = null;

        return CreateOrUpdateInsurancePolicy;
      })();

      v1.ListAppointmentsForPatient = (() => {
        class ListAppointmentsForPatient {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            this.result = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ListAppointmentsForPatient(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              for (const element of message.result) {
                healthie$1.v1.Appointment.encode(element, writer.uint32(42).fork()).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ListAppointmentsForPatient();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 5: {
                  if (!message.result || !message.result.length) {
                    message.result = [];
                  }
                  message.result.push(healthie$1.v1.Appointment.decode(reader, reader.uint32()));
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.ListAppointmentsForPatient) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.ListAppointmentsForPatient();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.ListAppointmentsForPatient.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.ListAppointmentsForPatient.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.ListAppointmentsForPatient.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.ListAppointmentsForPatient.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result) {
              if (!Array.isArray(object.result)) {
                throw new TypeError(".fetchactions.healthie.v1.ListAppointmentsForPatient.result: array type expected, but got " + (typeof object.result))
              }
              message.result = new Array(object.result.length);
              for (let i = 0; i < object.result.length; ++i) {
                if (typeof object.result[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.ListAppointmentsForPatient.result: object expected, but got " + (typeof object.result[i]));
                }
                message.result[i] = healthie$1.v1.Appointment.fromObject(object.result[i]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
              object.result = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result && message.result.length) {
              object.result = new Array(message.result.length);
              for (let i = 0; i < message.result.length; ++i) {
                object.result[i] = healthie$1.v1.Appointment.toObject(message.result[i], options);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.ListAppointmentsForPatient";
          }
        }

        ListAppointmentsForPatient.prototype.params = $util.emptyArray;
        ListAppointmentsForPatient.prototype.configs = $util.emptyArray;
        ListAppointmentsForPatient.prototype.requiredThirdPartyIdMissingBehavior = 0;
        ListAppointmentsForPatient.prototype.healthieEnvironment = 0;
        ListAppointmentsForPatient.prototype.result = $util.emptyArray;

        return ListAppointmentsForPatient;
      })();

      v1.CreateOrUpdateReferringProvider = (() => {
        class CreateOrUpdateReferringProvider {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateOrUpdateReferringProvider(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              healthie$1.v1.ReferringProvider.encode(message.result, writer.uint32(42).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateOrUpdateReferringProvider();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 5: {
                  message.result = healthie$1.v1.ReferringProvider.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.CreateOrUpdateReferringProvider) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.CreateOrUpdateReferringProvider();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateOrUpdateReferringProvider.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateOrUpdateReferringProvider.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateOrUpdateReferringProvider.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateOrUpdateReferringProvider.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.CreateOrUpdateReferringProvider.result: object expected, but got " + (typeof object.result));
              }
              message.result = healthie$1.v1.ReferringProvider.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = healthie$1.v1.ReferringProvider.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.CreateOrUpdateReferringProvider";
          }
        }

        CreateOrUpdateReferringProvider.prototype.params = $util.emptyArray;
        CreateOrUpdateReferringProvider.prototype.configs = $util.emptyArray;
        CreateOrUpdateReferringProvider.prototype.requiredThirdPartyIdMissingBehavior = 0;
        CreateOrUpdateReferringProvider.prototype.healthieEnvironment = 0;
        CreateOrUpdateReferringProvider.prototype.result = null;

        return CreateOrUpdateReferringProvider;
      })();

      v1.CreateOrUpdateNotificationContact = (() => {
        class CreateOrUpdateNotificationContact {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateOrUpdateNotificationContact(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              healthie$1.v1.NotificationContact.encode(message.result, writer.uint32(42).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateOrUpdateNotificationContact();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 5: {
                  message.result = healthie$1.v1.NotificationContact.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.CreateOrUpdateNotificationContact) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.CreateOrUpdateNotificationContact();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateOrUpdateNotificationContact.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateOrUpdateNotificationContact.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateOrUpdateNotificationContact.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateOrUpdateNotificationContact.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.CreateOrUpdateNotificationContact.result: object expected, but got " + (typeof object.result));
              }
              message.result = healthie$1.v1.NotificationContact.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = healthie$1.v1.NotificationContact.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.CreateOrUpdateNotificationContact";
          }
        }

        CreateOrUpdateNotificationContact.prototype.params = $util.emptyArray;
        CreateOrUpdateNotificationContact.prototype.configs = $util.emptyArray;
        CreateOrUpdateNotificationContact.prototype.requiredThirdPartyIdMissingBehavior = 0;
        CreateOrUpdateNotificationContact.prototype.healthieEnvironment = 0;
        CreateOrUpdateNotificationContact.prototype.result = null;

        return CreateOrUpdateNotificationContact;
      })();

      v1.GetUser = (() => {
        class GetUser {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new GetUser(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(24).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              healthie$1.v1.User.encode(message.result, writer.uint32(34).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new GetUser();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 4: {
                  message.result = healthie$1.v1.User.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.GetUser) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.GetUser();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.GetUser.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.GetUser.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.GetUser.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.GetUser.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.GetUser.result: object expected, but got " + (typeof object.result));
              }
              message.result = healthie$1.v1.User.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = healthie$1.v1.User.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.GetUser";
          }
        }

        GetUser.prototype.params = $util.emptyArray;
        GetUser.prototype.configs = $util.emptyArray;
        GetUser.prototype.healthieEnvironment = 0;
        GetUser.prototype.result = null;

        return GetUser;
      })();

      v1.UpdateTags = (() => {
        class UpdateTags {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new UpdateTags(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              google$1.protobuf.Empty.encode(message.result, writer.uint32(42).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new UpdateTags();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 5: {
                  message.result = google$1.protobuf.Empty.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.UpdateTags) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.UpdateTags();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.UpdateTags.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.UpdateTags.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.UpdateTags.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.UpdateTags.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.UpdateTags.result: object expected, but got " + (typeof object.result));
              }
              message.result = google$1.protobuf.Empty.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = google$1.protobuf.Empty.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.UpdateTags";
          }
        }

        UpdateTags.prototype.params = $util.emptyArray;
        UpdateTags.prototype.configs = $util.emptyArray;
        UpdateTags.prototype.requiredThirdPartyIdMissingBehavior = 0;
        UpdateTags.prototype.healthieEnvironment = 0;
        UpdateTags.prototype.result = null;

        return UpdateTags;
      })();

      v1.UpdateClientSource = (() => {
        class UpdateClientSource {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new UpdateClientSource(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              healthie$1.v1.ClientSource.encode(message.result, writer.uint32(42).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new UpdateClientSource();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 5: {
                  message.result = healthie$1.v1.ClientSource.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.UpdateClientSource) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.UpdateClientSource();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.UpdateClientSource.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.UpdateClientSource.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.UpdateClientSource.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.UpdateClientSource.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.UpdateClientSource.result: object expected, but got " + (typeof object.result));
              }
              message.result = healthie$1.v1.ClientSource.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = healthie$1.v1.ClientSource.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.UpdateClientSource";
          }
        }

        UpdateClientSource.prototype.params = $util.emptyArray;
        UpdateClientSource.prototype.configs = $util.emptyArray;
        UpdateClientSource.prototype.requiredThirdPartyIdMissingBehavior = 0;
        UpdateClientSource.prototype.healthieEnvironment = 0;
        UpdateClientSource.prototype.result = null;

        return UpdateClientSource;
      })();

      v1.CreateDocument = (() => {
        class CreateDocument {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateDocument(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              healthie$1.v1.Document.encode(message.result, writer.uint32(42).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateDocument();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 5: {
                  message.result = healthie$1.v1.Document.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.CreateDocument) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.CreateDocument();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateDocument.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateDocument.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateDocument.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateDocument.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.CreateDocument.result: object expected, but got " + (typeof object.result));
              }
              message.result = healthie$1.v1.Document.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = healthie$1.v1.Document.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.CreateDocument";
          }
        }

        CreateDocument.prototype.params = $util.emptyArray;
        CreateDocument.prototype.configs = $util.emptyArray;
        CreateDocument.prototype.requiredThirdPartyIdMissingBehavior = 0;
        CreateDocument.prototype.healthieEnvironment = 0;
        CreateDocument.prototype.result = null;

        return CreateDocument;
      })();

      v1.CreateBillingItem = (() => {
        class CreateBillingItem {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateBillingItem(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(24).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              healthie$1.v1.BillingItem.encode(message.result, writer.uint32(34).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateBillingItem();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 4: {
                  message.result = healthie$1.v1.BillingItem.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.CreateBillingItem) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.CreateBillingItem();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateBillingItem.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateBillingItem.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateBillingItem.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateBillingItem.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.CreateBillingItem.result: object expected, but got " + (typeof object.result));
              }
              message.result = healthie$1.v1.BillingItem.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = healthie$1.v1.BillingItem.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.CreateBillingItem";
          }
        }

        CreateBillingItem.prototype.params = $util.emptyArray;
        CreateBillingItem.prototype.configs = $util.emptyArray;
        CreateBillingItem.prototype.healthieEnvironment = 0;
        CreateBillingItem.prototype.result = null;

        return CreateBillingItem;
      })();

      v1.CreateInvoice = (() => {
        class CreateInvoice {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateInvoice(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(24).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              healthie$1.v1.RequestedPayment.encode(message.result, writer.uint32(34).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateInvoice();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 4: {
                  message.result = healthie$1.v1.RequestedPayment.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.CreateInvoice) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.CreateInvoice();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateInvoice.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateInvoice.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateInvoice.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateInvoice.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.CreateInvoice.result: object expected, but got " + (typeof object.result));
              }
              message.result = healthie$1.v1.RequestedPayment.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = healthie$1.v1.RequestedPayment.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.CreateInvoice";
          }
        }

        CreateInvoice.prototype.params = $util.emptyArray;
        CreateInvoice.prototype.configs = $util.emptyArray;
        CreateInvoice.prototype.healthieEnvironment = 0;
        CreateInvoice.prototype.result = null;

        return CreateInvoice;
      })();

      v1.ListInsurancePlans = (() => {
        class ListInsurancePlans {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            this.result = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ListInsurancePlans(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(24).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              for (const element of message.result) {
                healthie$1.v1.InsurancePlan.encode(element, writer.uint32(34).fork()).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ListInsurancePlans();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 4: {
                  if (!message.result || !message.result.length) {
                    message.result = [];
                  }
                  message.result.push(healthie$1.v1.InsurancePlan.decode(reader, reader.uint32()));
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.ListInsurancePlans) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.ListInsurancePlans();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.ListInsurancePlans.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.ListInsurancePlans.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.ListInsurancePlans.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.ListInsurancePlans.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result) {
              if (!Array.isArray(object.result)) {
                throw new TypeError(".fetchactions.healthie.v1.ListInsurancePlans.result: array type expected, but got " + (typeof object.result))
              }
              message.result = new Array(object.result.length);
              for (let i = 0; i < object.result.length; ++i) {
                if (typeof object.result[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.ListInsurancePlans.result: object expected, but got " + (typeof object.result[i]));
                }
                message.result[i] = healthie$1.v1.InsurancePlan.fromObject(object.result[i]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
              object.result = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result && message.result.length) {
              object.result = new Array(message.result.length);
              for (let i = 0; i < message.result.length; ++i) {
                object.result[i] = healthie$1.v1.InsurancePlan.toObject(message.result[i], options);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.ListInsurancePlans";
          }
        }

        ListInsurancePlans.prototype.params = $util.emptyArray;
        ListInsurancePlans.prototype.configs = $util.emptyArray;
        ListInsurancePlans.prototype.healthieEnvironment = 0;
        ListInsurancePlans.prototype.result = $util.emptyArray;

        return ListInsurancePlans;
      })();

      v1.CreatePatientStripeCreditCardV2 = (() => {
        class CreatePatientStripeCreditCardV2 {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreatePatientStripeCreditCardV2(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              healthie$1.v1.StripeCustomerDetail.encode(message.result, writer.uint32(42).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreatePatientStripeCreditCardV2();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 5: {
                  message.result = healthie$1.v1.StripeCustomerDetail.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.CreatePatientStripeCreditCardV2) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.CreatePatientStripeCreditCardV2();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.CreatePatientStripeCreditCardV2.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreatePatientStripeCreditCardV2.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.CreatePatientStripeCreditCardV2.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreatePatientStripeCreditCardV2.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.CreatePatientStripeCreditCardV2.result: object expected, but got " + (typeof object.result));
              }
              message.result = healthie$1.v1.StripeCustomerDetail.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = healthie$1.v1.StripeCustomerDetail.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.CreatePatientStripeCreditCardV2";
          }
        }

        CreatePatientStripeCreditCardV2.prototype.params = $util.emptyArray;
        CreatePatientStripeCreditCardV2.prototype.configs = $util.emptyArray;
        CreatePatientStripeCreditCardV2.prototype.requiredThirdPartyIdMissingBehavior = 0;
        CreatePatientStripeCreditCardV2.prototype.healthieEnvironment = 0;
        CreatePatientStripeCreditCardV2.prototype.result = null;

        return CreatePatientStripeCreditCardV2;
      })();

      v1.CreateAllergySensitivity = (() => {
        class CreateAllergySensitivity {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateAllergySensitivity(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(32).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              healthie$1.v1.AllergySensitivity.encode(message.result, writer.uint32(42).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateAllergySensitivity();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 5: {
                  message.result = healthie$1.v1.AllergySensitivity.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.CreateAllergySensitivity) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.CreateAllergySensitivity();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateAllergySensitivity.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateAllergySensitivity.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateAllergySensitivity.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateAllergySensitivity.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.CreateAllergySensitivity.result: object expected, but got " + (typeof object.result));
              }
              message.result = healthie$1.v1.AllergySensitivity.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = healthie$1.v1.AllergySensitivity.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.CreateAllergySensitivity";
          }
        }

        CreateAllergySensitivity.prototype.params = $util.emptyArray;
        CreateAllergySensitivity.prototype.configs = $util.emptyArray;
        CreateAllergySensitivity.prototype.requiredThirdPartyIdMissingBehavior = 0;
        CreateAllergySensitivity.prototype.healthieEnvironment = 0;
        CreateAllergySensitivity.prototype.result = null;

        return CreateAllergySensitivity;
      })();

      v1.SearchUsers = (() => {
        class SearchUsers {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            this.result = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new SearchUsers(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.healthieEnvironment != null && Object.hasOwnProperty.call(message, "healthieEnvironment")) {
              writer.uint32(24).int32(message.healthieEnvironment);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              for (const element of message.result) {
                healthie$1.v1.UserSummary.encode(element, writer.uint32(34).fork()).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new SearchUsers();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.healthieEnvironment = reader.int32();
                  break;
                }
                case 4: {
                  if (!message.result || !message.result.length) {
                    message.result = [];
                  }
                  message.result.push(healthie$1.v1.UserSummary.decode(reader, reader.uint32()));
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.SearchUsers) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.SearchUsers();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.SearchUsers.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.SearchUsers.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.SearchUsers.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.SearchUsers.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.healthieEnvironment != null) {
              switch (object.healthieEnvironment) {
                case "UNDEFINED_ILLEGAL":
                case 0: {
                  message.healthieEnvironment = 0;
                  break;
                }
                case "SANDBOX":
                case 1: {
                  message.healthieEnvironment = 1;
                  break;
                }
                case "PRODUCTION":
                case 2: {
                  message.healthieEnvironment = 2;
                  break;
                }
                default: {
                  if (typeof object.healthieEnvironment == "number") {
                    message.healthieEnvironment = object.healthieEnvironment;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result) {
              if (!Array.isArray(object.result)) {
                throw new TypeError(".fetchactions.healthie.v1.SearchUsers.result: array type expected, but got " + (typeof object.result))
              }
              message.result = new Array(object.result.length);
              for (let i = 0; i < object.result.length; ++i) {
                if (typeof object.result[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.SearchUsers.result: object expected, but got " + (typeof object.result[i]));
                }
                message.result[i] = healthie$1.v1.UserSummary.fromObject(object.result[i]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
              object.result = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.healthieEnvironment = options.enums === String ? "UNDEFINED_ILLEGAL" : 0
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.healthieEnvironment != null && message.hasOwnProperty("healthieEnvironment")) {
              object.healthieEnvironment = options.enums === String ? healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] === undefined ? message.healthieEnvironment : healthie$1.v1.HealthieEnvironment[message.healthieEnvironment] : message.healthieEnvironment;
            }
            if (message.result && message.result.length) {
              object.result = new Array(message.result.length);
              for (let i = 0; i < message.result.length; ++i) {
                object.result[i] = healthie$1.v1.UserSummary.toObject(message.result[i], options);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.SearchUsers";
          }
        }

        SearchUsers.prototype.params = $util.emptyArray;
        SearchUsers.prototype.configs = $util.emptyArray;
        SearchUsers.prototype.healthieEnvironment = 0;
        SearchUsers.prototype.result = $util.emptyArray;

        return SearchUsers;
      })();

      v1.CreateAppointment = (() => {
        class CreateAppointment {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            this.result = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateAppointment(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              for (const element of message.result) {
                healthie$1.v1.Appointment.encode(element, writer.uint32(34).fork()).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateAppointment();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  if (!message.result || !message.result.length) {
                    message.result = [];
                  }
                  message.result.push(healthie$1.v1.Appointment.decode(reader, reader.uint32()));
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.CreateAppointment) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.CreateAppointment();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateAppointment.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateAppointment.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateAppointment.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateAppointment.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result) {
              if (!Array.isArray(object.result)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateAppointment.result: array type expected, but got " + (typeof object.result))
              }
              message.result = new Array(object.result.length);
              for (let i = 0; i < object.result.length; ++i) {
                if (typeof object.result[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateAppointment.result: object expected, but got " + (typeof object.result[i]));
                }
                message.result[i] = healthie$1.v1.Appointment.fromObject(object.result[i]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
              object.result = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.result && message.result.length) {
              object.result = new Array(message.result.length);
              for (let i = 0; i < message.result.length; ++i) {
                object.result[i] = healthie$1.v1.Appointment.toObject(message.result[i], options);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.CreateAppointment";
          }
        }

        CreateAppointment.prototype.params = $util.emptyArray;
        CreateAppointment.prototype.configs = $util.emptyArray;
        CreateAppointment.prototype.requiredThirdPartyIdMissingBehavior = 0;
        CreateAppointment.prototype.result = $util.emptyArray;

        return CreateAppointment;
      })();

      v1.CreateReferral = (() => {
        class CreateReferral {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateReferral(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              healthie$1.v1.Referral.encode(message.result, writer.uint32(34).fork()).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateReferral();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  message.result = healthie$1.v1.Referral.decode(reader, reader.uint32());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.CreateReferral) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.CreateReferral();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateReferral.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateReferral.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateReferral.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateReferral.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result != null) {
              if (typeof object.result !== "object") {
                throw new TypeError(".fetchactions.healthie.v1.CreateReferral.result: object expected, but got " + (typeof object.result));
              }
              message.result = healthie$1.v1.Referral.fromObject(object.result);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.result != null && message.hasOwnProperty("result")) {
              object.result = healthie$1.v1.Referral.toObject(message.result, options);
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.CreateReferral";
          }
        }

        CreateReferral.prototype.params = $util.emptyArray;
        CreateReferral.prototype.configs = $util.emptyArray;
        CreateReferral.prototype.requiredThirdPartyIdMissingBehavior = 0;
        CreateReferral.prototype.result = null;

        return CreateReferral;
      })();

      v1.CreateFamilyHistory = (() => {
        class CreateFamilyHistory {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            this.result = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateFamilyHistory(properties)
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params")) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(element, writer.uint32(10).fork()).ldelim();
              }
            }
            if (message.configs != null && Object.hasOwnProperty.call(message, "configs")) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(element, writer.uint32(18).fork()).ldelim();
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && Object.hasOwnProperty.call(message, "requiredThirdPartyIdMissingBehavior")) {
              writer.uint32(24).int32(message.requiredThirdPartyIdMissingBehavior);
            }
            if (message.result != null && Object.hasOwnProperty.call(message, "result")) {
              for (const element of message.result) {
                healthie$1.v1.FamilyHistoryCondition.encode(element, writer.uint32(34).fork()).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateFamilyHistory();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(workflow_parameters$1.v1.DestinationActionParameter.decode(reader, reader.uint32()));
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(workflow_parameters$1.v1.DestinationActionParameterConfig.decode(reader, reader.uint32()));
                  break;
                }
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = reader.int32();
                  break;
                }
                case 4: {
                  if (!message.result || !message.result.length) {
                    message.result = [];
                  }
                  message.result.push(healthie$1.v1.FamilyHistoryCondition.decode(reader, reader.uint32()));
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.healthie.v1.CreateFamilyHistory) {
              return object;
            }
            const message = new $root.fetchactions.healthie.v1.CreateFamilyHistory();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateFamilyHistory.params: array type expected, but got " + (typeof object.params))
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateFamilyHistory.params: object expected, but got " + (typeof object.params[i]));
                }
                message.params[i] = workflow_parameters$1.v1.DestinationActionParameter.fromObject(object.params[i]);
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateFamilyHistory.configs: array type expected, but got " + (typeof object.configs))
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateFamilyHistory.configs: object expected, but got " + (typeof object.configs[i]));
                }
                message.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(object.configs[i]);
              }
            }
            if (object.requiredThirdPartyIdMissingBehavior != null) {
              switch (object.requiredThirdPartyIdMissingBehavior) {
                case "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR":
                case 0: {
                  message.requiredThirdPartyIdMissingBehavior = 0;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_WORKFLOW":
                case 1: {
                  message.requiredThirdPartyIdMissingBehavior = 1;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH":
                case 2: {
                  message.requiredThirdPartyIdMissingBehavior = 2;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE":
                case 3: {
                  message.requiredThirdPartyIdMissingBehavior = 3;
                  break;
                }
                case "REQUIRED_PARAMETER_MISSING_BEHAVIOR_RETRY_NODE":
                case 4: {
                  message.requiredThirdPartyIdMissingBehavior = 4;
                  break;
                }
                default: {
                  if (typeof object.requiredThirdPartyIdMissingBehavior == "number") {
                    message.requiredThirdPartyIdMissingBehavior = object.requiredThirdPartyIdMissingBehavior;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.result) {
              if (!Array.isArray(object.result)) {
                throw new TypeError(".fetchactions.healthie.v1.CreateFamilyHistory.result: array type expected, but got " + (typeof object.result))
              }
              message.result = new Array(object.result.length);
              for (let i = 0; i < object.result.length; ++i) {
                if (typeof object.result[i] !== "object") {
                  throw new TypeError(".fetchactions.healthie.v1.CreateFamilyHistory.result: object expected, but got " + (typeof object.result[i]));
                }
                message.result[i] = healthie$1.v1.FamilyHistoryCondition.fromObject(object.result[i]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
              object.result = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? "UNDEFINED_REQUIRED_PARAMETER_MISSING_BEHAVIOR" : 0
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] = workflow_parameters$1.v1.DestinationActionParameter.toObject(message.params[i], options);
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] = workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(message.configs[i], options);
              }
            }
            if (message.requiredThirdPartyIdMissingBehavior != null && message.hasOwnProperty("requiredThirdPartyIdMissingBehavior")) {
              object.requiredThirdPartyIdMissingBehavior = options.enums === String ? workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] === undefined ? message.requiredThirdPartyIdMissingBehavior : workflow_parameters$1.v1.ParameterMissingPolicy.RequiredParameterMissingBehavior[message.requiredThirdPartyIdMissingBehavior] : message.requiredThirdPartyIdMissingBehavior;
            }
            if (message.result && message.result.length) {
              object.result = new Array(message.result.length);
              for (let i = 0; i < message.result.length; ++i) {
                object.result[i] = healthie$1.v1.FamilyHistoryCondition.toObject(message.result[i], options);
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = "type.googleapis.com") {
            return typeUrlPrefix + "fetchactions.healthie.v1.CreateFamilyHistory";
          }
        }

        CreateFamilyHistory.prototype.params = $util.emptyArray;
        CreateFamilyHistory.prototype.configs = $util.emptyArray;
        CreateFamilyHistory.prototype.requiredThirdPartyIdMissingBehavior = 0;
        CreateFamilyHistory.prototype.result = $util.emptyArray;

        return CreateFamilyHistory;
      })();

      return v1;
    })();

    return healthie;
  })();

  return fetchactions;
})();

export const fetchactions = $root.fetchactions;
