import { LoadMoreRow } from './LoadMoreRow';
import { VirtualTableBodyProps } from './types';
import { VirtualTableRow } from './VirtualTableRow';
import { memo } from 'react';
import { useVirtualizer, Virtualizer } from '@tanstack/react-virtual';

const VirtualTableBody: <RowData>(
  props: VirtualTableBodyProps<RowData>
) => JSX.Element = ({
  cellBackgroundColor,
  cellHeight,
  cellHoveredBackgroundColor,
  cellHoveredBorderColor,
  cellSelectedBackgroundColor,
  cellSelectedBorderColor,
  containerRef,
  fetchNextPage,
  hasNextPage,
  isFetching,
  isLoading,
  numberOfColumns,
  onRowClick,
  rows,
}) => {
  const shouldLoadMoreRows: boolean =
    hasNextPage && fetchNextPage !== undefined;

  const virtualizer: Virtualizer<HTMLDivElement, Element> = useVirtualizer({
    count: rows.length + (shouldLoadMoreRows ? 1 : 0),
    estimateSize: () => 100,
    getScrollElement: () => containerRef.current || null,
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  const virtualItems = virtualizer.getVirtualItems();

  return (
    <tbody data-testid='virtual-body-group'>
      {virtualItems.map((virtualRow, index) => {
        const row = rows[virtualRow.index];

        const commonProps = {
          cellBackgroundColor,
          cellHeight,
          cellHoveredBackgroundColor,
          cellHoveredBorderColor,
          cellSelectedBackgroundColor,
          cellSelectedBorderColor,
          firstRowId: rows[0]?.id,
          isLoading,
          onRowClick,
          row,
        };

        return row ? (
          <VirtualTableRow
            key={index}
            virtualRow={virtualRow}
            virtualizer={virtualizer}
            {...commonProps}
          />
        ) : (
          shouldLoadMoreRows && (
            <LoadMoreRow
              fetchNextPage={fetchNextPage!}
              isFetching={isFetching}
              isLoading={isLoading}
              key={index}
              numberOfColumns={numberOfColumns}
              virtualRow={virtualRow}
              virtualizer={virtualizer}
            />
          )
        );
      })}
    </tbody>
  );
};

export const MemoizedVirtualTableBody = memo(
  VirtualTableBody
) as typeof VirtualTableBody;
